import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { orderFilterValuesSchema } from '../schemas/orderSchema';

const loadGlobalOrdersFilterValues = async (params?: URLParams) => {
  const response = await api.get('/api/order/filter-values', { params });

  return orderFilterValuesSchema.parse(response.data);
};

export default loadGlobalOrdersFilterValues;
