import api from '../../services/api';
import globalSuperLeagueSchema, {
  GlobalSuperLeagueSchema,
} from '../schemas/globalSuperLeagueSchema';

type Schema = GlobalSuperLeagueSchema;

interface Payload {
  name: Schema['name'];
  code: Schema['code'];
  poker_room: Schema['poker_room'];
  extra: Schema['extra'];
}

interface Args {
  payload: Payload;
}

const createGlobalSuperLeague = async (args: Args) => {
  const { payload } = args;

  const response = await api.post('/api/reference/super-league', payload);

  return globalSuperLeagueSchema.pick({ id: true }).parse(response.data);
};

export default createGlobalSuperLeague;
