import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { BotSettingSchema } from '../../../api/schemas/botSettingSchema';
import Button from '../../../components/Button';
import FadingText from '../../../components/FadingText';
import Link from '../../../components/Link';
import PageAddon from '../../../components/PageAddon';
import routes from '../../../config/routes';
import { can } from '../../../utils/permissions';
import { useGlobalBotSettingContext } from '../GlobalBotSettingContext';
import useActions from '../useActions';
import styles from './styles.module.scss';

interface Props {
  data: BotSettingSchema;
  onClose: () => void;
}

const GlobalBotSettingDeleteError = (props: Props) => {
  const { data, onClose } = props;

  const { id } = data;

  const { t } = useTranslation();
  const { deleteBotSetting } = useActions({ data });
  const { deleteError, setDeleteError } = useGlobalBotSettingContext();

  if (!deleteError) return null;

  const { accounts, usage_rules } = deleteError;

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon
        onClose={() => {
          setDeleteError(null);
          onClose();
        }}
        title={<PageAddon.DangerTitle>{t('common.delete_error')}</PageAddon.DangerTitle>}
      >
        <div className={styles.container}>
          <div>{t('sentences.bot_settings_links_to')}</div>
          {Boolean(accounts.length) && (
            <div className={styles.wrapper}>
              <PageAddon.Heading level="second">{t('common.accounts')}:</PageAddon.Heading>
              <div className={styles.buttons}>
                {accounts.map((item) => {
                  const content = <FadingText text={item.mga_id} className={styles.text} />;

                  if (!can.account.read) return content;

                  return (
                    <Link
                      key={item.id}
                      to={routes.globalBotSettingErrorAccount({
                        botSettingId: id,
                        accountId: item.id,
                      })}
                    >
                      {content}
                    </Link>
                  );
                })}
              </div>
            </div>
          )}
          {Boolean(usage_rules.length) && (
            <div className={styles.wrapper}>
              <PageAddon.Heading level="second">{t('common.usage_rules')}:</PageAddon.Heading>
              <div className={styles.buttons}>
                {usage_rules.map((item) => (
                  <Link
                    key={item.id}
                    to={routes.globalBotSettingErrorBotSettingRule({
                      botSettingId: id,
                      botSettingRuleId: item.id,
                    })}
                  >
                    <FadingText text={item.name} className={styles.text} />
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
        <PageAddon.Controls>
          <Button onClick={deleteBotSetting}>{t('common.retry')}</Button>
        </PageAddon.Controls>
      </PageAddon>
    </PageAddon.Group>
  );
};

export default GlobalBotSettingDeleteError;
