import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';
import { WorkspaceUserSchema } from '../schemas/workspaceUserSchema';

interface Payload {
  user_id: WorkspaceUserSchema['id'];
  username: WorkspaceUserSchema['username'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const sendWorkspaceUserRecoveryPassword = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/recovery-user-password`,
    payload
  );

  return emptyResponseSchema.parse(response.data);
};

export default sendWorkspaceUserRecoveryPassword;
