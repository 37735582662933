import { z } from 'zod';
import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import workspaceAccountTransactionSchema, {
  WorkspaceAccountTransactionSchema,
} from '../schemas/account/workspaceAccountTransactionSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = WorkspaceAccountTransactionSchema;

interface Payload {
  amount: Schema['new_balance']['amount'];
  currency: Schema['new_balance']['currency'];
  club_id: Schema['new_balance']['club_id'];
  user_message: Schema['user_message'];
  new_account_id: WorkspaceAccountSchema['id'];
  file_ids: WorkspaceAccountTransactionSchema['files'][number]['id'][];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
  payload: Payload;
}

const transferWorkspaceAccount = async (args: Args) => {
  const { workspaceId, accountId, payload } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/${accountId}/transfer`,
    payload
  );

  return z
    .object({
      dest_transaction_id: workspaceAccountTransactionSchema.shape.id,
      source_transaction_id: workspaceAccountTransactionSchema.shape.id,
    })
    .parse(response.data);
};

export default transferWorkspaceAccount;
