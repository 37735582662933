import { useEffect } from 'react';
import { ws } from '../services/WebSocketService';
import { WebSocketMessage } from '../types/WebSocketMessage';

type DomainValue<T> = WebSocketMessage<T>['domain'];
type Domain<T> = DomainValue<T> | DomainValue<T>[];

interface Config<T> {
  domain: Domain<T>;
  onMessage: (message: WebSocketMessage<T>) => void;
  enabled?: (message: WebSocketMessage<T>) => boolean;
}

const useWebSocketMessage = <T>(config: Config<T>) => {
  const { domain, onMessage, enabled } = config;

  useEffect(() => {
    const domains = Array.isArray(domain) ? domain : [domain];

    const handler = (message: WebSocketMessage<T>) => {
      if (domains.includes(message.domain)) {
        if (enabled) {
          if (enabled(message)) {
            onMessage(message);
          }
        } else {
          onMessage(message);
        }
      }
    };

    ws.subscribe<T>(handler);

    return () => ws.unsubscribe<T>(handler);
  }, [domain, onMessage, enabled]);
};

export default useWebSocketMessage;
