import api from '../../services/api';
import notificationsCountSchema from '../schemas/notificationsCountSchema';

const loadNotificationsCount = async () => {
  const response = await api.get('/api/notification/count');

  return notificationsCountSchema.parse(response.data);
};

export default loadNotificationsCount;
