import { z } from 'zod';

export const TRAINER_GAME_TYPE = {
  UNSUPPORTED: 99999,
  FL: 0,
  NL: 1,
  FLR: 2,
  NLR: 3,
  MTT: 4,
  PLO: 5,
  NLD: 6,
  NLC: 7,
  SNG: 8,
  PLOR: 9,
  PLOD: 10,
  PLOHL: 11,
  SNGHU: 12,
  SNGDON: 13,
  FIFTY: 14,
  SPIN: 15,
  STUD: 16,
  RAZZ: 17,
  MTTPLO: 18,
  NLO: 19,
  MTTSNG: 20,
  SPNL: 21,
  BTC: 22,
  NLS: 23,
  NLP: 24,
  SPINPLO: 25,
  CSDNL: 26,
  POFC: 27,
  AANL: 28,
  AANLR: 29,
  PLO5C: 30,
  PLOC: 31,
  NLB: 32,
  CSDNLB: 33,
  PLO6C: 34,
  PLOB: 35,
  SNGTON: 36,
  PLO6CB: 37,
  PLO5CB: 38,
  PLOBHL: 39,
  PLO5CHL: 40,
  PLO5CBHL: 41,
  ROE: 42,
  NLAOF: 43,
  NLBAOF: 44,
  PLO6CHL: 45,
  PLO6CBHL: 46,
  PLO6CDB: 47,
  PLO6CDBB: 48,
  PLO5CDB: 49,
  PLO5CDBB: 50,
  PLODB: 51,
  PLODBB: 52,
  NLDB: 53,
  NLDBB: 54,
} as const;

const trainerGameTypeSchema = z.nativeEnum(TRAINER_GAME_TYPE);

export type TrainerGameTypeSchema = z.infer<typeof trainerGameTypeSchema>;

export default trainerGameTypeSchema;
