import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { BotSettingSchema } from '../../api/schemas/botSettingSchema';
import { PaginationSchema } from '../../api/schemas/paginationSchema';
import PageAddon from '../../components/PageAddon';
import Select, { OnLoad } from '../../components/Select';
import { TRAINER_GAME_TYPE_LABELS } from '../../utils/trainer';
import { getBotSettingCaption } from '../global-bot-setting-rule/helpers';
import { CommonAccountSchema, CommonAccountFields as Fields } from './types';

interface Props {
  data: CommonAccountSchema;
  onSubmit: () => Promise<void>;
  onLoad: OnLoad<PaginationSchema<BotSettingSchema>>;
  disabled?: boolean;
  controls?: (args: { botSettingId: BotSettingSchema['id'] }) => ReactNode;
}

const AccountDetailBotSettings = (props: Props) => {
  const { data, disabled, controls, onSubmit, onLoad } = props;

  const { bot_settings } = data;

  const { watch, setValue } = useFormContext<Fields>();

  const botSettingsIds = watch('bot_settings_ids');

  if (bot_settings.length === 0 && disabled) return '—';

  return (
    <PageAddon.QuickField.Group>
      {bot_settings.map((item) => (
        <PageAddon.QuickField
          key={item.id}
          disabled={disabled}
          title={item.name}
          subTitle={TRAINER_GAME_TYPE_LABELS[item.game_type]}
          onDelete={() => {
            setValue(
              'bot_settings_ids',
              botSettingsIds.filter((el) => el.value !== item.id)
            );

            onSubmit();
          }}
        >
          {controls && controls({ botSettingId: item.id })}
        </PageAddon.QuickField>
      ))}
      {!disabled && (
        <Select.Async
          onLoad={async (args) => {
            const response = await onLoad(args);

            const options = response.items.map((item) => ({
              value: item.id,
              label: item.name,
              caption: getBotSettingCaption(item),
            }));

            return options;
          }}
        >
          {({ options, ...selectAsyncProps }) => (
            <Select.Quick
              name="bot_settings_ids"
              disabled={disabled}
              options={options.filter(
                (item) => !botSettingsIds.map((el) => el.value).includes(item.value)
              )}
              onChange={(value) => {
                setValue('bot_settings_ids', value ? [...botSettingsIds, value] : []);

                onSubmit();
              }}
              {...selectAsyncProps}
            />
          )}
        </Select.Async>
      )}
    </PageAddon.QuickField.Group>
  );
};

export default AccountDetailBotSettings;
