import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  count: number;
  children: ReactNode;
  variant?: 'count' | 'dot';
  className?: string;
}

const Indicator = (props: Props) => {
  const { children, count, variant = 'count', className: customClassName } = props;

  const className = clsx(styles.indicator, {
    [styles[variant]]: true,
    ...(customClassName && { [customClassName]: true }),
  });

  return (
    <div className={styles.container}>
      {count !== 0 && <div className={className}>{variant === 'count' && count}</div>}
      {children}
    </div>
  );
};

export default Indicator;
