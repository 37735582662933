import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadGlobalLeague } from '../../api';
import { GlobalLeagueSchema } from '../../api/schemas/globalLeagueSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { updateDetailQueryData } from '../../utils/queryClient';

interface Props {
  leagueId: GlobalLeagueSchema['id'];
  children: (args: { data: GlobalLeagueSchema }) => ReactElement;
}

const GlobalLeagueLoader = (props: Props) => {
  const { leagueId, children } = props;

  const queryKey = [queryKeys.globalLeague({ leagueId })];
  const queryFn = () => loadGlobalLeague({ leagueId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<GlobalLeagueSchema>({
    domain: 'League',
    onMessage: (message) => {
      if (
        (message.action === 'Edited' || message.action === 'Deleted') &&
        message.payload.id === leagueId
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data });
};

export default GlobalLeagueLoader;
