import { useState, useEffect } from 'react';
import { HexColorPicker } from 'react-colorful';
import usePrevious from '../../hooks/usePrevious';
import Input from '../Input';
import styles from './styles.module.scss';

interface Props {
  defaultValue?: string;
  onChange?: (value: string) => void;
}

const ColorPicker = (props: Props) => {
  const { defaultValue, onChange } = props;

  const [value, setValue] = useState(defaultValue || '');

  const prevValue = usePrevious(value);

  useEffect(() => {
    if (onChange && value !== prevValue) {
      onChange(value);
    }
  }, [value, prevValue, onChange]);

  return (
    <div className={styles.container}>
      <HexColorPicker color={value} onChange={setValue} className={styles.picker} />
      <Input
        size="small"
        value={value}
        className={styles.input}
        onChange={(event) => setValue(event.target.value)}
      />
    </div>
  );
};

export default ColorPicker;
