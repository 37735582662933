import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { workspaceClubTransactionFilterValuesSchema } from '../schemas/workspaceClubTransactionSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceClubTransactionsFilterValues = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(
    `/api/maintenance/${workspaceId}/club-manager/transactions/filter-values`,
    { params }
  );

  return workspaceClubTransactionFilterValuesSchema.parse(response.data);
};

export default loadWorkspaceClubTransactionsFilterValues;
