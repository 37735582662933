import { matchSorter } from 'match-sorter';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Chip from '../../../components/Chip';
import SearchInput from '../../../components/SearchInput';
import { getFieldGroups } from './helpers';
import styles from './styles.module.scss';

type Fields = number[];

interface Props {
  variant: 'view' | 'csv';
  value: Fields;
  onChange: (value: Fields) => void;
}

const WorkspaceAccountFields = (props: Props) => {
  const { variant, value, onChange } = props;

  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const fieldGroups = getFieldGroups(variant);

  const fieldGroupsValues = fieldGroups
    .flatMap((item) => item.fields.map((el) => ({ value: el.value })))
    .map((item) => item.value);

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <SearchInput size="small" value={search} onChange={setSearch} className={styles.search} />
        {!search && (
          <Button
            variant="light"
            onClick={() => {
              if (value.length === fieldGroupsValues.length) {
                onChange([]);
              } else {
                onChange(fieldGroupsValues);
              }
            }}
          >
            {value.length === fieldGroupsValues.length
              ? t('common.deselect_all')
              : t('common.select_all')}
          </Button>
        )}
      </div>
      {fieldGroups.map((item) => {
        const { label, fields } = item;

        const filteredFields = search ? matchSorter(fields, search, { keys: ['label'] }) : fields;

        const fieldsValues = fields.map((el) => el.value);

        const allSelected = fieldsValues.every((el) => value.includes(el));

        if (filteredFields.length) {
          return (
            <div key={label} className={styles.group}>
              <div className={styles.label}>
                <span>{label}</span>
                {!search && (
                  <Button
                    variant="light"
                    size="extra-small"
                    onClick={() => {
                      const filteredValue = value.filter((el) => !fieldsValues.includes(el));

                      if (allSelected) {
                        onChange(filteredValue);
                      } else {
                        onChange([...filteredValue, ...fieldsValues]);
                      }
                    }}
                  >
                    {allSelected ? t('common.deselect_all') : t('common.select_all')}
                  </Button>
                )}
              </div>
              <div className={styles.availableChips}>
                {filteredFields.map((field) => {
                  const handleClick = () => {
                    if (value.includes(field.value)) {
                      onChange(value.filter((el) => el !== field.value));
                    } else {
                      onChange([...value, field.value]);
                    }
                  };

                  return (
                    <Chip
                      key={field.value}
                      onClick={handleClick}
                      active={value.includes(field.value)}
                    >
                      {field.label}
                    </Chip>
                  );
                })}
              </div>
            </div>
          );
        }

        return null;
      })}
    </div>
  );
};

export default WorkspaceAccountFields;
