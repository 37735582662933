import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import deleteGlobalBotSettingRule from '../../api/global-bot-setting-rule/deleteGlobalBotSettingRule';
import { BotSettingRuleSchema } from '../../api/schemas/botSettingRuleSchema';
import { presets } from '../../components/Dropdown';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { RouteParams } from '../../utils/generics';
import notify from '../../utils/notify';
import { can } from '../../utils/permissions';

type Params = RouteParams<typeof routes.globalBotSettingRule>;

interface Args {
  data: BotSettingRuleSchema;
}

const useDropdown = (args: Args) => {
  const { data } = args;

  const { id, name, is_deleted } = data;

  const { t } = useTranslation();
  const { navigate } = useURL();
  const { botSettingRuleId } = useParams<Params>();

  return {
    title: name,
    disabled: is_deleted,
    options: {
      edit: {
        ...presets.edit,
        onClick: () => navigate(routes.globalBotSettingRuleEdit({ botSettingRuleId: id })),
        disabled: !can.botSetting.ruleCreateUpdate,
        ...(!can.botSetting.ruleCreateUpdate && { tooltip: t('common.no_permission') }),
      },
      copy: presets.copyId(id),
      divider: { divider: true },
      delete: {
        ...presets.delete,
        onClick: async () => {
          await deleteGlobalBotSettingRule({ botSettingRuleId: id });

          if (id === botSettingRuleId) {
            navigate(routes.globalBotSettingsRules);
          }

          notify('success', { title: t('sentences.record_has_been_deleted') });
        },
        disabled: !can.botSetting.ruleDelete,
        ...(!can.botSetting.ruleDelete && { tooltip: t('common.no_permission') }),
      },
    },
  };
};

export default useDropdown;
