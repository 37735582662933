import { useTranslation } from 'react-i18next';
import Filter from '../../components/Filter';
import useFilter from '../../hooks/useFilter';

interface Fields {
  invited_ge: string;
  invited_le: string;
  show_unverified: boolean;
}

const WorkspaceUserFilter = () => {
  const { t } = useTranslation();

  const filter = useFilter<Fields>({
    invited_ge: 'single',
    invited_le: 'single',
    show_unverified: 'toggle',
  });

  const { values, setValue, clearValue } = filter;

  const { invited_ge, invited_le, show_unverified } = values;

  const filterProps = { filter };

  return (
    <Filter {...filterProps}>
      <div>
        <Filter.Collapse
          label={t('common.invited')}
          {...((invited_ge || invited_le) && {
            onClear: clearValue(['invited_ge', 'invited_le']),
          })}
        >
          <Filter.Date
            from={{ value: invited_ge, onChange: setValue('invited_ge') }}
            to={{ value: invited_le, onChange: setValue('invited_le') }}
          />
        </Filter.Collapse>
      </div>
      <Filter.Switcher checked={show_unverified} onChange={setValue('show_unverified')}>
        {t('common.show_unverified')}
      </Filter.Switcher>
    </Filter>
  );
};

export default WorkspaceUserFilter;
