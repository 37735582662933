import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  icon?: ReactNode;
  subTitle?: string;
  danger?: boolean;
}

const DialogTitle = (props: Props) => {
  const { children, icon, subTitle, danger } = props;

  return (
    <div className={styles.container}>
      <div className={clsx(styles.title, danger && styles.danger)}>
        {icon}
        {children}
      </div>
      {subTitle && <span>{subTitle}</span>}
    </div>
  );
};

export default DialogTitle;
