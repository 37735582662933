import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createWorkspaceClubWhitelistMember } from '../../api';
import { WorkspaceClubWhitelistSchema } from '../../api/schemas/workspaceClubWhitelistSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Form from '../../components/Form';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import Select, { Option } from '../../components/Select';
import SubmitButton from '../../components/SubmitButton';
import { maxLength, minLength, required } from '../../utils/form';
import notify from '../../utils/notify';
import { trainerPokerRoomOptions } from '../../utils/trainer';

interface Fields {
  player_id: string;
  room: Option<WorkspaceClubWhitelistSchema['room']>;
}

interface Props {
  workspaceId: WorkspaceSchema['id'];
  onCreate: (args: { whitelistMemberId: WorkspaceClubWhitelistSchema['id'] }) => void;
  onClose: () => void;
}

const WorkspaceClubWhitelistForm = (props: Props) => {
  const { workspaceId, onCreate, onClose } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: {
      player_id: '',
      room: null,
    },
  });

  const {
    register,
    control,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    if (!values.room) throw new Error();

    const payload = {
      player_id: values.player_id,
      room: values.room.value,
    };

    const response = await createWorkspaceClubWhitelistMember({
      workspaceId,
      payload,
    });

    onCreate({ whitelistMemberId: response.id });
    notify('success', { title: t('sentences.record_has_been_created') });
  };

  return (
    <PageAddon title={t('common.create_whitelist')} onClose={onClose}>
      <Form form={form} onSubmit={onSubmit}>
        <Input
          label={`${t('common.pid')}*`}
          error={errors.player_id?.message}
          {...register('player_id', {
            validate: {
              required,
              minLength: minLength(),
              maxLength: maxLength(),
            },
          })}
        />
        <Controller
          name="room"
          control={control}
          rules={{ validate: { required } }}
          render={({ field }) => (
            <Select
              clearable={false}
              label={`${t('common.poker_room')}*`}
              options={trainerPokerRoomOptions}
              value={field.value}
              onChange={field.onChange}
              error={errors.room?.message}
            />
          )}
        />
        <PageAddon.Controls>
          <SubmitButton>{t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default WorkspaceClubWhitelistForm;
