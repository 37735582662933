import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { OrderTransactionSchema } from '../../../api/schemas/orderTransactionSchema';
import Placeholder from '../../../components/Placeholder';
import Spinner from '../../../components/Spinner';
import CURRENCY from '../../../constants/CURRENCY';
import usePaginatedData from '../../../hooks/usePaginatedData';
import { convertCentsToUSDT } from '../../../utils/data';
import { formatAbsoluteDate } from '../../../utils/date';
import OrderTransactionStatus from '../OrderTransactionStatus';
import styles from './styles.module.scss';

interface Props {
  data: OrderTransactionSchema[];
  loading: boolean;
  loadingMore: boolean;
  checkIsInView: ReturnType<typeof usePaginatedData>['checkIsInView'];
}

const OrderTransactions = (props: Props) => {
  const { data, loading, loadingMore, checkIsInView } = props;

  const { t } = useTranslation();

  const tableHead = (
    <div className={styles.tableRow}>
      <div className={styles.tableHeadCell}>{t('common.date')}</div>
      <div className={styles.tableHeadCell}>{t('common.status')}</div>
      <div className={styles.tableHeadCell}>
        {t('common.amount')}
        {CURRENCY.USDT}
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className={clsx(styles.table, styles.loading)}>
        {tableHead}
        {Array.from({ length: 3 }).map((_, index) => (
          <div key={index} className={styles.tableRow}>
            <div className={styles.datePlaceholder}>
              <Placeholder />
              <Placeholder />
            </div>
            <Placeholder className={styles.placeholder} />
            <Placeholder className={styles.placeholder} />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={styles.table}>
      {tableHead}
      {data.length === 0 && <div className={styles.empty}>{t('common.no_transactions')}</div>}
      {data.map((item, index) => (
        <div key={item.id} {...checkIsInView(index)} className={styles.tableRow}>
          <div className={styles.tableCell}>
            <div className={styles.date}>
              {formatAbsoluteDate(item.execute_on)}
              <span>{item.is_fee ? t('common.fee') : t('common.payment')}</span>
            </div>
          </div>
          <div className={styles.tableCell}>
            <OrderTransactionStatus data={item} />
          </div>
          <div className={styles.tableCell}>{convertCentsToUSDT(item.amount)}</div>
        </div>
      ))}
      {loadingMore && (
        <div className={styles.spinner}>
          <Spinner />
          <span>{t('common.loading')}</span>
        </div>
      )}
    </div>
  );
};

export default OrderTransactions;
