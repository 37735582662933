import { useTranslation } from 'react-i18next';
import { startWorkspaceClubManager, stopWorkspaceClubManager } from '../../api';
import { WorkspaceClubManagerSchema, STATUS } from '../../api/schemas/workspaceClubManagerSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Button, { Props as ButtonProps } from '../../components/Button';
import Confirm from '../../components/Confirm';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import { handleError } from '../../utils/form';
import notify from '../../utils/notify';

interface Props {
  data: WorkspaceClubManagerSchema;
  workspaceId: WorkspaceSchema['id'];
  isDetail?: boolean;
}

const WorkspaceClubManagerStartButton = (props: Props) => {
  const { data, workspaceId, isDetail = false } = props;

  const { id, status, is_deleted } = data;

  const { t } = useTranslation();

  const stopped = status === STATUS.STOPPED;

  const handleClick = async () => {
    try {
      await startWorkspaceClubManager({ workspaceId, managerId: id });
    } catch (error) {
      handleError({ error });
    }
  };

  const handleConfirm = async () => {
    try {
      await stopWorkspaceClubManager({ workspaceId, managerId: id });

      notify('success', { title: t('sentences.manager_success_stop') });
    } catch (error) {
      handleError({ error });
    }
  };

  const disabled = is_deleted;

  const buttonProps: ButtonProps = {
    size: isDetail ? 'medium' : 'small',
    variant: 'transparent',
    icon: stopped ? icon('play', 16) : icon('pause', 16),
    onClick: handleClick,
    disabled,
  };

  return (
    <Tooltip label={stopped ? t('common.run') : t('common.stop')} hideOnMobile={!disabled}>
      {stopped ? (
        <div>
          <Button {...buttonProps} />
        </div>
      ) : (
        <Confirm
          onConfirm={handleConfirm}
          title={t('sentences.stop_a_manager')}
          confirmText={t('common.stop')}
        >
          {({ confirming }) => <Button focused={confirming} {...buttonProps} />}
        </Confirm>
      )}
    </Tooltip>
  );
};

export default WorkspaceClubManagerStartButton;
