import { useTranslation } from 'react-i18next';
import { GlobalBonusSchema } from '../../api/schemas/globalBonusSchema';
import PageAddon from '../../components/PageAddon';
import icon from '../../static/icons';
import { formatAbsoluteDate } from '../../utils/date';
import GlobalBonusDetailDropdown from './GlobalBonusDetailDropdown';
import useForm from './useForm';

interface Props {
  data: GlobalBonusSchema;
  onClose: () => void;
}

const GlobalBonusDetail = (props: Props) => {
  const { data, onClose } = props;

  const { id, is_deleted, created_on, modified_on } = data;

  const { t } = useTranslation();

  const { fields } = useForm({ data });

  const titleIcons = is_deleted && icon('trash', 12);

  return (
    <PageAddon copy title={formatAbsoluteDate(created_on)} icons={titleIcons} onClose={onClose}>
      <PageAddon.Fields>
        <PageAddon.Id id={id} />
        {fields.deal_id}
        {fields.type}
        {fields.amount}
        {fields.currency}
        {fields.description}
        {fields.reconcile}
        <PageAddon.Field label={t('common.modified')}>
          <PageAddon.Copy>{formatAbsoluteDate(modified_on)}</PageAddon.Copy>
        </PageAddon.Field>
      </PageAddon.Fields>
      {!is_deleted && (
        <PageAddon.Controls>
          <PageAddon.Controls.Group>
            <GlobalBonusDetailDropdown data={data} />
          </PageAddon.Controls.Group>
        </PageAddon.Controls>
      )}
    </PageAddon>
  );
};

export default GlobalBonusDetail;
