import api from '../../services/api';
import areaSchema, { AreaSchema } from '../schemas/areaSchema';

interface Args {
  areaId: AreaSchema['id'];
}

const loadGlobalArea = async (args: Args) => {
  const { areaId } = args;

  const response = await api.get(`/api/area/${areaId}`);

  return areaSchema.parse(response.data);
};

export default loadGlobalArea;
