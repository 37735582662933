import { z } from 'zod';
import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import workspaceSessionSchema from '../schemas/session/workspaceSessionSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
}

const loadWorkspaceAccountSessions = async (args: Args, params?: URLParams) => {
  const { workspaceId, accountId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/${accountId}/session`,
    { params }
  );

  return z.array(workspaceSessionSchema).parse(response.data);
};

export default loadWorkspaceAccountSessions;
