import { motion, AnimatePresence } from 'framer-motion';
import { Props as PageAddonProps } from '..';
import usePrevious from '../../../hooks/usePrevious';
import styles from './styles.module.scss';

interface Props {
  tabs: Exclude<PageAddonProps['tabs'], undefined>;
  tabIndex: number;
}

const PageAddonTabs = (props: Props) => {
  const { tabs, tabIndex } = props;

  const prevTabIndex = usePrevious(tabIndex);

  return (
    <AnimatePresence initial={false}>
      <motion.div
        key={String(tabIndex)}
        initial={{ x: tabIndex < prevTabIndex ? 20 : -20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ ease: 'easeIn', duration: 0.2 }}
        className={styles.container}
      >
        {tabs.find((_, index) => index === tabIndex)?.content}
      </motion.div>
    </AnimatePresence>
  );
};

export default PageAddonTabs;
