import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Page from '../../components/Page';
import useSidebarSection from '../../hooks/useSidebarSection';
import Error403 from '../../static/403.svg';
import styles from './styles.module.scss';

const Error403Page = () => {
  const { t } = useTranslation();
  const { isSectionExists, moveToFirstSection } = useSidebarSection();

  return (
    <Page search={false} filter={false}>
      <div className={styles.container}>
        <Error403 width={594} height={254} />
        <span>{t('common.access_denied')}</span>
        {isSectionExists && <Button onClick={moveToFirstSection}>{t('common.go_home')}</Button>}
      </div>
    </Page>
  );
};

export default Error403Page;
