import { useInfiniteQuery, QueryKey } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import { PaginationSchema } from '../api/schemas/paginationSchema';
import Id from '../types/Id';

type Response<T> = PaginationSchema<T>;

interface Config<T> {
  queryKey: QueryKey;
  queryFn: (urlParams: { page?: string }) => Promise<Response<T>>;
  enabled?: boolean;
}

const usePaginatedData = <T extends Id>(config: Config<T>) => {
  const { queryKey, queryFn, enabled } = config;

  const { data, isPending, isFetching, isFetchingNextPage, hasNextPage, error, fetchNextPage } =
    useInfiniteQuery({
      queryKey,
      queryFn: ({ pageParam }) => queryFn(pageParam ? { page: String(pageParam) } : {}),
      enabled,
      initialPageParam: 0,
      meta: { ignoredStatuses: [403] },
      getNextPageParam: (response) => {
        const { page, total, size } = response;

        const nextPage = page + 1;
        const totalPages = Math.ceil(total / size);

        return nextPage > totalPages ? null : nextPage;
      },
    });

  const { ref } = useInView({
    onChange: (value) => {
      if (value && !error) {
        fetchNextPage();
      }
    },
  });

  const total = data?.pages[0].total;
  const flatData = data?.pages.map((item) => item.items).flat() || [];
  const ids = flatData.map((item) => item.id);

  const checkIsInView = (index: number) =>
    flatData.length === index + 1 && !isPending && !isFetchingNextPage && hasNextPage && { ref };

  return {
    ids,
    data: flatData,
    total,
    loading: isPending,
    isFetching,
    loadingMore: isFetchingNextPage && !isPending,
    hasNextPage,
    error,
    checkIsInView,
    fetchNextPage,
  };
};

export default usePaginatedData;
