import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';
import { WorkspaceUserSchema } from '../schemas/workspaceUserSchema';

type Schema = WorkspaceUserSchema;

interface Args {
  workspaceId: WorkspaceSchema['id'];
  userId: Schema['id'];
}

const deleteWorkspaceUser = async (args: Args) => {
  const { workspaceId, userId } = args;

  const response = await api.delete(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/user/${userId}`);

  return emptyResponseSchema.parse(response.data);
};

export default deleteWorkspaceUser;
