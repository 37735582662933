import { FloatingOverlay, FloatingPortal } from '@floating-ui/react';
import clsx from 'clsx';
import {
  useCallback,
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
  WheelEvent,
  ElementRef,
  ReactNode,
} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';
import icon from '../../static/icons';
import { getUrlWithToken } from '../../utils/auth';
import { checkIsMediaFile } from '../../utils/data';
import MediaViewerPreview from './MediaViewerPreview';
import styles from './styles.module.scss';
import { Files } from './types';

type Index = number | null;
type Which = 'prev' | 'next';

interface Props {
  files: Files;
  className?: string;
  children?: ReactNode;
}

interface ViewerProps {
  files: Props['files'];
  selectedIndex: number;
  setSelectedIndex: Dispatch<SetStateAction<Index>>;
}

const Viewer = (props: ViewerProps) => {
  const { files, selectedIndex, setSelectedIndex } = props;

  const { t } = useTranslation();

  const goToImage = (which: Which) => {
    setSelectedIndex((prev) => {
      if (prev === null) return prev;

      const newIndex = prev + (which === 'prev' ? -1 : 1);

      if (!files[newIndex]) return prev;

      return newIndex;
    });
  };

  const hasImage = (which: Which) => Boolean(files[selectedIndex + (which === 'prev' ? -1 : 1)]);

  const goToPrevImage = () => goToImage('prev');
  const goToNextImage = () => goToImage('next');

  const handleWheel = (event: WheelEvent) => {
    const { deltaY } = event;

    if (deltaY <= 0) goToPrevImage();
    if (deltaY >= 0) goToNextImage();
  };

  const closeViewer = useCallback(() => setSelectedIndex(null), [setSelectedIndex]);

  const handlers = useSwipeable({ onSwipedLeft: goToNextImage, onSwipedRight: goToPrevImage });

  const ref = useOnclickOutside(closeViewer, { ignoreClass: styles.control });

  useHotkeys('esc', closeViewer);
  useHotkeys('left', goToPrevImage);
  useHotkeys('right', goToNextImage);

  const hasPrevImage = hasImage('prev');
  const hasNextImage = hasImage('next');

  const { src, name } = files[selectedIndex];

  const closeButtonRef = useRef<ElementRef<'button'>>(null);

  useEffect(() => {
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, []);

  return (
    <FloatingOverlay lockScroll className={styles.container} onWheel={handleWheel} {...handlers}>
      <div className={styles.control}>
        {hasPrevImage && (
          <button
            type="button"
            className={clsx(styles.button, styles.navigation)}
            onClick={goToPrevImage}
          >
            {icon('chevronLeft', 24)}
          </button>
        )}
      </div>
      <figure className={styles.wrapper}>
        <img ref={ref} src={src} alt={name} />
        <figcaption>{name}</figcaption>
      </figure>
      <div className={styles.control}>
        <button
          ref={closeButtonRef}
          type="button"
          className={clsx(styles.button, styles.close)}
          onClick={closeViewer}
        >
          {icon('cross', 24)}
        </button>
        {hasNextImage && (
          <button
            type="button"
            className={clsx(styles.button, styles.navigation)}
            onClick={goToNextImage}
          >
            {icon('chevronRight', 24)}
          </button>
        )}
        <a
          className={clsx(styles.button, styles.download)}
          href={src}
          download={src}
          target="_blank"
          rel="noreferrer"
        >
          {icon('download', 24)}
        </a>
      </div>
      <div className={styles.info}>{`${selectedIndex + 1} ${t('common.of')} ${files.length}`}</div>
    </FloatingOverlay>
  );
};

const MediaViewer = (props: Props) => {
  const { className, children } = props;
  let { files } = props;

  const [selectedIndex, setSelectedIndex] = useState<Index>(null);

  files = files.filter((file) => checkIsMediaFile(file.name));

  files = files.map((item) => ({
    ...item,
    src: item.src.startsWith('blob') ? item.src : getUrlWithToken(item.src),
  }));

  if (files.length === 0 && !children) return null;

  return (
    <>
      {selectedIndex !== null && (
        <FloatingPortal id="root">
          <Viewer files={files} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />
        </FloatingPortal>
      )}
      <div className={clsx(styles.preview, className)}>
        {files.map((file, index) => {
          const { src, name, error, onRemove } = file;

          const handleClick = () => setSelectedIndex(index);

          return (
            <MediaViewerPreview
              key={src}
              src={src}
              name={name}
              error={error}
              onClick={handleClick}
              onRemove={onRemove}
            />
          );
        })}
        {children}
      </div>
    </>
  );
};

export default MediaViewer;
