import { ReactNode } from 'react';
import PageSorting from '../PageSorting';
import PageTotal from '../PageTotal';
import { Props as TableProps } from '../Table/types';
import styles from './styles.module.scss';

interface Props<T> extends Pick<TableProps<T>, 'total' | 'sorting' | 'loading' | 'meta'> {
  extra?: ReactNode;
}

const PageTop = <T,>(props: Props<T>) => {
  const { extra, total, sorting, loading, meta } = props;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {extra}
        {(total || total === 0) && <PageTotal total={total} meta={meta} loading={loading} />}
      </div>
      {sorting && <PageSorting sorting={sorting} />}
    </div>
  );
};

export default PageTop;
