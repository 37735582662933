import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadWorkspaceAccount } from '../../api';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { updateDetailQueryData } from '../../utils/queryClient';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
  children: (args: { data: WorkspaceAccountSchema }) => ReactElement;
}

const WorkspaceAccountLoader = (props: Props) => {
  const { workspaceId, accountId, children } = props;

  const queryKey = [queryKeys.workspaceAccount({ workspaceId, accountId })];
  const queryFn = () => loadWorkspaceAccount({ workspaceId, accountId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<WorkspaceAccountSchema>({
    domain: 'Account',
    enabled: (message) => message.meta_info?.maintenance_id === workspaceId,
    onMessage: (message) => {
      if (
        (message.action === 'Edited' ||
          message.action === 'Deleted' ||
          message.action === 'BalanceChanged' ||
          message.action === 'UserRemoved' ||
          message.action === 'PilotSwitchOn' ||
          message.action === 'PilotSwitchOff' ||
          message.action === 'ConfirmPilotStarted' ||
          message.action === 'ConfirmPilotFinished' ||
          message.action === 'AccountIsOnline' ||
          message.action === 'AccountIsOffline' ||
          message.action === 'IdentitySet' ||
          message.action === 'IdentityRemoved' ||
          message.action === 'NoteAdded' ||
          message.action === 'NoteRemoved' ||
          message.action === 'VerificationSubmitted' ||
          message.action === 'VerificationFinished') &&
        message.payload.id === accountId
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data });
};

export default WorkspaceAccountLoader;
