import api from '../../services/api';
import URLParams from '../../types/URLParams';
import botSettingRuleSchema from '../schemas/botSettingRuleSchema';
import paginationSchema from '../schemas/paginationSchema';

const loadGlobalBotSettingsRules = async (params?: URLParams) => {
  const response = await api.get('/api/bot-settings-usage-rule', { params });

  return paginationSchema(botSettingRuleSchema).parse(response.data);
};

export default loadGlobalBotSettingsRules;
