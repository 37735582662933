import { useTranslation } from 'react-i18next';
import { WorkspaceClubMemberSchema } from '../../../api/schemas/workspaceClubMemberSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import PageDetailLink from '../../../components/PageDetailLink';
import Tooltip from '../../../components/Tooltip';
import routes from '../../../config/routes';
import icon from '../../../static/icons';
import styles from './styles.module.scss';

interface Props {
  data: WorkspaceClubMemberSchema;
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceClubMemberDetailLink = (props: Props) => {
  const { data, workspaceId } = props;

  const { id, nickname, player_id, accept, kick } = data;

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <PageDetailLink
        label={player_id}
        to={routes.workspaceClubMember({ workspaceId, memberId: id })}
        extra={
          <>
            {accept && <Tooltip label={t('common.awaiting_entry')}>{icon('clock', 12)}</Tooltip>}
            {kick && (
              <Tooltip label={t('common.awaiting_kick_off')}>{icon('minusCircle', 12)}</Tooltip>
            )}
          </>
        }
      />
      <div className={styles.caption}>{nickname}</div>
    </div>
  );
};

export default WorkspaceClubMemberDetailLink;
