import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
/* import emptyResponseSchema from '../schemas/emptyResponseSchema'; */
import { IdentitySchema } from '../schemas/identitySchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Payload = IdentitySchema['id'][];

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const bulkReleaseWorkspaceIdentitiesOnMarket = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/identity/bulk-release-on-market`,
    payload
  );

  /* return emptyResponseSchema.parse(response.data); */
  /* TODO: схема должна быть пустой */
  return response.data;
};

export default bulkReleaseWorkspaceIdentitiesOnMarket;
