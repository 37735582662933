import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadWorkspaceChannelsFilterValues } from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Filter from '../../components/Filter';
import queryKeys from '../../config/queryKeys';
import useFilter from '../../hooks/useFilter';
import useURL from '../../hooks/useURL';
import { CHANNEL_STATUS_LABELS } from './helpers';

interface Fields {
  country_in: string[];
  status_in: string[];
  avg_latency_ge: string;
  avg_latency_le: string;
  packet_loss_percent_ge: string;
  packet_loss_percent_le: string;
  created_ge: string;
  created_le: string;
  is_used: boolean;
  is_free: boolean;
  show_deleted: boolean;
}

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceChannelFilter = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { urlParams } = useURL();

  const queryKey = [
    queryKeys.workspaceChannelsFilterValues({ workspaceId }),
    urlParams.is_used,
    urlParams.is_free,
    urlParams.show_deleted,
  ];
  const queryFn = () =>
    loadWorkspaceChannelsFilterValues(
      { workspaceId },
      {
        is_used: urlParams.is_used,
        is_free: urlParams.is_free,
        show_deleted: urlParams.show_deleted,
      }
    );

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  const filter = useFilter<Fields>({
    country_in: 'multi',
    status_in: 'multi',
    avg_latency_ge: 'single',
    avg_latency_le: 'single',
    packet_loss_percent_ge: 'single',
    packet_loss_percent_le: 'single',
    created_ge: 'single',
    created_le: 'single',
    is_used: 'toggle',
    is_free: 'toggle',
    show_deleted: 'toggle',
  });

  const { values, setValue, clearValue } = filter;

  const {
    country_in,
    status_in,
    avg_latency_ge,
    avg_latency_le,
    packet_loss_percent_ge,
    packet_loss_percent_le,
    created_ge,
    created_le,
    is_used,
    is_free,
    show_deleted,
  } = values;

  const filterProps = { filter };

  if (isPending) {
    return (
      <Filter {...filterProps}>
        <Filter.Placeholder />
      </Filter>
    );
  }

  if (!data) return null;

  const { country, status, avg_latency, packet_loss_percent } = data;

  return (
    <Filter {...filterProps}>
      <div>
        {country.length !== 0 && (
          <Filter.Collapse
            label={t('common.country')}
            count={country_in.length}
            onClear={clearValue('country_in')}
          >
            <Filter.MultiCheckbox
              config={country.map((item) => ({
                value: item,
                label: item,
                onChange: setValue('country_in'),
                checked: country_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {status.length !== 0 && (
          <Filter.Collapse
            label={t('common.status')}
            count={status_in.length}
            onClear={clearValue('status_in')}
          >
            <Filter.MultiCheckbox
              config={status.map((item) => ({
                value: String(item),
                label: CHANNEL_STATUS_LABELS[item],
                onChange: setValue('status_in'),
                checked: status_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        <Filter.RangeInput
          label={t('common.avg_latency_ms')}
          step={1}
          min={avg_latency.min}
          max={avg_latency.max}
          valueMin={avg_latency_ge}
          valueMax={avg_latency_le}
          fieldMin="avg_latency_ge"
          fieldMax="avg_latency_le"
        />
        <Filter.RangeInput
          label={t('common.packet_loss_%')}
          step={1}
          min={packet_loss_percent.min}
          max={packet_loss_percent.max}
          valueMin={packet_loss_percent_ge}
          valueMax={packet_loss_percent_le}
          fieldMin="packet_loss_percent_ge"
          fieldMax="packet_loss_percent_le"
        />
        <Filter.Collapse
          label={t('common.created')}
          {...((created_ge || created_le) && {
            onClear: clearValue(['created_ge', 'created_le']),
          })}
        >
          <Filter.Date
            from={{ value: created_ge, onChange: setValue('created_ge') }}
            to={{ value: created_le, onChange: setValue('created_le') }}
          />
        </Filter.Collapse>
      </div>
      <Filter.Switcher checked={is_used} onChange={setValue('is_used')}>
        {t('common.show_used')}
      </Filter.Switcher>
      <Filter.Switcher checked={is_free} onChange={setValue('is_free')}>
        {t('common.show_free')}
      </Filter.Switcher>
      <Filter.Switcher checked={show_deleted} onChange={setValue('show_deleted')}>
        {t('common.show_deleted')}
      </Filter.Switcher>
    </Filter>
  );
};

export default WorkspaceChannelFilter;
