import api from '../../services/api';
import globalAccountSchema, { GlobalAccountSchema } from '../schemas/account/globalAccountSchema';

interface Args {
  accountId: GlobalAccountSchema['id'];
}

const loadGlobalAccount = async (args: Args) => {
  const { accountId } = args;

  const response = await api.get(`/api/account/${accountId}`);

  return globalAccountSchema.parse(response.data);
};

export default loadGlobalAccount;
