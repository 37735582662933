import FadingText from '../FadingText';
import Link from '../Link';
import styles from './styles.module.scss';

interface Props {
  to: string;
  label: string;
  extra?: React.ReactNode;
  onClick?: () => void;
}

const PageDetailLink = (props: Props) => {
  const { to, label, extra, onClick } = props;

  return (
    <FadingText text={label} extra={<div className={styles.extra}>{extra}</div>}>
      <Link to={to} onClick={onClick}>
        {label}
      </Link>
    </FadingText>
  );
};

export default PageDetailLink;
