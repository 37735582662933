import clsx from 'clsx';
import { Props as ButtonProps } from '../../types';
import styles from './styles.module.scss';

type Props = Pick<ButtonProps, 'variant' | 'danger'>;

const ButtonGroupDivider = (props: Props) => {
  const { variant = 'primary', danger } = props;

  const className = clsx(styles.container, {
    [styles[variant]]: true,
    [styles.danger]: danger,
  });

  return <div className={className} />;
};

export default ButtonGroupDivider;
