import { useEffect } from 'react';
import { FieldValues, useForm, DefaultValues } from 'react-hook-form';
import usePrevious from './usePrevious';

const useQuickForm = <Fields extends FieldValues>(args: {
  data: unknown;
  defaultValues: DefaultValues<Fields>;
}) => {
  const { data, defaultValues } = args;

  const form = useForm<Fields>({
    defaultValues,
    mode: 'onChange',
    shouldFocusError: false,
  });

  const prevData = usePrevious(JSON.stringify(data));

  useEffect(() => {
    if (JSON.stringify(data) !== prevData && !Object.keys(form.formState.dirtyFields).length) {
      form.reset(defaultValues);
    }
  }, [data, form, prevData, defaultValues]);

  return { ...form };
};

export default useQuickForm;
