import { GlobalRoleSchema } from '../../api/schemas/globalRoleSchema';
import PageDetailLink from '../../components/PageDetailLink';
import routes from '../../config/routes';

interface Props {
  data: GlobalRoleSchema;
}

const GlobalRoleDetailLink = (props: Props) => {
  const { data } = props;

  const { id, name } = data;

  return <PageDetailLink label={name} to={routes.globalRole({ roleId: id })} />;
};

export default GlobalRoleDetailLink;
