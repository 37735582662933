import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import jsonSchema from './jsonSchema';
import trainerPokerRoomSchema from './trainerPokerRoomSchema';

const globalSuperLeagueSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string(),
  poker_room: trainerPokerRoomSchema,
  extra: jsonSchema,
  is_deleted: z.boolean(),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export type GlobalSuperLeagueSchema = z.infer<typeof globalSuperLeagueSchema>;

export default globalSuperLeagueSchema;
