import clsx from 'clsx';
import { ChangeEventHandler, forwardRef, ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  value: string;
  children?: ReactNode;
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const Radio = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { children, className, ...rest } = props;

  return (
    <label className={clsx(styles.label, className)}>
      <input ref={ref} type="radio" className={styles.input} {...rest} />
      <div className={styles.radio} />
      {children}
    </label>
  );
});

export default Radio;
