import { EventContentArg, FormatterInput } from '@fullcalendar/core';
import { differenceInMinutes, isAfter } from 'date-fns';
import { t } from '../../../i18n';
import { getDate } from '../../../utils/date';

const isCurrentYear = (value: number) => {
  if (value === getDate().getFullYear()) return true;

  return false;
};

const getMonth = (value: number) => {
  if (value === 0) return t('months.january');
  if (value === 1) return t('months.february');
  if (value === 2) return t('months.march');
  if (value === 3) return t('months.april');
  if (value === 4) return t('months.may');
  if (value === 5) return t('months.june');
  if (value === 6) return t('months.july');
  if (value === 7) return t('months.august');
  if (value === 8) return t('months.september');
  if (value === 9) return t('months.october');
  if (value === 10) return t('months.november');

  return t('months.december');
};

export const emptyTooltip = () => ''; // Removes HTML title

export const getEventContent = (info: EventContentArg) => {
  const { startStr, endStr, title } = info.event;

  const getMonthName = (value: string) => {
    if (value === '01') return t('months.january');
    if (value === '02') return t('months.february');
    if (value === '03') return t('months.march');
    if (value === '04') return t('months.april');
    if (value === '05') return t('months.may');
    if (value === '06') return t('months.june');
    if (value === '07') return t('months.july');
    if (value === '08') return t('months.august');
    if (value === '09') return t('months.september');
    if (value === '10') return t('months.october');
    if (value === '11') return t('months.november');

    return t('months.december');
  };

  const startDate = startStr.slice(0, 10);
  const endDate = endStr.slice(0, 10);

  const startDay = startStr.slice(8, 10);
  const endDay = endStr.slice(8, 10);

  const startMonth = getMonthName(startStr.slice(5, 7));
  const endMonth = getMonthName(endStr.slice(5, 7));

  const startTime = startStr.slice(11, 16);
  const endTime = endStr.slice(11, 16);

  if (startDate === endDate) return `${startTime} - ${endTime} ${title}`;

  return `${startMonth} ${startDay}, ${startTime} - ${endMonth} ${endDay}, ${endTime} ${title}`;
};

export const getDayTitle: FormatterInput = (date) => {
  const { day, month: unformattedMonth, year } = date.date;

  const month = getMonth(unformattedMonth);

  if (isCurrentYear(year)) return `${month} ${day}`;

  return `${month} ${day}, ${year}`;
};

export const getWeekTitle: FormatterInput = (date) => {
  if (date.end) {
    const startDay = date.start.day;
    const endDay = date.end.day;

    const startMonth = getMonth(date.start.month);
    const endMonth = getMonth(date.end.month);

    const startYear = date.start.year;
    const endYear = date.end.year;

    if (isCurrentYear(startYear) && isCurrentYear(endYear)) {
      if (startMonth === endMonth) {
        return `${startMonth} ${startDay} - ${endDay}`;
      }

      if (startMonth !== endMonth) {
        return `${startMonth} ${startDay} - ${endMonth} ${endDay}`;
      }
    }

    if (startMonth === endMonth) {
      return `${startMonth} ${startDay} - ${endDay}, ${startYear}`;
    }

    if (startMonth !== endMonth) {
      return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
    }
  }

  return getDayTitle(date);
};

export const getMonthTitle: FormatterInput = (date) => {
  const { month: unformattedMonth, year } = date.date;

  const month = getMonth(unformattedMonth);

  if (isCurrentYear(year)) return month;

  return `${month} ${year}`;
};

export const getListEventTime: FormatterInput = (date) => {
  const formatTo2Digit = (value: number) => {
    if (value >= 0 && value < 10) return `0${value}`;

    return value;
  };

  const start = `${formatTo2Digit(date.start.hour)}:${formatTo2Digit(date.start.minute)}`;

  if (date.end) {
    const end = `${formatTo2Digit(date.end.hour)}:${formatTo2Digit(date.end.minute)}`;

    const timeInterval = `${start} – ${end}`;

    if (timeInterval === '00:00 – 00:00') return t('common.all_day');

    if (timeInterval.endsWith('– 00:00')) return timeInterval.replaceAll('– 00:00', '– 24:00');

    return timeInterval;
  }

  return start;
};

export const isFuture = (value: string) => isAfter(getDate(value), getDate());

export const lessThanDay = (start: Date, end: Date) => {
  const minutesPerHour = 60;
  const hoursPerDay = 24;

  return differenceInMinutes(end, start) <= hoursPerDay * minutesPerHour;
};
