import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { loadCurrentUser } from '../api';
import { WorkspaceSchema } from '../api/schemas/workspaceSchema';
import queryKeys from '../config/queryKeys';
import { useSessionStore } from '../store/session';
import { useUserStore } from '../store/user';
import useWebSocketMessage from './useWebSocketMessage';

const useUser = () => {
  const { data, setUser, setWorkspaceTypes } = useUserStore((state) => ({
    data: state.data,
    setUser: state.setUser,
    setWorkspaceTypes: state.setWorkspaceTypes,
  }));

  const { session } = useSessionStore((state) => ({ session: state.session }));

  const queryKey = [queryKeys.currentUser, data, session];
  const queryFn = () => loadCurrentUser();

  const { data: userData } = useQuery({ queryKey, queryFn, enabled: Boolean(!data && session) });

  useWebSocketMessage<WorkspaceSchema>({
    domain: 'Maintenance',
    onMessage: (message) => {
      if (message.action === 'Edited') {
        const { id, maintenance_types } = message.payload;

        setWorkspaceTypes({ id, types: maintenance_types });
      }
    },
  });

  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [userData, setUser]);
};

export default useUser;
