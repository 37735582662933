import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import workspaceDashboardAccountTotalSchema from '../schemas/workspace-dashboard/workspaceDashboardAccountTotalSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceDashboardAccountsTotal = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/dashboard/accounts-total`,
    { params }
  );

  return workspaceDashboardAccountTotalSchema.parse(response.data);
};

export default loadWorkspaceDashboardAccountsTotal;
