import clsx from 'clsx';
import { useState, ReactNode, useCallback, useRef } from 'react';
import staticIcon from '../../../static/icons';
import PageAddonCollapseError from './PageAddonCollapseError';
import PageAddonCollapseField from './PageAddonCollapseField';
import styles from './styles.module.scss';

interface Props {
  icon?: ReactNode;
  label: string;
  children?: ReactNode;
  defaultOpen?: boolean;
  className?: string;
}

const PageAddonCollapse = (props: Props) => {
  const { icon, label, children, defaultOpen = false, className } = props;

  const [open, setOpen] = useState(defaultOpen);

  const handleClick = () => setOpen((prev) => !prev);

  const ref = useRef<HTMLDivElement | null>(null);

  const callbackRef = useCallback(() => {
    if (open) {
      ref.current?.scrollIntoView({ block: 'nearest' });
    }
  }, [open]);

  return (
    <div ref={ref} className={clsx(styles.container, className)}>
      <button
        type="button"
        className={clsx(styles.toggler, open && styles.open)}
        onClick={handleClick}
      >
        <div className={styles.label}>
          {icon}
          {label}
        </div>
        {staticIcon('chevronDown', 16)}
      </button>
      {open && (
        <div ref={callbackRef} className={styles.content}>
          {children}
        </div>
      )}
    </div>
  );
};

PageAddonCollapse.Error = PageAddonCollapseError;
PageAddonCollapse.Field = PageAddonCollapseField;

export default PageAddonCollapse;
