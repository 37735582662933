import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';
import { WorkspaceUserSchema } from '../schemas/workspaceUserSchema';

interface Payload {
  new_owner_id: WorkspaceUserSchema['id'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const transferWorkspaceOwnership = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/transfer-ownership`,
    payload
  );

  return emptyResponseSchema.parse(response.data);
};

export default transferWorkspaceOwnership;
