import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';
import { WorkspaceTokenSchema } from '../schemas/workspaceTokenSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  tokenId: WorkspaceTokenSchema['id'];
}

const deleteWorkspaceToken = async (args: Args) => {
  const { workspaceId, tokenId } = args;

  const response = await api.delete(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/token/${tokenId}`);

  return emptyResponseSchema.parse(response.data);
};

export default deleteWorkspaceToken;
