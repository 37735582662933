import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import { can } from '../../utils/permissions';
import GlobalWorkspaceDetailAddress from './GlobalWorkspaceDetailAddress';
import GlobalWorkspaceDetailBalance from './GlobalWorkspaceDetailBalance';
import useForm from './useForm';

interface Props {
  data: WorkspaceSchema;
}

const GlobalWorkspaceDetailBillingTab = (props: Props) => {
  const { data } = props;

  const { id } = data;

  const { fields } = useForm({ data });

  return (
    <PageAddon.Fields>
      {fields.hands_billing}
      {fields.allow_negative_balance_pilot}
      {can.workspace.addressRead && <GlobalWorkspaceDetailAddress workspaceId={id} />}
      {can.workspace.balanceRead && <GlobalWorkspaceDetailBalance workspaceId={id} />}
    </PageAddon.Fields>
  );
};

export default GlobalWorkspaceDetailBillingTab;
