import { z } from 'zod';

const currentUserSessionSchema = z.object({
  access_token: z.string(),
  expires_in: z.number(),
  refresh_expires_in: z.number(),
  refresh_token: z.string(),
  token_type: z.string(),
});

export type CurrentUserSessionSchema = z.infer<typeof currentUserSessionSchema>;

export default currentUserSessionSchema;
