import { useDropzone } from 'react-dropzone';
import { MB } from '../../../../constants';
import CONTENT_TYPES from '../../../../constants/CONTENT_TYPES';
import EXTENSIONS from '../../../../constants/EXTENSIONS';
import icon from '../../../../static/icons';
import Files from '../../../../types/Files';
import { handleDrop } from '../../../../utils/files';
import styles from './styles.module.scss';

interface Props {
  onAttach: (files: Files) => void;
}

const AccountTransactionsAttachMedia = (props: Props) => {
  const { onAttach } = props;

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: 3 * MB,
    onDrop: (acceptedFiles) => onAttach(handleDrop(acceptedFiles)),
    accept: { [CONTENT_TYPES['image/*']]: [EXTENSIONS.JPG, EXTENSIONS.JPEG, EXTENSIONS.PNG] },
  });

  return (
    <div {...getRootProps()} className={styles.container}>
      <input {...getInputProps()} />
      {icon('upload', 20)}
    </div>
  );
};

export default AccountTransactionsAttachMedia;
