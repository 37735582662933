import { WorkspaceClubWhitelistSchema } from '../../api/schemas/workspaceClubWhitelistSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Dropdown from '../../components/Dropdown';
import { compact } from '../../utils';
import useDropdown from './useDropdown';

interface Props {
  data: WorkspaceClubWhitelistSchema;
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceClubWhitelistDetailDropdown = (props: Props) => {
  const { title, options } = useDropdown(props);

  const compactOptions = compact([options.delete, options.restore]);

  return <Dropdown title={title} buttonSize="medium" options={compactOptions} />;
};

export default WorkspaceClubWhitelistDetailDropdown;
