import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { workspaceAccountFilterValuesSchema } from '../schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceAccountsFilterValues = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/filter-values`,
    { params }
  );

  return workspaceAccountFilterValuesSchema.parse(response.data);
};

export default loadWorkspaceAccountsFilterValues;
