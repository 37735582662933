import { loadWorkspaceOrderTransactions } from '../../api';
import { OrderSchema } from '../../api/schemas/orderSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import queryKeys from '../../config/queryKeys';
import usePaginatedDataNew from '../../hooks/usePaginatedDataNew';
import OrderTransactions from '../order/OrderTransactions';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  orderId: OrderSchema['id'];
}

const WorkspaceOrderTransactions = (props: Props) => {
  const { workspaceId, orderId } = props;

  const queryKey = [queryKeys.workspaceOrderTransactions({ workspaceId, orderId })];

  const { data, loading, loadingMore, checkIsInView } = usePaginatedDataNew({
    queryKey,
    queryFn: (params) => loadWorkspaceOrderTransactions({ workspaceId, orderId }, params),
  });

  return (
    <OrderTransactions
      data={data}
      loading={loading}
      loadingMore={loadingMore}
      checkIsInView={checkIsInView}
    />
  );
};

export default WorkspaceOrderTransactions;
