import api from '../../services/api';
import { GlobalAccountSchema } from '../schemas/account/globalAccountSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { LobbyTableSchema } from '../schemas/lobbyTableSchema';

interface Args {
  accountId: GlobalAccountSchema['id'];
  tableId: LobbyTableSchema['id'];
}

const closeGlobalAccountTable = async (args: Args) => {
  const { accountId, tableId } = args;

  const response = await api.post(`/api/lobby/account/${accountId}/leave-table/${tableId}`);

  return emptyResponseSchema.parse(response.data);
};

export default closeGlobalAccountTable;
