import { z } from 'zod';
import accountCommonBalanceSchema from './accountCommonBalanceSchema';

const accountTransactionBalanceSchema = z.object({
  amount: accountCommonBalanceSchema.shape.amount,
  currency: accountCommonBalanceSchema.shape.currency,
  league_id: accountCommonBalanceSchema.shape.league_id.or(z.null()),
  league_name: accountCommonBalanceSchema.shape.league_name.or(z.null()),
  club_id: accountCommonBalanceSchema.shape.club_id.or(z.null()),
  club_name: accountCommonBalanceSchema.shape.club_name.or(z.null()),
  table_id: accountCommonBalanceSchema.shape.table_id.or(z.null()),
});

export default accountTransactionBalanceSchema;
