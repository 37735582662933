import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import workspaceAddressSchema from '../schemas/workspaceAddressSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceAddress = async (args: Args) => {
  const { workspaceId } = args;

  const response = await api.get(`/api/global/${ALIASES.WORKSPACE.L}/${workspaceId}/address`);

  return workspaceAddressSchema.parse(response.data);
};

export default loadWorkspaceAddress;
