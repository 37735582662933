import { z } from 'zod';
import dateTimeSchema from '../dateTimeSchema';
import fileSchema from '../fileSchema';
import globalUserSchema from '../globalUserSchema';
import accountTransactionBalanceSchema from './accountTransactionBalanceSchema';
import { accountCommonSchema } from '.';

export const INITIATOR = {
  UNKNOWN: 0,
  TRAINER: 1,
  PLATFORM: 2,
  USER: 3,
} as const;

export const TYPE = {
  UNKNOWN: 0,
  DEPOSIT: 1,
  WITHDRAW: 2,
  GAME_RESULT: 3,
  ADJUSTMENT: 4,
  FEE: 5,
  TRANSFER: 6,
  REWARD: 7,
} as const;

export const OBJECT = {
  UNKNOWN: 0,
  LOBBY: 1,
  TABLE: 2,
  CLUB: 3,
  LEAGUE: 4,
} as const;

export const STATUS = {
  UNKNOWN: 0,
  PENDING: 1,
  COMPLETED: 2,
  CANCELED: 3,
} as const;

const deltaSchema = z.number();

const authorizerSchema = z.object({
  id: globalUserSchema.shape.id,
  login: globalUserSchema.shape.username,
});

const correctionsSchema = z.object({
  old_delta: deltaSchema,
  new_delta: deltaSchema,
  comment: z.string(),
});

const accountTransactionSchema = z.object({
  id: z.string(),
  account_id: accountCommonSchema.shape.id,
  initiator: z.nativeEnum(INITIATOR),
  trx_type: z.nativeEnum(TYPE),
  trx_object: z.nativeEnum(OBJECT),
  trx_status: z.nativeEnum(STATUS),
  trainer_seq: z.number(),
  delta: deltaSchema,
  old_balance: accountTransactionBalanceSchema,
  new_balance: accountTransactionBalanceSchema,
  user_id: globalUserSchema.shape.id.or(z.null()),
  user_login: globalUserSchema.shape.username.or(z.null()),
  user_message: z.string().or(z.null()),
  files: z.array(fileSchema),
  authorizer: authorizerSchema.or(z.null()),
  corrections: correctionsSchema.or(z.null()),
  finalized_on: dateTimeSchema.or(z.null()),
  executed_on: dateTimeSchema,
});

export default accountTransactionSchema;
