import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceStageSchema } from '../schemas/stage/workspaceStageSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = WorkspaceStageSchema;

interface Payload {
  name?: Schema['name'];
  is_playable?: Schema['is_playable'];
  clear_channel?: Schema['clear_channel'];
  clear_schedule?: Schema['clear_schedule'];
  order?: Schema['order'];
  columns?: number[];
  default_sorting?: Schema['default_sorting'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  stageId: WorkspaceStageSchema['id'];
  payload: Payload;
}

const updateWorkspaceAccountsStage = async (args: Args) => {
  const { workspaceId, stageId, payload } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/stage/${stageId}`,
    payload
  );

  return emptyResponseSchema.parse(response.data);
};

export default updateWorkspaceAccountsStage;
