import api from '../../services/api';
import botSettingSchema, { BotSettingSchema } from '../schemas/botSettingSchema';

type Schema = BotSettingSchema;

interface Payload {
  name: Schema['name'];
  bot_code: Schema['bot_code'];
  bot_build: Schema['bot_build'];
  game_type: Schema['game_type'];
  description?: Schema['description'];
  bot_build_straddle?: Schema['bot_build_straddle'];
  bot_code_tight?: Schema['bot_code_tight'];
  bot_build_tight?: Schema['bot_build_tight'];
  params_size?: Schema['params_size'];
  params_size_hu?: Schema['params_size_hu'];
  maintenance_id?: Exclude<Schema['maintenance'], null>['id'];
}

interface Args {
  payload: Payload;
}

const createGlobalBotSetting = async (args: Args) => {
  const { payload } = args;

  const response = await api.post('/api/bot-settings', payload);

  return botSettingSchema.pick({ id: true }).parse(response.data);
};

export default createGlobalBotSetting;
