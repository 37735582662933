import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadGlobalSeatControlRulesFilterValues } from '../../api';
import Filter from '../../components/Filter';
import queryKeys from '../../config/queryKeys';
import useFilter from '../../hooks/useFilter';
import useURL from '../../hooks/useURL';

interface Fields {
  maintenance_in: string[];
  created_ge: string;
  created_le: string;
  updated_ge: string;
  updated_le: string;
  show_deleted: boolean;
}

interface Props {
  total?: number;
}

const GlobalSeatControlRuleFilter = (props: Props) => {
  const { total } = props;

  const { t } = useTranslation();
  const { urlParams } = useURL();

  const queryKey = [queryKeys.globalSeatControlRulesFilterValues, urlParams.show_deleted];
  const queryFn = () =>
    loadGlobalSeatControlRulesFilterValues({ show_deleted: urlParams.show_deleted });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  const filter = useFilter<Fields>({
    maintenance_in: 'multi',
    created_ge: 'single',
    created_le: 'single',
    updated_ge: 'single',
    updated_le: 'single',
    show_deleted: 'toggle',
  });

  const { values, setValue, clearValue } = filter;

  const { maintenance_in, created_ge, created_le, updated_ge, updated_le, show_deleted } = values;

  const filterProps = { total, filter };

  if (isPending) {
    return (
      <Filter {...filterProps}>
        <Filter.Placeholder />
      </Filter>
    );
  }

  if (!data) return null;

  const { maintenance } = data;

  return (
    <Filter {...filterProps}>
      <div>
        {maintenance.length !== 0 && (
          <Filter.Collapse
            label={t('common.workspace')}
            count={maintenance_in.length}
            onClear={clearValue('maintenance_in')}
          >
            <Filter.MultiCheckbox
              config={maintenance.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('maintenance_in'),
                checked: maintenance_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        <Filter.Collapse
          label={t('common.modified')}
          {...((updated_ge || updated_le) && {
            onClear: clearValue(['updated_ge', 'updated_le']),
          })}
        >
          <Filter.Date
            from={{ value: updated_ge, onChange: setValue('updated_ge') }}
            to={{ value: updated_le, onChange: setValue('updated_le') }}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.created')}
          {...((created_ge || created_le) && {
            onClear: clearValue(['created_ge', 'created_le']),
          })}
        >
          <Filter.Date
            from={{ value: created_ge, onChange: setValue('created_ge') }}
            to={{ value: created_le, onChange: setValue('created_le') }}
          />
        </Filter.Collapse>
      </div>
      <Filter.Switcher checked={show_deleted} onChange={setValue('show_deleted')}>
        {t('common.show_deleted')}
      </Filter.Switcher>
    </Filter>
  );
};

export default GlobalSeatControlRuleFilter;
