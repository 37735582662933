import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { OBJECT } from '../../../api/schemas/account/accountTransactionSchema';
import routes from '../../../config/routes';
import { RouteParams } from '../../../utils/generics';
import { CommonAccountTransactionSchema } from '../types';

interface Fields {
  search: string;
  trx_type_in: CommonAccountTransactionSchema['trx_type'][];
}

type Params =
  | RouteParams<typeof routes.globalAccountTransactions>
  | RouteParams<typeof routes.workspaceAccountTransactions>;

const useAccountTransactionsFilter = () => {
  const { type, id } = useParams<Params>();

  const form = useForm<Fields>({
    defaultValues: {
      search: '',
      trx_type_in: [],
    },
  });

  const { watch } = form;

  const { search, trx_type_in } = watch();

  return {
    urlParams: {
      ...(type === 'table' && { trx_object_eq: String(OBJECT.TABLE), table_id: id }),
      ...(type === 'club' && { trx_object_eq: String(OBJECT.CLUB), club_id: id }),
      ...(type === 'league' && { trx_object_eq: String(OBJECT.LEAGUE), league_id: id }),
      ...(type === 'lobby' && { trx_object_eq: String(OBJECT.LOBBY) }),
      ...(search && { search }),
      ...(trx_type_in.length && { trx_type_in: String(trx_type_in) }),
    },
    form,
  };
};

export default useAccountTransactionsFilter;
