import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceSchema } from '../schemas/workspaceSchema';
import workspaceUserSchema, { WorkspaceUserSchema } from '../schemas/workspaceUserSchema';

type Schema = WorkspaceUserSchema;

interface Args {
  workspaceId: WorkspaceSchema['id'];
  userId: Schema['id'];
}

const loadWorkspaceUser = async (args: Args) => {
  const { workspaceId, userId } = args;

  const response = await api.get(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/user/${userId}`);

  return workspaceUserSchema.parse(response.data);
};

export default loadWorkspaceUser;
