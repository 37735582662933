import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { loadGlobalClubs, updateGlobalWorkspace } from '../../api';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import LinkButton from '../../components/LinkButton';
import PageAddon from '../../components/PageAddon';
import Select, { Options } from '../../components/Select';
import useQuickForm from '../../hooks/useQuickForm';
import useURL from '../../hooks/useURL';
import { handleError } from '../../utils/form';
import { can } from '../../utils/permissions';
import { useGlobalWorkspaceClubsQuery } from './queries';

interface Fields {
  club_ids: Options;
}

interface Props {
  workspaceId: WorkspaceSchema['id'];
  getClubRoute?: (args: { clubId: GlobalClubSchema['id'] }) => string;
}

const GlobalWorkspaceDetailClubsTab = (props: Props) => {
  const { workspaceId, getClubRoute } = props;

  const disabled = !can.club.createUpdate;

  const { navigate } = useURL();

  const [search, setSearch] = useState('');

  const { data, loading } = useGlobalWorkspaceClubsQuery({ workspaceId, search });

  const form = useQuickForm<Fields>({
    data,
    defaultValues: { club_ids: data.map((item) => ({ value: item.id, label: item.name })) },
  });

  const { watch, setValue, handleSubmit } = form;

  const onSubmit = async (values: Fields) => {
    try {
      await updateGlobalWorkspace({
        workspaceId,
        payload: { club_ids: values.club_ids.map((item) => item.value) },
      });
    } catch (error) {
      handleError({ error, form });
    }
  };

  const clubIds = watch('club_ids');

  return (
    <FormProvider {...form}>
      <PageAddon.List
        loading={loading}
        search={search}
        onSearch={setSearch}
        controls={
          <Select.Async
            onLoad={async (args) => {
              const response = await loadGlobalClubs(args);

              const options = response.items.map((item) => ({
                value: item.id,
                label: item.name,
                caption: item.code,
              }));

              return options;
            }}
          >
            {({ options, ...selectAsyncProps }) => (
              <Select.Quick
                name="club_ids"
                popupSize="auto"
                options={options.filter(
                  (item) => !clubIds.map((el) => el.value).includes(item.value)
                )}
                onChange={(value) => {
                  setValue('club_ids', value ? [...clubIds, value] : []);

                  handleSubmit(onSubmit)();
                }}
                {...selectAsyncProps}
              >
                <PageAddon.List.Add disabled={disabled} />
              </Select.Quick>
            )}
          </Select.Async>
        }
      >
        {data.map((item) => (
          <PageAddon.List.Item
            key={item.id}
            disabled={disabled}
            title={item.name}
            subTitle={item.code}
            onDelete={() => {
              setValue(
                'club_ids',
                clubIds.filter((el) => el.value !== item.id)
              );

              handleSubmit(onSubmit)();
            }}
          >
            {getClubRoute && (
              <LinkButton
                size="small"
                variant="transparent"
                onClick={() => navigate(getClubRoute({ clubId: item.id }))}
              />
            )}
          </PageAddon.List.Item>
        ))}
      </PageAddon.List>
    </FormProvider>
  );
};

export default GlobalWorkspaceDetailClubsTab;
