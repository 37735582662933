import { z } from 'zod';

const workspaceAccountsBuyCountrySchema = z.object({
  country_code: z.string(),
  count: z.number(),
});

export type WorkspaceAccountsBuyCountrySchema = z.infer<typeof workspaceAccountsBuyCountrySchema>;

export default workspaceAccountsBuyCountrySchema;
