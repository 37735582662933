import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import WorkspaceAccountDetail from '../../features/workspace-account/WorkspaceAccountDetail';
import WorkspaceAccountLoader from '../../features/workspace-account/WorkspaceAccountLoader';
import WorkspaceIdentityDetail from '../../features/workspace-identity/WorkspaceIdentityDetail';
import WorkspaceIdentityForm from '../../features/workspace-identity/WorkspaceIdentityForm';
import WorkspaceIdentityLoader from '../../features/workspace-identity/WorkspaceIdentityLoader';
import WorkspaceIdentitiesPage from '../../pages/WorkspaceIdentitiesPage';
import Route from './Route';

const page = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceIdentitiesPage workspaceId={workspaceId} />}
  </Route>
);

const createForm = (
  <Route params={['workspaceId']}>
    {({ workspaceId, navigate }) => (
      <WorkspaceIdentityForm
        workspaceId={workspaceId}
        onClose={() => navigate(routes.workspaceIdentities({ workspaceId }))}
        onCreate={({ identityId }) =>
          navigate(routes.workspaceIdentity({ workspaceId, identityId }))
        }
      />
    )}
  </Route>
);

const detail = (
  <Route params={['workspaceId', 'identityId']}>
    {({ workspaceId, identityId, navigate }) => (
      <WorkspaceIdentityLoader workspaceId={workspaceId} identityId={identityId}>
        {({ data }) => (
          <WorkspaceIdentityDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceIdentities({ workspaceId }))}
            onEdit={() => navigate(routes.workspaceIdentityEdit({ workspaceId, identityId }))}
            getAccountRoute={({ accountId }) =>
              routes.workspaceIdentityAccount({ workspaceId, identityId, accountId })
            }
          />
        )}
      </WorkspaceIdentityLoader>
    )}
  </Route>
);

const detailAccount = (
  <Route params={['workspaceId', 'identityId', 'accountId']}>
    {({ workspaceId, identityId, accountId, navigate }) => (
      <WorkspaceAccountLoader workspaceId={workspaceId} accountId={accountId}>
        {({ data }) => (
          <WorkspaceAccountDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceIdentity({ workspaceId, identityId }))}
          />
        )}
      </WorkspaceAccountLoader>
    )}
  </Route>
);

const editForm = (
  <Route params={['workspaceId', 'identityId']}>
    {({ workspaceId, identityId, navigate }) => (
      <WorkspaceIdentityLoader workspaceId={workspaceId} identityId={identityId}>
        {({ data }) => (
          <WorkspaceIdentityForm
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceIdentity({ workspaceId, identityId }))}
            onEdit={() => navigate(routes.workspaceIdentity({ workspaceId, identityId }))}
          />
        )}
      </WorkspaceIdentityLoader>
    )}
  </Route>
);

const workspaceIdentitiesPage: RouteObject = {
  path: routes.workspaceIdentities({ workspaceId: ':workspaceId' }),
  element: page,
  children: [
    {
      path: routes.workspaceIdentitiesCreate({ workspaceId: ':workspaceId' }),
      element: createForm,
    },
    {
      path: routes.workspaceIdentity({
        workspaceId: ':workspaceId',
        identityId: ':identityId',
      }),
      element: detail,
      children: [
        {
          path: routes.workspaceIdentityAccount({
            workspaceId: ':workspaceId',
            identityId: ':identityId',
            accountId: ':accountId',
          }),
          element: detailAccount,
        },
      ],
    },
    {
      path: routes.workspaceIdentityEdit({
        workspaceId: ':workspaceId',
        identityId: ':identityId',
      }),
      element: editForm,
    },
  ],
};

export default workspaceIdentitiesPage;
