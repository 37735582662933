import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { WorkspaceUserSchema } from '../../api/schemas/workspaceUserSchema';
import LinkButton from '../../components/LinkButton';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import queryKeys from '../../config/queryKeys';
import useURL from '../../hooks/useURL';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import icon from '../../static/icons';
import { formatAbsoluteDate } from '../../utils/date';
import {
  createInfinitePaginatedQueryData,
  deleteInfinitePaginatedQueryData,
} from '../../utils/queryClient';
import { useGlobalWorkspaceUsersQuery } from './queries';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  getUserRoute?: (args: { userId: WorkspaceUserSchema['id'] }) => string;
}

const GlobalWorkspaceDetailUsersTab = (props: Props) => {
  const { workspaceId, getUserRoute } = props;

  const { t } = useTranslation();
  const { navigate } = useURL();

  const [search, setSearch] = useState('');

  const queryKey = [queryKeys.globalWorkspaceUsers({ workspaceId }), search];

  const { data, loading, checkIsInView } = useGlobalWorkspaceUsersQuery({ workspaceId, search });

  useWebSocketMessage<{
    id: WorkspaceSchema['id'];
    participant: WorkspaceUserSchema;
    modified_on: WorkspaceSchema['modified_on'];
  }>({
    domain: 'GlobalMaintenance',
    onMessage: (message) => {
      const payload = message.payload.participant;

      if (message.action === 'ParticipantAdded') {
        createInfinitePaginatedQueryData(queryKey, payload);
      }

      if (message.action === 'ParticipantRemoved') {
        deleteInfinitePaginatedQueryData(queryKey, (item) => item.id === payload.id);
      }
    },
    enabled: (message) => message.payload.id === workspaceId,
  });

  return (
    <PageAddon.List loading={loading} search={search} onSearch={setSearch}>
      {data.map((item, index) => (
        <PageAddon.List.Item
          key={item.id}
          title={item.username}
          subTitle={formatAbsoluteDate(item.invited_on)}
          {...checkIsInView(index)}
        >
          {item.is_owner && <Tooltip label={t('common.owner')}>{icon('crown', 16)}</Tooltip>}
          {getUserRoute && (
            <LinkButton
              size="small"
              variant="transparent"
              onClick={() => navigate(getUserRoute({ userId: item.id }))}
            />
          )}
        </PageAddon.List.Item>
      ))}
    </PageAddon.List>
  );
};

export default GlobalWorkspaceDetailUsersTab;
