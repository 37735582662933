import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import { WorkspaceTokenSchema } from '../../../api/schemas/workspaceTokenSchema';
import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import icon from '../../../static/icons';
import WorkspaceSettingsEmpty from '../WorkspaceSettingsEmpty';
import WorkspaceSettingsFieldGroup from '../WorkspaceSettingsFieldGroup';
import WorkspaceSettingsTokensItem from './WorkspaceSettingsTokensItem';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: WorkspaceTokenSchema[];
}

const WorkspaceSettingsTokens = (props: Props) => {
  const { workspaceId, data } = props;

  const { t } = useTranslation();

  const [creating, setCreating] = useState(false);

  const create = () => setCreating(true);

  if (!data.length && !creating) {
    return (
      <WorkspaceSettingsEmpty buttonText={t('common.token')} onClick={create}>
        {t('sentences.settings_tokens_empty')}
      </WorkspaceSettingsEmpty>
    );
  }

  const commonProps = { workspaceId };

  return (
    <WorkspaceSettingsFieldGroup
      renderBefore={
        <Alert
          type="info"
          title={t('common.how_to_use_it')}
          description={t('sentences.copy_the_token')}
          closable={false}
        />
      }
      renderAfter={
        <Button
          size="small"
          variant="secondary"
          disabled={creating}
          icon={icon('add', 16)}
          onClick={create}
        >
          {t('common.token')}
        </Button>
      }
    >
      {data.map((item) => (
        <WorkspaceSettingsTokensItem key={item.id} data={item} {...commonProps} />
      ))}
      {creating && (
        <WorkspaceSettingsTokensItem onRemove={() => setCreating(false)} {...commonProps} />
      )}
    </WorkspaceSettingsFieldGroup>
  );
};

export default WorkspaceSettingsTokens;
