import api from '../../services/api';
import URLParams from '../../types/URLParams';
import notificationsSettingSchema from '../schemas/notificationsSettingSchema';
import paginationSchemaNew from '../schemas/paginationSchemaNew';

const loadNotificationsSettings = async (params?: URLParams) => {
  const response = await api.get('/api/notification/settings', { params });

  return paginationSchemaNew(notificationsSettingSchema).parse(response.data);
};

export default loadNotificationsSettings;
