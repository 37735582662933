import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { CombinatorSelectorProps } from 'react-querybuilder';
import styles from './styles.module.scss';

const QueryBuilderCombinatorSelector = (props: CombinatorSelectorProps) => {
  const { value, handleOnChange } = props;

  const { t } = useTranslation();

  return (
    <>
      {value === 'and' && (
        <button
          type="button"
          className={clsx(styles.button, styles.and)}
          onClick={() => handleOnChange('or')}
          data-testid="and-combinator"
        >
          {t('common.and')}
        </button>
      )}
      {value === 'or' && (
        <button
          type="button"
          className={clsx(styles.button, styles.or)}
          onClick={() => handleOnChange('and')}
          data-testid="or-combinator"
        >
          {t('common.or')}
        </button>
      )}
    </>
  );
};

export default QueryBuilderCombinatorSelector;
