import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Form from '../../../../components/Form';
import Input from '../../../../components/Input';
import SubmitButton from '../../../../components/SubmitButton';
import Textarea from '../../../../components/Textarea';
import icon from '../../../../static/icons';
import { convertCentsToDollars, convertDollarsToCents } from '../../../../utils/data';
import { bigNumber, required } from '../../../../utils/form';
import notify from '../../../../utils/notify';
import { CommonAccountTransactionSchema } from '../../types';
import { OnFinalize } from '../types';
import styles from './styles.module.scss';

interface Fields {
  delta: string;
  comment: string;
}

interface Props {
  data: CommonAccountTransactionSchema;
  onFinalize: OnFinalize;
}

const AccountTransactionsForm = (props: Props) => {
  const { data, onFinalize } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: {
      delta: String(convertCentsToDollars(data.delta)),
      comment: '',
    },
  });

  const {
    control,
    register,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    const delta = Number(values.delta);
    const formattedDelta = delta > 0 ? -delta : delta;

    await onFinalize({
      transactionId: data.id,
      delta: convertDollarsToCents(formattedDelta),
      comment: values.comment,
    });

    notify('success', { title: t('sentences.changes_have_been_saved') });
  };

  return (
    <Form form={form} className={styles.form} onSubmit={onSubmit}>
      <div>
        <span>{t('common.complete_transaction')}</span>
        <Controller
          name="delta"
          control={control}
          rules={{ validate: { required, max: bigNumber } }}
          render={({ field }) => (
            <Input
              size="small"
              placeholder={t('common.amount')}
              format={{
                prefix: '-',
                decimalScale: 2,
                allowNegative: false,
                onValueChange: field.onChange,
              }}
              error={errors.delta?.message}
              {...field}
            />
          )}
        />
        <Textarea
          maxLength={0}
          placeholder={t('common.comment')}
          className={styles.textarea}
          error={errors.comment?.message}
          {...register('comment', { validate: required })}
        />
      </div>
      <SubmitButton size="small" icon={icon('checkCircle', 16)}>
        {t('common.complete')}
      </SubmitButton>
    </Form>
  );
};

export default AccountTransactionsForm;
