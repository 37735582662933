import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import routes from '../../config/routes';
import useRerender from '../../hooks/useRerender';
import useTimeZone from '../../hooks/useTimeZone';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';
import { useUserStore } from '../../store/user';
import { addWhen } from '../../utils';
import { logoutUser } from '../../utils/auth';
import Dropdown, { presets } from '../Dropdown';
import styles from './styles.module.scss';

const User = () => {
  useRerender({ interval: 1000 });

  const { t } = useTranslation();
  const user = useUserStore();
  const { time, options } = useTimeZone();
  const { navigate } = useURL();
  const { pathname } = useLocation();

  const [view, setView] = useState<'default' | 'timeZone'>('default');

  const defaultView = view === 'default';

  const getOptions = () => {
    if (defaultView) {
      return [
        {
          ...presets.more,
          icon: icon('clock', 20),
          label: time,
          onClick: () => setView('timeZone'),
        },
        ...addWhen(
          {
            icon: icon(pathname === routes.storybook ? 'home' : 'star', 20),
            label: pathname === routes.storybook ? 'Home' : 'Storybook',
            onClick: () =>
              navigate(pathname === routes.storybook ? routes.home : routes.storybook, {
                params: false,
              }),
          },
          window.ENV === 'dev'
        ),
        {
          icon: icon('logOut', 20),
          label: t('common.log_out'),
          danger: true,
          onClick: logoutUser,
        },
      ];
    }

    return [
      {
        ...presets.back(),
        onClick: () => setView('default'),
      },
      { divider: true },
      {
        render: () => (
          <div className={styles.option}>{t('sentences.time_zone_settings_affect_all_dates')}</div>
        ),
      },
      ...options,
    ];
  };

  return (
    <Dropdown
      {...(defaultView && {
        addon: (
          <div className={styles.addon}>
            <span>{user.data?.username}</span>
          </div>
        ),
      })}
      options={getOptions()}
      className={styles.dropdown}
    >
      {({ open }) => (
        <button type="button" className={clsx(styles.avatar, open && styles.open)}>
          {icon('user', 16)}
        </button>
      )}
    </Dropdown>
  );
};

export default User;
