import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Confirm from '../../../components/Confirm';
import Tooltip from '../../../components/Tooltip';
import icon from '../../../static/icons';
import styles from './styles.module.scss';

interface Props {
  loading: boolean;
  canLeaveTable: boolean;
  onClose: () => void;
}

const AccountCloseTable = (props: Props) => {
  const { loading, canLeaveTable, onClose } = props;

  const { t } = useTranslation();

  return (
    <Tooltip label={!canLeaveTable && t('common.no_permission')} hideOnMobile={canLeaveTable}>
      <Confirm onConfirm={onClose} confirmText={t('common.close')}>
        {({ confirming }) => (
          <Button
            danger
            focused={confirming}
            loading={loading}
            className={styles.button}
            variant="transparent"
            icon={icon('logOut', 16)}
            disabled={!canLeaveTable}
          >
            {t('common.close_table')}
          </Button>
        )}
      </Confirm>
    </Tooltip>
  );
};

export default AccountCloseTable;
