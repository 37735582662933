import { useTranslation } from 'react-i18next';
import Page from '../../components/Page';
import Stub from '../../components/Stub';
import { useUserStore } from '../../store/user';
import WorkspaceDashboard from './WorkspaceDashboard';

const HomePage = () => {
  const { t } = useTranslation();

  const { workspace } = useUserStore();

  if (!workspace) {
    return (
      <Page search={false} filter={false} heading={t('common.dashboard')}>
        <Stub title={t('sentences.page_will_available')} />
      </Page>
    );
  }

  return <WorkspaceDashboard workspace={workspace} />;
};

export default HomePage;
