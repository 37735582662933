import clsx from 'clsx';
import { memo } from 'react';
import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import Id from '../../../types/Id';
import TableRowSelect from '../TableRowSelect';
import { useTableStoreContext } from '../TableStoreContext';
import { Column } from '../types';
import styles from './styles.module.scss';

interface Props<T> {
  data: T;
  column: Column<T>;
}

const TableBodyCell = memo(
  <T extends Id>(props: Props<T>) => {
    const { data, column } = props;

    const { key, pin, align, select, resize, style, render } = column;

    const store = useTableStoreContext();

    const { scrolled, scrolledToEnd, resizedWidth } = useStoreWithEqualityFn(
      store,
      (state) => {
        const width = resize && key && state.columnSizes && state.columnSizes[key];

        return {
          ...(width && { resizedWidth: width }),
          ...(pin && { scrolled: state.scrolled, scrolledToEnd: state.scrolledToEnd }),
        };
      },
      shallow
    );

    const className = clsx(styles.container, {
      [styles.alignCenter]: align === 'center',
      [styles.alignRight]: align === 'right',
      [styles.pinLeft]: pin === 'left',
      [styles.pinRight]: pin === 'right',
      [styles.withLeftShadow]: scrolled,
      [styles.withRightShadow]: column.controls && !scrolledToEnd,
    });

    return (
      <div
        className={className}
        data-pin-left={pin === 'left'}
        data-pin-right={pin === 'right'}
        style={{ ...style, ...(resizedWidth && { width: `${resizedWidth}px` }) }}
      >
        {select && <TableRowSelect id={data.id} />}
        {render({ values: data })}
      </div>
    );
  },
  (prevProps, newProps) => JSON.stringify(prevProps.data) === JSON.stringify(newProps.data)
);

export default TableBodyCell;
