export default {
  CSV: '.csv',
  DOC: '.doc',
  DOCX: '.docx',
  JPG: '.jpg',
  JPEG: '.jpeg',
  PDF: '.pdf',
  PNG: '.png',
  TXT: '.txt',
  XSL: '.xsl',
  XSLX: '.xslx',
  ZIP: '.zip',
  JSON: '.json',
};
