import { z } from 'zod';
import api from '../services/api';
import URLParams from '../types/URLParams';
import geonamesCitySchema from './schemas/geonamesCitySchema';

const geonamesSearchCity = async (params?: URLParams) => {
  const response = await api.get('/api/geonames/search', { params });

  return z
    .object({ geonames: z.array(geonamesCitySchema), total: z.number() })
    .parse(response.data);
};

export default geonamesSearchCity;
