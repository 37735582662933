import { useTranslation } from 'react-i18next';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import Tooltip from '../../../components/Tooltip';
import icon from '../../../static/icons';
import styles from './styles.module.scss';

interface Props {
  data: Exclude<WorkspaceAccountSchema['stage'], null>;
}

const WorkspaceAccountStageTooltip = (props: Props) => {
  const { data } = props;

  const { t } = useTranslation();

  const { is_playable, stop_current_session, clear_channel, clear_schedule } = data;

  const getIcon = (value: boolean) =>
    value
      ? icon('check', 16, { className: styles.filled })
      : icon('cross', 16, { className: styles.unfilled });

  return (
    <Tooltip.FieldGroup>
      <Tooltip.Field label={t('sentences.start_planned_sessions')}>
        {getIcon(is_playable)}
      </Tooltip.Field>
      <Tooltip.Field label={t('sentences.stop_active_session')}>
        {getIcon(stop_current_session)}
      </Tooltip.Field>
      <Tooltip.Field label={t('sentences.remove_channels')}>{getIcon(clear_channel)}</Tooltip.Field>
      <Tooltip.Field label={t('sentences.remove_planned_sessions')}>
        {getIcon(clear_schedule)}
      </Tooltip.Field>
    </Tooltip.FieldGroup>
  );
};

export default WorkspaceAccountStageTooltip;
