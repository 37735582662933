import { useQuery } from '@tanstack/react-query';
import {
  deleteWorkspaceAccountSession,
  loadWorkspaceAccountSession,
  updateWorkspaceAccountSession,
} from '../../api';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSessionSchema } from '../../api/schemas/session/workspaceSessionSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import queryKeys from '../../config/queryKeys';
import AccountScheduleDialogForm from '../account/AccountSchedule/AccountScheduleDialogForm';
import AccountScheduleDialogSpinner from '../account/AccountSchedule/AccountScheduleDialogSpinner';

interface Props {
  sessionId: WorkspaceSessionSchema['id'];
  account: WorkspaceAccountSchema;
  onClose: () => void;
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceAccountScheduleDialog = (props: Props) => {
  const { sessionId, account, onClose, workspaceId } = props;

  const { id: accountId } = account;

  const queryKey = [
    queryKeys.workspaceAccountScheduleSession({
      workspaceId,
      accountId,
      sessionId,
    }),
  ];

  const queryFn = () =>
    loadWorkspaceAccountSession({
      workspaceId,
      accountId,
      sessionId,
    });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  if (isPending) return <AccountScheduleDialogSpinner />;

  if (!data) return null;

  return (
    <AccountScheduleDialogForm
      data={data}
      account={account}
      canDelete
      onDelete={() =>
        deleteWorkspaceAccountSession({
          workspaceId,
          accountId,
          sessionId,
        })
      }
      onUpdate={(payload) =>
        updateWorkspaceAccountSession({ workspaceId, accountId, sessionId, payload })
      }
      onClose={onClose}
    />
  );
};

export default WorkspaceAccountScheduleDialog;
