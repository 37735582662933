import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { OrderSchema } from '../schemas/orderSchema';
import orderTransactionSchema from '../schemas/orderTransactionSchema';
import paginationSchema from '../schemas/paginationSchema';

interface Args {
  orderId: OrderSchema['id'];
}

const loadGlobalOrderTransactions = async (args: Args, params?: URLParams) => {
  const { orderId } = args;

  const response = await api.get(`/api/order/${orderId}/transaction`, { params });

  return paginationSchema(orderTransactionSchema).parse(response.data);
};

export default loadGlobalOrderTransactions;
