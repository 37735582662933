import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalAccountDetail from '../../features/global-account/GlobalAccountDetail';
import GlobalAccountLoader from '../../features/global-account/GlobalAccountLoader';
import GlobalClubDeleteError from '../../features/global-club/GlobalClubDeleteError';
import GlobalClubDetail from '../../features/global-club/GlobalClubDetail';
import GlobalClubForm from '../../features/global-club/GlobalClubForm';
import GlobalClubLoader from '../../features/global-club/GlobalClubLoader';
import GlobalClubNotes from '../../features/global-club/GlobalClubNotes';
import GlobalLeagueDetail from '../../features/global-league/GlobalLeagueDetail';
import GlobalLeagueForm from '../../features/global-league/GlobalLeagueForm';
import GlobalLeagueLoader from '../../features/global-league/GlobalLeagueLoader';
import GlobalSuperLeagueDetail from '../../features/global-super-league/GlobalSuperLeagueDetail';
import GlobalSuperLeagueForm from '../../features/global-super-league/GlobalSuperLeagueForm';
import GlobalSuperLeagueLoader from '../../features/global-super-league/GlobalSuperLeagueLoader';
import GlobalWorkspaceDetail from '../../features/global-workspace/GlobalWorkspaceDetail';
import GlobalWorkspaceLoader from '../../features/global-workspace/GlobalWorkspaceLoader';
import GlobalClubsPage from '../../pages/GlobalClubsPage';
import Notes from '../Notes';
import Route from './Route';

const page = <GlobalClubsPage />;

const createForm = (
  <Route>
    {({ navigate }) => (
      <GlobalClubForm
        onClose={() => navigate(routes.globalClubs)}
        onCreate={({ clubId }) => navigate(routes.globalClub({ clubId }))}
      />
    )}
  </Route>
);

const detail = (
  <Route params={['clubId']}>
    {({ clubId, navigate }) => (
      <GlobalClubLoader clubId={clubId}>
        {({ data }) => (
          <GlobalClubDetail
            data={data}
            onClose={() => navigate(routes.globalClubs)}
            onEdit={() => navigate(routes.globalClubEdit({ clubId }))}
            getNotesRoute={() => navigate(routes.globalClubNotes({ clubId }))}
            getLeagueRoute={({ leagueId }) => routes.globalClubLeague({ clubId, leagueId })}
            getWorkspaceRoute={({ workspaceId }) =>
              routes.globalClubWorkspace({ clubId, workspaceId })
            }
          />
        )}
      </GlobalClubLoader>
    )}
  </Route>
);

const detailNotes = (
  <Route params={['clubId']}>
    {({ clubId, navigate }) => (
      <GlobalClubNotes clubId={clubId}>
        {(loader) => <Notes onClose={() => navigate(routes.globalClub({ clubId }))} {...loader} />}
      </GlobalClubNotes>
    )}
  </Route>
);

const detailLeague = (
  <Route params={['clubId', 'leagueId']}>
    {({ clubId, leagueId, navigate }) => (
      <GlobalLeagueLoader leagueId={leagueId}>
        {({ data }) => (
          <GlobalLeagueDetail
            data={data}
            onClose={() => navigate(routes.globalClub({ clubId }))}
            onEdit={() => navigate(routes.globalClubLeagueEdit({ clubId, leagueId }))}
            getSuperLeagueRoute={({ superLeagueId }) =>
              routes.globalClubLeagueSuperLeague({
                clubId,
                leagueId,
                superLeagueId,
              })
            }
          />
        )}
      </GlobalLeagueLoader>
    )}
  </Route>
);

const detailLeagueSuperLeague = (
  <Route params={['clubId', 'leagueId', 'superLeagueId']}>
    {({ clubId, leagueId, superLeagueId, navigate }) => (
      <GlobalSuperLeagueLoader superLeagueId={superLeagueId}>
        {({ data }) => (
          <GlobalSuperLeagueDetail
            data={data}
            onClose={() => navigate(routes.globalClubLeague({ clubId, leagueId }))}
            onEdit={() =>
              navigate(
                routes.globalClubLeagueSuperLeagueEdit({
                  clubId,
                  leagueId,
                  superLeagueId,
                })
              )
            }
          />
        )}
      </GlobalSuperLeagueLoader>
    )}
  </Route>
);

const detailLeagueSuperLeagueEditForm = (
  <Route params={['clubId', 'leagueId', 'superLeagueId']}>
    {({ clubId, leagueId, superLeagueId, navigate }) => (
      <GlobalSuperLeagueLoader superLeagueId={superLeagueId}>
        {({ data }) => (
          <GlobalSuperLeagueForm
            data={data}
            onClose={() =>
              navigate(
                routes.globalClubLeagueSuperLeague({
                  clubId,
                  leagueId,
                  superLeagueId,
                })
              )
            }
            onEdit={() =>
              navigate(
                routes.globalClubLeagueSuperLeague({
                  clubId,
                  leagueId,
                  superLeagueId,
                })
              )
            }
          />
        )}
      </GlobalSuperLeagueLoader>
    )}
  </Route>
);

const detailLeagueEditForm = (
  <Route params={['clubId', 'leagueId']}>
    {({ clubId, leagueId, navigate }) => (
      <GlobalLeagueLoader leagueId={leagueId}>
        {({ data }) => (
          <GlobalLeagueForm
            data={data}
            onClose={() => navigate(routes.globalClubLeague({ clubId, leagueId }))}
            onEdit={() => navigate(routes.globalClubLeague({ clubId, leagueId }))}
          />
        )}
      </GlobalLeagueLoader>
    )}
  </Route>
);

const detailWorkspace = (
  <Route params={['clubId', 'workspaceId']}>
    {({ clubId, workspaceId, navigate }) => (
      <GlobalWorkspaceLoader workspaceId={workspaceId}>
        {({ data }) => (
          <GlobalWorkspaceDetail
            data={data}
            onClose={() => navigate(routes.globalClub({ clubId }))}
          />
        )}
      </GlobalWorkspaceLoader>
    )}
  </Route>
);

const deleteError = (
  <Route params={['clubId']}>
    {({ clubId, navigate }) => (
      <GlobalClubLoader clubId={clubId}>
        {({ data }) => (
          <GlobalClubDeleteError
            data={data}
            onClose={() => navigate(routes.globalClub({ clubId }))}
          />
        )}
      </GlobalClubLoader>
    )}
  </Route>
);

const deleteErrorAccount = (
  <Route params={['clubId', 'accountId']}>
    {({ clubId, accountId, navigate }) => (
      <GlobalAccountLoader accountId={accountId}>
        {(args) => (
          <GlobalAccountDetail
            data={args.data}
            onClose={() => navigate(routes.globalClubError({ clubId }))}
          />
        )}
      </GlobalAccountLoader>
    )}
  </Route>
);

const editForm = (
  <Route params={['clubId']}>
    {({ clubId, navigate }) => (
      <GlobalClubLoader clubId={clubId}>
        {({ data, workspacesData }) => (
          <GlobalClubForm
            data={data}
            workspacesData={workspacesData}
            onClose={() => navigate(routes.globalClub({ clubId }))}
            onEdit={() => navigate(routes.globalClub({ clubId }))}
          />
        )}
      </GlobalClubLoader>
    )}
  </Route>
);

const globalClubsPage: RouteObject = {
  path: routes.globalClubs,
  element: page,
  children: [
    {
      path: routes.globalClubsCreate,
      element: createForm,
    },
    {
      path: routes.globalClub({ clubId: ':clubId' }),
      element: detail,
      children: [
        {
          path: routes.globalClubNotes({ clubId: ':clubId' }),
          element: detailNotes,
        },
        {
          path: routes.globalClubLeague({
            clubId: ':clubId',
            leagueId: ':leagueId',
          }),
          element: detailLeague,
          children: [
            {
              path: routes.globalClubLeagueSuperLeague({
                clubId: ':clubId',
                leagueId: ':leagueId',
                superLeagueId: ':superLeagueId',
              }),
              element: detailLeagueSuperLeague,
            },
            {
              path: routes.globalClubLeagueSuperLeagueEdit({
                clubId: ':clubId',
                leagueId: ':leagueId',
                superLeagueId: ':superLeagueId',
              }),
              element: detailLeagueSuperLeagueEditForm,
            },
          ],
        },
        {
          path: routes.globalClubLeagueEdit({
            clubId: ':clubId',
            leagueId: ':leagueId',
          }),
          element: detailLeagueEditForm,
        },
        {
          path: routes.globalClubWorkspace({ clubId: ':clubId', workspaceId: ':workspaceId' }),
          element: detailWorkspace,
        },
      ],
    },
    {
      path: routes.globalClubError({ clubId: ':clubId' }),
      element: deleteError,
      children: [
        {
          path: routes.globalClubErrorAccount({
            clubId: ':clubId',
            accountId: ':accountId',
          }),
          element: deleteErrorAccount,
        },
      ],
    },
    {
      path: routes.globalClubEdit({ clubId: ':clubId' }),
      element: editForm,
    },
  ],
};

export default globalClubsPage;
