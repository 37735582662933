import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createWorkspaceAccount } from '../../../api';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Form from '../../../components/Form';
import PageAddon from '../../../components/PageAddon';
import SubmitButton from '../../../components/SubmitButton';
import useWorkspace from '../../../hooks/useWorkspace';
import notify from '../../../utils/notify';
import { getTrainerPokerRoomOptions } from '../../../utils/trainer';
import { WorkspaceAccountFields as Fields } from '../../account/types';
import { getDefaultValues, getPayload } from '../helpers';
import { getFields } from './helpers';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
  onCreate: (args: { accountId: WorkspaceAccountSchema['id'] }) => void;
}

const WorkspaceAccountForm = (props: Props) => {
  const { workspaceId, onClose, onCreate } = props;

  const { t } = useTranslation();
  const workspace = useWorkspace();

  const roomOptions = getTrainerPokerRoomOptions(workspace.poker_rooms);

  const defaultValues = getDefaultValues();

  const form = useForm<Fields>({
    defaultValues:
      roomOptions.length === 1 ? { ...defaultValues, room: roomOptions[0] } : defaultValues,
  });

  const fields = getFields({ form, workspace });

  const onSubmit = async (values: Fields) => {
    if (!values.room) throw new Error();

    const response = await createWorkspaceAccount({
      workspaceId,
      payload: { ...getPayload(values), room: values.room.value },
    });

    onCreate({ accountId: response.id });

    notify('success', { title: t('sentences.record_has_been_created') });
  };

  return (
    <PageAddon title={t('common.create_account')} onClose={onClose}>
      <Form form={form} onSubmit={onSubmit}>
        {fields.room}
        {fields.login}
        {fields.password}
        <PageAddon.Controls>
          <SubmitButton>{t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default WorkspaceAccountForm;
