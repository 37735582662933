import { FieldSelectorProps, useValueSelector } from 'react-querybuilder';
import Select from '../Select';
import { optionListMapNameToValue, selectConfig } from './helpers';
import styles from './styles.module.scss';

const QueryBuilderFieldSelector = (props: FieldSelectorProps) => {
  const { options } = props;

  const { onChange, val: value } = useValueSelector(props);

  const data = optionListMapNameToValue(options);

  const option = data.find((item) => item.name === value);

  return (
    <Select
      size="small"
      popupSize="auto"
      clearable={false}
      value={{
        value: option ? option.name : data[0].name,
        label: option ? option.label : data[0].label,
      }}
      config={selectConfig}
      className={styles.field}
      options={data.map((item) => ({ value: item.name, label: item.label, group: item.group }))}
      onChange={(event) => {
        if (event) {
          onChange(event.value);
        }
      }}
    />
  );
};

export default QueryBuilderFieldSelector;
