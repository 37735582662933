import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import workspaceAccountSchema, {
  WorkspaceAccountSchema,
} from '../schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
}

const loadWorkspaceAccount = async (args: Args) => {
  const { workspaceId, accountId } = args;

  const response = await api.get(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/${accountId}`);

  return workspaceAccountSchema.parse(response.data);
};

export default loadWorkspaceAccount;
