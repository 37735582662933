import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button, { Props as ButtonProps } from '../Button';

interface Props {
  children: ReactNode;
  icon?: ButtonProps['icon'];
  disabled?: ButtonProps['disabled'];
  size?: ButtonProps['size'];
}

const SubmitButton = (props: Props) => {
  const { size, icon, disabled, children } = props;

  const { t } = useTranslation();

  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Button type="submit" size={size} icon={icon} disabled={disabled} loading={isSubmitting}>
      {children || t('common.submit')}
    </Button>
  );
};

export default SubmitButton;
