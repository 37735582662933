import { WorkspaceClubTransactionSchema } from '../../../api/schemas/workspaceClubTransactionSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import PageDetailLink from '../../../components/PageDetailLink';
import routes from '../../../config/routes';
import { formatAbsoluteDate } from '../../../utils/date';
import { CLUB_TRANSACTIONS_TYPES_LABELS } from '../helpers';
import styles from './styles.module.scss';

interface Props {
  data: WorkspaceClubTransactionSchema;
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceClubTransactionDetailLink = (props: Props) => {
  const { data, workspaceId } = props;

  const { id, type, created_on } = data;

  return (
    <div className={styles.container}>
      <PageDetailLink
        label={formatAbsoluteDate(created_on)}
        to={routes.workspaceClubTransaction({ workspaceId, transactionId: id })}
      />
      <div className={styles.caption}>{CLUB_TRANSACTIONS_TYPES_LABELS[type]}</div>
    </div>
  );
};

export default WorkspaceClubTransactionDetailLink;
