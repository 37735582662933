import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import paginationSchemaNew from '../schemas/paginationSchemaNew';
import workspaceClubManagerSchema from '../schemas/workspaceClubManagerSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceClubManagers = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(
    `/api/v2/${ALIASES.WORKSPACE.L}/${workspaceId}/club-manager/managers`,
    {
      params,
    }
  );

  return paginationSchemaNew(workspaceClubManagerSchema).parse(response.data);
};

export default loadWorkspaceClubManagers;
