import api from '../../services/api';
import URLParams from '../../types/URLParams';
import globalBonusSchema from '../schemas/globalBonusSchema';
import paginationSchemaNew from '../schemas/paginationSchemaNew';

const loadGlobalBonuses = async (params?: URLParams) => {
  const response = await api.get('/api/bonus', { params });

  return paginationSchemaNew(globalBonusSchema).parse(response.data);
};

export default loadGlobalBonuses;
