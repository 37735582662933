import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useBreakpoints from '../../hooks/useBreakpoints';
import { FilterFields, UseFilterReturn } from '../../hooks/useFilter';
import icon from '../../static/icons';
import Button from '../Button';
import { usePageContext } from '../Page';
import FilterCollapse from './FilterCollapse';
import { FilterProvider } from './FilterContext';
import FilterDate from './FilterDate';
import FilterMultiCheckbox from './FilterMultiCheckbox';
import FilterPlaceholder from './FilterPlaceholder';
import FilterRadio from './FilterRadio';
import FilterRangeInput from './FilterRangeInput';
import FilterSwitcher from './FilterSwitcher';
import styles from './styles.module.scss';

interface Props<T extends FilterFields<T>> {
  children: React.ReactNode;
  filter: UseFilterReturn<T>;
  total?: number;
}

const Filter = <T extends FilterFields<T>>(props: Props<T>) => {
  const { children, total, filter } = props;

  const { t } = useTranslation();
  const { hasTabs, closeFilter } = usePageContext();
  const { isMobile, isTablet, isPhone } = useBreakpoints();

  const { count, isPending, resetValues } = filter;

  const getResults = () => {
    if (!count || isPending) return t('common.show_results');

    if (total === 0) return t('common.no_results');

    if (total) return `${t('common.show_results')} (${total})`;

    return t('common.show_results');
  };

  return (
    <FilterProvider {...filter}>
      <div className={styles.spacer} />
      <div className={clsx(styles.container, isTablet && hasTabs && styles.withOffset)}>
        <div className={styles.top}>
          <div className={styles.title}>{t('common.filter')}</div>
          <div>
            {count !== 0 && (
              <Button variant="light" icon={icon('broom', 16)} size="small" onClick={resetValues}>
                {t('common.clear_all')}
              </Button>
            )}
            {isMobile && (
              <Button
                size="small"
                variant="transparent"
                icon={icon('cross', 16)}
                onClick={closeFilter}
              />
            )}
          </div>
        </div>
        <div className={styles.content}>{children}</div>
        {isPhone && (
          <div className={styles.controls}>
            <Button
              loading={isPending}
              disabled={!count || isPending || total === 0}
              onClick={closeFilter}
            >
              {getResults()}
            </Button>
          </div>
        )}
      </div>
    </FilterProvider>
  );
};

Filter.Collapse = FilterCollapse;
Filter.Date = FilterDate;
Filter.MultiCheckbox = FilterMultiCheckbox;
Filter.Placeholder = FilterPlaceholder;
Filter.Radio = FilterRadio;
Filter.RangeInput = FilterRangeInput;
Filter.Switcher = FilterSwitcher;

export default Filter;
