import { ReactNode } from 'react';
import SubmitButton from '../SubmitButton';

interface Props {
  children: ReactNode;
}

const AuthSubmitButton = (props: Props) => {
  const { children } = props;

  return <SubmitButton size="large">{children}</SubmitButton>;
};

export default AuthSubmitButton;
