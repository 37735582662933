import api from '../../services/api';
import { CurrentUserSessionSchema } from '../schemas/currentUserSessionSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';

interface Payload {
  refresh_token: CurrentUserSessionSchema['refresh_token'];
}

interface Args {
  payload: Payload;
}

const logoutUser = async (args: Args) => {
  const { payload } = args;

  const response = await api.post('/api/logout', payload);

  return emptyResponseSchema.parse(response.data);
};

export default logoutUser;
