import { AreaSchema, STATUS } from '../../api/schemas/areaSchema';
import Badge, { Color } from '../../components/Badge';
import { STATUS_LABELS } from './helpers';

const COLORS: Record<AreaSchema['status'], Color> = {
  [STATUS.UNKNOWN]: 'grey',
  [STATUS.NEW]: 'blue',
  [STATUS.VERIFIED]: 'green',
};

interface Props {
  data: AreaSchema;
}

const GlobalAreaStatus = (props: Props) => {
  const { data } = props;

  const { status } = data;

  return <Badge color={COLORS[status]}>{STATUS_LABELS[status]}</Badge>;
};

export default GlobalAreaStatus;
