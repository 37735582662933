import clsx from 'clsx';
import { ElementRef, forwardRef, ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children?: ReactNode;
  danger?: boolean;
}

const TagCount = forwardRef<ElementRef<'div'>, Props>((props, ref) => {
  const { children, danger } = props;

  return (
    <div ref={ref} className={clsx(styles.container, danger && styles.danger)}>
      {children}
    </div>
  );
});

export default TagCount;
