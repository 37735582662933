import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import WorkspaceClubWhitelistDetail from '../../features/workspace-club-whitelist/WorkspaceClubWhitelistDetail';
import WorkspaceClubWhitelistForm from '../../features/workspace-club-whitelist/WorkspaceClubWhitelistForm';
import WorkspaceClubWhitelistLoader from '../../features/workspace-club-whitelist/WorkspaceClubWhitelistLoader';
import WorkspaceClubWhitelistPage from '../../pages/WorkspaceClubWhitelistPage';
import Route from './Route';

const page = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceClubWhitelistPage workspaceId={workspaceId} />}
  </Route>
);

const createForm = (
  <Route params={['workspaceId']}>
    {({ workspaceId, navigate }) => (
      <WorkspaceClubWhitelistForm
        workspaceId={workspaceId}
        onClose={() => navigate(routes.workspaceClubWhitelist({ workspaceId }))}
        onCreate={({ whitelistMemberId }) =>
          navigate(routes.workspaceClubWhitelistMember({ workspaceId, whitelistMemberId }))
        }
      />
    )}
  </Route>
);

const detail = (
  <Route params={['workspaceId', 'whitelistMemberId']}>
    {({ workspaceId, whitelistMemberId, navigate }) => (
      <WorkspaceClubWhitelistLoader workspaceId={workspaceId} whitelistMemberId={whitelistMemberId}>
        {({ data }) => (
          <WorkspaceClubWhitelistDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceClubWhitelist({ workspaceId }))}
          />
        )}
      </WorkspaceClubWhitelistLoader>
    )}
  </Route>
);

const workspaceClubWhitelistPage: RouteObject = {
  path: routes.workspaceClubWhitelist({ workspaceId: ':workspaceId' }),
  element: page,
  children: [
    {
      path: routes.workspaceClubWhitelistCreate({ workspaceId: ':workspaceId' }),
      element: createForm,
    },
    {
      path: routes.workspaceClubWhitelistMember({
        workspaceId: ':workspaceId',
        whitelistMemberId: ':whitelistMemberId',
      }),
      element: detail,
    },
  ],
};

export default workspaceClubWhitelistPage;
