import { OrderTransactionSchema, STATUS } from '../../../api/schemas/orderTransactionSchema';
import Badge, { Color } from '../../../components/Badge';
import { ORDER_TRANSACTION_STATUS_LABELS } from '../helpers';

const STATUS_COLORS: Record<OrderTransactionSchema['status'], Color> = {
  [STATUS.UNKNOWN]: 'grey',
  [STATUS.PROCESS]: 'orange',
  [STATUS.DONE]: 'green',
};

interface Props {
  data: OrderTransactionSchema;
}

const OrderTransactionStatus = (props: Props) => {
  const { data } = props;

  const { status } = data;

  return <Badge color={STATUS_COLORS[status]}>{ORDER_TRANSACTION_STATUS_LABELS[status]}</Badge>;
};

export default OrderTransactionStatus;
