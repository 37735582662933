import { z } from 'zod';

const geonamesCitySchema = z.object({
  country_code: z.string(),
  country_name: z.string(),
  country_id: z.string(),
  name: z.string(),
  geoname_id: z.number(),
  admin_code: z.string(),
  longitude: z.number(),
  latitude: z.number(),
  toponym_name: z.string(),
  fcode: z.string(),
});

export type GeonamesCitySchema = z.infer<typeof geonamesCitySchema>;

export default geonamesCitySchema;
