import { useTranslation } from 'react-i18next';
import icon from '../../static/icons';
import Id from '../../types/Id';
import Button from '../Button';
import Placeholder from '../Placeholder';
import { Props as TableProps } from '../Table/types';
import styles from './styles.module.scss';

type Props<T> = Pick<TableProps<T>, 'meta' | 'total' | 'loading'>;

const PageTotal = <T extends Id>(props: Props<T>) => {
  const { total, meta, loading } = props;

  const createdAmount = meta?.createdAmount || 0;
  const deletedAmount = meta?.deletedAmount || 0;

  const getTotal = () => {
    if (total) {
      if (total < 0) return 0;

      return total;
    }

    return 0;
  };

  const { t } = useTranslation();

  if (loading) return <Placeholder className={styles.placeholder} />;

  return (
    <div className={styles.container}>
      <span>{t('common.result')}</span>
      <div>
        {getTotal()}
        {Boolean(createdAmount) && (
          <span className={styles.createdAmount}>{` + ${createdAmount}`}</span>
        )}
        {Boolean(deletedAmount) && (
          <span className={styles.deletedAmount}>{` - ${deletedAmount}`}</span>
        )}
      </div>
      {Boolean(createdAmount || deletedAmount) && (
        <Button variant="light" icon={icon('refresh', 16)} onClick={meta?.onRefresh} />
      )}
    </div>
  );
};

export default PageTotal;
