import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadWorkspaceClubMember } from '../../api';
import { WorkspaceClubMemberSchema } from '../../api/schemas/workspaceClubMemberSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { updateDetailQueryData } from '../../utils/queryClient';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  memberId: WorkspaceClubMemberSchema['id'];
  children: (args: { data: WorkspaceClubMemberSchema }) => ReactElement;
}

const WorkspaceClubMemberLoader = (props: Props) => {
  const { workspaceId, memberId, children } = props;

  const queryKey = [queryKeys.workspaceClubMember({ workspaceId, memberId })];
  const queryFn = () => loadWorkspaceClubMember({ workspaceId, memberId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<WorkspaceClubMemberSchema>({
    domain: 'ClubMember',
    enabled: (message) => message.meta_info?.maintenance_id === workspaceId,
    onMessage: (message) => {
      if (
        (message.action === 'Edited' ||
          message.action === 'Deleted' ||
          message.action === 'Kick') &&
        message.payload.id === memberId
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data });
};

export default WorkspaceClubMemberLoader;
