import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { loadWorkspaceTags } from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Select from '../../components/Select';
import { required } from '../../utils/form';
import { WorkspaceAccountFields } from '../account/types';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  required?: boolean;
}

const WorkspaceAccountFormTagIds = (props: Props) => {
  const { workspaceId, required: req } = props;

  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext<WorkspaceAccountFields>();

  return (
    <Controller
      name="tag_ids"
      control={control}
      {...(req && { rules: { validate: required } })}
      render={({ field }) => (
        <Select.AsyncV2
          onLoad={(args) => loadWorkspaceTags({ workspaceId }, args)}
          select={(item) => ({ value: item.id, label: item.name })}
        >
          {({ options, ...rest }) => (
            <Select.Multi
              renderValueAsTag
              label={t('common.tags')}
              options={options}
              value={field.value}
              onChange={field.onChange}
              error={errors.tag_ids?.message}
              {...rest}
            />
          )}
        </Select.AsyncV2>
      )}
    />
  );
};

export default WorkspaceAccountFormTagIds;
