import { ColumnSizes } from '../../components/Table';
import { getParsedLocalStorageItem, setLocalStorageItem } from '../../utils/localStorage';

const useTableColumnResize = () => {
  const columnSizes = getParsedLocalStorageItem('column_sizes');

  const getColumnSizes = () => {
    if (columnSizes) {
      if (columnSizes['global-deal']) return columnSizes['global-deal'];
    }

    return undefined;
  };

  return {
    columnSizes: getColumnSizes(),
    onColumnResize: (columns: ColumnSizes) => {
      setLocalStorageItem(
        'column_sizes',
        JSON.stringify({ ...(columnSizes || {}), 'global-deal': columns })
      );
    },
  };
};

export default useTableColumnResize;
