import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import marketIdentitySchema from '../schemas/marketIdentitySchema';
import paginationSchema from '../schemas/paginationSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceMarketIdentities = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/identity-market`, {
    params,
  });

  return paginationSchema(marketIdentitySchema).parse(response.data);
};

export default loadWorkspaceMarketIdentities;
