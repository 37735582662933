import { Suspense, lazy } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  createGlobalClub,
  loadGlobalLeagues,
  loadGlobalWorkspaces,
  updateGlobalClub,
} from '../../../api';
import { GlobalClubSchema } from '../../../api/schemas/globalClubSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Checkbox from '../../../components/Checkbox';
import Form from '../../../components/Form';
import Input from '../../../components/Input';
import PageAddon from '../../../components/PageAddon';
import Select, { Option, Options } from '../../../components/Select';
import SubmitButton from '../../../components/SubmitButton';
import { convertNumberToString, convertStringToNumber } from '../../../utils/data';
import { min, max, maxLength, minLength, required } from '../../../utils/form';
import notify from '../../../utils/notify';
import { can } from '../../../utils/permissions';
import { TRAINER_POKER_ROOM_LABELS, trainerPokerRoomOptions } from '../../../utils/trainer';

const CodeEditor = lazy(() => import('../../../components/CodeEditor'));

interface Fields {
  name: string;
  code: string;
  poker_room: Option<GlobalClubSchema['poker_room']>;
  league_id: Option;
  maintenance_ids: Options;
  extra: string;
  optimal_account_count: string;
  high_risk: boolean;
}

interface CommonProps {
  onClose: () => void;
}

interface CreateProps {
  onCreate: (args: { clubId: GlobalClubSchema['id'] }) => void;
  data?: never;
  workspacesData?: never;
  onEdit?: never;
}

interface EditProps {
  data: GlobalClubSchema;
  workspacesData: WorkspaceSchema[];
  onEdit: () => void;
  onCreate?: never;
}

type Props = CommonProps & (CreateProps | EditProps);

const GlobalClubForm = (props: Props) => {
  const { data, workspacesData, onClose, onCreate, onEdit } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: {
      name: data?.name || '',
      code: data?.code || '',
      poker_room: data
        ? { value: data.poker_room, label: TRAINER_POKER_ROOM_LABELS[data.poker_room] }
        : null,
      league_id: data?.league ? { value: data.league.id, label: data.league.code } : null,
      extra: data?.extra ? JSON.stringify(data.extra, null, 1) : '',
      optimal_account_count: data ? convertNumberToString(data.optimal_account_count) : '',
      high_risk: data?.high_risk || false,
      ...(can.workspace.read && {
        maintenance_ids: workspacesData
          ? workspacesData.map((item) => ({
              value: item.id,
              label: item.name,
            }))
          : [],
      }),
    },
  });

  const {
    control,
    register,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    if (!values.poker_room) throw new Error();

    const payload = {
      name: values.name,
      code: values.code,
      poker_room: values.poker_room.value,
      league_id: values.league_id?.value || null,
      extra: values.extra ? JSON.parse(values.extra) : null,
      optimal_account_count: convertStringToNumber(values.optimal_account_count),
      high_risk: values.high_risk,
      ...(can.workspace.read && {
        maintenance_ids: values.maintenance_ids.map((item) => item.value),
      }),
    };

    if (data) {
      await updateGlobalClub({
        clubId: data.id,
        payload: {
          name: payload.name,
          league_id: payload.league_id,
          high_risk: payload.high_risk,
          extra: payload.extra,
          optimal_account_count: payload.optimal_account_count,
          ...(can.workspace.read && {
            maintenance_ids: payload.maintenance_ids,
          }),
        },
      });

      onEdit();
    } else {
      const response = await createGlobalClub({ payload });

      onCreate({ clubId: response.id });
    }

    notify('success', {
      title: data ? t('sentences.changes_have_been_saved') : t('sentences.record_has_been_created'),
    });
  };

  return (
    <PageAddon
      title={data ? `${t('common.edit')} ${data.code}` : t('common.create_club')}
      onClose={onClose}
    >
      <Form form={form} onSubmit={onSubmit}>
        {!data && (
          <Input
            label={`${t('common.code')}*`}
            error={errors.code?.message}
            {...register('code', {
              validate: {
                required,
                minLength: minLength(),
                maxLength: maxLength(),
              },
            })}
          />
        )}
        <Input
          label={`${t('common.name')}*`}
          error={errors.name?.message}
          {...register('name', {
            validate: {
              required,
              maxLength: maxLength(),
            },
          })}
        />
        {!data && (
          <Controller
            name="poker_room"
            control={control}
            rules={{ validate: { required } }}
            render={({ field }) => (
              <Select
                clearable={false}
                label={`${t('common.poker_room')}*`}
                options={trainerPokerRoomOptions}
                value={field.value}
                onChange={field.onChange}
                error={errors.poker_room?.message}
              />
            )}
          />
        )}
        <Controller
          name="league_id"
          control={control}
          render={({ field }) => (
            <Select.Async
              onLoad={async (args) => {
                const response = await loadGlobalLeagues(args);

                const options = response.items.map((item) => ({
                  value: item.id,
                  label: item.code,
                }));

                return options;
              }}
            >
              {({ options, ...rest }) => (
                <Select
                  label={t('common.league')}
                  options={options}
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.league_id?.message}
                  {...rest}
                />
              )}
            </Select.Async>
          )}
        />
        {can.workspace.read && (
          <Controller
            name="maintenance_ids"
            control={control}
            render={({ field }) => (
              <Select.Async
                onLoad={async (args) => {
                  const response = await loadGlobalWorkspaces(args);

                  return response.items.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }));
                }}
              >
                {(selectProps) => (
                  <Select.Multi
                    label={t('common.workspaces')}
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.maintenance_ids?.message}
                    {...selectProps}
                  />
                )}
              </Select.Async>
            )}
          />
        )}
        <Controller
          name="optimal_account_count"
          control={control}
          rules={{
            validate: {
              min: min(t('common.optimal_account_count'), 1),
              max: max(t('common.optimal_account_count'), 9),
            },
          }}
          render={({ field }) => (
            <Input
              label={t('common.optimal_account_count')}
              format={{
                allowNegative: false,
                onValueChange: field.onChange,
              }}
              error={errors.optimal_account_count?.message}
              {...field}
            />
          )}
        />
        <Checkbox rtl variant="switch" {...register('high_risk')}>
          {t('common.high_risk')}
        </Checkbox>
        <Suspense fallback={null}>
          <Controller
            name="extra"
            control={control}
            render={({ field }) => (
              <CodeEditor
                value={field.value}
                label={{
                  text: t('common.extra_fields'),
                  hint: (
                    <>
                      {t('sentences.club_extra_field_hint_1')} <br />— &nbsp;
                      <strong>{t('sentences.club_extra_field_hint_2')}</strong>
                      &nbsp; {t('sentences.club_extra_field_hint_3')} <br />
                      &nbsp;&nbsp; {t('sentences.club_extra_field_hint_4')} <br />
                      &nbsp;&nbsp; {t('sentences.club_extra_field_hint_5')} <br />
                      &nbsp;&nbsp; {t('sentences.club_extra_field_hint_6')}
                    </>
                  ),
                }}
                onChange={field.onChange}
                error={errors.extra?.message}
              />
            )}
          />
        </Suspense>
        <PageAddon.Controls>
          <SubmitButton>{data ? t('common.save') : t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default GlobalClubForm;
