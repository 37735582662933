import api from '../../services/api';
import URLParams from '../../types/URLParams';
import areaSchema from '../schemas/areaSchema';
import paginationSchemaNew from '../schemas/paginationSchemaNew';

const loadGlobalAreas = async (params?: URLParams) => {
  const response = await api.get('/api/area', { params });

  return paginationSchemaNew(areaSchema).parse(response.data);
};

export default loadGlobalAreas;
