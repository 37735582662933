import { useSessionStore } from '../store/session';
import { refreshUserToken } from '../utils/auth';

let refreshTokenInterval: number;

const useSessionUpdate = () => {
  const { session } = useSessionStore();

  if (session && !refreshTokenInterval) {
    const { expires_in } = session;

    const intervalTime = (80 / 100) * (expires_in / 0.001);

    refreshUserToken();
    refreshTokenInterval = window.setInterval(refreshUserToken, intervalTime);
  }

  if (!session && refreshTokenInterval) {
    clearInterval(refreshTokenInterval);
  }
};

export default useSessionUpdate;
