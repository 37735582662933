import { useTranslation } from 'react-i18next';
import icon from '../../static/icons';
import Button, { Props as ButtonProps } from '../Button';
import Tooltip from '../Tooltip';
import styles from './styles.module.scss';

interface Props {
  size: ButtonProps['size'];
  variant: ButtonProps['variant'];
  onClick?: () => void;
}

const LinkButton = (props: Props) => {
  const { size, variant, onClick } = props;

  const { t } = useTranslation();

  return (
    <Tooltip label={t('common.open')}>
      <Button
        size={size}
        variant={variant}
        className={styles.link}
        icon={icon('externalLink', 16)}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export default LinkButton;
