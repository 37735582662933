import { WorkspaceStageSchema } from '../../api/schemas/stage/workspaceStageSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { ColumnSizes } from '../../components/Table';
import { getParsedLocalStorageItem, setLocalStorageItem } from '../../utils/localStorage';

interface Config {
  workspaceId: WorkspaceSchema['id'];
  stage?: WorkspaceStageSchema;
}

const useTableColumnResize = (config: Config) => {
  const { workspaceId, stage } = config;

  const columnSizes = getParsedLocalStorageItem('column_sizes');

  const getColumnSizes = () => {
    if (columnSizes && stage) {
      if (columnSizes['workspace-accounts'] && columnSizes['workspace-accounts'][workspaceId]) {
        return columnSizes['workspace-accounts'][workspaceId][stage.id];
      }
    }

    return undefined;
  };

  return {
    columnSizes: getColumnSizes(),
    onColumnResize: (columns: ColumnSizes) => {
      if (stage) {
        setLocalStorageItem(
          'column_sizes',
          JSON.stringify({
            ...columnSizes,
            'workspace-accounts': {
              ...(columnSizes ? columnSizes['workspace-accounts'] : {}),
              [workspaceId]: {
                ...(columnSizes && columnSizes['workspace-accounts']
                  ? columnSizes['workspace-accounts'][workspaceId]
                  : {}),
                [stage.id]: columns,
              },
            },
          })
        );
      }
    },
  };
};

export default useTableColumnResize;
