import { useTranslation } from 'react-i18next';
import { MetricsSchema } from '../../api/schemas/metricsSchema';
import Collapse from '../../components/Collapse';
import { getMetrics } from './helpers';

interface Props {
  data: MetricsSchema;
}

const AccountMetrics = (props: Props) => {
  const { data } = props;

  const { t } = useTranslation();

  const {
    hands_played,
    net_win_sum,
    net_win_sum_bb,
    stack_size,
    stack_size_bb,
    win_rate_bb,
    ev_rate_bb,
    local_brain_count,
    errors_count,
  } = getMetrics(data);

  return (
    <>
      <Collapse.InfoField label={t('common.hands')}>{hands_played}</Collapse.InfoField>
      <Collapse.InfoField label={t('common.net_win')}>{net_win_sum}</Collapse.InfoField>
      <Collapse.InfoField label={t('common.net_win,bb')}>{net_win_sum_bb}</Collapse.InfoField>
      <Collapse.InfoField label={t('common.stack')}>{stack_size}</Collapse.InfoField>
      <Collapse.InfoField label={t('common.stack,bb')}>{stack_size_bb}</Collapse.InfoField>
      <Collapse.InfoField label={t('common.bb/100')}>{win_rate_bb}</Collapse.InfoField>
      <Collapse.InfoField label={t('common.evbb/100')}>{ev_rate_bb}</Collapse.InfoField>
      <Collapse.InfoField label={t('common.hands_on_local_brain')}>
        {local_brain_count}
      </Collapse.InfoField>
      <Collapse.InfoField label={t('common.errors')}>{errors_count}</Collapse.InfoField>
    </>
  );
};

export default AccountMetrics;
