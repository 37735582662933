import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadGlobalOrder } from '../../api';
import { OrderSchema } from '../../api/schemas/orderSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { updateDetailQueryData } from '../../utils/queryClient';

interface Props {
  orderId: OrderSchema['id'];
  children: (args: { data: OrderSchema }) => ReactElement;
}

const GlobalOrderLoader = (props: Props) => {
  const { orderId, children } = props;

  const queryKey = [queryKeys.globalOrder({ orderId })];
  const queryFn = () => loadGlobalOrder({ orderId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<OrderSchema>({
    domain: 'GlobalOrder',
    onMessage: (message) => {
      if (
        (message.action === 'Edited' || message.action === 'Done') &&
        message.payload.id === orderId
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data });
};

export default GlobalOrderLoader;
