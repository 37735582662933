import { useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useTranslation } from 'react-i18next';
import { MarketIdentitySchema } from '../../../../api/schemas/marketIdentitySchema';
import { WorkspaceSchema } from '../../../../api/schemas/workspaceSchema';
import Button from '../../../../components/Button';
import Tooltip from '../../../../components/Tooltip';
import icon from '../../../../static/icons';
import { useCartStore } from '../../../../store/cart';
import WorkspaceMarketIdentityCartMonthInput from '../WorkspaceMarketIdentityCartMonthInput';
import styles from './styles.module.scss';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: MarketIdentitySchema;
  isDetail?: boolean;
}

const WorkspaceMarketIdentityCartButton = (props: Props) => {
  const { workspaceId, data, isDetail = false } = props;

  const { id, owner } = data;

  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const { getIdentities, add, remove } = useCartStore((state) => ({
    getIdentities: state.getIdentities,
    add: state.add,
    remove: state.remove,
  }));

  const identity = getIdentities(workspaceId).find((item) => item.data.id === id);

  const show = () => {
    add(workspaceId, data);
    setVisible(true);
  };

  const hide = () => setVisible(false);

  const ref = useOnclickOutside(hide);

  const isOwnIdentity = Boolean(owner.name);

  const size = isDetail ? 'medium' : 'small';

  if (identity) {
    return (
      <div className={styles.container}>
        <Tooltip label={t('common.remove_from_cart')} hideOnMobile>
          <Button
            size={size}
            danger
            variant="transparent"
            icon={icon('shoppingBagOut', 16)}
            onClick={() => remove(id)}
          />
        </Tooltip>
        {visible && (
          <div ref={ref} className={styles.popup}>
            <WorkspaceMarketIdentityCartMonthInput workspaceId={workspaceId} identity={identity} />
            <Button size={size} icon={icon('check', 16)} onClick={hide} />
          </div>
        )}
      </div>
    );
  }

  return (
    <Tooltip
      label={isOwnIdentity ? t('common.cant_rent_your_own_identity') : t('common.add_to_cart')}
      hideOnMobile={!isOwnIdentity}
    >
      <div>
        <Button
          size={size}
          variant="transparent"
          icon={icon('shoppingBag', 16)}
          onClick={show}
          disabled={isOwnIdentity}
        />
      </div>
    </Tooltip>
  );
};

export default WorkspaceMarketIdentityCartButton;
