import api from '../../services/api';
import URLParams from '../../types/URLParams';
import paginationSchema from '../schemas/paginationSchema';
import globalSessionSchema from '../schemas/session/globalSessionSchema';

const loadGlobalSessions = async (params?: URLParams) => {
  const response = await api.get(`/api/session`, { params });

  return paginationSchema(globalSessionSchema).parse(response.data);
};

export default loadGlobalSessions;
