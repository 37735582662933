import clsx from 'clsx';
import styles from './styles.module.scss';

interface Tab {
  label: string;
  active: boolean;
  onClick: () => void;
}

type Tabs = Tab[];

interface Props {
  tabs: Tabs;
}

const Tabs = (props: Props) => {
  const { tabs } = props;

  return (
    <div className={styles.container}>
      {tabs.map((tab) => (
        <button
          key={tab.label}
          type="button"
          onClick={tab.onClick}
          className={clsx(styles.tab, tab.active && styles.active)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
