import { ReactNode } from 'react';
import useOverflowCount from '../../../hooks/useOverflowCount';
import Tooltip from '../../Tooltip';
import TagCount from '../TagCount';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  danger?: (index: number) => boolean;
  tooltip?: (index: number) => ReactNode;
}

const TagGroup = (props: Props) => {
  const { children, danger, tooltip } = props;

  const { ref, count } = useOverflowCount();

  return (
    <div className={styles.container}>
      <div ref={ref} className={styles.tags}>
        {children}
      </div>
      {count !== 0 && (
        <Tooltip label={tooltip && tooltip(-count)}>
          <TagCount danger={danger && danger(-count)}>{`+${count}`}</TagCount>
        </Tooltip>
      )}
    </div>
  );
};

export default TagGroup;
