import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { globalSessionFilterValuesSchema } from '../schemas/session/globalSessionSchema';

const loadGlobalSessionsFilterValues = async (params?: URLParams) => {
  const response = await api.get(`/api/session/filter-values`, { params });

  return globalSessionFilterValuesSchema.parse(response.data);
};

export default loadGlobalSessionsFilterValues;
