import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  renderBefore?: ReactNode;
  renderAfter: ReactNode;
}

const WorkspaceSettingsFieldGroup = (props: Props) => {
  const { children, renderBefore, renderAfter } = props;

  return (
    <div className={styles.container}>
      {renderBefore}
      <div className={styles.fields}>{children}</div>
      {renderAfter}
    </div>
  );
};

export default WorkspaceSettingsFieldGroup;
