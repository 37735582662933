import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadWorkspaceAccountRoomConfig } from '../../api';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { AccountRoomConfigSchema } from '../../api/schemas/accountRoomConfigSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
  children: (args: { data: AccountRoomConfigSchema }) => ReactElement;
}

const WorkspaceAccountRoomConfigLoader = (props: Props) => {
  const { workspaceId, accountId, children } = props;

  const queryKey = [queryKeys.workspaceAccountRoomConfig({ workspaceId, accountId })];
  const queryFn = () => loadWorkspaceAccountRoomConfig({ workspaceId, accountId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403, 404] },
  });

  if (isPending) return <PageAddon.DetailPlaceholder wrapper={false} />;
  if (!data) return null;

  return children({ data });
};

export default WorkspaceAccountRoomConfigLoader;
