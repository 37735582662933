import clsx from 'clsx';
import Button, { Props as ButtonProps } from '../../../components/Button';
import icon from '../../../static/icons';
import { capitalize } from '../../../utils/data';
import styles from './styles.module.scss';

type Variant = Exclude<ButtonProps['variant'], undefined>;

const variants: Variant[] = ['primary', 'secondary', 'ghost', 'transparent', 'outline', 'light'];

const StorybookPageButton = () => {
  const variantsLabels = (
    <>
      {variants.map((item) => (
        <div key={item} className={clsx(styles.label, styles.extraSmall)}>
          {capitalize(item)}
        </div>
      ))}
    </>
  );

  const getSize = (variant: Variant) => {
    if (variant === 'light') return 'large';

    return 'medium';
  };

  return (
    <>
      <div className={styles.table}>
        <h2>Button state</h2>
        {variantsLabels}
        <div className={styles.label}>Normal</div>
        {variants.map((item) => (
          <div key={item} className={styles.buttons}>
            <Button icon={icon('add', 16)} size={getSize(item)} variant={item}>
              Create
            </Button>
            <Button icon={icon('add', 16)} size={getSize(item)} variant={item} />
          </div>
        ))}
        <div className={styles.label}>Danger</div>
        {variants.map((item) => (
          <div key={item} className={styles.buttons}>
            <Button icon={icon('trash', 16)} size={getSize(item)} variant={item} danger>
              Delete
            </Button>
            <Button icon={icon('trash', 16)} size={getSize(item)} variant={item} danger />
          </div>
        ))}
        <div className={styles.label}>Focused</div>
        {variants.map((item) => (
          <div key={item} className={styles.buttons}>
            <Button icon={icon('add', 16)} size={getSize(item)} variant={item} focused>
              Create
            </Button>
            <Button icon={icon('add', 16)} size={getSize(item)} variant={item} focused />
          </div>
        ))}
        <div className={styles.label}>Danger focused</div>
        {variants.map((item) => (
          <div key={item} className={styles.buttons}>
            <Button icon={icon('trash', 16)} size={getSize(item)} variant={item} focused danger>
              Delete
            </Button>
            <Button icon={icon('trash', 16)} size={getSize(item)} variant={item} focused danger />
          </div>
        ))}
        <div className={styles.label}>Loading</div>
        {variants.map((item) => (
          <div key={item} className={styles.buttons}>
            <Button icon={icon('add', 16)} size={getSize(item)} variant={item} loading>
              Create
            </Button>
            <Button icon={icon('add', 16)} size={getSize(item)} variant={item} loading />
          </div>
        ))}
        <div className={styles.label}>Danger loading</div>
        {variants.map((item) => (
          <div key={item} className={styles.buttons}>
            <Button icon={icon('trash', 16)} size={getSize(item)} variant={item} danger loading>
              Delete
            </Button>
            <Button icon={icon('trash', 16)} size={getSize(item)} variant={item} danger loading />
          </div>
        ))}
        <div className={styles.label}>Disabled</div>
        {variants.map((item) => (
          <div key={item} className={styles.buttons}>
            <Button icon={icon('add', 16)} size={getSize(item)} variant={item} disabled>
              Create
            </Button>
            <Button icon={icon('add', 16)} size={getSize(item)} variant={item} disabled />
          </div>
        ))}
      </div>
      <div className={styles.table}>
        <h2>Button group</h2>
        {variantsLabels}
        <div className={styles.label}>Normal</div>
        {variants.map((item) => {
          if (item === 'light') {
            return (
              <div key={item} className={styles.label}>
                —
              </div>
            );
          }

          return (
            <div key={item} className={styles.buttons}>
              <Button.Group>
                <Button icon={icon('add', 16)} size="medium" variant={item}>
                  Create
                </Button>
                <Button.Group.Divider variant={item} />
                <Button icon={icon('chevronDown', 16)} size="medium" variant={item} />
              </Button.Group>
            </div>
          );
        })}
        <div className={styles.label}>Danger</div>
        {variants.map((item) => {
          if (item === 'light') {
            return (
              <div key={item} className={styles.label}>
                —
              </div>
            );
          }

          return (
            <div key={item} className={styles.buttons}>
              <Button.Group>
                <Button icon={icon('add', 16)} size="medium" variant={item} danger>
                  Delete
                </Button>
                <Button.Group.Divider variant={item} danger />
                <Button icon={icon('chevronDown', 16)} size="medium" variant={item} danger />
              </Button.Group>
            </div>
          );
        })}
      </div>
      <div className={styles.table}>
        <h2>Button size</h2>
        {variantsLabels}
        <div className={clsx(styles.label, styles.large)}>Large</div>
        {variants.map((item) => (
          <div key={item} className={styles.buttons}>
            <Button icon={icon('add', 16)} size="large" variant={item}>
              Create
            </Button>
            <Button icon={icon('add', 16)} size="large" variant={item} />
          </div>
        ))}
        <div className={clsx(styles.label)}>Medium</div>
        {variants.map((item) => (
          <div key={item} className={styles.buttons}>
            <Button icon={icon('add', 16)} size="medium" variant={item}>
              Create
            </Button>
            <Button icon={icon('add', 16)} size="medium" variant={item} />
          </div>
        ))}
        <div className={clsx(styles.label, styles.small)}>Small</div>
        {variants.map((item) => (
          <div key={item} className={styles.buttons}>
            <Button icon={icon('add', 16)} size="small" variant={item}>
              Create
            </Button>
            <Button icon={icon('add', 16)} size="small" variant={item} />
          </div>
        ))}
        <div className={clsx(styles.label, styles.extraSmall)}>Extra small</div>
        {variants.map((item) => (
          <div key={item} className={styles.buttons}>
            <Button icon={icon('add', 16)} size="extra-small" variant={item}>
              Create
            </Button>
            <Button icon={icon('add', 16)} size="extra-small" variant={item} />
          </div>
        ))}
      </div>
    </>
  );
};

export default StorybookPageButton;
