import api from '../services/api';
import currentUserSchema from './schemas/currentUserSchema';

const loadCurrentUser = async () => {
  const response = await api.get('/api/user/me');

  return currentUserSchema.parse(response.data);
};

export default loadCurrentUser;
