import { createContext, ReactNode, useContext, useMemo } from 'react';
import { createTableStore } from './store';

type Context = ReturnType<typeof createTableStore>;

type Props = {
  store: ReturnType<typeof createTableStore>;
  children: ReactNode;
};

const TableStoreContext = createContext<Context | undefined>(undefined);

const TableStoreProvider = (props: Props) => {
  const { store, children } = props;

  const value = useMemo(() => store, [store]);

  return <TableStoreContext.Provider value={value}>{children}</TableStoreContext.Provider>;
};

const useTableStoreContext = () => {
  const context = useContext(TableStoreContext);

  if (context === undefined) {
    throw new Error('useTableStoreContext must be used within a TableStoreProvider');
  }

  return context;
};

export { TableStoreProvider, useTableStoreContext };
