import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import botSettingSchema from '../schemas/botSettingSchema';
import paginationSchema from '../schemas/paginationSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceBotSettings = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/bot-settings`, {
    params,
  });

  return paginationSchema(botSettingSchema).parse(response.data);
};

export default loadWorkspaceBotSettings;
