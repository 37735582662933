import api from '../../services/api';
import URLParams from '../../types/URLParams';
import notificationsSettingSchema from '../schemas/notificationsSettingSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadNotificationsSetting = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(`/api/notification/settings/${workspaceId}`, { params });

  return notificationsSettingSchema.parse(response.data);
};

export default loadNotificationsSetting;
