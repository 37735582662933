import { useTranslation } from 'react-i18next';
import { OrderFilterValuesSchema } from '../../api/schemas/orderSchema';
import Filter from '../../components/Filter';
import useFilter from '../../hooks/useFilter';
import { convertCentsToUSDT, convertUSDTToCents } from '../../utils/data';
import { ORDER_STATUS_LABELS, TYPE_LABELS } from './helpers';

interface Fields {
  order_type_in: string[];
  order_status_in: string[];
  amount_ge: string;
  amount_le: string;
  created_ge: string;
  created_le: string;
}

interface Props {
  data?: OrderFilterValuesSchema;
  loading: boolean;
  total?: number;
}

const OrderFilter = (props: Props) => {
  const { data, loading, total } = props;

  const { t } = useTranslation();

  const filter = useFilter<Fields>({
    order_type_in: 'multi',
    order_status_in: 'multi',
    amount_ge: 'single',
    amount_le: 'single',
    created_ge: 'single',
    created_le: 'single',
  });

  const { values, setValue, clearValue } = filter;

  const { order_type_in, order_status_in, amount_ge, amount_le, created_ge, created_le } = values;

  const filterProps = { total, filter };

  if (loading) {
    return (
      <Filter {...filterProps}>
        <Filter.Placeholder />
      </Filter>
    );
  }

  if (!data) return null;

  const { type, status, amount } = data;

  return (
    <Filter {...filterProps}>
      <div>
        {status.length !== 0 && (
          <Filter.Collapse
            label={t('common.status')}
            count={order_status_in.length}
            onClear={clearValue('order_status_in')}
          >
            <Filter.MultiCheckbox
              config={status.map((item) => ({
                value: String(item),
                label: ORDER_STATUS_LABELS[item],
                onChange: setValue('order_status_in'),
                checked: order_status_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {type.length !== 0 && (
          <Filter.Collapse
            label={t('common.type')}
            count={order_type_in.length}
            onClear={clearValue('order_type_in')}
          >
            <Filter.MultiCheckbox
              config={type.map((item) => ({
                value: String(item),
                label: TYPE_LABELS[item],
                onChange: setValue('order_type_in'),
                checked: order_type_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        <Filter.RangeInput
          range={false}
          label={t('common.amount')}
          step={1}
          decimalScale={6}
          min={amount.min}
          max={amount.max}
          valueMin={amount_ge}
          valueMax={amount_le}
          fieldMin="amount_ge"
          fieldMax="amount_le"
          formatter={convertCentsToUSDT}
          valueFormatter={convertUSDTToCents}
        />
        <Filter.Collapse
          label={t('common.created')}
          {...((created_ge || created_le) && {
            onClear: clearValue(['created_ge', 'created_le']),
          })}
        >
          <Filter.Date
            from={{ value: created_ge, onChange: setValue('created_ge') }}
            to={{ value: created_le, onChange: setValue('created_le') }}
          />
        </Filter.Collapse>
      </div>
    </Filter>
  );
};

export default OrderFilter;
