import Error from '../Error';
import Input from '../Input';
import Range, { Props as RangeProps } from '../Range';
import styles from './styles.module.scss';

interface Props {
  label: string;
  min: RangeProps['min'];
  max: RangeProps['max'];
  step: RangeProps['step'];
  values: RangeProps['values'];
  onFromChange: RangeProps['onFromChange'];
  error?: string;
}

const RangeInput = (props: Props) => {
  const { values, min, max, label, error, step, onFromChange } = props;

  const [from] = values;

  return (
    <div className={styles.container}>
      <div className={styles.inputs}>
        <span className={styles.label}>{label}</span>
        <Input
          size="small"
          value={String(from)}
          onChange={(event) => {
            const inputValue = Number(event.target.value);

            if (!Number.isNaN(inputValue)) {
              if (inputValue <= max) {
                onFromChange(inputValue);
              } else {
                onFromChange(max);
              }
            }
          }}
        />
      </div>
      <Range min={min} max={max} step={step} values={values} onFromChange={onFromChange} />
      <Error error={error} className={styles.error} />
    </div>
  );
};

export default RangeInput;
