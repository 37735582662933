import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSessionSchema } from '../schemas/session/workspaceSessionSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = WorkspaceSessionSchema;

interface Payload {
  start: Schema['start'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
  payload: Payload;
}

const deleteWorkspaceAccountPlannedSessions = async (args: Args) => {
  const { workspaceId, accountId, payload } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/${accountId}/session/delete-from`,
    payload
  );

  return emptyResponseSchema.parse(response.data);
};

export default deleteWorkspaceAccountPlannedSessions;
