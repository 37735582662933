import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadWorkspaceIdentities } from '../../api';
import { IdentitySchema } from '../../api/schemas/identitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Card from '../../components/Card';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { Column, getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import {
  getDocumentsTypes,
  getPhotosTypes,
  getSocialNetworksTypes,
  getWalletsTypes,
  renderCost,
} from '../../features/workspace-identity/helpers';
import WorkspaceIdentityCost from '../../features/workspace-identity/WorkspaceIdentityCost';
import WorkspaceIdentityCountry from '../../features/workspace-identity/WorkspaceIdentityCountry';
import WorkspaceIdentityCreateButton from '../../features/workspace-identity/WorkspaceIdentityCreateButton';
import WorkspaceIdentityDetailLink from '../../features/workspace-identity/WorkspaceIdentityDetailLink';
import WorkspaceIdentityDocuments from '../../features/workspace-identity/WorkspaceIdentityDocuments';
import WorkspaceIdentityDropdown from '../../features/workspace-identity/WorkspaceIdentityDropdown';
import WorkspaceIdentityFilter from '../../features/workspace-identity/WorkspaceIdentityFilter';
import WorkspaceIdentityPhotos from '../../features/workspace-identity/WorkspaceIdentityPhotos';
import WorkspaceIdentitySocialNetworks from '../../features/workspace-identity/WorkspaceIdentitySocialNetworks';
import WorkspaceIdentityTableSelectionActions from '../../features/workspace-identity/WorkspaceIdentityTableSelectionActions';
import WorkspaceIdentityWallets from '../../features/workspace-identity/WorkspaceIdentityWallets';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedDataNew from '../../hooks/usePaginatedDataNew';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { addWhen } from '../../utils';
import { RouteParams } from '../../utils/generics';
import { checkWorkspaceIdentityPokerFarm } from '../../utils/workspace';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.workspaceIdentity>;

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceIdentitiesPage = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { identityId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search, ...rest } = urlParams;

  const queryKey = [queryKeys.workspaceIdentities({ workspaceId }), urlParams];

  const query = usePaginatedDataNew({
    queryKey,
    queryFn: (params) => loadWorkspaceIdentities({ workspaceId }, { ...urlParams, ...params }),
  });

  const { data, error, checkIsInView } = query;

  const { checkIsCreated } = useWebSocketPaginatedActions<IdentitySchema>({
    queryKey,
    domain: 'Identity',
    updateAction: ['Edited', 'AddOnMarket', 'RemoveFromMarket', 'AccountAdded', 'AccountRemoved'],
    enabled: (message) => message.meta_info?.maintenance_id === workspaceId,
    select: (item) => item,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  const isPokerFarm = checkWorkspaceIdentityPokerFarm(workspaceId);

  return (
    <Table.Provider
      key={JSON.stringify(rest)}
      ids={data.map((item) => item.id)}
      disabledSelectAll={Boolean(search)}
      legacyRowSelection
    >
      <Page
        heading={t('common.identities')}
        extra={
          <PageControls>
            <WorkspaceIdentityCreateButton workspaceId={workspaceId} />
          </PageControls>
        }
        filter={<WorkspaceIdentityFilter workspaceId={workspaceId} />}
        addon={<Outlet />}
      >
        {isPhone && (
          <PageTop
            sorting={[
              { key: 'location.country_name', title: t('common.country'), default: true },
              { key: 'first_name', title: t('common.first_name') },
              { key: 'last_name', title: t('common.last_name') },
              ...addWhen({ key: 'on_market', title: t('common.on_market') }, !isPokerFarm),
            ]}
          />
        )}
        {(isDesktop || isTablet) && (
          <Table
            data={data}
            search={search}
            {...getTableProps(query)}
            className={isPokerFarm ? styles.tablePokerFarm : styles.table}
            columns={[
              {
                key: 'last_name',
                title: t('common.name'),
                sorting: true,
                render: ({ values }) => (
                  <WorkspaceIdentityDetailLink workspaceId={workspaceId} data={values} />
                ),
                pin: 'left',
                select: true,
              },
              ...addWhen<Column<IdentitySchema>>(
                {
                  title: <WorkspaceIdentityCost />,
                  render: ({ values }) => renderCost(values.monthly_cost),
                },
                !isPokerFarm
              ),
              ...addWhen<Column<IdentitySchema>>(
                {
                  title: t('common.min_rent'),
                  render: ({ values }) => values.minimal_rental_period,
                },
                !isPokerFarm
              ),
              {
                key: 'location.country_name',
                title: t('common.ctry'),
                sorting: true,
                render: ({ values }) => <WorkspaceIdentityCountry data={values} />,
              },
              {
                title: t('common.documents'),
                render: ({ values }) => (
                  <WorkspaceIdentityDocuments documents={getDocumentsTypes(values)} />
                ),
              },
              {
                title: t('common.selfies'),
                render: ({ values }) => <WorkspaceIdentityPhotos photos={getPhotosTypes(values)} />,
              },
              {
                title: t('common.wallets'),
                render: ({ values }) => (
                  <WorkspaceIdentityWallets wallets={getWalletsTypes(values)} />
                ),
              },
              {
                title: t('common.social_networks'),
                render: ({ values }) => (
                  <WorkspaceIdentitySocialNetworks
                    socialNetworks={getSocialNetworksTypes(values)}
                  />
                ),
              },
              {
                render: ({ values }) => (
                  <WorkspaceIdentityDropdown workspaceId={workspaceId} data={values} />
                ),
                align: 'right',
                controls: true,
                pin: 'right',
              },
            ]}
            renderRow={(row, index) => (
              <Table.Row
                highlighted={checkIsCreated(row.data.id)}
                active={row.data.id === identityId}
                danger={row.data.is_deleted}
                {...checkIsInView(index)}
                {...row}
              />
            )}
            selectionActions={
              <WorkspaceIdentityTableSelectionActions
                workspaceId={workspaceId}
                queryKey={queryKey}
              />
            }
          />
        )}
        {isPhone && (
          <Card.Group>
            {data.map((item, index) => (
              <Card
                key={item.id}
                highlighted={checkIsCreated(item.id)}
                danger={item.is_deleted}
                {...checkIsInView(index)}
              >
                <Card.Top>
                  <WorkspaceIdentityDetailLink workspaceId={workspaceId} data={item} />
                  <Card.Top.Controls>
                    <WorkspaceIdentityDropdown workspaceId={workspaceId} data={item} />
                  </Card.Top.Controls>
                </Card.Top>
                <Card.Field.Group>
                  {!isPokerFarm && (
                    <Card.Field label={<WorkspaceIdentityCost />}>
                      {renderCost(item.monthly_cost)}
                    </Card.Field>
                  )}
                  {!isPokerFarm && (
                    <Card.Field label={t('common.min_rent')}>
                      {item.minimal_rental_period}
                    </Card.Field>
                  )}
                  <Card.Field label={t('common.ctry')}>
                    <WorkspaceIdentityCountry data={item} />
                  </Card.Field>
                  <Card.Field label={t('common.documents')}>
                    <WorkspaceIdentityDocuments documents={getDocumentsTypes(item)} />
                  </Card.Field>
                  <Card.Field label={t('common.selfies')}>
                    <WorkspaceIdentityPhotos photos={getPhotosTypes(item)} />
                  </Card.Field>
                  <Card.Field label={t('common.wallets')}>
                    <WorkspaceIdentityWallets wallets={getWalletsTypes(item)} />
                  </Card.Field>
                </Card.Field.Group>
              </Card>
            ))}
          </Card.Group>
        )}
      </Page>
    </Table.Provider>
  );
};

export default WorkspaceIdentitiesPage;
