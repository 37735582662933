import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactNode } from 'react';
import SortableListItemHandle from './SortableListItemHandle';

interface Props {
  id: UniqueIdentifier;
  children: ReactNode;
  className?: string;
}

const SortableListItem = (props: Props) => {
  const { id, children, className } = props;

  const { transition, transform, isDragging, setNodeRef } = useSortable({ id });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
    ...(isDragging && { zIndex: 1 }),
  };

  return (
    <div ref={setNodeRef} style={style} className={className}>
      {children}
    </div>
  );
};

export default SortableListItem;

SortableListItem.Handle = SortableListItemHandle;
