import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';
import { WorkspaceTokenSchema } from '../schemas/workspaceTokenSchema';

type Schema = WorkspaceTokenSchema;

interface Payload {
  name?: Schema['name'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  tokenId: Schema['id'];
  payload: Payload;
}

const updateWorkspaceToken = async (args: Args) => {
  const { workspaceId, tokenId, payload } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/token/${tokenId}`,
    payload
  );

  return emptyResponseSchema.parse(response.data);
};

export default updateWorkspaceToken;
