import { z } from 'zod';

const geonamesCountrySchema = z.object({
  country_code: z.string(),
  country_name: z.string(),
  country_id: z.null(),
  name: z.null(),
  geoname_id: z.number(),
  admin_code: z.null(),
  longitude: z.null(),
  latitude: z.null(),
  toponym_name: z.null(),
  fcode: z.null(),
});

export type GeonamesCountrySchema = z.infer<typeof geonamesCountrySchema>;

export default geonamesCountrySchema;
