import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { ReactElement } from 'react';

type Children = (
  args: Pick<ReturnType<typeof useSortable>, 'attributes' | 'listeners' | 'setActivatorNodeRef'>
) => ReactElement;

interface Props {
  id: UniqueIdentifier;
  children: Children;
}

const SortableListItemHandle = (props: Props) => {
  const { id, children } = props;

  const { attributes, listeners, setActivatorNodeRef } = useSortable({ id });

  return children({ attributes, listeners, setActivatorNodeRef });
};

export default SortableListItemHandle;
