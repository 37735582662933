import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { globalDealFilterValuesSchema } from '../schemas/deal/globalDealSchema';

const loadGlobalDealsFilterValues = async (params?: URLParams) => {
  const response = await api.get('/api/deal/filter-values', { params });

  return globalDealFilterValuesSchema.parse(response.data);
};

export default loadGlobalDealsFilterValues;
