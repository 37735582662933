import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { seatControlRuleFilterValuesSchema } from '../schemas/seatControlRuleSchema';

const loadGlobalSeatControlRulesFilterValues = async (params?: URLParams) => {
  const response = await api.get('/api/seat-control/filter-values', { params });

  return seatControlRuleFilterValuesSchema.parse(response.data);
};

export default loadGlobalSeatControlRulesFilterValues;
