import clsx from 'clsx';
import { ElementRef, forwardRef, useState } from 'react';
import { HistorySchema } from '../../../api/schemas/historySchema';
import icon from '../../../static/icons';
import { formatAbsoluteDate } from '../../../utils/date';
import { ACTION_TYPE_COLORS, ACTION_TYPE_LABELS, Enums } from '../helpers';
import HistoryDialogCollapseFieldGroup from './HistoryDialogCollapseFieldGroup';
import styles from './styles.module.scss';

interface Props {
  data: HistorySchema;
  enums?: Enums;
}

const HistoryDialogCollapse = Object.assign(
  forwardRef<ElementRef<'div'>, Props>((props, ref) => {
    const { data, enums } = props;

    const { action_type, author, changes, created_on } = data;

    const [open, setOpen] = useState(true);

    const toggle = () => setOpen((prev) => !prev);

    return (
      <div ref={ref} className={styles.container}>
        <button type="button" className={clsx(styles.button, open && styles.open)} onClick={toggle}>
          <div className={styles.title}>
            <div className={styles.date}>{formatAbsoluteDate(created_on)}</div>
            <span>•</span>
            <div style={{ color: ACTION_TYPE_COLORS[action_type] }} className={styles.type}>
              {ACTION_TYPE_LABELS[action_type]}
            </div>
            {author && (
              <>
                <span>•</span>
                <div className={styles.author}>{author.username}</div>
              </>
            )}
          </div>
          {icon('chevronDown', 16)}
        </button>
        {open && (
          <div className={styles.content}>
            {changes.map((el) => (
              <HistoryDialogCollapseFieldGroup
                key={el.name}
                changes={el}
                actionType={action_type}
                enums={enums}
              />
            ))}
          </div>
        )}
      </div>
    );
  })
);

export default HistoryDialogCollapse;
