import { useTranslation } from 'react-i18next';
import { ActionWithRulesProps } from 'react-querybuilder';
import icon from '../../static/icons';
import Button from '../Button';
import Tooltip from '../Tooltip';

const QueryBuilderAddRuleAction = (props: ActionWithRulesProps) => {
  const { handleOnClick } = props;

  const { t } = useTranslation();

  return (
    <Tooltip label={t('common.rule')}>
      <Button
        variant="light"
        size="small"
        icon={icon('operator', 16)}
        onClick={handleOnClick}
        data-testid="add-rule"
      />
    </Tooltip>
  );
};

export default QueryBuilderAddRuleAction;
