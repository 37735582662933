import clsx from 'clsx';
import styles from './styles.module.scss';

const Placeholder = (props: React.ComponentProps<'div'>) => {
  const { className } = props;

  return <div className={clsx(styles.placeholder, className)} />;
};

export default Placeholder;
