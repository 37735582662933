import { changeWorkspaceAccountPassword } from '../../api';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import ChangePasswordDialog from '../../components/ChangePasswordDialog';

interface Props {
  id: WorkspaceSchema['id'];
  data: WorkspaceAccountSchema;
  onClose: () => void;
}

const WorkspaceAccountChangePasswordDialog = (props: Props) => {
  const { id, data, onClose } = props;

  return (
    <ChangePasswordDialog
      onClose={onClose}
      onSubmit={(values) =>
        changeWorkspaceAccountPassword({
          workspaceId: id,
          accountId: data.id,
          payload: { password: values.password },
        })
      }
    />
  );
};

export default WorkspaceAccountChangePasswordDialog;
