import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadGlobalAccountTablesSearch } from '../../api';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import Empty from '../../components/Empty';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import { can } from '../../utils/permissions';
import AccountDetailTablesTab from '../account/AccountDetailTablesTab';
import { useCloseGlobalAccountTableMutation } from './mutations';
import { useGlobalAccountTablesQuery } from './queries';

interface Props {
  data: GlobalAccountSchema;
}

const GlobalAccountDetailTablesTab = (props: Props) => {
  const { data } = props;

  const { id, is_online } = data;

  const { t } = useTranslation();

  const { data: accountTables, isLoading: accountTablesIsLoading } = useGlobalAccountTablesQuery(
    id,
    is_online
  );

  const {
    data: tablesSearch,
    isLoading: tablesSearchIsLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [queryKeys.globalAccountTablesSearch({ accountId: id })],
    queryFn: () => loadGlobalAccountTablesSearch({ accountId: id }),
    enabled: can.lobby.searchTables,
  });

  const closeTable = useCloseGlobalAccountTableMutation();

  if (accountTablesIsLoading || tablesSearchIsLoading) {
    return <PageAddon.DetailPlaceholder wrapper={false} />;
  }

  if (!accountTables?.tables.length && !tablesSearch?.length && !is_online) {
    return <Empty title={t('common.no_connected_tables')} />;
  }

  if (!accountTables || !tablesSearch) return null;

  return (
    <AccountDetailTablesTab
      tables={accountTables.tables}
      tablesSearch={tablesSearch}
      tablesSearchLoading={isFetching}
      tableClosing={closeTable.isPending}
      canSearchTables={can.lobby.searchTables}
      canLeaveTable={can.lobby.leaveTable}
      onTablesSearchRefresh={refetch}
      onCloseTable={(tableId) => closeTable.mutate({ accountId: id, tableId })}
    />
  );
};

export default GlobalAccountDetailTablesTab;
