import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { ChannelSchema } from '../schemas/channelSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Payload = ChannelSchema['id'][];

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const bulkDeleteWorkspaceChannels = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/channels/bulk-delete`,
    payload
  );

  /* return emptyResponseSchema.parse(response.data); */
  /* TODO: схема должна быть пустой */
  return response.data;
};

export default bulkDeleteWorkspaceChannels;
