import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { GlobalSuperLeagueSchema } from '../schemas/globalSuperLeagueSchema';

interface Args {
  superLeagueId: GlobalSuperLeagueSchema['id'];
}

const deleteGlobalSuperLeague = async (args: Args) => {
  const { superLeagueId } = args;

  const response = await api.delete(`/api/reference/super-league/${superLeagueId}`);

  return emptyResponseSchema.parse(response.data);
};

export default deleteGlobalSuperLeague;
