import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { SeatControlRuleSchema } from '../schemas/seatControlRuleSchema';

type Schema = SeatControlRuleSchema;

interface Payload {
  name?: Schema['name'];
  query?: Schema['query'];
  action_type?: Schema['action_type'];
}

interface Args {
  seatControlRuleId: Schema['id'];
  payload: Payload;
}

const updateGlobalSeatControlRule = async (args: Args) => {
  const { seatControlRuleId, payload } = args;

  const response = await api.patch(`/api/seat-control/${seatControlRuleId}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateGlobalSeatControlRule;
