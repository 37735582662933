import { ReactNode } from 'react';
import CardTopControls from './CardTopControls';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
}

const CardTop = (props: Props) => {
  const { children } = props;

  return <div className={styles.container}>{children}</div>;
};

CardTop.Controls = CardTopControls;

export default CardTop;
