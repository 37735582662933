import Button from '../../../components/Button';
import Stub from '../../../components/Stub';
import icon from '../../../static/icons';
import styles from './styles.module.scss';

interface Props {
  onClick: () => void;
  buttonText: string;
  children: string;
}

const WorkspaceSettingsEmpty = (props: Props) => {
  const { children, buttonText, onClick } = props;

  return (
    <div data-centered className={styles.container}>
      <Stub title={children} />
      <Button variant="secondary" icon={icon('add', 16)} onClick={onClick}>
        {buttonText}
      </Button>
    </div>
  );
};

export default WorkspaceSettingsEmpty;
