import clsx from 'clsx';
import { ReactNode, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import icon from '../../../static/icons';
import Button from '../../Button';
import Indicator from '../../Indicator';
import Tooltip from '../../Tooltip';
import { getUrlParams } from './helpers';
import PageAddonFilterPlaceholder from './PageAddonFilterPlaceholder';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

const PageAddonFilter = (props: Props) => {
  const { children, className } = props;

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const toggle = () => setOpen((prev) => !prev);

  const form = useFormContext();

  const {
    watch,
    reset,
    formState: { isDirty },
  } = form;

  return (
    <>
      {open && (
        <div className={clsx(styles.container, className)}>
          <div className={styles.content}>{children}</div>
          <div className={styles.controls}>
            <Button onClick={() => setOpen(false)}>{t('common.close')}</Button>
            <Button danger variant="transparent" disabled={!isDirty} onClick={() => reset()}>
              {t('common.reset')}
            </Button>
          </div>
        </div>
      )}
      <Indicator variant="dot" count={Object.keys(getUrlParams(watch())).length}>
        <Tooltip label={t('common.filters')} hideOnMobile>
          <Button size="small" variant="transparent" icon={icon('filter', 16)} onClick={toggle} />
        </Tooltip>
      </Indicator>
    </>
  );
};

PageAddonFilter.Placeholder = PageAddonFilterPlaceholder;

export default PageAddonFilter;
