import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Payload {
  name?: WorkspaceSchema['name'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const updateWorkspace = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.patch(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateWorkspace;
