/* eslint-disable react/button-has-type */

import clsx from 'clsx';
import { useState, ReactNode } from 'react';
import { useDropzone, DropzoneProps } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import icon from '../../static/icons';
import styles from './styles.module.scss';

interface Props extends Omit<DropzoneProps, 'children'> {
  children: ReactNode;
  className?: string;
}

const Dropzone = (props: Props) => {
  const { disabled, className, children, onDropRejected, ...rest } = props;

  const { t } = useTranslation();

  const [shouldShake, setShouldShake] = useState(false);

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    disabled,
    onDropRejected: (...args) => {
      setShouldShake(true);

      if (onDropRejected) onDropRejected(...args);
    },
    ...rest,
  });

  const getTitle = () => {
    if (isDragActive) {
      return (
        <>
          {icon('add', 16)}
          {t('sentences.drop_it_right_here')}
        </>
      );
    }

    return (
      <div>
        <span className={styles.loadButton}>
          {icon('upload', 16)}
          {t('sentences.accounts_import_browse_file_1')}
        </span>
        &nbsp;
        <span className={styles.loadText}>{t('sentences.accounts_import_browse_file_2')} </span>
      </div>
    );
  };

  const handleAnimationEnd = () => setShouldShake(false);

  const hasFileRejections = fileRejections.length;

  return (
    <button
      {...getRootProps({
        type: 'button',
        disabled,
        className: clsx(styles.container, {
          [styles.error]: hasFileRejections,
          [styles.shake]: shouldShake,
          ...(className && { [className]: true }),
        }),
        onAnimationEnd: handleAnimationEnd,
      })}
    >
      <input {...getInputProps()} />
      <div className={styles.title}>{getTitle()}</div>
      {!isDragActive && (
        <span
          className={clsx(
            styles.restrictions,
            disabled && styles.disabled,
            hasFileRejections && styles.error
          )}
        >
          {children}
        </span>
      )}
    </button>
  );
};

export default Dropzone;
