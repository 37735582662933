import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { workspaceFilterValuesSchema } from '../schemas/workspaceSchema';

const loadGlobalWorkspacesFilterValues = async (params?: URLParams) => {
  const response = await api.get(`/api/global/${ALIASES.WORKSPACE.L}/filter-values`, { params });

  return workspaceFilterValuesSchema.parse(response.data);
};

export default loadGlobalWorkspacesFilterValues;
