import { z } from 'zod';
import globalUserSchema from './globalUserSchema';

const workspaceUserSchema = globalUserSchema
  .pick({
    id: true,
    username: true,
    name: true,
    avatar: true,
    verified: true,
    is_deleted: true,
    created_on: true,
    modified_on: true,
  })
  .extend({
    is_owner: globalUserSchema.shape.maintenances.element.shape.is_owner,
    invited_on: globalUserSchema.shape.maintenances.element.shape.invited_on,
  });

export type WorkspaceUserSchema = z.infer<typeof workspaceUserSchema>;

export default workspaceUserSchema;
