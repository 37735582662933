import api from '../../services/api';
import globalLeagueSchema, { GlobalLeagueSchema } from '../schemas/globalLeagueSchema';

type Schema = GlobalLeagueSchema;

interface Payload {
  name: Schema['name'];
  code: Schema['code'];
  poker_room: Schema['poker_room'];
  super_league_id: Exclude<Schema['super_league'], null>['id'] | null;
  extra: Schema['extra'];
}

interface Args {
  payload: Payload;
}

const createGlobalLeague = async (args: Args) => {
  const { payload } = args;

  const response = await api.post('/api/reference/league', payload);

  return globalLeagueSchema.pick({ id: true }).parse(response.data);
};

export default createGlobalLeague;
