import clsx from 'clsx';
import { format, isSameHour, isSameMinute } from 'date-fns';
import { ElementRef, useEffect, useRef } from 'react';
import usePrevious from '../../../hooks/usePrevious';
import { useDatePickerContext } from '../DatePickerContext';
import commonStyles from '../styles.module.scss';
import styles from './styles.module.scss';

const DatePickerTime = () => {
  const { date, value, hours, minutes, setHour, setMinute } = useDatePickerContext();

  const refHourButton = useRef<ElementRef<'button'>>(null);
  const refMinuteButton = useRef<ElementRef<'button'>>(null);

  const prevDate = usePrevious(date);

  useEffect(() => {
    refHourButton.current?.scrollIntoView({ block: 'center' });
    refMinuteButton.current?.scrollIntoView({ block: 'center' });
  }, []);

  useEffect(() => {
    if (!isSameHour(date, prevDate)) {
      refMinuteButton.current?.scrollIntoView({ block: 'center' });
    }
  }, [date, prevDate]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.title}>H</div>
        <div className={styles.list}>
          {hours.map((hour) => {
            const isSelected = isSameHour(hour, new Date(value));

            const className = clsx(commonStyles.date, {
              [commonStyles.selected]: isSelected,
            });

            const handleClick = () => setHour(hour);

            return (
              <button
                key={String(hour)}
                type="button"
                onClick={handleClick}
                className={className}
                {...(isSelected && { ref: refHourButton })}
              >
                {format(hour, 'HH')}
              </button>
            );
          })}
        </div>
      </div>
      <div className={styles.wrapper}>
        <span className={styles.title}>M</span>
        <div className={styles.list}>
          {minutes.map((minute) => {
            const isSelected = isSameMinute(minute, new Date(value));

            const className = clsx(commonStyles.date, {
              [commonStyles.selected]: isSelected,
            });

            const handleClick = () => setMinute(minute);

            return (
              <button
                key={String(minute)}
                type="button"
                onClick={handleClick}
                className={className}
                {...(isSelected && { ref: refMinuteButton })}
              >
                {format(minute, 'mm')}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DatePickerTime;
