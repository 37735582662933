import { differenceInMinutes } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { STATUS } from '../../api/schemas/session';
import { MINUTE } from '../../constants';
import { displayMinuteInHours } from '../../utils/data';
import { CommonSessionSchema } from './types';

interface Args {
  data: CommonSessionSchema;
}

const useDuration = (args: Args) => {
  const { data } = args;

  const { start, end, status } = data;

  const getTimeRemains = useCallback(() => {
    if (status === STATUS.RUNNING) return differenceInMinutes(new Date(end), new Date());

    return null;
  }, [end, status]);

  const duration = differenceInMinutes(new Date(end), new Date(start));

  const [timeRemains, setTimeRemains] = useState(getTimeRemains);

  const getDelay = () => {
    if (timeRemains && timeRemains < 0) return Math.abs(timeRemains);

    if (status === STATUS.SENT_FINISH) {
      const minutesLeft = differenceInMinutes(new Date(end), new Date());

      if (minutesLeft < 0) return Math.abs(minutesLeft);

      return null;
    }

    return null;
  };

  const delay = getDelay();

  const durationInHours = `~${displayMinuteInHours(duration)}`;
  const timeRemainsInHours = displayMinuteInHours(timeRemains);
  const delayInHours = displayMinuteInHours(delay);

  const delayed = Boolean(delay);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemains(getTimeRemains());
    }, MINUTE);

    return () => clearInterval(intervalId);
  }, [getTimeRemains]);

  return {
    duration,
    timeRemains,
    delay,
    durationInHours,
    timeRemainsInHours,
    delayInHours,
    delayed,
  };
};

export default useDuration;
