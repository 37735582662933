import Spinner from '../../../components/Spinner';
import styles from './styles.module.scss';

const AccountScheduleSpinner = () => (
  <div className={styles.container}>
    <Spinner />
  </div>
);

export default AccountScheduleSpinner;
