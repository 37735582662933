import { useTranslation } from 'react-i18next';
import { GlobalUserSchema } from '../../api/schemas/globalUserSchema';
import { presets } from '../../components/Dropdown';
import icon from '../../static/icons';
import { can } from '../../utils/permissions';
import { inviteUser, resetPassword } from './helpers';

interface Args {
  data: GlobalUserSchema;
}

const useDropdown = (args: Args) => {
  const { data } = args;

  const { id, username, verified, is_deleted } = data;

  const { t } = useTranslation();

  return {
    title: username,
    disabled: is_deleted,
    options: {
      resetPassword: {
        icon: icon('unlock', 20),
        label: t('common.reset_password'),
        ...(!can.user.createUpdate && { tooltip: t('common.no_permission') }),
        disabled: !can.user.createUpdate,
        confirm: true,
        confirmText: t('common.reset'),
        confirmDanger: false,
        onClick: () => resetPassword({ id, username }),
      },
      ...(!verified && {
        invite: {
          icon: icon('mail', 20),
          label: t('common.send_invite_link'),
          ...(!can.user.createUpdate && { tooltip: t('common.no_permission') }),
          confirm: true,
          confirmText: t('common.send'),
          confirmDanger: false,
          disabled: !can.user.createUpdate,
          onClick: () => inviteUser({ email: username }),
        },
      }),
      copy: presets.copyId(id),
    },
  };
};

export default useDropdown;
