import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalAreaDetail from '../../features/global-area/GlobalAreaDetail';
import GlobalAreaForm from '../../features/global-area/GlobalAreaForm';
import GlobalAreaLoader from '../../features/global-area/GlobalAreaLoader';
import GlobalAreasPage from '../../pages/GlobalAreasPage';
import Route from './Route';

const page = <GlobalAreasPage />;

const createForm = (
  <Route>
    {({ navigate }) => (
      <GlobalAreaForm
        onClose={() => navigate(routes.globalAreas)}
        onCreate={({ areaId }) => navigate(routes.globalArea({ areaId }))}
      />
    )}
  </Route>
);

const detail = (
  <Route params={['areaId']}>
    {({ areaId, navigate }) => (
      <GlobalAreaLoader areaId={areaId}>
        {({ data }) => (
          <GlobalAreaDetail data={data} onClose={() => navigate(routes.globalAreas)} />
        )}
      </GlobalAreaLoader>
    )}
  </Route>
);

const editFormAreaRates = (
  <Route params={['areaId']}>
    {({ areaId, navigate }) => (
      <GlobalAreaLoader areaId={areaId}>
        {({ data }) => (
          <GlobalAreaForm data={data} onClose={() => navigate(routes.globalArea({ areaId }))} />
        )}
      </GlobalAreaLoader>
    )}
  </Route>
);

const globalAreasPage: RouteObject = {
  path: routes.globalAreas,
  element: page,
  children: [
    {
      path: routes.globalAreasCreate,
      element: createForm,
    },
    {
      path: routes.globalArea({ areaId: ':areaId' }),
      element: detail,
    },
    {
      path: routes.globalAreaEditAreaRates({ areaId: ':areaId' }),
      element: editFormAreaRates,
    },
  ],
};

export default globalAreasPage;
