import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadGlobalSessions } from '../../api';
import { GlobalSessionSchema } from '../../api/schemas/session/globalSessionSchema';
import Card from '../../components/Card';
import FadingText from '../../components/FadingText';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import GlobalSessionDetailLink from '../../features/global-session/GlobalSessionDetailLink';
import GlobalSessionDropdown from '../../features/global-session/GlobalSessionDropdown';
import GlobalSessionFilter from '../../features/global-session/GlobalSessionFilter';
import { renderClubs } from '../../features/session/helpers';
import SessionDuration from '../../features/session/SessionDuration';
import SessionStatus from '../../features/session/SessionStatus';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedData from '../../hooks/usePaginatedData';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { addWhen } from '../../utils';
import { RouteParams } from '../../utils/generics';
import { can } from '../../utils/permissions';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.globalSession>;

const GlobalSessionsPage = () => {
  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { sessionId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search } = urlParams;

  const queryKey = [queryKeys.globalSessions, urlParams];

  const query = usePaginatedData({
    queryKey,
    queryFn: (params) => loadGlobalSessions({ ...urlParams, ...params }),
  });

  const { data, total, loading, error, checkIsInView } = query;

  const { checkIsCreated, ...meta } = useWebSocketPaginatedActions<GlobalSessionSchema>({
    queryKey,
    domain: 'GlobalSession',
    updateAction: ['Edited', 'SessionStarted', 'SessionFinished', 'SwitchOnRoom', 'SwitchOffRoom'],
    select: (item) => item,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <Page
      heading={t('common.sessions')}
      filter={<GlobalSessionFilter urlParams={urlParams} total={total} />}
      tabs={[
        ...addWhen({ to: routes.globalAccounts, label: t('common.accounts') }, can.account.read),
        {
          to: routes.globalSessions,
          label: t('common.sessions'),
          active: true,
        },
      ]}
      extra={<PageControls />}
      addon={<Outlet />}
    >
      <PageTop
        total={total}
        loading={loading}
        {...(isPhone && {
          sorting: [
            { key: 'start', title: t('common.start'), default: true },
            { key: 'end', title: t('common.end') },
          ],
        })}
        meta={meta}
      />
      {(isDesktop || isTablet) && (
        <Table
          data={data}
          search={search}
          {...getTableProps(query)}
          className={styles.table}
          columns={[
            {
              key: 'start',
              title: t('common.date'),
              sorting: true,
              render: ({ values }) => <GlobalSessionDetailLink data={values} />,
            },
            {
              title: t('common.status'),
              render: ({ values }) => <SessionStatus data={values} />,
            },
            {
              title: t('common.duration'),
              render: ({ values }) => <SessionDuration data={values} />,
            },
            {
              title: t('common.clubs'),
              render: ({ values }) => renderClubs(values.clubs),
            },
            {
              title: t('common.workspace'),
              render: ({ values }) => <FadingText text={values.account.maintenance.name} />,
            },
            {
              render: ({ values }) => <GlobalSessionDropdown data={values} />,
              align: 'right',
              controls: true,
            },
          ]}
          renderRow={(row, index) => (
            <Table.Row
              highlighted={checkIsCreated(row.data.id)}
              active={row.data.id === sessionId}
              danger={row.data.is_deleted}
              {...checkIsInView(index)}
              {...row}
            />
          )}
        />
      )}
      {isPhone && (
        <Card.Group>
          {data.map((item, index) => (
            <Card
              key={item.id}
              highlighted={checkIsCreated(item.id)}
              danger={item.is_deleted}
              {...checkIsInView(index)}
            >
              <Card.Top>
                <GlobalSessionDetailLink data={item} />
                <Card.Top.Controls>
                  <GlobalSessionDropdown data={item} />
                </Card.Top.Controls>
              </Card.Top>
              <Card.Field.Group>
                <Card.Field label={t('common.status')}>
                  <SessionStatus data={item} />
                </Card.Field>
                <Card.Field label={t('common.duration')}>
                  <SessionDuration data={item} />
                </Card.Field>
                <Card.Field label={t('common.clubs')}>{renderClubs(item.clubs)}</Card.Field>
              </Card.Field.Group>
            </Card>
          ))}
        </Card.Group>
      )}
    </Page>
  );
};

export default GlobalSessionsPage;
