import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { NotificationsSettingSchema } from '../schemas/notificationsSettingSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = NotificationsSettingSchema;

interface Payload {
  action_types?: Schema['action_types'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const updateNotificationsSetting = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.patch(`/api/notification/settings/${workspaceId}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateNotificationsSetting;
