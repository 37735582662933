import icon from '../../../static/icons';
import { getShortName } from '../../../utils/data';
import styles from './styles.module.scss';

interface Props {
  name: string;
}

const SidebarAvatar = (props: Props) => {
  const { name } = props;

  return <div className={styles.container}>{name ? getShortName(name) : icon('add', 24)}</div>;
};

export default SidebarAvatar;
