import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadWorkspaceMarketIdentitiesFilterValues } from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Filter from '../../components/Filter';
import queryKeys from '../../config/queryKeys';
import useFilter from '../../hooks/useFilter';
import useURL from '../../hooks/useURL';
import { convertCentsToUSDT, convertUSDTToCents } from '../../utils/data';
import {
  PHOTOS_LABELS,
  styleIcon,
  WALLETS_LABELS,
  WALLETS_ICONS_16,
  DOCUMENTS_LABELS,
  DOCUMENTS_ICONS_16,
} from '../workspace-identity/helpers';

interface Fields {
  /* min_rental_ge: string;
  min_rental_le: string; */
  monthly_cost_ge: string;
  monthly_cost_le: string;
  country_in: string[];
  online_wallet_in: string[];
  documents_in: string[];
  photos_in: string[];
}

interface Props {
  workspaceId: WorkspaceSchema['id'];
  total?: number;
}

const WorkspaceMarketIdentityFilter = (props: Props) => {
  const { workspaceId, total } = props;

  const { t } = useTranslation();
  const { urlParams } = useURL();

  const queryKey = [queryKeys.workspaceMarketIdentitiesFilterValues({ workspaceId }), urlParams];
  const queryFn = () => loadWorkspaceMarketIdentitiesFilterValues({ workspaceId }, urlParams);

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  const filter = useFilter<Fields>({
    monthly_cost_ge: 'single',
    monthly_cost_le: 'single',
    country_in: 'multi',
    online_wallet_in: 'multi',
    documents_in: 'multi',
    photos_in: 'multi',
  });

  const { values, setValue, clearValue } = filter;

  const {
    monthly_cost_ge,
    monthly_cost_le,
    country_in,
    online_wallet_in,
    documents_in,
    photos_in,
  } = values;

  const filterProps = { total, filter };

  if (isPending) {
    return (
      <Filter {...filterProps}>
        <Filter.Placeholder />
      </Filter>
    );
  }

  if (!data) return null;

  const { monthly_cost, country, online_wallets, documents, photos } = data;

  return (
    <Filter {...filterProps}>
      <div>
        <Filter.RangeInput
          label={t('common.cost_month_USDT')}
          step={1}
          decimalScale={6}
          min={monthly_cost.min}
          max={monthly_cost.max}
          valueMin={monthly_cost_ge}
          valueMax={monthly_cost_le}
          fieldMin="monthly_cost_ge"
          fieldMax="monthly_cost_le"
          formatter={convertCentsToUSDT}
          valueFormatter={convertUSDTToCents}
        />
        {country.length !== 0 && (
          <Filter.Collapse
            label={t('common.country')}
            count={country_in.length}
            onClear={clearValue('country_in')}
          >
            <Filter.MultiCheckbox
              config={country.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('country_in'),
                checked: country_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        {online_wallets.length !== 0 && (
          <Filter.Collapse
            label={t('common.wallet')}
            count={online_wallet_in.length}
            onClear={clearValue('online_wallet_in')}
          >
            <Filter.MultiCheckbox
              config={online_wallets.map((item) => ({
                value: String(item.id),
                label: WALLETS_LABELS[item.id],
                onChange: setValue('online_wallet_in'),
                checked: online_wallet_in.includes(String(item.id)),
                extra: WALLETS_ICONS_16[item.id],
              }))}
            />
          </Filter.Collapse>
        )}
        {documents.length !== 0 && (
          <Filter.Collapse
            label={t('common.document')}
            count={documents_in.length}
            onClear={clearValue('documents_in')}
          >
            <Filter.MultiCheckbox
              config={documents.map((item) => {
                const icon = DOCUMENTS_ICONS_16[item.id];

                return {
                  value: String(item.id),
                  label: DOCUMENTS_LABELS[item.id],
                  onChange: setValue('documents_in'),
                  checked: documents_in.includes(String(item.id)),
                  ...(icon && { extra: styleIcon(icon) }),
                };
              })}
            />
          </Filter.Collapse>
        )}
        {photos.length !== 0 && (
          <Filter.Collapse
            label={t('common.selfie')}
            count={photos_in.length}
            onClear={clearValue('photos_in')}
          >
            <Filter.MultiCheckbox
              config={photos.map((item) => ({
                value: String(item.id),
                label: PHOTOS_LABELS[item.id],
                onChange: setValue('photos_in'),
                checked: photos_in.includes(String(item.id)),
              }))}
            />
          </Filter.Collapse>
        )}
      </div>
    </Filter>
  );
};

export default WorkspaceMarketIdentityFilter;
