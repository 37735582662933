import { IdentitySchema } from '../../../api/schemas/identitySchema';
import Tooltip from '../../../components/Tooltip';
import { SOCIAL_NETWORKS_ICONS_16, SOCIAL_NETWORKS_LABELS } from '../helpers';
import styles from './styles.module.scss';

type SocialNetwork = IdentitySchema['social_networks'][number]['type'];

type SocialNetworks = SocialNetwork[];

interface Props {
  socialNetworks: SocialNetworks;
}

const WorkspaceIdentitySocialNetworks = (props: Props) => {
  const { socialNetworks } = props;

  const uniqSocialNetworks = new Set<SocialNetwork>();

  socialNetworks.forEach((item) => uniqSocialNetworks.add(item));

  const SocialNetworksTypes = Array.from(uniqSocialNetworks);

  return (
    <div className={styles.container}>
      {SocialNetworksTypes.length
        ? SocialNetworksTypes.map((item) =>
            item ? (
              <Tooltip key={item} label={SOCIAL_NETWORKS_LABELS[item]}>
                {SOCIAL_NETWORKS_ICONS_16[item]}
              </Tooltip>
            ) : null
          )
        : '—'}
    </div>
  );
};

export default WorkspaceIdentitySocialNetworks;
