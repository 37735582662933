import { useUserStore } from '../store/user';

const useWorkspace = () => {
  const { workspace } = useUserStore();

  if (!workspace) throw new Error();

  return workspace;
};

export default useWorkspace;
