import Map from '../../../components/Map';

const StorybookPageMap = () => (
  <>
    <h2>Map</h2>
    <Map />
  </>
);

export default StorybookPageMap;
