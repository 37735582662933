import { MarketIdentitySchema } from '../../api/schemas/marketIdentitySchema';
import Dropdown from '../../components/Dropdown';
import useDropdown from './useDropdown';

interface Props {
  data: MarketIdentitySchema;
}

const WorkspaceMarketIdentityDropdown = (props: Props) => {
  const { title, disabled, options } = useDropdown(props);

  return <Dropdown title={title} buttonSize="small" disabled={disabled} options={[options.copy]} />;
};

export default WorkspaceMarketIdentityDropdown;
