import api from '../../services/api';
import { GlobalAccountSchema } from '../schemas/account/globalAccountSchema';
import { globalAccountTableSchema } from '../schemas/globalAccountTableSchema';

interface Args {
  accountId: GlobalAccountSchema['id'];
}

const loadGlobalAccountTables = async (args: Args) => {
  const { accountId } = args;

  const response = await api.get(`/api/lobby/account/${accountId}`);

  return globalAccountTableSchema.parse(response.data);
};

export default loadGlobalAccountTables;
