import api from '../../services/api';
import URLParams from '../../types/URLParams';
import globalClubSchema from '../schemas/globalClubSchema';
import paginationSchema from '../schemas/paginationSchema';

const loadGlobalClubs = async (params?: URLParams) => {
  const response = await api.get('/api/reference/club', { params });

  return paginationSchema(globalClubSchema).parse(response.data);
};

export default loadGlobalClubs;
