import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { keys, pick } from 'remeda';
import {
  bulkUpdateWorkspaceAccountsStrategyProfile,
  bulkUpdateFilteredWorkspaceAccountsStrategyProfile,
} from '../../../../api';
import { WorkspaceSchema } from '../../../../api/schemas/workspaceSchema';
import Form from '../../../../components/Form';
import PageAddon from '../../../../components/PageAddon';
import SubmitButton from '../../../../components/SubmitButton';
import routes from '../../../../config/routes';
import useURL from '../../../../hooks/useURL';
import { Fields, getDefaultValues, getPayload } from '../../../account/strategyHelpers';
import useBulkActions from '../../useBulkActions';
import useSelectedFields from '../../useSelectedFields';
import WorkspaceAccountFormAdd from '../../WorkspaceAccountForm/WorkspaceAccountFormAdd';
import WorkspaceAccountFormField from '../../WorkspaceAccountForm/WorkspaceAccountFormField';
import { getFields, labels } from './helpers';

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceAccountStrategyProfileBulkForm = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();

  const { navigate } = useURL();

  const form = useForm<Fields>({ defaultValues: getDefaultValues() });

  const fields = getFields(form);

  const { ids, params, selectAllMode, handle } = useBulkActions();

  const { selectedFields, disabled, addField, removeField, fieldDisabled } = useSelectedFields({
    fields,
  });

  const onClose = () => navigate(routes.workspaceAccounts({ workspaceId }));

  return (
    <PageAddon
      title={t('common.bulk_edit_strategy')}
      onClose={() => navigate(routes.workspaceAccounts({ workspaceId }))}
    >
      <Form
        form={form}
        onSubmit={(values) =>
          handle(async () => {
            const fn = selectAllMode
              ? bulkUpdateFilteredWorkspaceAccountsStrategyProfile
              : bulkUpdateWorkspaceAccountsStrategyProfile;

            await fn({
              workspaceId,
              payload: {
                account_ids: ids,
                to_edit: pick(
                  getPayload({ values }),
                  selectedFields.flatMap((item) => {
                    if (item === 'buyin_bb_min') return [item, 'buyin_bb_max'] as const;

                    if (item === 'buyin_bb_straddle_table_min') {
                      return [item, 'buyin_bb_straddle_table_max'] as const;
                    }

                    return item;
                  })
                ),
              },
              params,
            });

            onClose();
          })
        }
      >
        {selectedFields.map((item) => (
          <WorkspaceAccountFormField key={item} onRemove={() => removeField(item)}>
            {fields[item]}
          </WorkspaceAccountFormField>
        ))}
        <WorkspaceAccountFormAdd
          options={keys(fields).map((item) => ({
            label: labels[item],
            disabled: fieldDisabled(item),
            onClick: () => addField(item),
          }))}
        />
        <PageAddon.Controls>
          <SubmitButton disabled={disabled}>{t('common.save')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default WorkspaceAccountStrategyProfileBulkForm;
