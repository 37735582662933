import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import clsx from 'clsx';
import { Fragment, useState, useRef, ElementRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import useBreakpoints from '../../../hooks/useBreakpoints';
import icon from '../../../static/icons';
import Button from '../../Button';
import SearchInput from '../../SearchInput';
import SheetTop from '../../SheetTop';
import Spinner from '../../Spinner';
import { useSelectContext } from '../SelectContext';
import { OnChange, OptionValue } from '../types';
import styles from './styles.module.scss';

interface Props<T extends OptionValue = string> {
  onChange: OnChange<T>;
  children?: ReactNode;
}

const SelectOptions = <T extends OptionValue = string>(props: Props<T>) => {
  const { onChange, children } = props;

  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  const [translateY, setTranslateY] = useState<number | null>(null);

  const {
    open,
    multi,
    label,
    search,
    options,
    selected,
    activeIndex,
    listRef,
    loading,
    quick,
    creatable,
    popupSize,
    setOpen,
    setSearch,
    setActiveIndex,
    onLastOptionInView,
    getFloatingProps,
    getItemProps,
    floating: { refs, context, floatingStyles },
  } = useSelectContext<T>();

  const { ref: optionRef } = useInView({
    onChange: (inView) => {
      if (onLastOptionInView && inView) {
        onLastOptionInView();
      }
    },
  });

  const searchRef = useRef<ElementRef<'input'>>(null);

  const close = () => setOpen(false);

  if (!open) return null;

  const searchInput = (
    <div className={clsx(styles.search, quick && !isMobile && styles.quick)}>
      <SearchInput
        ref={searchRef}
        value={search}
        {...(quick &&
          !isMobile && {
            icon: false,
            size: 'small',
            className: styles.searchQuick,
            renderExtra: ({ clear }) => (
              <button type="button" onClick={clear} className={styles.clearQuick}>
                {icon('cross', 20)}
              </button>
            ),
          })}
        onChange={(value) => {
          setSearch(value);
          setActiveIndex(null);
        }}
        {...getItemProps()}
      />
    </div>
  );

  listRef.current[0] = searchRef.current;

  const content = (
    <FloatingFocusManager context={context}>
      <div
        className={clsx(styles.popup, quick && styles.quick)}
        {...(!isMobile && {
          ref: refs.setFloating,
          style: {
            ...floatingStyles,
            [popupSize === 'auto' ? 'minWidth' : 'width']:
              refs.reference.current?.getBoundingClientRect().width,
          },
          ...getFloatingProps(),
        })}
      >
        <div
          className={styles.options}
          style={{
            ...(translateY === null
              ? { transition: 'transform 0.1s ease-in-out' }
              : { transform: `translateY(${translateY}px)` }),
          }}
          {...(isMobile && { ref: refs.setFloating, ...getFloatingProps() })}
        >
          {isMobile ? (
            <SheetTop
              title={typeof label === 'string' ? label : label?.text}
              onCancel={close}
              setTranslateY={setTranslateY}
            >
              {searchInput}
            </SheetTop>
          ) : (
            searchInput
          )}
          {children}
          <div className={styles.optionsList}>
            {options.map((option, index) => {
              const indexWithSearch = index + 1;
              const isSelected = selected.includes(option.value);

              return (
                <Fragment key={option.value}>
                  {option.separator && <div className={styles.separator}>{option.separator}</div>}
                  <button
                    ref={(node) => {
                      listRef.current[indexWithSearch] = node;

                      if (indexWithSearch === options.length) {
                        optionRef(node);
                      }
                    }}
                    type="button"
                    role="option"
                    disabled={option.disabled}
                    aria-selected={activeIndex === indexWithSearch}
                    className={styles.option}
                    {...getItemProps({
                      onClick: () => {
                        onChange(option);

                        if (!multi) {
                          close();
                          setSearch('');
                        }
                      },
                      onKeyDown: (event) => {
                        if (event.code === 'Slash') {
                          event.preventDefault();
                          searchRef.current?.focus();
                        }
                      },
                    })}
                  >
                    <div>
                      {option.icon}
                      <div className={styles.optionLabel}>
                        <span {...(option.danger && { className: styles.danger })}>
                          {option.label}
                        </span>
                        <span>{option.caption}</span>
                      </div>
                    </div>
                    <div>
                      {option.endIcon}
                      {isSelected && icon('check', 16, { className: styles.check })}
                    </div>
                  </button>
                </Fragment>
              );
            })}
          </div>
          {!options.length && !loading && !creatable && (
            <div ref={optionRef} className={styles.empty}>
              {t('common.no_options')}
            </div>
          )}
          {isMobile && multi && options.length !== 0 && (
            <div className={styles.apply}>
              <Button onClick={close}>{t('common.apply')}</Button>
            </div>
          )}
          {loading && (
            <div className={styles.spinner}>
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </FloatingFocusManager>
  );

  return <FloatingPortal id="root">{content}</FloatingPortal>;
};

export default SelectOptions;
