import { z } from 'zod';
import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import channelSchema from '../schemas/channelSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Payload = FormData;

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const bulkCreateWorkspaceChannels = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/channel/bulk-create`,
    payload,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );

  return z.array(channelSchema.pick({ id: true })).parse(response.data);
};

export default bulkCreateWorkspaceChannels;
