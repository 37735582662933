import { z } from 'zod';
import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Payload {
  account_ids: WorkspaceAccountSchema['id'][];
  columns: number[];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const exportWorkspaceAccounts = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/export`,
    payload
  );

  return z.string().parse(response.data);
};

export default exportWorkspaceAccounts;
