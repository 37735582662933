import { z, ZodType } from 'zod';

const ruleSchema = z.object({
  field: z.string(),
  operator: z.string(),
  value: z.union([z.string(), z.number(), z.boolean(), z.array(z.string().or(z.number()))]),
});

export type RuleSchema = z.infer<typeof ruleSchema>;

interface RuleGroup {
  combinator: string;
  rules: Array<RuleSchema | RuleGroup>;
}

const ruleGroupSchema: ZodType<RuleGroup> = z.object({
  combinator: z.string(),
  rules: z.array(z.lazy(() => z.union([ruleSchema, ruleGroupSchema]))),
});

const querySchema = ruleGroupSchema;

export type QuerySchema = z.infer<typeof ruleGroupSchema>;

export default querySchema;
