import { z } from 'zod';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { GlobalAccountSchema } from '../schemas/account/globalAccountSchema';
import globalSessionSchema from '../schemas/session/globalSessionSchema';

interface Args {
  accountId: GlobalAccountSchema['id'];
}

const loadGlobalAccountSessions = async (args: Args, params?: URLParams) => {
  const { accountId } = args;

  const response = await api.get(`/api/account/${accountId}/session`, { params });

  return z.array(globalSessionSchema).parse(response.data);
};

export default loadGlobalAccountSessions;
