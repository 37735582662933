import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DOCUMENT_TYPE } from '../../api/schemas/identity';
import Collapse from '../../components/Collapse';
import DatePicker from '../../components/DatePicker';
import MediaFilesField from '../../components/MediaFilesField';
import PageAddon from '../../components/PageAddon';
import Select from '../../components/Select';
import { MB } from '../../constants';
import { DAY } from '../../constants/DATE_FORMATS';
import { required, validDateFormat } from '../../utils/form';
import { DOCUMENTS_LABELS } from './helpers';
import { Fields } from './types';
import WorkspaceIdentityFormCollapse from './WorkspaceIdentityFormCollapse';

const options = [
  {
    value: DOCUMENT_TYPE.PASSPORT,
    label: DOCUMENTS_LABELS[DOCUMENT_TYPE.PASSPORT],
  },
  {
    value: DOCUMENT_TYPE.NATIONAL_ID_CARD,
    label: DOCUMENTS_LABELS[DOCUMENT_TYPE.NATIONAL_ID_CARD],
  },
  {
    value: DOCUMENT_TYPE.DRIVING_LICENSE,
    label: DOCUMENTS_LABELS[DOCUMENT_TYPE.DRIVING_LICENSE],
  },
  {
    value: DOCUMENT_TYPE.UTILITY_BILL,
    label: DOCUMENTS_LABELS[DOCUMENT_TYPE.UTILITY_BILL],
  },
  {
    value: DOCUMENT_TYPE.BANK_STATEMENT,
    label: DOCUMENTS_LABELS[DOCUMENT_TYPE.BANK_STATEMENT],
  },
];

const WorkspaceIdentityFormDocuments = () => {
  const { t } = useTranslation();

  const {
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useFormContext<Fields>();

  const { fields, append, remove } = useFieldArray({ control, name: 'documents' });

  return (
    <PageAddon.DynamicFields
      label={t('common.documents')}
      onAppend={() =>
        append({ issued: '', expire: '', type: null, files: [] }, { shouldFocus: false })
      }
    >
      {fields.map((dynamicField, index) => {
        const { type } = watch(`documents.${index}`);

        return (
          <WorkspaceIdentityFormCollapse
            key={dynamicField.id}
            onDelete={() => remove(index)}
            defaultOpen={!dynamicField.files.length}
            label={(type && DOCUMENTS_LABELS[type.value]) || `${t('common.document')} ${index + 1}`}
            error={errors.documents ? Boolean(errors.documents[index]) : false}
          >
            <Collapse.FieldGroup>
              <Controller
                control={control}
                name={`documents.${index}.type`}
                rules={{ validate: required }}
                render={({ field }) => (
                  <Select
                    clearable={false}
                    label={`${t('common.type')}*`}
                    options={options}
                    value={field.value}
                    onChange={field.onChange}
                    {...(errors.documents && {
                      // @ts-expect-error
                      error: errors.documents[index]?.type?.message,
                    })}
                  />
                )}
              />
              <Controller
                control={control}
                name={`documents.${index}.issued`}
                rules={{ validate: { required, validDate: validDateFormat(DAY) } }}
                render={({ field }) => (
                  <DatePicker
                    label={`${t('common.date_of_issue')}*`}
                    {...(errors.documents && {
                      error: errors.documents[index]?.issued?.message,
                    })}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name={`documents.${index}.expire`}
                rules={{ validate: { validDate: validDateFormat(DAY) } }}
                render={({ field }) => (
                  <DatePicker
                    label={t('common.date_of_expiry')}
                    {...(errors.documents && {
                      error: errors.documents[index]?.expire?.message,
                    })}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name={`documents.${index}.files`}
                rules={{ validate: required }}
                render={({ field }) => (
                  <MediaFilesField
                    value={field.value}
                    maxSize={3 * MB}
                    onChange={field.onChange}
                    disabled={isSubmitting}
                    {...(errors.documents && {
                      error: errors.documents[index]?.files?.message,
                    })}
                  >
                    {t('sentences.workspace_identities_files_restrictions')}
                  </MediaFilesField>
                )}
              />
            </Collapse.FieldGroup>
          </WorkspaceIdentityFormCollapse>
        );
      })}
    </PageAddon.DynamicFields>
  );
};

export default WorkspaceIdentityFormDocuments;
