import { z } from 'zod';

export const TYPE = {
  UNKNOWN: 0,
  NOUS: 1,
  DEEPLAY: 2,
  ALTAY: 3,
  WEAK_FISH: 4,
  MIDDLE_FISH: 5,
  STRONG_FISH: 6,
  MIDDLE: 7,
  REG: 8,
  NOT_ENOUGH_DATA: 9,
  NO_DATA: 10,
  NOUS_ECO: 11,
  COLLUDER: 12,
  DEEPLAY_HINT: 13,
  DEEPLAY_ECO: 14,
} as const;

const lobbyTablePlayerSchema = z.object({
  pid: z.string(),
  position: z.number(),
  type: z.nativeEnum(TYPE),
});

export type LobbyTablePlayerSchema = z.infer<typeof lobbyTablePlayerSchema>;

export default lobbyTablePlayerSchema;
