import { GlobalLeagueSchema } from '../../api/schemas/globalLeagueSchema';
import Dropdown from '../../components/Dropdown';
import useDropdown from './useDropdown';

interface Props {
  data: GlobalLeagueSchema;
}

const GlobalLeagueDropdown = (props: Props) => {
  const { title, disabled, options } = useDropdown(props);

  return (
    <Dropdown
      title={title}
      buttonSize="small"
      disabled={disabled}
      options={[options.edit, options.copy, options.divider, options.delete]}
    />
  );
};

export default GlobalLeagueDropdown;
