import api from '../../services/api';
import URLParams from '../../types/URLParams';
import botSettingSchema from '../schemas/botSettingSchema';
import paginationSchema from '../schemas/paginationSchema';

const loadGlobalBotSettings = async (params?: URLParams) => {
  const response = await api.get('/api/bot-settings', { params });

  return paginationSchema(botSettingSchema).parse(response.data);
};

export default loadGlobalBotSettings;
