import { ReactNode } from 'react';
import Logo from '../../static/logo.svg';
import AuthLink from './AuthLink';
import AuthSubmitButton from './AuthSubmitButton';
import AuthText from './AuthText';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  logo?: boolean;
  title?: string;
  description?: string;
}

const Auth = (props: Props) => {
  const { logo = true, title, description, children } = props;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.top}>
          {logo && <Logo width={32} height={32} />}
          <div>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

Auth.Link = AuthLink;
Auth.SubmitButton = AuthSubmitButton;
Auth.Text = AuthText;

export default Auth;
