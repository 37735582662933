import { useTranslation } from 'react-i18next';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import { formatAbsoluteDate } from '../../utils/date';
import useForm from './useForm';

interface Props {
  data: WorkspaceSchema;
}

const GlobalWorkspaceDetailGeneralTab = (props: Props) => {
  const { data } = props;

  const { id, created_on, modified_on } = data;

  const { t } = useTranslation();

  const { fields } = useForm({ data });

  return (
    <PageAddon.Fields>
      <PageAddon.Id id={id} />
      {fields.bar_seq}
      {fields.business_model}
      {fields.maintenance_types}
      {fields.trainer_tech}
      {fields.poker_rooms}
      {fields.start_new_table}
      {fields.external_partner}
      <PageAddon.Field label={t('common.modified')}>
        <PageAddon.Copy>{formatAbsoluteDate(modified_on)}</PageAddon.Copy>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.created')}>
        <PageAddon.Copy>{formatAbsoluteDate(created_on)}</PageAddon.Copy>
      </PageAddon.Field>
    </PageAddon.Fields>
  );
};

export default GlobalWorkspaceDetailGeneralTab;
