import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { botSettingFilterValuesSchema } from '../schemas/botSettingSchema';

const loadGlobalBotSettingsFilterValues = async (params?: URLParams) => {
  const response = await api.get('/api/bot-settings/filter-values', { params });

  return botSettingFilterValuesSchema.parse(response.data);
};

export default loadGlobalBotSettingsFilterValues;
