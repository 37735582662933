import { arrayMove } from '@dnd-kit/sortable';
import { matchSorter } from 'match-sorter';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { WorkspaceStageSchema } from '../../../api/schemas/stage/workspaceStageSchema';
import Button from '../../../components/Button';
import Chip from '../../../components/Chip';
import Dialog from '../../../components/Dialog';
import Dropdown from '../../../components/Dropdown';
import Form from '../../../components/Form';
import SearchInput from '../../../components/SearchInput';
import SortableList from '../../../components/SortableList';
import SubmitButton from '../../../components/SubmitButton';
import useBreakpoints from '../../../hooks/useBreakpoints';
import icon from '../../../static/icons';
import { getSortingLabel, sortings } from '../helpers';
import WorkspaceAccountFields from '../WorkspaceAccountFields';
import { getFieldGroups } from '../WorkspaceAccountFields/helpers';
import styles from './styles.module.scss';

const getLabel = (field: number) =>
  getFieldGroups('view')
    .flatMap((item) => item.fields)
    .find((item) => item.value === field)?.label;

interface Fields {
  columns: number[];
  default_sorting: WorkspaceStageSchema['default_sorting']['name'];
  default_direction: WorkspaceStageSchema['default_sorting']['direction'];
}

interface Props {
  data: WorkspaceStageSchema;
  onSubmit: (values: Fields) => void;
}

const WorkspaceAccountTableViewDialogForm = (props: Props) => {
  const { data, onSubmit } = props;

  const { t } = useTranslation();
  const { isTablet, isPhone } = useBreakpoints();

  const form = useForm<Fields>({
    defaultValues: {
      columns: data.columns,
      default_sorting: data.default_sorting.name,
      default_direction: data.default_sorting.direction,
    },
  });

  const { watch, setValue } = form;

  const { columns, default_sorting, default_direction } = watch();

  const ascOrder = default_direction === 'asc';
  const descOrder = default_direction === 'desc';

  const [dropdownSearch, setDropdownSearch] = useState('');

  const filteredSortings = dropdownSearch
    ? matchSorter(sortings, dropdownSearch, { keys: [(item) => getSortingLabel(item)] })
    : sortings;

  return (
    <Form form={form} onSubmit={onSubmit} className={styles.form}>
      <div className={styles.container}>
        <div className={styles.displayedColumns}>
          <div className={styles.heading}>
            <span>{t('common.displayed')}</span>
            <div className={styles.sorting}>
              <span>{t('common.sort_by')}</span>
              <Dropdown
                title={t('common.sort_by')}
                addon={
                  <SearchInput
                    size="small"
                    value={dropdownSearch}
                    onChange={setDropdownSearch}
                    className={styles.dropdownSearch}
                  />
                }
                options={() => (
                  <>
                    <div className={styles.options}>
                      {filteredSortings.map((item) => (
                        <Dropdown.Option
                          key={item}
                          label={getSortingLabel(item)}
                          active={default_sorting === item}
                          onClick={() => setValue('default_sorting', item)}
                        />
                      ))}
                    </div>
                    <div className={styles.stickyOptions}>
                      <Dropdown.Option divider />
                      <Dropdown.Option
                        icon={icon('arrowLongDown', 16)}
                        label={t('common.ascending')}
                        active={ascOrder}
                        onClick={() => setValue('default_direction', 'asc')}
                      />
                      <Dropdown.Option
                        icon={icon('arrowLongTop', 16)}
                        label={t('common.descending')}
                        active={descOrder}
                        onClick={() => setValue('default_direction', 'desc')}
                      />
                    </div>
                  </>
                )}
              >
                {() => (
                  <Button
                    rtl
                    size="small"
                    variant="light"
                    icon={ascOrder ? icon('arrowLongDown', 16) : icon('arrowLongTop', 16)}
                  >
                    {getSortingLabel(default_sorting)}
                  </Button>
                )}
              </Dropdown>
            </div>
          </div>
          <SortableList
            mode="vertical"
            items={columns}
            onDnD={({ activeId, overId }) => {
              const oldIndex = columns.findIndex((item) => item === activeId);
              const newIndex = columns.findIndex((item) => item === overId);

              setValue('columns', arrayMove(columns, oldIndex, newIndex));
            }}
          >
            <div className={styles.chips}>
              <Chip disabled count={1} extra={icon('lock', 16, { className: styles.disabledIcon })}>
                {t('common.login')}
              </Chip>
              {columns.map((item, index) => (
                <SortableList.Item key={item} id={item} className={styles.chip}>
                  {isTablet || isPhone ? (
                    <Chip count={index + 2}>{getLabel(item)}</Chip>
                  ) : (
                    <SortableList.Item.Handle id={item}>
                      {({ attributes, listeners, setActivatorNodeRef }) => (
                        <Chip
                          ref={setActivatorNodeRef}
                          count={index + 2}
                          className={styles.draggableChip}
                          extra={icon('dragAndDrop', 16)}
                          {...attributes}
                          {...listeners}
                        >
                          {getLabel(item)}
                        </Chip>
                      )}
                    </SortableList.Item.Handle>
                  )}
                </SortableList.Item>
              ))}
            </div>
          </SortableList>
        </div>
        <div className={styles.columns}>
          <WorkspaceAccountFields
            variant="view"
            value={columns}
            onChange={(value) => setValue('columns', value)}
          />
        </div>
      </div>
      <Dialog.Footer className={styles.footer}>
        <SubmitButton>{t('common.apply')}</SubmitButton>
      </Dialog.Footer>
    </Form>
  );
};

export default WorkspaceAccountTableViewDialogForm;
