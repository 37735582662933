import { ChannelSchema } from '../../api/schemas/channelSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Dropdown from '../../components/Dropdown';
import useDropdown from './useDropdown';

interface Props {
  data: ChannelSchema;
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceChannelDetailDropdown = (props: Props) => {
  const { title, disabled, options } = useDropdown(props);

  return (
    <Dropdown title={title} buttonSize="medium" disabled={disabled} options={[options.delete]} />
  );
};

export default WorkspaceChannelDetailDropdown;
