import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadGlobalWorkspaceAddress } from '../../api';
import { WorkspaceAddressSchema } from '../../api/schemas/workspaceAddressSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import { copy } from '../../utils/clipboard';

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const GlobalWorkspaceDetailAddress = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();

  const queryKey = [queryKeys.globalWorkspaceAddress({ workspaceId })];
  const queryFn = () => loadGlobalWorkspaceAddress({ workspaceId });

  const { data, isPending, isError, refetch } = useQuery({
    queryKey,
    queryFn,
  });

  const getContent = (tronAddress: WorkspaceAddressSchema['tron_address']) => {
    const start = tronAddress.slice(0, 5);
    const end = [...tronAddress].reverse().join('').slice(0, 6);

    return <PageAddon.Copy onCopy={() => copy(tronAddress)}>{`${start}...${end}`}</PageAddon.Copy>;
  };

  return (
    <PageAddon.Field
      label={t('common.address')}
      loading={isPending}
      {...(isError && { onRetry: refetch })}
    >
      {data ? getContent(data.tron_address) : '—'}
    </PageAddon.Field>
  );
};

export default GlobalWorkspaceDetailAddress;
