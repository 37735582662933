import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { SeatControlRuleSchema } from '../schemas/seatControlRuleSchema';

type Schema = SeatControlRuleSchema;

interface Args {
  seatControlRuleId: Schema['id'];
}

const deleteGlobalSeatControlRule = async (args: Args) => {
  const { seatControlRuleId } = args;

  const response = await api.delete(`/api/seat-control/${seatControlRuleId}`);

  return emptyResponseSchema.parse(response.data);
};

export default deleteGlobalSeatControlRule;
