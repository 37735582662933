import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import HomePage from '../../pages/HomePage';

const homePage: RouteObject = {
  path: routes.home,
  element: <HomePage />,
};

export default homePage;
