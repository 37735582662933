import api from '../../services/api';
import URLParams from '../../types/URLParams';
import globalLeagueSchema from '../schemas/globalLeagueSchema';
import paginationSchema from '../schemas/paginationSchema';

const loadGlobalLeagues = async (params?: URLParams) => {
  const response = await api.get('/api/reference/league', { params });

  return paginationSchema(globalLeagueSchema).parse(response.data);
};

export default loadGlobalLeagues;
