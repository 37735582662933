import clsx from 'clsx';
import icon from '../../../static/icons';
import Button from '../../Button';
import { Close } from '../types';
import styles from './styles.module.scss';

interface Props {
  onClose: Close;
  className?: string;
}

const DialogClose = (props: Props) => {
  const { onClose, className } = props;

  return (
    <Button
      size="small"
      data-close="true"
      icon={icon('cross', 16)}
      variant="transparent"
      onClick={() => onClose()}
      className={clsx(styles.close, className)}
    />
  );
};

export default DialogClose;
