import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import workspaceClubMemberSchema from './workspaceClubMemberSchema';

export const STATUS = {
  UNKNOWN: 0,
  PENDING: 1,
  FAILED_MEMBER_NOT_FOUND: 2,
  FAILED_MEMBER_NOT_ENOUGH_CHIPS: 3,
  FAILED_CLUB_NOT_ENOUGH_CHIPS: 4,
  CONFIRMED: 5,
  FAILED_NOT_AUTHORIZED: 6,
  CANCELED: 7,
  FAILED_MEMBER_BLOCKED: 8,
} as const;

export const TYPE = {
  UNKNOWN: 0,
  DEPOSIT: 1,
  WITHDRAW: 2,
} as const;

const workspaceClubTransactionSchema = z.object({
  id: z.string(),
  member: workspaceClubMemberSchema,
  amount: z.number(),
  status: z.nativeEnum(STATUS),
  type: z.nativeEnum(TYPE),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export const workspaceClubTransactionFilterValuesSchema = z.object({
  room: z.array(workspaceClubTransactionSchema.shape.member.shape.room),
  club: z.array(
    z.object({
      id: workspaceClubTransactionSchema.shape.member.shape.club,
      name: workspaceClubTransactionSchema.shape.member.shape.club,
    })
  ),
  manager: z.array(
    z.object({
      id: workspaceClubTransactionSchema.shape.member.shape.manager.shape.id,
      name: workspaceClubTransactionSchema.shape.member.shape.manager.shape.login,
    })
  ),
  status: z.array(workspaceClubTransactionSchema.shape.status),
  type: z.array(workspaceClubTransactionSchema.shape.type),
});

export type WorkspaceClubTransactionSchema = z.infer<typeof workspaceClubTransactionSchema>;

export default workspaceClubTransactionSchema;
