import icon from '../../../static/icons';
import styles from './styles.module.scss';

interface Props {
  onClick?: () => void;
}

const AlertCloseButton = (props: Props) => {
  const { onClick } = props;

  return (
    <button type="button" className={styles.closeButton} onClick={onClick}>
      {icon('cross', 16)}
    </button>
  );
};

export default AlertCloseButton;
