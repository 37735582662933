import { z } from 'zod';
import globalAccountSchema from './account/globalAccountSchema';

const globalClubDeleteErrorSchema = z.object({
  accounts: z.array(
    z.object({
      id: globalAccountSchema.shape.id,
      mga_id: globalAccountSchema.shape.mga_id,
    })
  ),
});

export type GlobalClubDeleteErrorSchema = z.infer<typeof globalClubDeleteErrorSchema>;

export default globalClubDeleteErrorSchema;
