import clsx from 'clsx';
import { format, addHours, isSameDay, addMinutes, isSameMonth, isWeekend } from 'date-fns';
import { useDatePickerContext } from '../DatePickerContext';
import commonStyles from '../styles.module.scss';
import { Props } from '../types';
import styles from './styles.module.scss';

const DatePickerDays = (props: Pick<Props, 'withTime' | 'disabledDate'>) => {
  const { withTime, disabledDate } = props;

  const { date, days, value, daysOfWeek, setDay } = useDatePickerContext();

  return (
    <div className={styles.container}>
      <div className={styles.daysOfWeek}>
        {daysOfWeek.map((dayOfWeek) => (
          <div key={String(dayOfWeek)}>{format(dayOfWeek, 'E')}</div>
        ))}
      </div>
      <div className={styles.days}>
        {days.map((day) => {
          const className = clsx(commonStyles.date, {
            [commonStyles.today]: isSameDay(day, new Date()),
            [commonStyles.selected]: isSameDay(day, new Date(value)),
            [commonStyles.weekend]: isWeekend(day),
            [commonStyles.notAvailable]: !isSameMonth(day, date),
          });

          const handleClick = () => {
            if (typeof withTime !== 'boolean' && withTime?.selectEndOfDay) {
              setDay(addMinutes(addHours(day, 23), 59));
            } else {
              setDay(day);
            }
          };

          return (
            <button
              key={String(day)}
              type="button"
              className={className}
              onClick={handleClick}
              {...(disabledDate && { disabled: disabledDate(day) })}
            >
              {format(day, 'dd')}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default DatePickerDays;
