import api from '../../services/api';
import { BotSettingRuleSchema } from '../schemas/botSettingRuleSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';

type Schema = BotSettingRuleSchema;

interface Payload {
  name?: Schema['name'];
  bot_settings_id?: Schema['bot_settings']['id'];
  game_type?: Schema['game_type'];
  description?: Schema['description'];
  poker_room?: Schema['poker_room'];
  maintenance_id?: Exclude<Schema['maintenance'], null>['id'] | null;
}

interface Args {
  botSettingRuleId: Schema['id'];
  payload: Payload;
}

const updateGlobalBotSettingRule = async (args: Args) => {
  const { botSettingRuleId, payload } = args;

  const response = await api.patch(`/api/bot-settings-usage-rule/${botSettingRuleId}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateGlobalBotSettingRule;
