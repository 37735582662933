import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { notificationFilterValuesSchema } from '../schemas/notificationSchema';

const loadNotificationsFilterValues = async (params?: URLParams) => {
  const response = await api.get('/api/notification/filter-values', { params });

  return notificationFilterValuesSchema.parse(response.data);
};

export default loadNotificationsFilterValues;
