import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import TagGroup from '../../../components/Tag/TagGroup';
import Tooltip from '../../../components/Tooltip';
import styles from './styles.module.scss';

interface Props {
  data: WorkspaceAccountSchema;
}

const WorkspaceAccountTags = (props: Props) => {
  const { data } = props;

  const { tags } = data;

  if (!tags.length) return <>—</>;

  return (
    <TagGroup
      tooltip={(index) =>
        tags
          .slice(index)
          .map((item) => item.name)
          .join(', ')
      }
    >
      {tags.map((item) => (
        <Tooltip key={item.id} label={item.name} hideOnMobile>
          <div style={{ backgroundColor: item.color }} className={styles.circle} />
        </Tooltip>
      ))}
    </TagGroup>
  );
};

export default WorkspaceAccountTags;
