import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { GlobalLeagueSchema } from '../schemas/globalLeagueSchema';

interface Args {
  leagueId: GlobalLeagueSchema['id'];
}

const deleteGlobalLeague = async (args: Args) => {
  const { leagueId } = args;

  const response = await api.delete(`/api/reference/league/${leagueId}`);

  return emptyResponseSchema.parse(response.data);
};

export default deleteGlobalLeague;
