import { useTranslation } from 'react-i18next';
import { AreaSchema } from '../../api/schemas/areaSchema';
import Form from '../../components/Form';
import PageAddon from '../../components/PageAddon';
import SubmitButton from '../../components/SubmitButton';
import useForm from './useForm';

interface Props {
  data?: AreaSchema;
  onClose: () => void;
  onCreate?: Parameters<typeof useForm>[0]['onCreate'];
}

const GlobalAreaForm = (props: Props) => {
  const { data, onClose, onCreate } = props;

  const { t } = useTranslation();

  const { form, fields, onSubmit } = useForm({ data, onCreate, onClose });

  return (
    <PageAddon
      onClose={onClose}
      title={data ? t('common.edit_area_rates') : t('common.create_area')}
    >
      <Form form={form} onSubmit={onSubmit}>
        {!data && fields.name}
        {fields.periods}
        <PageAddon.Controls>
          <SubmitButton>{data ? t('common.save') : t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default GlobalAreaForm;
