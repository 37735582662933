import { z } from 'zod';
import globalAccountSchema from './account/globalAccountSchema';
import accountStatusSchema from './accountStatusSchema';
import lobbyTableSchema from './lobbyTableSchema';
import metricsSchema from './metricsSchema';
import workspaceSchema from './workspaceSchema';

export const lobbyAccountSchema = globalAccountSchema
  .pick({
    id: true,
    mga_id: true,
    max_tables: true,
    trainer_version: true,
  })
  .extend({
    maintenance_id: workspaceSchema.shape.id,
    metrics: metricsSchema,
    account_status: accountStatusSchema,
  });

const lobbySchema = z.object({
  accounts: z.array(lobbyAccountSchema),
  table: lobbyTableSchema,
});

export type LobbyAccountSchema = z.infer<typeof lobbyAccountSchema>;

export type LobbySchema = z.infer<typeof lobbySchema>;

export default lobbySchema;
