import { ReactNode, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Collapse from '../../../components/Collapse';
import Confirm from '../../../components/Confirm';
import icon from '../../../static/icons';
import { Fields } from '../types';
import styles from './styles.module.scss';

interface Props {
  label: string;
  defaultOpen: boolean;
  error: boolean;
  children: ReactNode;
  onDelete: () => void;
}

const WorkspaceIdentityFormCollapse = (props: Props) => {
  const { label, defaultOpen, error, children, onDelete } = props;

  const { t } = useTranslation();

  const {
    formState: { submitCount },
  } = useFormContext<Fields>();

  const [open, setOpen] = useState(defaultOpen);

  useEffect(() => {
    if (error) setOpen(true);
  }, [submitCount, error]);

  return (
    <Collapse
      alwaysRenderContent
      open={open}
      setOpen={setOpen}
      labelBefore={
        <Confirm
          onConfirm={onDelete}
          title={t('sentences.delete_this_element')}
          confirmText={t('common.delete')}
        >
          {({ confirming }) => (
            <Button
              danger
              focused={confirming}
              variant="transparent"
              size="extra-small"
              icon={icon('trash', 16)}
              className={styles.button}
            />
          )}
        </Confirm>
      }
      label={label}
    >
      {children}
    </Collapse>
  );
};

export default WorkspaceIdentityFormCollapse;
