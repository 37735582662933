import { ReactNode } from 'react';
import useURL from '../../../hooks/useURL';
import icon from '../../../static/icons';
import Button from '../../Button';
import styles from './styles.module.scss';

interface Props {
  to?: string;
  back?: boolean;
  children: ReactNode;
  description?: string;
  onClick?: () => void;
}

const AuthLink = (props: Props) => {
  const { to, back, description, children, onClick } = props;

  const { navigate } = useURL();

  const handleClick = () => {
    if (onClick) onClick();

    if (to) navigate(to, { params: false });
  };

  return (
    <div className={styles.container}>
      {description && <span>{description}&nbsp;</span>}
      <Button
        variant="light"
        size="extra-small"
        onClick={handleClick}
        {...(back && { icon: icon('chevronLeft', 16) })}
      >
        {children}
      </Button>
    </div>
  );
};

export default AuthLink;
