import Checkbox from '../../../Checkbox';
import styles from './styles.module.scss';

interface Props {
  checked: boolean;
  children: string;
  onChange: () => void;
  addon?: React.ReactNode;
  extra?: React.ReactNode;
}

const FilterCheckbox = (props: Props) => {
  const { checked, children, onChange, addon, extra } = props;

  return (
    <Checkbox onChange={onChange} checked={checked} className={styles.checkbox}>
      <div className={styles.container}>
        {extra && <div className={styles.extra}>{extra}</div>}
        <div className={styles.content}>
          {children}
          &nbsp;
          {(addon || addon === 0) && <span className={styles.addon}>({addon})</span>}
        </div>
      </div>
    </Checkbox>
  );
};

export default FilterCheckbox;
