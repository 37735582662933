import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import {
  WorkspaceClubManagerSchema,
  WorkspaceClubManagerPasswordSchema,
} from '../schemas/workspaceClubManagerSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Payload {
  password: WorkspaceClubManagerPasswordSchema;
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  managerId: WorkspaceClubManagerSchema['id'];
  payload: Payload;
}

const changeWorkspaceClubManagerPassword = async (args: Args) => {
  const { workspaceId, managerId, payload } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/club-manager/managers/${managerId}/password`,
    payload
  );

  return emptyResponseSchema.parse(response.data);
};

export default changeWorkspaceClubManagerPassword;
