import { ReactElement } from 'react';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import PageAddon from '../../components/PageAddon';
import { useGlobalAccountQuery } from './queries';

interface Props {
  accountId: GlobalAccountSchema['id'];
  children: (args: { data: GlobalAccountSchema }) => ReactElement;
}

const GlobalAccountLoader = (props: Props) => {
  const { accountId, children } = props;

  const { data, isPending } = useGlobalAccountQuery({ accountId });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data });
};

export default GlobalAccountLoader;
