import { arrayMove } from '@dnd-kit/sortable';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createWorkspaceAccountsStage, updateWorkspaceAccountsStage } from '../../../api';
import { PaginationSchema } from '../../../api/schemas/paginationSchema';
import { WorkspaceStageSchema } from '../../../api/schemas/stage/workspaceStageSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Button from '../../../components/Button';
import Dialog from '../../../components/Dialog';
import Dropdown, { presets } from '../../../components/Dropdown';
import PageTabs from '../../../components/Page/PageTabs';
import SortableList from '../../../components/SortableList';
import Tooltip from '../../../components/Tooltip';
import useRerender from '../../../hooks/useRerender';
import useURL from '../../../hooks/useURL';
import useWebSocketMessage from '../../../hooks/useWebSocketMessage';
import icon from '../../../static/icons';
import { mutateDetailQueryData } from '../../../utils/queryClient';
import { useWorkspaceAccountsStagesQuery } from '../queries';
import useBulkActions from '../useBulkActions';
import { useWorkspaceAccountsContext } from '../WorkspaceAccountsContext';
import styles from './styles.module.scss';
import { Editing } from './types';
import WorkspaceAccountStage from './WorkspaceAccountStage';
import WorkspaceAccountStagesForm from './WorkspaceAccountStagesForm';

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceAccountStages = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { rerender } = useRerender();
  const { disabled } = useBulkActions();
  const { setURLParams, clearURLParams } = useURL();
  const { displayAll, setDisplayAll } = useWorkspaceAccountsContext();

  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState<Editing>(false);
  const [warning, setWarning] = useState(false);

  const { data, isPending, queryKey } = useWorkspaceAccountsStagesQuery({ workspaceId });

  useWebSocketMessage<WorkspaceStageSchema>({
    domain: 'Stage',
    onMessage: (message) => {
      if (message.meta_info?.maintenance_id === workspaceId) {
        if (message.action === 'Deleted') {
          setWarning(true);
        }
      }
    },
  });

  if (displayAll) {
    return (
      <div className={styles.displayAll}>
        <Button
          variant="light"
          disabled={disabled}
          onClick={() => {
            setDisplayAll(false);
            clearURLParams();
          }}
          icon={icon('chevronLeft', 16)}
        >
          {t('common.back_to_stage_view')}
        </Button>
      </div>
    );
  }

  if (isPending) return <PageTabs.Placeholder />;
  if (!data) return null;

  const sortedStages = data.items;

  const handleWarning = () => {
    setWarning(false);
    setURLParams({ stage_order_eq: null });
  };

  return (
    <>
      {warning && (
        <Dialog onClose={handleWarning}>
          {() => (
            <>
              <Dialog.Top>
                <Dialog.Title>{t('sentences.stages_have_been_updated')}</Dialog.Title>
              </Dialog.Top>
              {t('sentences.stages_were_modified')}
              <Dialog.Footer>
                <Button onClick={handleWarning}>{t('common.ok')}</Button>
              </Dialog.Footer>
            </>
          )}
        </Dialog>
      )}
      {creating && (
        <Dialog onClose={() => setCreating(false)}>
          {({ close }) => (
            <>
              <Dialog.Top>
                <Dialog.Title>{t('common.create_stage')}</Dialog.Title>
              </Dialog.Top>
              <WorkspaceAccountStagesForm
                onSubmit={async (values) => {
                  await createWorkspaceAccountsStage({ workspaceId, payload: values });

                  close();
                }}
              />
            </>
          )}
        </Dialog>
      )}
      <PageTabs
        extra={
          editing ? (
            <Tooltip label={t('common.apply')}>
              <Button
                size="small"
                icon={icon('check', 16)}
                className={styles.apply}
                onClick={() => setEditing(false)}
              />
            </Tooltip>
          ) : (
            <Dropdown
              title={t('common.stages')}
              options={[
                { ...presets.edit, onClick: () => setEditing(true) },
                {
                  label: t('common.create_stage'),
                  icon: icon('add', 20),
                  onClick: () => setCreating(true),
                },
                {
                  label: t('common.display_all_accounts'),
                  icon: icon('list', 20),
                  onClick: () => {
                    setDisplayAll(true);
                    clearURLParams();
                  },
                },
              ]}
            />
          )
        }
      >
        <SortableList
          mode="horizontal"
          items={data.items.map((item) => item.id)}
          onDnD={async ({ activeId, overId }) => {
            const active = data.items.find((item) => item.id === activeId);
            const over = data.items.find((item) => item.id === overId);

            if (active && over) {
              const oldIndex = data.items.map((item) => item.id).indexOf(active.id);
              const newIndex = data.items.map((item) => item.id).indexOf(over.id);

              updateWorkspaceAccountsStage({
                workspaceId,
                stageId: active.id,
                payload: { order: over.order },
              });

              mutateDetailQueryData<PaginationSchema<WorkspaceStageSchema>>(
                queryKey,
                (prevData) => {
                  if (prevData) {
                    return {
                      ...prevData,
                      items: arrayMove(sortedStages, oldIndex, newIndex).map((item, index) => ({
                        ...item,
                        order: index + 1,
                      })),
                    };
                  }

                  return prevData;
                }
              );

              rerender(); // без этого костыля при реордере стейджи будут дёргаться
            }
          }}
        >
          {data.items.map((item, index) => (
            <WorkspaceAccountStage
              key={item.id}
              data={item}
              index={index}
              editing={editing}
              workspaceId={workspaceId}
            />
          ))}
        </SortableList>
      </PageTabs>
    </>
  );
};

export default WorkspaceAccountStages;
