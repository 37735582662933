import { ReactNode } from 'react';
import { copy } from '../../../utils/clipboard';
import styles from './styles.module.scss';

interface CommonProps {
  extra?: ReactNode;
}

interface PrimitiveProps {
  children: string | number;
  onCopy?: () => void;
}

interface NodeProps {
  children: ReactNode;
  onCopy: () => void;
}

type Props = CommonProps & (PrimitiveProps | NodeProps);

const PageAddonCopy = (props: Props) => {
  const { children, extra, onCopy } = props;

  return (
    <button
      type="button"
      className={styles.container}
      onClick={() => {
        if (onCopy) {
          onCopy();
        } else if (typeof children === 'string' || typeof children === 'number') {
          copy(children);
        }
      }}
    >
      {children}
      {extra}
    </button>
  );
};

export default PageAddonCopy;
