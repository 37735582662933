import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import fileSchema from './fileSchema';
import globalUserSchema from './globalUserSchema';

export const TYPE = {
  UNKNOWN: 0,
  ACCOUNT: 1,
  IDENTITY: 2,
  CHANNEL: 3,
  USER: 4,
  CM_MANAGER: 5,
  CM_TRANSACTION: 6,
  STRATEGY_PROFILE: 7,
  ANNOUNCEMENT: 8,
  AGENT: 9,
  DEAL: 10,
  CLUB: 11,
} as const;

const noteSchema = z.object({
  id: z.string(),
  message: z.string(),
  files: z.array(fileSchema),
  author: z.object({
    id: globalUserSchema.shape.id,
    username: globalUserSchema.shape.username,
  }),
  instance: z.object({
    id: z.string(),
    type: z.nativeEnum(TYPE),
    maintenance_id: z.string().or(z.null()),
  }),
  is_deleted: z.boolean(),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export type NoteSchema = z.infer<typeof noteSchema>;

export default noteSchema;
