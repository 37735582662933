import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import paginationSchemaNew from '../schemas/paginationSchemaNew';
import { WorkspaceSchema } from '../schemas/workspaceSchema';
import workspaceTokenSchema from '../schemas/workspaceTokenSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceTokens = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(`/api/v2/${ALIASES.WORKSPACE.L}/${workspaceId}/token`, {
    params,
  });

  return paginationSchemaNew(workspaceTokenSchema).parse(response.data);
};

export default loadWorkspaceTokens;
