import { ReactNode } from 'react';
import Placeholder from '../../../../components/Placeholder';
import { convertCentsToUSDT } from '../../../../utils/data';
import styles from './styles.module.scss';

interface Props {
  label: string;
  value?: number;
  children?: ReactNode;
}

const WorkspaceSettingsGeneralBalanceWidget = (props: Props) => {
  const { label, value, children } = props;

  return (
    <div className={styles.widget}>
      <div className={styles.label}>{`${label}, USDT`}</div>
      <div className={styles.content}>
        {value || value === 0 ? (
          <div className={styles.value}>{convertCentsToUSDT(value)}</div>
        ) : (
          <Placeholder className={styles.valuePlaceholder} />
        )}
        {children}
      </div>
    </div>
  );
};

export default WorkspaceSettingsGeneralBalanceWidget;
