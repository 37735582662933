import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { GlobalUserSchema } from '../schemas/globalUserSchema';

interface Payload {
  user_id: GlobalUserSchema['id'];
  username: GlobalUserSchema['username'];
}

interface Args {
  payload: Payload;
}

const sendGlobalUserRecoveryPassword = async (args: Args) => {
  const { payload } = args;

  const response = await api.post('/api/recovery-user-password', payload);

  return emptyResponseSchema.parse(response.data);
};

export default sendGlobalUserRecoveryPassword;
