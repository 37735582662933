import {
  useContext,
  createContext,
  ReactNode,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';

type Total = number;
type Errors = WorkspaceAccountSchema[];

interface Context {
  displayAll: boolean;
  total: Total;
  errors: Errors;
  setDisplayAll: Dispatch<SetStateAction<boolean>>;
  setTotal: Dispatch<SetStateAction<Total>>;
  setErrors: Dispatch<SetStateAction<Errors>>;
}

const WorkspaceAccountsContext = createContext<Context | undefined>(undefined);

interface Props {
  displayAll: boolean;
  setDisplayAll: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
}

const WorkspaceAccountsProvider = (props: Props) => {
  const { displayAll, setDisplayAll, children } = props;

  const [total, setTotal] = useState<Total>(0);
  const [errors, setErrors] = useState<Errors>([]);

  const value = useMemo(
    () => ({ displayAll, total, errors, setDisplayAll, setTotal, setErrors }),
    [displayAll, total, errors, setDisplayAll]
  );

  return (
    <WorkspaceAccountsContext.Provider value={value}>{children}</WorkspaceAccountsContext.Provider>
  );
};

const useWorkspaceAccountsContext = () => {
  const context = useContext(WorkspaceAccountsContext);

  if (context === undefined) {
    throw new Error('useWorkspaceAccountsContext must be used within a WorkspaceAccountsProvider');
  }

  return context;
};

export { useWorkspaceAccountsContext, WorkspaceAccountsProvider };
