import { OrderSchema, TYPE, STATUS as ORDER_STATUS } from '../../api/schemas/orderSchema';
import {
  OrderTransactionSchema,
  STATUS as ORDER_TRANSACTION_STATUS,
} from '../../api/schemas/orderTransactionSchema';
import { t } from '../../i18n';

export const TYPE_LABELS: Record<OrderSchema['type'], string> = {
  [TYPE.UNKNOWN]: '—',
  [TYPE.DEPOSIT]: t('common.deposit'),
  [TYPE.WITHDRAW]: t('common.withdraw'),
  [TYPE.BUY]: t('common.buy'),
  [TYPE.SELL]: t('common.sell'),
  [TYPE.POKER_HAND_BILLING]: t('common.poker_hand_billing'),
};

export const ORDER_STATUS_LABELS: Record<OrderSchema['status'], string> = {
  [ORDER_STATUS.UNKNOWN]: '—',
  [ORDER_STATUS.PROCESS]: t('common.processing'),
  [ORDER_STATUS.DONE]: t('common.done'),
};

export const ORDER_TRANSACTION_STATUS_LABELS: Record<OrderTransactionSchema['status'], string> = {
  [ORDER_TRANSACTION_STATUS.UNKNOWN]: '—',
  [ORDER_TRANSACTION_STATUS.PROCESS]: t('common.processing'),
  [ORDER_TRANSACTION_STATUS.DONE]: t('common.done'),
};
