import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import OrderDetail from '../../features/order/OrderDetail';
import WorkspaceAccountDetail from '../../features/workspace-account/WorkspaceAccountDetail';
import WorkspaceAccountLoader from '../../features/workspace-account/WorkspaceAccountLoader';
import WorkspaceOrderLoader from '../../features/workspace-order/WorkspaceOrderLoader';
import WorkspaceOrderTransactions from '../../features/workspace-order/WorkspaceOrderTransactions';
import WorkspaceOrdersPage from '../../pages/WorkspaceOrdersPage';
import Route from './Route';

const page = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceOrdersPage workspaceId={workspaceId} />}
  </Route>
);

const detail = (
  <Route params={['workspaceId', 'orderId']}>
    {({ workspaceId, orderId, navigate }) => (
      <WorkspaceOrderLoader workspaceId={workspaceId} orderId={orderId}>
        {({ data }) => (
          <OrderDetail
            data={data}
            workspaceId={workspaceId}
            transactions={
              <WorkspaceOrderTransactions workspaceId={workspaceId} orderId={orderId} />
            }
            onClose={() => navigate(routes.workspaceOrders({ workspaceId }))}
          />
        )}
      </WorkspaceOrderLoader>
    )}
  </Route>
);

const detailAccount = (
  <Route params={['workspaceId', 'orderId', 'accountId']}>
    {({ workspaceId, orderId, accountId, navigate }) => (
      <WorkspaceAccountLoader workspaceId={workspaceId} accountId={accountId}>
        {({ data }) => (
          <WorkspaceAccountDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceOrder({ workspaceId, orderId }))}
          />
        )}
      </WorkspaceAccountLoader>
    )}
  </Route>
);

const workspaceOrdersPage: RouteObject = {
  path: routes.workspaceOrders({ workspaceId: ':workspaceId' }),
  element: page,
  children: [
    {
      path: routes.workspaceOrder({ workspaceId: ':workspaceId', orderId: ':orderId' }),
      element: detail,
      children: [
        {
          path: routes.workspaceOrderAccount({
            workspaceId: ':workspaceId',
            orderId: ':orderId',
            accountId: ':accountId',
          }),
          element: detailAccount,
        },
      ],
    },
  ],
};

export default workspaceOrdersPage;
