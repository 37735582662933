import { useTranslation } from 'react-i18next';
import Stub from '../Stub';

interface Props {
  title?: string;
}

const Empty = (props: Props) => {
  const { title } = props;

  const { t } = useTranslation();

  return <Stub title={title || t('common.there_are_no_records')} />;
};

export default Empty;
