import { useTranslation } from 'react-i18next';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { WorkspaceUserSchema } from '../../api/schemas/workspaceUserSchema';
import PageDetailLink from '../../components/PageDetailLink';
import Tooltip from '../../components/Tooltip';
import routes from '../../config/routes';
import icon from '../../static/icons';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: WorkspaceUserSchema;
}

const WorkspaceUserDetailLink = (props: Props) => {
  const { workspaceId, data } = props;

  const { id, username, is_owner, verified } = data;

  const { t } = useTranslation();

  return (
    <PageDetailLink
      label={username}
      to={routes.workspaceUser({ workspaceId, userId: id })}
      extra={
        <>
          {is_owner && <Tooltip label={t('common.owner')}>{icon('crown', 12)}</Tooltip>}
          {!verified && (
            <Tooltip label={t('common.invite_not_accepted')}>{icon('clock', 12)}</Tooltip>
          )}
        </>
      }
    />
  );
};

export default WorkspaceUserDetailLink;
