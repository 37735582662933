import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadGlobalExpenses } from '../../api';
import { GlobalExpenseSchema } from '../../api/schemas/globalExpenseSchema';
import Card from '../../components/Card';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import { getAmount } from '../../features/global-deal/helpers';
import GlobalExpenseCreateButton from '../../features/global-expense/GlobalExpenseCreateButton';
import GlobalExpenseDetailLink from '../../features/global-expense/GlobalExpenseDetailLink';
import GlobalExpenseDropdown from '../../features/global-expense/GlobalExpenseDropdown';
import GlobalExpenseFilter from '../../features/global-expense/GlobalExpenseFilter';
import { TYPE_LABELS } from '../../features/global-expense/helpers';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedDataNew from '../../hooks/usePaginatedDataNew';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { addWhen } from '../../utils';
import { RouteParams } from '../../utils/generics';
import { can } from '../../utils/permissions';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.globalExpense>;

const GlobalExpensesPage = () => {
  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { expenseId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search } = urlParams;

  const queryKey = [queryKeys.globalExpenses, urlParams];

  const query = usePaginatedDataNew({
    queryKey,
    queryFn: (params) => loadGlobalExpenses({ ...urlParams, ...params }),
  });

  const { data, error, checkIsInView } = query;

  const { checkIsCreated } = useWebSocketPaginatedActions<GlobalExpenseSchema>({
    queryKey,
    domain: 'Expense',
    select: (item) => item,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <Page
      heading={t('common.expenses')}
      tabs={[
        { to: routes.globalDeals, label: t('common.deals') },
        ...addWhen({ to: routes.globalAgents, label: t('common.agents') }, can.agent.read),
        { to: routes.globalBonuses, label: t('common.bonuses') },
        { to: routes.globalExpenses, label: t('common.expenses'), active: true },
      ]}
      filter={<GlobalExpenseFilter />}
      extra={
        <PageControls>
          <GlobalExpenseCreateButton />
        </PageControls>
      }
      addon={<Outlet />}
    >
      {isPhone && (
        <PageTop
          sorting={[
            { key: 'created_on', title: t('common.created'), default: true },
            { key: 'amount', title: t('common.amount') },
          ]}
        />
      )}
      {(isDesktop || isTablet) && (
        <Table
          data={data}
          search={search}
          {...getTableProps(query)}
          className={styles.table}
          columns={[
            {
              key: 'created_on',
              sorting: true,
              title: t('common.date'),
              render: ({ values }) => <GlobalExpenseDetailLink data={values} />,
            },
            {
              title: t('common.deal'),
              render: ({ values }) => values.deal.code,
            },
            {
              title: t('common.type'),
              render: ({ values }) => TYPE_LABELS[values.type],
            },
            {
              key: 'amount',
              sorting: true,
              title: t('common.amount'),
              render: ({ values }) => getAmount(values),
            },
            {
              render: ({ values }) => <GlobalExpenseDropdown data={values} />,
              align: 'right',
              controls: true,
            },
          ]}
          renderRow={(row, index) => (
            <Table.Row
              highlighted={checkIsCreated(row.data.id)}
              active={row.data.id === expenseId}
              danger={row.data.is_deleted}
              {...checkIsInView(index)}
              {...row}
            />
          )}
        />
      )}
      {isPhone && (
        <Card.Group>
          {data.map((item, index) => (
            <Card
              key={item.id}
              highlighted={checkIsCreated(item.id)}
              danger={item.is_deleted}
              {...checkIsInView(index)}
            >
              <Card.Top>
                <GlobalExpenseDetailLink data={item} />
                <Card.Top.Controls>
                  <GlobalExpenseDropdown data={item} />
                </Card.Top.Controls>
              </Card.Top>
              <Card.Field.Group>
                <Card.Field label={t('common.deal')}>{item.deal.code}</Card.Field>
                <Card.Field label={t('common.type')}>{TYPE_LABELS[item.type]}</Card.Field>
                <Card.Field label={t('common.amount')}>{getAmount(item)}</Card.Field>
              </Card.Field.Group>
            </Card>
          ))}
        </Card.Group>
      )}
    </Page>
  );
};

export default GlobalExpensesPage;
