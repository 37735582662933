import { useTranslation } from 'react-i18next';
import PageCreateButton from '../../components/PageCreateButton';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { can } from '../../utils/permissions';

const GlobalAreaCreateButton = () => {
  const { t } = useTranslation();
  const { navigate } = useURL();

  const handleClick = () => navigate(routes.globalAreasCreate);

  if (!can.currency.createUpdate) return null;

  return <PageCreateButton onClick={handleClick}>{t('common.area')}</PageCreateButton>;
};

export default GlobalAreaCreateButton;
