import { Range as ReactRange, getTrackBackground } from 'react-range';
import COLORS from '../../constants/COLORS';
import styles from './styles.module.scss';
import { Props } from './types';

const Range = (props: Props) => {
  const { step, onFromChange, onToChange } = props;

  let { values, min, max } = props;

  let [from, to] = values;

  // если step сможет быть 0.1, то округление в этом случае надо отключить
  from = Math.floor(from);
  to = Math.floor(to);
  min = Math.ceil(min);
  max = Math.ceil(max);

  const getFrom = () => {
    if (from < min) return min;
    if (from > max) return max;

    return from;
  };

  values = [getFrom(), ...(onToChange ? [to > max ? max : to] : [])];

  return (
    <ReactRange
      min={min}
      max={max}
      step={step}
      values={values}
      onChange={(newValues) => {
        const [newFrom, newTo] = newValues;

        onFromChange(newFrom);

        if (onToChange) {
          onToChange(newTo);
        }
      }}
      renderTrack={({ props: trackProps, children }) => (
        <div
          {...trackProps}
          style={{
            ...trackProps.style,
            cursor: 'pointer',
            background: getTrackBackground({
              min,
              max,
              values,
              colors: [...(onToChange ? [COLORS.BLUE_600] : []), COLORS.BLUE_200, COLORS.BLUE_600],
            }),
          }}
          className={styles.track}
        >
          {children}
        </div>
      )}
      renderThumb={({ props: thumbProps }) => (
        <div
          {...thumbProps}
          key={thumbProps.key}
          style={thumbProps.style}
          className={styles.thumb}
        />
      )}
    />
  );
};

export default Range;
