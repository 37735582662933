import Tooltip from '../components/Tooltip';
import { t } from '../i18n';
import TetherIcon from '../static/wallets/tether.svg';

export default {
  USD: '$',
  USDT: (
    <Tooltip label={String(t('common.usdt'))}>
      <TetherIcon width={16} height={16} />
    </Tooltip>
  ),
};
