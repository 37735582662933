import { useState } from 'react';

const useSelectedFields = <T extends Record<string, unknown>>(args: {
  fields: T;
  defaultSelected?: (keyof T)[];
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { fields, defaultSelected = [] } = args;

  const [selectedFields, setSelectedFields] = useState<(keyof typeof fields)[]>(defaultSelected);

  const addField = (item: keyof typeof fields) => setSelectedFields((prev) => [...prev, item]);

  const removeField = (item: keyof typeof fields) =>
    setSelectedFields((prev) => prev.filter((el) => el !== item));

  const disabled = !selectedFields.length;

  const fieldDisabled = (item: keyof typeof fields) => selectedFields.includes(item);

  const resetFields = () => setSelectedFields(defaultSelected);

  return {
    selectedFields,
    disabled,
    addField,
    removeField,
    fieldDisabled,
    resetFields,
  };
};

export default useSelectedFields;
