import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { WorkspaceSessionSchema } from '../schemas/session/workspaceSessionSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = WorkspaceSessionSchema;

interface Payload {
  account_ids: Schema['account']['id'][];
  source_from: Schema['start'];
  source_to: Schema['end'];
  target_from: Schema['start'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
  params?: URLParams;
}

const bulkDuplicateWorkspaceAccountSessions = async (args: Args) => {
  const { workspaceId, payload, params } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/sessions/duplicate`,
    payload,
    { params }
  );

  /* return emptyResponseSchema.parse(response.data); */
  /* TODO: схема должна быть пустой */
  return response.data;
};

export default bulkDuplicateWorkspaceAccountSessions;
