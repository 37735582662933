import clsx from 'clsx';
import { useEffect, useState } from 'react';
import useDimensions from 'react-cool-dimensions';
import Tooltip from '../Tooltip';
import styles from './styles.module.scss';
import { Props } from './types';

const FadingText = (props: Props) => {
  const {
    text,
    children,
    extra,
    tooltip = true,
    tooltipLabel,
    className,
    onChange,
    ...rest
  } = props;

  const getFading = (element?: Element) => {
    if (element) return element.scrollWidth > element.clientWidth;

    return false;
  };

  const [isFading, setFading] = useState(false);

  const { observe } = useDimensions<HTMLSpanElement>({
    onResize: (event) => setFading(getFading(event.entry.target)),
  });

  useEffect(() => {
    if (onChange) onChange(isFading);
  }, [isFading, onChange]);

  const content = children || text;

  const commonProps = { className: clsx(styles.text, isFading && styles.fading), ...rest };

  return (
    <div className={clsx(styles.container, className)}>
      {isFading && tooltip ? (
        <Tooltip ref={observe} label={tooltipLabel || text} placement="top-start" arrowOffset={16}>
          <span {...commonProps}>{content}</span>
        </Tooltip>
      ) : (
        <span ref={observe} {...commonProps}>
          {content}
        </span>
      )}
      {extra && <div>{extra}</div>}
    </div>
  );
};

export default FadingText;
