import api from '../../services/api';
import globalBonusSchema, { GlobalBonusSchema } from '../schemas/globalBonusSchema';

interface Args {
  bonusId: GlobalBonusSchema['id'];
}

const loadGlobalBonus = async (args: Args) => {
  const { bonusId } = args;

  const response = await api.get(`/api/bonus/${bonusId}`);

  return globalBonusSchema.parse(response.data);
};

export default loadGlobalBonus;
