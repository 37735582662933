import clsx from 'clsx';
import useBreakpoints from '../../../hooks/useBreakpoints';
import { usePageContext } from '../../Page';
import Placeholder from '../../Placeholder';
import styles from './styles.module.scss';

interface Props {
  wrapper?: boolean;
}

const PageAddonDetailPlaceholder = (props: Props) => {
  const { wrapper = true } = props;

  const { isTablet } = useBreakpoints();
  const { hasTabs } = usePageContext();

  const content = (
    <div className={clsx(styles.content, !wrapper && styles.withoutWrapper)}>
      {Array.from({ length: 9 }).map((_, index) => (
        <div key={index}>
          <Placeholder className={styles.label} />
          <Placeholder className={styles.value} />
        </div>
      ))}
    </div>
  );

  if (!wrapper) return content;

  return (
    <div className={clsx(styles.container, isTablet && hasTabs && styles.withOffset)}>
      <div className={styles.top}>
        <Placeholder className={styles.title} />
        <Placeholder className={styles.button} />
      </div>
      {content}
      <div className={clsx(styles.bottom, !wrapper && styles.withoutWrapper)}>
        {Array.from({ length: 3 }).map((_, index) => (
          <Placeholder key={index} className={styles.control} />
        ))}
      </div>
    </div>
  );
};

export default PageAddonDetailPlaceholder;
