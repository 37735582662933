import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalSeatControlRuleDetail from '../../features/global-seat-control-rule/GlobalSeatControlRuleDetail';
import GlobalSeatControlRuleForm from '../../features/global-seat-control-rule/GlobalSeatControlRuleForm';
import GlobalSeatControlRuleLoader from '../../features/global-seat-control-rule/GlobalSeatControlRuleLoader';
import GlobalSeatControlRulesPage from '../../pages/GlobalSeatControlRulesPage';
import Route from './Route';

const page = <GlobalSeatControlRulesPage />;

const detail = (
  <Route params={['seatControlRuleId']}>
    {({ seatControlRuleId, navigate }) => (
      <GlobalSeatControlRuleLoader seatControlRuleId={seatControlRuleId}>
        {({ data }) => (
          <GlobalSeatControlRuleDetail
            data={data}
            onClose={() => navigate(routes.globalSeatConrolRules)}
            onEdit={() => navigate(routes.globalSeatConrolRuleEdit({ seatControlRuleId }))}
          />
        )}
      </GlobalSeatControlRuleLoader>
    )}
  </Route>
);

const editForm = (
  <Route params={['seatControlRuleId']}>
    {({ seatControlRuleId, navigate }) => (
      <GlobalSeatControlRuleLoader seatControlRuleId={seatControlRuleId}>
        {({ data }) => (
          <GlobalSeatControlRuleForm
            data={data}
            onClose={() => navigate(routes.globalSeatConrolRule({ seatControlRuleId }))}
          />
        )}
      </GlobalSeatControlRuleLoader>
    )}
  </Route>
);

const globalSeatConrolRulesPage: RouteObject = {
  path: routes.globalSeatConrolRules,
  element: page,
  children: [
    {
      path: routes.globalSeatConrolRule({ seatControlRuleId: ':seatControlRuleId' }),
      element: detail,
    },
    {
      path: routes.globalSeatConrolRuleEdit({ seatControlRuleId: ':seatControlRuleId' }),
      element: editForm,
    },
  ],
};

export default globalSeatConrolRulesPage;
