import { ReactNode, Children, Fragment } from 'react';

interface Props {
  children: ReactNode;
  separator?: ReactNode;
}

const Join = (props: Props) => {
  const { children, separator = ', ' } = props;

  return Children.map(children, (child, index) => (
    <Fragment key={index}>
      {index > 0 && separator}
      {child}
    </Fragment>
  ));
};

export default Join;
