import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RuleGroupType } from 'react-querybuilder';
import { useDebouncedCallback } from 'use-debounce';
import {
  activateWorkspaceSeatControlRule,
  createWorkspaceSeatControlRule,
  deactivateWorkspaceSeatControlRule,
  deleteWorkspaceSeatControlRule,
  updateWorkspaceSeatControlRule,
} from '../../../api';
import { ACTION_TYPE, SeatControlRuleSchema } from '../../../api/schemas/seatControlRuleSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Button from '../../../components/Button';
import Confirm from '../../../components/Confirm';
import Input from '../../../components/Input';
import QueryBuilder from '../../../components/QueryBuilder';
import { mapQuery, prepareQuery } from '../../../components/QueryBuilder/helpers';
import Tooltip from '../../../components/Tooltip';
import useQuickForm from '../../../hooks/useQuickForm';
import icon from '../../../static/icons';
import { required, handleError, minLength, maxLength } from '../../../utils/form';
import WorkspaceSettingsField from '../WorkspaceSettingsField';
import styles from './styles.module.scss';

interface Fields {
  name: string;
  query: RuleGroupType;
  action_type: SeatControlRuleSchema['action_type'];
}

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data?: SeatControlRuleSchema;
  onRemove?: () => void;
}

const WorkspaceSettingsRulesItem = (props: Props) => {
  const { workspaceId, data, onRemove } = props;

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const form = useQuickForm<Fields>({
    data,
    defaultValues: {
      name: data?.name || '',
      action_type: data?.action_type || ACTION_TYPE.PLAYER,
      query: data ? mapQuery(data.query) : { combinator: 'and', rules: [] },
    },
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    try {
      const payload = {
        name: values.name,
        action_type: values.action_type,
        query: prepareQuery(values.query),
      };

      if (data) {
        await updateWorkspaceSeatControlRule({ workspaceId, seatControlRuleId: data.id, payload });
      } else {
        await createWorkspaceSeatControlRule({ workspaceId, payload });

        if (onRemove) onRemove();
      }
    } catch (error) {
      handleError({ error, form });
    }
  };

  const debounced = useDebouncedCallback(() => handleSubmit(onSubmit)(), 500);

  return (
    <WorkspaceSettingsField>
      <div className={styles.container}>
        <div>
          <Button
            size="small"
            variant="transparent"
            className={styles.status}
            disabled={data?.is_deleted}
            icon={data?.is_active ? icon('pause', 16) : icon('play', 16)}
            onClick={async () => {
              if (data) {
                try {
                  if (data.is_active) {
                    await deactivateWorkspaceSeatControlRule({
                      workspaceId,
                      seatControlRuleId: data.id,
                    });
                  } else {
                    await activateWorkspaceSeatControlRule({
                      workspaceId,
                      seatControlRuleId: data.id,
                    });
                  }
                } catch (error) {
                  handleError({ error });
                }
              }
            }}
          />
          <Input.Quick
            hint
            name="name"
            control={control}
            rules={{ validate: { required, minLength: minLength(), maxLength: maxLength() } }}
            autoFocus={!data}
            onSubmit={handleSubmit(onSubmit)}
          />
          <div className={styles.buttons}>
            <Tooltip label={t('common.delete')}>
              <Confirm
                danger
                confirmText={t('common.delete')}
                onConfirm={async () => {
                  if (data) {
                    await deleteWorkspaceSeatControlRule({
                      workspaceId,
                      seatControlRuleId: data.id,
                    });
                  }

                  if (onRemove) onRemove();
                }}
              >
                {({ confirming }) => (
                  <Button
                    danger
                    size="small"
                    variant="transparent"
                    focused={confirming}
                    icon={icon('trash', 16)}
                  />
                )}
              </Confirm>
            </Tooltip>
            <Button
              size="small"
              variant="transparent"
              onClick={() => setOpen((prev) => !prev)}
              icon={open ? icon('chevronUp', 16) : icon('chevronDown', 16)}
            />
          </div>
        </div>
        {open && (
          <Controller
            name="query"
            control={control}
            rules={{
              validate: (value) => {
                const validateRules = (rules: RuleGroupType['rules']): boolean | string => {
                  if (rules) {
                    if (rules.length === 0) return true;

                    if (rules.length > 0) {
                      return rules.some((item) => 'rules' in item && validateRules(item.rules));
                    }
                  }

                  return false;
                };

                const hasError = validateRules(value.rules);

                if (hasError) return t('validations.group_or_condition');

                return true;
              },
            }}
            render={({ field }) => (
              <QueryBuilder
                value={field.value}
                error={errors.query?.message}
                onChange={(value) => {
                  setValue('query', value, { shouldValidate: true });

                  debounced();
                }}
              />
            )}
          />
        )}
      </div>
    </WorkspaceSettingsField>
  );
};

export default WorkspaceSettingsRulesItem;
