import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  label: ReactNode;
  children?: ReactNode;
  nested?: boolean;
}

const HistoryDialogCollapseField = (props: Props) => {
  const { label, children, nested = false } = props;

  return (
    <div className={styles.field}>
      <div className={styles.label}>
        {nested && <div className={styles.dot} />}
        {label}
      </div>
      {children && (
        <>
          <div className={styles.line} />
          <div className={styles.value}>{children}</div>
        </>
      )}
    </div>
  );
};

export default HistoryDialogCollapseField;
