import { ReactNode } from 'react';
import Button from '../../../../components/Button';
import icon from '../../../../static/icons';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  onRemove?: () => void;
}

const WorkspaceAccountFormField = (props: Props) => {
  const { children, onRemove } = props;

  return (
    <div className={styles.container}>
      {children}
      {onRemove && (
        <Button danger variant="transparent" icon={icon('trash', 16)} onClick={onRemove} />
      )}
    </div>
  );
};

export default WorkspaceAccountFormField;
