import { LobbySchema } from '../../api/schemas/lobbySchema';
import { COLOR } from '../../api/schemas/lobbyTableSchema';
import {
  checkIsCritical,
  checkIsWarning,
  checkIsOk,
  checkIsEmpty,
  checkIsHU,
  checkIsSixMax,
  checkIsFullRing,
  checkIsHasPlayers,
  checkIsFull,
} from './helpers';

type Counters = Record<string, number>;
type RangeCounters = Record<'min' | 'max', number | null>;

const getCounters = (data: LobbySchema[], unfilteredData: LobbySchema[]) => {
  const status: Counters = {
    critical: 0,
    warning: 0,
    ok: 0,
    empty: 0,
    banned: 0,
  };

  const color: Record<LobbySchema['table']['color'], number> = {
    [COLOR.UNKNOWN]: 0,
    [COLOR.GREEN]: 0,
    [COLOR.YELLOW]: 0,
    [COLOR.RED]: 0,
    [COLOR.PURPLE]: 0,
  };

  const rooms: Counters = {};
  const superLeagues: Counters = {};
  const leagues: Counters = {};
  const clubs: Counters = {};
  const currencies: Counters = {};
  const gameTypes: Counters = {};
  const limits: Counters = {};

  const tableSizes: Counters = {
    HU: 0,
    six_max: 0,
    full_ring: 0,
  };

  const playerCount: RangeCounters = {
    min: null,
    max: null,
  };

  const emptySeatsCount: RangeCounters = {
    min: null,
    max: null,
  };

  const workspaces: Counters = {};

  const trainerVersions: Counters = {};

  let empty = 0;
  let full = 0;

  data.forEach((item) => {
    const { table, accounts } = item;
    const {
      room,
      game_type,
      comparative_limit,
      league_id,
      club_id,
      currency,
      extra_fields,
      /* ff_pool */
    } = table;

    if (checkIsCritical(item)) status.critical += 1;
    if (checkIsWarning(item)) status.warning += 1;
    if (checkIsOk(item)) status.ok += 1;
    if (checkIsEmpty(item)) status.empty += 1;
    /* if (checkIsBanned(item)) status.banned += 1; */

    color[item.table.color] += 1;

    const roomsCount = rooms[room] || 0;

    let superLeagueCount = 0;

    if ('super_league_id' in extra_fields) {
      const { super_league_id } = extra_fields;

      superLeagueCount = superLeagues[String(super_league_id)] || 0;
    }

    const leagueCount = leagues[league_id] || 0;
    const clubCount = clubs[club_id] || 0;
    const currencyCount = currencies[currency] || 0;
    const gameTypeCount = gameTypes[game_type] || 0;
    const limitCount = limits[comparative_limit] || 0;

    rooms[room] = roomsCount + 1;

    if ('super_league_id' in extra_fields) {
      const { super_league_id } = extra_fields;

      superLeagues[String(super_league_id)] = superLeagueCount + 1;
    }

    leagues[league_id] = leagueCount + 1;
    clubs[club_id] = clubCount + 1;
    currencies[currency] = currencyCount + 1;
    gameTypes[game_type] = gameTypeCount + 1;
    limits[comparative_limit] = limitCount + 1;

    if (checkIsHU(item)) tableSizes.HU += 1;
    if (checkIsSixMax(item)) tableSizes.six_max += 1;
    if (checkIsFullRing(item)) tableSizes.full_ring += 1;

    accounts.forEach((account) => {
      const workspacesCount = workspaces[account.maintenance_id] || 0;

      workspaces[account.maintenance_id] = workspacesCount + 1;

      if (account.trainer_version) {
        const trainerVersionsCount = trainerVersions[account.trainer_version] || 0;

        trainerVersions[account.trainer_version] = trainerVersionsCount + 1;
      }
    });

    if (!checkIsHasPlayers(item)) empty += 1;
    if (checkIsFull(item)) full += 1;
  });

  unfilteredData.forEach((item) => {
    const { table } = item;
    const { player_count, max_no_of_seats } = table;

    if (playerCount.min === null) {
      playerCount.min = player_count;
    } else if (player_count < playerCount.min) {
      playerCount.min = player_count;
    }

    if (playerCount.max === null) {
      playerCount.max = player_count;
    } else if (player_count > playerCount.max) {
      playerCount.max = player_count;
    }

    const emptySeats = max_no_of_seats - player_count;

    if (emptySeatsCount.min === null) {
      emptySeatsCount.min = emptySeats;
    } else if (emptySeats < emptySeatsCount.min) {
      emptySeatsCount.min = emptySeats;
    }

    if (emptySeatsCount.max === null) {
      emptySeatsCount.max = emptySeats;
    } else if (emptySeats > emptySeatsCount.max) {
      emptySeatsCount.max = emptySeats;
    }
  });

  return {
    status,
    color,
    rooms,
    superLeagues,
    leagues,
    clubs,
    currencies,
    gameTypes,
    limits,
    tableSizes,
    playerCount,
    emptySeatsCount,
    workspaces,
    trainerVersions,
    empty,
    full,
  };
};

export default getCounters;
