import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import useLegacyRowSelection from '../../hooks/useLegacyRowSelection';
import usePrevious from '../../hooks/usePrevious';
import useRowSelection from '../../hooks/useRowSelection';
import useURL from '../../hooks/useURL';

type Context = ReturnType<typeof useRowSelection>;

interface State {
  disabled: boolean;
  setDisabled: (value: boolean) => void;
}

interface Config {
  disabledSelectAll?: boolean;
  legacyRowSelection?: boolean;
}

type Props = {
  ids: string[];
  children: ReactNode;
} & Config;

const TableContext = createContext<(Context & State & Config) | undefined>(undefined);

const TableProvider = (props: Props) => {
  const { ids, children, legacyRowSelection, ...rest } = props;

  const { urlParams } = useURL();

  const prevUrlParams = usePrevious(urlParams);

  delete urlParams.search;
  delete prevUrlParams.search;

  const useHook = legacyRowSelection ? useLegacyRowSelection : useRowSelection;

  const rowSelection = useHook({ ids });

  const { unselectAll } = rowSelection;

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (JSON.stringify(urlParams) !== JSON.stringify(prevUrlParams)) {
      unselectAll();
    }
  }, [urlParams, prevUrlParams, unselectAll]);

  const value = useMemo(
    () => ({ ...rowSelection, ...rest, disabled, setDisabled, legacyRowSelection }),
    [rowSelection, rest, disabled, setDisabled, legacyRowSelection]
  );

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
};

const useTableContext = () => {
  const context = useContext(TableContext);

  if (context === undefined) {
    throw new Error('useTableContext must be used within a TableProvider');
  }

  return context;
};

export { TableProvider, useTableContext };
