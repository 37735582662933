import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadWorkspaceClubManagersFilterValues } from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Filter from '../../components/Filter';
import queryKeys from '../../config/queryKeys';
import useFilter from '../../hooks/useFilter';
import useURL from '../../hooks/useURL';
import { TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';

interface Fields {
  room_in: string[];
  clubs_in: string[];
  updated_ge: string;
  updated_le: string;
  created_ge: string;
  created_le: string;
  show_deleted: boolean;
}

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceClubManagerFilter = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { urlParams } = useURL();

  const queryKey = [
    queryKeys.workspaceClubManagersFilterValues({ workspaceId }),
    urlParams.show_deleted,
  ];
  const queryFn = () =>
    loadWorkspaceClubManagersFilterValues(
      { workspaceId },
      { show_deleted: urlParams.show_deleted }
    );

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  const filter = useFilter<Fields>({
    room_in: 'multi',
    clubs_in: 'multi',
    updated_ge: 'single',
    updated_le: 'single',
    created_ge: 'single',
    created_le: 'single',
    show_deleted: 'toggle',
  });

  const { values, setValue, clearValue } = filter;

  const { room_in, clubs_in, updated_ge, updated_le, created_ge, created_le, show_deleted } =
    values;

  const filterProps = { filter };

  if (isPending) {
    return (
      <Filter {...filterProps}>
        <Filter.Placeholder />
      </Filter>
    );
  }

  if (!data) return null;

  const { room, clubs } = data;

  return (
    <Filter {...filterProps}>
      <div>
        {room.length !== 0 && (
          <Filter.Collapse
            label={t('common.poker_room')}
            count={room_in.length}
            onClear={clearValue('room_in')}
          >
            <Filter.MultiCheckbox
              config={room.map((item) => ({
                value: String(item),
                label: TRAINER_POKER_ROOM_LABELS[item],
                onChange: setValue('room_in'),
                checked: room_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {clubs.length !== 0 && (
          <Filter.Collapse
            label={t('common.club')}
            count={clubs_in.length}
            onClear={clearValue('clubs_in')}
          >
            <Filter.MultiCheckbox
              config={clubs.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('clubs_in'),
                checked: clubs_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        <Filter.Collapse
          label={t('common.modified')}
          {...((updated_ge || updated_le) && {
            onClear: clearValue(['updated_ge', 'updated_le']),
          })}
        >
          <Filter.Date
            from={{ value: updated_ge, onChange: setValue('updated_ge') }}
            to={{ value: updated_le, onChange: setValue('updated_le') }}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.created')}
          {...((created_ge || created_le) && {
            onClear: clearValue(['created_ge', 'created_le']),
          })}
        >
          <Filter.Date
            from={{ value: created_ge, onChange: setValue('created_ge') }}
            to={{ value: created_le, onChange: setValue('created_le') }}
          />
        </Filter.Collapse>
      </div>
      <Filter.Switcher checked={show_deleted} onChange={setValue('show_deleted')}>
        {t('common.show_deleted')}
      </Filter.Switcher>
    </Filter>
  );
};

export default WorkspaceClubManagerFilter;
