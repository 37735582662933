import api from '../services/api';
import currentUserSessionSchema, {
  CurrentUserSessionSchema,
} from './schemas/currentUserSessionSchema';

interface Payload {
  refresh_token: CurrentUserSessionSchema['refresh_token'];
}

interface Args {
  payload: Payload;
}

const refreshUserToken = async (args: Args) => {
  const { payload } = args;

  const response = await api.post('/api/refresh-token', payload);

  return currentUserSessionSchema.parse(response.data);
};

export default refreshUserToken;
