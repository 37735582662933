import {
  useState,
  createContext,
  useMemo,
  useCallback,
  useEffect,
  useContext,
  ReactNode,
} from 'react';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePrevious from '../../hooks/usePrevious';

interface Context {
  isFilterVisible: boolean;
  openFilter: () => void;
  closeFilter: () => void;
  hasTabs: boolean;
}

const PageContext = createContext<Context | undefined>(undefined);

interface Props {
  children: ReactNode;
  hasTabs: boolean;
}

const PageProvider = (props: Props) => {
  const { children, hasTabs } = props;

  const { isDesktop } = useBreakpoints();

  const [isFilterVisible, setIsFilterVisible] = useState(isDesktop);

  const openFilter = useCallback(() => setIsFilterVisible(true), [setIsFilterVisible]);
  const closeFilter = useCallback(() => setIsFilterVisible(false), [setIsFilterVisible]);

  const prevIsDesktop = usePrevious(isDesktop);

  useEffect(() => {
    if (isDesktop !== prevIsDesktop) {
      setIsFilterVisible(isDesktop);
    }
  }, [isDesktop, prevIsDesktop]);

  const value = useMemo(
    () => ({
      isFilterVisible,
      hasTabs,
      openFilter,
      closeFilter,
    }),
    [isFilterVisible, hasTabs, openFilter, closeFilter]
  );

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

const usePageContext = () => {
  const context = useContext(PageContext);

  if (context === undefined) {
    throw new Error('usePageContext must be used within a PageProvider');
  }

  return context;
};

export { PageProvider, usePageContext };
