import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceStageSchema } from '../schemas/stage/workspaceStageSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  stageId: WorkspaceStageSchema['id'];
}

const deleteWorkspaceAccountStage = async (args: Args) => {
  const { workspaceId, stageId } = args;

  const response = await api.delete(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/stage/${stageId}`);

  return emptyResponseSchema.parse(response.data);
};

export default deleteWorkspaceAccountStage;
