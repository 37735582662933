import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';
import { WorkspaceWalletSchema } from '../schemas/workspaceWalletSchema';

type Schema = WorkspaceWalletSchema;

interface Payload {
  address?: Schema['address'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  walletId: Schema['id'];
  payload: Payload;
}

const updateWorkspaceWallet = async (args: Args) => {
  const { workspaceId, walletId, payload } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/wallet/${walletId}`,
    payload
  );

  return emptyResponseSchema.parse(response.data);
};

export default updateWorkspaceWallet;
