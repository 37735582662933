import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import { WorkspaceAccountTransactionSchema } from '../schemas/account/workspaceAccountTransactionSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = WorkspaceAccountTransactionSchema;

interface Payload {
  file_ids: WorkspaceAccountTransactionSchema['files'][number]['id'][];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
  transactionId: Schema['id'];
  payload: Payload;
}

const attachFilesWorkspaceAccountTransaction = async (args: Args) => {
  const { workspaceId, accountId, transactionId, payload } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/${accountId}/transaction/${transactionId}`,
    payload
  );

  return emptyResponseSchema.parse(response.data);
};

export default attachFilesWorkspaceAccountTransaction;
