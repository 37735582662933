import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { loadWorkspaceClubs } from '../../../../api';
import { WorkspaceSchema } from '../../../../api/schemas/workspaceSchema';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';
import Tooltip from '../../../../components/Tooltip';
import icon from '../../../../static/icons';
import { required } from '../../../../utils/form';
import { WorkspaceAccountFields } from '../../../account/types';
import styles from './styles.module.scss';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  required?: boolean;
}

const WorkspaceAccountFormClubIds = (props: Props) => {
  const { workspaceId, required: req } = props;

  const { t } = useTranslation();

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<WorkspaceAccountFields>();

  const clubIds = watch('club_ids');
  const stoppedClubIds = watch('stopped_club_ids');
  const pokerRoom = watch('room')?.value;

  return (
    <div className={styles.container}>
      <Controller
        name="club_ids"
        control={control}
        {...(req && { rules: { validate: required } })}
        render={() => (
          <Select.Async
            onLoad={async (args) => {
              const response = await loadWorkspaceClubs(
                { workspaceId },
                { ...args, size: '100', ...(pokerRoom && { poker_room_in: `${pokerRoom}` }) }
              );

              const options = response.items.map((item) => ({
                value: item.id,
                label: item.name,
                caption: item.code,
              }));

              return options;
            }}
          >
            {({ options, ...rest }) => (
              <Select
                label={t('common.clubs')}
                options={options.filter(
                  (item) => !clubIds.map((el) => el.value).includes(item.value)
                )}
                value={null}
                onChange={(value) => setValue('club_ids', value ? [...clubIds, value] : [])}
                error={errors.club_ids?.message}
                {...rest}
              />
            )}
          </Select.Async>
        )}
      />
      {clubIds.map((item) => (
        <div key={item.value} className={styles.club}>
          <div className={styles.label}>
            {item.label}
            <span>{item.caption}</span>
          </div>
          <div className={styles.controls}>
            {stoppedClubIds.includes(item.value) ? (
              <Tooltip label={t('sentences.remove_club_from_stop_list')}>
                <Button
                  size="small"
                  variant="transparent"
                  icon={icon('play', 16)}
                  onClick={() =>
                    setValue(
                      'stopped_club_ids',
                      stoppedClubIds.filter((el) => el !== item.value)
                    )
                  }
                />
              </Tooltip>
            ) : (
              <Tooltip label={t('sentences.add_club_to_stop_list')}>
                <Button
                  size="small"
                  variant="transparent"
                  icon={icon('pause', 16)}
                  onClick={() => setValue('stopped_club_ids', [...stoppedClubIds, item.value])}
                />
              </Tooltip>
            )}
            <Button
              danger
              size="small"
              variant="transparent"
              icon={icon('trash', 16)}
              onClick={() =>
                setValue(
                  'club_ids',
                  clubIds.filter((el) => el.value !== item.value)
                )
              }
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default WorkspaceAccountFormClubIds;
