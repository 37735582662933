import {
  useContext,
  createContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import { GlobalClubDeleteErrorSchema } from '../../api/schemas/globalClubDeleteErrorSchema';

type DeleteError = GlobalClubDeleteErrorSchema | null;

interface Context {
  deleteError: DeleteError;
  setDeleteError: Dispatch<SetStateAction<DeleteError>>;
}

const GlobalClubContext = createContext<Context | undefined>(undefined);

interface Props {
  children: ReactNode;
}

const GlobalClubProvider = (props: Props) => {
  const { children } = props;

  const [deleteError, setDeleteError] = useState<DeleteError>(null);

  const value = useMemo(() => ({ deleteError, setDeleteError }), [deleteError]);

  return <GlobalClubContext.Provider value={value}>{children}</GlobalClubContext.Provider>;
};

const useGlobalClubContext = () => {
  const context = useContext(GlobalClubContext);

  if (context === undefined) {
    throw new Error('useGlobalClubContext must be used within a GlobalClubProvider');
  }

  return context;
};

export { useGlobalClubContext, GlobalClubProvider };
