import { NumericFormat } from 'react-number-format';

interface Props {
  children: number;
  prefix?: string;
}

const PrettyNumber = (props: Props) => {
  const { children, prefix } = props;

  return (
    <NumericFormat
      value={children}
      displayType="text"
      thousandSeparator
      renderText={(value) => {
        const [integer, decimal] = value.split('.');

        return (
          <div>
            {prefix}
            {integer}
            {decimal && <span>{`.${decimal}`}</span>}
          </div>
        );
      }}
    />
  );
};

export default PrettyNumber;
