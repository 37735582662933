import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import workspaceClubTransactionSchema, {
  WorkspaceClubTransactionSchema,
} from '../schemas/workspaceClubTransactionSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  transactionId: WorkspaceClubTransactionSchema['id'];
}

const loadWorkspaceClubTransaction = async (args: Args) => {
  const { workspaceId, transactionId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/club-manager/transactions/${transactionId}`
  );

  return workspaceClubTransactionSchema.parse(response.data);
};

export default loadWorkspaceClubTransaction;
