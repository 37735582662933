import clsx from 'clsx';
import Placeholder from '../../Placeholder';
import { Columns } from '../types';
import styles from './styles.module.scss';

interface Props<T> {
  columns: Columns<T>;
}

function TableRowsPlaceholder<T>(props: Props<T>) {
  const { columns } = props;

  return (
    <>
      {Array.from({ length: 7 }).map((_, index) => (
        <div key={index} className={styles.container}>
          {columns.map((column, columnIndex) => (
            <div key={columnIndex} className={styles.cell} style={column.style}>
              <Placeholder
                className={clsx(styles.placeholder, column.controls && styles.controls)}
              />
            </div>
          ))}
        </div>
      ))}
    </>
  );
}

export default TableRowsPlaceholder;
