import { z } from 'zod';
import { copy } from '../../../utils/clipboard';
import { cutUUID } from '../../../utils/data';
import styles from './styles.module.scss';

interface Props {
  id: string;
}

const CollapseId = (props: Props) => {
  const { id } = props;

  const handleClick = () => copy(id);

  const parsedId = z.string().uuid().safeParse(id);

  return (
    <button type="button" onClick={handleClick} className={styles.container}>
      {parsedId.success ? cutUUID(id) : id}
    </button>
  );
};

export default CollapseId;
