import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Dropdown from '../../components/Dropdown';
import { compact } from '../../utils';
import useDropdown from './useDropdown';

interface Props {
  data: WorkspaceSchema;
}

const GlobalWorkspaceDropdown = (props: Props) => {
  const { title, disabled, options } = useDropdown(props);

  const compactOptions = compact([options.copy, options.divider, options.delete]);

  return <Dropdown title={title} buttonSize="small" disabled={disabled} options={compactOptions} />;
};

export default GlobalWorkspaceDropdown;
