import Checkbox from '../../Checkbox';
import { useTableContext } from '../TableContext';

const TableRowSelectAll = () => {
  const { selected, unselected, selectAllMode, allSelected, disabledSelectAll, selectAll } =
    useTableContext();

  const checked = (() => {
    if (selectAllMode) {
      if (unselected.length > 0) return true;

      return selectAllMode;
    }

    return selected.length > 0;
  })();

  return (
    <Checkbox
      indeterminate={selectAllMode ? unselected.length > 0 : selected.length > 0 && !allSelected}
      onChange={selectAll}
      checked={checked}
      disabled={disabledSelectAll}
    />
  );
};

export default TableRowSelectAll;
