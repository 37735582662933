import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import icon from '../../static/icons';
import Button from '../Button';
import Dialog, { Props as DialogProps } from '../Dialog';
import styles from './styles.module.scss';

interface Props {
  title: string;
  description: ReactNode;
  confirmText: string;
  onConfirm: () => void;
  danger?: boolean;
  children?: DialogProps['label'];
  onClose?: () => void;
}

const ConfirmDialog = (props: Props) => {
  const { title, description, confirmText, onConfirm, danger, children, onClose } = props;

  const { t } = useTranslation();

  return (
    <Dialog label={children} className={styles.dialog} onClose={onClose}>
      {({ close }) => (
        <>
          <Dialog.Top>
            <Dialog.Title danger={danger} {...(danger && { icon: icon('alertCircle', 24) })}>
              {title}
            </Dialog.Title>
          </Dialog.Top>
          <span className={styles.description}>{description}</span>
          <Dialog.Footer>
            <Button variant="transparent" onClick={() => close()}>
              {t('common.cancel')}
            </Button>
            <Button danger={danger} onClick={onConfirm}>
              {confirmText}
            </Button>
          </Dialog.Footer>
        </>
      )}
    </Dialog>
  );
};

export default ConfirmDialog;
