import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import workspaceClubWhitelistSchema, {
  WorkspaceClubWhitelistSchema,
} from '../schemas/workspaceClubWhitelistSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  whitelistMemberId: WorkspaceClubWhitelistSchema['id'];
}

const loadWorkspaceClubWhitelistMember = async (args: Args) => {
  const { workspaceId, whitelistMemberId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/club-manager/white-list/${whitelistMemberId}`
  );

  return workspaceClubWhitelistSchema.parse(response.data);
};

export default loadWorkspaceClubWhitelistMember;
