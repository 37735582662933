import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import workspaceBalanceSchema from '../schemas/workspaceBalanceSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadGlobalWorkspaceBalance = async (args: Args) => {
  const { workspaceId } = args;

  const response = await api.get(`/api/global/${ALIASES.WORKSPACE.L}/${workspaceId}/balance`);

  return workspaceBalanceSchema.parse(response.data);
};

export default loadGlobalWorkspaceBalance;
