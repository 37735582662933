import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { IdentitySchema } from '../../api/schemas/identitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Button from '../../components/Button';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import { addWhen } from '../../utils';
import { checkWorkspaceIdentityPokerFarm } from '../../utils/workspace';
import { toggleMarket } from './helpers';
import WorkspaceIdentityDetailDropdown from './WorkspaceIdentityDetailDropdown';
import WorkspaceIdentityDetailTabDescription from './WorkspaceIdentityDetailTabDescription';
import WorkspaceIdentityDetailTabGeneral from './WorkspaceIdentityDetailTabGeneral';
import WorkspaceIdentityDetailTabRental from './WorkspaceIdentityDetailTabRental';

interface Props {
  data: IdentitySchema;
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
  onEdit: () => void;
  getAccountRoute?: (args: { accountId: WorkspaceAccountSchema['id'] }) => string;
}

const WorkspaceIdentityDetail = (props: Props) => {
  const { data, workspaceId, onClose, onEdit, getAccountRoute } = props;

  const { t } = useTranslation();

  const { first_name, last_name, on_market, is_deleted } = data;

  const isPokerFarm = checkWorkspaceIdentityPokerFarm(workspaceId);

  const titleIcons = (
    <>
      {is_deleted && icon('trash', 12)}
      {!isPokerFarm && on_market && (
        <Tooltip label={t('common.on_market')}>{icon('shoppingCart', 12)}</Tooltip>
      )}
    </>
  );

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon
        title={`${last_name} ${first_name}`}
        icons={titleIcons}
        onClose={onClose}
        tabs={[
          {
            label: t('common.general'),
            content: (
              <WorkspaceIdentityDetailTabGeneral
                data={data}
                workspaceId={workspaceId}
                getAccountRoute={getAccountRoute}
              />
            ),
          },
          {
            label: t('common.description'),
            content: <WorkspaceIdentityDetailTabDescription data={data} />,
          },
          ...addWhen(
            {
              label: t('common.rental'),
              content: <WorkspaceIdentityDetailTabRental data={data} />,
            },
            !isPokerFarm
          ),
        ]}
      >
        <PageAddon.Controls>
          <PageAddon.Controls.Group>
            {!isPokerFarm && (
              <Tooltip
                label={on_market ? t('common.remove_from_market') : t('common.sell_on_market')}
                hideOnMobile={!is_deleted}
              >
                <div>
                  <Button
                    variant="transparent"
                    icon={on_market ? icon('shoppingCartCrossed', 16) : icon('shoppingCart', 16)}
                    onClick={() => toggleMarket(workspaceId, data)}
                    data-testid="market"
                    disabled={is_deleted}
                  />
                </div>
              </Tooltip>
            )}
            <Tooltip
              label={on_market ? t('common.identity_is_on_market') : t('common.edit')}
              hideOnMobile={!on_market && !is_deleted}
            >
              <div>
                <Button
                  variant="transparent"
                  icon={icon('edit', 16)}
                  onClick={onEdit}
                  data-testid="edit"
                  disabled={on_market || is_deleted}
                />
              </div>
            </Tooltip>
            <WorkspaceIdentityDetailDropdown workspaceId={workspaceId} data={data} />
          </PageAddon.Controls.Group>
        </PageAddon.Controls>
      </PageAddon>
    </PageAddon.Group>
  );
};

export default WorkspaceIdentityDetail;
