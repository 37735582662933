import { useQuery } from '@tanstack/react-query';
import { lazy, Suspense, useState } from 'react';
import {
  createWorkspaceAccountSession,
  deleteWorkspaceAccountSession,
  loadWorkspaceAccountSessions,
  updateWorkspaceAccountSession,
} from '../../../api';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSessionSchema } from '../../../api/schemas/session/workspaceSessionSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import queryKeys from '../../../config/queryKeys';
import routes from '../../../config/routes';
import useURL from '../../../hooks/useURL';
import useWebSocketMessage from '../../../hooks/useWebSocketMessage';
import Error403Page from '../../../pages/Error403Page';
import { handleError } from '../../../utils/form';
import { createQueryData, deleteQueryData, updateQueryData } from '../../../utils/queryClient';
import AccountScheduleSpinner from '../../account/AccountScheduleSpinner';
import { useWorkspaceAccountQuery } from '../queries';
import WorkspaceAccountScheduleDialog from '../WorkspaceAccountScheduleDialog';
import WorkspaceAccountScheduleDropdown from './WorkspaceAccountScheduleDropdown';

const AccountSchedule = lazy(() => import('../../account/AccountSchedule'));

interface Props {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
}

const WorkspaceAccountSchedule = (props: Props) => {
  const { workspaceId, accountId } = props;

  const { navigate } = useURL();

  const [start_ge, setStartGe] = useState('');
  const [start_le, setStartLe] = useState('');

  const queryKey = [
    queryKeys.workspaceAccountSchedule({ workspaceId, accountId }),
    start_ge,
    start_le,
  ];

  const queryFn = () =>
    loadWorkspaceAccountSessions(
      { workspaceId, accountId },
      { show_completed: 'true', start_ge, start_le }
    );

  const { data, isPending, error } = useQuery({
    queryKey,
    queryFn,
    enabled: Boolean(start_ge) && Boolean(start_le),
    meta: { ignoredStatuses: [403] },
  });

  const { data: account, error: accountError } = useWorkspaceAccountQuery({
    workspaceId,
    accountId,
  });

  useWebSocketMessage<WorkspaceSessionSchema>({
    domain: 'Session',
    onMessage: (message) => {
      if (message.action === 'Created') {
        if (message.payload.account.id === accountId) {
          createQueryData(queryKey, message.payload);
        }
      }

      if (message.action === 'Edited') {
        updateQueryData(queryKey, message.payload, (item) => item.id === message.payload.id);
      }

      if (message.action === 'Deleted') {
        deleteQueryData<WorkspaceSessionSchema>(queryKey, (item) => item.id === message.payload.id);
      }
    },
  });

  if (error?.response?.status === 403 || accountError?.response?.status === 403) {
    return <Error403Page />;
  }

  if (!account) return <AccountScheduleSpinner />;

  return (
    <Suspense fallback={<AccountScheduleSpinner />}>
      <AccountSchedule
        onFilter={(args) => {
          setStartGe(args.start_ge);
          setStartLe(args.start_le);
        }}
        account={account}
        heading={account.login}
        data={data}
        loading={isPending}
        canCreateAndEdit
        canDelete
        extra={<WorkspaceAccountScheduleDropdown workspaceId={workspaceId} accountId={accountId} />}
        onBack={() => navigate(routes.workspaceAccount({ workspaceId, accountId }))}
        onDelete={async (sessionId) => {
          try {
            await deleteWorkspaceAccountSession({ workspaceId, accountId, sessionId });
          } catch (err) {
            handleError({ error: err });
          }
        }}
        onSelect={async (start, end, type) => {
          try {
            await createWorkspaceAccountSession({
              workspaceId,
              accountId,
              payload: { start, end, session_type: type },
            });
          } catch (err) {
            handleError({ error: err });
          }
        }}
        onEventChange={async (start, end, id) => {
          try {
            await updateWorkspaceAccountSession({
              workspaceId,
              accountId,
              sessionId: id,
              payload: { start, end },
            });
          } catch (err) {
            handleError({ error: err });
          }
        }}
      >
        {({ id, onClose }) => (
          <WorkspaceAccountScheduleDialog
            sessionId={id}
            account={account}
            workspaceId={workspaceId}
            onClose={onClose}
          />
        )}
      </AccountSchedule>
    </Suspense>
  );
};

export default WorkspaceAccountSchedule;
