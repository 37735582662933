import { useTranslation } from 'react-i18next';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageCreateButton from '../../components/PageCreateButton';
import icon from '../../static/icons';
import { checkWorkspaceIdentityPokerFarm } from '../../utils/workspace';
import useActions from './useActions';

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceIdentityCreateButton = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { createIdentity, openMarket } = useActions({ workspaceId });

  const isPokerFarm = checkWorkspaceIdentityPokerFarm(workspaceId);

  if (isPokerFarm) {
    return <PageCreateButton onClick={createIdentity}>{t('common.identity')}</PageCreateButton>;
  }

  return (
    <PageCreateButton
      onClick={createIdentity}
      more={[
        { icon: icon('shoppingCart', 20), label: t('common.buy_on_market'), onClick: openMarket },
      ]}
    >
      {t('common.identity')}
    </PageCreateButton>
  );
};

export default WorkspaceIdentityCreateButton;
