import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import StorybookPage from '../../pages/StorybookPage';

const storybookPage: RouteObject = {
  path: routes.storybook,
  element: <StorybookPage />,
};

export default storybookPage;
