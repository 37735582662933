import { useTranslation } from 'react-i18next';
import PageAddon from '..';
import Button from '../../Button';
import PageAddonCircles from '../PageAddonCircles';
import PageAddonControls from '../PageAddonControls';
import styles from './styles.module.scss';

interface Props {
  title: string;
  description: string;
  onError: () => void;
}

const PageAddonError = (props: Props) => {
  const { title, description, onError } = props;

  const { t } = useTranslation();

  return (
    <PageAddon title={title}>
      <div className={styles.container}>
        <PageAddonCircles type="error" />
        <span className={styles.title}>{t('common.error')}</span>
        <span className={styles.description}>{description}</span>
      </div>
      <PageAddonControls>
        <Button onClick={onError}>{t('common.done')}</Button>
      </PageAddonControls>
    </PageAddon>
  );
};

export default PageAddonError;
