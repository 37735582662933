import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './styles/global.scss';
import './styles/toastify.scss';
import Root from './components/Root';
import './i18n';

if (window.ENV !== 'dev') {
  Sentry.init({
    dsn: window.SENTRY_URL,
    environment: window.ENV,
    ignoreErrors: ['Request failed with status code 503', 'Request failed with status code 401'],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [Sentry.replayIntegration()],
  });
}

const root = document.getElementById('root');

if (root) {
  createRoot(root).render(
    <StrictMode>
      <Root />
    </StrictMode>
  );
}
