import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createGlobalAgent, updateGlobalAgent } from '../../../api';
import { AgentSchema } from '../../../api/schemas/agentSchema';
import Form from '../../../components/Form';
import Input from '../../../components/Input';
import PageAddon from '../../../components/PageAddon';
import SubmitButton from '../../../components/SubmitButton';
import Textarea from '../../../components/Textarea';
import { required } from '../../../utils/form';
import notify from '../../../utils/notify';
import styles from './styles.module.scss';

interface Fields {
  name: string;
  contact: string;
}

interface CommonProps {
  onClose: () => void;
}

interface CreateProps {
  onCreate: (args: { agentId: AgentSchema['id'] }) => void;
  data?: never;
  onEdit?: never;
}

interface EditProps {
  data: AgentSchema;
  onEdit: () => void;
  onCreate?: never;
}

type Props = CommonProps & (CreateProps | EditProps);

const GlobalAgentForm = (props: Props) => {
  const { data, onClose, onCreate, onEdit } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: { name: data?.name || '', contact: data?.contact || '' },
  });

  const {
    register,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    const payload = { name: values.name, contact: values.contact || null };

    if (data) {
      await updateGlobalAgent({ agentId: data.id, payload });

      onEdit();
    } else {
      const response = await createGlobalAgent({ payload });

      onCreate({ agentId: response.id });
    }

    notify('success', {
      title: data ? t('sentences.changes_have_been_saved') : t('sentences.record_has_been_created'),
    });
  };

  return (
    <PageAddon
      onClose={onClose}
      title={data ? `${t('common.edit')} ${data.name}` : t('common.create_agent')}
    >
      <Form form={form} onSubmit={onSubmit}>
        <Input
          label={`${t('common.name')}*`}
          error={errors.name?.message}
          {...register('name', { validate: required })}
        />
        <Textarea
          maxLength={0}
          label={t('common.contacts')}
          className={styles.textarea}
          error={errors.contact?.message}
          {...register('contact')}
        />
        <PageAddon.Controls>
          <SubmitButton>{data ? t('common.save') : t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default GlobalAgentForm;
