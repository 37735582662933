import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { WorkspaceClubTransactionSchema } from '../../api/schemas/workspaceClubTransactionSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import { formatAbsoluteDate } from '../../utils/date';
import { CLUB_TRANSACTIONS_TYPES_LABELS, canCancel } from './helpers';
import WorkspaceClubTransactionAmount from './WorkspaceClubTransactionAmount';
import WorkspaceClubTransactionDetailDropdown from './WorkspaceClubTransactionDetailDropdown';
import WorkspaceClubTransactionPID from './WorkspaceClubTransactionPID';
import WorkspaceClubTransactionStatus from './WorkspaceClubTransactionStatus';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: WorkspaceClubTransactionSchema;
  onClose: () => void;
}

const WorkspaceClubTransactionDetail = (props: Props) => {
  const { workspaceId, data, onClose } = props;

  const { t } = useTranslation();

  const { id, type, modified_on, created_on } = data;

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon title={formatAbsoluteDate(created_on)} onClose={onClose}>
        <PageAddon.Fields>
          <PageAddon.Id id={id} />
          <PageAddon.Field label={t('common.type')}>
            {CLUB_TRANSACTIONS_TYPES_LABELS[type]}
          </PageAddon.Field>
          <PageAddon.Field label={t('common.status')}>
            <WorkspaceClubTransactionStatus data={data} />
          </PageAddon.Field>
          <PageAddon.Field label={t('common.amount')}>
            <WorkspaceClubTransactionAmount data={data} />
          </PageAddon.Field>
          <PageAddon.Field label={t('common.pid')}>
            <WorkspaceClubTransactionPID data={data} caption={false} />
          </PageAddon.Field>
          <PageAddon.Field label={t('common.modified')}>
            {formatAbsoluteDate(modified_on)}
          </PageAddon.Field>
          <PageAddon.Field label={t('common.created')}>
            {formatAbsoluteDate(created_on)}
          </PageAddon.Field>
        </PageAddon.Fields>
        {canCancel(data) && (
          <PageAddon.Controls>
            <PageAddon.Controls.Group>
              <WorkspaceClubTransactionDetailDropdown data={data} workspaceId={workspaceId} />
            </PageAddon.Controls.Group>
          </PageAddon.Controls>
        )}
      </PageAddon>
    </PageAddon.Group>
  );
};

export default WorkspaceClubTransactionDetail;
