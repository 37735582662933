import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import History from '@tiptap/extension-history';
import ListItem from '@tiptap/extension-list-item';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { useEditor, EditorContent } from '@tiptap/react';
import clsx from 'clsx';
import { ReactNode, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import icon from '../../static/icons';
import Button from '../Button';
import Dialog from '../Dialog';
import Error from '../Error';
import Label, { getLabelProps } from '../Label';
import EditorToolbar from './EditorToolbar';
import styles from './styles.module.scss';

type Value = string;

interface Props {
  value: Value;
  label: Parameters<typeof getLabelProps>[0];
  error?: string;
  addon?: ReactNode;
  onChange?: (value: Value) => void;
}

const Editor = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { value: content, label, error, addon, onChange } = props;

  const { t } = useTranslation();
  const [maximized, setMaximized] = useState(false);

  const editor = useEditor({
    content,
    editorProps: { attributes: { class: styles.editor } },
    extensions: [Document, Text, Paragraph, History, Bold, BulletList, ListItem],
    onUpdate: (value) => {
      if (onChange) {
        onChange(value.editor.getHTML());
      }
    },
  });

  const labelProps = getLabelProps(label);

  const close = () => setMaximized(false);

  const field = (
    <div ref={ref} className={styles.container}>
      <Label addon={addon} {...labelProps} />
      <EditorContent
        editor={editor}
        className={clsx(styles.wrapper, {
          [styles.focused]: editor?.isFocused,
          [styles.error]: error,
        })}
      />
      <EditorToolbar editor={editor}>
        <button type="button" onClick={() => setMaximized((prev) => !prev)}>
          {maximized ? icon('minimize', 16) : icon('maximize', 16)}
        </button>
      </EditorToolbar>
      <Error error={error} />
    </div>
  );

  if (maximized) {
    return (
      <Dialog secondary className={styles.dialog} onClose={close}>
        {() => (
          <>
            <Dialog.Top>
              <Dialog.Title>{labelProps.children}</Dialog.Title>
            </Dialog.Top>
            {field}
            <Dialog.Footer>
              <Button onClick={close}>{t('common.apply')}</Button>
            </Dialog.Footer>
          </>
        )}
      </Dialog>
    );
  }

  return field;
});

export default Editor;
