import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { IdentitySchema } from '../schemas/identitySchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Payload = Array<{
  identity_id: IdentitySchema['id'];
  rental_period: number;
}>;

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const buyWorkspaceMarketIdentities = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/identity-market/buy`,
    payload
  );

  return emptyResponseSchema.parse(response.data);
};

export default buyWorkspaceMarketIdentities;
