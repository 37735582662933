import { createContext, useContext } from 'react';
import useDatePicker from './useDatePicker';

type Context = ReturnType<typeof useDatePicker> & { closeDatePicker: () => void };

const DatePickerContext = createContext<Context | undefined>(undefined);

interface Props extends Context {
  children: React.ReactNode;
}

const DatePickerProvider = (props: Props) => {
  const { children, ...rest } = props;

  return <DatePickerContext.Provider value={rest}>{children}</DatePickerContext.Provider>;
};

const useDatePickerContext = () => {
  const context = useContext(DatePickerContext);

  if (context === undefined) {
    throw new Error('useDatePickerContext must be used within a DatePickerProvider');
  }

  return context;
};

export { DatePickerProvider, useDatePickerContext };
