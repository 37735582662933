import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Auth from '../../components/Auth';
import Form from '../../components/Form';
import PasswordInput from '../../components/PasswordInput';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { logoutUser, receiveRecoveryPassword } from '../../utils/auth';
import { password } from '../../utils/form';
import notify from '../../utils/notify';

interface Fields {
  password: string;
}

const ResetPage = () => {
  const { t } = useTranslation();
  const { urlParams, navigate } = useURL();
  const { hash } = urlParams;

  const form = useForm<Fields>({ defaultValues: { password: '' } });

  const {
    register,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    await receiveRecoveryPassword({ hash }, values);

    await logoutUser();

    notify('success', { title: t('sentences.password_has_been_changed') });

    navigate(routes.login, { params: false });
  };

  return (
    <Auth
      title={t('common.create_new_password')}
      description={t('sentences.enter_at_least_6_characters')}
    >
      <Form form={form} onSubmit={onSubmit}>
        <PasswordInput
          error={errors.password?.message}
          {...register('password', { validate: password })}
        />
        <Auth.SubmitButton>{t('common.change_your_password')}</Auth.SubmitButton>
      </Form>
      <Auth.Link back to={routes.login}>
        {t('common.back_to_log_in')}
      </Auth.Link>
    </Auth>
  );
};

export default ResetPage;
