import { convertToUrlParams } from '../../../hooks/useURL';

export const getUrlParams = (values: object) =>
  convertToUrlParams(
    Object.fromEntries(
      Object.entries(values).map(([key, value]) => [
        key,
        Array.isArray(value) ? value.map((el) => el.value) : value,
      ])
    )
  );
