import { ReactNode } from 'react';
import icon from '../../../static/icons';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
}

const PageAddonDangerTitle = (props: Props) => {
  const { children } = props;

  return (
    <div className={styles.container}>
      {icon('alertCircle', 24)}
      {children}
    </div>
  );
};

export default PageAddonDangerTitle;
