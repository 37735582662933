import { WorkspaceClubMemberSchema } from '../../api/schemas/workspaceClubMemberSchema';
import PrettyNumber from '../../components/PrettyNumber';
import { convertCentsToDollars } from '../../utils/data';

interface Props {
  data: WorkspaceClubMemberSchema;
}

const WorkspaceClubMemberBalance = (props: Props) => {
  const { data } = props;

  const { balance } = data;

  return <PrettyNumber>{convertCentsToDollars(balance)}</PrettyNumber>;
};

export default WorkspaceClubMemberBalance;
