import { useTranslation } from 'react-i18next';
import {
  attachFilesWorkspaceAccountTransaction,
  finalizeWorkspaceAccountTransaction,
  loadWorkspaceAccountTransactions,
} from '../../../api';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import PageAddon from '../../../components/PageAddon';
import queryKeys from '../../../config/queryKeys';
import usePaginatedDataNew from '../../../hooks/usePaginatedDataNew';
import { uploadFiles } from '../../../utils/files';
import AccountTransactions from '../../account/AccountTransactions';
import useAccountTransactionsFilter from '../../account/AccountTransactions/useAccountTransactionsFilter';
import useAccountTransactionMessage from '../../global-account/useAccountTransactionMessage';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
  onClose: () => void;
}

const WorkspaceAccountTransactions = (props: Props) => {
  const { workspaceId, accountId, onClose } = props;

  const { t } = useTranslation();

  const filter = useAccountTransactionsFilter();

  const { urlParams } = filter;

  const queryKey = [queryKeys.workspaceAccountTransactions({ workspaceId, accountId }), urlParams];

  const { data, loading, loadingMore, checkIsInView } = usePaginatedDataNew({
    queryKey,
    queryFn: (params) =>
      loadWorkspaceAccountTransactions({ workspaceId, accountId }, { ...params, ...urlParams }),
  });

  useAccountTransactionMessage({ domain: 'AccountTransaction', accountId, queryKey, urlParams });

  const commonParams = { workspaceId, accountId };

  return (
    <PageAddon onClose={onClose} title={t('common.transactions')}>
      <AccountTransactions
        data={data}
        filter={filter}
        loading={loading}
        loadingMore={loadingMore}
        checkIsInView={checkIsInView}
        onMediaAttach={async ({ transactionId, files, file_ids }) => {
          const response = await uploadFiles(files);

          attachFilesWorkspaceAccountTransaction({
            ...commonParams,
            transactionId,
            payload: { file_ids: [...file_ids, ...response] },
          });
        }}
        onMediaRemove={async ({ transactionId, file_ids }) =>
          attachFilesWorkspaceAccountTransaction({
            ...commonParams,
            transactionId,
            payload: { file_ids },
          })
        }
        onFinalize={async ({ transactionId, delta, comment }) => {
          finalizeWorkspaceAccountTransaction({
            ...commonParams,
            transactionId,
            payload: { delta, comment },
          });
        }}
      />
    </PageAddon>
  );
};

export default WorkspaceAccountTransactions;
