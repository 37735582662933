import { useTranslation } from 'react-i18next';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageCreateButton from '../../components/PageCreateButton';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { useUserStore } from '../../store/user';
import { useWorkspaceQuery } from './queries';

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceUserInviteButton = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { navigate } = useURL();
  const user = useUserStore();

  const { data } = useWorkspaceQuery(workspaceId);

  const ownerId = data?.owner_id;

  const handleClick = () => navigate(routes.workspaceUserInvite({ workspaceId }));

  if (!ownerId || user.data?.id !== ownerId) return null;

  return <PageCreateButton onClick={handleClick}>{t('common.member')}</PageCreateButton>;
};

export default WorkspaceUserInviteButton;
