import { useTranslation } from 'react-i18next';
import { AgentSchema } from '../../api/schemas/agentSchema';
import PageDetailLink from '../../components/PageDetailLink';
import Tooltip from '../../components/Tooltip';
import routes from '../../config/routes';
import icon from '../../static/icons';

interface Props {
  data: AgentSchema;
}

const GlobalAgentDetailLink = (props: Props) => {
  const { data } = props;

  const { id, name, notes_count } = data;

  const { t } = useTranslation();

  return (
    <PageDetailLink
      label={name}
      to={routes.globalAgent({ agentId: id })}
      extra={
        <>
          {Boolean(notes_count) && <Tooltip label={t('common.notes')}>{icon('notes', 12)}</Tooltip>}
        </>
      }
    />
  );
};

export default GlobalAgentDetailLink;
