import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';

const fileSchema = z.object({
  id: z.string(),
  original_name: z.string(),
  content_type: z.string(),
  is_deleted: z.boolean(),
  created_on: dateTimeSchema,
});

export type FileSchema = z.infer<typeof fileSchema>;

export default fileSchema;
