import api from '../../services/api';
import URLParams from '../../types/URLParams';
import globalClubAnnouncementSchema from '../schemas/globalClubAnnouncementSchema';
import { GlobalClubSchema } from '../schemas/globalClubSchema';
import paginationSchemaNew from '../schemas/paginationSchemaNew';

interface Args {
  clubId: GlobalClubSchema['id'];
}

const loadGlobalClubAnnouncements = async (args: Args, params?: URLParams) => {
  const { clubId } = args;

  const response = await api.get(`/api/reference/club/${clubId}/announcements`, { params });

  return paginationSchemaNew(globalClubAnnouncementSchema).parse(response.data);
};

export default loadGlobalClubAnnouncements;
