import clsx from 'clsx';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import routes from '../../config/routes';
import useBreakpoints from '../../hooks/useBreakpoints';
import useSidebarSection from '../../hooks/useSidebarSection';
import icon from '../../static/icons';
import LogoText from '../../static/logo-text.svg';
import Logo from '../../static/logo.svg';
import { useSidebarStore } from '../../store/sidebar';
import { useUserStore } from '../../store/user';
import SidebarCollapse from './SidebarCollapse';
import SidebarItem from './SidebarItem';
import SidebarPlaceholder from './SidebarPlaceholder';
import SidebarSeparator from './SidebarSeparator';
import SidebarUser from './SidebarUser';
import SidebarWorkspaces from './SidebarWorkspaces';
import styles from './styles.module.scss';

const DECEMBER = 11;

const date = new Date();

const Sidebar = () => {
  const { t } = useTranslation();
  const user = useUserStore();
  const { pathname } = useLocation();
  const { isMobile } = useBreakpoints();
  const { sections, collapsedSections } = useSidebarSection();

  const { open, show, hide } = useSidebarStore((state) => ({
    open: state.open,
    show: state.show,
    hide: state.hide,
  }));

  const ref = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    const el = ref.current;

    if (el) el.scrollTo({ top: el.scrollHeight });
  };

  const collapseActive = collapsedSections.some((item) => pathname.startsWith(item.to));

  return (
    <div className={styles.container}>
      <div
        {...(!isMobile && { onMouseEnter: show, onMouseLeave: hide })}
        className={clsx(styles.wrapper, open && styles.open)}
      >
        <div className={styles.top}>
          <a href={routes.home} className={clsx(styles.logo, open && styles.open)}>
            {date.getMonth() === DECEMBER /* && date.getDate() > 14 */ ? (
              <div className={styles.tree}>
                <div>🎄</div>
                <span>❄️</span>
                <span>❄️</span>
                <span>❄️</span>
              </div>
            ) : (
              <Logo width={32} height={32} />
            )}
            <LogoText width={111} height={20} />
          </a>
        </div>
        <div ref={ref} className={clsx(styles.content, open && styles.open)}>
          <SidebarWorkspaces />
          {!user.data && (
            <SidebarPlaceholder>
              <SidebarSeparator />
            </SidebarPlaceholder>
          )}
          {collapsedSections.length !== 0 && <SidebarSeparator />}
          <ul>
            {sections?.map((section) => (
              <SidebarItem key={section.to} to={section.to} icon={section.icon}>
                {section.title}
              </SidebarItem>
            ))}
            {Boolean(collapsedSections.length) && (
              <SidebarCollapse
                label={t('common.settings')}
                icon={icon('sliders', 24)}
                active={collapseActive}
                onOpen={scrollToBottom}
              >
                {collapsedSections.map((section) => (
                  <SidebarItem key={section.to} to={section.to}>
                    {section.title}
                  </SidebarItem>
                ))}
              </SidebarCollapse>
            )}
            {isMobile && <SidebarUser />}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
