import { ReactNode } from 'react';
import { FieldValues, FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { handleError } from '../../utils/form';
import styles from './styles.module.scss';

interface Props<T extends FieldValues> {
  form: UseFormReturn<T>;
  children: ReactNode;
  className?: string;
  onSubmit?: SubmitHandler<T>;
}

const Form = <T extends FieldValues>(props: Props<T>) => {
  const { form, children, className, onSubmit } = props;

  const { handleSubmit } = form;

  const submitHandler: SubmitHandler<T> = async (values) => {
    try {
      if (onSubmit) await onSubmit(values);
    } catch (error) {
      handleError({ error, form });
    }
  };

  return (
    <FormProvider {...form}>
      <form className={className || styles.form} onSubmit={handleSubmit(submitHandler)}>
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
