import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MarketIdentitySchema } from '../../../api/schemas/marketIdentitySchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import PageAddon from '../../../components/PageAddon';
import Stub from '../../../components/Stub';
import Tooltip from '../../../components/Tooltip';
import CURRENCY from '../../../constants/CURRENCY';
import useRowSelection from '../../../hooks/useRowSelection';
import useWebSocketMessage from '../../../hooks/useWebSocketMessage';
import { isApiError400 } from '../../../services/api';
import icon from '../../../static/icons';
import { useCartStore } from '../../../store/cart';
import { useWorkspaceBalanceQuery } from '../../workspace-settings/WorkspaceSettingsGeneral/queries';
import { getSellerName } from '../helpers';
import { useBuyWorkspaceMarketIdentityMutation } from './mutations';
import styles from './styles.module.scss';
import WorkspaceMarketIdentityCartBalance from './WorkspaceMarketIdentityCartBalance';
import WorkspaceMarketIdentityCartMonthInput from './WorkspaceMarketIdentityCartMonthInput';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
}

const WorkspaceMarketIdentityCart = (props: Props) => {
  const { workspaceId, onClose } = props;

  const { t } = useTranslation();

  const [lastTotal, setLastTotal] = useState(0);

  const buyIdentities = useBuyWorkspaceMarketIdentityMutation();

  const { data } = useWorkspaceBalanceQuery({ workspaceId });

  const { alert, getIdentities, getTotal, getPrice, getBalance, remove, closeAlert } = useCartStore(
    (state) => ({
      alert: state.alert,
      getIdentities: state.getIdentities,
      getTotal: state.getTotal,
      getPrice: state.getPrice,
      getBalance: state.getBalance,
      remove: state.remove,
      closeAlert: state.closeAlert,
    })
  );

  const identities = getIdentities(workspaceId);

  const ids = identities.map((item) => item.data.id);

  const { selected, allSelected, select, unselect, selectAll, unselectAll, checkIsSelected } =
    useRowSelection({ ids });

  const removeSelected = () => {
    unselectAll();
    remove(selected);
  };

  useWebSocketMessage<{ id: MarketIdentitySchema['id'] }>({
    domain: 'Market',
    onMessage: (message) => {
      if (message.action === 'Deleted') unselect(message.payload.id);
    },
  });

  if (buyIdentities.isPending) {
    return (
      <PageAddon.Loading title={t('common.shopping_cart')} description={t('common.processing')} />
    );
  }

  if (buyIdentities.isSuccess) {
    return (
      <PageAddon.Success
        onSuccess={onClose}
        title={t('common.shopping_cart')}
        description={t('sentences.charged_from_balance', { value: lastTotal })}
      />
    );
  }

  if (buyIdentities.isError && isApiError400(buyIdentities.error) && buyIdentities.error.response) {
    return (
      <PageAddon.Error
        onError={onClose}
        title={t('common.shopping_cart')}
        description={buyIdentities.error.response.data.error[0].description}
      />
    );
  }

  const total = getTotal(workspaceId);

  const checkoutIsDisabled = () => {
    if (!data) return true;
    if (getBalance(workspaceId, data.balance) > 0) return true;
    if (identities.some((item) => item.period < item.data.minimal_rental_period)) return true;

    return false;
  };

  const checkout = () => {
    setLastTotal(total);

    buyIdentities.mutate({
      workspaceId,
      payload: identities.map((item) => ({
        identity_id: item.data.id,
        rental_period: item.period,
      })),
    });
  };

  return (
    <PageAddon onClose={onClose} title={t('common.shopping_cart')}>
      {alert && (
        <Alert
          type="warning"
          title={t('sentences.your_funds_are_safe')}
          description={t('sentences.hold_total_amount')}
          className={styles.alert}
          onClose={closeAlert}
        />
      )}
      <WorkspaceMarketIdentityCartBalance workspaceId={workspaceId} />
      {identities.length === 0 && (
        <Stub
          title={t('common.your_cart_is_empty')}
          description={t('sentences.cart_empty_description')}
        />
      )}
      <div className={styles.cards}>
        {identities.length > 0 && (
          <div className={styles.actions}>
            <Checkbox
              indeterminate={!allSelected}
              onChange={selectAll}
              checked={Boolean(selected.length)}
            >
              <div className={styles.selectAll}>{t('common.select_all')}</div>
            </Checkbox>
            {selected.length > 0 && (
              <Button
                icon={icon('trash', 16)}
                size="extra-small"
                variant="transparent"
                onClick={removeSelected}
              />
            )}
          </div>
        )}
        {identities.map((item) => {
          const { id, location } = item.data;

          const handleChange = () => select(id);

          const handleRemove = () => {
            remove(id);
            unselect(id);
          };

          return (
            <div key={id} className={styles.card}>
              <div className={styles.cardTop}>
                <div>
                  <Checkbox onChange={handleChange} checked={checkIsSelected(id)} />
                  <div className={styles.cardInfo}>
                    {getSellerName(item.data)}
                    <div className={styles.cardDot} />
                    <span>{t('common.identity')}</span>
                    <div className={styles.cardDot} />
                    <Tooltip label={location.country_name}>
                      <span>{location.country_iso}</span>
                    </Tooltip>
                  </div>
                </div>
                <Button
                  icon={icon('trash', 16)}
                  size="extra-small"
                  variant="transparent"
                  onClick={handleRemove}
                />
              </div>
              <div className={styles.cardBottom}>
                <WorkspaceMarketIdentityCartMonthInput workspaceId={workspaceId} identity={item} />
                <div className={styles.price}>
                  {CURRENCY.USDT}
                  {getPrice(item)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {identities.length > 0 && (
        <PageAddon.Controls className={styles.controls}>
          <div className={styles.total}>
            <span>{t('common.total')}</span>
            <div>
              {CURRENCY.USDT}
              {total}
            </div>
          </div>
          <Button disabled={checkoutIsDisabled()} onClick={checkout}>
            {t('common.checkout')}
          </Button>
        </PageAddon.Controls>
      )}
    </PageAddon>
  );
};

export default WorkspaceMarketIdentityCart;
