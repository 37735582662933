import clsx from 'clsx';
import { format, isSameYear } from 'date-fns';
import { useDatePickerContext } from './DatePickerContext';
import styles from './styles.module.scss';

const DatePickerYears = () => {
  const { value, years, setYear } = useDatePickerContext();

  return (
    <div className={styles.grid}>
      {years.map((year) => {
        const className = clsx(styles.date, {
          [styles.today]: isSameYear(year, new Date()),
          [styles.selected]: isSameYear(year, new Date(value)),
        });

        const handleClick = () => setYear(year);

        return (
          <button key={String(year)} type="button" className={className} onClick={handleClick}>
            {format(year, 'yyyy')}
          </button>
        );
      })}
    </div>
  );
};

export default DatePickerYears;
