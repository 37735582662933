import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadGlobalUsers } from '../../api';
import { GlobalUserSchema } from '../../api/schemas/globalUserSchema';
import Card from '../../components/Card';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import Tag from '../../components/Tag';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import GlobalUserDetailLink from '../../features/global-user/GlobalUserDetailLink';
import GlobalUserDropdown from '../../features/global-user/GlobalUserDropdown';
import GlobalUserFilter from '../../features/global-user/GlobalUserFilter';
import GlobalUserInviteButton from '../../features/global-user/GlobalUserInviteButton';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedData from '../../hooks/usePaginatedData';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { addWhen } from '../../utils';
import { formatRelativeDate } from '../../utils/date';
import { RouteParams } from '../../utils/generics';
import { can } from '../../utils/permissions';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.globalUser>;

const GlobalUsersPage = () => {
  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { userId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search } = urlParams;

  const queryKey = [queryKeys.globalUsers, urlParams];

  const query = usePaginatedData({
    queryKey,
    queryFn: (params) => loadGlobalUsers({ ...urlParams, ...params }),
  });

  const { data, total, loading, error, checkIsInView } = query;

  const { checkIsCreated, ...meta } = useWebSocketPaginatedActions<GlobalUserSchema>({
    queryKey,
    domain: 'GlobalUser',
    updateAction: ['Edited', 'EmailVerified'],
    select: (item) => item,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  const renderRoles = (roles: GlobalUserSchema['roles']) => {
    if (roles.length) {
      const [first, ...rest] = roles;

      return (
        <Tag count={roles.length} countTooltip={rest.map((item) => item.name).join(', ')}>
          <Tag.FadingText text={first.name} />
        </Tag>
      );
    }

    return '—';
  };

  const renderWorkspaces = (workspaces: GlobalUserSchema['maintenances']) => {
    if (workspaces.length) {
      const [first, ...rest] = workspaces;

      return (
        <Tag count={workspaces.length} countTooltip={rest.map((item) => item.name).join(', ')}>
          <Tag.FadingText text={first.name} />
        </Tag>
      );
    }

    return '—';
  };

  return (
    <Page
      heading={t('common.members')}
      filter={<GlobalUserFilter total={total} />}
      tabs={[
        { to: routes.globalUsers, label: t('common.members'), active: true },
        ...addWhen({ to: routes.globalRoles, label: t('common.roles') }, can.role.read),
      ]}
      extra={
        <PageControls>
          <GlobalUserInviteButton />
        </PageControls>
      }
      addon={<Outlet />}
    >
      <PageTop
        total={total}
        loading={loading}
        {...(isPhone && {
          sorting: [
            { key: 'username', title: t('common.login'), default: true },
            { key: 'created_on', title: t('common.created') },
            { key: 'modified_on', title: t('common.modified') },
          ],
        })}
        meta={meta}
      />
      {(isDesktop || isTablet) && (
        <Table
          data={data}
          search={search}
          {...getTableProps(query)}
          className={styles.table}
          columns={[
            {
              key: 'username',
              title: t('common.login'),
              sorting: true,
              render: ({ values }) => <GlobalUserDetailLink data={values} />,
            },
            {
              title: t('common.roles'),
              render: ({ values }) => renderRoles(values.roles),
            },
            {
              title: t('common.workspaces'),
              render: ({ values }) => renderWorkspaces(values.maintenances),
            },
            {
              render: ({ values }) => <GlobalUserDropdown data={values} />,
              align: 'right',
              controls: true,
            },
          ]}
          renderRow={(row, index) => (
            <Table.Row
              highlighted={checkIsCreated(row.data.id)}
              active={row.data.id === userId}
              {...checkIsInView(index)}
              {...row}
            />
          )}
        />
      )}
      {isPhone && (
        <Card.Group>
          {data.map((item, index) => (
            <Card
              key={item.id}
              highlighted={checkIsCreated(item.id)}
              danger={item.is_deleted}
              {...checkIsInView(index)}
            >
              <Card.Top>
                <GlobalUserDetailLink data={item} />
                <Card.Top.Controls>
                  <GlobalUserDropdown data={item} />
                </Card.Top.Controls>
              </Card.Top>
              <Card.Field.Group>
                <Card.Field label={t('common.roles')}>{renderRoles(item.roles)}</Card.Field>
                <Card.Field label={t('common.workspaces')}>
                  {renderWorkspaces(item.maintenances)}
                </Card.Field>
                <Card.Field label={t('common.created')}>
                  {formatRelativeDate(item.created_on)}
                </Card.Field>
              </Card.Field.Group>
            </Card>
          ))}
        </Card.Group>
      )}
    </Page>
  );
};

export default GlobalUsersPage;
