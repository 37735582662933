import { z } from 'zod';
import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import workspaceDashboardIdentityCountSchema from '../schemas/workspace-dashboard/workspaceDashboardIdentityCountSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceDashboardIdentitiesCount = async (args: Args) => {
  const { workspaceId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/dashboard/identities-by-country`
  );

  return z.array(workspaceDashboardIdentityCountSchema).parse(response.data);
};

export default loadWorkspaceDashboardIdentitiesCount;
