import 'fix-date';
import { useEffect } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import useSessionUpdate from '../../hooks/useSessionUpdate';
import useUser from '../../hooks/useUser';
import { ws } from '../../services/WebSocketService';
import { useSessionStore } from '../../store/session';
import { useTimeZoneStore } from '../../store/timeZone';
import { useUserStore } from '../../store/user';
import Router from '../Router';
import Spinner from '../Spinner';
import styles from './styles.module.scss';

smoothscroll.polyfill();

const App = () => {
  useUser();
  useSessionUpdate();

  const { data } = useUserStore((state) => ({ data: state.data }));
  const { session } = useSessionStore((state) => ({ session: state.session }));
  const { timeZone } = useTimeZoneStore((state) => ({ timeZone: state.timeZone }));

  useEffect(() => {
    if (session) {
      ws.open();
    } else {
      ws.close();
    }
  }, [session]);

  if (session && !data) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  return <Router key={timeZone} />;
};

export default App;
