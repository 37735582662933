import { IdentitySchema } from '../../../api/schemas/identitySchema';
import { MarketIdentitySchema } from '../../../api/schemas/marketIdentitySchema';
import Tooltip from '../../../components/Tooltip';
import { WALLETS_ICONS_16, WALLETS_LABELS } from '../helpers';
import styles from './styles.module.scss';

type Wallet = IdentitySchema['online_wallets'][number]['type'];
type MarketWallet = MarketIdentitySchema['online_wallets'][number];

type Wallets = Wallet[];
type MarketWallets = MarketIdentitySchema['online_wallets'];

interface Props {
  wallets: Wallets | MarketWallets;
}

const WorkspaceIdentityWallets = (props: Props) => {
  const { wallets } = props;

  const uniqWallets = new Set<Wallet | MarketWallet>();

  wallets.forEach((item) => uniqWallets.add(item));

  const walletsTypes = Array.from(uniqWallets);

  const [first, second, ...rest] = walletsTypes;

  const renderWallets = () => {
    if (!walletsTypes.length) return '—';

    return [first, second].map((item) =>
      item ? (
        <Tooltip key={item} label={WALLETS_LABELS[item]}>
          {WALLETS_ICONS_16[item]}
        </Tooltip>
      ) : null
    );
  };

  return (
    <div className={styles.container}>
      {renderWallets()}
      {walletsTypes.length > 2 && (
        <Tooltip label={rest.map((item) => WALLETS_LABELS[item]).join(',')}>
          <div className={styles.count}> + {walletsTypes.length - 2}</div>
        </Tooltip>
      )}
    </div>
  );
};

export default WorkspaceIdentityWallets;
