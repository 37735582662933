import { z } from 'zod';

export const ACCOUNT_STATUS = {
  UNKNOWN: 0,
  IN_GAME: 1,
  SIT_OUT: 2,
  WATCHER: 3,
  IN_WAITLIST: 4,
} as const;

const accountStatusSchema = z.nativeEnum(ACCOUNT_STATUS);

export type AccountStatusSchema = z.infer<typeof accountStatusSchema>;

export default accountStatusSchema;
