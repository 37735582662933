import { useState, useEffect, useCallback } from 'react';
import usePrevious from './usePrevious';
import useURL from './useURL';
import useURLFilter from './useURLFilter';

type SetValue<T> = (field: keyof T) => (value: string | number | boolean) => void;
type ClearValue<T> = (field: keyof T | Array<keyof T>) => () => void;

export type FilterFields<Fields> = Record<keyof Fields, string | string[] | boolean>;

type MappedFields<Fields> = {
  [Property in keyof Fields]: Fields[Property];
};

type Config<Fields> = {
  [Property in keyof Fields]: Fields[Property] extends string
    ? 'single'
    : Fields[Property] extends string[]
      ? 'multi'
      : 'toggle';
};

export interface UseFilterReturn<T> {
  values: MappedFields<T>;
  count: number;
  isResetting: boolean;
  isPending: boolean;
  setValue: SetValue<T>;
  clearValue: ClearValue<T>;
  resetValues: () => void;
}

const useFilter = <Fields extends FilterFields<Fields>>(
  config: Config<Fields>
): UseFilterReturn<Fields> => {
  const { urlParams } = useURL();
  const { isPending, setURLParams } = useURLFilter();

  const [isResetting, setIsResetting] = useState(false);

  const getDefaultValues = useCallback(
    (shouldResetValue = false) => {
      const entries = Object.entries<'single' | 'multi' | 'toggle'>(config).map((item) => {
        const [key, type] = item;

        const value = urlParams[key];

        if (type === 'single') return [key, shouldResetValue ? '' : value];
        if (type === 'toggle') return [key, shouldResetValue ? false : Boolean(value)];
        if (type === 'multi') return [key, shouldResetValue ? [] : value?.split(',') || []];

        return item;
      });

      return Object.fromEntries(entries);
    },
    [config, urlParams]
  );

  const defaultValues = getDefaultValues();

  const [values, setValues] = useState<MappedFields<Fields>>(defaultValues);

  const prevValues = usePrevious(values);

  useEffect(() => {
    if (JSON.stringify(prevValues) !== JSON.stringify(values)) {
      setURLParams(values);
    }
  }, [setURLParams, values, prevValues]);

  useEffect(() => {
    if (isResetting) setIsResetting(false);
  }, [isResetting]);

  const setValue: SetValue<Fields> = (field) => (value) => {
    const oldValue = values[field];

    const isMulti = Array.isArray(oldValue);

    if (typeof value === 'string' && !isMulti) {
      setValues((prev) => ({ ...prev, [field]: value }));
    }

    if (typeof value === 'string' && isMulti) {
      const newValue = oldValue.includes(value)
        ? oldValue.filter((el) => el !== value)
        : [...oldValue, value];

      setValues((prev) => ({ ...prev, [field]: newValue }));
    }

    if (typeof value === 'number' && !isMulti) {
      setValues((prev) => ({ ...prev, [field]: String(value) }));
    }

    if (typeof value === 'boolean' && typeof oldValue === 'boolean') {
      setValues((prev) => ({ ...prev, [field]: value }));
    }
  };

  const clearValue: ClearValue<Fields> = (field) => () => {
    if (Array.isArray(field)) {
      field.forEach((item) => {
        clearValue(item)();
      });
    } else {
      const oldValue = values[field];

      if (typeof oldValue === 'string') {
        setValues((prev) => ({ ...prev, [field]: undefined }));
      }

      if (Array.isArray(oldValue)) {
        setValues((prev) => ({ ...prev, [field]: [] }));
      }
    }
  };

  const resetValues = useCallback(() => {
    setValues(getDefaultValues(true));
    setIsResetting(true);
  }, [getDefaultValues]);

  const prevUrlParams = usePrevious(urlParams);
  const isUrlParamsChanged = JSON.stringify(urlParams) !== JSON.stringify(prevUrlParams);
  const isUrlParamsEmpty = !Object.keys(urlParams).length;

  useEffect(() => {
    if (isUrlParamsChanged && isUrlParamsEmpty) {
      resetValues();
    }
  }, [isUrlParamsChanged, isUrlParamsEmpty, resetValues]);

  const count = Object.values(values).filter((item) => {
    if (Array.isArray(item) && item.length) return true;
    if (typeof item === 'boolean' && item) return true;
    if (typeof item === 'string' && item) return true;

    return false;
  }).length;

  return { values, count, isResetting, isPending, setValue, clearValue, resetValues };
};

export default useFilter;
