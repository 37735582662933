import { format } from 'date-fns';
import { useState, useEffect, useMemo } from 'react';
import * as utils from './helpers';

interface Config {
  value: string;
  open: boolean;
  format: string;
  onChange: (value: string) => void;
}

const useDatePicker = (config: Config) => {
  const { value, open, format: dateFormat, onChange } = config;

  const initialDate = useMemo(() => (value ? new Date(value) : new Date()), [value]);

  const [date, setDate] = useState(initialDate);
  const [picker, setPicker] = useState<'day' | 'month' | 'year'>('day');

  useEffect(() => {
    if (!open) {
      setDate(initialDate);
      setPicker('day');
    }
  }, [open, initialDate]);

  const prevMonth = () => setDate(utils.getPrevMonth);
  const nextMonth = () => setDate(utils.getNextMonth);

  const prevYear = () => setDate(utils.getPrevYear);
  const nextYear = () => setDate(utils.getNextYear);

  const prevDecade = () => setDate(utils.getPrevDecade);
  const nextDecade = () => setDate(utils.getNextDecade);

  const daysOfWeek = utils.getDaysOfWeek(date);
  const days = utils.getDays(date);
  const months = utils.getMonths(date);
  const years = utils.getYears(date);
  const hours = utils.getHours(date);
  const minutes = utils.getMinutes(date);

  const setDay = (day: Date) => {
    onChange(format(day, dateFormat));
    setDate(day);
  };

  const setMonth = (month: Date) => {
    setDate(utils.getDateWithNewMonth(month));
    setPicker('day');
  };

  const setYear = (year: Date) => {
    setDate(utils.getDateWithNewYear(year));
    setPicker('month');
  };

  const setHour = (hour: Date) => {
    const dateWithNewHour = utils.getDateWithNewHour(hour);

    setDate(dateWithNewHour);
    onChange(format(dateWithNewHour, dateFormat));
  };

  const setMinute = (minute: Date) => {
    const dateWithNewMinute = utils.getDateWithNewMinute(minute);

    setDate(dateWithNewMinute);
    onChange(format(dateWithNewMinute, dateFormat));
  };

  const goToPrev = () => {
    if (picker === 'day') return prevMonth();
    if (picker === 'month') return prevYear();
    if (picker === 'year') return prevDecade();

    return undefined;
  };

  const goToNext = () => {
    if (picker === 'day') return nextMonth();
    if (picker === 'month') return nextYear();
    if (picker === 'year') return nextDecade();

    return undefined;
  };

  const clearValue = () => onChange('');

  return {
    date,
    value,
    picker,
    days,
    daysOfWeek,
    months,
    years,
    hours,
    minutes,
    clearValue,
    setPicker,
    setDay,
    setMonth,
    setYear,
    setMinute,
    setHour,
    goToPrev,
    goToNext,
  };
};

export default useDatePicker;
