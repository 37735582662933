import { useState, useCallback, UIEvent } from 'react';

const useScrolled = () => {
  const [scrolled, setScrolled] = useState(false);

  const onScroll = useCallback((event: UIEvent<HTMLDivElement>) => {
    if (event.currentTarget.scrollTop > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }, []);

  return { scrolled, onScroll };
};

export default useScrolled;
