import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadWorkspaceIdentitiesFilterValues } from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Filter from '../../components/Filter';
import queryKeys from '../../config/queryKeys';
import useFilter from '../../hooks/useFilter';
import useURL from '../../hooks/useURL';
import { convertCentsToUSDT, convertUSDTToCents } from '../../utils/data';
import { checkWorkspaceIdentityPokerFarm } from '../../utils/workspace';
import {
  styleIcon,
  WALLETS_LABELS,
  WALLETS_ICONS_16,
  DOCUMENTS_LABELS,
  DOCUMENTS_ICONS_16,
  PHOTOS_LABELS,
} from './helpers';

interface Fields {
  /* min_rental_ge: string;
  min_rental_le: string; */
  monthly_cost_ge: string;
  monthly_cost_le: string;
  country_in: string[];
  online_wallet_in: string[];
  documents_in: string[];
  photos_in: string[];
  has_bank_account: boolean;
  has_phone: boolean;
  on_market_eq: boolean;
  updated_ge: string;
  updated_le: string;
  created_ge: string;
  created_le: string;
  show_deleted: boolean;
}

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceIdentityFilter = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();

  const { urlParams } = useURL();

  const queryKey = [
    queryKeys.workspaceIdentitiesFilterValues({ workspaceId }),
    urlParams.has_bank_account,
    urlParams.has_phone,
    urlParams.on_market_eq,
    urlParams.show_deleted,
  ];
  const queryFn = () =>
    loadWorkspaceIdentitiesFilterValues(
      { workspaceId },
      {
        has_bank_account: urlParams.has_bank_account,
        has_phone: urlParams.has_phone,
        on_market_eq: urlParams.on_market_eq,
        show_deleted: urlParams.show_deleted,
      }
    );

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  const filter = useFilter<Fields>({
    monthly_cost_ge: 'single',
    monthly_cost_le: 'single',
    country_in: 'multi',
    online_wallet_in: 'multi',
    documents_in: 'multi',
    photos_in: 'multi',
    has_bank_account: 'toggle',
    has_phone: 'toggle',
    on_market_eq: 'toggle',
    updated_ge: 'single',
    updated_le: 'single',
    created_ge: 'single',
    created_le: 'single',
    show_deleted: 'toggle',
  });

  const { values, setValue, clearValue } = filter;

  const {
    monthly_cost_ge,
    monthly_cost_le,
    country_in,
    online_wallet_in,
    documents_in,
    photos_in,
    has_bank_account,
    has_phone,
    on_market_eq,
    updated_ge,
    updated_le,
    created_ge,
    created_le,
    show_deleted,
  } = values;

  const filterProps = { filter };

  if (isPending) {
    return (
      <Filter {...filterProps}>
        <Filter.Placeholder />
      </Filter>
    );
  }

  if (!data) return null;

  const { monthly_cost, country, online_wallets, documents, photos } = data;

  const isPokerFarm = checkWorkspaceIdentityPokerFarm(workspaceId);

  return (
    <Filter {...filterProps}>
      <div>
        {!isPokerFarm && (
          <Filter.RangeInput
            label={t('common.cost_month_USDT')}
            step={1}
            decimalScale={6}
            min={monthly_cost.min}
            max={monthly_cost.max}
            valueMin={monthly_cost_ge}
            valueMax={monthly_cost_le}
            fieldMin="monthly_cost_ge"
            fieldMax="monthly_cost_le"
            formatter={convertCentsToUSDT}
            valueFormatter={convertUSDTToCents}
          />
        )}
        {country.length !== 0 && (
          <Filter.Collapse
            label={t('common.country')}
            count={country_in.length}
            onClear={clearValue('country_in')}
          >
            <Filter.MultiCheckbox
              config={country.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('country_in'),
                checked: country_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        {online_wallets.length !== 0 && (
          <Filter.Collapse
            label={t('common.wallet')}
            count={online_wallet_in.length}
            onClear={clearValue('online_wallet_in')}
          >
            <Filter.MultiCheckbox
              config={online_wallets.map((item) => ({
                value: String(item),
                label: WALLETS_LABELS[item],
                onChange: setValue('online_wallet_in'),
                checked: online_wallet_in.includes(String(item)),
                extra: WALLETS_ICONS_16[item],
              }))}
            />
          </Filter.Collapse>
        )}
        {documents.length !== 0 && (
          <Filter.Collapse
            label={t('common.document')}
            count={documents_in.length}
            onClear={clearValue('documents_in')}
          >
            <Filter.MultiCheckbox
              config={documents.map((item) => {
                const icon = DOCUMENTS_ICONS_16[item];

                return {
                  value: String(item),
                  label: DOCUMENTS_LABELS[item],
                  onChange: setValue('documents_in'),
                  checked: documents_in.includes(String(item)),
                  ...(icon && { extra: styleIcon(icon) }),
                };
              })}
            />
          </Filter.Collapse>
        )}
        {photos.length !== 0 && (
          <Filter.Collapse
            label={t('common.selfie')}
            count={photos_in.length}
            onClear={clearValue('photos_in')}
          >
            <Filter.MultiCheckbox
              config={photos.map((item) => ({
                value: String(item),
                label: PHOTOS_LABELS[item],
                onChange: setValue('photos_in'),
                checked: photos_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        <Filter.Collapse
          label={t('common.modified')}
          {...((updated_ge || updated_le) && {
            onClear: clearValue(['updated_ge', 'updated_le']),
          })}
        >
          <Filter.Date
            from={{ value: updated_ge, onChange: setValue('updated_ge') }}
            to={{ value: updated_le, onChange: setValue('updated_le') }}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.created')}
          {...((created_ge || created_le) && {
            onClear: clearValue(['created_ge', 'created_le']),
          })}
        >
          <Filter.Date
            from={{ value: created_ge, onChange: setValue('created_ge') }}
            to={{ value: created_le, onChange: setValue('created_le') }}
          />
        </Filter.Collapse>
      </div>
      <Filter.Switcher checked={has_bank_account} onChange={setValue('has_bank_account')}>
        {t('common.bank_account')}
      </Filter.Switcher>
      <Filter.Switcher checked={has_phone} onChange={setValue('has_phone')}>
        {t('common.has_phone')}
      </Filter.Switcher>
      {!isPokerFarm && (
        <Filter.Switcher checked={on_market_eq} onChange={setValue('on_market_eq')}>
          {t('common.on_market')}
        </Filter.Switcher>
      )}
      <Filter.Switcher checked={show_deleted} onChange={setValue('show_deleted')}>
        {t('common.show_deleted')}
      </Filter.Switcher>
    </Filter>
  );
};

export default WorkspaceIdentityFilter;
