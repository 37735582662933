import { useTranslation } from 'react-i18next';
import { switchOffGlobalAccountPilot, switchOffWorkspaceAccountPilot } from '../../../api';
import { PILOT_STATUS } from '../../../api/schemas/account';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Button, { Props as ButtonProps } from '../../../components/Button';
import Confirm from '../../../components/Confirm';
import Tooltip from '../../../components/Tooltip';
import icon from '../../../static/icons';
import { handleError } from '../../../utils/form';
import notify from '../../../utils/notify';
import { can } from '../../../utils/permissions';
import { CommonAccountSchema } from '../types';
import { useSwitchOnWorkspaceAccountPilotMutation } from './mutations';

interface Props {
  data: CommonAccountSchema;
  workspaceId?: WorkspaceSchema['id'];
  isDetail?: boolean;
}

const AccountPilotButton = (props: Props) => {
  const { data, workspaceId, isDetail = false } = props;

  const { id, pilot_status, stage } = data;

  const { t } = useTranslation();

  const on = pilot_status === PILOT_STATUS.ON;
  const loading = pilot_status === PILOT_STATUS.STARTING || pilot_status === PILOT_STATUS.FINISHING;

  const { mutate, isPending } = useSwitchOnWorkspaceAccountPilotMutation();

  const handleConfirm = async () => {
    try {
      if (workspaceId) {
        await switchOffWorkspaceAccountPilot({ workspaceId, accountId: id });
      }

      if (!workspaceId) {
        await switchOffGlobalAccountPilot({ accountId: id });
      }

      notify('success', { title: t('sentences.pilot_success_stop') });
    } catch (error) {
      handleError({ error });
    }
  };

  const getDisabled = () => {
    if (isPending) return true;
    if (!stage.is_playable) return true;

    if (!workspaceId) {
      if (can.account.switchPilotOff) return !on;

      return true;
    }

    return false;
  };

  const getTooltip = () => {
    if (on) return t('common.stop_pilot');

    if (loading) return null;

    if (workspaceId) return t('common.run_pilot');

    return t('common.you_cant_run_pilot');
  };

  const disabled = getDisabled();

  const buttonProps: ButtonProps = {
    loading,
    size: isDetail ? 'medium' : 'small',
    variant: 'transparent',
    icon: on ? icon('pause', 16) : icon('play', 16),
    onClick: () => {
      if (workspaceId) mutate({ workspaceId, accountId: id });
    },
    disabled,
  };

  return (
    <Tooltip label={getTooltip()} hideOnMobile={!disabled}>
      {on ? (
        <Confirm
          onConfirm={handleConfirm}
          title={t('sentences.stop_a_pilot')}
          confirmText={t('common.stop')}
        >
          {({ confirming }) => <Button focused={confirming} {...buttonProps} />}
        </Confirm>
      ) : (
        <div>
          <Button {...buttonProps} />
        </div>
      )}
    </Tooltip>
  );
};

export default AccountPilotButton;
