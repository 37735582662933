import { useState, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';

interface Props {
  children: ReactNode;
}

const ShowButton = (props: Props) => {
  const { children } = props;

  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const handleClick = () => setVisible((prev) => !prev);

  return (
    <>
      {visible ? (
        children
      ) : (
        <Button variant="light" size="small" onClick={handleClick}>
          {t('common.show')}
        </Button>
      )}
    </>
  );
};

export default ShowButton;
