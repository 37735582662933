import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import WorkspaceMarketIdentityCart from '../../features/workspace-market-identity/WorkspaceMarketIdentityCart';
import WorkspaceMarketIdentityDetail from '../../features/workspace-market-identity/WorkspaceMarketIdentityDetail';
import WorkspaceMarketIdentityLoader from '../../features/workspace-market-identity/WorkspaceMarketIdentityLoader';
import WorkspaceMarketIdentityPage from '../../pages/WorkspaceMarketIdentityPage';
import Route from './Route';

const page = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceMarketIdentityPage workspaceId={workspaceId} />}
  </Route>
);

const cart = (
  <Route params={['workspaceId']}>
    {({ workspaceId, navigate }) => (
      <WorkspaceMarketIdentityCart
        workspaceId={workspaceId}
        onClose={() => navigate(routes.workspaceMarketIdentities({ workspaceId }))}
      />
    )}
  </Route>
);

const detail = (
  <Route params={['workspaceId', 'identityId']}>
    {({ workspaceId, identityId, navigate }) => (
      <WorkspaceMarketIdentityLoader workspaceId={workspaceId} identityId={identityId}>
        {({ data }) => (
          <WorkspaceMarketIdentityDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceMarketIdentities({ workspaceId }))}
          />
        )}
      </WorkspaceMarketIdentityLoader>
    )}
  </Route>
);

const detailCart = (
  <Route params={['workspaceId', 'identityId']}>
    {({ workspaceId, identityId, navigate }) => (
      <WorkspaceMarketIdentityCart
        workspaceId={workspaceId}
        onClose={() => navigate(routes.workspaceMarketIdentity({ workspaceId, identityId }))}
      />
    )}
  </Route>
);

const workspaceMarketIdentityPage: RouteObject = {
  path: routes.workspaceMarketIdentities({ workspaceId: ':workspaceId' }),
  element: page,
  children: [
    {
      path: routes.workspaceMarketIdentitiesCart({ workspaceId: ':workspaceId' }),
      element: cart,
    },
    {
      path: routes.workspaceMarketIdentity({
        workspaceId: ':workspaceId',
        identityId: ':identityId',
      }),
      element: detail,
    },
    {
      path: routes.workspaceMarketIdentityCart({
        workspaceId: ':workspaceId',
        identityId: ':identityId',
      }),
      element: detailCart,
    },
  ],
};

export default workspaceMarketIdentityPage;
