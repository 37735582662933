import usePaginatedData from '../../hooks/usePaginatedData';
import usePaginatedDataNew from '../../hooks/usePaginatedDataNew';
import Id from '../../types/Id';
import { Props } from './types';

const getTableProps = <T extends Id>(
  queryReturn: ReturnType<typeof usePaginatedData> | ReturnType<typeof usePaginatedDataNew>
): Pick<Props<T>, 'loading' | 'loadingMore' | 'allLoaded'> => {
  const { loading, loadingMore, hasNextPage } = queryReturn;

  return { loading, loadingMore, allLoaded: !hasNextPage };
};

export default getTableProps;
