import { isAfter, addMinutes } from 'date-fns';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { MarketIdentitySchema } from '../api/schemas/marketIdentitySchema';
import { WorkspaceSchema } from '../api/schemas/workspaceSchema';
import LOCALSTORAGE from '../constants/LOCALSTORAGE';
import { ws } from '../services/WebSocketService';
import { convertCentsToUSDT } from '../utils/data';

type Schema = MarketIdentitySchema;
type WorkspaceId = WorkspaceSchema['id'];

export type Identity = { data: Schema; period: number; date: string };
type Identities = Record<WorkspaceId, Identity[]>;

export const MAX_PERIOD = 12;

interface State {
  identities: Identities;
  alert: boolean;
  setIdentities: (payload: Identities) => void;
  getIdentities: (workspaceId: WorkspaceId) => Identity[];
  getTotal: (workspaceId: WorkspaceId) => number;
  getPrice: (payload: Identity) => number;
  add: (workspaceId: WorkspaceId, payload: Schema) => void;
  remove: (payload: Schema['id'] | Schema['id'][]) => void;
  increment: (workspaceId: WorkspaceId, payload: Schema) => void;
  decrement: (workspaceId: WorkspaceId, payload: Schema, min: number) => void;
  change: (workspaceId: WorkspaceId, payload: Schema, value: number) => void;
  check: () => void;
  getBalance: (workspaceId: WorkspaceId, balance: number) => number;
  closeAlert: () => void;
}

const useCartStore = create(
  persist<State>(
    (set, get) => ({
      identities: {},
      alert: true,
      setIdentities: (payload) => set({ identities: payload }),
      getIdentities: (workspaceId) => {
        const identities = get().identities[workspaceId] || [];

        return identities;
      },
      getTotal: (workspaceId) => {
        const total = get()
          .getIdentities(workspaceId)
          .reduce((acc, curr) => acc + curr.period * curr.data.monthly_cost, 0);

        return convertCentsToUSDT(total);
      },
      getPrice: (payload) => convertCentsToUSDT(payload.data.monthly_cost * payload.period),
      add: (workspaceId, payload) =>
        set((state) => ({
          identities: {
            ...state.identities,
            [workspaceId]: [
              ...state.getIdentities(workspaceId),
              {
                data: payload,
                period: payload.minimal_rental_period,
                date: new Date().toISOString(),
              },
            ],
          },
        })),
      remove: (payload) =>
        set((state) => {
          const entries = Object.entries(state.identities).map((item) => {
            const [workspaceId, identities] = item;

            return [
              workspaceId,
              identities.filter((identity) => {
                if (Array.isArray(payload)) return !payload.includes(identity.data.id);

                return identity.data.id !== payload;
              }),
            ];
          });

          return { identities: Object.fromEntries(entries) };
        }),
      increment: (workspaceId, payload) =>
        set((state) => ({
          identities: {
            ...state.identities,
            [workspaceId]: state
              .getIdentities(workspaceId)
              .map((item) =>
                item.data.id === payload.id
                  ? { ...item, period: item.period < MAX_PERIOD ? item.period + 1 : item.period }
                  : item
              ),
          },
        })),
      decrement: (workspaceId, payload, min) =>
        set((state) => ({
          identities: {
            ...state.identities,
            [workspaceId]: state.getIdentities(workspaceId).map((item) => {
              const period = item.period - 1;

              return item.data.id === payload.id
                ? { ...item, period: period < min ? min : period }
                : item;
            }),
          },
        })),
      change: (workspaceId, payload, value) =>
        set((state) => ({
          identities: {
            ...state.identities,
            [workspaceId]: state
              .getIdentities(workspaceId)
              .map((item) =>
                item.data.id === payload.id
                  ? { ...item, period: value < MAX_PERIOD ? value : MAX_PERIOD }
                  : item
              ),
          },
        })),
      check: () =>
        set((state) => {
          const entries = Object.entries(state.identities).map((item) => {
            const [workspaceId, identities] = item;

            return [
              workspaceId,
              identities.filter((identity) =>
                isAfter(addMinutes(new Date(identity.date), 30), new Date())
              ),
            ];
          });

          return { identities: Object.fromEntries(entries) };
        }),
      getBalance: (workspaceId, balance) => {
        const total = get().getTotal(workspaceId);

        const diff = convertCentsToUSDT(balance) - total;

        return diff < 0 ? Number(Math.abs(diff).toFixed(6)) : 0;
      },
      closeAlert: () => set({ alert: false }),
    }),
    { name: LOCALSTORAGE.CART }
  )
);

window.addEventListener('storage', (event) => {
  if (event.key === LOCALSTORAGE.CART) {
    useCartStore.persist.rehydrate();
  }
});

setInterval(() => useCartStore.getState().check(), 1000); // TODO: сделать проверку в check() чтобы не было рендера

ws.subscribe<{ id: Schema['id'] }>((message) => {
  const { domain, action, payload } = message;

  if (domain === 'Market' && action === 'Deleted') {
    useCartStore.getState().remove(payload.id);
  }
});

export { useCartStore };
