import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';

export const STATUS = {
  NOTHING: 0,
  GOOD_TABLE: 1,
  BAD_TABLE: 2,
  RESERVED: 3,
  BANNED: 4,
} as const;

const accountTablesSearchSchema = z.object({
  id: z.string(),
  sb: z.number(),
  bb: z.number(),
  name: z.string(),
  updated_on: dateTimeSchema,
  max_no_of_seats: z.number(),
  player_count: z.number().or(z.null()),
  club_table: z.boolean(),
  lifetime: z.boolean(),
  color: z.number().or(z.null()),
  color_updated_on: dateTimeSchema.or(z.null()),
  status: z.nativeEnum(STATUS).or(z.null()),
  status_updated_on: dateTimeSchema.or(z.null()),
});

export type AccountTablesSearchSchema = z.infer<typeof accountTablesSearchSchema>;

export default accountTablesSearchSchema;
