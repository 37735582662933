import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import globalClubSchema from './globalClubSchema';

const globalClubAnnouncementSchema = z.object({
  id: z.string(),
  club_id: globalClubSchema.shape.id,
  message: z.string(),
  type: z.string().or(z.null()),
  identity: z.string().or(z.null()),
  is_active: z.boolean(),
  deactivated: dateTimeSchema.or(z.null()),
  created_on: dateTimeSchema,
});

export type GlobalClubAnnouncementSchema = z.infer<typeof globalClubAnnouncementSchema>;

export default globalClubAnnouncementSchema;
