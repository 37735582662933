import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import paginationSchemaNew from '../schemas/paginationSchemaNew';
import { WorkspaceSchema } from '../schemas/workspaceSchema';
import workspaceUserSchema from '../schemas/workspaceUserSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceUsers = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(`/api/v2/${ALIASES.WORKSPACE.L}/${workspaceId}/users`, {
    params,
  });

  return paginationSchemaNew(workspaceUserSchema).parse(response.data);
};

export default loadWorkspaceUsers;
