import { z } from 'zod';
import botSettingSchema from './botSettingSchema';
import dateTimeSchema from './dateTimeSchema';
import trainerGameTypeSchema from './trainerGameTypeSchema';
import trainerPokerRoomSchema from './trainerPokerRoomSchema';
import workspaceSchema from './workspaceSchema';

const botSettingRuleWorkspaceSchema = workspaceSchema.pick({ id: true, name: true });

const botSettingRuleSchema = z.object({
  id: z.string(),
  name: z.string(),
  bot_settings: botSettingSchema,
  description: z.string(),
  game_type: trainerGameTypeSchema,
  poker_room: trainerPokerRoomSchema.or(z.null()),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
  maintenance: botSettingRuleWorkspaceSchema.or(z.null()),
  is_deleted: z.boolean(),
});

export const botSettingRuleFilterValuesSchema = z.object({
  maintenance: z.array(botSettingRuleWorkspaceSchema),
  game_type: z.array(botSettingRuleSchema.shape.game_type),
  poker_room: z.array(trainerPokerRoomSchema),
});

export type BotSettingRuleSchema = z.infer<typeof botSettingRuleSchema>;

export default botSettingRuleSchema;
