import { CREDIT, PAYMENT_SYSTEM, PROTECTION, ROLE, STATUS } from '../../api/schemas/deal';
import { GlobalDealSchema } from '../../api/schemas/deal/globalDealSchema';
import { GlobalBonusSchema } from '../../api/schemas/globalBonusSchema';
import { GlobalExpenseSchema } from '../../api/schemas/globalExpenseSchema';
import Tag from '../../components/Tag';
import { CURRENCY_LABELS } from '../../utils/currency';
import { convertCentsToDollars } from '../../utils/data';
import {
  CREDIT_LABELS,
  PAYMENT_SYSTEM_LABELS,
  PROTECTION_LABELS,
  ROLE_LABELS,
  STATUS_LABELS,
} from '../deal/helpers';

export const roleOptions = Object.values(ROLE)
  .filter((item) => item !== STATUS.UNKNOWN)
  .map((item) => ({
    value: item,
    label: ROLE_LABELS[item],
  }));

export const statusOptions = Object.values(STATUS)
  .filter((item) => item !== STATUS.UNKNOWN)
  .map((item) => ({
    value: item,
    label: STATUS_LABELS[item],
  }));

export const creditOptions = Object.values(CREDIT)
  .filter((item) => item !== STATUS.UNKNOWN)
  .map((item) => ({
    value: item,
    label: CREDIT_LABELS[item],
  }));

export const protectionOptions = Object.values(PROTECTION)
  .filter((item) => item !== STATUS.UNKNOWN)
  .map((item) => ({
    value: item,
    label: PROTECTION_LABELS[item],
  }));

export const paymentSystemOptions = Object.values(PAYMENT_SYSTEM)
  .filter((item) => item !== STATUS.UNKNOWN)
  .map((item) => ({
    value: item,
    label: PAYMENT_SYSTEM_LABELS[item],
  }));

export const renderWorkspaces = (data: GlobalDealSchema) => {
  const { workspaces } = data;

  if (workspaces.length) {
    return (
      <Tag.Group
        tooltip={(index) =>
          workspaces
            .slice(index)
            .map((item) => item.name)
            .join(', ')
        }
      >
        {workspaces.map((item) => (
          <Tag key={item.id}>{item.name}</Tag>
        ))}
      </Tag.Group>
    );
  }

  return '—';
};

export const getAmount = (values: GlobalBonusSchema | GlobalExpenseSchema) =>
  `${convertCentsToDollars(values.amount)} ${CURRENCY_LABELS[values.currency]}`;
