import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';
import { WorkspaceTagSchema } from '../schemas/workspaceTagSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  tagId: WorkspaceTagSchema['id'];
}

const deleteWorkspaceTag = async (args: Args) => {
  const { workspaceId, tagId } = args;

  const response = await api.delete(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/tag/${tagId}`);

  return emptyResponseSchema.parse(response.data);
};

export default deleteWorkspaceTag;
