import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';
import updateWorkspaceStrategyProfile from './updateWorkspaceAccountStrategyProfile';

type Payload = {
  account_ids: WorkspaceAccountSchema['id'][];
  to_edit: Parameters<typeof updateWorkspaceStrategyProfile>[0]['payload'];
};

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
  params?: URLParams;
}

const bulkUpdateFilteredWorkspaceAccountsStrategyProfile = async (args: Args) => {
  const { workspaceId, payload, params } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/strategy-profile/bulk-edit-filtered`,
    payload,
    { params }
  );

  return emptyResponseSchema.parse(response.data);
};

export default bulkUpdateFilteredWorkspaceAccountsStrategyProfile;
