import { useEffect, useState } from 'react';
import ReactQueryBuilder, { RuleGroupType } from 'react-querybuilder';
import usePrevious from '../../hooks/usePrevious';
import Error from '../Error';
import { fields, operators } from './helpers';
import QueryBuilderAddGroupAction from './QueryBuilderAddGroupAction';
import QueryBuilderAddRuleAction from './QueryBuilderAddRuleAction';
import QueryBuilderCombinatorSelector from './QueryBuilderCombinatorSelector';
import QueryBuilderFieldSelector from './QueryBuilderFieldSelector';
import QueryBuilderOperatorSelector from './QueryBuilderOperatorSelector';
import QueryBuilderRemoveGroupAction from './QueryBuilderRemoveGroupAction';
import QueryBuilderRemoveRuleAction from './QueryBuilderRemoveRuleAction';
import QueryBuilderValueEditor from './QueryBuilderValueEditor';
import styles from './styles.module.scss';

interface Props {
  value: RuleGroupType;
  onChange: (value: RuleGroupType) => void;
  error?: string;
}

const QueryBuilder = (props: Props) => {
  const { value, error, onChange } = props;

  const [query, setQuery] = useState(value);

  const prevQuery = usePrevious(query);

  useEffect(() => {
    if (JSON.stringify(query) !== JSON.stringify(prevQuery)) {
      onChange(query);
    }
  }, [query, prevQuery, onChange]);

  return (
    <div className={styles.container}>
      <ReactQueryBuilder
        enableDragAndDrop={false}
        query={query}
        fields={fields}
        operators={operators}
        onQueryChange={setQuery}
        resetOnFieldChange
        resetOnOperatorChange
        controlClassnames={{
          queryBuilder: styles.queryBuilder,
          ruleGroup: styles.ruleGroup,
          header: styles.header,
          rule: styles.rule,
        }}
        controlElements={{
          combinatorSelector: QueryBuilderCombinatorSelector,
          fieldSelector: QueryBuilderFieldSelector,
          operatorSelector: QueryBuilderOperatorSelector,
          valueEditor: QueryBuilderValueEditor,
          addRuleAction: QueryBuilderAddRuleAction,
          addGroupAction: QueryBuilderAddGroupAction,
          removeRuleAction: QueryBuilderRemoveRuleAction,
          removeGroupAction: QueryBuilderRemoveGroupAction,
        }}
        getRuleGroupClassname={(ruleGroup: RuleGroupType) =>
          ruleGroup.combinator === 'and' ? styles.and : styles.or
        }
      />
      <Error error={error} />
    </div>
  );
};

export default QueryBuilder;
