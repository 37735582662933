import { useState } from 'react';
import ColorPicker from '../../../components/ColorPicker';
import COLORS from '../../../constants/COLORS';
import styles from './styles.module.scss';

const StorybookPageColorPicker = () => {
  const [color, setColor] = useState(COLORS.BLUE_100);

  return (
    <>
      <h2>Color picker</h2>
      <div className={styles.colorPicker}>
        <ColorPicker defaultValue={color} onChange={setColor} />
      </div>
    </>
  );
};

export default StorybookPageColorPicker;
