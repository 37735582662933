import { WorkspaceClubTransactionSchema } from '../../../api/schemas/workspaceClubTransactionSchema';
import FadingText from '../../../components/FadingText';
import Link from '../../../components/Link';
import routes from '../../../config/routes';
import styles from './styles.module.scss';

interface Props {
  data: WorkspaceClubTransactionSchema;
  caption?: boolean;
}

const WorkspaceClubTransactionPID = (props: Props) => {
  const { data, caption = true } = props;

  const { id, member } = data;

  const { id: memberId, player_id, nickname, maintenance } = member;

  return (
    <div className={styles.container}>
      <Link
        to={routes.workspaceClubTransactionMember({
          workspaceId: maintenance.id,
          transactionId: id,
          memberId,
        })}
      >
        <FadingText text={player_id} />
      </Link>
      {caption && <div className={styles.caption}>{nickname}</div>}
    </div>
  );
};

export default WorkspaceClubTransactionPID;
