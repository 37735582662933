import { z } from 'zod';
import { identityMinimalRentalPeriodSchema, identityMonthlyCostSchema } from './identity';
import identitySchema from './identitySchema';

const marketIdentityOwnerSchema = z.object({
  id: z.string(),
  index: z.string(),
  name: z.string(),
});

const marketIdentitySchema = z.object({
  id: identitySchema.shape.id,
  owner: marketIdentityOwnerSchema,
  gender: identitySchema.shape.gender,
  date_of_birth: identitySchema.shape.date_of_birth,
  location: identitySchema.shape.location,
  minimal_rental_period: identityMinimalRentalPeriodSchema,
  monthly_cost: identityMonthlyCostSchema,
  online_wallets: z.array(identitySchema.shape.online_wallets.element.shape.type),
  rooms: identitySchema.shape.rooms,
  channel: identitySchema.shape.channel,
  photos: z.array(identitySchema.shape.photos.element.shape.type),
  documents: z.array(identitySchema.shape.documents.element.shape.type),
  phones: z.boolean(),
  bank_accounts: z.boolean(),
  on_market: identitySchema.shape.on_market,
  is_deleted: identitySchema.shape.is_deleted,
  created_on: identitySchema.shape.created_on,
  modified_on: identitySchema.shape.modified_on,
});

export const marketIdentityFilterValuesSchema = z.object({
  country: z.array(
    z.object({
      id: marketIdentitySchema.shape.location.shape.country_name,
      name: marketIdentitySchema.shape.location.shape.country_name,
    })
  ),
  minimal_rental_period: z.object({ min: z.number(), max: z.number() }),
  monthly_cost: z.object({ min: z.number(), max: z.number() }),
  online_wallets: z.array(
    z.object({
      id: marketIdentitySchema.shape.online_wallets.element,
      name: marketIdentitySchema.shape.online_wallets.element,
    })
  ),
  documents: z.array(
    z.object({
      id: marketIdentitySchema.shape.documents.element,
      name: marketIdentitySchema.shape.documents.element,
    })
  ),
  photos: z.array(
    z.object({
      id: marketIdentitySchema.shape.photos.element,
      name: marketIdentitySchema.shape.photos.element,
    })
  ),
});

export type MarketIdentitySchema = z.infer<typeof marketIdentitySchema>;

export default marketIdentitySchema;
