import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import useURL, { Params } from './useURL';

interface Config {
  debounced?: boolean;
}

const useURLFilter = (config: Config = {}) => {
  const { debounced: isDebounced = true } = config;

  const [isPending, setPending] = useState(false);
  const { setURLParams } = useURL();

  const debounced = useDebouncedCallback((params: Params) => setURLParams(params), 1000);

  const debouncedIsPending = debounced.isPending();

  const handleChange = (params: Params) => {
    if (isDebounced) {
      debounced(params);
      setPending(debounced.isPending());
    } else {
      setURLParams(params);
    }
  };

  useEffect(() => {
    setPending(debouncedIsPending);
  }, [debouncedIsPending]);

  useEffect(() => () => debounced.flush(), [debounced]);

  return { isPending, setURLParams: handleChange };
};

export default useURLFilter;
