import api from '../../services/api';
import { CurrentUserPasswordSchema, CurrentUserSchema } from '../schemas/currentUserSchema';
import currentUserSessionSchema from '../schemas/currentUserSessionSchema';

interface Payload {
  username: CurrentUserSchema['username'];
  password: CurrentUserPasswordSchema;
}

interface Args {
  payload: Payload;
}

const loginUser = async (args: Args) => {
  const { payload } = args;

  const response = await api.post('/api/login', payload);

  return currentUserSessionSchema.parse(response.data);
};

export default loginUser;
