import api from '../../services/api';
import lobbySchema, { LobbySchema } from '../schemas/lobbySchema';

interface Args {
  tableId: LobbySchema['table']['id'];
}

const loadGlobalLobbyTable = async (args: Args) => {
  const { tableId } = args;

  const response = await api.get(`/api/lobby/room/${tableId}`);

  return lobbySchema.parse(response.data);
};

export default loadGlobalLobbyTable;
