import { useTranslation } from 'react-i18next';
import { entries } from 'remeda';
import { updateGlobalRole } from '../../api';
import { GlobalRoleSchema } from '../../api/schemas/globalRoleSchema';
import PageAddon from '../../components/PageAddon';
import Select, { Options } from '../../components/Select';
import useQuickForm from '../../hooks/useQuickForm';
import icon from '../../static/icons';
import { formatAbsoluteDate } from '../../utils/date';
import { handleError } from '../../utils/form';
import { can } from '../../utils/permissions';
import GlobalRoleDetailDropdown from './GlobalRoleDetailDropdown';
import { permissionsGroups } from './helpers';

type GroupKey = keyof typeof permissionsGroups;

type Fields = {
  [Key in GroupKey]: Options<(typeof permissionsGroups)[GroupKey]['permissions'][number]>;
};

interface Props {
  data: GlobalRoleSchema;
  onClose: () => void;
}

const GlobalRoleDetail = (props: Props) => {
  const { data, onClose } = props;

  const { id, name, permissions, is_deleted, created_on, modified_on } = data;

  const { t } = useTranslation();

  const disabled = !can.role.createUpdate;

  const form = useQuickForm<Fields>({
    data,
    defaultValues: Object.fromEntries(
      Object.entries(permissionsGroups).map((item) => {
        const [key, value] = item;

        return [
          key,
          value.permissions
            .filter((el) => permissions.includes(el))
            .map((el) => ({
              value: el,
              label: t(`permissions.${el}`),
            })),
        ];
      })
    ),
  });

  const { control, handleSubmit } = form;

  const onSubmit = async (values: Fields) => {
    const payload = {
      permissions: Object.values(values)
        .flat()
        .map((item) => item.value),
    };

    try {
      await updateGlobalRole({ roleId: data.id, payload });
    } catch (error) {
      handleError({ error, form });
    }
  };

  const commonProps = { control, disabled, onSubmit: handleSubmit(onSubmit) };

  const titleIcons = is_deleted && icon('trash', 12);

  return (
    <PageAddon copy onClose={onClose} title={name} icons={titleIcons}>
      <PageAddon.Fields>
        <PageAddon.Id id={id} />
        {entries(permissionsGroups).map((item) => {
          const [key, value] = item;

          return (
            <PageAddon.Field key={key} label={value.label}>
              <Select.Multi.Quick
                selectAll
                name={key}
                options={value.permissions.map((el) => ({
                  value: el,
                  label: t(`permissions.${el}`),
                }))}
                {...commonProps}
              />
            </PageAddon.Field>
          );
        })}
        <PageAddon.Field label={t('common.modified')}>
          <PageAddon.Copy>{formatAbsoluteDate(modified_on)}</PageAddon.Copy>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.created')}>
          <PageAddon.Copy>{formatAbsoluteDate(created_on)}</PageAddon.Copy>
        </PageAddon.Field>
      </PageAddon.Fields>
      {!is_deleted && (
        <PageAddon.Controls>
          <PageAddon.Controls.Group>
            <GlobalRoleDetailDropdown data={data} />
          </PageAddon.Controls.Group>
        </PageAddon.Controls>
      )}
    </PageAddon>
  );
};

export default GlobalRoleDetail;
