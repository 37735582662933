import { z } from 'zod';

const accountFingerprintValidationSchema = z.object({
  validation_errors: z.array(z.string()),
  mac_address_assigned_to: z.string(),
  cpu_id_assigned_to: z.array(z.string()),
});

export type AccountFingerprintValidationSchema = z.infer<typeof accountFingerprintValidationSchema>;

export default accountFingerprintValidationSchema;
