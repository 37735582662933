import { format, subYears } from 'date-fns';
import icon from '../../../static/icons';
import Button from '../../Button';
import { useDatePickerContext } from '../DatePickerContext';
import styles from './styles.module.scss';

const DatePickerNavigation = () => {
  const { date, picker, goToPrev, goToNext, setPicker } = useDatePickerContext();

  const getTitle = () => {
    if (picker === 'day') return format(date, 'MMM yyyy');
    if (picker === 'month') return format(date, 'yyyy');

    if (picker === 'year') {
      const start = format(subYears(date, 11), 'yyyy');
      const end = format(date, 'yyyy');

      return `${start} — ${end}`;
    }

    return '';
  };

  const handleClick = () => {
    if (picker === 'day') return setPicker('month');
    if (picker === 'month') return setPicker('year');

    return undefined;
  };

  return (
    <div className={styles.container}>
      <Button variant="transparent" icon={icon('chevronLeft', 16)} onClick={goToPrev} />
      <Button
        rtl
        variant="transparent"
        onClick={handleClick}
        {...(picker !== 'year' && { icon: icon('dualArrow', 16) })}
        {...(picker === 'year' && { className: styles.button })}
      >
        {getTitle()}
      </Button>
      <Button variant="transparent" icon={icon('chevronRight', 16)} onClick={goToNext} />
    </div>
  );
};

export default DatePickerNavigation;
