import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadNotificationsSettings } from '../../../api';
import { NotificationsSettingSchema } from '../../../api/schemas/notificationsSettingSchema';
import ConfirmDialog from '../../ConfirmDialog';
import Select, { Option } from '../../Select';
import NotificationsSettingsDialogFormActionTypes from './NotificationsSettingsDialogFormActionTypes';
import styles from './styles.module.scss';
import { ConfirmClose, Dirty, Workspace } from './types';

interface Props {
  defaultWorkspace: NotificationsSettingSchema['workspace'];
  setConfirmClose: Dispatch<SetStateAction<ConfirmClose>>;
}

const NotificationsSettingsDialogForm = (props: Props) => {
  const { defaultWorkspace, setConfirmClose } = props;

  const { t } = useTranslation();

  const [workspace, setWorkspace] = useState<Workspace>({
    value: defaultWorkspace.id,
    label: defaultWorkspace.name,
  });

  const [confirming, setConfirming] = useState<Option | null>(null);
  const [dirty, setDirty] = useState<Dirty>(false);

  useEffect(() => {
    setConfirmClose(dirty);
  }, [dirty, setConfirmClose]);

  return (
    <>
      {confirming && (
        <ConfirmDialog
          danger
          title={t('sentences.changes_are_not_saved')}
          description={t('sentences.switch_workspace_without_saving')}
          confirmText={t('common.switch')}
          onConfirm={() => {
            setWorkspace(confirming);
            setConfirming(null);
          }}
          onClose={() => setConfirming(null)}
        />
      )}
      <Select.AsyncV2
        onLoad={(args) => loadNotificationsSettings(args)}
        select={(item) => ({
          value: item.workspace.id,
          label: item.workspace.name,
        })}
      >
        {({ options, ...rest }) => (
          <Select
            clearable={false}
            size="small"
            value={workspace}
            onChange={(value) => {
              if (value) {
                if (dirty) {
                  setConfirming(value);
                } else {
                  setWorkspace(value);
                }
              }
            }}
            options={options}
            className={styles.select}
            {...rest}
          />
        )}
      </Select.AsyncV2>
      <NotificationsSettingsDialogFormActionTypes workspace={workspace} setDirty={setDirty} />
    </>
  );
};

export default NotificationsSettingsDialogForm;
