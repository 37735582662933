import { z } from 'zod';
import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceSchema } from '../schemas/workspaceSchema';
import workspaceUserSchema, { WorkspaceUserSchema } from '../schemas/workspaceUserSchema';

type Schema = WorkspaceUserSchema;

type Payload = { email: Schema['username'] }[];

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const inviteWorkspaceUsers = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.post(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/invite`, payload);

  return z.array(workspaceUserSchema.pick({ id: true })).parse(response.data);
};

export default inviteWorkspaceUsers;
