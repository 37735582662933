import { matchSorter } from 'match-sorter';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePrevious from '../../../hooks/usePrevious';
import SearchInput from '../../SearchInput';
import { useFilterContext } from '../FilterContext';
import FilterCheckbox from './FilterCheckbox';
import styles from './styles.module.scss';

interface Config {
  value: string;
  label: string;
  checked: boolean;
  onChange: (value: string) => void;
  addon?: React.ReactNode;
  extra?: React.ReactNode;
}

interface Props {
  config: Config[];
  searchKeys?: string[];
}

const FilterMultiCheckbox = (props: Props) => {
  const { config, searchKeys = [] } = props;

  const { t } = useTranslation();
  const { isResetting } = useFilterContext();

  const [search, setSearch] = useState('');

  const clearSearch = useCallback(() => setSearch(''), [setSearch]);

  const filteredConfig = search
    ? matchSorter(config, search, { keys: ['label', ...searchKeys] })
    : config;

  const prevConfig = usePrevious(config);

  const checkedCount = config.filter((item) => item.checked).length;
  const prevCheckedCount = prevConfig.filter((item) => item.checked).length;

  useEffect(() => {
    if (checkedCount !== prevCheckedCount && checkedCount === 0) {
      clearSearch();
    }
  }, [checkedCount, prevCheckedCount, clearSearch]);

  useEffect(() => {
    if (isResetting) clearSearch();
  }, [isResetting, clearSearch]);

  return (
    <div className={styles.container}>
      {config.length > 10 && (
        <SearchInput value={search} onChange={setSearch} className={styles.search} />
      )}
      {!filteredConfig.length && (
        <div className={styles.emptyResult}>{t('common.no_matching_results')}</div>
      )}
      <div className={styles.checkboxes}>
        {filteredConfig.map((item) => {
          const { value, label, checked, onChange, addon, extra } = item;

          return (
            <div key={value} className={styles.checkbox}>
              <FilterCheckbox
                addon={addon}
                checked={checked}
                onChange={() => onChange(value)}
                extra={extra}
              >
                {label}
              </FilterCheckbox>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FilterMultiCheckbox;
