import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceClubMemberSchema } from '../schemas/workspaceClubMemberSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = WorkspaceClubMemberSchema;

interface Args {
  workspaceId: WorkspaceSchema['id'];
  memberId: Schema['id'];
}

const kickWorkspaceClubMember = async (args: Args) => {
  const { workspaceId, memberId } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/club-manager/members/${memberId}/kick`
  );

  return emptyResponseSchema.parse(response.data);
};

export default kickWorkspaceClubMember;
