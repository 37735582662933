import clsx from 'clsx';
import { ReactNode } from 'react';
import EXTENSIONS from '../../constants/EXTENSIONS';
import CSVIcon from '../../static/files/csv.svg';
import DOCIcon from '../../static/files/doc.svg';
import FileIcon from '../../static/files/file.svg';
import JPGIcon from '../../static/files/jpg.svg';
import PDFIcon from '../../static/files/pdf.svg';
import PNGIcon from '../../static/files/png.svg';
import TXTIcon from '../../static/files/txt.svg';
import XSLIcon from '../../static/files/xsl.svg';
import ZIPIcon from '../../static/files/zip.svg';
import staticIcon from '../../static/icons';
import { getUrlWithToken } from '../../utils/auth';
import { checkIsMediaFile, getFileExtension } from '../../utils/data';
import Button from '../Button';
import { Files } from '../MediaViewer';
import styles from './styles.module.scss';

const EXTENSIONS_ICONS: Record<string, ReactNode> = {
  [EXTENSIONS.CSV]: <CSVIcon width={24} height={24} />,
  [EXTENSIONS.DOC]: <DOCIcon width={24} height={24} />,
  [EXTENSIONS.DOCX]: <DOCIcon width={24} height={24} />,
  [EXTENSIONS.JPG]: <JPGIcon width={24} height={24} />,
  [EXTENSIONS.JPEG]: <JPGIcon width={24} height={24} />,
  [EXTENSIONS.PDF]: <PDFIcon width={24} height={24} />,
  [EXTENSIONS.PNG]: <PNGIcon width={24} height={24} />,
  [EXTENSIONS.TXT]: <TXTIcon width={24} height={24} />,
  [EXTENSIONS.XSL]: <XSLIcon width={24} height={24} />,
  [EXTENSIONS.XSLX]: <XSLIcon width={24} height={24} />,
  [EXTENSIONS.ZIP]: <ZIPIcon width={24} height={24} />,
};

const getFileName = (file: string) => {
  const { length } = file;

  if (length > 25) {
    const slicedNameStart = file.slice(0, 22);
    const slicedNameEnd = file.slice(length - 13, length);

    return `${slicedNameStart}...${slicedNameEnd}`;
  }

  return file;
};

interface Props {
  files: Files;
  download?: boolean;
  className?: string;
}

const FilesList = (props: Props) => {
  const { className, download = true } = props;
  let { files } = props;

  files = files.filter((file) => !checkIsMediaFile(file.name));

  if (files.length === 0) return null;

  return (
    <div className={clsx(styles.container, className)}>
      {files.map((item) => {
        const { src, name, error, onRemove } = item;

        const icon = EXTENSIONS_ICONS[getFileExtension(name)];

        const content = getFileName(name);

        return (
          <div key={src} className={styles.file} data-file>
            <div className={styles.top}>
              <div>
                {icon || <FileIcon width={24} height={24} />}
                {download ? (
                  <a download href={src.startsWith('blob') ? src : getUrlWithToken(src)}>
                    {content}
                  </a>
                ) : (
                  <span>{content}</span>
                )}
              </div>
              {onRemove && (
                <Button
                  size="extra-small"
                  variant="secondary"
                  onClick={onRemove}
                  icon={staticIcon('cross', 16)}
                />
              )}
            </div>
            {error && <span className={styles.error}>{error}</span>}
          </div>
        );
      })}
    </div>
  );
};

export default FilesList;
