import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import trainerGameTypeSchema from './trainerGameTypeSchema';
import workspaceSchema from './workspaceSchema';

const botSettingWorkspaceSchema = workspaceSchema.pick({ id: true, name: true });

const botSettingSchema = z.object({
  id: z.string(),
  name: z.string(),
  bot_code: z.string(),
  bot_build: z.string(),
  bot_build_straddle: z.string().or(z.null()),
  bot_code_tight: z.string().or(z.null()),
  bot_build_tight: z.string().or(z.null()),
  params_size: z.string().or(z.null()),
  params_size_hu: z.string().or(z.null()),
  description: z.string(),
  game_type: trainerGameTypeSchema,
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
  maintenance: botSettingWorkspaceSchema.or(z.null()),
  is_deleted: z.boolean(),
});

export const botSettingFilterValuesSchema = z.object({
  maintenance: z.array(botSettingWorkspaceSchema),
  game_type: z.array(botSettingSchema.shape.game_type),
});

export type BotSettingSchema = z.infer<typeof botSettingSchema>;

export default botSettingSchema;
