import { ReactNode } from 'react';
import { DropzoneProps } from 'react-dropzone';
import Files from '../../types/Files';
import { handleDrop } from '../../utils/files';
import Dropzone from '../Dropzone';
import FilesList from '../FilesList';
import PageAddonMedia from '../PageAddon/PageAddonMedia/PageAddonMedia';
import styles from './styles.module.scss';

interface Props {
  value: Files;
  children: ReactNode;
  onChange: (files: Files) => void;
  error?: string;
  disabled?: boolean;
  accept?: DropzoneProps['accept'];
  maxSize?: DropzoneProps['maxSize'];
}

const MediaFilesField = (props: Props) => {
  const { value, accept, maxSize, children, error, disabled, onChange } = props;

  const files = value.map((item) => ({
    name: item.name,
    src: item.url,
    ...(!disabled && {
      onRemove: () => onChange(value.filter((file) => file.url !== item.url)),
    }),
  }));

  return (
    <>
      <div>
        <Dropzone
          accept={accept}
          maxSize={maxSize}
          disabled={disabled}
          onDrop={(acceptedFiles) => onChange([...value, ...handleDrop(acceptedFiles)])}
        >
          {children}
        </Dropzone>
        {error && <div className={styles.error}>{error}</div>}
      </div>
      <div className={styles.files}>
        <PageAddonMedia files={files} />
        <FilesList files={files} />
      </div>
    </>
  );
};

export default MediaFilesField;
