import Checkbox from '../../Checkbox';

interface Props {
  checked: boolean;
  children: React.ReactNode;
  count?: number;
  onChange: (value: boolean) => void;
}

const FilterSwitcher = (props: Props) => {
  const { checked, children, count, onChange } = props;

  return (
    <Checkbox
      rtl
      variant="switch"
      checked={checked}
      onChange={(event) => onChange(event.target.checked)}
    >
      {children}
      &nbsp;
      {(count || count === 0) && <span>({count})</span>}
    </Checkbox>
  );
};

export default FilterSwitcher;
