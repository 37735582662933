import { Link as ReactRouterLink, LinkProps } from 'react-router-dom';
import useURL from '../../hooks/useURL';

interface Props extends Omit<LinkProps, 'to'> {
  to: string;
  params?: boolean;
}

const Link = (props: Props) => {
  const { to, children, params = true, ...rest } = props;

  const { getRouteWithUrlParams } = useURL();

  return (
    <ReactRouterLink to={params ? getRouteWithUrlParams(to) : to} {...rest}>
      {children}
    </ReactRouterLink>
  );
};

export default Link;
