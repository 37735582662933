import PageAddonCollapse from '../PageAddonCollapse';
import PageAddonField from '../PageAddonField';
import styles from './styles.module.scss';

interface Props {
  label: string;
  children: string;
}

const PageAddonText = (props: Props) => {
  const { label, children } = props;

  if (!children) return <PageAddonField label={label}>—</PageAddonField>;

  return (
    <PageAddonCollapse label={label}>
      <span className={styles.container}>{children}</span>
    </PageAddonCollapse>
  );
};

export default PageAddonText;
