import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  createWorkspaceIdentity,
  updateWorkspaceIdentity,
  geonamesSearchCountry,
  geonamesSearchCity,
} from '../../api';
import { IdentitySchema } from '../../api/schemas/identitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Checkbox from '../../components/Checkbox';
import DatePicker from '../../components/DatePicker';
import Editor from '../../components/Editor';
import Form from '../../components/Form';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import Radio from '../../components/Radio';
import RangeInput from '../../components/RangeInput';
import Select from '../../components/Select';
import SubmitButton from '../../components/SubmitButton';
import { DAY } from '../../constants/DATE_FORMATS';
import {
  convertCentsToUSDT,
  convertNumberToString,
  convertStringToNumber,
  convertUSDTToCents,
} from '../../utils/data';
import { getFileURL, uploadFiles } from '../../utils/files';
import { maxLength, minLength, required, age21, validDateFormat, max } from '../../utils/form';
import notify from '../../utils/notify';
import { TRAINER_POKER_ROOM_LABELS, trainerPokerRoomOptions } from '../../utils/trainer';
import { checkWorkspaceIdentityPokerFarm } from '../../utils/workspace';
import {
  BANK_ACCOUNTS_LABELS,
  DOCUMENTS_LABELS,
  getIdentityFullName,
  PHOTOS_LABELS,
  SOCIAL_NETWORKS_LABELS,
  WALLETS_LABELS,
} from './helpers';
import styles from './styles.module.scss';
import { Fields } from './types';
import WorkspaceIdentityFormBankAccounts from './WorkspaceIdentityFormBankAccounts';
import WorkspaceIdentityFormDocuments from './WorkspaceIdentityFormDocuments';
import WorkspaceIdentityFormOnlineWallets from './WorkspaceIdentityFormOnlineWallets';
import WorkspaceIdentityFormPhones from './WorkspaceIdentityFormPhones';
import WorkspaceIdentityFormPhotos from './WorkspaceIdentityFormPhotos';
import WorkspaceIdentityFormSocialNetworks from './WorkspaceIdentityFormSocialNetworks';

const GENDER = {
  OTHER: 0,
  MALE: 1,
  FEMALE: 2,
} as const;

const GENDER_ENUM = {
  [String(GENDER.OTHER)]: GENDER.OTHER,
  [String(GENDER.MALE)]: GENDER.MALE,
  [String(GENDER.FEMALE)]: GENDER.FEMALE,
};

const MAX_RENTAL_PERIOD = 12;
const MAX_MONTHLY_COST = 9999;

interface CommonProps {
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
}

interface CreateProps {
  onCreate: (args: { identityId: IdentitySchema['id'] }) => void;
  data?: never;
  onEdit?: never;
}

interface EditProps {
  data: IdentitySchema;
  onEdit: () => void;
  onCreate?: never;
}

type Props = CommonProps & (CreateProps | EditProps);

const WorkspaceIdentityForm = (props: Props) => {
  const { workspaceId, data, onCreate, onEdit, onClose } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: {
      first_name: data?.first_name || '',
      last_name: data?.last_name || '',
      date_of_birth: data?.date_of_birth || '',
      gender: data ? String(data.gender) : String(GENDER.MALE),
      minimal_rental_period: data ? convertNumberToString(data.minimal_rental_period) : '',
      monthly_cost: data ? convertNumberToString(data.monthly_cost, convertCentsToUSDT) : '',
      rooms: data
        ? data.rooms.map((item) => ({
            value: item,
            label: TRAINER_POKER_ROOM_LABELS[item],
          }))
        : [],
      channel: data?.channel || false,
      country: data?.location
        ? { value: data.location.country_iso, label: data.location.country_name }
        : null,
      city: data?.location.city ? { value: data.location.city, label: data.location.city } : null,
      phones: data?.phones.map((item) => ({ phone: item })) || [],
      online_wallets: data
        ? data.online_wallets.map((item) => ({
            ...item,
            password: item.password || '',
            type: { value: item.type, label: WALLETS_LABELS[item.type] },
          }))
        : [],
      bank_accounts: data
        ? data.bank_accounts.map((item) => ({
            ...item,
            type: { value: item.type, label: BANK_ACCOUNTS_LABELS[item.type] },
          }))
        : [],
      documents: data
        ? data.documents.map((item) => ({
            issued: item.issued,
            expire: item.expire || '',
            type: { value: item.type, label: DOCUMENTS_LABELS[item.type] },
            files: item.files.map((file) => ({
              id: file.id,
              url: getFileURL(file, 'identity'),
              name: file.original_name,
              content_type: file.content_type,
            })),
          }))
        : [],
      photos: data
        ? data.photos.map((item) => ({
            type: { value: item.type, label: PHOTOS_LABELS[item.type] },
            files: item.files.map((file) => ({
              id: file.id,
              url: getFileURL(file, 'identity'),
              name: file.original_name,
              content_type: file.content_type,
            })),
          }))
        : [],
      social_networks: data
        ? data.social_networks.map((item) => ({
            ...item,
            type: { value: item.type, label: SOCIAL_NETWORKS_LABELS[item.type] },
          }))
        : [],
      description: data?.description || '',
    },
  });

  const {
    control,
    watch,
    register,
    setValue,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    const documents = await Promise.all(
      values.documents.map(async (item) => {
        if (!item.type) throw new Error();

        return {
          type: item.type.value,
          issued: item.issued,
          expire: item.expire || null,
          file_ids: await uploadFiles(item.files),
        };
      })
    );

    const photos = await Promise.all(
      values.photos.map(async (item) => {
        if (!item.type) throw new Error();

        return {
          type: item.type.value,
          file_ids: await uploadFiles(item.files),
        };
      })
    );

    const payload = {
      ...values,
      minimal_rental_period: convertStringToNumber(values.minimal_rental_period),
      monthly_cost: convertStringToNumber(values.monthly_cost, convertUSDTToCents),
      rooms: values.rooms.map((item) => item.value),
      gender: GENDER_ENUM[values.gender],
      location: {
        country_iso: values.country?.value || '',
        country_name: values.country?.label || '',
        city: values.city?.value || '',
      },
      phones: values.phones.map((item) => item.phone),
      documents,
      photos,
      bank_accounts: values.bank_accounts.map((item) => {
        if (!item.type) throw new Error();

        return {
          ...item,
          type: item.type.value,
        };
      }),
      online_wallets: values.online_wallets.map((item) => {
        if (!item.type) throw new Error();

        return {
          ...item,
          password: item.password || null,
          type: item.type.value,
        };
      }),
      social_networks: values.social_networks.map((item) => {
        if (!item.type) throw new Error();

        return {
          ...item,
          type: item.type.value,
        };
      }),
      description: values.description,
    };

    if (data) {
      await updateWorkspaceIdentity({ workspaceId, identityId: data.id, payload });

      onEdit();
    } else {
      const response = await createWorkspaceIdentity({ workspaceId, payload });

      onCreate({ identityId: response.id });
    }

    notify('success', {
      title: data ? t('sentences.changes_have_been_saved') : t('sentences.record_has_been_created'),
    });
  };

  const country = watch('country');

  const isPokerFarm = checkWorkspaceIdentityPokerFarm(workspaceId);

  return (
    <PageAddon
      title={
        data ? `${t('common.edit')} ${getIdentityFullName(data)}` : t('common.create_identity')
      }
      onClose={onClose}
    >
      <Form form={form} onSubmit={onSubmit}>
        <PageAddon.Heading level="first">{t('common.profile')}</PageAddon.Heading>
        <div className={styles.fields}>
          <Input
            label={`${t('common.first_name')}*`}
            error={errors.first_name?.message}
            {...register('first_name', {
              validate: {
                required,
                minLength: minLength(),
                maxLength: maxLength(),
              },
            })}
          />
          <Input
            label={`${t('common.last_name')}*`}
            error={errors.last_name?.message}
            {...register('last_name', {
              validate: {
                required,
                minLength: minLength(),
                maxLength: maxLength(),
              },
            })}
          />
        </div>
        <Controller
          control={control}
          name="date_of_birth"
          rules={{ validate: { required, age21, validDate: validDateFormat(DAY) } }}
          render={({ field }) => (
            <DatePicker
              label={`${t('common.date_of_birth')}*`}
              error={errors.date_of_birth?.message}
              {...field}
            />
          )}
        />
        <div className={styles.radioGroup}>
          {t('common.gender')}
          <div>
            <Radio {...register('gender')} value={String(GENDER.MALE)} className={styles.radio}>
              <span>{t('common.male')}</span>
            </Radio>
            <Radio {...register('gender')} value={String(GENDER.FEMALE)} className={styles.radio}>
              <span>{t('common.female')}</span>
            </Radio>
          </div>
        </div>
        <PageAddon.Heading level="first" className={styles.heading}>
          {t('common.details')}
        </PageAddon.Heading>
        {!isPokerFarm && (
          <Controller
            name="minimal_rental_period"
            control={control}
            rules={{
              validate: {
                max: max(t('common.min_rent'), MAX_RENTAL_PERIOD),
              },
            }}
            render={({ field }) => (
              <RangeInput
                step={1}
                min={0}
                max={MAX_RENTAL_PERIOD}
                label={t('common.min_rental_month')}
                values={[Number(field.value)]}
                onFromChange={(value) => field.onChange(String(value))}
                error={errors.minimal_rental_period?.message}
              />
            )}
          />
        )}
        {!isPokerFarm && (
          <Controller
            name="monthly_cost"
            control={control}
            rules={{
              validate: {
                max: max(t('common.monthly_cost'), MAX_MONTHLY_COST, 'USDT'),
              },
            }}
            render={({ field }) => (
              <RangeInput
                step={10}
                min={0}
                max={MAX_MONTHLY_COST}
                label={t('common.cost_month_USDT')}
                values={[Number(field.value)]}
                onFromChange={(value) => field.onChange(String(value))}
                error={errors.monthly_cost?.message}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="country"
          rules={{ validate: required }}
          render={({ field }) => (
            <Select.Async
              paginated={false}
              onLoad={async ({ search }) => {
                const response = await geonamesSearchCountry({ ...(search && { search }) });

                const options = response.geonames.map((item) => ({
                  value: item.country_code,
                  label: item.country_name,
                }));

                return options;
              }}
            >
              {({ options, ...rest }) => (
                <Select
                  clearable={false}
                  label={`${t('common.country')}*`}
                  options={options}
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);

                    setValue('city', null);
                  }}
                  error={errors.country?.message}
                  {...rest}
                />
              )}
            </Select.Async>
          )}
        />
        <Controller
          control={control}
          name="city"
          rules={{ validate: required }}
          render={({ field }) => (
            <Select.Async
              onLoad={async ({ page, search }) => {
                const response = await geonamesSearchCity({
                  featureClass: 'P',
                  country: country?.value || '',
                  max_rows: String(20),
                  orderBy: 'population',
                  ...(page && { start_row: String(20 * (Number(page) - 1)) }),
                  ...(search && { name: search }),
                });

                const options = response.geonames.map((item) => ({
                  value: item.name,
                  label: item.name,
                }));

                return options;
              }}
            >
              {({ options, ...rest }) => (
                <Select
                  disabled={!country}
                  clearable={false}
                  label={`${t('common.city')}*`}
                  options={options}
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.city?.message}
                  {...rest}
                />
              )}
            </Select.Async>
          )}
        />
        {!isPokerFarm && (
          <Controller
            name="rooms"
            control={control}
            render={({ field }) => (
              <Select.Multi
                renderValueAsTag
                label={t('common.visited_poker_rooms')}
                value={field.value}
                onChange={field.onChange}
                error={errors.rooms?.message}
                options={trainerPokerRoomOptions}
              />
            )}
          />
        )}
        {!isPokerFarm && (
          <Checkbox variant="switch" className={styles.checkbox} {...register('channel')}>
            {t('common.channel')}
            &nbsp;
          </Checkbox>
        )}
        <WorkspaceIdentityFormPhones />
        <WorkspaceIdentityFormOnlineWallets />
        <WorkspaceIdentityFormBankAccounts />
        <WorkspaceIdentityFormDocuments />
        <WorkspaceIdentityFormPhotos />
        <WorkspaceIdentityFormSocialNetworks />
        <PageAddon.Heading level="first" className={styles.heading}>
          {t('common.other')}
        </PageAddon.Heading>
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <Editor
              label={t('common.description')}
              error={errors.description?.message}
              {...field}
            />
          )}
        />
        <PageAddon.Controls>
          <SubmitButton>{data ? t('common.save') : t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default WorkspaceIdentityForm;
