import { ChannelSchema, STATUS } from '../../api/schemas/channelSchema';
import Badge from '../../components/Badge';
import { CHANNEL_STATUS_LABELS } from './helpers';

interface Props {
  data: ChannelSchema;
}

const WorkspaceChannelStatus = (props: Props) => {
  const { data } = props;

  const { status } = data;

  const getColor = () => {
    if (status === STATUS.ACTIVE) return 'green';
    if (status === STATUS.INACTIVE) return 'orange';

    return 'grey';
  };

  return <Badge color={getColor()}>{CHANNEL_STATUS_LABELS[status]}</Badge>;
};

export default WorkspaceChannelStatus;
