import { useReducer } from 'react';

interface Args {
  ids: string[];
}

interface State {
  selected: string[];
  unselected: string[];
  selectAllMode: boolean;
}

type Action =
  | {
      type: 'select' | 'unselect';
      payload: string;
    }
  | { type: 'selectAll' | 'unselectAll'; payload?: never };

const reducer = (state: State, action: Action): State => {
  const { type, payload } = action;

  if (type === 'select') {
    if (Array.isArray(payload)) {
      return {
        ...state,
        selected: [...state.selected, ...payload],
      };
    }

    if (state.selectAllMode) {
      const unselected = state.unselected.includes(payload)
        ? state.unselected.filter((item) => item !== payload)
        : [...state.unselected, payload];

      return {
        ...state,
        unselected,
      };
    }

    const selected = state.selected.includes(payload)
      ? state.selected.filter((item) => item !== payload)
      : [...state.selected, payload];

    return {
      ...state,
      selected,
    };
  }

  if (type === 'unselect') {
    if (state.selectAllMode) {
      return {
        ...state,
        unselected: [...state.unselected, payload],
      };
    }

    return {
      ...state,
      selected: state.selected.filter((item) => item !== payload),
    };
  }

  if (type === 'selectAll') {
    if (state.selectAllMode) {
      if (state.unselected.length) {
        return {
          ...state,
          unselected: [],
        };
      }

      return {
        ...state,
        unselected: [],
        selectAllMode: false,
      };
    }

    return {
      ...state,
      selected: [],
      selectAllMode: true,
    };
  }

  if (type === 'unselectAll') {
    return {
      ...state,
      selected: [],
      unselected: [],
      selectAllMode: false,
    };
  }

  if (type === 'selectMore') {
    return {
      ...state,
      selected: payload,
    };
  }

  return state;
};

const useRowSelection = (args: Args) => {
  const { ids } = args;

  const [state, dispatch] = useReducer(reducer, {
    selected: [],
    unselected: [],
    selectAllMode: false,
  });

  const checkIsSelected = (id: string) => {
    if (state.selectAllMode) return !state.unselected.includes(id);

    return state.selected.includes(id);
  };

  const allSelected = (() => {
    if (ids.length === 0) return false;

    if (state.selectAllMode) {
      if (state.unselected.length === 0) return true;

      return false;
    }

    if (state.selected.length === ids.length) return true;

    return false;
  })();

  return {
    selected: state.selected,
    unselected: state.unselected,
    allSelected,
    selectAllMode: state.selectAllMode,
    select: (payload: string) => dispatch({ type: 'select', payload }),
    unselect: (payload: string) => dispatch({ type: 'unselect', payload }),
    selectAll: () => dispatch({ type: 'selectAll' }),
    unselectAll: () => dispatch({ type: 'unselectAll' }),
    checkIsSelected,
  };
};

export default useRowSelection;
