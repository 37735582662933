import { invert } from 'remeda';
import { CURRENCY } from '../api/schemas/currencySchema';

export const CURRENCY_LABELS = invert(CURRENCY);

export const currenciesOptions = Object.values(CURRENCY)
  .filter((item) => item !== CURRENCY.UNKNOWN)
  .map((item) => ({
    value: item,
    label: CURRENCY_LABELS[item],
  }));
