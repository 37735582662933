import api from '../../services/api';
import { AgentSchema } from '../schemas/agentSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';

type Schema = AgentSchema;

interface Payload {
  name?: Schema['name'];
  contact?: Schema['contact'] | null;
}

interface Args {
  agentId: Schema['id'];
  payload: Payload;
}

const updateGlobalAgent = async (args: Args) => {
  const { agentId, payload } = args;

  const response = await api.patch(`/api/agent/${agentId}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateGlobalAgent;
