import { ReactNode } from 'react';
import Empty from '../../static/empty.svg';
import styles from './styles.module.scss';

interface Props {
  title: string;
  description?: string;
  children?: ReactNode;
}

const Stub = (props: Props) => {
  const { title, description, children } = props;

  return (
    <div className={styles.container}>
      {children || <Empty width={80} height={81} />}
      <div>{title}</div>
      {description && <span>{description}</span>}
    </div>
  );
};

export default Stub;
