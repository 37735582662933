import api from '../../services/api';
import areaSchema, { AreaSchema } from '../schemas/areaSchema';

type Schema = AreaSchema;

interface Payload {
  name: Schema['name'];
  area_rates: Schema['area_rates'];
}

interface Args {
  payload: Payload;
}

const createGlobalArea = async (args: Args) => {
  const { payload } = args;

  const response = await api.post('/api/area', payload);

  return areaSchema.pick({ id: true }).parse(response.data);
};

export default createGlobalArea;
