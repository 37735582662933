import { useTranslation } from 'react-i18next';
import { WorkspaceClubWhitelistSchema } from '../../api/schemas/workspaceClubWhitelistSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import { formatAbsoluteDate } from '../../utils/date';
import { TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';
import WorkspaceClubWhitelistDetailDropdown from './WorkspaceClubWhitelistDetailDropdown';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: WorkspaceClubWhitelistSchema;
  onClose: () => void;
}

const WorkspaceClubWhitelistDetail = (props: Props) => {
  const { workspaceId, data, onClose } = props;

  const { t } = useTranslation();

  const { id, player_id, room, created_on } = data;

  return (
    <PageAddon title={player_id} onClose={onClose}>
      <PageAddon.Fields>
        <PageAddon.Id id={id} />
        <PageAddon.Field label={t('common.poker_room')}>
          {TRAINER_POKER_ROOM_LABELS[room]}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.created')}>
          {formatAbsoluteDate(created_on)}
        </PageAddon.Field>
      </PageAddon.Fields>
      <PageAddon.Controls>
        <PageAddon.Controls.Group>
          <WorkspaceClubWhitelistDetailDropdown data={data} workspaceId={workspaceId} />
        </PageAddon.Controls.Group>
      </PageAddon.Controls>
    </PageAddon>
  );
};

export default WorkspaceClubWhitelistDetail;
