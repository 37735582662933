import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadGlobalWorkspaceBalance } from '../../api';
import { WorkspaceBalanceSchema } from '../../api/schemas/workspaceBalanceSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { convertCentsToUSDT } from '../../utils/data';
import { updateDetailQueryData } from '../../utils/queryClient';

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const GlobalWorkspaceDetailBalance = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();

  const queryKey = [queryKeys.globalWorkspaceBalance({ workspaceId })];
  const queryFn = () => loadGlobalWorkspaceBalance({ workspaceId });

  const { data, isPending, isError, refetch } = useQuery({
    queryKey,
    queryFn,
  });

  useWebSocketMessage<WorkspaceBalanceSchema>({
    domain: 'GlobalMaintenance',
    onMessage: (message) => {
      if (message.action === 'BalanceState' && message.payload.id === workspaceId) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  return (
    <>
      <PageAddon.Field
        label={t('common.balance_USDT')}
        loading={isPending}
        {...(isError && { onRetry: refetch })}
      >
        {data ? <PageAddon.Copy>{convertCentsToUSDT(data.balance)}</PageAddon.Copy> : '—'}
      </PageAddon.Field>
      <PageAddon.Field
        label={t('common.hold_USDT')}
        loading={isPending}
        {...(isError && { onRetry: refetch })}
      >
        {data ? <PageAddon.Copy>{convertCentsToUSDT(data.balance_hold)}</PageAddon.Copy> : '—'}
      </PageAddon.Field>
    </>
  );
};

export default GlobalWorkspaceDetailBalance;
