import { ACTION_TYPE, SeatControlRuleSchema } from '../../api/schemas/seatControlRuleSchema';
import { t } from '../../i18n';
import styles from './styles.module.scss';

export const ACTION_TYPE_COLOR: Record<SeatControlRuleSchema['action_type'], string> = {
  [ACTION_TYPE.PLAYER]: styles.player,
  [ACTION_TYPE.OBSERVER]: styles.observer,
};

export const ACTION_TYPE_LABEL: Record<SeatControlRuleSchema['action_type'], string> = {
  [ACTION_TYPE.PLAYER]: t('common.player'),
  [ACTION_TYPE.OBSERVER]: t('common.observer'),
};
