import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { updateGlobalAccountSession } from '../../api';
import { GlobalSessionSchema } from '../../api/schemas/session/globalSessionSchema';
import Form from '../../components/Form';
import PageAddon from '../../components/PageAddon';
import SubmitButton from '../../components/SubmitButton';
import notify from '../../utils/notify';
import { TYPE_ENUM, getTitle } from '../session/helpers';
import SessionFormType from '../session/SessionFormType';
import { Fields } from '../session/types';

interface Props {
  data: GlobalSessionSchema;
  onClose: () => void;
}

const GlobalSessionForm = (props: Props) => {
  const { data, onClose } = props;

  const { id: sessionId, start, end, account } = data;

  const { id: accountId } = account;

  const { t } = useTranslation();

  const form = useForm<Fields>({ defaultValues: { session_type: String(data.session_type) } });

  const onSubmit = async (values: Fields) => {
    const payload = {
      session_type: TYPE_ENUM[values.session_type],
    };

    await updateGlobalAccountSession({ accountId, sessionId, payload });

    onClose();
    notify('success', { title: t('sentences.changes_have_been_saved') });
  };

  return (
    <PageAddon onClose={onClose} title={`${t('common.edit')} ${getTitle({ start, end })}`}>
      <Form form={form} onSubmit={onSubmit}>
        <SessionFormType />
        <PageAddon.Controls>
          <SubmitButton>{t('common.save')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default GlobalSessionForm;
