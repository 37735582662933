import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { loadWorkspaceChannels } from '../../../api';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Button from '../../../components/Button';
import { usePageAddonContext } from '../../../components/PageAddon';
import Select from '../../../components/Select';
import icon from '../../../static/icons';
import { required } from '../../../utils/form';
import { WorkspaceAccountFields } from '../../account/types';
import WorkspaceChannelForm from '../../workspace-channel/WorkspaceChannelForm';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  required?: boolean;
}

const WorkspaceAccountFormChannelIds = (props: Props) => {
  const { workspaceId, required: req } = props;

  const { t } = useTranslation();

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<WorkspaceAccountFields>();

  const { setExtra } = usePageAddonContext();

  const channelIds = watch('channel_ids');

  return (
    <Controller
      name="channel_ids"
      control={control}
      {...(req && { rules: { validate: required } })}
      render={({ field }) => (
        <Select.AsyncV2
          onLoad={(args) => loadWorkspaceChannels({ workspaceId }, { ...args, is_free: 'true' })}
          select={(item) => ({
            value: item.id,
            label: item.host,
            ...(item.country && { caption: item.country }),
          })}
        >
          {(selectProps) => (
            <Select.Multi
              clearable={!req}
              renderValueAsTag
              label={req ? `${t('common.channels')}*` : t('common.channels')}
              addon={
                <Button
                  rtl
                  variant="light"
                  size="extra-small"
                  icon={icon('add', 16)}
                  onClick={() =>
                    setExtra(
                      <WorkspaceChannelForm
                        workspaceId={workspaceId}
                        onClose={() => setExtra(null)}
                        onCreate={({ channelId, host }) => {
                          setValue('channel_ids', [
                            ...channelIds,
                            { value: channelId, label: host },
                          ]);

                          setExtra(null);
                        }}
                      />
                    )
                  }
                >
                  {t('common.create')}
                </Button>
              }
              value={field.value}
              onChange={field.onChange}
              error={errors.channel_ids?.message}
              {...selectProps}
            />
          )}
        </Select.AsyncV2>
      )}
    />
  );
};

export default WorkspaceAccountFormChannelIds;
