import { useTranslation } from 'react-i18next';
import { IdentitySchema } from '../../api/schemas/identitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageDetailLink from '../../components/PageDetailLink';
import Tooltip from '../../components/Tooltip';
import routes from '../../config/routes';
import icon from '../../static/icons';
import { checkWorkspaceIdentityPokerFarm } from '../../utils/workspace';
import { getRentalState } from './helpers';
import styles from './styles.module.scss';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: IdentitySchema;
}

const WorkspaceIdentityDetailLink = (props: Props) => {
  const { workspaceId, data } = props;

  const { id, first_name, last_name, on_market } = data;

  const { t } = useTranslation();

  const isPokerFarm = checkWorkspaceIdentityPokerFarm(workspaceId);

  return (
    <div className={styles.detailLink}>
      <PageDetailLink
        label={`${last_name} ${first_name}`}
        to={routes.workspaceIdentity({ workspaceId, identityId: id })}
        {...(!isPokerFarm &&
          on_market && {
            extra: <Tooltip label={t('common.on_market')}>{icon('shoppingCart', 12)}</Tooltip>,
          })}
      />
      {!isPokerFarm && <span>{getRentalState(data)}</span>}
    </div>
  );
};

export default WorkspaceIdentityDetailLink;
