import { useFormContext } from 'react-hook-form';
import { loadWorkspaceDeals } from '../../api';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceDealSchema } from '../../api/schemas/deal/workspaceDealSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import LinkButton from '../../components/LinkButton';
import PageAddon from '../../components/PageAddon';
import Select from '../../components/Select';
import useURL from '../../hooks/useURL';
import { WorkspaceAccountFields as Fields } from '../account/types';
import { renderCaption } from '../deal/helpers';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: WorkspaceAccountSchema;
  onSubmit: () => Promise<void>;
  disabled?: boolean;
  getDealRoute?: (args: { dealId: WorkspaceDealSchema['id'] }) => string;
}

const WorkspaceAccountDetailDeals = (props: Props) => {
  const { workspaceId, data, disabled, onSubmit, getDealRoute } = props;

  const { room, clubs, deals } = data;

  const { navigate } = useURL();

  const { watch, setValue } = useFormContext<Fields>();

  const dealIds = watch('deal_ids');

  if (deals.length === 0 && disabled) return '—';

  return (
    <PageAddon.QuickField.Group>
      {deals.map((item) => (
        <PageAddon.QuickField
          key={item.id}
          disabled={disabled}
          title={item.code}
          subTitle={item.agent.name}
          onDelete={() => {
            setValue(
              'deal_ids',
              dealIds.filter((el) => el.value !== item.id)
            );

            onSubmit();
          }}
        >
          {getDealRoute && (
            <LinkButton
              size="extra-small"
              variant="light"
              onClick={() => navigate(getDealRoute({ dealId: item.id }))}
            />
          )}
        </PageAddon.QuickField>
      ))}
      {!disabled && (
        <Select.AsyncV2
          onLoad={(args) =>
            loadWorkspaceDeals(
              { workspaceId },
              {
                ...args,
                poker_room_in: `${room}`,
                club_id_in: clubs.map((item) => item.id).join(','),
              }
            )
          }
          select={(item) => ({
            value: item.id,
            label: item.code,
            caption: renderCaption(item),
          })}
        >
          {({ options, ...selectAsyncProps }) => (
            <Select.Quick
              name="deal_ids"
              disabled={disabled}
              options={options.filter(
                (item) => !dealIds.map((el) => el.value).includes(item.value)
              )}
              onChange={(value) => {
                setValue('deal_ids', value ? [...dealIds, value] : []);

                onSubmit();
              }}
              {...selectAsyncProps}
            />
          )}
        </Select.AsyncV2>
      )}
    </PageAddon.QuickField.Group>
  );
};

export default WorkspaceAccountDetailDeals;
