import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { pick } from 'remeda';
import {
  bulkUpdateWorkspaceAccountsRoomConfig,
  bulkUpdateFilteredWorkspaceAccountsRoomConfig,
} from '../../../api';
import { TrainerPokerRoomSchema } from '../../../api/schemas/trainerPokerRoomSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Form from '../../../components/Form';
import PageAddon from '../../../components/PageAddon';
import Select, { Options } from '../../../components/Select';
import Stub from '../../../components/Stub';
import SubmitButton from '../../../components/SubmitButton';
import routes from '../../../config/routes';
import useURL from '../../../hooks/useURL';
import { TRAINER_POKER_ROOM_LABELS } from '../../../utils/trainer';
import {
  getDefaultValues,
  getFields,
  getPayload,
  labels,
  roomsFields,
} from '../../account/roomConfigHelpers';
import { RoomConfigFields as Fields } from '../../account/types';
import { useWorkspaceAccountsFilterValuesQuery } from '../queries';
import useBulkActions from '../useBulkActions';
import useSelectedFields from '../useSelectedFields';
import WorkspaceAccountFormAdd from '../WorkspaceAccountForm/WorkspaceAccountFormAdd';
import WorkspaceAccountFormField from '../WorkspaceAccountForm/WorkspaceAccountFormField';

interface BulkFormProps {
  workspaceId: WorkspaceSchema['id'];
  roomOptions: Options<TrainerPokerRoomSchema>;
}

const BulkForm = (props: BulkFormProps) => {
  const { workspaceId, roomOptions } = props;

  const { t } = useTranslation();

  const { navigate } = useURL();

  const defaultRoom = roomOptions[0];

  const defaultValues = getDefaultValues();

  const [room, setRoom] = useState(defaultRoom);

  const form = useForm<Fields>({ defaultValues });

  const fields = getFields(form);

  const { ids, params, selectAllMode, handle } = useBulkActions();

  const { selectedFields, disabled, addField, removeField, fieldDisabled, resetFields } =
    useSelectedFields({ fields });

  const onClose = () => navigate(routes.workspaceAccounts({ workspaceId }));

  return (
    <PageAddon title={t('common.bulk_edit_config')} onClose={onClose}>
      {room ? (
        <Form
          form={form}
          onSubmit={(values) =>
            handle(async () => {
              const fn = selectAllMode
                ? bulkUpdateFilteredWorkspaceAccountsRoomConfig
                : bulkUpdateWorkspaceAccountsRoomConfig;

              await fn({
                workspaceId,
                payload: {
                  account_ids: ids,
                  poker_room_config: pick(getPayload(values), selectedFields),
                },
                params,
              });

              onClose();
            })
          }
        >
          <Select
            clearable={false}
            label={t('common.poker_room')}
            value={room}
            options={roomOptions}
            onChange={(value) => {
              if (value) {
                form.reset();
                setRoom(value);
                resetFields();
              }
            }}
          />
          {selectedFields.map((item) => (
            <WorkspaceAccountFormField key={item} onRemove={() => removeField(item)}>
              {fields[item]}
            </WorkspaceAccountFormField>
          ))}
          <WorkspaceAccountFormAdd
            options={roomsFields[room.value].map((item) => ({
              label: labels[item],
              disabled: fieldDisabled(item),
              onClick: () => addField(item),
            }))}
          />
          <PageAddon.Controls>
            <SubmitButton disabled={disabled}>{t('common.save')}</SubmitButton>
          </PageAddon.Controls>
        </Form>
      ) : (
        <Stub title={t('sentences.no_config_options_available')} />
      )}
    </PageAddon>
  );
};

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceAccountRoomConfigBulkForm = (props: Props) => {
  const { workspaceId } = props;

  const { data, isPending } = useWorkspaceAccountsFilterValuesQuery({ workspaceId });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  const roomOptions = data.room
    .map((item) => ({
      value: item,
      label: TRAINER_POKER_ROOM_LABELS[item],
    }))
    .filter((item) => Object.keys(roomsFields).includes(String(item.value)));

  return <BulkForm workspaceId={workspaceId} roomOptions={roomOptions} />;
};

export default WorkspaceAccountRoomConfigBulkForm;
