import { useTranslation } from 'react-i18next';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { BotSettingSchema } from '../../../api/schemas/botSettingSchema';
import PageAddon from '../../../components/PageAddon';
import { TRAINER_GAME_TYPE_LABELS } from '../../../utils/trainer';
import styles from './styles.module.scss';

interface Props {
  data: WorkspaceAccountSchema;
  botSettingId: BotSettingSchema['id'];
  onClose: () => void;
}

const WorkspaceAccountBotSettingDetail = (props: Props) => {
  const { data, botSettingId, onClose } = props;

  const { t } = useTranslation();

  const botSetting = data.bot_settings.find((item) => item.id === botSettingId);

  if (!botSetting) return null;

  const {
    id,
    name,
    game_type,
    bot_code,
    bot_build,
    bot_build_straddle,
    bot_code_tight,
    bot_build_tight,
    params_size,
    params_size_hu,
  } = botSetting;

  return (
    <PageAddon copy onClose={onClose} title={name}>
      <PageAddon.Fields>
        <PageAddon.Id id={id} />
        <PageAddon.Field label={t('common.game_type')}>
          <PageAddon.Copy>{TRAINER_GAME_TYPE_LABELS[game_type]}</PageAddon.Copy>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.code')}>
          <PageAddon.Copy>{bot_code}</PageAddon.Copy>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.build')}>
          <PageAddon.Copy>{bot_build}</PageAddon.Copy>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.straddle')}>
          {bot_build_straddle ? <PageAddon.Copy>{bot_build_straddle}</PageAddon.Copy> : '—'}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.code_tight')}>
          {bot_code_tight ? <PageAddon.Copy>{bot_code_tight}</PageAddon.Copy> : '—'}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.build_tight')}>
          {bot_build_tight ? <PageAddon.Copy>{bot_build_tight}</PageAddon.Copy> : '—'}
        </PageAddon.Field>
        <PageAddon.Collapse label={t('common.params')}>
          <pre className={styles.pre}>{params_size || '—'}</pre>
        </PageAddon.Collapse>
        <PageAddon.Collapse label={t('common.params_hu')}>
          <pre className={styles.pre}>{params_size_hu || '—'}</pre>
        </PageAddon.Collapse>
      </PageAddon.Fields>
    </PageAddon>
  );
};

export default WorkspaceAccountBotSettingDetail;
