import { useState } from 'react';
import { WorkspaceClubManagerSchema } from '../../api/schemas/workspaceClubManagerSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Dropdown from '../../components/Dropdown';
import { compact } from '../../utils';
import useDropdown from './useDropdown';
import WorkspaceClubManagerChangePasswordDialog from './WorkspaceClubManagerChangePasswordDialog';

interface Props {
  data: WorkspaceClubManagerSchema;
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceClubManagerDropdown = (props: Props) => {
  const { data, workspaceId } = props;

  const [changePassword, setChangePassword] = useState(false);

  const { title, options } = useDropdown({
    ...props,
    changePassword: () => setChangePassword(true),
  });

  const compactOptions = compact([
    options.edit,
    options.changePassword,
    options.copy,
    options.divider,
    options.delete,
    options.restore,
  ]);

  return (
    <>
      {changePassword && (
        <WorkspaceClubManagerChangePasswordDialog
          id={workspaceId}
          data={data}
          onClose={() => setChangePassword(false)}
        />
      )}
      <Dropdown title={title} buttonSize="small" options={compactOptions} />
    </>
  );
};

export default WorkspaceClubManagerDropdown;
