import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Page from '../../components/Page';
import Placeholder from '../../components/Placeholder';
import useSidebarSection from '../../hooks/useSidebarSection';
import Error404 from '../../static/404.svg';
import { useUserStore } from '../../store/user';
import styles from './styles.module.scss';

const Error404Page = () => {
  const { t } = useTranslation();

  const user = useUserStore();
  const { isSectionExists, moveToFirstSection } = useSidebarSection();

  return (
    <Page search={false} filter={false}>
      <div className={styles.container}>
        <Error404 width={600} height={255} />
        <span>{t('common.page_not_found')}</span>
        {!user.data ? (
          <Placeholder className={styles.placeholder} />
        ) : (
          isSectionExists && <Button onClick={moveToFirstSection}>{t('common.go_home')}</Button>
        )}
      </div>
    </Page>
  );
};

export default Error404Page;
