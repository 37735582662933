import {
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useFloatingNodeId,
  useFloatingParentNodeId,
  FloatingTree,
  FloatingNode,
  FloatingPortal,
  FloatingOverlay,
  FloatingFocusManager,
} from '@floating-ui/react';
import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import usePrevious from '../../hooks/usePrevious';
import useScrolled from '../../hooks/useScrolled';
import ConfirmDialog from '../ConfirmDialog';
import DialogClose from './DialogClose';
import DialogFooter from './DialogFooter';
import DialogTitle from './DialogTitle';
import DialogTop from './DialogTop';
import styles from './styles.module.scss';
import { Close, Props } from './types';

const DialogComponent = (props: Props) => {
  const { label, className, secondary, onClose, children, confirmClose } = props;

  const { t } = useTranslation();

  const [open, setOpen] = useState(!label);
  const { scrolled, onScroll } = useScrolled();

  const nodeId = useFloatingNodeId();

  const [confirming, setConfirming] = useState(false);

  const close: Close = (args = { confirmClose: Boolean(confirmClose) }) => {
    if (args.confirmClose && confirmClose) {
      if (!confirming) {
        setConfirming(true);
      } else {
        setOpen(false);
        setConfirming(false);
      }
    } else {
      setOpen(false);
    }
  };

  const { refs, context } = useFloating({
    open,
    nodeId,
    onOpenChange: (value) => {
      if (value) {
        setOpen(true);
      } else {
        close();
      }
    },
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context, {
      bubbles: false,
      outsidePress: (event) => {
        if (event.target instanceof Element) return !event.target.closest('.Toastify');

        return true;
      },
    }),
  ]);

  const prevOpen = usePrevious(open);

  useEffect(() => {
    if (onClose && open !== prevOpen && !open) onClose();
  }, [open, prevOpen, onClose]);

  const referenceProps = getReferenceProps({ ref: refs.setReference });

  return (
    <FloatingNode id={nodeId}>
      {label && label({ referenceProps })}
      {confirming && (
        <ConfirmDialog
          danger
          title={t('sentences.changes_are_not_saved')}
          description={t('sentences.close_without_saving')}
          confirmText={t('common.close')}
          onConfirm={() => close()}
          onClose={() => setConfirming(false)}
        />
      )}
      <FloatingPortal id="root">
        {open && (
          <FloatingOverlay
            lockScroll
            data-overlay="true"
            className={clsx(styles.overlay, className, secondary && styles.secondary)}
          >
            <FloatingFocusManager context={context}>
              <div
                data-content="true"
                {...getFloatingProps({ ref: refs.setFloating, className: styles.content })}
              >
                <DialogClose onClose={close} />
                {children({ scrolled, onScroll, close })}
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        )}
      </FloatingPortal>
    </FloatingNode>
  );
};

const Dialog = (props: Props) => {
  const parentId = useFloatingParentNodeId();

  if (parentId === null) {
    return (
      <FloatingTree>
        <DialogComponent {...props} />
      </FloatingTree>
    );
  }

  return <DialogComponent {...props} />;
};

Dialog.Top = DialogTop;
Dialog.Title = DialogTitle;
Dialog.Footer = DialogFooter;

export default Dialog;
