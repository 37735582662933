import { WorkspaceClubWhitelistSchema } from '../../api/schemas/workspaceClubWhitelistSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageDetailLink from '../../components/PageDetailLink';
import routes from '../../config/routes';

interface Props {
  data: WorkspaceClubWhitelistSchema;
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceClubWhitelistDetailLink = (props: Props) => {
  const { data, workspaceId } = props;

  const { id: whitelistMemberId, player_id } = data;

  return (
    <PageDetailLink
      label={player_id}
      to={routes.workspaceClubWhitelistMember({ workspaceId, whitelistMemberId })}
    />
  );
};

export default WorkspaceClubWhitelistDetailLink;
