import { DOCUMENT_TYPE } from '../../../api/schemas/identity';
import { IdentitySchema } from '../../../api/schemas/identitySchema';
import { MarketIdentitySchema } from '../../../api/schemas/marketIdentitySchema';
import Tooltip from '../../../components/Tooltip';
import {
  DOCUMENTS_CROSSED_ICONS_16,
  DOCUMENTS_ICONS_16,
  DOCUMENTS_LABELS,
  DOCUMENTS_NEGATIVE_LABELS,
  styleIcon,
} from '../helpers';
import styles from './styles.module.scss';

type Documents = IdentitySchema['documents'][number]['type'][];
type MarketDocuments = MarketIdentitySchema['documents'];

interface Props {
  documents: Documents | MarketDocuments;
}

const WorkspaceIdentityDocuments = (props: Props) => {
  const { documents } = props;

  const allTypes = Object.values(DOCUMENT_TYPE);
  const currentTypes = documents.map((item) => item);

  return (
    <div className={styles.container}>
      {allTypes.map((item) => {
        const isDocumentLoad = currentTypes.includes(item);

        return item ? (
          <Tooltip
            key={item}
            label={isDocumentLoad ? DOCUMENTS_LABELS[item] : DOCUMENTS_NEGATIVE_LABELS[item]}
          >
            {isDocumentLoad
              ? styleIcon(DOCUMENTS_ICONS_16[item])
              : DOCUMENTS_CROSSED_ICONS_16[item]}
          </Tooltip>
        ) : null;
      })}
    </div>
  );
};

export default WorkspaceIdentityDocuments;
