import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadWorkspaceAccountSession } from '../../api';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSessionSchema } from '../../api/schemas/session/workspaceSessionSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { updateDetailQueryData } from '../../utils/queryClient';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
  sessionId: WorkspaceSessionSchema['id'];
  children: (args: {
    data: WorkspaceSessionSchema;
    sessionId: WorkspaceSessionSchema['id'];
  }) => ReactElement;
}

const WorkspaceSessionLoader = (props: Props) => {
  const { workspaceId, accountId, sessionId, children } = props;

  const queryKey = [queryKeys.workspaceSession({ workspaceId, sessionId })];
  const queryFn = () => loadWorkspaceAccountSession({ workspaceId, accountId, sessionId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<WorkspaceSessionSchema>({
    domain: 'Session',
    onMessage: (message) => {
      if (
        (message.action === 'Edited' ||
          message.action === 'Deleted' ||
          message.action === 'SessionStarted' ||
          message.action === 'SessionFinished' ||
          message.action === 'SwitchOnRoom' ||
          message.action === 'SwitchOffRoom') &&
        message.payload.id === sessionId
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data, sessionId });
};

export default WorkspaceSessionLoader;
