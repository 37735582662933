import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { botSettingRuleFilterValuesSchema } from '../schemas/botSettingRuleSchema';

const loadGlobalBotSettingsRulesFilterValues = async (params?: URLParams) => {
  const response = await api.get('/api/bot-settings-usage-rule/filter-values', {
    params,
  });

  return botSettingRuleFilterValuesSchema.parse(response.data);
};

export default loadGlobalBotSettingsRulesFilterValues;
