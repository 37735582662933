import { ActionWithRulesProps } from 'react-querybuilder';
import icon from '../../static/icons';
import Button from '../Button';
import styles from './styles.module.scss';

const QueryBuilderRemoveGroupAction = (props: ActionWithRulesProps) => {
  const { handleOnClick } = props;

  return (
    <Button
      danger
      size="extra-small"
      variant="transparent"
      icon={icon('trash', 16)}
      onClick={handleOnClick}
      className={styles.removeGroup}
    />
  );
};

export default QueryBuilderRemoveGroupAction;
