import { useTranslation } from 'react-i18next';
import icon from '../../../../static/icons';
import Button from '../../../Button';
import { useSelectContext } from '../../../Select/SelectContext';
import Tooltip from '../../../Tooltip';

interface Props {
  disabled: boolean;
}

const PageAddonListAdd = (props: Props) => {
  const { disabled } = props;

  const { t } = useTranslation();

  const {
    floating: { refs },
    getReferenceProps,
  } = useSelectContext();

  return (
    <Tooltip ref={refs.setReference} label={t('common.add')}>
      <Button
        size="small"
        variant="transparent"
        disabled={disabled}
        icon={icon('add', 16)}
        {...getReferenceProps()}
      />
    </Tooltip>
  );
};

export default PageAddonListAdd;
