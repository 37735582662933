import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import workspaceAccountNoteSchema from '../schemas/noteSchema';
import paginationSchemaNew from '../schemas/paginationSchemaNew';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
}

const loadWorkspaceAccountNotes = async (args: Args, params?: URLParams) => {
  const { workspaceId, accountId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/${accountId}/notes`,
    {
      params,
    }
  );

  return paginationSchemaNew(workspaceAccountNoteSchema).parse(response.data);
};

export default loadWorkspaceAccountNotes;
