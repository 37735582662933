import api from '../../services/api';
import URLParams from '../../types/URLParams';
import paginationSchema from '../schemas/paginationSchema';
import seatControlRuleSchema from '../schemas/seatControlRuleSchema';

const loadGlobalSeatControlRules = async (params?: URLParams) => {
  const response = await api.get('/api/seat-control', { params });

  return paginationSchema(seatControlRuleSchema).parse(response.data);
};

export default loadGlobalSeatControlRules;
