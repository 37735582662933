import { matchSorter } from 'match-sorter';
import { LobbySchema } from '../../api/schemas/lobbySchema';
import URLParams from '../../types/URLParams';
import {
  checkIsHasPlayers,
  checkIsFull,
  checkIsCritical,
  checkIsWarning,
  checkIsOk,
  checkIsEmpty,
  checkIsHU,
  checkIsSixMax,
  checkIsFullRing,
} from './helpers';

const getFilteredData = (rawData: LobbySchema[], urlParams: URLParams) => {
  let data = rawData;

  const {
    search,
    status,
    color,
    rooms,
    super_leagues,
    leagues,
    clubs,
    currencies,
    game_types,
    limits,
    table_sizes,
    player_count_ge,
    player_count_le,
    empty_seats_ge,
    empty_seats_le,
    maintenance_in,
    trainer_version_in,
    show_empty,
    hide_full,
  } = urlParams;

  if (!show_empty) data = data.filter((item) => checkIsHasPlayers(item));
  if (hide_full) data = data.filter((item) => !checkIsFull(item));

  if (search) {
    data = matchSorter(data, search, {
      keys: ['table.table_name', 'table.id', 'accounts.*.login'],
    });
  }

  if (status) {
    data = data.filter((item) => {
      if (status.includes('critical') && checkIsCritical(item)) return true;
      if (status.includes('warning') && checkIsWarning(item)) return true;
      if (status.includes('ok') && checkIsOk(item)) return true;
      if (status.includes('empty') && checkIsEmpty(item)) return true;
      /* if (status.includes('banned') && checkIsBanned(item)) return true; */
      return false;
    });
  }

  if (color) {
    data = data.filter((item) => color.split(',').includes(String(item.table.color)));
  }

  if (rooms) {
    data = data.filter((item) => rooms.split(',').includes(String(item.table.room)));
  }

  if (super_leagues) {
    data = data.filter((item) =>
      super_leagues.split(',').includes(String(item.table.extra_fields.super_league_id))
    );
  }

  if (leagues) {
    data = data.filter((item) => leagues.split(',').includes(item.table.league_id || '—'));
  }

  if (clubs) {
    data = data.filter((item) => clubs.split(',').includes(item.table.club_id));
  }

  if (currencies) {
    data = data.filter((item) => currencies.split(',').includes(item.table.currency));
  }

  if (game_types) {
    data = data.filter((item) => game_types.split(',').includes(String(item.table.game_type)));
  }

  if (limits) {
    data = data.filter((item) => limits.split(',').includes(String(item.table.comparative_limit)));
  }

  if (table_sizes) {
    data = data.filter((item) => {
      if (table_sizes.includes('HU') && checkIsHU(item)) return true;
      if (table_sizes.includes('six_max') && checkIsSixMax(item)) return true;
      if (table_sizes.includes('full_ring') && checkIsFullRing(item)) return true;

      return false;
    });
  }

  if (maintenance_in) {
    data = data.filter((item) => {
      const workspaces = new Set(item.accounts.map((account) => account.maintenance_id));

      if (maintenance_in.split(',').some((workspace) => workspaces.has(workspace))) return true;

      return false;
    });
  }

  if (trainer_version_in) {
    data = data.filter((item) => {
      const trainerVersions = new Set(item.accounts.map((account) => account.trainer_version));

      if (
        trainer_version_in.split(',').some((trainerVersion) => trainerVersions.has(trainerVersion))
      ) {
        return true;
      }

      return false;
    });
  }

  if (player_count_ge) {
    data = data.filter((item) => item.table.player_count >= Number(player_count_ge));
  }

  if (player_count_le) {
    data = data.filter((item) => item.table.player_count <= Number(player_count_le));
  }

  if (empty_seats_ge) {
    data = data.filter(
      (item) => item.table.max_no_of_seats - item.table.player_count >= Number(empty_seats_ge)
    );
  }

  if (empty_seats_le) {
    data = data.filter(
      (item) => item.table.max_no_of_seats - item.table.player_count <= Number(empty_seats_le)
    );
  }

  return data;
};

export default getFilteredData;
