import { z } from 'zod';
import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import accountTablesSearchSchema from '../schemas/accountTablesSearchSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
}

const loadWorkspaceAccountTablesSearch = async (args: Args) => {
  const { workspaceId, accountId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/lobby/account/${accountId}/search-tables`
  );

  return z.array(accountTablesSearchSchema).parse(response.data);
};

export default loadWorkspaceAccountTablesSearch;
