import {
  useHover,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  flip,
  shift,
  arrow,
  offset,
  autoUpdate,
  Placement,
  FloatingPortal,
  FloatingArrowProps,
  FloatingArrow,
} from '@floating-ui/react';
import clsx from 'clsx';
import { cloneElement, useState, ReactNode, ReactElement, useRef, forwardRef } from 'react';
import { mergeRefs } from 'react-merge-refs';
import useBreakpoints from '../../hooks/useBreakpoints';
import styles from './styles.module.scss';
import TooltipField from './TooltipField';
import TooltipFieldGroup from './TooltipFieldGroup';

interface Props {
  label: ReactNode;
  children: ReactElement;
  delay?: number;
  placement?: Placement;
  hideOnMobile?: boolean;
  className?: string;
  arrowOffset?: FloatingArrowProps['staticOffset'];
}

const Tooltip = Object.assign(
  forwardRef<HTMLElement, Props>((props, ref) => {
    const {
      label,
      delay,
      children,
      hideOnMobile = true,
      className,
      arrowOffset,
      placement: customPlacement = 'top',
    } = props;

    const { isDesktop, isMobile } = useBreakpoints();
    const [open, setOpen] = useState(false);

    const arrowRef = useRef(null);

    const { refs, context, floatingStyles } = useFloating({
      open,
      strategy: 'fixed',
      placement: customPlacement,
      middleware: [offset(8), flip(), shift({ padding: 4 }), arrow({ element: arrowRef })],
      onOpenChange: setOpen,
      whileElementsMounted: autoUpdate,
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
      useHover(context, {
        enabled: isDesktop,
        move: false,
        ...(delay && { delay: { open: delay * 1000, close: 0 } }),
      }),
      useClick(context, { enabled: !isDesktop }),
      useDismiss(context, { referencePress: true }),
    ]);

    const getVisible = () => {
      if (open && label) {
        if (isMobile && hideOnMobile) return false;

        return true;
      }

      return false;
    };

    return (
      <>
        {cloneElement(children, {
          ref: mergeRefs([refs.setReference, ref]),
          ...getReferenceProps(children.props),
        })}
        {getVisible() && (
          <FloatingPortal id="root">
            <div
              ref={refs.setFloating}
              className={clsx(styles.tooltip, className)}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <FloatingArrow
                ref={arrowRef}
                context={context}
                tipRadius={1}
                staticOffset={arrowOffset}
                className={styles.arrow}
              />
              {label}
            </div>
          </FloatingPortal>
        )}
      </>
    );
  }),
  {
    FieldGroup: TooltipFieldGroup,
    Field: TooltipField,
  }
);

export default Tooltip;
