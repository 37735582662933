import clsx from 'clsx';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import icon from '../../static/icons';
import Input, { Props } from '../Input';
import styles from './styles.module.scss';

const PasswordInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { autoComplete = 'off', ...rest } = props;

  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const togglePassword = () => setVisible((prev) => !prev);

  const getType = () => {
    if (visible) return 'text';
    if (autoComplete === 'off') return 'text';

    return 'password';
  };

  return (
    <Input
      type={getType()}
      label={t('common.password')}
      spellCheck={false}
      className={clsx(styles.input, !visible && autoComplete === 'off' && styles.hidden)}
      extra={
        <Input.Button onClick={togglePassword}>
          {visible ? icon('eyeOff', 20) : icon('eye', 20)}
        </Input.Button>
      }
      {...{ ...rest, ref }}
    />
  );
});

export default PasswordInput;
