import api from '../../services/api';
import { BotSettingSchema } from '../schemas/botSettingSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';

interface Args {
  botSettingId: BotSettingSchema['id'];
}

const deleteGlobalBotSetting = async (args: Args) => {
  const { botSettingId } = args;

  const response = await api.delete(`/api/bot-settings/${botSettingId}`);

  return emptyResponseSchema.parse(response.data);
};

export default deleteGlobalBotSetting;
