import { QueryKey } from '@tanstack/react-query';
import Id from '../types/Id';
import { WebSocketMessage } from '../types/WebSocketMessage';
import {
  queryClient,
  createInfinitePaginatedQueryData,
  updateInfinitePaginatedQueryData,
} from '../utils/queryClient';
import useWebSocketMessage from './useWebSocketMessage';
import useWebSocketMessageActionIds, { Action } from './useWebSocketMessageActionIds';

interface Config<T extends Id, U extends Id = T> {
  domain: WebSocketMessage<T>['domain'];
  queryKey: QueryKey;
  createAction?: Action<T>;
  updateAction?: Action<T>;
  deleteAction?: Action<T>;
  select: (data: T) => U;
  enabled?: (message: WebSocketMessage<T>) => boolean;
  ids?: U['id'][];
  append?: boolean;
}

const useWebSocketPaginatedActions = <T extends Id, U extends Id = T>(config: Config<T, U>) => {
  const {
    domain,
    queryKey,
    createAction = ['Created'],
    updateAction = ['Edited'],
    deleteAction = ['Deleted'],
    select,
    enabled,
    ids,
  } = config;

  const [createdIds, setCreatedIds] = useWebSocketMessageActionIds<T>({
    domain,
    action: [...createAction, 'Restored'],
    enabled,
    select: (payload) => select(payload).id,
  });

  const [deletedIds, setDeletedIds] = useWebSocketMessageActionIds<T>({
    domain,
    action: deleteAction,
    enabled,
    select: (payload) => select(payload).id,
  });

  useWebSocketMessage<T>({
    domain,
    enabled,
    onMessage: (message) => {
      const payload = select(message.payload);

      if ([...createAction].includes(message.action)) {
        if (!ids ? true : !ids.includes(payload.id)) {
          createInfinitePaginatedQueryData(queryKey, payload);
        }
      }

      if ([...updateAction, ...deleteAction, 'Restored'].includes(message.action)) {
        updateInfinitePaginatedQueryData(queryKey, payload, (item) => item.id === payload.id);
      }
    },
  });

  const onRefresh = () => {
    setCreatedIds([]);
    setDeletedIds([]);
    queryClient.resetQueries({ queryKey });
  };

  const checkIsCreated = (id: Id['id']) => createdIds.includes(id);

  return {
    createdAmount: createdIds.length,
    deletedAmount: deletedIds.length,
    onRefresh,
    checkIsCreated,
  };
};

export default useWebSocketPaginatedActions;
