import { z } from 'zod';
import dateTimeSchema from '../dateTimeSchema';
import fileSchema from '../fileSchema';
import trainerPokerRoomSchema from '../trainerPokerRoomSchema';
import workspaceSchema from '../workspaceSchema';

const GENDER = {
  UNKNOWN: 0,
  MALE: 1,
  FEMALE: 2,
} as const;

export const ONLINE_WALLET_TYPE = {
  UNKNOWN: 0,
  SKRILL: 1,
  NETELLER: 2,
  PAYPAL: 3,
  BTC: 4,
  ETH: 5,
  USDT_ERC20: 6,
  USDT_TRC20: 7,
  USDC_ERC20: 8,
  USDC_TRC20: 9,
} as const;

export const BANK_ACCOUNT_TYPE = {
  UNKNOWN: 0,
  CHECKING: 1,
  SAVING: 2,
  MONEY_MARKET: 3,
  CERTIFICATE_OF_DEPOSIT: 4,
  CARD: 5,
} as const;

export const DOCUMENT_TYPE = {
  UNKNOWN: 0,
  PASSPORT: 1,
  NATIONAL_ID_CARD: 2,
  DRIVING_LICENSE: 3,
  UTILITY_BILL: 4,
  BANK_STATEMENT: 5,
} as const;

export const PHOTO_TYPE = {
  UNKNOWN: 0,
  SELFIE: 1,
  SELFIE_WITH_IDENTITY_DOCUMENT: 2,
  SELFIE_WITH_BLANK_PAPER_SHEET: 3,
} as const;

export const SOCIAL_NETWORK_TYPE = {
  UNKNOWN: 0,
  FACEBOOK: 1,
  TWITTER: 2,
  TELEGRAM: 3,
  INSTAGRAM: 4,
  WECHAT: 5,
} as const;

const locationSchema = z.object({
  country_iso: z.string(),
  country_name: z.string(),
  city: z.string(),
});

const onlineWalletSchema = z.object({
  type: z.nativeEnum(ONLINE_WALLET_TYPE),
  login: z.string(),
  password: z.string().or(z.null()),
});

const bankAccountSchema = z.object({
  type: z.nativeEnum(BANK_ACCOUNT_TYPE),
  name: z.string(),
  banking_digits: z.string(),
  fullname: z.string(),
});

const documentSchema = z.object({
  type: z.nativeEnum(DOCUMENT_TYPE),
  issued: z.string(),
  expire: z.string().or(z.null()),
  files: z.array(fileSchema),
});

const photoSchema = z.object({
  type: z.nativeEnum(PHOTO_TYPE),
  files: z.array(fileSchema),
});

const socialNetworkSchema = z.object({
  type: z.nativeEnum(SOCIAL_NETWORK_TYPE),
  link: z.string(),
  login: z.string(),
  password: z.string(),
});

export const identityMinimalRentalPeriodSchema = z.number();
export const identityMonthlyCostSchema = z.number();

export const identityCommonSchema = z.object({
  id: z.string(),
  owner: workspaceSchema, // TODO: там еще есть participiants, кажется это лишнее
  buyer: workspaceSchema.or(z.null()), // TODO: там еще есть participiants, кажется это лишнее
  first_name: z.string(),
  last_name: z.string(),
  gender: z.nativeEnum(GENDER),
  date_of_birth: z.string(),
  location: locationSchema,
  minimal_rental_period: identityMinimalRentalPeriodSchema.or(z.null()),
  monthly_cost: identityMonthlyCostSchema.or(z.null()),
  online_wallets: z.array(onlineWalletSchema),
  phones: z.array(z.string()),
  bank_accounts: z.array(bankAccountSchema),
  documents: z.array(documentSchema),
  photos: z.array(photoSchema),
  social_networks: z.array(socialNetworkSchema),
  rooms: z.array(trainerPokerRoomSchema),
  on_market: z.boolean(),
  channel: z.boolean(),
  description: z.string(),
  is_deleted: z.boolean(),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export const identityCommonFilterValuesSchema = z.object({
  country: z.array(
    z.object({
      id: identityCommonSchema.shape.location.shape.country_name,
      name: identityCommonSchema.shape.location.shape.country_name,
    })
  ),
  minimal_rental_period: z.object({ min: z.number(), max: z.number() }),
  monthly_cost: z.object({ min: z.number(), max: z.number() }),
  online_wallets: z.array(identityCommonSchema.shape.online_wallets.element.shape.type),
  documents: z.array(identityCommonSchema.shape.documents.element.shape.type),
  photos: z.array(identityCommonSchema.shape.photos.element.shape.type),
});
