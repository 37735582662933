import { TYPE } from '../../api/schemas/session';
import Badge, { Color } from '../../components/Badge';
import { TYPE_LABELS } from './helpers';
import { CommonSessionSchema } from './types';

const TYPE_COLORS: Record<CommonSessionSchema['session_type'], Color> = {
  [TYPE.UNKNOWN]: 'grey',
  [TYPE.GAME]: 'blue',
  [TYPE.MINING]: 'green',
};

interface Props {
  data: CommonSessionSchema;
}

const SessionType = (props: Props) => {
  const { data } = props;

  const { session_type } = data;

  return <Badge color={TYPE_COLORS[session_type]}>{TYPE_LABELS[session_type]}</Badge>;
};

export default SessionType;
