import { z } from 'zod';
import accountCommonIdentitySchema from './accountCommonIdentitySchema';
import { accountCommonFilterValuesSchema, accountCommonSchema } from '.';

const globalAccountSchema = accountCommonSchema.extend({
  identity: accountCommonIdentitySchema, // cyclic
  mga_id: z.string(),
  user: accountCommonSchema.shape.user.or(z.null()),
  /* host: z.string().or(z.null()),// не используется */
  /* port: z.number().or(z.null()),// не используется */
  /* allocation_of_limits: accountCommonSchema.shape.allocation_of_limits, // не используется */
  /* pool_type: accountCommonSchema.shape.pool_type, // не используется */
  /* pools: z.array(z.unknown()), // не используется */
});

export const globalAccountFilterValuesSchema = accountCommonFilterValuesSchema.extend({
  maintenance: z.array(globalAccountSchema.shape.maintenance.pick({ id: true, name: true })),
});

export type GlobalAccountSchema = z.infer<typeof globalAccountSchema>;

export default globalAccountSchema;
