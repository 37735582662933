import api from '../../services/api';
import { CurrentUserPasswordSchema } from '../schemas/currentUserSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { HashSchema } from '../schemas/hashSchema';

interface Payload {
  password: CurrentUserPasswordSchema;
}

interface Args {
  hash: HashSchema;
  payload: Payload;
}

const receiveRecoveryPassword = async (args: Args) => {
  const { hash, payload } = args;

  const response = await api.post(`/api/recovery-password/${hash}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default receiveRecoveryPassword;
