import { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import URLParams from '../types/URLParams';

export type Navigate = (to: string, config?: { params: boolean }) => void;

export type Params = Record<string, unknown>;

export const convertToUrlParams = (params: Params) => {
  const keys = Object.keys(params);
  const values = Object.values(params);

  return keys.reduce<URLParams>((acc, curr, index) => {
    const value = values[index];

    if (typeof value === 'number') {
      acc[curr] = String(value);
    }

    if (typeof value === 'string' && value !== '') {
      acc[curr] = value;
    }

    if (typeof value === 'boolean' && value === true) {
      acc[curr] = String(value);
    }

    if (Array.isArray(value) && value.length) {
      acc[curr] = value.join(',');
    }

    return acc;
  }, {});
};

const useURL = () => {
  const routerNavigate = useNavigate();
  const { search, pathname } = useLocation();

  const getRouteWithUrlParams = (to: string) => `${to}${search}`;

  const navigate: Navigate = (to, config) => {
    if (config?.params === false) {
      routerNavigate(to);
    } else {
      routerNavigate(getRouteWithUrlParams(to));
    }
  };

  const urlParams = useMemo(() => Object.fromEntries(new URLSearchParams(search)), [search]);

  const setURLParams = (params: Params, config = { merge: true }) => {
    const mergedParams = { ...(config.merge ? urlParams : {}), ...params };

    const newParams = convertToUrlParams(mergedParams);

    const newURLParams = new URLSearchParams(newParams).toString();

    navigate(`${pathname}?${newURLParams}`, { params: false });
  };

  const clearURLParams = () => setURLParams({}, { merge: false });

  return { urlParams, setURLParams, clearURLParams, getRouteWithUrlParams, navigate };
};

export default useURL;
