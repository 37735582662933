import { Payload } from '../../components/Notes';
import api from '../../services/api';
import { AgentSchema } from '../schemas/agentSchema';
import noteSchema from '../schemas/noteSchema';

interface Args {
  agentId: AgentSchema['id'];
  payload: Payload;
}

const createGlobalAgentNote = async (args: Args) => {
  const { agentId, payload } = args;

  const response = await api.post(`/api/agent/${agentId}/notes`, payload);

  return noteSchema.pick({ id: true }).parse(response.data);
};

export default createGlobalAgentNote;
