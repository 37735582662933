import { useTranslation } from 'react-i18next';
import { kickWorkspaceClubMember } from '../../api';
import { TYPE } from '../../api/schemas/workspaceClubManagerSchema';
import { WorkspaceClubMemberSchema } from '../../api/schemas/workspaceClubMemberSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { presets } from '../../components/Dropdown';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';
import notify from '../../utils/notify';

interface Args {
  data: WorkspaceClubMemberSchema;
  workspaceId: WorkspaceSchema['id'];
}

const useDropdown = (args: Args) => {
  const { data, workspaceId } = args;

  const { id, player_id, kick, manager, is_deleted } = data;

  const { t } = useTranslation();
  const { navigate } = useURL();

  const agent = manager.type === TYPE.AGENT;

  return {
    title: player_id,
    disabled: is_deleted,
    options: {
      copy: presets.copyId(id),
      deposit: {
        icon: icon('deposit', 20),
        label: t('common.deposit'),
        onClick: () => navigate(routes.workspaceClubMemberDeposit({ workspaceId, memberId: id })),
      },
      withdraw: {
        icon: icon('withdraw', 20),
        label: t('common.withdraw'),
        onClick: () => navigate(routes.workspaceClubMemberWithdraw({ workspaceId, memberId: id })),
      },
      divider: { divider: true },
      kick: {
        icon: icon('minusCircle', 20),
        label: t('common.kick'),
        danger: true,
        confirm: true,
        confirmText: t('common.kick'),
        disabled: kick || agent,
        ...(agent && { tooltip: t('sentences.agents_cant_kick_members') }),
        onClick: async () => {
          await kickWorkspaceClubMember({ workspaceId, memberId: id });

          notify('success', {
            title: t('sentences.member_has_been_added_to_the_kick_list'),
          });
        },
      },
    },
  };
};

export default useDropdown;
