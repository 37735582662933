import icon from '../../../static/icons';
import Button from '../../Button';
import styles from './styles.module.scss';

interface Props {
  onClick: () => void;
}

const InputClearButton = (props: Props) => {
  const { onClick } = props;

  return (
    <div className={styles.container}>
      <Button size="extra-small" variant="transparent" icon={icon('cross', 16)} onClick={onClick} />
    </div>
  );
};

export default InputClearButton;
