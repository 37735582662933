import { BotSettingSchema } from '../../api/schemas/botSettingSchema';
import PageDetailLink from '../../components/PageDetailLink';
import routes from '../../config/routes';

interface Props {
  data: BotSettingSchema;
}

const GlobalBotSettingDetailLink = (props: Props) => {
  const { data } = props;

  const { id, name } = data;

  return <PageDetailLink label={name} to={routes.globalBotSetting({ botSettingId: id })} />;
};

export default GlobalBotSettingDetailLink;
