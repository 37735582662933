import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { STATUS } from '../../api/schemas/channelSchema';
import { COLUMNS, WorkspaceStageSchema } from '../../api/schemas/stage/workspaceStageSchema';
import FadingText from '../../components/FadingText';
import Join from '../../components/Join';
import PrettyNumber from '../../components/PrettyNumber';
import Tag from '../../components/Tag';
import AccountPilotButton from '../../features/account/AccountPilotButton';
import AccountPilotView from '../../features/account/AccountPilotView';
import AccountStatus from '../../features/account/AccountStatus';
import {
  PLAYER_TYPE_LABELS,
  SEATING_PREFERENCE_LABELS,
  TABLE_SIZE_LABELS,
} from '../../features/account/helpers';
import { getTitle } from '../../features/session/helpers';
import { getAccountMetrics } from '../../features/workspace-account/helpers';
import WorkspaceAccountLobbyBalance from '../../features/workspace-account/WorkspaceAccountLobbyBalance';
import WorkspaceAccountNetWin from '../../features/workspace-account/WorkspaceAccountNetWin';
import WorkspaceAccountTags from '../../features/workspace-account/WorkspaceAccountTags';
import { getGender, getIdentityFullName } from '../../features/workspace-identity/helpers';
import WorkspaceIdentityCountry from '../../features/workspace-identity/WorkspaceIdentityCountry';
import WorkspaceIdentityDocuments from '../../features/workspace-identity/WorkspaceIdentityDocuments';
import WorkspaceIdentityPhotos from '../../features/workspace-identity/WorkspaceIdentityPhotos';
import WorkspaceIdentitySocialNetworks from '../../features/workspace-identity/WorkspaceIdentitySocialNetworks';
import WorkspaceIdentityWallets from '../../features/workspace-identity/WorkspaceIdentityWallets';
import { t } from '../../i18n';
import icon from '../../static/icons';
import URLParams from '../../types/URLParams';
import { convertCentsToDollars, renderNumber } from '../../utils/data';
import { formatAbsoluteDate, formatRelativeDate } from '../../utils/date';
import {
  checkBoolean,
  checkDate,
  checkIn,
  checkInEnum,
  checkInId,
  checkHasParams,
  checkRange,
} from '../../utils/filter';
import {
  TRAINER_GAME_TYPE_LABELS,
  TRAINER_LIMITS_LABELS,
  TRAINER_POKER_ROOM_LABELS,
} from '../../utils/trainer';
import styles from './styles.module.scss';

export const getContent = (data: WorkspaceAccountSchema) => {
  const {
    clubs,
    game_types,
    stopped_clubs,
    room,
    comparative_limits,
    max_tables,
    pid,
    geo,
    maintenance,
    table_sizes,
    bot_settings,
    channels,
    identity,
    user,
    metrics,
    balance,
    email,
    deals,
    affiliate_reference_number,
    display_name,
    trainer_version,
    notes_count,
    strategy_profile,
    next_session,
    last_online,
    last_played,
    modified_on,
    created_on,
  } = data;

  const { hands_played, win_rate_bb } = getAccountMetrics(metrics);

  const renderClubs = (variant: 'name' | 'code') => {
    if (clubs.length) {
      const stoppedClubsIds = stopped_clubs.map((item) => item.id);

      return (
        <Tag.Group
          danger={(index) => clubs.slice(index).some((item) => stoppedClubsIds.includes(item.id))}
          tooltip={(index) => (
            <Join>
              {clubs.slice(index).map((item) => (
                <span
                  key={item.id}
                  {...(stoppedClubsIds.includes(item.id) && { className: styles.danger })}
                >
                  {variant === 'name' ? item.name : item.code}
                </span>
              ))}
            </Join>
          )}
        >
          {clubs.map((item) => (
            <Tag key={item.id} danger={stoppedClubsIds.includes(item.id)}>
              {variant === 'name' ? item.name : item.code}
            </Tag>
          ))}
        </Tag.Group>
      );
    }

    return '—';
  };

  const renderGameTypes = () => {
    if (game_types.length) {
      return (
        <Tag.Group
          tooltip={(index) =>
            game_types
              .slice(index)
              .map((item) => TRAINER_GAME_TYPE_LABELS[item])
              .join(', ')
          }
        >
          {game_types.map((item) => (
            <Tag key={item}>{TRAINER_GAME_TYPE_LABELS[item]}</Tag>
          ))}
        </Tag.Group>
      );
    }

    return '—';
  };

  const renderLimits = () => {
    if (comparative_limits.length) {
      return (
        <Tag.Group
          tooltip={(index) =>
            comparative_limits
              .slice(index)
              .map((item) => TRAINER_LIMITS_LABELS[item])
              .join(', ')
          }
        >
          {comparative_limits.map((item) => (
            <Tag key={item}>{TRAINER_LIMITS_LABELS[item]}</Tag>
          ))}
        </Tag.Group>
      );
    }

    return '—';
  };

  const renderTableSizes = () => {
    if (table_sizes.length) {
      return (
        <Tag.Group
          tooltip={(index) =>
            table_sizes
              .slice(index)
              .map((item) => TABLE_SIZE_LABELS[item])
              .join(', ')
          }
        >
          {table_sizes.map((item) => (
            <Tag key={item}>{TABLE_SIZE_LABELS[item]}</Tag>
          ))}
        </Tag.Group>
      );
    }

    return '—';
  };

  const renderChannels = () => {
    if (channels.length) {
      return (
        <Tag.Group
          tooltip={(index) =>
            channels
              .slice(index)
              .map((item) => item.host)
              .join(', ')
          }
        >
          {channels.map((item) => {
            const inactive =
              channels[0].status === STATUS.UNKNOWN || channels[0].status === STATUS.INACTIVE;

            return (
              <Tag key={item.id} secondary={inactive}>
                {item.host}
              </Tag>
            );
          })}
        </Tag.Group>
      );
    }

    return '—';
  };

  const renderBotSettings = () => {
    if (bot_settings.length) {
      return (
        <Tag.Group
          tooltip={(index) =>
            bot_settings
              .slice(index)
              .map((item) => item.name)
              .join(', ')
          }
        >
          {bot_settings.map((item) => (
            <Tag key={item.id}>{item.name}</Tag>
          ))}
        </Tag.Group>
      );
    }

    return '—';
  };

  const renderIdentityPhones = () => {
    if (identity?.phones.length) {
      const { phones } = identity;

      return (
        <Tag.Group
          tooltip={(index) =>
            phones
              .slice(index)
              .map((item) => item)
              .join(', ')
          }
        >
          {phones.map((item, index) => (
            <Tag key={index}>{item}</Tag>
          ))}
        </Tag.Group>
      );
    }

    return '—';
  };

  const renderIdentityBankAccounts = () => {
    if (identity?.bank_accounts.length) {
      const { bank_accounts } = identity;

      return (
        <Tag.Group
          tooltip={(index) =>
            bank_accounts
              .slice(index)
              .map((item) => item.banking_digits)
              .join(', ')
          }
        >
          {bank_accounts.map((item, index) => (
            <Tag key={index}>{item.banking_digits}</Tag>
          ))}
        </Tag.Group>
      );
    }

    return '—';
  };

  const renderClubBalance = () => {
    if (balance) {
      const { club_balance } = balance;

      const getLabel = (clubBalance: (typeof club_balance)[number]) =>
        clubBalance.club_name || clubBalance.club_id;

      return (
        <Tag.Group
          tooltip={(index) =>
            club_balance
              .slice(index)
              .map((item) => getLabel(item))
              .join(', ')
          }
        >
          {club_balance.map((item) => (
            <Tag
              key={item.id}
              caption={
                <Tag.Caption>
                  <PrettyNumber>{convertCentsToDollars(item.amount)}</PrettyNumber>
                </Tag.Caption>
              }
            >
              {getLabel(item)}
            </Tag>
          ))}
        </Tag.Group>
      );
    }

    return '—';
  };

  const renderDeals = () => {
    if (deals.length) {
      return (
        <Tag.Group
          tooltip={(index) =>
            deals
              .slice(index)
              .map((item) => item.code)
              .join(', ')
          }
        >
          {deals.map((item) => (
            <Tag key={item.id}>{item.code}</Tag>
          ))}
        </Tag.Group>
      );
    }

    return '—';
  };

  return {
    [COLUMNS.UNKNOWN]: '—',
    [COLUMNS.CREATED_ON]: formatRelativeDate(created_on),
    [COLUMNS.MODIFIED_ON]: formatRelativeDate(modified_on),
    [COLUMNS.ROOM]: <FadingText text={TRAINER_POKER_ROOM_LABELS[room]} />,
    [COLUMNS.CLUB_NAME]: renderClubs('name'),
    [COLUMNS.GAME_TYPES]: renderGameTypes(),
    [COLUMNS.LIMITS]: renderLimits(),
    [COLUMNS.TABLE_SIZES]: renderTableSizes(),
    [COLUMNS.MAX_TABLES]: max_tables,
    [COLUMNS.IS_ONLINE]: <AccountStatus data={data} />,
    [COLUMNS.PID]: <FadingText text={pid || '—'} />,
    [COLUMNS.GEO]: geo ? icon('check', 16) : icon('cross', 16),
    [COLUMNS.CHANNELS]: renderChannels(),
    [COLUMNS.HANDS_PLAYED]: hands_played,
    [COLUMNS.NET_WIN_SUM]: <WorkspaceAccountNetWin data={data} />,
    [COLUMNS.WIN_RATE_BB]: win_rate_bb,
    [COLUMNS.TAGS]: <WorkspaceAccountTags data={data} />,
    [COLUMNS.BOT_SETTINGS]: renderBotSettings(),
    [COLUMNS.IDENTITY]: identity ? icon('check', 16) : icon('cross', 16),
    [COLUMNS.AFFILIATE_REFERENCE_NUMBER]: <FadingText text={affiliate_reference_number || '—'} />,
    [COLUMNS.LAST_ONLINE]: formatRelativeDate(last_online),
    [COLUMNS.PILOT_STATUS]: (
      <>
        <AccountPilotButton data={data} workspaceId={maintenance.id} />
        <AccountPilotView data={data} />
      </>
    ),
    [COLUMNS.LAST_PLAYED]: formatRelativeDate(last_played),
    [COLUMNS.USER]: <FadingText text={user?.username || '—'} />,
    [COLUMNS.CLUB_CODE]: renderClubs('code'),
    [COLUMNS.NEXT_SESSION]: next_session
      ? getTitle({ start: next_session.start, end: next_session.end })
      : '—',
    [COLUMNS.IDENTITY_BANK_ACCOUNTS]: renderIdentityBankAccounts(),
    [COLUMNS.IDENTITY_DATE_OF_BIRTH]: identity ? formatAbsoluteDate(identity.date_of_birth) : '—',
    [COLUMNS.IDENTITY_DOCUMENTS]: identity ? (
      <WorkspaceIdentityDocuments documents={identity.documents.map((item) => item.type)} />
    ) : (
      '—'
    ),
    [COLUMNS.IDENTITY_FULL_NAME]: (
      <FadingText text={identity ? getIdentityFullName(identity) : '—'} />
    ),
    [COLUMNS.IDENTITY_GENDER]: identity ? getGender(identity.gender) : '—',
    [COLUMNS.IDENTITY_LOCATION]: identity ? <WorkspaceIdentityCountry data={identity} /> : '—',
    [COLUMNS.IDENTITY_ONLINE_WALLETS]: identity ? (
      <WorkspaceIdentityWallets wallets={identity.online_wallets.map((item) => item.type)} />
    ) : (
      '—'
    ),
    [COLUMNS.IDENTITY_PHONES]: renderIdentityPhones(),
    [COLUMNS.IDENTITY_PHOTOS]: identity ? (
      <WorkspaceIdentityPhotos photos={identity.photos.map((item) => item.type)} />
    ) : (
      '—'
    ),
    [COLUMNS.IDENTITY_SOCIAL_NETWORKS]: identity ? (
      <WorkspaceIdentitySocialNetworks
        socialNetworks={identity.social_networks.map((item) => item.type)}
      />
    ) : (
      '—'
    ),
    [COLUMNS.TRAINER_VERSION]: trainer_version || '—',
    [COLUMNS.NOTES]: notes_count ? icon('check', 16) : icon('cross', 16),
    [COLUMNS.BALANCE_CLUB]: renderClubBalance(),
    [COLUMNS.BALANCE_LOBBY]: <WorkspaceAccountLobbyBalance data={data} />,
    [COLUMNS.DISPLAY_NAME]: <FadingText text={display_name || '—'} />,
    [COLUMNS.EMAIL]: <FadingText text={email || '—'} />,
    [COLUMNS.VPIP]: renderNumber(data.vpip),
    [COLUMNS.DEALS]: renderDeals(),
    [COLUMNS.STRATEGY_PROFILE_BUYIN_BB]: strategy_profile.buyin_bb,
    [COLUMNS.STRATEGY_PROFILE_REBUY_BELOW_BB]: strategy_profile.rebuy_below_bb,
    [COLUMNS.STRATEGY_PROFILE_STOP_LOSE_BB]: strategy_profile.stop_lose_bb,
    [COLUMNS.STRATEGY_PROFILE_STOP_WIN_BB]: strategy_profile.stop_win_bb,
    [COLUMNS.STRATEGY_PROFILE_MAX_SWING_BB]: strategy_profile.max_swing_bb,
    [COLUMNS.STRATEGY_PROFILE_STOP_LOSE_TABLE_BB]: strategy_profile.stop_lose_table_bb,
    [COLUMNS.STRATEGY_PROFILE_STOP_WIN_TABLE_BB]: strategy_profile.stop_win_table_bb,
    [COLUMNS.STRATEGY_PROFILE_MAX_STACK_SIZE_BB]: strategy_profile.max_stack_size_bb,
    [COLUMNS.STRATEGY_PROFILE_EFFECTIVE_STACK_LIMIT_BB]: strategy_profile.effective_stack_limit_bb,
    [COLUMNS.STRATEGY_PROFILE_TABLE_MIN_HANDS]: strategy_profile.table_min_hands,
    [COLUMNS.STRATEGY_PROFILE_BUYIN_BB_MIN]: strategy_profile.buyin_bb_min,
    [COLUMNS.STRATEGY_PROFILE_BUYIN_BB_MAX]: strategy_profile.buyin_bb_max,
    [COLUMNS.STRATEGY_PROFILE_BUYIN_BB_STRADDLE_TABLE_MIN]:
      strategy_profile.buyin_bb_straddle_table_min,
    [COLUMNS.STRATEGY_PROFILE_BUYIN_BB_STRADDLE_TABLE_MAX]:
      strategy_profile.buyin_bb_straddle_table_max,
    [COLUMNS.STRATEGY_PROFILE_REBUY_BELOW_BB_STRADDLE_TABLE]:
      strategy_profile.buyin_step_bb_straddle_table,
    [COLUMNS.STRATEGY_PROFILE_PLAYER_TYPE]: PLAYER_TYPE_LABELS[strategy_profile.player_type],
    [COLUMNS.STRATEGY_PROFILE_BUYIN_STEP_BB]: strategy_profile.buyin_step_bb,
    [COLUMNS.STRATEGY_PROFILE_BUYIN_STEP_BB_STRADDLE_TABLE]:
      strategy_profile.buyin_step_bb_straddle_table,
    [COLUMNS.STRATEGY_PROFILE_WAIT_FOR_BIG_BLIND]: strategy_profile.wait_for_big_blind
      ? t('common.true')
      : t('common.false'),
    [COLUMNS.STRATEGY_PROFILE_SEATING_PREFERENCE]:
      SEATING_PREFERENCE_LABELS[strategy_profile.seating_preference],
  };
};

export const COLUMNS_WIDTHS: Record<WorkspaceStageSchema['columns'][number], number> = {
  [COLUMNS.UNKNOWN]: 64,
  [COLUMNS.CREATED_ON]: 120,
  [COLUMNS.MODIFIED_ON]: 120,
  [COLUMNS.ROOM]: 96,
  [COLUMNS.CLUB_NAME]: 165,
  [COLUMNS.GAME_TYPES]: 96,
  [COLUMNS.LIMITS]: 96,
  [COLUMNS.TABLE_SIZES]: 140,
  [COLUMNS.MAX_TABLES]: 96,
  [COLUMNS.IS_ONLINE]: 120,
  [COLUMNS.PID]: 96,
  [COLUMNS.GEO]: 104,
  [COLUMNS.CHANNELS]: 140,
  [COLUMNS.HANDS_PLAYED]: 64,
  [COLUMNS.NET_WIN_SUM]: 120,
  [COLUMNS.WIN_RATE_BB]: 72,
  [COLUMNS.TAGS]: 80,
  [COLUMNS.BOT_SETTINGS]: 190,
  [COLUMNS.IDENTITY]: 72,
  [COLUMNS.AFFILIATE_REFERENCE_NUMBER]: 140,
  [COLUMNS.LAST_ONLINE]: 120,
  [COLUMNS.PILOT_STATUS]: 72,
  [COLUMNS.LAST_PLAYED]: 120,
  [COLUMNS.USER]: 150,
  [COLUMNS.CLUB_CODE]: 96,
  [COLUMNS.NEXT_SESSION]: 220,
  [COLUMNS.IDENTITY_BANK_ACCOUNTS]: 154,
  [COLUMNS.IDENTITY_DATE_OF_BIRTH]: 140,
  [COLUMNS.IDENTITY_DOCUMENTS]: 132,
  [COLUMNS.IDENTITY_FULL_NAME]: 150,
  [COLUMNS.IDENTITY_GENDER]: 104,
  [COLUMNS.IDENTITY_LOCATION]: 112,
  [COLUMNS.IDENTITY_ONLINE_WALLETS]: 150,
  [COLUMNS.IDENTITY_PHONES]: 140,
  [COLUMNS.IDENTITY_PHOTOS]: 104,
  [COLUMNS.IDENTITY_SOCIAL_NETWORKS]: 160,
  [COLUMNS.TRAINER_VERSION]: 104,
  [COLUMNS.NOTES]: 64,
  [COLUMNS.BALANCE_CLUB]: 120,
  [COLUMNS.BALANCE_LOBBY]: 120,
  [COLUMNS.DISPLAY_NAME]: 120,
  [COLUMNS.EMAIL]: 150,
  [COLUMNS.VPIP]: 64,
  [COLUMNS.DEALS]: 96,
  [COLUMNS.STRATEGY_PROFILE_BUYIN_BB]: 96,
  [COLUMNS.STRATEGY_PROFILE_REBUY_BELOW_BB]: 160,
  [COLUMNS.STRATEGY_PROFILE_STOP_LOSE_BB]: 112,
  [COLUMNS.STRATEGY_PROFILE_STOP_WIN_BB]: 112,
  [COLUMNS.STRATEGY_PROFILE_MAX_SWING_BB]: 120,
  [COLUMNS.STRATEGY_PROFILE_STOP_LOSE_TABLE_BB]: 160,
  [COLUMNS.STRATEGY_PROFILE_STOP_WIN_TABLE_BB]: 160,
  [COLUMNS.STRATEGY_PROFILE_MAX_STACK_SIZE_BB]: 140,
  [COLUMNS.STRATEGY_PROFILE_EFFECTIVE_STACK_LIMIT_BB]: 180,
  [COLUMNS.STRATEGY_PROFILE_TABLE_MIN_HANDS]: 130,
  [COLUMNS.STRATEGY_PROFILE_BUYIN_BB_MIN]: 140,
  [COLUMNS.STRATEGY_PROFILE_BUYIN_BB_MAX]: 140,
  [COLUMNS.STRATEGY_PROFILE_BUYIN_BB_STRADDLE_TABLE_MIN]: 240,
  [COLUMNS.STRATEGY_PROFILE_BUYIN_BB_STRADDLE_TABLE_MAX]: 240,
  [COLUMNS.STRATEGY_PROFILE_REBUY_BELOW_BB_STRADDLE_TABLE]: 250,
  [COLUMNS.STRATEGY_PROFILE_PLAYER_TYPE]: 96,
  [COLUMNS.STRATEGY_PROFILE_BUYIN_STEP_BB]: 120,
  [COLUMNS.STRATEGY_PROFILE_BUYIN_STEP_BB_STRADDLE_TABLE]: 230,
  [COLUMNS.STRATEGY_PROFILE_WAIT_FOR_BIG_BLIND]: 130,
  [COLUMNS.STRATEGY_PROFILE_SEATING_PREFERENCE]: 150,
};

export const checkMessage = (urlParams: URLParams, data: WorkspaceAccountSchema) => {
  if (checkHasParams(urlParams)) return true;

  if (
    checkInId(urlParams, 'club_in', data.clubs) &&
    checkInEnum(urlParams, 'game_types_in', data.game_types) &&
    checkInEnum(urlParams, 'comparative_limits_in', data.comparative_limits) &&
    checkRange(urlParams, ['max_tables_ge', 'max_tables_le'], data.max_tables) &&
    checkIn(urlParams, 'pilot_status_in', data.pilot_status) &&
    checkInId(urlParams, 'tag_in', data.tags) &&
    checkIn(urlParams, 'user_in', data.user?.id) &&
    checkInId(urlParams, 'bot_settings_in', data.bot_settings) &&
    checkRange(urlParams, ['hands_played_ge', 'hands_played_le'], data.metrics.hands_played) &&
    checkRange(urlParams, ['net_win_sum_ge', 'net_win_sum_le'], data.metrics.net_win_sum) &&
    checkBoolean(urlParams, 'has_next_session', data.next_session) &&
    checkDate(urlParams, ['last_online_ge', 'last_online_le'], data.last_online) &&
    checkDate(urlParams, ['last_played_ge', 'last_played_le'], data.last_played) &&
    checkDate(urlParams, ['updated_ge', 'updated_le'], data.modified_on) &&
    checkDate(urlParams, ['created_ge', 'created_le'], data.created_on) &&
    checkBoolean(urlParams, 'show_no_deals', !data.deals.length)
  ) {
    return true;
  }

  return false;
};
