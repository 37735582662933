import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
  scrolled?: boolean;
}

const DialogTop = (props: Props) => {
  const { children, className, scrolled } = props;

  return (
    <div className={clsx(styles.container, scrolled && styles.scrolled, className)}>{children}</div>
  );
};

export default DialogTop;
