import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LobbySchema } from '../../../api/schemas/lobbySchema';
import { COLOR } from '../../../api/schemas/lobbyTableSchema';
import Filter from '../../../components/Filter';
import useFilter from '../../../hooks/useFilter';
import {
  TRAINER_GAME_TYPE_LABELS,
  TRAINER_LIMITS_LABELS,
  TRAINER_POKER_ROOM_LABELS,
} from '../../../utils/trainer';
import getCounters from '../getCounters';
import {
  statuses,
  tableSizes,
  tableSizesLabel,
  COLOR_LABELS,
  STATUS_DOT_CLASS_NAMES,
  COLOR_DOT_CLASS_NAMES,
} from '../helpers';
import styles from '../styles.module.scss';

type Rooms = LobbySchema['table']['room'][];
type Limits = LobbySchema['table']['comparative_limit'][];
type GameTypes = LobbySchema['table']['game_type'][];

interface Fields {
  status: string[];
  color: string[];
  rooms: string[];
  super_leagues: string[];
  leagues: string[];
  clubs: string[];
  currencies: string[];
  game_types: string[];
  limits: string[];
  table_sizes: string[];
  player_count_ge: string;
  player_count_le: string;
  empty_seats_ge: string;
  empty_seats_le: string;
  maintenance_in: string[];
  trainer_version_in: string[];
  show_empty: boolean;
  hide_full: boolean;
}

interface Props {
  data?: LobbySchema[];
  loading: boolean;
  counters: ReturnType<typeof getCounters>;
  total?: number;
}

const GlobalLobbyFilter = (props: Props) => {
  const { data, loading, counters, total } = props;

  const { t } = useTranslation();

  const filter = useFilter<Fields>({
    status: 'multi',
    color: 'multi',
    rooms: 'multi',
    super_leagues: 'multi',
    leagues: 'multi',
    clubs: 'multi',
    currencies: 'multi',
    game_types: 'multi',
    limits: 'multi',
    table_sizes: 'multi',
    player_count_ge: 'single',
    player_count_le: 'single',
    empty_seats_ge: 'single',
    empty_seats_le: 'single',
    maintenance_in: 'multi',
    trainer_version_in: 'multi',
    show_empty: 'toggle',
    hide_full: 'toggle',
  });

  const { values, setValue, clearValue } = filter;

  const {
    status,
    color,
    rooms,
    super_leagues,
    leagues,
    clubs,
    currencies,
    game_types,
    limits,
    table_sizes,
    player_count_ge,
    player_count_le,
    empty_seats_ge,
    empty_seats_le,
    maintenance_in,
    trainer_version_in,
    show_empty,
    hide_full,
  } = values;

  const [roomsData, setRoomsData] = useState<Rooms>([]);
  const [gameTypesData, setGameTypesData] = useState<GameTypes>([]);
  const [limitsData, setLimitsData] = useState<Limits>([]);

  useEffect(() => {
    let uniqPokerRooms: Rooms = [];
    let uniqGameTypes: GameTypes = [];
    let uniqLimits: Limits = [];

    data?.forEach((item) => {
      if (!uniqPokerRooms.find((el) => el === item.table.room)) {
        uniqPokerRooms = [...uniqPokerRooms, item.table.room];
      }

      if (!uniqGameTypes.find((el) => el === item.table.game_type)) {
        uniqGameTypes = [...uniqGameTypes, item.table.game_type];
      }

      if (!uniqLimits.find((el) => el === item.table.comparative_limit)) {
        uniqLimits = [...uniqLimits, item.table.comparative_limit];
      }
    });

    const sortedLimits = [...uniqLimits].sort((a, b) =>
      TRAINER_LIMITS_LABELS[a].localeCompare(TRAINER_LIMITS_LABELS[b], undefined, { numeric: true })
    );

    setRoomsData(uniqPokerRooms);
    setGameTypesData(uniqGameTypes);
    setLimitsData(sortedLimits);
  }, [data]);

  const filterProps = { total, filter };

  return (
    <Filter {...filterProps}>
      {loading ? (
        <Filter.Placeholder />
      ) : (
        <>
          <div>
            <Filter.Collapse
              label={t('common.table_status')}
              count={status.length}
              onClear={clearValue('status')}
            >
              <Filter.MultiCheckbox
                config={statuses
                  .map((item) => ({
                    value: item,
                    label: t(`common.${item}`),
                    addon: counters.status[item],
                    onChange: setValue('status'),
                    checked: status.includes(item),
                    extra: <div className={clsx(styles.dot, STATUS_DOT_CLASS_NAMES[item])} />,
                  }))
                  .filter((item) => item.addon !== 0)}
              />
            </Filter.Collapse>
            <Filter.Collapse
              label={t('common.table_color')}
              count={color.length}
              onClear={clearValue('color')}
            >
              <Filter.MultiCheckbox
                config={Object.values(COLOR)
                  .map((item) => ({
                    value: String(item),
                    label: COLOR_LABELS[item],
                    addon: counters.color[item],
                    onChange: setValue('color'),
                    checked: color.includes(String(item)),
                    extra: <div className={clsx(styles.dot, COLOR_DOT_CLASS_NAMES[item])} />,
                  }))
                  .filter((item) => item.addon !== 0)}
              />
            </Filter.Collapse>
            <Filter.Collapse
              label={t('common.table_poker_room')}
              count={rooms.length}
              onClear={clearValue('rooms')}
            >
              <Filter.MultiCheckbox
                config={roomsData.map((item) => ({
                  value: String(item),
                  label: TRAINER_POKER_ROOM_LABELS[item],
                  addon: counters.rooms[item] || 0,
                  onChange: setValue('rooms'),
                  checked: rooms.includes(String(item)),
                }))}
              />
            </Filter.Collapse>
            <Filter.Collapse
              label={t('common.table_super_league')}
              count={super_leagues.length}
              onClear={clearValue('super_leagues')}
            >
              <Filter.MultiCheckbox
                config={Object.keys(counters.superLeagues).map((item) => ({
                  value: item,
                  label: item,
                  addon: counters.superLeagues[item] || 0,
                  onChange: setValue('super_leagues'),
                  checked: super_leagues.includes(item),
                }))}
              />
            </Filter.Collapse>
            <Filter.Collapse
              label={t('common.table_league')}
              count={leagues.length}
              onClear={clearValue('leagues')}
            >
              <Filter.MultiCheckbox
                config={Object.keys(counters.leagues).map((item) => ({
                  value: item || '—',
                  label: item || '—',
                  addon: counters.leagues[item] || 0,
                  onChange: setValue('leagues'),
                  checked: leagues.includes(item || '—'),
                }))}
              />
            </Filter.Collapse>
            <Filter.Collapse
              label={t('common.table_club')}
              count={clubs.length}
              onClear={clearValue('clubs')}
            >
              <Filter.MultiCheckbox
                config={Object.keys(counters.clubs).map((item) => ({
                  value: item,
                  label: item,
                  addon: counters.clubs[item] || 0,
                  onChange: setValue('clubs'),
                  checked: clubs.includes(item),
                }))}
              />
            </Filter.Collapse>
            <Filter.Collapse
              label={t('common.table_currency')}
              count={currencies.length}
              onClear={clearValue('currencies')}
            >
              <Filter.MultiCheckbox
                config={Object.keys(counters.currencies).map((item) => ({
                  value: item,
                  label: item,
                  addon: counters.currencies[item] || 0,
                  onChange: setValue('currencies'),
                  checked: currencies.includes(item),
                }))}
              />
            </Filter.Collapse>
            <Filter.Collapse
              label={t('common.table_game_type')}
              count={game_types.length}
              onClear={clearValue('game_types')}
            >
              <Filter.MultiCheckbox
                config={gameTypesData.map((item) => ({
                  value: String(item),
                  label: TRAINER_GAME_TYPE_LABELS[item],
                  addon: counters.gameTypes[item] || 0,
                  onChange: setValue('game_types'),
                  checked: game_types.includes(String(item)),
                }))}
              />
            </Filter.Collapse>
            <Filter.Collapse
              label={t('common.table_limit')}
              count={limits.length}
              onClear={clearValue('limits')}
            >
              <Filter.MultiCheckbox
                config={limitsData.map((item) => ({
                  value: String(item),
                  label: TRAINER_LIMITS_LABELS[item],
                  addon: counters.limits[item] || 0,
                  onChange: setValue('limits'),
                  checked: limits.includes(String(item)),
                }))}
              />
            </Filter.Collapse>
            <Filter.Collapse
              label={t('common.table_size')}
              count={table_sizes.length}
              onClear={clearValue('table_sizes')}
            >
              <Filter.MultiCheckbox
                config={Object.keys(tableSizes).map((item) => ({
                  value: item,
                  label: tableSizesLabel[item],
                  addon: counters.tableSizes[item],
                  onChange: setValue('table_sizes'),
                  checked: table_sizes.includes(item),
                }))}
              />
            </Filter.Collapse>
            <Filter.RangeInput
              label={t('common.table_players')}
              step={1}
              min={counters.playerCount.min || 0}
              max={counters.playerCount.max || 0}
              valueMin={player_count_ge}
              valueMax={player_count_le}
              fieldMin="player_count_ge"
              fieldMax="player_count_le"
            />
            <Filter.RangeInput
              label={t('common.table_empty_seats')}
              step={1}
              min={counters.emptySeatsCount.min || 0}
              max={counters.emptySeatsCount.max || 0}
              valueMin={empty_seats_ge}
              valueMax={empty_seats_le}
              fieldMin="empty_seats_ge"
              fieldMax="empty_seats_le"
            />
            {Object.keys(counters.workspaces).length !== 0 && (
              <Filter.Collapse
                label={t('common.account_workspace')}
                count={maintenance_in.length}
                onClear={clearValue('maintenance_in')}
              >
                <Filter.MultiCheckbox
                  config={Object.keys(counters.workspaces).map((item) => ({
                    value: item,
                    label: item,
                    onChange: setValue('maintenance_in'),
                    checked: maintenance_in.includes(item),
                  }))}
                />
              </Filter.Collapse>
            )}
            {Object.keys(counters.trainerVersions).length !== 0 && (
              <Filter.Collapse
                label={t('common.account_trainer_version')}
                count={trainer_version_in.length}
                onClear={clearValue('trainer_version_in')}
              >
                <Filter.MultiCheckbox
                  config={Object.keys(counters.trainerVersions).map((item) => ({
                    value: item,
                    label: item,
                    onChange: setValue('trainer_version_in'),
                    checked: trainer_version_in.includes(item),
                  }))}
                />
              </Filter.Collapse>
            )}
          </div>
          <Filter.Switcher
            count={counters.empty}
            checked={show_empty}
            onChange={setValue('show_empty')}
          >
            {t('common.show_empty_tables')}
          </Filter.Switcher>
          <Filter.Switcher
            count={counters.full}
            checked={hide_full}
            onChange={setValue('hide_full')}
          >
            {t('common.hide_full_tables')}
          </Filter.Switcher>
        </>
      )}
    </Filter>
  );
};

export default GlobalLobbyFilter;
