import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceClubWhitelistSchema } from '../schemas/workspaceClubWhitelistSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  whitelistMemberId: WorkspaceClubWhitelistSchema['id'];
}

const restoreWorkspaceClubWhitelistMember = async (args: Args) => {
  const { workspaceId, whitelistMemberId } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/club-manager/white-list/${whitelistMemberId}/restore`
  );

  return emptyResponseSchema.parse(response.data);
};

export default restoreWorkspaceClubWhitelistMember;
