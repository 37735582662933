import { useTranslation } from 'react-i18next';
import Button from '../../Button';
import { useDatePickerContext } from '../DatePickerContext';
import { Props } from '../types';
import styles from './styles.module.scss';

const DatePickerHeader = (props: Pick<Props, 'label' | 'placeholder'>) => {
  const { label, placeholder } = props;

  const { t } = useTranslation();
  const { clearValue, closeDatePicker } = useDatePickerContext();

  const handleCancel = () => {
    clearValue();
    closeDatePicker();
  };

  return (
    <div className={styles.container}>
      <span>{label || placeholder}</span>
      <Button variant="light" size="extra-small" onClick={handleCancel}>
        {t('common.cancel')}
      </Button>
    </div>
  );
};

export default DatePickerHeader;
