import { z } from 'zod';
import api from '../services/api';

interface Payload {
  file: File;
}

interface Args {
  payload: Payload;
}

const uploadFile = async (args: Args) => {
  const { payload } = args;

  const response = await api.post('/api/storage', payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return z.object({ id: z.string() }).parse(response.data);
};

export default uploadFile;
