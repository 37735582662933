import { useTranslation } from 'react-i18next';
import CURRENCY from '../../../constants/CURRENCY';
import styles from './styles.module.scss';

const WorkspaceIdentityCost = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      {t('common.cost')}
      {CURRENCY.USDT}
    </div>
  );
};

export default WorkspaceIdentityCost;
