import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadGlobalWorkspace } from '../../api';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { can } from '../../utils/permissions';
import { updateDetailQueryData } from '../../utils/queryClient';
import { useGlobalWorkspaceClubsQuery, useGlobalWorkspaceUsersQuery } from './queries';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  children: (args: { data: WorkspaceSchema; clubsData: GlobalClubSchema[] }) => ReactElement;
}

const GlobalWorkspaceLoader = (props: Props) => {
  const { workspaceId, children } = props;

  const queryKey = [queryKeys.globalWorkspace({ workspaceId })];
  const queryFn = () => loadGlobalWorkspace({ workspaceId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<WorkspaceSchema>({
    domain: 'GlobalMaintenance',
    onMessage: (message) => {
      if (
        (message.action === 'Edited' ||
          message.action === 'Deleted' ||
          message.action === 'NegativeBalancePilotAllowed' ||
          message.action === 'NegativeBalancePilotDisallowed') &&
        message.payload.id === workspaceId
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  useGlobalWorkspaceUsersQuery({ workspaceId });

  const { data: clubsData, loading: clubsLoading } = useGlobalWorkspaceClubsQuery({ workspaceId });

  if (isPending || (can.club.read && clubsLoading)) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data, clubsData });
};

export default GlobalWorkspaceLoader;
