import {
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  flip,
  offset,
  autoUpdate,
  FloatingOverlay,
  FloatingPortal,
} from '@floating-ui/react';
import clsx from 'clsx';
import { format } from 'date-fns';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeRefs } from 'react-merge-refs';
import { DAY, DAY_TIME, MONTH_DAY_YEAR, MONTH_DAY_YEAR_TIME } from '../../constants/DATE_FORMATS';
import useBreakpoints from '../../hooks/useBreakpoints';
import icon from '../../static/icons';
import Error from '../Error';
import Label from '../Label';
import { DatePickerProvider } from './DatePickerContext';
import DatePickerDays from './DatePickerDays';
import DatePickerFooter from './DatePickerFooter';
import DatePickerHeader from './DatePickerHeader';
import DatePickerMonths from './DatePickerMonths';
import DatePickerNavigation from './DatePickerNavigation';
import DatePickerTime from './DatePickerTime';
import DatePickerYears from './DatePickerYears';
import styles from './styles.module.scss';
import { Props } from './types';
import useDatePicker from './useDatePicker';

const OFFSET = 4;

const DatePicker = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    value,
    size = 'large',
    label,
    disabled,
    placeholder,
    error,
    withTime,
    quick,
    onChange,
    disabledDate,
  } = props;

  const dateFormat = withTime ? DAY_TIME : DAY;

  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const [open, setOpen] = useState(false);
  const datePicker = useDatePicker({ value, open, format: dateFormat, onChange });

  const { refs, context, floatingStyles } = useFloating({
    open,
    strategy: 'fixed',
    placement: 'bottom-start',
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({ mainAxis: OFFSET }),
      flip({ fallbackPlacements: ['bottom-end', 'top-start', 'top-end', 'right', 'left'] }),
    ],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context, {
      outsidePressEvent: 'click',
    }),
  ]);

  const { picker } = datePicker;

  const content = (
    <div
      ref={refs.setFloating}
      className={styles.container}
      {...getFloatingProps()}
      {...((isDesktop || isTablet) && { style: floatingStyles })}
    >
      <div className={styles.wrapper}>
        <div className={styles.datePicker}>
          {isPhone && <DatePickerHeader label={label} placeholder={placeholder} />}
          <DatePickerNavigation />
          {picker === 'day' && <DatePickerDays withTime={withTime} disabledDate={disabledDate} />}
          {picker === 'month' && <DatePickerMonths />}
          {picker === 'year' && <DatePickerYears />}
        </div>
        {withTime && <DatePickerTime />}
      </div>
      <DatePickerFooter />
    </div>
  );

  const closeDatePicker = () => setOpen(false);

  const getValue = () => {
    if (!value && placeholder) return placeholder;
    if (quick && !value && disabled) return '—';
    if (quick && !value) return t('common.enter');

    return value ? format(value, withTime ? MONTH_DAY_YEAR_TIME : MONTH_DAY_YEAR) : '';
  };

  if (quick && disabled) {
    return (
      <div data-quick className={styles.field}>
        {getValue()}
      </div>
    );
  }

  return (
    <DatePickerProvider {...datePicker} closeDatePicker={closeDatePicker}>
      <div data-quick className={styles.field}>
        {label && <Label>{label}</Label>}
        <button
          ref={mergeRefs([refs.setReference, ref])}
          type="button"
          disabled={disabled}
          className={clsx(
            styles.button,
            open && styles.open,
            quick && styles.quick,
            error && styles.error,
            !value && styles.empty,
            size === 'medium' && styles.medium,
            placeholder && !value && styles.placeholder
          )}
          {...getReferenceProps()}
        >
          {getValue()}
          <div>{icon('calendar', quick ? 16 : 20)}</div>
        </button>
        {error && <Error error={error} />}
      </div>
      <FloatingPortal id="root">
        {open &&
          (isDesktop || isTablet ? (
            content
          ) : (
            <FloatingOverlay lockScroll className={styles.overlay}>
              {content}
            </FloatingOverlay>
          ))}
      </FloatingPortal>
    </DatePickerProvider>
  );
});

export default DatePicker;
