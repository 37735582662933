import { useTranslation } from 'react-i18next';
import { STATUS } from '../../api/schemas/channelSchema';
import { WorkspaceClubManagerSchema } from '../../api/schemas/workspaceClubManagerSchema';
import PageAddon from '../../components/PageAddon';
import Tag from '../../components/Tag';
import Tooltip from '../../components/Tooltip';

interface Props {
  data: WorkspaceClubManagerSchema;
}

const WorkspaceClubManagerChannel = (props: Props) => {
  const { data } = props;

  const { channel } = data;

  const { t } = useTranslation();

  if (!channel) return <PageAddon.Field label={t('common.channel')}>—</PageAddon.Field>;

  const inactive = channel.status === STATUS.UNKNOWN || channel.status === STATUS.INACTIVE;

  return (
    <PageAddon.Field label={t('common.channel')}>
      <Tooltip key={channel.id} label={inactive ? t('common.inactive') : t('common.active')}>
        <Tag secondary={inactive}>{channel.host}</Tag>
      </Tooltip>
    </PageAddon.Field>
  );
};

export default WorkspaceClubManagerChannel;
