import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import WorkspaceClubMemberAmountForm from '../../features/workspace-club-member/WorkspaceClubMemberAmountForm';
import WorkspaceClubMemberDetail from '../../features/workspace-club-member/WorkspaceClubMemberDetail';
import WorkspaceClubMemberLoader from '../../features/workspace-club-member/WorkspaceClubMemberLoader';
import WorkspaceClubTransactionDetail from '../../features/workspace-club-transaction/WorkspaceClubTransactionDetail';
import WorkspaceClubTransactionLoader from '../../features/workspace-club-transaction/WorkspaceClubTransactionLoader';
import WorkspaceClubTransactionsPage from '../../pages/WorkspaceClubTransactionsPage';
import Route from './Route';

const page = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceClubTransactionsPage workspaceId={workspaceId} />}
  </Route>
);

const detail = (
  <Route params={['workspaceId', 'transactionId']}>
    {({ workspaceId, transactionId, navigate }) => (
      <WorkspaceClubTransactionLoader workspaceId={workspaceId} transactionId={transactionId}>
        {({ data }) => (
          <WorkspaceClubTransactionDetail
            workspaceId={workspaceId}
            data={data}
            onClose={() => navigate(routes.workspaceClubTransactions({ workspaceId }))}
          />
        )}
      </WorkspaceClubTransactionLoader>
    )}
  </Route>
);

const detailMember = (
  <Route params={['workspaceId', 'transactionId', 'memberId']}>
    {({ workspaceId, transactionId, memberId, navigate }) => (
      <WorkspaceClubMemberLoader workspaceId={workspaceId} memberId={memberId}>
        {({ data }) => (
          <WorkspaceClubMemberDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() =>
              navigate(routes.workspaceClubTransaction({ workspaceId, transactionId }))
            }
            onDeposit={() =>
              navigate(
                routes.workspaceClubTransactionMemberDeposit({
                  workspaceId,
                  transactionId,
                  memberId,
                })
              )
            }
            onWithdraw={() =>
              navigate(
                routes.workspaceClubTransactionMemberWithdraw({
                  workspaceId,
                  transactionId,
                  memberId,
                })
              )
            }
          />
        )}
      </WorkspaceClubMemberLoader>
    )}
  </Route>
);

const detailMemberDepositForm = (
  <Route params={['workspaceId', 'transactionId', 'memberId']}>
    {({ workspaceId, transactionId, memberId, navigate }) => (
      <WorkspaceClubMemberLoader workspaceId={workspaceId} memberId={memberId}>
        {({ data }) => (
          <WorkspaceClubMemberAmountForm
            data={data}
            workspaceId={workspaceId}
            type="deposit"
            onClose={() =>
              navigate(
                routes.workspaceClubTransactionMember({ workspaceId, transactionId, memberId })
              )
            }
          />
        )}
      </WorkspaceClubMemberLoader>
    )}
  </Route>
);

const detailMemberWorkspaceForm = (
  <Route params={['workspaceId', 'transactionId', 'memberId']}>
    {({ workspaceId, transactionId, memberId, navigate }) => (
      <WorkspaceClubMemberLoader workspaceId={workspaceId} memberId={memberId}>
        {({ data }) => (
          <WorkspaceClubMemberAmountForm
            data={data}
            workspaceId={workspaceId}
            type="withdraw"
            onClose={() =>
              navigate(
                routes.workspaceClubTransactionMember({ workspaceId, transactionId, memberId })
              )
            }
          />
        )}
      </WorkspaceClubMemberLoader>
    )}
  </Route>
);

const workspaceClubTransactionsPage: RouteObject = {
  path: routes.workspaceClubTransactions({ workspaceId: ':workspaceId' }),
  element: page,
  children: [
    {
      path: routes.workspaceClubTransaction({
        workspaceId: ':workspaceId',
        transactionId: ':transactionId',
      }),
      element: detail,
      children: [
        {
          path: routes.workspaceClubTransactionMember({
            workspaceId: ':workspaceId',
            transactionId: ':transactionId',
            memberId: ':memberId',
          }),
          element: detailMember,
        },
        {
          path: routes.workspaceClubTransactionMemberDeposit({
            workspaceId: ':workspaceId',
            transactionId: ':transactionId',
            memberId: ':memberId',
          }),
          element: detailMemberDepositForm,
        },
        {
          path: routes.workspaceClubTransactionMemberWithdraw({
            workspaceId: ':workspaceId',
            transactionId: ':transactionId',
            memberId: ':memberId',
          }),
          element: detailMemberWorkspaceForm,
        },
      ],
    },
  ],
};

export default workspaceClubTransactionsPage;
