import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceSchema } from '../schemas/workspaceSchema';
import workspaceTagSchema, { WorkspaceTagSchema } from '../schemas/workspaceTagSchema';

type Schema = WorkspaceTagSchema;

interface Payload {
  name: Schema['name'];
  color: Schema['color'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const createWorkspaceTag = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.post(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/tag`, payload);

  return workspaceTagSchema.pick({ id: true }).parse(response.data);
};

export default createWorkspaceTag;
