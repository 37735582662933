import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { SeatControlRuleSchema } from '../schemas/seatControlRuleSchema';

type Schema = SeatControlRuleSchema;

interface Args {
  seatControlRuleId: Schema['id'];
}

const deactivateGlobalSeatControlRule = async (args: Args) => {
  const { seatControlRuleId } = args;

  const response = await api.patch(`/api/seat-control/${seatControlRuleId}/deactivate`);

  return emptyResponseSchema.parse(response.data);
};

export default deactivateGlobalSeatControlRule;
