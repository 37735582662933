import { useTranslation } from 'react-i18next';
import { MarketIdentitySchema } from '../../api/schemas/marketIdentitySchema';
import PageAddon from '../../components/PageAddon';
import Tag from '../../components/Tag';
import { formatAbsoluteDate } from '../../utils/date';
import { TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';
import { WALLETS_LABELS, DOCUMENTS_LABELS, PHOTOS_LABELS } from '../workspace-identity/helpers';
import WorkspaceIdentityPageAddonFieldChannel from '../workspace-identity/WorkspaceIdentityPageAddonFieldChannel';

interface Props {
  data: MarketIdentitySchema;
}

const WorkspaceMarketIdentityDetailDetailsTab = (props: Props) => {
  const { data } = props;

  const { t } = useTranslation();

  const {
    date_of_birth,
    gender,
    rooms,
    channel,
    online_wallets,
    bank_accounts,
    documents,
    photos,
  } = data;

  const getGender = () => {
    if (gender === 1) return t('common.male');

    if (gender === 2) return t('common.female');

    return '—';
  };

  const getPokerRooms = () => {
    if (!rooms.length) return '—';

    return rooms.map((item) => <Tag key={item}>{TRAINER_POKER_ROOM_LABELS[item]}</Tag>);
  };

  const getOnlineWallets = () => {
    if (!online_wallets.length) return '—';

    return online_wallets.map((item) => <Tag key={item}>{WALLETS_LABELS[item]}</Tag>);
  };

  const getDocuments = () => {
    if (!documents.length) return '—';

    return documents.map((item) => <Tag key={item}>{DOCUMENTS_LABELS[item]}</Tag>);
  };

  const gePhotos = () => {
    if (!photos.length) return '—';

    return photos.map((item) => <Tag key={item}>{PHOTOS_LABELS[item]}</Tag>);
  };

  return (
    <PageAddon.Fields>
      <PageAddon.Field label={t('common.date_of_birth')}>
        {formatAbsoluteDate(date_of_birth)}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.gender')}>{getGender()}</PageAddon.Field>
      <WorkspaceIdentityPageAddonFieldChannel channel={channel} />
      <PageAddon.Field label={t('sentences.present_in_poker_rooms')}>
        <PageAddon.Tags>{getPokerRooms()}</PageAddon.Tags>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.online_wallets')}>
        <PageAddon.Tags>{getOnlineWallets()}</PageAddon.Tags>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.bank_accounts')}>
        {bank_accounts ? t('common.true') : t('common.false')}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.documents')}>
        <PageAddon.Tags>{getDocuments()}</PageAddon.Tags>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.selfies')}>
        <PageAddon.Tags>{gePhotos()}</PageAddon.Tags>
      </PageAddon.Field>
    </PageAddon.Fields>
  );
};

export default WorkspaceMarketIdentityDetailDetailsTab;
