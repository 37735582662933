import { z } from 'zod';
import globalAccountSchema from './account/globalAccountSchema';
import { lobbyTableWithMetricsSchema } from './lobbyTableSchema';
import metricsSchema from './metricsSchema';
import workspaceSchema from './workspaceSchema';

export const accountSchema = globalAccountSchema
  .pick({
    id: true,
    mga_id: true,
    max_tables: true,
    trainer_version: true,
  })
  .extend({
    maintenance_id: workspaceSchema.shape.id,
    metrics: metricsSchema,
  });

const globalAccountTableSchema = z.object({
  tables: z.array(lobbyTableWithMetricsSchema),
  account: accountSchema,
});

export type GlobalAccountTableSchema = z.infer<typeof globalAccountTableSchema>;

export { globalAccountTableSchema };
