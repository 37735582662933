import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import icon from '../../static/icons';
import Button from '../Button';
import Input from '../Input';
import Radio from '../Radio';
import styles from './styles.module.scss';

type Value = string;
type Values = Value[];

interface Select {
  label: string;
  value: Value;
  onChange: (value: Value) => void;
}

interface Props {
  label: string;
  values: Values;
  onChange: (values: Values) => void;
  error?: string;
  disabled?: boolean;
  select?: Select;
}

const TagInput = (props: Props) => {
  const { label, values, onChange, error, disabled, select } = props;

  const { t } = useTranslation();

  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const addValue = () => {
    if (select) select.onChange(inputValue);

    if (!values.includes(inputValue)) onChange([...values, inputValue]);

    setInputValue('');
  };

  useEffect(() => {
    if (select) {
      if (values.length && !values.includes(select.value)) {
        select.onChange(values[0]);
      }

      if (!values.length && select.value) {
        select.onChange('');
      }
    }
  }, [values, select]);

  return (
    <div className={styles.container}>
      <Input
        value={inputValue}
        label={label}
        className={styles.input}
        disabled={disabled}
        error={error}
        {...(inputValue.trim() && {
          extra: <Input.Button onClick={addValue}>{icon('cornerDownLeft', 20)}</Input.Button>,
        })}
        onChange={(value) => setInputValue(value.target.value)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && event.currentTarget.value.trim()) {
            event.preventDefault();
            addValue();
          }
        }}
      />
      {inputValue.trim() && focused && (
        <div className={styles.message}>
          {t('sentences.press_enter_to_add_1')}
          &nbsp;
          <span className={styles.messageKey}>{t('sentences.press_enter_to_add_2')}</span>
          &nbsp;
          {t('sentences.press_enter_to_add_3')}
          &nbsp;
          <span className={styles.messageValue}>{`“${inputValue}”`}</span>
        </div>
      )}
      {Boolean(values.length) && (
        <div className={styles.values}>
          {values.map((item) => {
            const deleteValue = () => onChange(values.filter((el) => el !== item));

            const deleteButton = (
              <Button
                danger
                size="small"
                variant="transparent"
                icon={icon('trash', 16)}
                onClick={deleteValue}
              />
            );

            if (select) {
              const radio = (
                <Radio
                  value={item}
                  checked={item === select.value}
                  className={styles.radio}
                  onChange={(event) => select.onChange(event.target.value)}
                >
                  <span>{item}</span>
                </Radio>
              );

              return (
                <div key={item} className={styles.value}>
                  {item === select.value && (
                    <div className={styles.selectedValue}>
                      {radio}
                      <span>{select.label}</span>
                    </div>
                  )}
                  {item !== select.value && radio}
                  {deleteButton}
                </div>
              );
            }

            return (
              <div key={item} className={styles.value}>
                <span>{item}</span>
                {deleteButton}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TagInput;
