import { z } from 'zod';
import globalAccountSchema from '../account/globalAccountSchema';
import workspaceSchema from '../workspaceSchema';
import { sessionCommonFilterValuesSchema, sessionCommonSchema } from '.';

const sessionAccountSchema = z.object({
  id: globalAccountSchema.shape.id,
  mga_id: globalAccountSchema.shape.mga_id,
  maintenance: z.object({
    name: globalAccountSchema.shape.maintenance.shape.name,
  }),
});

const globalSessionSchema = sessionCommonSchema.extend({
  account: sessionAccountSchema,
});

export const globalSessionFilterValuesSchema = sessionCommonFilterValuesSchema.extend({
  maintenance: z.array(workspaceSchema.pick({ id: true, name: true })),
});

export type GlobalSessionSchema = z.infer<typeof globalSessionSchema>;
export type GlobalSessionFilterValuesSchema = z.infer<typeof globalSessionFilterValuesSchema>;

export default globalSessionSchema;
