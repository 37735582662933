import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadGlobalSuperLeague } from '../../api';
import { GlobalSuperLeagueSchema } from '../../api/schemas/globalSuperLeagueSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { updateDetailQueryData } from '../../utils/queryClient';

interface Props {
  superLeagueId: GlobalSuperLeagueSchema['id'];
  children: (args: { data: GlobalSuperLeagueSchema }) => ReactElement;
}

const GlobalSuperLeagueLoader = (props: Props) => {
  const { superLeagueId, children } = props;

  const queryKey = [queryKeys.globalSuperLeague({ superLeagueId })];
  const queryFn = () => loadGlobalSuperLeague({ superLeagueId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<GlobalSuperLeagueSchema>({
    domain: 'SuperLeague',
    onMessage: (message) => {
      if (
        (message.action === 'Edited' || message.action === 'Deleted') &&
        message.payload.id === superLeagueId
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data });
};

export default GlobalSuperLeagueLoader;
