import { useTranslation } from 'react-i18next';
import { WorkspaceClubManagerSchema } from '../../../api/schemas/workspaceClubManagerSchema';
import PrettyNumber from '../../../components/PrettyNumber';
import Tooltip from '../../../components/Tooltip';
import icon from '../../../static/icons';
import { convertCentsToDollars } from '../../../utils/data';
import styles from './styles.module.scss';

interface Props {
  data: WorkspaceClubManagerSchema;
}

const WorkspaceClubManagerBalance = (props: Props) => {
  const { data } = props;

  const { t } = useTranslation();

  const { balance, balance_threshold } = data;

  const content = <PrettyNumber>{convertCentsToDollars(balance)}</PrettyNumber>;

  if (balance_threshold !== null && balance <= balance_threshold) {
    return (
      <Tooltip label={t('common.low_balance')} hideOnMobile>
        <div className={styles.container}>
          {content}
          {icon('alertCircle', 16)}
        </div>
      </Tooltip>
    );
  }

  return content;
};

export default WorkspaceClubManagerBalance;
