import { ActionProps } from 'react-querybuilder';
import icon from '../../static/icons';
import Button from '../Button';

const QueryBuilderRemoveRuleAction = (props: ActionProps) => {
  const { handleOnClick } = props;

  return (
    <Button
      danger
      size="extra-small"
      variant="transparent"
      icon={icon('trash', 16)}
      onClick={handleOnClick}
    />
  );
};

export default QueryBuilderRemoveRuleAction;
