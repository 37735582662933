import { WorkspaceDashboardAccountSchema } from '../../../api/schemas/workspace-dashboard/workspaceDashboardAccountSchema';
import styles from './styles.module.scss';

interface Props {
  data: WorkspaceDashboardAccountSchema;
}

const WorkspaceDashboardDetailLink = (props: Props) => {
  const { data } = props;

  const { club_name, club_code } = data;

  return (
    <div className={styles.container}>
      {club_name}
      <div className={styles.caption}>{club_code}</div>
    </div>
  );
};

export default WorkspaceDashboardDetailLink;
