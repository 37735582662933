import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import identitySchema, { IdentitySchema } from '../schemas/identitySchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  identityId: IdentitySchema['id'];
}

const loadWorkspaceIdentity = async (args: Args) => {
  const { workspaceId, identityId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/identity/${identityId}`
  );

  return identitySchema.parse(response.data);
};

export default loadWorkspaceIdentity;
