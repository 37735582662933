import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import workspaceSchema from './workspaceSchema';

export const TYPE = {
  UNKNOWN: 0,
  USDT: 1,
} as const;

const workspaceWalletSchema = z.object({
  id: z.string(),
  maintenance: workspaceSchema, // TODO: там еще есть participiants, кажется это лишнее
  address: z.string(),
  type: z.nativeEnum(TYPE),
  is_deleted: z.boolean(),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export type WorkspaceWalletSchema = z.infer<typeof workspaceWalletSchema>;

export default workspaceWalletSchema;
