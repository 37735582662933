import Badge, { Color } from '../../../components/Badge';
import icon from '../../../static/icons';
import { capitalize } from '../../../utils/data';
import styles from './styles.module.scss';

const colors: Color[] = ['green', 'orange', 'red', 'blue', 'purple', 'grey'];

const ICONS = {
  green: icon('check', 16),
  orange: icon('clock', 16),
  red: icon('crossCircle', 16),
  blue: icon('clock', 16),
  purple: icon('clock', 16),
  grey: icon('checkCircle', 16),
};

const StorybookPageBadge = () => (
  <>
    <h2>Badge</h2>
    <div className={styles.badges}>
      <div>
        {colors.map((item) => (
          <Badge key={item} color={item}>
            {ICONS[item]}
            {capitalize(item)}
          </Badge>
        ))}
      </div>
      <div>
        {colors.map((item) => (
          <Badge key={item} color={item}>
            {capitalize(item)}
          </Badge>
        ))}
      </div>
    </div>
  </>
);

export default StorybookPageBadge;
