import routes from '../config/routes';
import { t } from '../i18n';
import icon from '../static/icons';
import { useUserStore } from '../store/user';
import { addWhen } from '../utils';
import { can } from '../utils/permissions';
import useURL from './useURL';

const useSidebarSection = () => {
  const user = useUserStore();
  const { navigate } = useURL();

  const getAccountsTo = () => {
    if (can.account.read) return routes.globalAccounts;
    if (can.session.read) return routes.globalSessions;

    return routes.globalAccounts;
  };

  const getMembersTo = () => {
    if (can.user.read) return routes.globalUsers;
    if (can.role.read) return routes.globalRoles;

    return routes.globalUsers;
  };

  const sections = [
    ...addWhen(
      {
        to: routes.globalLobby,
        title: t('common.lobby'),
        icon: icon('lobby', 24),
      },
      can.lobby.read
    ),
    ...addWhen(
      {
        to: getAccountsTo(),
        title: t('common.accounts'),
        icon: icon('spadeMaps', 24),
      },
      can.account.read || can.session.read
    ),
    ...addWhen(
      {
        to: routes.globalWorkspaces,
        title: t('common.workspaces'),
        icon: icon('folder', 24),
      },
      can.workspace.read
    ),
    ...addWhen(
      {
        to: routes.globalOrders,
        title: t('common.orders'),
        icon: icon('documents', 24),
      },
      can.order.read
    ),
    ...addWhen(
      {
        to: routes.globalDeals,
        title: t('common.deals'),
        icon: icon('case', 24),
      },
      can.deal.read
    ),
    ...addWhen(
      {
        to: getMembersTo(),
        title: t('common.members'),
        icon: icon('users', 24),
      },
      can.user.read || can.role.read
    ),
    ...addWhen(
      {
        to: routes.globalSeatConrolRules,
        title: t('common.seating_rules'),
        icon: icon('compasses', 24),
      },
      can.seatControlRule.read
    ),
  ];

  const collapsedSections = [
    ...addWhen(
      {
        to: routes.globalAreas,
        title: t('common.areas'),
      },
      can.currency.read
    ),
    ...addWhen(
      {
        to: routes.globalBotSettings,
        title: t('common.bot_settings'),
      },
      can.botSetting.read
    ),

    ...addWhen(
      {
        to: routes.globalClubs,
        title: t('common.clubs'),
      },
      can.club.read
    ),
  ];

  const isWorkspaceExist = Boolean(user.workspaces && Object.keys(user.workspaces).length);
  const isPlatformSectionExist = Boolean(sections.length);
  const isSectionExists = isWorkspaceExist || isPlatformSectionExist;

  const moveToFirstSection = () => {
    if (isWorkspaceExist) {
      navigate(routes.home, { params: false });
    } else if (isPlatformSectionExist) {
      navigate(sections[0].to, { params: false });
    }
  };

  return { sections, collapsedSections, isSectionExists, moveToFirstSection };
};

export default useSidebarSection;
