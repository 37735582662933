import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HistoryFilterValuesSchema, HistorySchema } from '../../api/schemas/historySchema';
import usePrevious from '../../hooks/usePrevious';
import URLParams from '../../types/URLParams';
import { getUrlParams } from '../PageAddon/PageAddonFilter/helpers';
import Placeholder from '../Placeholder';
import Select, { Options } from '../Select';
import { ACTION_TYPE_LABELS, ActionType } from './helpers';
import styles from './styles.module.scss';

interface Fields {
  action_type_in: Options<ActionType>;
  author_in: Options<Exclude<HistorySchema['author'], null>['id']>;
}

interface Props {
  query: ReturnType<typeof useQuery<HistoryFilterValuesSchema>>;
  onChange?: (values: URLParams) => void;
}

const HistoryDialogFilter = (props: Props) => {
  const { query, onChange } = props;

  const { t } = useTranslation();

  const { data, isPending } = query;

  const form = useForm<Fields>({ defaultValues: { action_type_in: [], author_in: [] } });

  const { control, watch } = form;

  const values = watch();
  const prevValues = usePrevious(values);

  useEffect(() => {
    if (onChange && JSON.stringify(values) !== JSON.stringify(prevValues)) {
      onChange(getUrlParams(values));
    }
  }, [values, prevValues, onChange]);

  return (
    <FormProvider {...form}>
      {isPending && (
        <>
          <Placeholder className={styles.selectPlaceholder} />
          <Placeholder className={styles.selectPlaceholder} />
        </>
      )}
      {data && (
        <>
          <Controller
            name="action_type_in"
            control={control}
            render={({ field }) => (
              <Select.Multi
                size="small"
                popupSize="auto"
                placeholder={t('common.type')}
                options={data.action_type
                  .map((item) => ({
                    value: item,
                    label: ACTION_TYPE_LABELS[item],
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="author_in"
            control={control}
            render={({ field }) => (
              <Select.Multi
                size="small"
                popupSize="auto"
                placement="bottom-end"
                placeholder={t('common.author')}
                options={data.author.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </>
      )}
    </FormProvider>
  );
};

export default HistoryDialogFilter;
