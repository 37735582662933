import { useTranslation } from 'react-i18next';
import { INITIATOR } from '../../../../api/schemas/account/accountTransactionSchema';
import Id from '../../../../components/Id';
import { CommonAccountTransactionSchema } from '../../types';
import styles from './styles.module.scss';

interface Props {
  data: CommonAccountTransactionSchema;
}

const AccountTransactionsAuthor = (props: Props) => {
  const { data } = props;

  const { user_id, user_login } = data;

  const { t } = useTranslation();

  if (data.initiator === INITIATOR.TRAINER) return <>{t('common.trainer')}</>;
  if (data.initiator === INITIATOR.PLATFORM) return <>{t('common.platform')}</>;

  if (data.initiator === INITIATOR.USER && user_id) {
    return (
      <div className={styles.user}>
        {user_login}
        <Id variant="secondary" id={user_id} />
      </div>
    );
  }

  return null;
};

export default AccountTransactionsAuthor;
