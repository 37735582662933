import {
  flip,
  offset,
  useClick,
  autoUpdate,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import clsx from 'clsx';
import { useState } from 'react';
import Button from '../../../components/Button';
import ColorPicker from '../../../components/ColorPicker';
import ColorPickerIcon from '../../../static/color-picker.svg';
import icon from '../../../static/icons';
import styles from './styles.module.scss';

export const presets = [
  '#dfbd33',
  '#ffd93f',
  '#ffe992',
  '#c43333',
  '#f85959',
  '#ff9a78',
  '#7f24bc',
  '#b84dff',
  '#ffb8ff',
  '#2664c1',
  '#4d94ff',
  '#94dcff',
  '#279f27',
  '#5cd85c',
  '#94ff94',
  '#2e2d2d',
  '#b8b8b8',
  '#dcdcdc',
];

type Value = string;

interface Props {
  value: Value;
  onChange: (value: Value) => void;
}

const WorkspaceSettingsTagsColor = (props: Props) => {
  const { value, onChange } = props;

  const [open, setOpen] = useState(false);

  const isCustomColor = !presets.includes(value);

  const [custom, setCustom] = useState(isCustomColor);

  const { refs, context, floatingStyles } = useFloating({
    open,
    strategy: 'fixed',
    placement: 'bottom-start',
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(4), flip()],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context, { outsidePressEvent: 'click' }),
  ]);

  return (
    <>
      <Button
        ref={refs.setReference}
        size="small"
        variant="transparent"
        className={styles.color}
        icon={<div style={{ backgroundColor: value }} />}
        {...getReferenceProps()}
      />
      {open && (
        <div
          ref={refs.setFloating}
          className={styles.popup}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <div className={styles.presets}>
            {presets.map((item) => {
              const preset = value === item;

              return (
                <button
                  key={item}
                  type="button"
                  aria-label={item}
                  onClick={() => {
                    onChange(item);
                    setCustom(false);
                  }}
                  style={{ backgroundColor: item }}
                  className={styles.preset}
                >
                  <div
                    style={{ borderColor: item }}
                    className={clsx(styles.ring, preset && styles.active)}
                  >
                    {icon('check', 16)}
                  </div>
                </button>
              );
            })}
            <button
              type="button"
              onClick={() => {
                setCustom(true);
              }}
              {...(custom && { style: { backgroundColor: value } })}
              className={styles.preset}
            >
              {custom && isCustomColor && (
                <div style={{ borderColor: value }} className={clsx(styles.ring, styles.active)}>
                  {icon('check', 16)}
                </div>
              )}
              {!custom && <ColorPickerIcon width={30} height={30} />}
            </button>
          </div>
          {custom && <ColorPicker defaultValue={value} onChange={onChange} />}
        </div>
      )}
    </>
  );
};

export default WorkspaceSettingsTagsColor;
