import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { GlobalRoleSchema } from '../schemas/globalRoleSchema';

type Schema = GlobalRoleSchema;

interface Args {
  roleId: Schema['id'];
}

const deleteGlobalRole = async (args: Args) => {
  const { roleId } = args;

  const response = await api.delete(`/api/role/${roleId}`);

  return emptyResponseSchema.parse(response.data);
};

export default deleteGlobalRole;
