import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  bulkDeleteFilteredWorkspaceChannels,
  bulkDeleteWorkspaceChannels,
  exportFilteredWorkspaceChannels,
  exportWorkspaceChannels,
} from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Button from '../../components/Button';
import Confirm from '../../components/Confirm';
import Tooltip from '../../components/Tooltip';
import useBulkActions from '../../hooks/useBulkActions';
import icon from '../../static/icons';
import { downloadFile } from '../../utils/files';

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceChannelTableSelectionActions = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();

  const { ids, params, selectAllMode, disabled, handle } = useBulkActions();

  const exportChannels = useMutation({ mutationFn: exportWorkspaceChannels, onSuccess: () => {} });
  const exportFilteredChannels = useMutation({
    mutationFn: exportFilteredWorkspaceChannels,
    onSuccess: () => {},
  });

  return (
    <>
      <Tooltip label={t('common.delete')} hideOnMobile>
        <Confirm
          title={t('sentences.delete_records')}
          confirmText={t('common.delete')}
          onConfirm={() =>
            handle(() => {
              const fn = selectAllMode
                ? bulkDeleteFilteredWorkspaceChannels
                : bulkDeleteWorkspaceChannels;

              return fn({ workspaceId, payload: ids, params });
            })
          }
        >
          {({ confirming }) => (
            <Button
              danger
              focused={confirming}
              variant="transparent"
              icon={icon('trash', 16)}
              data-testid="bulk-delete"
              disabled={disabled}
            />
          )}
        </Confirm>
      </Tooltip>
      <Tooltip label={t('common.export_to_CSV')} hideOnMobile>
        <Button
          variant="transparent"
          icon={icon('download', 16)}
          data-testid="export"
          onClick={() =>
            handle(async () => {
              const fn = selectAllMode
                ? exportFilteredChannels.mutateAsync
                : exportChannels.mutateAsync;

              const response = await fn({ workspaceId, payload: ids, params });

              downloadFile(response, 'channels.csv');
            })
          }
          disabled={disabled}
          loading={exportChannels.isPending || exportFilteredChannels.isPending}
        />
      </Tooltip>
    </>
  );
};

export default WorkspaceChannelTableSelectionActions;
