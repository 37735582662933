import Radio from '../../Radio';
import styles from './styles.module.scss';

interface Props {
  value: string;
  onChange: (value: string) => void;
  config: { value: string; label: string }[];
}

const FilterRadio = (props: Props) => {
  const { value, onChange, config } = props;

  return (
    <div className={styles.container}>
      {config.map((item) => (
        <Radio
          key={item.value}
          value={item.value}
          checked={value === item.value}
          onChange={(event) => onChange(event.target.value)}
          className={styles.radio}
        >
          <span>{item.label}</span>
        </Radio>
      ))}
    </div>
  );
};

export default FilterRadio;
