import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { globalAccountFilterValuesSchema } from '../schemas/account/globalAccountSchema';

const loadGlobalAccountsFilterValues = async (params?: URLParams) => {
  const response = await api.get('/api/account/filter-values', { params });

  return globalAccountFilterValuesSchema.parse(response.data);
};

export default loadGlobalAccountsFilterValues;
