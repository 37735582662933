import { useTranslation } from 'react-i18next';
import { STATUS } from '../../api/schemas/areaSchema';
import Filter from '../../components/Filter';
import useFilter from '../../hooks/useFilter';
import { STATUS_LABELS } from './helpers';

interface Fields {
  status_in: string[];
  updated_ge: string;
  updated_le: string;
  created_ge: string;
  created_le: string;
}

const GlobalAreaFilter = () => {
  const { t } = useTranslation();

  const filter = useFilter<Fields>({
    status_in: 'multi',
    updated_ge: 'single',
    updated_le: 'single',
    created_ge: 'single',
    created_le: 'single',
  });

  const { values, setValue, clearValue } = filter;

  const { status_in, updated_ge, updated_le, created_ge, created_le } = values;

  return (
    <Filter filter={filter}>
      <div>
        <Filter.Collapse
          label={t('common.status')}
          count={status_in.length}
          onClear={clearValue('status_in')}
        >
          <Filter.MultiCheckbox
            config={Object.values(STATUS)
              .filter((item) => item !== STATUS.UNKNOWN)
              .map((item) => ({
                value: String(item),
                label: STATUS_LABELS[item],
                onChange: setValue('status_in'),
                checked: status_in.includes(String(item)),
              }))}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.modified')}
          {...((updated_ge || updated_le) && {
            onClear: clearValue(['updated_ge', 'updated_le']),
          })}
        >
          <Filter.Date
            from={{ value: updated_ge, onChange: setValue('updated_ge') }}
            to={{ value: updated_le, onChange: setValue('updated_le') }}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.created')}
          {...((created_ge || created_le) && {
            onClear: clearValue(['created_ge', 'created_le']),
          })}
        >
          <Filter.Date
            from={{ value: created_ge, onChange: setValue('created_ge') }}
            to={{ value: created_le, onChange: setValue('created_le') }}
          />
        </Filter.Collapse>
      </div>
    </Filter>
  );
};

export default GlobalAreaFilter;
