import { z } from 'zod';

export const STATUS = {
  UNKNOWN: 0,
  GREEN: 1,
  YELLOW: 2,
  RED: 3,
} as const;

const metricsSchema = z.object({
  hands_played: z.number(),
  stack_size: z.number(),
  stack_size_bb: z.number(),
  errors_count: z.number(),
  net_win_sum: z.number(),
  net_win_sum_bb: z.number(),
  ai_ev_sum: z.number(),
  ai_ev_sum_bb: z.number(),
  win_rate_bb: z.number(),
  ev_rate_bb: z.number(),
  local_brain_count: z.number(),
  status: z.nativeEnum(STATUS),
});

export type MetricsSchema = z.infer<typeof metricsSchema>;

export default metricsSchema;
