import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import Checkbox from '../../../../components/Checkbox';
import Confirm from '../../../../components/Confirm';
import Dialog from '../../../../components/Dialog';
import Form from '../../../../components/Form';
import Id from '../../../../components/Id';
import icon from '../../../../static/icons';
import notify from '../../../../utils/notify';
import { TYPE_ENUM, getTitle } from '../../../session/helpers';
import SessionFormType from '../../../session/SessionFormType';
import SessionStatus from '../../../session/SessionStatus';
import { CommonSessionSchema } from '../../../session/types';
import { CommonAccountSchema } from '../../types';
import { isFuture } from '../helpers';
import styles from './styles.module.scss';

interface Fields {
  club_ids: CommonAccountSchema['clubs'][number]['id'][];
  session_type: string;
}

interface Props {
  data: CommonSessionSchema;
  account: CommonAccountSchema;
  canDelete: boolean;
  onDelete: () => void;
  onUpdate: (payload: {
    club_ids: CommonAccountSchema['clubs'][number]['id'][];
    session_type: CommonSessionSchema['session_type'];
  }) => void;
  onClose: () => void;
}

const AccountScheduleDialogForm = (props: Props) => {
  const { data, account, canDelete, onDelete, onUpdate, onClose } = props;

  const { start, end, finishing_reason } = data;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: {
      club_ids: data.clubs.map((item) => item.id),
      session_type: String(data.session_type),
    },
  });

  const {
    watch,
    setValue,
    formState: { isSubmitting },
  } = form;

  const clubIds = watch('club_ids');

  const title = getTitle({ start, end });

  const isClubsExist = Boolean(account.clubs.length);

  const isPastSession = !isFuture(start);

  const deleteRecord = async () => {
    await onDelete();

    notify('success', { title: t('sentences.record_has_been_deleted') });

    onClose();
  };

  const onSubmit = async (values: Fields) => {
    const payload = {
      club_ids: values.club_ids,
      session_type: TYPE_ENUM[values.session_type],
    };

    await onUpdate(payload);

    notify('success', { title: t('sentences.changes_have_been_saved') });
    onClose();
  };

  return (
    <Dialog secondary className={styles.dialog} onClose={onClose}>
      {({ close }) => (
        <>
          <Dialog.Top>
            <Dialog.Title>{title}</Dialog.Title>
            {canDelete && (
              <Confirm
                onConfirm={deleteRecord}
                title={t('sentences.delete_this_event')}
                confirmText={t('common.delete')}
              >
                {({ confirming }) => (
                  <Button
                    danger
                    size="small"
                    variant="transparent"
                    focused={confirming}
                    disabled={isPastSession}
                    icon={icon('trash', 16)}
                  />
                )}
              </Confirm>
            )}
          </Dialog.Top>
          <Form form={form} onSubmit={onSubmit} className={styles.form}>
            <div className={styles.container}>
              <div className={styles.field}>
                <span className={styles.heading}>{t('common.id')}</span>
                <Id id={data.id} variant="primary" />
              </div>
              <div className={styles.wrapper}>
                <div className={styles.field}>
                  <span className={styles.heading}>{t('common.status')}</span>
                  <SessionStatus data={data} />
                </div>
                {finishing_reason && (
                  <div className={styles.field}>
                    <span className={styles.heading}>{t('common.finishing_reason')}</span>
                    {finishing_reason}
                  </div>
                )}
              </div>
              <SessionFormType disabled={isPastSession} className={styles.radioGroup} />
              {isClubsExist && (
                <div>
                  <span className={styles.heading}>{t('common.choose_at_least_one_club')}</span>
                  <div className={styles.clubs}>
                    {account.clubs.map((item) => {
                      const selectClub = () => {
                        if (clubIds.includes(item.id)) {
                          setValue(
                            'club_ids',
                            clubIds.filter((el) => el !== item.id)
                          );
                        } else {
                          setValue('club_ids', [...clubIds, item.id]);
                        }
                      };

                      return (
                        <Checkbox
                          key={item.id}
                          checked={clubIds.includes(item.id)}
                          className={styles.checkbox}
                          disabled={isPastSession}
                          onChange={selectClub}
                        >
                          <span className={styles.club}>{item.name}</span>
                        </Checkbox>
                      );
                    })}
                  </div>
                </div>
              )}
              {!isClubsExist && <span className={styles.heading}>{t('common.no_clubs')}</span>}{' '}
            </div>
            <Dialog.Footer>
              {isClubsExist && (
                <>
                  <Button variant="transparent" onClick={() => close()}>
                    {t('common.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    loading={isSubmitting}
                    disabled={!clubIds.length || isPastSession}
                  >
                    {t('common.save')}
                  </Button>
                </>
              )}
              {!isClubsExist && <Button onClick={() => close()}>{t('common.ok')}</Button>}
            </Dialog.Footer>
          </Form>
        </>
      )}
    </Dialog>
  );
};

export default AccountScheduleDialogForm;
