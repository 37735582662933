import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import paginationSchemaNew from '../schemas/paginationSchemaNew';
import seatControlRuleSchema from '../schemas/seatControlRuleSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceSeatControlRules = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(`/api/v2/${ALIASES.WORKSPACE.L}/${workspaceId}/seat-control`, {
    params,
  });

  return paginationSchemaNew(seatControlRuleSchema).parse(response.data);
};

export default loadWorkspaceSeatControlRules;
