import clsx from 'clsx';
import { useState, ReactNode } from 'react';
import icon from '../../static/icons';
import { Type } from '../../types/Alert';
import { getMessage } from '../../utils/notify';
import AlertCloseButton from './AlertCloseButton';
import styles from './styles.module.scss';

interface Props {
  type: Type;
  title: ReactNode;
  description?: string;
  className?: string;
  closable?: boolean;
  onClose?: () => void;
}

const Alert = (props: Props) => {
  const { type, title, description, className: customClassName, closable = true, onClose } = props;

  const [isOpen, setOpen] = useState(true);

  const getIcon = () => {
    if (type === 'success') return icon('checkCircle', 24);
    if (type === 'warning') return icon('alertTriangle', 24);
    if (type === 'info') return icon('alertCircle', 24);

    return icon('xCircle', 24);
  };

  const message = getMessage(type, { title, description, large: true });

  const closeAlert = () => {
    setOpen(false);

    if (onClose) {
      onClose();
    }
  };

  const className = clsx(styles.alert, {
    [styles[type]]: true,
    ...(customClassName && { [customClassName]: true }),
  });

  if (isOpen) {
    return (
      <div className={className}>
        <div data-toastify-body className={styles.alertBody}>
          <div data-toastify-icon className={styles.alertIcon}>
            {getIcon()}
          </div>
          <div>{message}</div>
        </div>
        {closable && <AlertCloseButton onClick={closeAlert} />}
      </div>
    );
  }

  return null;
};

Alert.CloseButton = AlertCloseButton;

export default Alert;
