import { useState } from 'react';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import Dropdown from '../../components/Dropdown';
import GlobalAccountCredentialsDialog from './GlobalAccountCredentialsDialog';
import useDropdown from './useDropdown';

interface Props {
  data: GlobalAccountSchema;
}

const GlobalAccountDropdown = (props: Props) => {
  const { data } = props;

  const [type, setType] = useState<'credentials' | null>(null);

  const { title, options } = useDropdown({ data, credentials: () => setType('credentials') });

  return (
    <>
      {type === 'credentials' && (
        <GlobalAccountCredentialsDialog accountId={data.id} onClose={() => setType(null)} />
      )}
      <Dropdown
        title={title}
        buttonSize="small"
        options={[
          options.schedule,
          options.resetCache,
          options.credentials,
          options.copy,
          options.divider,
          options.closePokerRoom,
        ]}
      />
    </>
  );
};

export default GlobalAccountDropdown;
