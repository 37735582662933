import COLORS from '../../../constants/COLORS';

export const CHART_COLORS = [
  COLORS.PURPLE_300,
  COLORS.PINK_300,
  COLORS.CYAN_300,
  COLORS.FUCHSIA_300,
  COLORS.GREEN_400,
  COLORS.ORANGE_400,
  COLORS.PURPLE_400,
  COLORS.PINK_400,
  COLORS.CYAN_400,
  COLORS.FUCHSIA_400,
  COLORS.GREEN_200,
  COLORS.ORANGE_200,
  COLORS.PURPLE_200,
  COLORS.PINK_200,
  COLORS.CYAN_200,
  COLORS.FUCHSIA_200,
  COLORS.GREEN_100,
  COLORS.ORANGE_100,
  COLORS.PURPLE_100,
  COLORS.PINK_100,
  COLORS.CYAN_100,
  COLORS.FUCHSIA_100,
  COLORS.GREEN_500,
  COLORS.ORANGE_500,
  COLORS.PURPLE_500,
  COLORS.PINK_500,
  COLORS.CYAN_500,
  COLORS.FUCHSIA_500,
];
