import { useTranslation } from 'react-i18next';
import { useTableContext } from '../components/Table';
import URLParams from '../types/URLParams';
import { handleError } from '../utils/form';
import notify from '../utils/notify';
import useURL from './useURL';

interface Args {
  params?: URLParams;
}

const useBulkActions = (args: Args = {}) => {
  const { params } = args;

  const { t } = useTranslation();
  const { urlParams } = useURL();
  const { selected, unselected, selectAllMode, disabled, unselectAll, setDisabled } =
    useTableContext();

  const handle = async (callback: () => Promise<unknown>, config = { notify: true }) => {
    setDisabled(true);

    try {
      await callback();

      unselectAll();

      if (config.notify) {
        notify('success', { title: t('common.action_completed') });
      }
    } catch (error) {
      handleError({ error });
    }

    setDisabled(false);
  };

  const { search: _, ...rest } = urlParams;

  return {
    ids: selectAllMode ? unselected : selected,
    params: { ...rest, ...params },
    selectAllMode,
    disabled,
    handle,
  };
};

export default useBulkActions;
