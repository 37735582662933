import clsx from 'clsx';
import { format, isSameMonth } from 'date-fns';
import { useDatePickerContext } from './DatePickerContext';
import styles from './styles.module.scss';

const DatePickerMonths = () => {
  const { value, months, setMonth } = useDatePickerContext();

  return (
    <div className={styles.grid}>
      {months.map((month) => {
        const className = clsx(styles.date, {
          [styles.today]: isSameMonth(month, new Date()),
          [styles.selected]: isSameMonth(month, new Date(value)),
        });

        const handleClick = () => setMonth(month);

        return (
          <button key={String(month)} type="button" className={className} onClick={handleClick}>
            {format(month, 'MMM')}
          </button>
        );
      })}
    </div>
  );
};

export default DatePickerMonths;
