import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { pick, keys } from 'remeda';
import { autoCreateWorkspaceAccounts } from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Form from '../../components/Form';
import PageAddon from '../../components/PageAddon';
import SubmitButton from '../../components/SubmitButton';
import usePrevious from '../../hooks/usePrevious';
import useWorkspace from '../../hooks/useWorkspace';
import notify from '../../utils/notify';
import { getTrainerPokerRoomOptions } from '../../utils/trainer';
import { WorkspaceAccountFields as Fields } from '../account/types';
import { getDefaultValues, getPayload } from './helpers';
import useSelectedFields from './useSelectedFields';
import { getFields } from './WorkspaceAccountForm/helpers';
import WorkspaceAccountFormAdd from './WorkspaceAccountForm/WorkspaceAccountFormAdd';
import WorkspaceAccountFormField from './WorkspaceAccountForm/WorkspaceAccountFormField';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
}

const WorkspaceAccountAutoCreateForm = (props: Props) => {
  const { workspaceId, onClose } = props;

  const { t } = useTranslation();

  const workspace = useWorkspace();

  const roomOptions = getTrainerPokerRoomOptions(workspace.poker_rooms);

  const defaultValues = getDefaultValues();

  const form = useForm<Fields & { nickname_pool?: string }>({
    defaultValues: { ...defaultValues, room: roomOptions[0] },
  });

  const formFields = getFields({ form, workspace, required: true });

  const fields = {
    room: formFields.room,
    channel_ids: formFields.channel_ids,
    club_ids: formFields.club_ids,
    game_types: formFields.game_types,
    comparative_limits: formFields.comparative_limits,
    table_sizes: formFields.table_sizes,
    max_tables: formFields.max_tables,
    tag_ids: formFields.tag_ids,
    affiliate_reference_number: formFields.affiliate_reference_number,
    user_id: formFields.user_id,
    geo: formFields.geo,
    identity_id: formFields.identity_id,
    bot_settings_ids: formFields.bot_settings_ids,
    deal_ids: formFields.deal_ids,
    nickname_pool: formFields.nickname_pool,
  } as const;

  const labels: Record<keyof typeof fields, string> = {
    room: t('common.room'),
    channel_ids: t('common.channels'),
    club_ids: t('common.clubs'),
    game_types: t('common.game_types'),
    comparative_limits: t('common.limits'),
    table_sizes: t('common.table_sizes'),
    max_tables: t('common.max_tables'),
    tag_ids: t('common.tags'),
    affiliate_reference_number: t('common.affiliate'),
    user_id: t('common.assign_to'),
    geo: t('common.geolocation'),
    identity_id: t('common.identity'),
    bot_settings_ids: t('common.bot_settings'),
    deal_ids: t('common.deals'),
    nickname_pool: t('common.nickname_pool'),
  };

  const defaultSelected: (keyof typeof fields)[] = ['room', 'channel_ids'];

  const { selectedFields, addField, removeField, fieldDisabled, resetFields } = useSelectedFields({
    fields,
    defaultSelected,
  });

  const room = form.watch('room');
  const channel_ids = form.watch('channel_ids');

  const prevRoom = usePrevious(room);
  const prevChannelIds = usePrevious(channel_ids);

  useEffect(() => {
    if (room?.value !== prevRoom?.value) {
      form.reset({ ...defaultValues, room });
      resetFields();
    }
  }, [room?.value, prevRoom?.value, room, form, defaultValues, resetFields]);

  useEffect(() => {
    if (channel_ids.length !== prevChannelIds.length) {
      resetFields();
    }
  }, [channel_ids.length, prevChannelIds.length, resetFields]);

  return (
    <PageAddon title={t('common.auto_create_account')} onClose={onClose}>
      <Form
        form={form}
        onSubmit={async (values) => {
          if (!values.room) throw new Error();

          const payload = { ...getPayload(values), room: values.room.value };

          await autoCreateWorkspaceAccounts({
            workspaceId,
            payload: pick(payload, selectedFields),
          });

          onClose();
          notify('success', { title: t('sentences.record_has_been_created') });
        }}
      >
        {t('sentences.auto_create_desc')}
        {selectedFields.map((item) => (
          <WorkspaceAccountFormField
            key={item}
            {...(!defaultSelected.includes(item) && { onRemove: () => removeField(item) })}
          >
            {fields[item]}
          </WorkspaceAccountFormField>
        ))}
        <WorkspaceAccountFormAdd
          options={keys(fields)
            .filter((item) => !defaultSelected.includes(item))
            .map((item) => ({
              label: labels[item],
              disabled: fieldDisabled(item),
              onClick: () => addField(item),
            }))}
        />
        <PageAddon.Controls>
          <SubmitButton disabled={!channel_ids.length}>{t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default WorkspaceAccountAutoCreateForm;
