import { Editor as TipTapEditor } from '@tiptap/react';
import clsx from 'clsx';
import { ReactNode } from 'react';
import icon from '../../static/icons';
import styles from './styles.module.scss';

interface Props {
  editor: TipTapEditor | null;
  children: ReactNode;
}

const EditorToolbar = (props: Props) => {
  const { editor, children } = props;

  if (!editor) return null;

  return (
    <div className={styles.toolbar}>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={clsx(editor.isFocused && editor.isActive('bold') && styles.active)}
      >
        {icon('bold', 16)}
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={clsx(editor.isFocused && editor.isActive('bulletList') && styles.active)}
      >
        {icon('list', 16)}
      </button>
      {children}
    </div>
  );
};

export default EditorToolbar;
