import { useTranslation } from 'react-i18next';
import { IdentitySchema } from '../../../api/schemas/identitySchema';
import { MarketIdentitySchema } from '../../../api/schemas/marketIdentitySchema';
import PageAddon from '../../../components/PageAddon';
import Tooltip from '../../../components/Tooltip';
import icon from '../../../static/icons';
import styles from './styles.module.scss';

type Channel = IdentitySchema['channel'];
type MarketChannel = MarketIdentitySchema['channel'];

interface Props {
  channel: Channel | MarketChannel;
}

const WorkspaceIdentityPageAddonFieldChannel = (props: Props) => {
  const { t } = useTranslation();

  const { channel } = props;

  return (
    <PageAddon.Field
      label={
        <>
          {t('common.channel')}
          <Tooltip label={t('sentences.to_get_information_contact_with_seller')}>
            {icon('helpCircle', 16, { className: styles.icon })}
          </Tooltip>
        </>
      }
    >
      {channel ? t('common.true') : t('common.false')}
    </PageAddon.Field>
  );
};

export default WorkspaceIdentityPageAddonFieldChannel;
