import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import WorkspaceChannelDetail from '../../features/workspace-channel/WorkspaceChannelDetail';
import WorkspaceChannelForm from '../../features/workspace-channel/WorkspaceChannelForm';
import WorkspaceChannelImport from '../../features/workspace-channel/WorkspaceChannelImport';
import WorkspaceChannelLoader from '../../features/workspace-channel/WorkspaceChannelLoader';
import WorkspaceChannelsPage from '../../pages/WorkspaceChannelsPage';
import Route from './Route';

const page = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceChannelsPage workspaceId={workspaceId} />}
  </Route>
);

const detail = (
  <Route params={['workspaceId', 'channelId']}>
    {({ workspaceId, channelId, navigate }) => (
      <WorkspaceChannelLoader workspaceId={workspaceId} channelId={channelId}>
        {({ data }) => (
          <WorkspaceChannelDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceChannels({ workspaceId }))}
          />
        )}
      </WorkspaceChannelLoader>
    )}
  </Route>
);

const createForm = (
  <Route params={['workspaceId']}>
    {({ workspaceId, navigate }) => (
      <WorkspaceChannelForm
        workspaceId={workspaceId}
        onClose={() => navigate(routes.workspaceChannels({ workspaceId }))}
        onCreate={({ channelId }) => navigate(routes.workspaceChannel({ workspaceId, channelId }))}
      />
    )}
  </Route>
);

const importForm = (
  <Route params={['workspaceId']}>
    {({ workspaceId, navigate }) => (
      <WorkspaceChannelImport
        workspaceId={workspaceId}
        onClose={() => navigate(routes.workspaceChannels({ workspaceId }))}
      />
    )}
  </Route>
);

const workspaceChannelsPage: RouteObject = {
  path: routes.workspaceChannels({ workspaceId: ':workspaceId' }),
  element: page,
  children: [
    {
      path: routes.workspaceChannelsCreate({ workspaceId: ':workspaceId' }),
      element: createForm,
    },
    {
      path: routes.workspaceChannelsImport({ workspaceId: ':workspaceId' }),
      element: importForm,
    },
    {
      path: routes.workspaceChannel({
        workspaceId: ':workspaceId',
        channelId: ':channelId',
      }),
      element: detail,
    },
  ],
};

export default workspaceChannelsPage;
