import api from '../../services/api';
import globalExpenseSchema, { GlobalExpenseSchema } from '../schemas/globalExpenseSchema';

interface Args {
  expenseId: GlobalExpenseSchema['id'];
}

const loadGlobalExpense = async (args: Args) => {
  const { expenseId } = args;

  const response = await api.get(`/api/expenses/${expenseId}`);

  return globalExpenseSchema.parse(response.data);
};

export default loadGlobalExpense;
