import clsx from 'clsx';
import { useState } from 'react';
import useDimensions from 'react-cool-dimensions';
import { Props as PageAddonProps } from '..';
import Tooltip from '../../Tooltip';
import styles from './styles.module.scss';

interface Props {
  tabs: Exclude<PageAddonProps['tabs'], undefined>;
  tabIndex: number;
  onChange: (tabIndex: number) => void;
}

const PageAddonTitleTabs = (props: Props) => {
  const { tabs, tabIndex, onChange } = props;

  const [left, setLeft] = useState<number | null>(null);
  const [width, setWidth] = useState<number | null>(null);

  const { observe } = useDimensions<HTMLElement>({
    onResize: (event) => {
      const { target } = event.entry;

      if (target instanceof HTMLElement) {
        setLeft(target.offsetLeft);
        setWidth(target.getBoundingClientRect().width);
      }
    },
  });

  return (
    <div className={styles.container}>
      {tabs.map((item, index) => {
        const { label, count, tooltip, disabled } = item;
        const active = index === tabIndex;

        return (
          <Tooltip key={index} label={tooltip} {...(active && { ref: observe })}>
            <button
              type="button"
              onClick={() => onChange(index)}
              disabled={disabled}
              className={clsx(styles.button, active && styles.active)}
            >
              <div>
                {label}
                {Boolean(count) && <span>{` (${count})`}</span>}
              </div>
            </button>
          </Tooltip>
        );
      })}
      <div
        className={styles.indicator}
        {...(left && width && { style: { left: `${left}px`, width: `${width}px` } })}
      />
    </div>
  );
};

export default PageAddonTitleTabs;
