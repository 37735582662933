import { WorkspaceClubManagerSchema, STATUS } from '../../api/schemas/workspaceClubManagerSchema';
import Badge, { Color } from '../../components/Badge';
import { CLUB_MANAGER_STATUSES_LABELS } from './helpers';

const STATUS_COLORS: Record<WorkspaceClubManagerSchema['status'], Color> = {
  [STATUS.UNKNOWN]: 'grey',
  [STATUS.STOPPED]: 'orange',
  [STATUS.STARTED]: 'orange',
  [STATUS.OFFLINE]: 'grey',
  [STATUS.ONLINE]: 'green',
};

interface Props {
  data: WorkspaceClubManagerSchema;
}

const WorkspaceClubManagerStatus = (props: Props) => {
  const { data } = props;

  const { status } = data;

  return <Badge color={STATUS_COLORS[status]}>{CLUB_MANAGER_STATUSES_LABELS[status]}</Badge>;
};

export default WorkspaceClubManagerStatus;
