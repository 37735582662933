import { useTranslation } from 'react-i18next';
import useBreakpoints from '../../hooks/useBreakpoints';
import icon from '../../static/icons';
import Button from '../Button';
import Dropdown, { Options } from '../Dropdown';

interface Props {
  children: React.ReactNode;
  more?: Options;
  disabled?: boolean;
  onClick?: () => void;
}

const PageCreateButton = (props: Props) => {
  const { disabled, children, more, onClick } = props;

  const { t } = useTranslation();
  const { isPhone } = useBreakpoints();

  const commonProps = {
    disabled,
    size: isPhone ? ('small' as const) : ('medium' as const),
    'data-testid': 'create',
  };

  const content = (
    <Button icon={icon('add', 16)} onClick={onClick} {...commonProps}>
      {isPhone ? null : children}
    </Button>
  );

  if (more) {
    return (
      <Button.Group>
        {content}
        <Button.Group.Divider />
        <Dropdown title={t('common.options')} options={more} arrowOffset={12}>
          {() => <Button icon={icon('chevronDown', 16)} {...commonProps} />}
        </Dropdown>
      </Button.Group>
    );
  }

  return content;
};

export default PageCreateButton;
