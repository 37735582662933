import jsFileDownload from 'js-file-download';
import { uploadFile } from '../api';
import { FileSchema } from '../api/schemas/fileSchema';
import Files from '../types/Files';

export const uploadFiles = async (files: Files) => {
  const promises = files.map((item) =>
    item.file ? uploadFile({ payload: { file: item.file } }) : item.url
  );

  const response = await Promise.all(promises);

  return response.map((item) => (typeof item === 'string' ? item.split('/')[6] : item.id));
};

export const getFileURL = (file: FileSchema, service: 'identity' | 'notes' | 'transactions') =>
  `${window.BASE_URL}/api/file/${service}/${file.id}`;

interface ModifiedFile extends File {
  error?: string;
}

export const handleDrop = (files: ModifiedFile[]) =>
  files.map((item) => ({
    name: item.name,
    content_type: item.type,
    url: URL.createObjectURL(item),
    file: item,
    ...(item.error && { error: item.error }),
  }));

export const downloadFile = jsFileDownload;
