import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadWorkspaceUsers } from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { WorkspaceUserSchema } from '../../api/schemas/workspaceUserSchema';
import Card from '../../components/Card';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import WorkspaceUserDetailLink from '../../features/workspace-user/WorkspaceUserDetailLink';
import WorkspaceUserDropdown from '../../features/workspace-user/WorkspaceUserDropdown';
import WorkspaceUserFilter from '../../features/workspace-user/WorkspaceUserFilter';
import WorkspaceUserInviteButton from '../../features/workspace-user/WorkspaceUserInviteButton';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedDataNew from '../../hooks/usePaginatedDataNew';
import useURL from '../../hooks/useURL';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { formatRelativeDate } from '../../utils/date';
import { RouteParams } from '../../utils/generics';
import {
  deleteInfinitePaginatedQueryData,
  updateInfinitePaginatedQueryData,
} from '../../utils/queryClient';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.workspaceUser>;

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceUsersPage = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { userId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search } = urlParams;

  const queryKey = [queryKeys.workspaceUsers({ workspaceId }), urlParams];

  const query = usePaginatedDataNew({
    queryKey,
    queryFn: (params) => loadWorkspaceUsers({ workspaceId }, { ...urlParams, ...params }),
  });

  const { data, error, checkIsInView } = query;

  const { checkIsCreated } = useWebSocketPaginatedActions<
    {
      id: string;
      participant: WorkspaceUserSchema;
    },
    WorkspaceUserSchema
  >({
    queryKey,
    domain: 'Maintenance',
    createAction: ['ParticipantAdded'],
    deleteAction: ['ParticipantRemoved'],
    select: (item) => item.participant,
    enabled: (message) => message.payload.id === workspaceId,
  });

  useWebSocketMessage<WorkspaceUserSchema>({
    domain: 'User',
    onMessage: (message) => {
      if (message.action === 'EmailVerified') {
        updateInfinitePaginatedQueryData(
          queryKey,
          message.payload,
          (item) => item.id === message.payload.id
        );
      }
    },
  });

  useWebSocketMessage<{
    id: WorkspaceUserSchema['id'];
    modified_on: string;
    participant: WorkspaceUserSchema;
  }>({
    domain: 'Maintenance',
    onMessage: (message) => {
      if (message.action === 'ParticipantRemoved') {
        deleteInfinitePaginatedQueryData(
          queryKey,
          (item) => item.id === message.payload.participant.id
        );
      }
    },
  });

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <Page
      heading={t('common.members')}
      extra={
        <PageControls>
          <WorkspaceUserInviteButton workspaceId={workspaceId} />
        </PageControls>
      }
      addon={<Outlet />}
      filter={<WorkspaceUserFilter />}
    >
      {isPhone && (
        <PageTop
          sorting={[
            { key: 'username', title: t('common.login'), default: true },
            { key: 'invited_on', title: t('common.invited') },
          ]}
        />
      )}
      {(isDesktop || isTablet) && (
        <Table
          data={data}
          search={search}
          {...getTableProps(query)}
          className={styles.table}
          columns={[
            {
              key: 'username',
              title: t('common.login'),
              sorting: true,
              render: ({ values }) => (
                <WorkspaceUserDetailLink workspaceId={workspaceId} data={values} />
              ),
            },
            {
              key: 'invited_on',
              title: t('common.invited'),
              sorting: true,
              render: ({ values }) => formatRelativeDate(values.invited_on),
            },
            {
              render: ({ values }) => (
                <WorkspaceUserDropdown workspaceId={workspaceId} data={values} />
              ),
              align: 'right',
              controls: true,
            },
          ]}
          renderRow={(row, index) => (
            <Table.Row
              highlighted={checkIsCreated(row.data.id)}
              active={row.data.id === userId}
              {...checkIsInView(index)}
              {...row}
            />
          )}
        />
      )}
      {isPhone && (
        <Card.Group>
          {data.map((item, index) => (
            <Card
              key={item.id}
              highlighted={checkIsCreated(item.id)}
              danger={item.is_deleted}
              {...checkIsInView(index)}
            >
              <Card.Top>
                <WorkspaceUserDetailLink workspaceId={workspaceId} data={item} />
                <Card.Top.Controls>
                  <WorkspaceUserDropdown workspaceId={workspaceId} data={item} />
                </Card.Top.Controls>
              </Card.Top>
              <Card.Field.Group>
                <Card.Field label={t('common.invited')}>
                  {formatRelativeDate(item.invited_on)}
                </Card.Field>
              </Card.Field.Group>
            </Card>
          ))}
        </Card.Group>
      )}
    </Page>
  );
};

export default WorkspaceUsersPage;
