import clsx from 'clsx';
import { format, isSameDay } from 'date-fns';
import { useState, Fragment, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import { FileSchema } from '../../api/schemas/fileSchema';
import { NoteSchema } from '../../api/schemas/noteSchema';
import { MB } from '../../constants';
import { MONTH_DAY, MONTH_DAY_YEAR, TIME } from '../../constants/DATE_FORMATS';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import icon from '../../static/icons';
import { useUserStore } from '../../store/user';
import Files from '../../types/Files';
import { getDate } from '../../utils/date';
import { getFileURL, handleDrop } from '../../utils/files';
import {
  createInfinitePaginatedQueryData,
  deleteInfinitePaginatedQueryData,
  updateInfinitePaginatedQueryData,
} from '../../utils/queryClient';
import Button from '../Button';
import Confirm from '../Confirm';
import Empty from '../Empty';
import FilesList from '../FilesList';
import MediaViewer from '../MediaViewer';
import PageAddon from '../PageAddon';
import Spinner from '../Spinner';
import Tooltip from '../Tooltip';
import { useUploadFilesMutation } from './mutations';
import NotesButton from './NotesButton';
import styles from './styles.module.scss';
import { Props as NotesProps } from './types';

const MAX_FILES = 10;

interface Payload {
  message: string;
  file_ids: string[];
}

type Props = {
  onClose: () => void;
} & NotesProps;

const Notes = (props: Props) => {
  const { onClose, creating, updating, onCreate, onUpdate, onDelete, onCreateMessage, query } =
    props;

  const { data, queryKey } = query;

  const { t } = useTranslation();

  const { data: userData } = useUserStore();

  const [value, setValue] = useState('');
  const [files, setFiles] = useState<Files>([]);
  const [note, setNote] = useState<NoteSchema | null>(null);

  const uploadFiles = useUploadFilesMutation();

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const getDisabled = () => {
    if (files.some((item) => item.error)) return true;

    if (!value.trim() && !files.length) return true;

    if (creating) return true;
    if (updating) return true;
    if (uploadFiles.isPending) return true;

    return false;
  };

  const disabled = getDisabled();

  const cancelEdit = () => {
    if (note) {
      setNote(null);
      setValue('');
      setFiles([]);
    }
  };

  useHotkeys('esc', cancelEdit, { enableOnFormTags: true });

  useWebSocketMessage<NoteSchema>({
    domain: 'Note',
    onMessage: (message) => {
      const { payload } = message;

      if (message.action === 'Created' && onCreateMessage(message)) {
        createInfinitePaginatedQueryData(queryKey, payload);
      }

      if (message.action === 'Edited') {
        updateInfinitePaginatedQueryData(
          queryKey,
          message.payload,
          (item) => item.id === payload.id
        );
      }

      if (message.action === 'Deleted') {
        deleteInfinitePaginatedQueryData(queryKey, (item) => item.id === payload.id);
      }
    },
  });

  const handleNote = async () => {
    if (!disabled) {
      const file_ids = await uploadFiles.mutateAsync(files);

      const payload: Payload = { message: value.trim(), file_ids };

      if (note) {
        await onUpdate({ noteId: note.id, payload });

        cancelEdit();
      } else {
        await onCreate({ payload });

        setValue('');
        setFiles([]);
      }
    }
  };

  const disabledAttach = files.length >= MAX_FILES;

  const { isDragActive, getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxSize: 3 * MB,
    maxFiles: MAX_FILES,
    disabled: disabledAttach,
    onDrop: (acceptedFiles, fileRejections) =>
      setFiles((prev) =>
        [
          ...prev,
          ...handleDrop([
            ...acceptedFiles,
            ...fileRejections.map((item) =>
              Object.assign(item.file, {
                error: item.errors[0].message.replace('3145728 bytes', '3 Mb'),
              })
            ),
          ]),
        ].slice(0, MAX_FILES)
      ),
  });

  const preparedFiles = files.map((item) => ({
    name: item.name,
    src: item.url,
    ...(item.error && { error: item.error }),
    onRemove: () => setFiles((prev) => prev.filter((file) => file.url !== item.url)),
  }));

  const getFiles = (noteFiles: FileSchema[]) =>
    noteFiles.map((item) => ({
      name: item.original_name,
      src: getFileURL(item, 'notes'),
    }));

  // scroll up
  // confirm delete

  return (
    <PageAddon onClose={onClose} title={t('common.notes')} className={styles.addon}>
      <div {...getRootProps()} className={styles.container}>
        {isDragActive && (
          <div className={styles.dropzone}>
            <div>
              <div>{t('sentences.drag_and_drop_here')}</div>
              <span>{t('sentences.file_must_be_not_larger_than', { value: 3 })}</span>
            </div>
          </div>
        )}
        {!!data.length && (
          <div className={styles.notes}>
            {data.map((item, index) => {
              const nextNote = data[index + 1];
              const own = item.author.id === userData?.id;

              const date = getDate(item.created_on);

              const isTomorrow = () => {
                if (nextNote) {
                  if (!isSameDay(date, getDate(nextNote.created_on))) return true;

                  return false;
                }

                return true;
              };

              return (
                <Fragment key={item.id}>
                  <div
                    {...(data.length - 1 - index === 0 && { ref: query.ref })}
                    className={clsx(
                      styles.note,
                      own && styles.own,
                      item.id === note?.id && styles.editing
                    )}
                  >
                    <span className={clsx(styles.time, own && styles.own)}>
                      {own && (
                        <>
                          <Button
                            variant="transparent"
                            size="extra-small"
                            icon={icon('edit', 16)}
                            onClick={() => {
                              const noteFiles = item.files.map((el) => ({
                                name: el.original_name,
                                content_type: el.content_type,
                                url: getFileURL(el, 'notes'),
                              }));

                              setNote(item);
                              setValue(item.message);
                              setFiles(noteFiles);
                              textareaRef.current?.focus();
                            }}
                          />
                          <Confirm
                            confirmText={t('common.delete')}
                            onConfirm={() => onDelete({ noteId: item.id })}
                          >
                            {({ confirming }) => (
                              <Button
                                danger
                                focused={confirming}
                                variant="transparent"
                                size="extra-small"
                                icon={icon('trash', 16)}
                                disabled={item.id === note?.id}
                              />
                            )}
                          </Confirm>
                        </>
                      )}
                      {format(date, TIME)}
                      {item.created_on !== item.modified_on && icon('edit', 12)}
                    </span>
                    <div>
                      {item.message}
                      <MediaViewer
                        className={clsx(styles.mediaViewer, styles.message, own && styles.own)}
                        files={getFiles(item.files)}
                      />
                      <FilesList className={styles.filesList} files={getFiles(item.files)} />
                    </div>
                  </div>
                  {(!(nextNote?.author.id === item.author.id) || isTomorrow()) && (
                    <div className={clsx(styles.author, own && styles.own)}>
                      {item.author.username}
                    </div>
                  )}
                  {isTomorrow() && (
                    <Tooltip delay={0.5} label={format(date, MONTH_DAY_YEAR)}>
                      <div className={styles.day} style={{ zIndex: data.length - index }}>
                        {format(date, MONTH_DAY)}
                      </div>
                    </Tooltip>
                  )}
                </Fragment>
              );
            })}
          </div>
        )}
        {!data.length && !query.loading && <Empty title={t('common.no_notes')} />}
        {query.loading && (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        )}
        {query.loadingMore && (
          <div className={clsx(styles.spinner, styles.more)}>
            <Spinner />
          </div>
        )}
      </div>
      <div className={styles.bottom}>
        {!!files.length && (
          <div className={styles.files}>
            <MediaViewer
              className={clsx(styles.mediaViewer, styles.preview)}
              files={preparedFiles}
            />
            <FilesList download={false} className={styles.filesList} files={preparedFiles} />
          </div>
        )}
        <div className={styles.controls}>
          <Tooltip label={disabledAttach && t('sentences.attach_max_files')}>
            <Button
              variant="transparent"
              size="small"
              onClick={open}
              icon={icon('paperclip', 16)}
              disabled={disabledAttach}
            />
          </Tooltip>
          <input {...getInputProps()} className={styles.input} />
          <TextareaAutosize
            ref={textareaRef}
            maxRows={4}
            value={value}
            placeholder={t('sentences.write_a_note')}
            onChange={(event) => setValue(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey) {
                event.preventDefault();

                if (event.currentTarget.value.trim()) {
                  handleNote();
                }
              }

              if (event.key === 'ArrowUp') {
                const lastNote = data[0];
                const own = lastNote.author.id === userData?.id;

                if (lastNote && own) {
                  setNote(lastNote);
                  setValue(lastNote.message);
                }
              }
            }}
          />
          <div className={styles.buttons}>
            {note && (
              <Button
                danger
                variant="transparent"
                size="small"
                icon={icon('cross', 16)}
                onClick={cancelEdit}
              />
            )}
            <Button
              variant="transparent"
              size="small"
              icon={note ? icon('check', 16) : icon('send', 16)}
              disabled={disabled}
              loading={creating || uploadFiles.isPending}
              onClick={handleNote}
            />
          </div>
        </div>
      </div>
    </PageAddon>
  );
};

Notes.Button = NotesButton;

export default Notes;
