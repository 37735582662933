import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadWorkspaceClubMembersFilterValues } from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Filter from '../../components/Filter';
import queryKeys from '../../config/queryKeys';
import useFilter from '../../hooks/useFilter';
import useURL from '../../hooks/useURL';
import { convertCentsToDollars, convertDollarsToCents } from '../../utils/data';
import { TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';

interface Fields {
  room_in: string[];
  club_in: string[];
  manager_in: string[];
  balance_ge: string;
  balance_le: string;
  total_fee_ge: string;
  total_fee_le: string;
  winnings_ge: string;
  winnings_le: string;
  hands_ge: string;
  hands_le: string;
  updated_ge: string;
  updated_le: string;
  created_ge: string;
  created_le: string;
  show_accept: boolean;
  show_kick: boolean;
  show_deleted: boolean;
}

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceClubMemberFilter = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { urlParams } = useURL();

  const queryKey = [
    queryKeys.workspaceClubMembersFilterValues({ workspaceId }),
    urlParams.show_accept,
    urlParams.show_kick,
    urlParams.show_deleted,
  ];
  const queryFn = () =>
    loadWorkspaceClubMembersFilterValues(
      { workspaceId },
      {
        show_accept: urlParams.show_accept,
        show_kick: urlParams.show_kick,
        show_deleted: urlParams.show_deleted,
      }
    );

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  const filter = useFilter<Fields>({
    room_in: 'multi',
    club_in: 'multi',
    manager_in: 'multi',
    balance_ge: 'single',
    balance_le: 'single',
    total_fee_ge: 'single',
    total_fee_le: 'single',
    winnings_ge: 'single',
    winnings_le: 'single',
    hands_ge: 'single',
    hands_le: 'single',
    updated_ge: 'single',
    updated_le: 'single',
    created_ge: 'single',
    created_le: 'single',
    show_accept: 'toggle',
    show_kick: 'toggle',
    show_deleted: 'toggle',
  });

  const { values, setValue, clearValue } = filter;

  const {
    room_in,
    club_in,
    manager_in,
    balance_ge,
    balance_le,
    total_fee_ge,
    total_fee_le,
    winnings_ge,
    winnings_le,
    hands_ge,
    hands_le,
    updated_ge,
    updated_le,
    created_ge,
    created_le,
    show_accept,
    show_kick,
    show_deleted,
  } = values;

  const filterProps = { filter };

  if (isPending) {
    return (
      <Filter {...filterProps}>
        <Filter.Placeholder />
      </Filter>
    );
  }

  if (!data) return null;

  const { room, club, manager, balance, total_fee, winnings, hands } = data;

  return (
    <Filter {...filterProps}>
      <div>
        {room.length !== 0 && (
          <Filter.Collapse
            label={t('common.poker_room')}
            count={room_in.length}
            onClear={clearValue('room_in')}
          >
            <Filter.MultiCheckbox
              config={room.map((item) => ({
                value: String(item),
                label: TRAINER_POKER_ROOM_LABELS[item],
                onChange: setValue('room_in'),
                checked: room_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {club.length !== 0 && (
          <Filter.Collapse
            label={t('common.club')}
            count={club_in.length}
            onClear={clearValue('club_in')}
          >
            <Filter.MultiCheckbox
              config={club.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('club_in'),
                checked: club_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        {manager.length !== 0 && (
          <Filter.Collapse
            label={t('common.manager')}
            count={manager_in.length}
            onClear={clearValue('manager_in')}
          >
            <Filter.MultiCheckbox
              config={manager.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('manager_in'),
                checked: manager_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        <Filter.RangeInput
          range={false}
          label={t('common.balance')}
          step={1}
          decimalScale={2}
          min={balance.min}
          max={balance.max}
          valueMin={balance_ge}
          valueMax={balance_le}
          fieldMin="balance_ge"
          fieldMax="balance_le"
          formatter={convertCentsToDollars}
          valueFormatter={convertDollarsToCents}
        />
        <Filter.RangeInput
          range={false}
          label={t('common.total_fee')}
          step={1}
          decimalScale={2}
          min={total_fee.min}
          max={total_fee.max}
          valueMin={total_fee_ge}
          valueMax={total_fee_le}
          fieldMin="total_fee_ge"
          fieldMax="total_fee_le"
          formatter={convertCentsToDollars}
          valueFormatter={convertDollarsToCents}
        />
        <Filter.RangeInput
          range={false}
          label={t('common.winnings')}
          step={1}
          decimalScale={2}
          min={winnings.min}
          max={winnings.max}
          valueMin={winnings_ge}
          valueMax={winnings_le}
          fieldMin="winnings_ge"
          fieldMax="winnings_le"
          formatter={convertCentsToDollars}
          valueFormatter={convertDollarsToCents}
        />
        <Filter.RangeInput
          range={false}
          label={t('common.hands')}
          step={1}
          min={hands.min}
          max={hands.max}
          valueMin={hands_ge}
          valueMax={hands_le}
          fieldMin="hands_ge"
          fieldMax="hands_le"
        />
        <Filter.Collapse
          label={t('common.modified')}
          {...((updated_ge || updated_le) && {
            onClear: clearValue(['updated_ge', 'updated_le']),
          })}
        >
          <Filter.Date
            from={{ value: updated_ge, onChange: setValue('updated_ge') }}
            to={{ value: updated_le, onChange: setValue('updated_le') }}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.created')}
          {...((created_ge || created_le) && {
            onClear: clearValue(['created_ge', 'created_le']),
          })}
        >
          <Filter.Date
            from={{ value: created_ge, onChange: setValue('created_ge') }}
            to={{ value: created_le, onChange: setValue('created_le') }}
          />
        </Filter.Collapse>
      </div>
      <Filter.Switcher checked={show_accept} onChange={setValue('show_accept')}>
        {t('common.show_accepted')}
      </Filter.Switcher>
      <Filter.Switcher checked={show_kick} onChange={setValue('show_kick')}>
        {t('common.show_awaiting_kick_off')}
      </Filter.Switcher>
      <Filter.Switcher checked={show_deleted} onChange={setValue('show_deleted')}>
        {t('common.show_kicked')}
      </Filter.Switcher>
    </Filter>
  );
};

export default WorkspaceClubMemberFilter;
