import MediaViewer, { Files } from '../../MediaViewer';
import styles from './styles.module.scss';

interface Props {
  files: Files;
}

const PageAddonMedia = (props: Props) => {
  const { files } = props;

  return <MediaViewer files={files} className={styles.container} />;
};

export default PageAddonMedia;
