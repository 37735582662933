import { ReactNode } from 'react';
import styles from '../styles.module.scss';
import ButtonGroupDivider from './ButtonGroupDivider';

interface Props {
  children: ReactNode;
}

const ButtonGroup = (props: Props) => {
  const { children } = props;

  return <div className={styles.buttonGroup}>{children}</div>;
};

ButtonGroup.Divider = ButtonGroupDivider;

export default ButtonGroup;
