import { useTranslation } from 'react-i18next';
import { WorkspaceSchema } from '../../../../api/schemas/workspaceSchema';
import Button from '../../../../components/Button';
import icon from '../../../../static/icons';
import { useCartStore } from '../../../../store/cart';
import { convertCentsToUSDT } from '../../../../utils/data';
import WorkspaceSettingsDepositDialog from '../../../workspace-settings/WorkspaceSettingsDepositDialog';
import { useWorkspaceBalanceQuery } from '../../../workspace-settings/WorkspaceSettingsGeneral/queries';
import styles from './styles.module.scss';

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceMarketIdentityCartBalance = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();

  const { data } = useWorkspaceBalanceQuery({ workspaceId });

  const { getBalance } = useCartStore((state) => ({
    getBalance: state.getBalance,
  }));

  const renderError = () => {
    if (!data) return null;

    const balance = getBalance(workspaceId, data.balance);

    if (!balance) return null;

    return (
      <>
        <div className={styles.error}>
          {t('sentences.not_enough_balance')}
          &nbsp;
          <span>({balance})</span>
        </div>
        <WorkspaceSettingsDepositDialog workspaceId={workspaceId}>
          {({ referenceProps }) => (
            <Button
              size="small"
              icon={icon('add', 16)}
              className={styles.button}
              {...referenceProps}
            >
              {t('common.deposit')}
            </Button>
          )}
        </WorkspaceSettingsDepositDialog>
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.title}>{t('common.balance_USDT')}</div>
        <div className={styles.line} />
        {data && <div className={styles.value}>{convertCentsToUSDT(data.balance)}</div>}
      </div>
      {renderError()}
    </div>
  );
};

export default WorkspaceMarketIdentityCartBalance;
