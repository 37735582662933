import api from '../../services/api';
import URLParams from '../../types/URLParams';
import agentSchema from '../schemas/agentSchema';
import paginationSchemaNew from '../schemas/paginationSchemaNew';

const loadGlobalAgents = async (params?: URLParams) => {
  const response = await api.get('/api/agent', { params });

  return paginationSchemaNew(agentSchema).parse(response.data);
};

export default loadGlobalAgents;
