import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalSuperLeagueDetail from '../../features/global-super-league/GlobalSuperLeagueDetail';
import GlobalSuperLeagueForm from '../../features/global-super-league/GlobalSuperLeagueForm';
import GlobalSuperLeagueLoader from '../../features/global-super-league/GlobalSuperLeagueLoader';
import GlobalSuperLeaguesPage from '../../pages/GlobalSuperLeaguesPage';
import Route from './Route';

const page = <GlobalSuperLeaguesPage />;

const createForm = (
  <Route>
    {({ navigate }) => (
      <GlobalSuperLeagueForm
        onClose={() => navigate(routes.globalSuperLeagues)}
        onCreate={({ superLeagueId }) => navigate(routes.globalSuperLeague({ superLeagueId }))}
      />
    )}
  </Route>
);

const detail = (
  <Route params={['superLeagueId']}>
    {({ superLeagueId, navigate }) => (
      <GlobalSuperLeagueLoader superLeagueId={superLeagueId}>
        {({ data }) => (
          <GlobalSuperLeagueDetail
            data={data}
            onClose={() => navigate(routes.globalSuperLeagues)}
            onEdit={() => navigate(routes.globalSuperLeagueEdit({ superLeagueId }))}
          />
        )}
      </GlobalSuperLeagueLoader>
    )}
  </Route>
);

const editForm = (
  <Route params={['superLeagueId']}>
    {({ superLeagueId, navigate }) => (
      <GlobalSuperLeagueLoader superLeagueId={superLeagueId}>
        {({ data }) => (
          <GlobalSuperLeagueForm
            data={data}
            onClose={() => navigate(routes.globalSuperLeague({ superLeagueId }))}
            onEdit={() => navigate(routes.globalSuperLeague({ superLeagueId }))}
          />
        )}
      </GlobalSuperLeagueLoader>
    )}
  </Route>
);

const globalSuperLeaguesPage: RouteObject = {
  path: routes.globalSuperLeagues,
  element: page,
  children: [
    {
      path: routes.globalSuperLeaguesCreate,
      element: createForm,
    },
    {
      path: routes.globalSuperLeague({ superLeagueId: ':superLeagueId' }),
      element: detail,
    },
    {
      path: routes.globalSuperLeagueEdit({ superLeagueId: ':superLeagueId' }),
      element: editForm,
    },
  ],
};

export default globalSuperLeaguesPage;
