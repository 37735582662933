import {
  addDays,
  setYear,
  getYear,
  setMonth,
  subYears,
  getMonth,
  addYears,
  setHours,
  subMonths,
  addMonths,
  endOfWeek,
  endOfYear,
  setMinutes,
  endOfMonth,
  startOfWeek,
  startOfYear,
  startOfHour,
  startOfMonth,
  eachDayOfInterval,
  eachYearOfInterval,
  eachMonthOfInterval,
  getHours as getHour,
  getMinutes as getMinute,
} from 'date-fns';

const MONDAY = 1;

export const getDaysOfWeek = (date: Date) => {
  const weekStart = startOfWeek(date, { weekStartsOn: MONDAY });
  const weekEnd = endOfWeek(date, { weekStartsOn: MONDAY });

  const daysOfWeek = eachDayOfInterval({ start: weekStart, end: weekEnd });

  return daysOfWeek;
};

export const getDays = (date: Date) => {
  const GRID_DAYS_AMOUNT = 42;

  const monthStart = startOfMonth(date);
  const monthEnd = endOfMonth(date);
  const monthDays = eachDayOfInterval({ start: addDays(monthStart, 1), end: monthEnd });

  const monthStartWeekDay = startOfWeek(monthStart, { weekStartsOn: MONDAY });

  const prevMonthDays = eachDayOfInterval({ start: monthStartWeekDay, end: monthStart });

  const nextMonthDays = eachDayOfInterval({
    start: addDays(monthEnd, 1),
    end: addDays(monthEnd, GRID_DAYS_AMOUNT - (prevMonthDays.length + monthDays.length)),
  });

  const days = [prevMonthDays, monthDays, nextMonthDays].flat();

  return days;
};

export const getMonths = (date: Date) => {
  const yearStart = startOfYear(date);
  const yearEnd = endOfYear(date);

  const months = eachMonthOfInterval({ start: yearStart, end: yearEnd });

  return months;
};

export const getYears = (date: Date) => {
  const start = subYears(date, 11);
  const end = date;

  const years = eachYearOfInterval({ start, end });

  return years;
};

export const getHours = (date: Date) => {
  const hours = [];

  for (let i = 0; i < 24; i += 1) {
    hours.push(setHours(date, i));
  }

  return hours;
};

export const getMinutes = (date: Date) => {
  const minutes = [];

  for (let i = 0; i < 60; i += 1) {
    minutes.push(setMinutes(date, i));
  }

  return minutes;
};

export const getPrevMonth = (date: Date) => subMonths(date, 1);
export const getNextMonth = (date: Date) => addMonths(date, 1);

export const getPrevYear = (date: Date) => subYears(date, 1);
export const getNextYear = (date: Date) => addYears(date, 1);

export const getPrevDecade = (date: Date) => subYears(date, 10);
export const getNextDecade = (date: Date) => addYears(date, 10);

export const getDateWithNewMonth = (date: Date) => setMonth(date, getMonth(date));
export const getDateWithNewYear = (date: Date) => setYear(date, getYear(date));
export const getDateWithNewHour = (date: Date) => startOfHour(setHours(date, getHour(date)));
export const getDateWithNewMinute = (date: Date) => setMinutes(date, getMinute(date));
