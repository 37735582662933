import { ReactNode } from 'react';
import CollapseInfoFieldDot from './CollapseInfoFieldDot';
import styles from './styles.module.scss';

interface Props {
  label: ReactNode;
  children?: ReactNode;
  caption?: string;
}

const CollapseInfoField = (props: Props) => {
  const { label, children, caption } = props;

  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      {children && (
        <>
          <div className={styles.line} />
          <div className={styles.value}>
            {children}
            {caption && <span>({caption})</span>}
          </div>
        </>
      )}
    </div>
  );
};

CollapseInfoField.Dot = CollapseInfoFieldDot;

export default CollapseInfoField;
