import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';
import { WorkspaceTagSchema } from '../schemas/workspaceTagSchema';

type Schema = WorkspaceTagSchema;

interface Payload {
  name?: Schema['name'];
  color?: Schema['color'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  tagId: Schema['id'];
  payload: Payload;
}

const updateWorkspaceTag = async (args: Args) => {
  const { workspaceId, tagId, payload } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/tag/${tagId}`,
    payload
  );

  return emptyResponseSchema.parse(response.data);
};

export default updateWorkspaceTag;
