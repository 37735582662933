import { BotSettingRuleSchema } from '../../api/schemas/botSettingRuleSchema';
import FadingText from '../../components/FadingText';
import Link from '../../components/Link';
import routes from '../../config/routes';

interface Props {
  data: BotSettingRuleSchema;
}

const GlobalBotSettingRuleBotSetting = (props: Props) => {
  const { data } = props;

  const { id, bot_settings } = data;

  const { id: botSettingId } = bot_settings;

  return (
    <Link to={routes.globalBotSettingRuleBotSetting({ botSettingRuleId: id, botSettingId })}>
      <FadingText text={bot_settings.name} />
    </Link>
  );
};

export default GlobalBotSettingRuleBotSetting;
