import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { AgentSchema } from '../../api/schemas/agentSchema';
import Button from '../../components/Button';
import Notes from '../../components/Notes';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import { formatAbsoluteDate } from '../../utils/date';
import { can } from '../../utils/permissions';
import GlobalAgentDetailDropdown from './GlobalAgentDetailDropdown';

interface Props {
  data: AgentSchema;
  onClose: () => void;
  onEdit: () => void;
  getNotesRoute: () => void;
}

const GlobalAgentDetail = (props: Props) => {
  const { data, onClose, onEdit, getNotesRoute } = props;

  const { t } = useTranslation();

  const { id, name, contact, notes_count, is_deleted, created_on, modified_on } = data;

  const titleIcons = (
    <>
      {is_deleted && icon('trash', 12)}
      {Boolean(notes_count) && <Tooltip label={t('common.notes')}>{icon('notes', 12)}</Tooltip>}
    </>
  );

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon title={name} icons={titleIcons} onClose={onClose}>
        <PageAddon.Fields>
          <PageAddon.Id id={id} />
          <PageAddon.Collapse label={t('common.contacts')}>
            <PageAddon.Collapse.Field label={false}>{contact || '—'}</PageAddon.Collapse.Field>
          </PageAddon.Collapse>
          <PageAddon.Field label={t('common.modified')}>
            {formatAbsoluteDate(modified_on)}
          </PageAddon.Field>
          <PageAddon.Field label={t('common.created')}>
            {formatAbsoluteDate(created_on)}
          </PageAddon.Field>
        </PageAddon.Fields>
        {!is_deleted && (
          <PageAddon.Controls>
            <PageAddon.Controls.Group>
              <Notes.Button count={notes_count} onClick={getNotesRoute} />
              <Tooltip
                label={can.agent.createUpdate ? t('common.edit') : t('common.no_permission')}
              >
                <div>
                  <Button
                    variant="transparent"
                    icon={icon('edit', 16)}
                    onClick={onEdit}
                    data-testid="edit"
                    disabled={!can.agent.createUpdate}
                  />
                </div>
              </Tooltip>
              <GlobalAgentDetailDropdown data={data} />
            </PageAddon.Controls.Group>
          </PageAddon.Controls>
        )}
      </PageAddon>
    </PageAddon.Group>
  );
};

export default GlobalAgentDetail;
