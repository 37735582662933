import { useTranslation } from 'react-i18next';
import {
  WorkspaceClubTransactionSchema,
  STATUS,
} from '../../api/schemas/workspaceClubTransactionSchema';
import Badge from '../../components/Badge';
import Tooltip from '../../components/Tooltip';
import { CLUB_TRANSACTIONS_STATUSES_ICONS, CLUB_TRANSACTIONS_STATUSES_LABELS } from './helpers';

interface Props {
  data: WorkspaceClubTransactionSchema;
}

const WorkspaceClubTransactionStatus = (props: Props) => {
  const { data } = props;

  const { status } = data;

  const { t } = useTranslation();

  const getLabel = () => {
    if (status === STATUS.CONFIRMED || status === STATUS.PENDING || status === STATUS.CANCELED) {
      return null;
    }

    return CLUB_TRANSACTIONS_STATUSES_LABELS[status];
  };

  const getColor = () => {
    if (status === STATUS.CONFIRMED) return 'green';
    if (status === STATUS.PENDING) return 'blue';

    return 'red';
  };

  const getContent = () => {
    if (status === STATUS.CONFIRMED || status === STATUS.PENDING || status === STATUS.CANCELED) {
      return CLUB_TRANSACTIONS_STATUSES_LABELS[status];
    }

    return t('common.failed');
  };

  return (
    <Tooltip label={getLabel()}>
      <Badge color={getColor()}>
        {CLUB_TRANSACTIONS_STATUSES_ICONS[status]}
        {getContent()}
      </Badge>
    </Tooltip>
  );
};

export default WorkspaceClubTransactionStatus;
