import { QueryKey } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  bulkDeleteWorkspaceIdentities,
  bulkReleaseWorkspaceIdentitiesOnMarket,
  bulkRemoveWorkspaceIdentitiesFromMarket,
  bulkRestoreWorkspaceIdentities,
} from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Button from '../../components/Button';
import Confirm from '../../components/Confirm';
import { useTableContext } from '../../components/Table';
import Tooltip from '../../components/Tooltip';
import useBulkActions from '../../hooks/useBulkActions';
import icon from '../../static/icons';
import { checkWorkspaceIdentityPokerFarm } from '../../utils/workspace';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  queryKey: QueryKey;
}

const WorkspaceIdentityTableSelectionActions = (props: Props) => {
  const { workspaceId /* queryKey */ } = props;

  const { t } = useTranslation();

  const { selected: ids } = useTableContext();
  const { disabled, handle } = useBulkActions();

  const handleConfirm = (handler: () => Promise<unknown>) => async () => {
    await handle(handler);

    /* queryClient.invalidateQueries(queryKey); */
  };

  const isPokerFarm = checkWorkspaceIdentityPokerFarm(workspaceId);

  return (
    <>
      <Tooltip label={t('common.delete')} hideOnMobile>
        <Confirm
          danger
          title={t('sentences.delete_records')}
          confirmText={t('common.delete')}
          onConfirm={handleConfirm(() =>
            bulkDeleteWorkspaceIdentities({ workspaceId, payload: ids })
          )}
        >
          {() => (
            <Button
              danger
              variant="transparent"
              icon={icon('trash', 16)}
              data-testid="bulk-delete"
              disabled={disabled}
            />
          )}
        </Confirm>
      </Tooltip>
      <Tooltip label={t('common.restore')} hideOnMobile>
        <Confirm
          title={t('sentences.restore_records')}
          confirmText={t('common.restore')}
          onConfirm={handleConfirm(() =>
            bulkRestoreWorkspaceIdentities({ workspaceId, payload: ids })
          )}
        >
          {() => (
            <Button
              variant="transparent"
              icon={icon('rotate', 16)}
              data-testid="bulk-restore"
              disabled={disabled}
            />
          )}
        </Confirm>
      </Tooltip>
      {!isPokerFarm && (
        <Tooltip label={t('common.sell_on_market')} hideOnMobile>
          <Confirm
            title={t('sentences.sell_on_market')}
            confirmText={t('common.sell')}
            onConfirm={handleConfirm(() =>
              bulkReleaseWorkspaceIdentitiesOnMarket({ workspaceId, payload: ids })
            )}
          >
            {() => (
              <Button
                variant="transparent"
                icon={icon('shoppingCart', 16)}
                data-testid="bulk-release-on-market"
                disabled={disabled}
              />
            )}
          </Confirm>
        </Tooltip>
      )}
      {!isPokerFarm && (
        <Tooltip label={t('common.remove_from_market')} hideOnMobile>
          <Confirm
            title={t('sentences.remove_from_market')}
            confirmText={t('common.remove')}
            onConfirm={handleConfirm(() =>
              bulkRemoveWorkspaceIdentitiesFromMarket({ workspaceId, payload: ids })
            )}
          >
            {() => (
              <Button
                variant="transparent"
                icon={icon('shoppingCartCrossed', 16)}
                data-testid="bulk-remove-from-market"
                disabled={disabled}
              />
            )}
          </Confirm>
        </Tooltip>
      )}
    </>
  );
};

export default WorkspaceIdentityTableSelectionActions;
