import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import LoginPage from '../../pages/LoginPage';

const loginPage: RouteObject = {
  path: routes.login,
  element: <LoginPage />,
};

export default loginPage;
