import { z } from 'zod';
import dateTimeSchema from '../dateTimeSchema';
import globalClubSchema from '../globalClubSchema';

export const STATUS = {
  UNKNOWN: 0,
  PLANNED: 1,
  SENT_START: 2,
  RUNNING: 3,
  SENT_FINISH: 4,
  FINISHED: 5,
  EXPIRED: 6,
  CANCELED: 7,
} as const;

export const TYPE = {
  UNKNOWN: 0,
  GAME: 1,
  MINING: 2,
} as const;

export const sessionCommonSchema = z.object({
  id: z.string(),
  clubs: z.array(globalClubSchema.pick({ id: true, name: true, code: true })),
  status: z.nativeEnum(STATUS),
  session_type: z.nativeEnum(TYPE),
  finishing_reason: z.string().or(z.null()),
  start: dateTimeSchema,
  end: dateTimeSchema,
  is_deleted: z.boolean(),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export const sessionCommonFilterValuesSchema = z.object({
  status: z.array(sessionCommonSchema.shape.status),
  session_type: z.array(sessionCommonSchema.shape.session_type),
  clubs: z.array(
    z.object({
      id: sessionCommonSchema.shape.clubs.element.shape.id,
      name: sessionCommonSchema.shape.clubs.element.shape.name,
    })
  ),
});
