import clsx from 'clsx';
import { useState } from 'react';
import { FieldValues, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useQuickField from '../../../hooks/useQuickField';
import icon from '../../../static/icons';
import Button from '../../Button';
import Input from '../Input';
import { Props as InputProps } from '../types';
import styles from './styles.module.scss';

type Props<T extends FieldValues> = UseControllerProps<T> &
  InputProps & {
    onSubmit?: () => void;
    hint?: boolean;
  };

const InputQuick = <T extends FieldValues>(props: Props<T>) => {
  const {
    name,
    control,
    rules,
    disabled,
    onSubmit,
    className,
    hint,
    type = 'text',
    extra,
    patternFormat,
    ...rest
  } = props;

  const { t } = useTranslation();

  const { controller, focused, error, listeners } = useQuickField({
    name,
    control,
    rules,
    onSubmit,
  });

  const { field } = controller;

  const [visible, setVisible] = useState(false);

  const size = 'small';
  const password = type === 'password';

  const getExtra = () => {
    if (password) {
      return (
        <Button
          size="small"
          variant="light"
          icon={visible ? icon('eyeOff', 16) : icon('eye', 16)}
          onClick={() => setVisible((prev) => !prev)}
        />
      );
    }

    return extra;
  };

  const getPlaceholder = () => {
    if (patternFormat && focused) {
      if (patternFormat === 'time') return 'hh:mm';
    }

    return t('common.enter');
  };

  if (disabled && !field.value) return '—';

  return (
    <Input
      data-quick
      size={size}
      disabled={disabled}
      patternFormat={patternFormat}
      placeholder={getPlaceholder()}
      className={clsx(
        styles.input,
        error && styles.error,
        (password || extra) && styles.extra,
        password && !visible && field.value && styles.hidden,
        patternFormat === 'time' && styles.patternFormat,
        className
      )}
      error={error}
      extra={getExtra()}
      errorVariant="popover"
      {...(hint &&
        focused &&
        field.value !== '' && { extra: <div className={styles.enter}>{t('common.enter')}</div> })}
      {...field}
      {...listeners}
      {...rest}
    />
  );
};

export default InputQuick;
