import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import WorkspaceClubManagerDetail from '../../features/workspace-club-manager/WorkspaceClubManagerDetail';
import WorkspaceClubManagerForm from '../../features/workspace-club-manager/WorkspaceClubManagerForm';
import WorkspaceClubManagerLoader from '../../features/workspace-club-manager/WorkspaceClubManagerLoader';
import WorkspaceClubManagersPage from '../../pages/WorkspaceClubManagersPage';
import Route from './Route';

const page = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceClubManagersPage workspaceId={workspaceId} />}
  </Route>
);

const createForm = (
  <Route params={['workspaceId']}>
    {({ workspaceId, navigate }) => (
      <WorkspaceClubManagerForm
        workspaceId={workspaceId}
        onClose={() => navigate(routes.workspaceClubManagers({ workspaceId }))}
        onCreate={({ managerId }) =>
          navigate(routes.workspaceClubManager({ workspaceId, managerId }))
        }
      />
    )}
  </Route>
);

const detail = (
  <Route params={['workspaceId', 'managerId']}>
    {({ workspaceId, managerId, navigate }) => (
      <WorkspaceClubManagerLoader workspaceId={workspaceId} managerId={managerId}>
        {({ data }) => (
          <WorkspaceClubManagerDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceClubManagers({ workspaceId }))}
            onEdit={() => navigate(routes.workspaceClubManagerEdit({ workspaceId, managerId }))}
          />
        )}
      </WorkspaceClubManagerLoader>
    )}
  </Route>
);

const editForm = (
  <Route params={['workspaceId', 'managerId']}>
    {({ workspaceId, managerId, navigate }) => (
      <WorkspaceClubManagerLoader workspaceId={workspaceId} managerId={managerId}>
        {({ data }) => (
          <WorkspaceClubManagerForm
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceClubManager({ workspaceId, managerId }))}
            onEdit={() => navigate(routes.workspaceClubManager({ workspaceId, managerId }))}
          />
        )}
      </WorkspaceClubManagerLoader>
    )}
  </Route>
);

const workspaceClubManagersPage: RouteObject = {
  path: routes.workspaceClubManagers({ workspaceId: ':workspaceId' }),
  element: page,
  children: [
    {
      path: routes.workspaceClubManagersCreate({ workspaceId: ':workspaceId' }),
      element: createForm,
    },
    {
      path: routes.workspaceClubManager({
        workspaceId: ':workspaceId',
        managerId: ':managerId',
      }),
      element: detail,
    },
    {
      path: routes.workspaceClubManagerEdit({
        workspaceId: ':workspaceId',
        managerId: ':managerId',
      }),
      element: editForm,
    },
  ],
};

export default workspaceClubManagersPage;
