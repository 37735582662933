import api from '../../services/api';
import globalClubSchema, { GlobalClubSchema } from '../schemas/globalClubSchema';

interface Args {
  clubId: GlobalClubSchema['id'];
}

const loadGlobalClub = async (args: Args) => {
  const { clubId } = args;

  const response = await api.get(`/api/reference/club/${clubId}`);

  return globalClubSchema.parse(response.data);
};

export default loadGlobalClub;
