import { z, ZodType } from 'zod';

const paginationSchema = <T extends ZodType>(schema: T) =>
  z.object({
    items: z.array(schema),
    page: z.number(),
    size: z.number(),
    total: z.number(),
  });

export interface PaginationSchema<T> {
  items: T[];
  total: number;
  page: number;
  size: number;
}

export default paginationSchema;
