import { useTranslation } from 'react-i18next';
import { bulkCreateWorkspaceAccounts } from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import ImportFromCSV from '../../components/ImportFromCSV';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
}

const WorkspaceAccountImport = (props: Props) => {
  const { workspaceId, onClose } = props;

  const { t } = useTranslation();

  const cols = [
    'login',
    'password',
    'poker_room',
    'club',
    'game_types',
    'limits',
    'table_sizes',
    'channel',
    'max_tables',
    'affiliate_ref',
    'tags',
    'member',
  ];

  const sample = `${cols.join(',')}\naccount_login,account_password,PPPoker,club_code,"NL, NLB","C2, C10","2, 3",channel_host,1,"affiliate","tag_name",user_name`;

  return (
    <ImportFromCSV
      onClose={onClose}
      sample={sample}
      title={t('common.bulk_import_accounts')}
      onImport={(formData) => bulkCreateWorkspaceAccounts({ workspaceId, payload: formData })}
    />
  );
};

export default WorkspaceAccountImport;
