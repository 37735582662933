import { useTranslation } from 'react-i18next';
import { LobbyTableWithMetricsSchema } from '../../api/schemas/lobbyTableSchema';
import Collapse from '../../components/Collapse';
import { copy } from '../../utils/clipboard';
import { TRAINER_LIMITS_LABELS } from '../../utils/trainer';
import AccountCloseTable from './AccountCloseTable';
import AccountMetrics from './AccountMetrics';
import { ACCOUNT_STATUS_COLORS, ACCOUNT_STATUS_LABELS } from './helpers';

interface Props {
  data: LobbyTableWithMetricsSchema;
  closing: boolean;
  canLeaveTable: boolean;
  onClose: () => void;
}

const AccountTable = (props: Props) => {
  const { data, closing, canLeaveTable, onClose } = props;

  const { id, comparative_limit, ante, metrics, account_status } = data;

  const { t } = useTranslation();

  const handleClick = () => copy(id);

  return (
    <Collapse
      label={<Collapse.LabelCopy onClick={handleClick}>{id}</Collapse.LabelCopy>}
      labelBefore={<Collapse.Badge color={ACCOUNT_STATUS_COLORS[account_status]} />}
      caption={
        <Collapse.Caption>
          {TRAINER_LIMITS_LABELS[comparative_limit]}
          {ante ? ' • A' : ''}
        </Collapse.Caption>
      }
    >
      <AccountMetrics data={metrics} />
      <Collapse.InfoField label={t('common.account_status')}>
        {ACCOUNT_STATUS_LABELS[account_status]}
      </Collapse.InfoField>
      <AccountCloseTable loading={closing} canLeaveTable={canLeaveTable} onClose={onClose} />
    </Collapse>
  );
};

export default AccountTable;
