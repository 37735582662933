import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

type Element = HTMLDivElement | null;
type Scrolled = boolean;
type ScrolledToEnd = boolean;
export type ColumnSizes = Record<string, number>;

interface State {
  element: Element;
  columnSizes: ColumnSizes | null;
  scrolled: Scrolled;
  scrolledToEnd: ScrolledToEnd;
  setElement: (payload: Element) => void;
  onScroll: () => void;
  setColumnSizes: (payload: ColumnSizes) => void;
}

export const createTableStore = (args: {
  columnSizes?: ColumnSizes;
  onColumnResize?: (columns: ColumnSizes) => void;
}) => {
  const { columnSizes, onColumnResize } = args;

  return createWithEqualityFn<State>()(
    (set) => ({
      element: null,
      columnSizes: columnSizes || null,
      scrolled: false,
      scrolledToEnd: false,
      setElement: (payload) => set(() => ({ element: payload })),
      onScroll: () =>
        set((state) => {
          if (state.element) {
            const { scrollLeft, clientWidth, scrollWidth } = state.element;

            return {
              scrolled: state.element.scrollLeft > 0,
              scrolledToEnd: scrollLeft + clientWidth === scrollWidth,
            };
          }

          return state;
        }),
      setColumnSizes: (payload) =>
        set((state) => {
          const value = { ...state.columnSizes, ...payload };

          if (onColumnResize) onColumnResize(value);

          return { columnSizes: value };
        }),
    }),
    shallow
  );
};
