import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceClubManagerSchema } from '../schemas/workspaceClubManagerSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  managerId: WorkspaceClubManagerSchema['id'];
}

const restoreWorkspaceClubManager = async (args: Args) => {
  const { workspaceId, managerId } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/club-manager/managers/${managerId}/restore`
  );

  return emptyResponseSchema.parse(response.data);
};

export default restoreWorkspaceClubManager;
