import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { depositWorkspaceClubMember, withdrawWorkspaceClubMember } from '../../api';
import { WorkspaceClubMemberSchema } from '../../api/schemas/workspaceClubMemberSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Form from '../../components/Form';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import SubmitButton from '../../components/SubmitButton';
import { convertDollarsToCents } from '../../utils/data';
import { required } from '../../utils/form';
import notify from '../../utils/notify';

interface Fields {
  amount: string;
}

interface Props {
  data: WorkspaceClubMemberSchema;
  workspaceId: WorkspaceSchema['id'];
  type: 'deposit' | 'withdraw';
  onClose: () => void;
}

const WorkspaceClubMemberAmountForm = (props: Props) => {
  const { data, workspaceId, type, onClose } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: {
      amount: '',
    },
  });

  const {
    control,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    const fn = type === 'deposit' ? depositWorkspaceClubMember : withdrawWorkspaceClubMember;

    await fn({
      workspaceId,
      memberId: data.id,
      payload: { amount: convertDollarsToCents(Number(values.amount)) },
    });

    onClose();
    notify('success', { title: t('common.action_completed') });
  };

  return (
    <PageAddon
      title={type === 'deposit' ? t('common.deposit') : t('common.withdraw')}
      onClose={onClose}
    >
      <Form form={form} onSubmit={onSubmit}>
        <Controller
          control={control}
          rules={{
            validate: {
              required,
            },
          }}
          name="amount"
          render={({ field }) => (
            <Input
              label={`${t('common.amount')}*`}
              error={errors.amount?.message}
              format={{
                decimalScale: 2,
                onValueChange: field.onChange,
              }}
              {...field}
            />
          )}
        />
        <PageAddon.Controls>
          <SubmitButton>{t('common.apply')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default WorkspaceClubMemberAmountForm;
