import {
  BUSINESS_MODEL,
  WORKSPACE_TYPES,
  WorkspaceSchema,
  WorkspaceType,
} from '../../api/schemas/workspaceSchema';
import { Options } from '../../components/Select';
import { t } from '../../i18n';

export const WORKSPACE_TYPES_LABELS: Record<WorkspaceType, string> = {
  1: t('common.poker_farm'),
  2: t('common.affiliate'),
  3: t('common.channel_provider'),
  4: t('common.identity_provider'),
  5: t('common.club_manager'),
};

export const workspaceTypesOptions = Object.values(WORKSPACE_TYPES).map((item) => ({
  value: item,
  label: WORKSPACE_TYPES_LABELS[item],
}));

export const BUSINESS_MODEL_LABELS: Record<WorkspaceSchema['business_model'], string> = {
  0: t('common.poker_farm'),
  1: t('common.BOT'),
  2: t('common.ECO'),
};

export const businessModelOptions: Options<WorkspaceSchema['business_model']> = [
  { value: BUSINESS_MODEL.BOT, label: t('common.BOT') },
  { value: BUSINESS_MODEL.ECO, label: t('common.ECO') },
];
