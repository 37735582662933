import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Select from '../../components/Select';
import { required } from '../../utils/form';
import { limitOptions } from './helpers';
import { CommonAccountFields } from './types';

interface Props {
  required?: boolean;
}

const AccountFormLimitIds = (props: Props) => {
  const { required: req } = props;

  const { t } = useTranslation();

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<CommonAccountFields>();

  const limitIds = watch('comparative_limits');

  const indeterminate = limitIds.length !== limitOptions.length;

  return (
    <Controller
      name="comparative_limits"
      control={control}
      {...(req && { rules: { validate: required } })}
      render={({ field }) => (
        <Select.Multi
          renderValueAsTag
          label={t('common.limits')}
          options={limitOptions}
          value={field.value}
          onChange={field.onChange}
          error={errors.comparative_limits?.message}
          {...(limitOptions.length > 0 && {
            addon: (
              <Button
                size="extra-small"
                variant="light"
                onClick={() => {
                  setValue('comparative_limits', indeterminate ? limitOptions : []);
                }}
              >
                {indeterminate ? t('common.select_all') : t('common.deselect_all')}
              </Button>
            ),
          })}
        />
      )}
    />
  );
};

export default AccountFormLimitIds;
