import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createGlobalRole } from '../../api';
import { GlobalRoleSchema } from '../../api/schemas/globalRoleSchema';
import Form from '../../components/Form';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import SubmitButton from '../../components/SubmitButton';
import { maxLength, minLength, required } from '../../utils/form';
import notify from '../../utils/notify';

interface Fields {
  name: string;
}

interface Props {
  onClose: () => void;
  onCreate: (args: { roleId: GlobalRoleSchema['id'] }) => void;
}

const GlobalRoleForm = (props: Props) => {
  const { onClose, onCreate } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({ defaultValues: { name: '' } });

  const {
    register,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    const response = await createGlobalRole({ payload: values });

    onCreate({ roleId: response.id });

    notify('success', { title: t('sentences.record_has_been_created') });
  };

  return (
    <PageAddon onClose={onClose} title={t('common.create_role')}>
      <Form form={form} onSubmit={onSubmit}>
        <Input
          label={`${t('common.name')}*`}
          error={errors.name?.message}
          {...register('name', {
            validate: {
              required,
              minLength: minLength(),
              maxLength: maxLength(),
            },
          })}
        />
        <PageAddon.Controls>
          <SubmitButton>{t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default GlobalRoleForm;
