import { WorkspaceSessionSchema } from '../../../api/schemas/session/workspaceSessionSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import PageDetailLink from '../../../components/PageDetailLink';
import routes from '../../../config/routes';
import { getTitle } from '../../session/helpers';
import styles from './styles.module.scss';

interface Props {
  data: WorkspaceSessionSchema;
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceSessionDetailLink = (props: Props) => {
  const { data, workspaceId } = props;

  const { id, start, end, account } = data;

  const { login } = account;

  const label = getTitle({ start, end });

  return (
    <div className={styles.container}>
      <PageDetailLink
        label={label}
        to={routes.workspaceSession({ workspaceId, accountId: account.id, sessionId: id })}
      />
      <span>{login}</span>
    </div>
  );
};

export default WorkspaceSessionDetailLink;
