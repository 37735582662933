import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadGlobalRoles } from '../../api';
import { GlobalRoleSchema } from '../../api/schemas/globalRoleSchema';
import Card from '../../components/Card';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import GlobalRoleCreateButton from '../../features/global-role/GlobalRoleCreateButton';
import GlobalRoleDetailLink from '../../features/global-role/GlobalRoleDetailLink';
import GlobalRoleDropdown from '../../features/global-role/GlobalRoleDropdown';
import GlobalRoleFilter from '../../features/global-role/GlobalRoleFilter';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedData from '../../hooks/usePaginatedData';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { addWhen } from '../../utils';
import { RouteParams } from '../../utils/generics';
import { can } from '../../utils/permissions';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.globalRole>;

const GlobalRolesPage = () => {
  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { roleId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search } = urlParams;

  const queryKey = [queryKeys.globalRoles, urlParams];

  const query = usePaginatedData({
    queryKey,
    queryFn: (params) => loadGlobalRoles({ ...urlParams, ...params }),
  });

  const { data, total, loading, error, checkIsInView } = query;

  const { checkIsCreated, ...meta } = useWebSocketPaginatedActions<GlobalRoleSchema>({
    queryKey,
    domain: 'Role',
    select: (item) => item,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <Page
      heading={t('common.roles')}
      tabs={[
        ...addWhen({ to: routes.globalUsers, label: t('common.members') }, can.user.read),
        { to: routes.globalRoles, label: t('common.roles'), active: true },
      ]}
      filter={<GlobalRoleFilter total={total} />}
      extra={
        <PageControls>
          <GlobalRoleCreateButton />
        </PageControls>
      }
      addon={<Outlet />}
    >
      <PageTop
        total={total}
        loading={loading}
        {...(isPhone && { sorting: [{ key: 'name', title: t('common.name'), default: true }] })}
        meta={meta}
      />
      {(isDesktop || isTablet) && (
        <Table
          data={data}
          search={search}
          {...getTableProps(query)}
          className={styles.table}
          columns={[
            {
              key: 'name',
              title: t('common.name'),
              sorting: true,
              render: ({ values }) => <GlobalRoleDetailLink data={values} />,
            },
            {
              render: ({ values }) => <GlobalRoleDropdown data={values} />,
              align: 'right',
              controls: true,
            },
          ]}
          renderRow={(row, index) => (
            <Table.Row
              highlighted={checkIsCreated(row.data.id)}
              active={row.data.id === roleId}
              danger={row.data.is_deleted}
              {...checkIsInView(index)}
              {...row}
            />
          )}
        />
      )}
      {isPhone && (
        <Card.Group>
          {data.map((item, index) => (
            <Card
              key={item.id}
              highlighted={checkIsCreated(item.id)}
              danger={item.is_deleted}
              {...checkIsInView(index)}
            >
              <Card.Top>
                <GlobalRoleDetailLink data={item} />
                <Card.Top.Controls>
                  <GlobalRoleDropdown data={item} />
                </Card.Top.Controls>
              </Card.Top>
            </Card>
          ))}
        </Card.Group>
      )}
    </Page>
  );
};

export default GlobalRolesPage;
