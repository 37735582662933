import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import workspaceClubManagerSchema, {
  WorkspaceClubManagerSchema,
  WorkspaceClubManagerPasswordSchema,
} from '../schemas/workspaceClubManagerSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = WorkspaceClubManagerSchema;

interface Payload {
  login: Schema['login'];
  password: WorkspaceClubManagerPasswordSchema;
  type: Schema['type'];
  room: Schema['room'];
  clubs: Schema['clubs'];
  channel_id: Exclude<Schema['channel'], null>['id'] | null;
  balance_threshold: Schema['balance_threshold'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const createWorkspaceClubManager = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/club-manager/managers`,
    payload
  );

  return workspaceClubManagerSchema.pick({ id: true }).parse(response.data);
};

export default createWorkspaceClubManager;
