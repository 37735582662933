import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { deleteWorkspaceClubWhitelistMember, restoreWorkspaceClubWhitelistMember } from '../../api';
import { WorkspaceClubWhitelistSchema } from '../../api/schemas/workspaceClubWhitelistSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { presets } from '../../components/Dropdown';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { RouteParams } from '../../utils/generics';
import notify from '../../utils/notify';

type Params = RouteParams<typeof routes.workspaceClubWhitelistMember>;

interface Args {
  data: WorkspaceClubWhitelistSchema;
  workspaceId: WorkspaceSchema['id'];
}

const useDropdown = (args: Args) => {
  const { data, workspaceId } = args;

  const { id, player_id, is_deleted } = data;

  const { t } = useTranslation();
  const { whitelistMemberId } = useParams<Params>();
  const { navigate } = useURL();

  return {
    title: player_id,
    options: {
      copy: presets.copyId(id),
      divider: { divider: true },
      ...(!is_deleted && {
        delete: {
          ...presets.delete,
          onClick: async () => {
            await deleteWorkspaceClubWhitelistMember({ workspaceId, whitelistMemberId: id });

            if (id === whitelistMemberId) {
              navigate(routes.workspaceClubWhitelist({ workspaceId }));
            }

            notify('success', { title: t('sentences.record_has_been_deleted') });
          },
        },
      }),
      ...(is_deleted && {
        restore: {
          ...presets.restore,
          onClick: async () => {
            await restoreWorkspaceClubWhitelistMember({ workspaceId, whitelistMemberId: id });

            notify('success', { title: t('sentences.record_has_been_restored') });
          },
        },
      }),
    },
  };
};

export default useDropdown;
