import { ColumnSizes } from '../../components/Table';
import { getParsedLocalStorageItem, setLocalStorageItem } from '../../utils/localStorage';

const useTableColumnResize = () => {
  const columnSizes = getParsedLocalStorageItem('column_sizes');

  const getColumnSizes = () => {
    if (columnSizes) {
      if (columnSizes['workspace-deal']) return columnSizes['workspace-deal'];
    }

    return undefined;
  };

  return {
    columnSizes: getColumnSizes(),
    onColumnResize: (columns: ColumnSizes) => {
      setLocalStorageItem(
        'column_sizes',
        JSON.stringify({ ...(columnSizes || {}), 'workspace-deal': columns })
      );
    },
  };
};

export default useTableColumnResize;
