import { z } from 'zod';
import { identityCommonFilterValuesSchema, identityCommonSchema } from './identity';
import identityCommonAccountSchema from './identity/identityCommonAccountSchema';

const identitySchema = identityCommonSchema.extend({
  accounts: z.array(identityCommonAccountSchema), // cyclic
});

export const identityFilterValuesSchema = identityCommonFilterValuesSchema;

export type IdentitySchema = z.infer<typeof identitySchema>;

export default identitySchema;
