import { useParams } from 'react-router-dom';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Button from '../../components/Button';
import Indicator from '../../components/Indicator';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';
import { useCartStore } from '../../store/cart';
import { RouteParams } from '../../utils/generics';

type Params = RouteParams<typeof routes.workspaceMarketIdentity>;

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceMarketIdentityWidget = (props: Props) => {
  const { workspaceId } = props;

  const { navigate } = useURL();
  const { identityId } = useParams<Params>();

  const { getIdentities } = useCartStore((state) => ({
    getIdentities: state.getIdentities,
  }));

  const identities = getIdentities(workspaceId);

  return (
    <Indicator count={identities.length}>
      <Button
        variant="transparent"
        icon={icon('shoppingBag', 16)}
        size="small"
        onClick={() =>
          navigate(
            identityId
              ? routes.workspaceMarketIdentityCart({ workspaceId, identityId })
              : routes.workspaceMarketIdentitiesCart({ workspaceId })
          )
        }
      />
    </Indicator>
  );
};

export default WorkspaceMarketIdentityWidget;
