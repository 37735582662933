import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import workspaceSessionSchema, {
  WorkspaceSessionSchema,
} from '../schemas/session/workspaceSessionSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
  sessionId: WorkspaceSessionSchema['id'];
}

const loadWorkspaceAccountSession = async (args: Args) => {
  const { workspaceId, accountId, sessionId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/${accountId}/session/${sessionId}`
  );

  return workspaceSessionSchema.parse(response.data);
};

export default loadWorkspaceAccountSession;
