import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { orderFilterValuesSchema } from '../schemas/orderSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceOrdersFilterValues = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/order/filter-values`, {
    params,
  });

  return orderFilterValuesSchema.parse(response.data);
};

export default loadWorkspaceOrdersFilterValues;
