import { ToastContainer, Slide } from 'react-toastify';
import icon from '../../static/icons';
import styles from './styles.module.scss';

// при смене имени класса для контейнера надо так же поменять его в компоненте Dialog

const Toasts = () => (
  <ToastContainer
    autoClose={3000}
    draggable={false}
    transition={Slide}
    position="top-center"
    closeButton={({ closeToast }) => (
      <button type="button" className={styles.closeButton} onClick={closeToast}>
        {icon('cross', 16)}
      </button>
    )}
  />
);
export default Toasts;
