import clsx from 'clsx';
import { ReactNode } from 'react';
import Empty from '../../Empty';
import Placeholder from '../../Placeholder';
import SearchInput from '../../SearchInput';
import PageAddonListAdd from './PageAddonListAdd';
import PageAddonListItem from './PageAddonListItem';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  search: string;
  onSearch: (value: string) => void;
  controls?: ReactNode;
  loading?: boolean;
}

const PageAddonList = (props: Props) => {
  const { children, controls, loading, search, onSearch } = props;

  const getContent = () => {
    if (loading) {
      return Array.from({ length: 14 }).map((_, index) => (
        <Placeholder key={index} className={styles.placeholder} />
      ));
    }

    if (!children || (Array.isArray(children) && children.length === 0)) {
      return <Empty />;
    }

    return children;
  };

  return (
    <div className={clsx(styles.container, loading && styles.loading)}>
      <div className={styles.controls}>
        <SearchInput size="small" value={search} onChange={onSearch} />
        {controls}
      </div>
      <div className={styles.content}>{getContent()}</div>
    </div>
  );
};

PageAddonList.Add = PageAddonListAdd;
PageAddonList.Item = PageAddonListItem;

export default PageAddonList;
