import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PHOTO_TYPE } from '../../api/schemas/identity';
import Collapse from '../../components/Collapse';
import MediaFilesField from '../../components/MediaFilesField';
import PageAddon from '../../components/PageAddon';
import Select from '../../components/Select';
import { MB } from '../../constants';
import CONTENT_TYPES from '../../constants/CONTENT_TYPES';
import EXTENSIONS from '../../constants/EXTENSIONS';
import { required } from '../../utils/form';
import { PHOTOS_LABELS } from './helpers';
import { Fields } from './types';
import WorkspaceIdentityFormCollapse from './WorkspaceIdentityFormCollapse';

const photosOptions = [
  {
    value: PHOTO_TYPE.SELFIE,
    label: PHOTOS_LABELS[PHOTO_TYPE.SELFIE],
  },
  {
    value: PHOTO_TYPE.SELFIE_WITH_IDENTITY_DOCUMENT,
    label: PHOTOS_LABELS[PHOTO_TYPE.SELFIE_WITH_IDENTITY_DOCUMENT],
  },
  {
    value: PHOTO_TYPE.SELFIE_WITH_BLANK_PAPER_SHEET,
    label: PHOTOS_LABELS[PHOTO_TYPE.SELFIE_WITH_BLANK_PAPER_SHEET],
  },
];

const WorkspaceIdentityFormPhotos = () => {
  const { t } = useTranslation();

  const {
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useFormContext<Fields>();

  const { fields, append, remove } = useFieldArray({ control, name: 'photos' });

  return (
    <PageAddon.DynamicFields
      label={t('common.photos')}
      onAppend={() => append({ files: [], type: null }, { shouldFocus: false })}
    >
      {fields.map((dynamicField, index) => {
        const { type } = watch(`photos.${index}`);

        return (
          <WorkspaceIdentityFormCollapse
            key={dynamicField.id}
            onDelete={() => remove(index)}
            defaultOpen={!dynamicField.files.length}
            label={(type && PHOTOS_LABELS[type.value]) || `${t('common.photo')} ${index + 1}`}
            error={errors.photos ? Boolean(errors.photos[index]) : false}
          >
            <Collapse.FieldGroup>
              <Controller
                control={control}
                name={`photos.${index}.type`}
                rules={{ validate: required }}
                render={({ field }) => (
                  <Select
                    clearable={false}
                    label={`${t('common.type')}*`}
                    options={photosOptions}
                    value={field.value}
                    onChange={field.onChange}
                    {...(errors.photos && {
                      // @ts-expect-error
                      error: errors.photos[index]?.type?.message,
                    })}
                  />
                )}
              />
              <Controller
                control={control}
                name={`photos.${index}.files`}
                rules={{ validate: required }}
                render={({ field }) => (
                  <MediaFilesField
                    value={field.value}
                    maxSize={3 * MB}
                    onChange={field.onChange}
                    accept={{
                      [CONTENT_TYPES['image/*']]: [EXTENSIONS.JPG, EXTENSIONS.JPEG, EXTENSIONS.PNG],
                    }}
                    disabled={isSubmitting}
                    {...(errors.photos && {
                      error: errors.photos[index]?.files?.message,
                    })}
                  >
                    {t('sentences.workspace_identities_files_restrictions')}
                  </MediaFilesField>
                )}
              />
            </Collapse.FieldGroup>
          </WorkspaceIdentityFormCollapse>
        );
      })}
    </PageAddon.DynamicFields>
  );
};

export default WorkspaceIdentityFormPhotos;
