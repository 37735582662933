import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import globalRoleSchema from './globalRoleSchema';
import workspaceSchema from './workspaceSchema';

const userRoleSchema = globalRoleSchema.pick({
  id: true,
  name: true,
  permissions: true,
  is_deleted: true,
});

const userWorkspaceSchema = z.object({
  id: workspaceSchema.shape.id,
  name: workspaceSchema.shape.name,
  maintenance_types: workspaceSchema.shape.maintenance_types,
  is_owner: z.boolean(),
  invited_on: dateTimeSchema,
});

const globalUserSchema = z.object({
  id: z.string(),
  username: z.string(),
  name: z.string(),
  avatar: z.string(),
  roles: z.array(userRoleSchema),
  maintenances: z.array(userWorkspaceSchema),
  verified: z.boolean(),
  is_deleted: z.boolean(),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export const globalUserFilterValuesSchema = z.object({
  maintenances: z.array(
    z.object({
      id: globalUserSchema.shape.maintenances.element.shape.id,
      name: globalUserSchema.shape.maintenances.element.shape.name,
    })
  ),
  roles: z.array(
    z.object({
      id: globalUserSchema.shape.roles.element.shape.id,
      name: globalUserSchema.shape.roles.element.shape.name,
    })
  ),
});

export type GlobalUserSchema = z.infer<typeof globalUserSchema>;

export default globalUserSchema;
