import { useTranslation } from 'react-i18next';
import icon from '../../../static/icons';
import Button from '../../Button';
import styles from './styles.module.scss';

interface Props {
  label: React.ReactNode;
  children: React.ReactNode;
  onAppend: () => void;
}

const PageAddonDynamicFields = (props: Props) => {
  const { label, children, onAppend } = props;

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        <span>{label}</span>
        <Button variant="light" size="small" icon={icon('add', 16)} onClick={onAppend}>
          {t('common.add')}
        </Button>
      </div>
      {children}
    </div>
  );
};

export default PageAddonDynamicFields;
