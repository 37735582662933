import { useState } from 'react';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import LinkButton from '../../components/LinkButton';
import PageAddon from '../../components/PageAddon';
import useURL from '../../hooks/useURL';
import { useGlobalClubWorkspacesQuery } from './queries';

interface Props {
  clubId: GlobalClubSchema['id'];
  getWorkspaceRoute?: (args: { workspaceId: WorkspaceSchema['id'] }) => string;
}

const GlobalClubDetailWorkspacesTab = (props: Props) => {
  const { clubId, getWorkspaceRoute } = props;

  const { navigate } = useURL();

  const [search, setSearch] = useState('');

  const { data, loading } = useGlobalClubWorkspacesQuery({ clubId, search });

  return (
    <PageAddon.List loading={loading} search={search} onSearch={setSearch}>
      {data.map((item) => (
        <PageAddon.List.Item key={item.id} title={item.name}>
          {getWorkspaceRoute && (
            <LinkButton
              size="small"
              variant="transparent"
              onClick={() => navigate(getWorkspaceRoute({ workspaceId: item.id }))}
            />
          )}
        </PageAddon.List.Item>
      ))}
    </PageAddon.List>
  );
};

export default GlobalClubDetailWorkspacesTab;
