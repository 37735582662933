import { OrderSchema } from '../../api/schemas/orderSchema';
import { presets } from '../../components/Dropdown';
import { formatAbsoluteDate } from '../../utils/date';

interface Args {
  data: OrderSchema;
}

const useDropdown = (args: Args) => {
  const { data } = args;

  const { id, created_on } = data;

  const title = formatAbsoluteDate(created_on);

  return {
    title,
    options: {
      copy: presets.copyId(id),
    },
  };
};

export default useDropdown;
