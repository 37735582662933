import { z } from 'zod';
import identitySchema from '../identitySchema';

const workspaceDashboardIdentityCountSchema = z.object({
  count: z.number(),
  country: identitySchema.shape.location.shape.country_name,
});

export type WorkspaceDashboardIdentityCountSchema = z.infer<
  typeof workspaceDashboardIdentityCountSchema
>;

export default workspaceDashboardIdentityCountSchema;
