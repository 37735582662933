import api from '../../services/api';
import { GlobalAccountSchema } from '../schemas/account/globalAccountSchema';
import globalSessionSchema, { GlobalSessionSchema } from '../schemas/session/globalSessionSchema';

type Schema = GlobalSessionSchema;

interface Payload {
  start: Schema['start'];
  end: Schema['end'];
  session_type: Schema['session_type'];
}

interface Args {
  accountId: GlobalAccountSchema['id'];
  payload: Payload;
}

const createGlobalAccountSession = async (args: Args) => {
  const { accountId, payload } = args;

  const response = await api.post(`/api/account/${accountId}/session`, payload);

  return globalSessionSchema.pick({ id: true }).parse(response.data);
};

export default createGlobalAccountSession;
