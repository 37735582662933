import { z } from 'zod';

const workspaceAccountVerificationCodeSchema = z.object({
  verification_code: z.number(),
});

export type WorkspaceAccountVerificationCodeSchema = z.infer<
  typeof workspaceAccountVerificationCodeSchema
>;

export default workspaceAccountVerificationCodeSchema;
