import api from '../../services/api';
import { BotSettingRuleSchema } from '../schemas/botSettingRuleSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';

type Schema = BotSettingRuleSchema;

interface Args {
  botSettingRuleId: Schema['id'];
}

const deleteGlobalBotSettingRule = async (args: Args) => {
  const { botSettingRuleId } = args;

  const response = await api.delete(`/api/bot-settings-usage-rule/${botSettingRuleId}`);

  return emptyResponseSchema.parse(response.data);
};

export default deleteGlobalBotSettingRule;
