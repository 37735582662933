import { z } from 'zod';
import channelSchema from '../channelSchema';

const workspaceDashboardChannelCountSchema = z.object({
  count: z.number(),
  status: channelSchema.shape.status,
});

export type WorkspaceDashboardChannelCountSchema = z.infer<
  typeof workspaceDashboardChannelCountSchema
>;

export default workspaceDashboardChannelCountSchema;
