import { GlobalSuperLeagueSchema } from '../../api/schemas/globalSuperLeagueSchema';
import PageDetailLink from '../../components/PageDetailLink';
import routes from '../../config/routes';

interface Props {
  data: GlobalSuperLeagueSchema;
}

const GlobalSuperLeagueDetailLink = (props: Props) => {
  const { data } = props;

  const { id, code } = data;

  return <PageDetailLink label={code} to={routes.globalSuperLeague({ superLeagueId: id })} />;
};

export default GlobalSuperLeagueDetailLink;
