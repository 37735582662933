import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { OrderSchema, TYPE } from '../../../api/schemas/orderSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Link from '../../../components/Link';
import PageAddon from '../../../components/PageAddon';
import routes from '../../../config/routes';
import { convertCentsToUSDT } from '../../../utils/data';
import { formatAbsoluteDate } from '../../../utils/date';
import { TYPE_LABELS } from '../helpers';
import OrderStatus from '../OrderStatus';
import OrderDetailExtra from './OrderDetailExtra';
import styles from './styles.module.scss';

interface Props {
  data: OrderSchema;
  transactions: ReactNode;
  onClose: () => void;
  workspaceId?: WorkspaceSchema['id'];
}

const OrderDetail = (props: Props) => {
  const { data, transactions, onClose, workspaceId } = props;

  const { t } = useTranslation();

  const { type, maintenance, wallet_from, wallet_to, order_item, created_on } = data;

  const renderFrom = () => {
    if (wallet_from) {
      if (type === TYPE.WITHDRAW) return wallet_from.maintenance.name;
    }

    return null;
  };

  const renderTo = () => {
    if (wallet_to) {
      if (type === TYPE.DEPOSIT) return wallet_to.maintenance.name;

      if (type === TYPE.WITHDRAW) {
        return <PageAddon.Id label={t('common.to')} id={wallet_to.address} />;
      }
    }

    return null;
  };

  const renderOrderItem = () => {
    if (order_item) {
      const { id, domain } = order_item;

      const to = workspaceId
        ? routes.workspaceOrderAccount({ workspaceId, orderId: data.id, accountId: id })
        : routes.globalOrderAccount({ orderId: data.id, accountId: id });

      if (domain === 'Account') {
        return (
          <PageAddon.Field label={domain}>
            <Link to={to}>{t('common.open_details')}</Link>
          </PageAddon.Field>
        );
      }

      return <PageAddon.Id id={id} label={domain} />;
    }

    return null;
  };

  const createdDate = formatAbsoluteDate(created_on);

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon title={createdDate} onClose={onClose}>
        <PageAddon.Fields>
          <PageAddon.Id id={data.id} />
          <PageAddon.Field label={t('common.type')}>{TYPE_LABELS[data.type]}</PageAddon.Field>
          <PageAddon.Field label={t('common.amount_USDT')}>
            {convertCentsToUSDT(data.amount)}
          </PageAddon.Field>
          <PageAddon.Field label={t('common.status')}>
            <OrderStatus data={data} />
          </PageAddon.Field>
          {!workspaceId && (
            <PageAddon.Field label={t('common.workspace')}>{maintenance.name}</PageAddon.Field>
          )}
          {renderOrderItem()}
          {renderFrom()}
          {renderTo()}
          <OrderDetailExtra data={data} />
          <PageAddon.Field label={t('common.created')}>{createdDate}</PageAddon.Field>
        </PageAddon.Fields>
        <PageAddon.Heading level="second" className={styles.heading}>
          {t('common.transactions')}
        </PageAddon.Heading>
        {transactions}
      </PageAddon>
    </PageAddon.Group>
  );
};

export default OrderDetail;
