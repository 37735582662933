import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { IdentitySchema } from '../../../api/schemas/identitySchema';
import { MarketIdentitySchema } from '../../../api/schemas/marketIdentitySchema';
import Tooltip from '../../../components/Tooltip';

interface Props {
  data: IdentitySchema | MarketIdentitySchema | Exclude<WorkspaceAccountSchema['identity'], null>;
}

const WorkspaceIdentityCountry = (props: Props) => {
  const { data } = props;

  const { location } = data;

  return (
    <Tooltip label={location.country_name}>
      <span>{location.country_iso}</span>
    </Tooltip>
  );
};

export default WorkspaceIdentityCountry;
