import { z } from 'zod';

const workspaceDashboardAccountTotalSchema = z.object({
  total: z.number(),
});

export type WorkspaceDashboardAccountTotalSchema = z.infer<
  typeof workspaceDashboardAccountTotalSchema
>;

export default workspaceDashboardAccountTotalSchema;
