import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import paginationSchemaNew from '../schemas/paginationSchemaNew';
import workspaceSessionSchema from '../schemas/session/workspaceSessionSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceSessions = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(`/api/v2/${ALIASES.WORKSPACE.L}/${workspaceId}/session`, {
    params,
  });

  return paginationSchemaNew(workspaceSessionSchema).parse(response.data);
};

export default loadWorkspaceSessions;
