import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  active: boolean;
  onClick: () => void;
}

const PageTab = (props: Props) => {
  const { children, active, onClick } = props;

  return (
    <button type="button" onClick={onClick} className={clsx(styles.tab, active && styles.active)}>
      <span>{children}</span>
    </button>
  );
};

export default PageTab;
