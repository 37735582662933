import Id from '../../../types/Id';
import TableHeadCell from '../TableHeadCell';
import { Columns } from '../types';
import styles from './styles.module.scss';

interface Props<T> {
  columns: Columns<T>;
}

const TableHead = <T extends Id>(props: Props<T>) => {
  const { columns } = props;

  return (
    <div className={styles.container}>
      {columns.map((column, index) => (
        <TableHeadCell key={`${index}-${column.title}`} column={column} />
      ))}
    </div>
  );
};

export default TableHead;
