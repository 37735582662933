import { z } from 'zod';
import api from '../../services/api';
import lobbySchema from '../schemas/lobbySchema';

const loadGlobalLobby = async () => {
  const response = await api.get('/api/lobby/room');

  return z.array(lobbySchema).parse(response.data);
};

export default loadGlobalLobby;
