import { GlobalBonusSchema } from '../../api/schemas/globalBonusSchema';
import PageDetailLink from '../../components/PageDetailLink';
import routes from '../../config/routes';
import { formatAbsoluteDate } from '../../utils/date';

interface Props {
  data: GlobalBonusSchema;
}

const GlobalBonusDetailLink = (props: Props) => {
  const { data } = props;

  const { id, created_on } = data;

  return (
    <PageDetailLink
      label={formatAbsoluteDate(created_on)}
      to={routes.globalBonus({ bonusId: id })}
    />
  );
};

export default GlobalBonusDetailLink;
