import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { GlobalUserSchema } from '../schemas/globalUserSchema';

type Schema = GlobalUserSchema;

interface Payload {
  name?: Schema['name'];
  role_ids?: Schema['roles'][number]['id'][];
}

interface Args {
  userId: Schema['id'];
  payload: Payload;
}

const updateGlobalUser = async (args: Args) => {
  const { userId, payload } = args;

  const response = await api.patch(`/api/user/${userId}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateGlobalUser;
