import { WorkspaceClubManagerSchema } from '../../api/schemas/workspaceClubManagerSchema';
import PrettyNumber from '../../components/PrettyNumber';
import { convertCentsToDollars } from '../../utils/data';

interface Props {
  data: WorkspaceClubManagerSchema;
}

const WorkspaceClubManagerBalanceThreshold = (props: Props) => {
  const { data } = props;

  const { balance_threshold } = data;

  if (balance_threshold === null) return <>—</>;

  return <PrettyNumber>{convertCentsToDollars(balance_threshold)}</PrettyNumber>;
};

export default WorkspaceClubManagerBalanceThreshold;
