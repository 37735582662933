import { useState, useEffect, useCallback } from 'react';
import usePrevious from './usePrevious';

interface Args {
  ids: string[];
}

const useLegacyRowSelection = (args: Args) => {
  const { ids } = args;

  const [selected, setSelected] = useState<string[]>([]);

  const [selectAllMode, setSelectAllMode] = useState(false);

  const unselected = ids.filter((item) => !selected.includes(item));

  const prevIds = usePrevious(ids);

  useEffect(() => {
    if (selectAllMode && ids.length !== prevIds.length) {
      setSelected((prev) => [...prev, ...ids.filter((item) => !prevIds.includes(item))]);
    }
  }, [ids, prevIds, selectAllMode]);

  const select = useCallback(
    (id: string) => {
      if (selected.includes(id)) {
        setSelected((prev) => prev.filter((item) => item !== id));
      } else {
        setSelected((prev) => [...prev, id]);
      }
    },
    [selected]
  );

  const unselect = useCallback((id: string) => {
    setSelected((prev) => prev.filter((item) => item !== id));
  }, []);

  const allSelected = ids.length !== 0 && ids.length === selected.length;

  const selectAll = useCallback(() => {
    if (allSelected) {
      setSelected([]);
      setSelectAllMode(false);
    } else {
      setSelected(ids);
      setSelectAllMode(true);
    }
  }, [allSelected, ids]);

  const unselectAll = useCallback(() => {
    setSelected([]);
    setSelectAllMode(false);
  }, []);

  const checkIsSelected = useCallback((id: string) => selected.includes(id), [selected]);

  return {
    selected,
    unselected,
    allSelected,
    selectAllMode,
    select,
    unselect,
    selectAll,
    unselectAll,
    checkIsSelected,
  };
};

export default useLegacyRowSelection;
