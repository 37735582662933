import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { OrderSchema } from '../schemas/orderSchema';
import orderTransactionSchema from '../schemas/orderTransactionSchema';
import paginationSchemaNew from '../schemas/paginationSchemaNew';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  orderId: OrderSchema['id'];
}

const loadWorkspaceOrderTransactions = async (args: Args, params?: URLParams) => {
  const { workspaceId, orderId } = args;

  const response = await api.get(
    `/api/v2/${ALIASES.WORKSPACE.L}/${workspaceId}/order/${orderId}/transaction`,
    { params }
  );

  return paginationSchemaNew(orderTransactionSchema).parse(response.data);
};

export default loadWorkspaceOrderTransactions;
