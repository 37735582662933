import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import { accountStrategyProfileSchema } from '../schemas/accountStrategyProfileSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
}

const loadWorkspaceAccountStrategyProfile = async (args: Args) => {
  const { workspaceId, accountId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/${accountId}/strategy-profile`
  );

  return accountStrategyProfileSchema.parse(response.data);
};

export default loadWorkspaceAccountStrategyProfile;
