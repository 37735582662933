import { loadGlobalWorkspaceClubs, loadGlobalWorkspaceUsers } from '../../api';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import queryKeys from '../../config/queryKeys';
import usePaginatedData from '../../hooks/usePaginatedData';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { can } from '../../utils/permissions';
import {
  createInfinitePaginatedQueryData,
  deleteInfinitePaginatedQueryData,
} from '../../utils/queryClient';

export const useGlobalWorkspaceUsersQuery = (args: {
  workspaceId: WorkspaceSchema['id'];
  search?: string;
}) => {
  const { workspaceId, search } = args;

  const queryKey = [queryKeys.globalWorkspaceUsers({ workspaceId }), search];

  return usePaginatedData({
    queryKey,
    queryFn: ({ page }) =>
      loadGlobalWorkspaceUsers(
        { workspaceId },
        { ...(page && { page }), ...(search && { search }) }
      ),
    enabled: can.user.read,
  });
};

export const useGlobalWorkspaceClubsQuery = (args: {
  workspaceId: WorkspaceSchema['id'];
  search?: string;
}) => {
  const { workspaceId, search } = args;

  const queryKey = [queryKeys.globalWorkspaceClubs({ workspaceId }), search];

  useWebSocketMessage<{
    id: WorkspaceSchema['id'];
    modified_on: WorkspaceSchema['modified_on'];
    club: GlobalClubSchema;
  }>({
    domain: 'GlobalMaintenance',
    onMessage: (message) => {
      const payload = message.payload.club;

      if (message.action === 'ClubAdded') {
        createInfinitePaginatedQueryData(queryKey, payload);
      }

      if (message.action === 'ClubRemoved') {
        deleteInfinitePaginatedQueryData(queryKey, (item) => item.id === payload.id);
      }
    },
    enabled: (message) => message.payload.id === workspaceId,
  });

  return {
    queryKey,
    ...usePaginatedData({
      queryKey,
      queryFn: ({ page }) =>
        loadGlobalWorkspaceClubs(
          { workspaceId },
          { size: '2000', ...(page && { page }), ...(search && { search }) }
        ),
      enabled: can.club.read,
    }),
  };
};
