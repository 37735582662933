import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadWorkspaceDashboardAccounts, loadWorkspaceDashboardAccountsTotal } from '../../../api';
import { WorkspaceDashboardAccountSchema } from '../../../api/schemas/workspace-dashboard/workspaceDashboardAccountSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Card from '../../../components/Card';
import Chip from '../../../components/Chip';
import Page from '../../../components/Page';
import PageControls from '../../../components/PageControls';
import PageSorting from '../../../components/PageSorting';
import Placeholder from '../../../components/Placeholder';
import Table, { Columns, getTableProps } from '../../../components/Table';
import queryKeys from '../../../config/queryKeys';
import WorkspaceDashboardDetailLink from '../../../features/workspace-dashboard/WorkspaceDashboardDetailLink';
import WorkspaceDashboardFilter from '../../../features/workspace-dashboard/WorkspaceDashboardFilter';
import WorkspaceDashboardWidgets from '../../../features/workspace-dashboard/WorkspaceDashboardWidgets';
import useBreakpoints from '../../../hooks/useBreakpoints';
import usePaginatedData from '../../../hooks/usePaginatedData';
import useURL from '../../../hooks/useURL';
import Error403Page from '../../Error403Page';
import { COLUMNS_WIDTHS, GROUPS, GROUPS_LABELS, GroupKey, getContent } from './helpers';
import styles from './styles.module.scss';

interface Props {
  workspace: WorkspaceSchema;
}

const WorkspaceDashboard = (props: Props) => {
  const { workspace } = props;

  const { id: workspaceId, name: workspaceName } = workspace;

  const [groups, setGroups] = useState<GroupKey[]>([]);

  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { urlParams } = useURL();

  const query = usePaginatedData({
    queryKey: [queryKeys.workspaceDashboardAccounts({ workspaceId }), urlParams, groups],
    queryFn: (params) =>
      loadWorkspaceDashboardAccounts(
        { workspaceId },
        { ...urlParams, ...params, group_by: groups }
      ),
  });

  const { data, total, error, checkIsInView } = query;

  const { data: grandTotal, isPending: grandTotalLoading } = useQuery({
    queryKey: [queryKeys.workspaceDashboardAccountsTotal({ workspaceId }), urlParams],
    queryFn: () => loadWorkspaceDashboardAccountsTotal({ workspaceId }, urlParams),
    meta: { ignoredStatuses: [403] },
  });

  const currentGroups = GROUPS.filter((item) => groups.includes(item));

  const sorting = currentGroups.map((item) => ({
    key: item,
    title: GROUPS_LABELS[item],
  }));

  const columns: Columns<WorkspaceDashboardAccountSchema> = currentGroups.map((item) => ({
    key: item,
    title: GROUPS_LABELS[item],
    sorting: true,
    render: ({ values }) => getContent(values)[item],
    style: { width: COLUMNS_WIDTHS[item] },
  }));

  const grandTotalContent = (
    <div className={styles.grandTotal}>
      <span className={styles.grandTotalLabel}>{t('common.total')}</span>
      {grandTotalLoading ? (
        <Placeholder className={styles.grandTotalPlaceholder} />
      ) : (
        <span>{grandTotal?.total}</span>
      )}
    </div>
  );

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <Page
      search={false}
      heading={t('common.dashboard')}
      filter={<WorkspaceDashboardFilter workspaceId={workspaceId} total={total} />}
      extra={<PageControls />}
    >
      <span className={styles.title}>{workspaceName}</span>
      <WorkspaceDashboardWidgets workspaceId={workspaceId} className={styles.widgets} />
      <div className={styles.controls}>
        <span>{t('common.accounts_by_club')}</span>
        <div>
          {GROUPS.map((item) => {
            const onClick = () => {
              setGroups((prev) => {
                if (prev.includes(item)) return prev.filter((el) => el !== item);

                return [...prev, item];
              });
            };

            return (
              <Chip key={item} active={groups.includes(item)} onClick={onClick}>
                {GROUPS_LABELS[item]}
              </Chip>
            );
          })}
        </div>
      </div>
      {(isDesktop || isTablet) && (
        <Table
          data={data}
          {...getTableProps(query)}
          columns={[
            {
              key: 'club_name',
              title: t('common.club'),
              sorting: true,
              render: ({ values }) => <WorkspaceDashboardDetailLink data={values} />,
              pin: 'left',
              style: { width: 160 },
            },
            ...columns,
            {
              key: 'count',
              title: t('common.accounts'),
              sorting: true,
              render: ({ values }) => values.count,
              align: 'right',
              pin: 'right',
              controls: true,
              style: { minWidth: 92, width: '1fr' },
            },
          ]}
          renderRow={(row, index) => <Table.Row {...checkIsInView(index)} {...row} />}
          footer={grandTotalContent}
        />
      )}
      {isPhone && (
        <>
          <div className={styles.sorting}>
            {grandTotalContent}
            <PageSorting
              sorting={[
                { key: 'club_name', title: t('common.club_name'), default: true },
                ...sorting,
                { key: 'count', title: t('common.accounts') },
              ]}
            />
          </div>
          <Card.Group>
            {data.map((item, index) => (
              <Card key={item.id} {...checkIsInView(index)}>
                <Card.Top>
                  <WorkspaceDashboardDetailLink data={item} />
                </Card.Top>
                <Card.Field.Group>
                  {currentGroups.map((el) => (
                    <Card.Field label={GROUPS_LABELS[el]}>{getContent(item)[el]}</Card.Field>
                  ))}
                  <Card.Field label={t('common.accounts')}>{item.count}</Card.Field>
                </Card.Field.Group>
              </Card>
            ))}
          </Card.Group>
        </>
      )}
    </Page>
  );
};

export default WorkspaceDashboard;
