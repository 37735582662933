import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { workspaceSessionFilterValuesSchema } from '../schemas/session/workspaceSessionSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceSessionsFilterValues = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/session/filter-values`,
    { params }
  );

  return workspaceSessionFilterValuesSchema.parse(response.data);
};

export default loadWorkspaceSessionsFilterValues;
