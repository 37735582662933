import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { STATUS } from '../../../../api/schemas/orderSchema';
import { WorkspaceSchema } from '../../../../api/schemas/workspaceSchema';
import Button from '../../../../components/Button';
import routes from '../../../../config/routes';
import useURL from '../../../../hooks/useURL';
import { checkWorkspaceType } from '../../../../utils/workspace';
import { useWorkspaceBalanceQuery } from '../queries';
import WorkspaceSettingsGeneralBalanceWidget from './WorkspaceSettingsGeneralBalanceWidget';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  deposit: ReactNode;
}

const WorkspaceSettingsGeneralBalance = (props: Props) => {
  const { workspaceId, deposit } = props;

  const { t } = useTranslation();
  const { navigate } = useURL();

  const { data } = useWorkspaceBalanceQuery({ workspaceId });

  const canReadOrders = checkWorkspaceType({ workspaceId }, ['IDENTITY_PROVIDER']);

  return (
    <>
      <WorkspaceSettingsGeneralBalanceWidget label={t('common.balance')} value={data?.balance}>
        {deposit}
      </WorkspaceSettingsGeneralBalanceWidget>
      <WorkspaceSettingsGeneralBalanceWidget
        label={t('common.hold_balance')}
        value={data?.balance_hold}
      >
        {data && canReadOrders && (
          <Button
            size="small"
            variant="transparent"
            onClick={() =>
              navigate(
                `${routes.workspaceOrders({ workspaceId: data.id })}?order_status_in=${
                  STATUS.PROCESS
                }`,
                { params: false }
              )
            }
          >
            {t('common.details')}
          </Button>
        )}
      </WorkspaceSettingsGeneralBalanceWidget>
    </>
  );
};

export default WorkspaceSettingsGeneralBalance;
