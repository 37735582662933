import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadGlobalBotSetting } from '../../api';
import { BotSettingSchema } from '../../api/schemas/botSettingSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { updateDetailQueryData } from '../../utils/queryClient';

interface Props {
  botSettingId: BotSettingSchema['id'];
  children: (args: { data: BotSettingSchema }) => ReactElement;
}

const GlobalBotSettingLoader = (props: Props) => {
  const { botSettingId, children } = props;

  const queryKey = [queryKeys.globalBotSetting({ botSettingId })];
  const queryFn = () => loadGlobalBotSetting({ botSettingId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<BotSettingSchema>({
    domain: 'GlobalBotSettings',
    onMessage: (message) => {
      if (
        message.payload.id === botSettingId &&
        (message.action === 'Edited' || message.action === 'Deleted')
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data });
};

export default GlobalBotSettingLoader;
