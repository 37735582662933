import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { useWorkspaceAccountsStagesQuery } from './queries';
import useCurrentStage from './useCurrentStage';

const useStages = (args: { workspaceId: WorkspaceSchema['id'] }) => {
  const { workspaceId } = args;

  const { currentStage } = useCurrentStage();

  const query = useWorkspaceAccountsStagesQuery({ workspaceId });

  const { data } = query;

  const stage = data?.items.find((item) => item.order === currentStage);

  return { stage, query };
};

export default useStages;
