import { cloneElement, ReactElement } from 'react';
import { releaseWorkspaceIdentityOnMarket, removeWorkspaceIdentityFromMarket } from '../../api';
import {
  BANK_ACCOUNT_TYPE,
  DOCUMENT_TYPE,
  ONLINE_WALLET_TYPE,
  PHOTO_TYPE,
  SOCIAL_NETWORK_TYPE,
} from '../../api/schemas/identity';
import { IdentitySchema } from '../../api/schemas/identitySchema';
import { MarketIdentitySchema } from '../../api/schemas/marketIdentitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { t } from '../../i18n';
import staticIcon from '../../static/icons';
import Facebook from '../../static/social-networks/facebook.svg';
import Instagram from '../../static/social-networks/instagram.svg';
import Telegram from '../../static/social-networks/telegram.svg';
import Twitter from '../../static/social-networks/twitter.svg';
import WeChat from '../../static/social-networks/wechat.svg';
import Btc from '../../static/wallets/btc.svg';
import Eth from '../../static/wallets/eth.svg';
import Neteller from '../../static/wallets/neteller.svg';
import Paypal from '../../static/wallets/paypal.svg';
import Skrill from '../../static/wallets/skrill.svg';
import UsdcErc from '../../static/wallets/usdc_erc.svg';
import UsdcTrc from '../../static/wallets/usdc_trc.svg';
import UsdtErc from '../../static/wallets/usdt_erc.svg';
import UsdtTrc from '../../static/wallets/usdt_trc.svg';
import { convertCentsToUSDT, renderNumber } from '../../utils/data';
import { handleError } from '../../utils/form';
import styles from './styles.module.scss';

type Cost = IdentitySchema['monthly_cost'] | MarketIdentitySchema['monthly_cost'];

export const WALLETS_LABELS = {
  [ONLINE_WALLET_TYPE.UNKNOWN]: '—',
  [ONLINE_WALLET_TYPE.SKRILL]: t('common.skrill'),
  [ONLINE_WALLET_TYPE.NETELLER]: t('common.neteller'),
  [ONLINE_WALLET_TYPE.PAYPAL]: t('common.paypal'),
  [ONLINE_WALLET_TYPE.BTC]: t('common.btc'),
  [ONLINE_WALLET_TYPE.ETH]: t('common.eth'),
  [ONLINE_WALLET_TYPE.USDT_ERC20]: t('common.usdt_erc20'),
  [ONLINE_WALLET_TYPE.USDT_TRC20]: t('common.usdt_trc20'),
  [ONLINE_WALLET_TYPE.USDC_ERC20]: t('common.usdc_erc20'),
  [ONLINE_WALLET_TYPE.USDC_TRC20]: t('common.usdc_trc20'),
} as const;

export const WALLETS_ICONS_16 = {
  [ONLINE_WALLET_TYPE.UNKNOWN]: null,
  [ONLINE_WALLET_TYPE.SKRILL]: <Skrill width={16} height={16} />,
  [ONLINE_WALLET_TYPE.NETELLER]: <Neteller width={16} height={16} />,
  [ONLINE_WALLET_TYPE.PAYPAL]: <Paypal width={16} height={16} />,
  [ONLINE_WALLET_TYPE.BTC]: <Btc width={16} height={16} />,
  [ONLINE_WALLET_TYPE.ETH]: <Eth width={16} height={16} />,
  [ONLINE_WALLET_TYPE.USDT_ERC20]: <UsdtErc width={16} height={16} />,
  [ONLINE_WALLET_TYPE.USDT_TRC20]: <UsdtTrc width={16} height={16} />,
  [ONLINE_WALLET_TYPE.USDC_ERC20]: <UsdcErc width={16} height={16} />,
  [ONLINE_WALLET_TYPE.USDC_TRC20]: <UsdcTrc width={16} height={16} />,
} as const;

export const DOCUMENTS_LABELS = {
  [DOCUMENT_TYPE.UNKNOWN]: '—',
  [DOCUMENT_TYPE.PASSPORT]: t('common.passport'),
  [DOCUMENT_TYPE.NATIONAL_ID_CARD]: t('common.national_id_card'),
  [DOCUMENT_TYPE.DRIVING_LICENSE]: t('common.driving_license'),
  [DOCUMENT_TYPE.UTILITY_BILL]: t('common.utility_bill'),
  [DOCUMENT_TYPE.BANK_STATEMENT]: t('common.bank_statement'),
} as const;

export const DOCUMENTS_NEGATIVE_LABELS = {
  [DOCUMENT_TYPE.UNKNOWN]: '—',
  [DOCUMENT_TYPE.PASSPORT]: t('common.no_passport'),
  [DOCUMENT_TYPE.NATIONAL_ID_CARD]: t('common.no_national_id_card'),
  [DOCUMENT_TYPE.DRIVING_LICENSE]: t('common.no_driving_license'),
  [DOCUMENT_TYPE.UTILITY_BILL]: t('common.no_utility_bill'),
  [DOCUMENT_TYPE.BANK_STATEMENT]: t('common.no_bank_statement'),
} as const;

export const DOCUMENTS_ICONS_16 = {
  [DOCUMENT_TYPE.UNKNOWN]: null,
  [DOCUMENT_TYPE.PASSPORT]: staticIcon('passport', 16),
  [DOCUMENT_TYPE.NATIONAL_ID_CARD]: staticIcon('globe', 16),
  [DOCUMENT_TYPE.DRIVING_LICENSE]: staticIcon('wheel', 16),
  [DOCUMENT_TYPE.UTILITY_BILL]: staticIcon('home', 16),
  [DOCUMENT_TYPE.BANK_STATEMENT]: staticIcon('fileText', 16),
} as const;

export const DOCUMENTS_CROSSED_ICONS_16 = {
  [DOCUMENT_TYPE.UNKNOWN]: null,
  [DOCUMENT_TYPE.PASSPORT]: staticIcon('passportCrossed', 16),
  [DOCUMENT_TYPE.NATIONAL_ID_CARD]: staticIcon('globeCrossed', 16),
  [DOCUMENT_TYPE.DRIVING_LICENSE]: staticIcon('wheelCrossed', 16),
  [DOCUMENT_TYPE.UTILITY_BILL]: staticIcon('homeCrossed', 16),
  [DOCUMENT_TYPE.BANK_STATEMENT]: staticIcon('fileTextCrossed', 16),
} as const;

export const PHOTOS_LABELS = {
  [PHOTO_TYPE.UNKNOWN]: '—',
  [PHOTO_TYPE.SELFIE]: t('common.selfie'),
  [PHOTO_TYPE.SELFIE_WITH_IDENTITY_DOCUMENT]: t('common.selfie_with_identity_document'),
  [PHOTO_TYPE.SELFIE_WITH_BLANK_PAPER_SHEET]: t('common.selfie_with_blank_paper_sheet'),
} as const;

export const PHOTOS_NEGATIVE_LABELS = {
  [PHOTO_TYPE.UNKNOWN]: '—',
  [PHOTO_TYPE.SELFIE]: t('common.no_selfie'),
  [PHOTO_TYPE.SELFIE_WITH_IDENTITY_DOCUMENT]: t('common.no_selfie_with_identity_document'),
  [PHOTO_TYPE.SELFIE_WITH_BLANK_PAPER_SHEET]: t('common.no_selfie_with_blank_paper_sheet'),
} as const;

export const BANK_ACCOUNTS_LABELS = {
  [BANK_ACCOUNT_TYPE.UNKNOWN]: '—',
  [BANK_ACCOUNT_TYPE.CHECKING]: t('common.checking'),
  [BANK_ACCOUNT_TYPE.SAVING]: t('common.saving'),
  [BANK_ACCOUNT_TYPE.MONEY_MARKET]: t('common.money_market'),
  [BANK_ACCOUNT_TYPE.CERTIFICATE_OF_DEPOSIT]: t('common.certificate_of_deposit'),
  [BANK_ACCOUNT_TYPE.CARD]: t('common.card'),
} as const;

export const SOCIAL_NETWORKS_LABELS = {
  [SOCIAL_NETWORK_TYPE.UNKNOWN]: '—',
  [SOCIAL_NETWORK_TYPE.FACEBOOK]: t('common.facebook'),
  [SOCIAL_NETWORK_TYPE.TWITTER]: t('common.twitter'),
  [SOCIAL_NETWORK_TYPE.TELEGRAM]: t('common.telegram'),
  [SOCIAL_NETWORK_TYPE.INSTAGRAM]: t('common.instagram'),
  [SOCIAL_NETWORK_TYPE.WECHAT]: t('common.wechat'),
} as const;

export const SOCIAL_NETWORKS_ICONS_16 = {
  [SOCIAL_NETWORK_TYPE.UNKNOWN]: null,
  [SOCIAL_NETWORK_TYPE.FACEBOOK]: <Facebook width={16} height={16} />,
  [SOCIAL_NETWORK_TYPE.TWITTER]: <Twitter width={16} height={16} />,
  [SOCIAL_NETWORK_TYPE.TELEGRAM]: <Telegram width={16} height={16} />,
  [SOCIAL_NETWORK_TYPE.INSTAGRAM]: <Instagram width={16} height={16} />,
  [SOCIAL_NETWORK_TYPE.WECHAT]: <WeChat width={16} height={16} />,
} as const;

export const renderCost = (value: Cost) => renderNumber(value, convertCentsToUSDT);

export const styleIcon = (icon: ReactElement, condition = true) =>
  cloneElement(icon, { className: condition ? styles.icon : styles.disabledIcon });

export const getRentalState = (data: IdentitySchema) => {
  const { buyer, on_market } = data;

  if (buyer !== null) return t('common.rented');
  if (!on_market) return t('common.private');
  if (on_market) return t('common.on_market');

  return '—';
};

export const getDocumentsTypes = (data: IdentitySchema) => data.documents.map((item) => item.type);

export const getPhotosTypes = (data: IdentitySchema) => data.photos.map((item) => item.type);

export const getWalletsTypes = (data: IdentitySchema) =>
  data.online_wallets.map((item) => item.type);

export const getSocialNetworksTypes = (data: IdentitySchema) =>
  data.social_networks.map((item) => item.type);

export const toggleMarket = async (workspaceId: WorkspaceSchema['id'], data: IdentitySchema) => {
  const { id, on_market } = data;

  try {
    if (on_market) {
      await removeWorkspaceIdentityFromMarket({ workspaceId, identityId: id });
    } else {
      await releaseWorkspaceIdentityOnMarket({ workspaceId, identityId: id });
    }
  } catch (error) {
    handleError({ error });
  }
};

export const getIdentityFullName = (data: Pick<IdentitySchema, 'first_name' | 'last_name'>) =>
  `${data.last_name} ${data.first_name}`;

export const getGender = (gender: IdentitySchema['gender']) => {
  if (gender === 1) return t('common.male');

  if (gender === 2) return t('common.female');

  return '—';
};

export const walletsOptions = Object.values(ONLINE_WALLET_TYPE)
  .filter((item) => item !== 0)
  .map((item) => ({
    value: item,
    label: WALLETS_LABELS[item],
  }));
