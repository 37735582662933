import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { deleteGlobalClub } from '../../api';
import globalClubDeleteErrorSchema from '../../api/schemas/globalClubDeleteErrorSchema';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { isApiError400 } from '../../services/api';
import { handleError } from '../../utils/form';
import { RouteParams } from '../../utils/generics';
import notify from '../../utils/notify';
import { useGlobalClubContext } from './GlobalClubContext';

type Params = RouteParams<typeof routes.globalClub>;

interface Args {
  data: GlobalClubSchema;
}

const useActions = (args: Args) => {
  const { data } = args;

  const { id } = data;

  const { t } = useTranslation();
  const { navigate } = useURL();
  const { clubId } = useParams<Params>();
  const { setDeleteError } = useGlobalClubContext();

  const deleteClub = async () => {
    try {
      await deleteGlobalClub({ clubId: id });

      if (id === clubId) {
        navigate(routes.globalClubs);
      }

      notify('success', { title: t('sentences.record_has_been_deleted') });
    } catch (error) {
      handleError({ error });

      if (
        isApiError400(error) &&
        error.response &&
        globalClubDeleteErrorSchema.parse(error.response.data.error[0].payload)
      ) {
        navigate(routes.globalClubError({ clubId: id }));
        setDeleteError(globalClubDeleteErrorSchema.parse(error.response.data.error[0].payload));
      }
    }
  };

  return { deleteClub };
};

export default useActions;
