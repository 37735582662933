import {
  numbers as numbersRegExp,
  commaSeparatedNumbers as commaSeparatedNumbersRegExp,
  floatNumbers as floatNumbersRegExp,
} from './regExp';

export const numbers = (value: string) => numbersRegExp.test(value);

export const floatNumbers = (limit?: number) => (value: string) =>
  floatNumbersRegExp(limit).test(value);

export const commaSeparatedNumbers = (value: string) => commaSeparatedNumbersRegExp.test(value);

export const commaSeparatedFloatNumbers = (value: string) => {
  const values = value.split(',');

  let isValid = true;

  if (value.startsWith(',')) {
    isValid = false;
  }

  if (isValid) {
    for (let index = 0; index < values.length; index += 1) {
      const element = values[index];

      if (element.startsWith('.')) {
        isValid = false;
        break;
      }

      if (element.startsWith(',')) {
        isValid = false;
        break;
      }

      if (Number.isNaN(Number(element))) {
        isValid = false;
        break;
      }

      if (element.split('.')[1] && element.split('.')[1].length > 2) {
        isValid = false;
        break;
      }
    }
  }

  if (isValid) {
    if (value.endsWith(',,')) {
      isValid = false;
    }

    if (value.endsWith('.,')) {
      isValid = false;
    }
  }

  return isValid;
};

export const time = (value: string) => {
  if (value[2] && Number(value[2]) >= 6) return false;

  return true;
};
