import { z } from 'zod';
import globalAccountSchema from '../account/globalAccountSchema';
import workspaceAccountSchema from '../account/workspaceAccountSchema';
import workspaceSchema from '../workspaceSchema';
import { dealCommonSchema, dealCommonFilterValuesSchema } from '.';

const globalDealSchema = dealCommonSchema.extend({
  accounts: z.array(
    z.object({
      id: globalAccountSchema.shape.id,
      login: workspaceAccountSchema.shape.login,
      mga_id: globalAccountSchema.shape.mga_id,
      workspace: z.object({
        id: globalAccountSchema.shape.maintenance.shape.id,
        name: globalAccountSchema.shape.maintenance.shape.name,
      }),
    })
  ),
  workspaces: z.array(
    z.object({
      id: workspaceSchema.shape.id,
      name: workspaceSchema.shape.name,
    })
  ),
});

export const globalDealFilterValuesSchema = dealCommonFilterValuesSchema;

export type GlobalDealSchema = z.infer<typeof globalDealSchema>;
export type GlobalDealFilterValuesSchema = z.infer<typeof globalDealFilterValuesSchema>;

export default globalDealSchema;
