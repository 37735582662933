import clsx from 'clsx';
import Placeholder from '../../../components/Placeholder';
import Tooltip from '../../../components/Tooltip';
import { CHART_COLORS } from './helpers';
import styles from './styles.module.scss';

interface ChartPiece {
  label: string;
  count: number;
  color: string | null;
}

export type Chart = ChartPiece[];

const sumCount = (data: Chart) => data.reduce((acc, curr) => acc + curr.count, 0);

interface Props {
  title: string;
  data: Chart;
  loading: boolean;
}

const WorkspaceDashboardWidget = (props: Props) => {
  const { title, data, loading } = props;

  const total = data.length;

  const count = sumCount(data);

  const chartColors = Array.from({ length: total }).map((_, index) => {
    const colors = CHART_COLORS.filter((item) => !data.map((el) => el.color).includes(item));

    const colorIndex = index - Math.trunc(index / colors.length) * colors.length;

    return colors[colorIndex];
  });

  return (
    <div className={styles.widget}>
      <div className={styles.title}>
        {title}
        <span>{loading ? <Placeholder className={styles.countPlaceholder} /> : count}</span>
      </div>
      {loading ? (
        <Placeholder className={styles.chartPlaceholder} />
      ) : (
        <div className={clsx(styles.chart, total === 0 && styles.empty)}>
          {data.map((item, index) => (
            <Tooltip key={JSON.stringify(item)} label={`${item.label}: ${item.count}`}>
              <div
                style={{
                  backgroundColor: item.color || chartColors[index],
                  width: `calc(100% * ${item.count} / ${count})`,
                }}
                className={styles.chartPiece}
              />
            </Tooltip>
          ))}
        </div>
      )}
    </div>
  );
};

export default WorkspaceDashboardWidget;
