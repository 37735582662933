import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { GlobalDealSchema } from '../schemas/deal/globalDealSchema';
import workspaceAccountNoteSchema from '../schemas/noteSchema';
import paginationSchemaNew from '../schemas/paginationSchemaNew';

interface Args {
  dealId: GlobalDealSchema['id'];
}

const loadGlobalDealNotes = async (args: Args, params?: URLParams) => {
  const { dealId } = args;

  const response = await api.get(`/api/deal/${dealId}/notes`, {
    params,
  });

  return paginationSchemaNew(workspaceAccountNoteSchema).parse(response.data);
};

export default loadGlobalDealNotes;
