import { WorkspaceClubWhitelistSchema } from '../../api/schemas/workspaceClubWhitelistSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Dropdown from '../../components/Dropdown';
import { compact } from '../../utils';
import useDropdown from './useDropdown';

interface Props {
  data: WorkspaceClubWhitelistSchema;
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceClubWhitelistDropdown = (props: Props) => {
  const { title, options } = useDropdown(props);

  const compactOptions = compact([options.copy, options.divider, options.delete, options.restore]);

  return <Dropdown title={title} buttonSize="small" options={compactOptions} />;
};

export default WorkspaceClubWhitelistDropdown;
