import clsx from 'clsx';
import { Dispatch, ElementRef, forwardRef, ReactNode, SetStateAction, useState } from 'react';
import icon from '../../static/icons';
import Button from '../Button';
import Link from '../Link';
import CollapseBadge from './CollapseBadge';
import CollapseCaption from './CollapseCaption';
import CollapseFieldGroup from './CollapseFieldGroup';
import CollapseGroup from './CollapseGroup';
import CollapseId from './CollapseId';
import CollapseInfoField from './CollapseInfoField';
import CollapseLabelCopy from './CollapseLabelCopy';
import CollapseSecondaryLabel from './CollapseSecondaryLabel';
import styles from './styles.module.scss';

interface Props {
  label: ReactNode;
  to?: string;
  labelAfter?: ReactNode;
  labelBefore?: ReactNode;
  caption?: ReactNode;
  className?: string;
  defaultOpen?: boolean;
  alwaysRenderContent?: boolean;
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  extra?: ReactNode;
  children?: ReactNode;
  collapse?: boolean;
}

const Collapse = Object.assign(
  forwardRef<ElementRef<'div'>, Props>((props, ref) => {
    const {
      label,
      to,
      labelBefore,
      labelAfter,
      caption,
      className,
      defaultOpen = false,
      alwaysRenderContent = false,
      open: passedOpen,
      setOpen: passedSetOpen,
      extra,
      children,
      collapse = true,
    } = props;

    let [open, setOpen] = useState(defaultOpen);

    open = passedOpen || open;
    setOpen = passedSetOpen || setOpen;

    const handleClick = () => setOpen((prev) => !prev);

    const renderContent = () => {
      if (alwaysRenderContent) {
        return <div className={clsx(styles.content, open && styles.open)}>{children}</div>;
      }

      if (open) return <div className={clsx(styles.content, styles.open)}>{children}</div>;

      return null;
    };

    return (
      <div ref={ref} className={clsx(styles.container, className)}>
        <div className={styles.top}>
          <div className={styles.wrapperInfo}>
            {labelBefore}
            <div className={styles.info}>
              <div className={styles.label}>
                {to ? (
                  <Link to={to} className={styles.labelLink}>
                    {label}
                  </Link>
                ) : (
                  <span className={styles.labelText}>{label}</span>
                )}
                {labelAfter}
              </div>
              {caption}
            </div>
          </div>
          <div className={styles.controls}>
            {extra}
            {collapse && (
              <div className={styles.button}>
                <Button
                  size="small"
                  variant="ghost"
                  icon={open ? icon('chevronUp', 16) : icon('chevronDown', 16)}
                  onClick={handleClick}
                  disabled={!children}
                />
              </div>
            )}
          </div>
        </div>
        {renderContent()}
      </div>
    );
  }),
  {
    Id: CollapseId,
    SecondaryLabel: CollapseSecondaryLabel,
    Badge: CollapseBadge,
    FieldGroup: CollapseFieldGroup,
    InfoField: CollapseInfoField,
    Group: CollapseGroup,
    Caption: CollapseCaption,
    LabelCopy: CollapseLabelCopy,
  }
);

export default Collapse;
