import { useState, useCallback } from 'react';

const useOverflowCount = () => {
  const [element, setElement] = useState<HTMLDivElement | null>(null);

  const ref = useCallback((node: HTMLDivElement) => setElement(node), [setElement]);

  const count = (() => {
    if (element) {
      const childrens = Array.from(element.children);

      for (let index = 0; index < childrens.length; index += 1) {
        const children = childrens[index];

        if (children instanceof HTMLDivElement && children.offsetLeft > element.clientWidth) {
          return childrens.length - index + 1;
        }
      }
    }

    return 0;
  })();

  return { ref, count };
};

export default useOverflowCount;
