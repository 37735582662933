import clsx from 'clsx';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import icon from '../../static/icons';
import Input, { Props as InputProps } from '../Input';
import styles from './styles.module.scss';

type Value = string;

interface Props {
  value: Value;
  onChange: (value: Value) => void;
  size?: InputProps['size'];
  className?: string;
  icon?: boolean;
  renderExtra?: (args: { clear: () => void }) => InputProps['extra'];
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyDown?: InputProps['onKeyDown'];
}

const SearchInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    value,
    className,
    size = 'medium',
    renderExtra,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
  } = props;

  const { t } = useTranslation();

  const clear = () => onChange('');

  return (
    <Input
      ref={ref}
      size={size}
      value={value}
      placeholder={t('common.search')}
      className={clsx(styles.input, className)}
      onChange={(event) => onChange(event.target.value)}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      {...(props.icon !== false && { icon: icon('search', 16) })}
      {...(value && {
        extra: renderExtra ? renderExtra({ clear }) : <Input.ClearButton onClick={clear} />,
      })}
    />
  );
});

export default SearchInput;
