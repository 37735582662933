import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadWorkspaceUser } from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { WorkspaceUserSchema } from '../../api/schemas/workspaceUserSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { updateDetailQueryData } from '../../utils/queryClient';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  userId: WorkspaceUserSchema['id'];
  children: (args: { data: WorkspaceUserSchema }) => ReactElement;
}

const WorkspaceUserLoader = (props: Props) => {
  const { workspaceId, userId, children } = props;

  const queryKey = [queryKeys.workspaceUser({ workspaceId, userId })];
  const queryFn = () => loadWorkspaceUser({ workspaceId, userId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<WorkspaceUserSchema>({
    domain: 'User',
    enabled: (message) => message.payload.id === userId,
    onMessage: (message) => {
      if (message.action === 'EmailVerified') {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  useWebSocketMessage<{
    id: WorkspaceSchema['id'];
    participant: WorkspaceUserSchema;
  }>({
    domain: 'Maintenance',
    enabled: (message) => message.payload.id === workspaceId,
    onMessage: (message) => {
      if (message.action === 'ParticipantRemoved') {
        updateDetailQueryData(queryKey, message.payload.participant);
      }
    },
  });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data });
};

export default WorkspaceUserLoader;
