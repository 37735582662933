import { OrderSchema } from '../../api/schemas/orderSchema';
import Dropdown from '../../components/Dropdown';
import useDropdown from './useDropdown';

interface Props {
  data: OrderSchema;
}

const OrderDropdown = (props: Props) => {
  const { title, options } = useDropdown(props);

  return <Dropdown title={title} buttonSize="small" options={[options.copy]} />;
};

export default OrderDropdown;
