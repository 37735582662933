import { z } from 'zod';
import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Payload {
  account_ids: WorkspaceAccountSchema['id'][];
  columns: number[];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
  params?: URLParams;
}

const exportFilteredWorkspaceAccounts = async (args: Args) => {
  const { workspaceId, payload, params } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/export-filtered`,
    payload,
    { params }
  );

  return z.string().parse(response.data);
};

export default exportFilteredWorkspaceAccounts;
