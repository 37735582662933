import { useMediaQuery } from 'react-responsive';

const useBreakpoints = () => {
  const isDesktop = useMediaQuery({ minWidth: 1279 });
  const isMobile = useMediaQuery({ maxWidth: 1279 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1279 });
  const isPhone = useMediaQuery({ maxWidth: 767 });

  return { isDesktop, isMobile, isTablet, isPhone };
};

export default useBreakpoints;
