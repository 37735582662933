import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { deleteWorkspace, loadWorkspace } from '../../../api';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Button from '../../../components/Button';
import ConfirmDialog from '../../../components/ConfirmDialog';
import queryKeys from '../../../config/queryKeys';
import routes from '../../../config/routes';
import useURL from '../../../hooks/useURL';
import useWebSocketMessage from '../../../hooks/useWebSocketMessage';
import Error403Page from '../../../pages/Error403Page';
import icon from '../../../static/icons';
import { useUserStore } from '../../../store/user';
import notify from '../../../utils/notify';
import { updateDetailQueryData } from '../../../utils/queryClient';
import WorkspaceSettingsDepositDialog from '../WorkspaceSettingsDepositDialog';
import WorkspaceSettingsSpinner from '../WorkspaceSettingsSpinner';
import styles from './styles.module.scss';
import WorkspaceSettingsGeneralBalance from './WorkspaceSettingsGeneralBalance';
import WorkspaceSettingsGeneralForm from './WorkspaceSettingsGeneralForm';

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceSettingsGeneral = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const user = useUserStore();
  const { navigate } = useURL();

  const queryKey = [queryKeys.workspace({ workspaceId })];
  const queryFn = () => loadWorkspace({ workspaceId });

  const { data, isPending, error } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<WorkspaceSchema>({
    domain: 'Maintenance',
    onMessage: (message) => {
      if (message.payload.id === workspaceId) {
        if (message.action === 'Edited') {
          updateDetailQueryData(queryKey, message.payload);
        }

        if (message.action === 'OwnerChanged') {
          updateDetailQueryData(queryKey, message.payload);
        }
      }
    },
  });

  if (error?.response?.status === 403) return <Error403Page />;

  if (isPending) return <WorkspaceSettingsSpinner />;

  if (!data) return null;

  const { id, name, owner_id } = data;

  const deleteRecord = async () => {
    await deleteWorkspace({ workspaceId: id });

    navigate(routes.home, { params: false });

    notify('success', { title: t('sentences.record_has_been_deleted') });
  };

  const owner = user.data?.id === owner_id;

  return (
    <div className={styles.container}>
      {data.hands_billing && (
        <WorkspaceSettingsGeneralBalance
          workspaceId={workspaceId}
          deposit={
            <WorkspaceSettingsDepositDialog workspaceId={id}>
              {({ referenceProps }) => (
                <Button size="small" {...referenceProps}>
                  {t('common.deposit')}
                </Button>
              )}
            </WorkspaceSettingsDepositDialog>
          }
        />
      )}
      <WorkspaceSettingsGeneralForm key={data.modified_on} data={data} disabled={!owner} />
      {owner && (
        <div className={styles.delete}>
          <span>{t('common.delete_workspace')}</span>
          {t('sentences.delete_workspace')}
          <ConfirmDialog
            danger
            title={t('common.delete_workspace')}
            description={
              <>
                {t('sentences.confirm_delete_1')} <span className={styles.bold}>{name}</span>
                {t('sentences.confirm_delete_2')}
              </>
            }
            confirmText={t('common.delete')}
            onConfirm={() => deleteRecord()}
          >
            {({ referenceProps }) => (
              <Button
                danger
                size="small"
                variant="secondary"
                icon={icon('trash', 16)}
                {...referenceProps}
              >
                {t('common.delete_workspace')}
              </Button>
            )}
          </ConfirmDialog>
        </div>
      )}
    </div>
  );
};

export default WorkspaceSettingsGeneral;
