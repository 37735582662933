import { useTranslation } from 'react-i18next';
import PageAddon from '..';
import Spinner from '../../Spinner';
import styles from './styles.module.scss';

interface Props {
  title: string;
  description: string;
}

const PageAddonLoading = (props: Props) => {
  const { title, description } = props;

  const { t } = useTranslation();

  return (
    <PageAddon title={title}>
      <div className={styles.container}>
        <div className={styles.spinner}>
          <Spinner />
        </div>
        <span className={styles.title}>{description}</span>
        <span className={styles.description}>{t('sentences.wait_for_a_few_seconds')}</span>
      </div>
    </PageAddon>
  );
};

export default PageAddonLoading;
