import { BRAIN_STATUS, ROOM_STATUS } from '../../api/schemas/account';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import { t } from '../../i18n';
import {
  TRAINER_GAME_TYPE_LABELS,
  TRAINER_LIMITS_LABELS,
  TRAINER_POKER_ROOM_LABELS,
} from '../../utils/trainer';
import { getGeolocationAsString, TABLE_SIZE_LABELS } from '../account/helpers';
import { GlobalAccountFields as Fields } from '../account/types';

export const ROOM_STATUS_LABELS: Record<GlobalAccountSchema['room_status'], string> = {
  [ROOM_STATUS.UNKNOWN]: '—',
  [ROOM_STATUS.OFF]: t('common.off'),
  [ROOM_STATUS.ON]: t('common.on'),
};

export const BRAIN_STATUS_LABELS: Record<GlobalAccountSchema['brain_status'], string> = {
  [BRAIN_STATUS.UNKNOWN]: '—',
  [BRAIN_STATUS.DISCONNECTED]: t('common.disconnected'),
  [BRAIN_STATUS.CONNECTED]: t('common.connected'),
};

export const getDefaultValues = (data: GlobalAccountSchema) => ({
  room: { value: data.room, label: TRAINER_POKER_ROOM_LABELS[data.room] },
  game_types: data.game_types.map((item) => ({
    value: item,
    label: TRAINER_GAME_TYPE_LABELS[item],
  })),
  comparative_limits: data.comparative_limits.map((item) => ({
    value: item,
    label: TRAINER_LIMITS_LABELS[item],
  })),
  table_sizes: data.table_sizes.map((item) => ({
    value: item,
    label: TABLE_SIZE_LABELS[item],
  })),
  max_tables: String(data.max_tables),
  geo: getGeolocationAsString(data.geo),
  bot_settings_ids: data.bot_settings.map((item) => ({ value: item.id, label: item.name })),
  club_ids: data.clubs.map((item) => ({ value: item.id, label: item.name, caption: item.code })),
  stopped_club_ids: data.stopped_clubs.map((item) => item.id),
});

export const getPayload = (values: Fields) => {
  const [lat, lng] = values.geo.split(',');

  return {
    game_types: values.game_types.map((item) => item.value),
    comparative_limits: values.comparative_limits.map((item) => item.value),
    table_sizes: values.table_sizes.map((item) => item.value),
    max_tables: Number(values.max_tables),
    geo: values.geo ? { lat: Number(lat), lng: Number(lng) } : null,
    bot_settings_ids: values.bot_settings_ids.map((item) => item.value),
    club_ids: values.club_ids.map((item) => item.value),
    stopped_club_ids: values.stopped_club_ids,
  };
};
