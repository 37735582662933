import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Auth from '../../components/Auth';
import Form from '../../components/Form';
import Input from '../../components/Input';
import routes from '../../config/routes';
import { sendRecoveryPassword } from '../../utils/auth';
import { email, required } from '../../utils/form';

interface Fields {
  email: string;
}

const RecoveryPage = () => {
  const { t } = useTranslation();

  const [checkEmail, setCheckEmail] = useState(false);

  const form = useForm<Fields>({ defaultValues: { email: '' } });

  const {
    watch,
    register,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    await sendRecoveryPassword(values);

    setCheckEmail(true);
  };

  const userEmail = watch('email');

  const backToLogin = (
    <Auth.Link back to={routes.login}>
      {t('common.back_to_log_in')}
    </Auth.Link>
  );

  if (checkEmail) {
    return (
      <Auth logo={false} title={t('common.check_your_email')}>
        <Auth.Text>
          {t('sentences.recovery_link_sent_to')}
          &nbsp;
          <span>{userEmail}</span>
        </Auth.Text>
        {backToLogin}
      </Auth>
    );
  }

  return (
    <Auth title={t('common.password_recovery')} description={t('sentences.send_a_recovery_link')}>
      <Form form={form} onSubmit={onSubmit}>
        <Input
          label={t('common.email')}
          error={errors.email?.message}
          {...register('email', { validate: { required, email } })}
        />
        <Auth.SubmitButton>{t('common.send_recovery_link')}</Auth.SubmitButton>
      </Form>
      {backToLogin}
    </Auth>
  );
};

export default RecoveryPage;
