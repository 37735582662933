import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import querySchema from './querySchema';
import workspaceSchema from './workspaceSchema';

export const ACTION_TYPE = {
  PLAYER: 10,
  OBSERVER: 20,
} as const;

const seatControlRuleSchema = z.object({
  id: z.string(),
  name: z.string(),
  query: querySchema,
  action_type: z.nativeEnum(ACTION_TYPE),
  maintenance: workspaceSchema,
  is_active: z.boolean(),
  is_deleted: z.boolean(),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export const seatControlRuleFilterValuesSchema = z.object({
  maintenance: z.array(
    z.object({
      id: seatControlRuleSchema.shape.maintenance.shape.id,
      name: seatControlRuleSchema.shape.maintenance.shape.name,
    })
  ),
});

export type SeatControlRuleSchema = z.infer<typeof seatControlRuleSchema>;

export default seatControlRuleSchema;
