import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { loadWorkspaceSessionsFilterValues } from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import queryKeys from '../../config/queryKeys';
import URLParams from '../../types/URLParams';
import SessionFilter from '../session/SessionFilter';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  urlParams: URLParams;
}

const WorkspaceSessionFilter = (props: Props) => {
  const { workspaceId, urlParams } = props;

  const queryKey = [queryKeys.workspaceSessionsFilterValues({ workspaceId }), urlParams];
  const queryFn = () => loadWorkspaceSessionsFilterValues({ workspaceId }, urlParams);

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  return <SessionFilter data={data} loading={isPending} workspace />;
};

export default WorkspaceSessionFilter;
