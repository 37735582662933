import clsx from 'clsx';
import { ComponentPropsWithRef, ReactNode, forwardRef } from 'react';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  count?: number;
  extra?: ReactNode;
  active?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: ComponentPropsWithRef<'button'>['onClick'];
}

const Chip = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { children, count, extra, active, disabled, className, ...rest } = props;

  return (
    <button
      ref={ref}
      type="button"
      disabled={disabled}
      className={clsx(styles.container, className, active && styles.active)}
      {...rest}
    >
      <div>
        {count && <span className={styles.count}>{count}</span>}
        {children}
      </div>
      {extra}
    </button>
  );
});

export default Chip;
