import api from '../../services/api';
import botSettingSchema, { BotSettingSchema } from '../schemas/botSettingSchema';

interface Args {
  botSettingId: BotSettingSchema['id'];
}

const loadGlobalBotSetting = async (args: Args) => {
  const { botSettingId } = args;

  const response = await api.get(`/api/bot-settings/${botSettingId}`);

  return botSettingSchema.parse(response.data);
};

export default loadGlobalBotSetting;
