import { LobbySchema } from '../../api/schemas/lobbySchema';
import { TRAINER_GAME_TYPE_LABELS } from '../../utils/trainer';
import { checkIsCritical, checkIsWarning, checkIsOk } from './helpers';

const getSortedData = (data: LobbySchema[]) => {
  const critical: LobbySchema[] = [];
  const warning: LobbySchema[] = [];
  const ok: LobbySchema[] = [];
  const other: LobbySchema[] = [];
  const banned: LobbySchema[] = [];

  data.forEach((item) => {
    if (checkIsCritical(item)) {
      critical.push(item);
    } else if (checkIsWarning(item)) {
      warning.push(item);
    } else if (checkIsOk(item)) {
      ok.push(item);
    } /* else if (checkIsBanned(item)) {
      banned.push(item);
    } */ else {
      other.push(item);
    }
  });

  const groups = [critical, warning, ok, other, banned];

  const sortGroup = (group: LobbySchema[]) =>
    [...group].sort((a, b) => {
      if (a.accounts.length > b.accounts.length) return 1;
      if (a.accounts.length < b.accounts.length) return -1;

      /* if (Number(a.table.ff_pool) > Number(b.table.ff_pool)) return 1;
      if (Number(a.table.ff_pool) < Number(b.table.ff_pool)) return -1; */
      const compare = TRAINER_GAME_TYPE_LABELS[a.table.game_type].localeCompare(
        TRAINER_GAME_TYPE_LABELS[b.table.game_type]
      );

      if (compare === 1) return 1;
      if (compare === -1) return -1;

      if (a.table.big_blind > b.table.big_blind) return 1;
      if (a.table.big_blind < b.table.big_blind) return -1;

      if (a.table.max_no_of_seats > b.table.max_no_of_seats) return 1;
      if (a.table.max_no_of_seats < b.table.max_no_of_seats) return -1;

      if (a.table.player_count > b.table.player_count) return -1;
      if (a.table.player_count < b.table.player_count) return 1;

      return 0;
    });

  return groups.map(sortGroup).flat();
};

export default getSortedData;
