import { useTranslation } from 'react-i18next';
import { GlobalDealFilterValuesSchema } from '../../../api/schemas/deal/globalDealSchema';
import PageTabs from '../../../components/Page/PageTabs';
import useURL from '../../../hooks/useURL';
import { TRAINER_POKER_ROOM_LABELS } from '../../../utils/trainer';

interface Props {
  loading: boolean;
  data?: GlobalDealFilterValuesSchema;
}

const GlobalDealRooms = (props: Props) => {
  const { loading, data } = props;

  const { t } = useTranslation();
  const { urlParams, setURLParams } = useURL();
  const { poker_room_in } = urlParams;

  if (loading) return <PageTabs.Placeholder />;
  if (!data) return null;

  return (
    <PageTabs>
      <PageTabs.Tab onClick={() => setURLParams({ poker_room_in: '' })} active={!poker_room_in}>
        <span>{t('common.all')}</span>
      </PageTabs.Tab>
      {data.poker_room.map((item) => (
        <PageTabs.Tab
          key={item}
          active={Number(poker_room_in) === item}
          onClick={() => setURLParams({ poker_room_in: String(item) })}
        >
          {TRAINER_POKER_ROOM_LABELS[item]}
        </PageTabs.Tab>
      ))}
    </PageTabs>
  );
};

export default GlobalDealRooms;
