import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { HashSchema } from '../schemas/hashSchema';

interface Payload {
  user_hash: HashSchema;
}

interface Args {
  payload: Payload;
}

const verifyEmail = async (args: Args) => {
  const { payload } = args;

  const response = await api.post(`/api/verify-email`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default verifyEmail;
