import api from '../../services/api';
import { GlobalAccountSchema } from '../schemas/account/globalAccountSchema';
import { AccountRoomConfigSchema } from '../schemas/accountRoomConfigSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';

type Schema = AccountRoomConfigSchema;

type Payload = Schema;

interface Args {
  accountId: GlobalAccountSchema['id'];
  payload: Payload;
}

const updateGlobalAccountRoomConfig = async (args: Args) => {
  const { accountId, payload } = args;

  const response = await api.patch(`/api/account/${accountId}/room-customize`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateGlobalAccountRoomConfig;
