import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import PageDetailLink from '../../../components/PageDetailLink';
import routes from '../../../config/routes';
import { BUSINESS_MODEL_LABELS } from '../helpers';
import styles from './styles.module.scss';

interface Props {
  data: WorkspaceSchema;
}

const GlobalWorkspaceDetailLink = (props: Props) => {
  const { data } = props;

  const { id, name, business_model } = data;

  return (
    <div className={styles.container}>
      <PageDetailLink label={name} to={routes.globalWorkspace({ workspaceId: id })} />
      <div className={styles.caption}>{BUSINESS_MODEL_LABELS[business_model]}</div>
    </div>
  );
};

export default GlobalWorkspaceDetailLink;
