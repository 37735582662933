import api from '../../services/api';
import agentSchema, { AgentSchema } from '../schemas/agentSchema';

interface Args {
  agentId: AgentSchema['id'];
}

const loadGlobalAgent = async (args: Args) => {
  const { agentId } = args;

  const response = await api.get(`/api/agent/${agentId}`);

  return agentSchema.parse(response.data);
};

export default loadGlobalAgent;
