import { GlobalExpenseSchema } from '../../api/schemas/globalExpenseSchema';
import Dropdown from '../../components/Dropdown';
import useDropdown from './useDropdown';

interface Props {
  data: GlobalExpenseSchema;
}

const GlobalExpenseDetailDropdown = (props: Props) => {
  const { title, disabled, options } = useDropdown(props);

  return (
    <Dropdown title={title} buttonSize="medium" disabled={disabled} options={[options.delete]} />
  );
};

export default GlobalExpenseDetailDropdown;
