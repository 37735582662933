import { z } from 'zod';
import api from '../../services/api';
import { LobbySchema } from '../schemas/lobbySchema';
import lobbyTablePlayerSchema from '../schemas/lobbyTablePlayerSchema';

interface Args {
  tableId: LobbySchema['table']['id'];
}

const loadGlobalLobbyTablePlayers = async (args: Args) => {
  const { tableId } = args;

  const response = await api.get(`/api/lobby/room/${tableId}/players`);

  return z.object({ players: z.array(lobbyTablePlayerSchema).or(z.null()) }).parse(response.data);
};

export default loadGlobalLobbyTablePlayers;
