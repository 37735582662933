import { useInfiniteQuery } from '@tanstack/react-query';
import { ReactElement, useId, useState } from 'react';
import { PaginationSchemaNew } from '../../api/schemas/paginationSchemaNew';
import { Options, Props as SelectProps } from './types';

type Args = {
  next_page?: string;
  search?: string;
};

interface Props<
  T extends (args: Args) => Promise<PaginationSchemaNew<Awaited<ReturnType<T>>['items'][number]>>,
> {
  onLoad: T;
  select: (response: Awaited<ReturnType<T>>['items'][number]) => Options[number];
  children: (args: {
    options: Options;
    filterOptions: Exclude<SelectProps['filterOptions'], undefined>;
    loading: Exclude<SelectProps['loading'], undefined>;
    onSearch: Exclude<SelectProps['onSearch'], undefined>;
    onOpen: Exclude<SelectProps['onOpen'], undefined>;
    onLastOptionInView?: Exclude<SelectProps['onLastOptionInView'], undefined>;
  }) => ReactElement;
}

const SelectAsyncV2 = <
  T extends (args: Args) => Promise<PaginationSchemaNew<Awaited<ReturnType<T>>['items'][number]>>,
>(
  props: Props<T>
) => {
  const { onLoad, children, select } = props;

  const id = useId();
  const [search, setSearch] = useState('');
  const [enabled, setEnabled] = useState(false);

  const queryKey = [id, search, enabled];

  const { data, isPending, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam: next_page }) =>
      onLoad({ ...(next_page && { next_page }), ...(search && { search }) }),
    enabled,
    initialPageParam: '',
    getNextPageParam: ({ next_page }) => next_page || null,
  });

  const options = data?.pages.flatMap((item) => item.items.map(select)) || [];

  const onLastOptionInView = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  return children({
    options,
    filterOptions: false,
    loading: isPending,
    onOpen: setEnabled,
    onSearch: setSearch,
    onLastOptionInView,
  });
};

export default SelectAsyncV2;
