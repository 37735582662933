import { useQuery } from '@tanstack/react-query';
import loadGlobalAccountBalance from '../../api/global-account/loadGlobalAccountBalance';
import { GlobalAccountBalanceSchema } from '../../api/schemas/account/globalAccountBalanceSchema';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { updateDetailQueryData } from '../../utils/queryClient';
import AccountBalance from '../account/AccountBalance';
import { OnTransactionsOpen } from '../account/types';
import GlobalAccountDetailBalanceControls from './GlobalAccountDetailBalanceControls';

interface Props {
  accountData: GlobalAccountSchema;
  onTransactionsOpen?: OnTransactionsOpen;
}

const GlobalAccountDetailBalanceTab = (props: Props) => {
  const { accountData, onTransactionsOpen } = props;

  const { id: accountId } = accountData;

  const queryKey = [queryKeys.globalAccountBalance({ accountId })];
  const queryFn = () => loadGlobalAccountBalance({ accountId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<GlobalAccountBalanceSchema>({
    domain: 'AccountBalance',
    onMessage: (message) => {
      if (message.action === 'Changed' && message.payload.id === accountId) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  if (isPending) return <PageAddon.DetailPlaceholder wrapper={false} />;
  if (!data) return null;

  return (
    <AccountBalance
      data={data}
      {...(onTransactionsOpen && {
        renderButtons: (balance) => (
          <GlobalAccountDetailBalanceControls
            balance={balance}
            onTransactionsOpen={onTransactionsOpen}
          />
        ),
      })}
    />
  );
};

export default GlobalAccountDetailBalanceTab;
