import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { minLength, required } from '../../utils/form';
import notify from '../../utils/notify';
import Dialog from '../Dialog';
import Form from '../Form';
import PasswordInput from '../PasswordInput';
import SubmitButton from '../SubmitButton';
import styles from './styles.module.scss';

interface Fields {
  password: string;
}

interface Props {
  onClose: () => void;
  onSubmit: (values: Fields) => void;
}

const ChangePasswordDialog = (props: Props) => {
  const { onClose, onSubmit } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({ defaultValues: { password: '' } });

  const {
    register,
    formState: { errors },
  } = form;

  return (
    <Dialog onClose={onClose}>
      {({ close }) => {
        const handleSubmit = async (values: Fields) => {
          await onSubmit(values);

          notify('success', { title: t('sentences.password_has_been_changed') });
          close({ confirmClose: false });
        };

        return (
          <>
            <Dialog.Top>
              <Dialog.Title>{t('common.change_password')}</Dialog.Title>
            </Dialog.Top>
            <Form form={form} onSubmit={handleSubmit} className={styles.form}>
              <PasswordInput
                label={`${t('common.new_password')}*`}
                error={errors.password?.message}
                {...register('password', {
                  validate: { required, minLength: minLength() },
                })}
              />
              <Dialog.Footer>
                <SubmitButton>{t('common.change')}</SubmitButton>
              </Dialog.Footer>
            </Form>
          </>
        );
      }}
    </Dialog>
  );
};

export default ChangePasswordDialog;
