import api from '../../services/api';
import globalUserSchema, { GlobalUserSchema } from '../schemas/globalUserSchema';

interface Args {
  userId: GlobalUserSchema['id'];
}

const loadGlobalUser = async (args: Args) => {
  const { userId } = args;

  const response = await api.get(`/api/user/${userId}`);

  return globalUserSchema.parse(response.data);
};

export default loadGlobalUser;
