import { z } from 'zod';
import workspaceDealSchema from '../deal/workspaceDealSchema';
import { identityCommonSchema } from '../identity';
import metricsSchema from '../metricsSchema';
import { workspaceStrategyProfileSchema } from '../workspaceStrategyProfileSchema';
import workspaceTagSchema from '../workspaceTagSchema';
import accountCommonIdentitySchema from './accountCommonIdentitySchema';
import { accountCommonSchema, accountCommonFilterValuesSchema } from '.';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const workspaceAccountPasswordSchema = z.string();
const workspaceAccountVpipSchema = z.number();

const workspaceAccountSchema = accountCommonSchema.extend({
  identity: accountCommonIdentitySchema, // cyclic
  login: z.string(),
  tags: z.array(workspaceTagSchema.pick({ id: true, name: true, color: true })),
  user: accountCommonSchema.shape.user.or(z.null()),
  vpip: workspaceAccountVpipSchema.or(z.null()),
  deals: z.array(workspaceDealSchema),
  strategy_profile: workspaceStrategyProfileSchema.pick({
    buyin_bb: true,
    rebuy_below_bb: true,
    stop_lose_bb: true,
    stop_win_bb: true,
    max_swing_bb: true,
    stop_lose_table_bb: true,
    stop_win_table_bb: true,
    max_stack_size_bb: true,
    effective_stack_limit_bb: true,
    table_min_hands: true,
    buyin_bb_min: true,
    buyin_bb_max: true,
    buyin_bb_straddle_table_min: true,
    buyin_bb_straddle_table_max: true,
    rebuy_below_bb_straddle_table: true,
    player_type: true,
    buyin_step_bb: true,
    buyin_step_bb_straddle_table: true,
    wait_for_big_blind: true,
    seating_preference: true,
  }),
});

export const workspaceAccountFilterValuesSchema = accountCommonFilterValuesSchema.extend({
  tag: z.array(
    workspaceAccountSchema.shape.tags.element.pick({ id: true, name: true, color: true })
  ),
  identity: z.array(z.object({ id: identityCommonSchema.shape.id, name: z.string() })),
  hands_played: z.object({
    min: metricsSchema.shape.hands_played,
    max: metricsSchema.shape.hands_played,
  }),
  net_win_sum: z.object({
    min: metricsSchema.shape.net_win_sum,
    max: metricsSchema.shape.net_win_sum,
  }),
  vpip: z.object({
    min: workspaceAccountVpipSchema,
    max: workspaceAccountVpipSchema,
  }),
  strategy_player_type: z.array(workspaceAccountSchema.shape.strategy_profile.shape.player_type),
});

export type WorkspaceAccountPasswordSchema = z.infer<typeof workspaceAccountPasswordSchema>;
export type WorkspaceAccountSchema = z.infer<typeof workspaceAccountSchema>;

export default workspaceAccountSchema;
