import clsx from 'clsx';
import { ReactNode, useState } from 'react';
import { JsonSchema } from '../../../../api/schemas/jsonSchema';
import icon from '../../../../static/icons';
import { copy } from '../../../../utils/clipboard';
import Button from '../../../Button';
import styles from './styles.module.scss';

interface Props {
  label: string;
  value: JsonSchema;
  children: ReactNode;
  nestingLevel: number;
}

const PageAddonCodeCollapse = (props: Props) => {
  const { label, value, children, nestingLevel } = props;

  const [closed, setClosed] = useState(false);

  const toggle = () => setClosed((prev) => !prev);

  return (
    <>
      <div style={{ columnGap: `${nestingLevel * 8}px` }} className={styles.container}>
        <div className={styles.index}>
          <Button
            variant="light"
            icon={icon('copy', 12)}
            className={styles.copyButton}
            onClick={() => copy(`${label}: ${JSON.stringify(value, null, 2)}`)}
          />
        </div>
        <button type="button" className={styles.collapse} onClick={toggle}>
          {nestingLevel > 1 && <div className={styles.dot} />}
          <div className={clsx(styles.label, closed && styles.closed)}>
            <span>{label}</span>
            {icon('chevronUp', 12)}
          </div>
        </button>
      </div>
      <div className={clsx(styles.content, closed && styles.closed)}>{children}</div>
    </>
  );
};

export default PageAddonCodeCollapse;
