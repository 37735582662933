import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MB } from '../../constants';
import CONTENT_TYPES from '../../constants/CONTENT_TYPES';
import EXTENSIONS from '../../constants/EXTENSIONS';
import { isApiError400 } from '../../services/api';
import icon from '../../static/icons';
import { downloadFile } from '../../utils/files';
import notify from '../../utils/notify';
import Button from '../Button';
import Dropzone from '../Dropzone';
import PageAddon from '../PageAddon';
import styles from './styles.module.scss';

interface Props {
  title: string;
  sample: string;
  onClose: () => void;
  onImport: (payload: FormData) => void;
}

const ImportFromCSV = (props: Props) => {
  const { title, sample, onClose, onImport } = props;

  const { t } = useTranslation();

  const [file, setFile] = useState<File | null>(null);
  const [isImporting, setImporting] = useState(false);
  const [fileError, setFileError] = useState<string[] | null>(null);

  const downloadSampleFile = () => downloadFile(sample, 'sample.csv');

  const handleImport = async () => {
    try {
      if (file) {
        const formData = new FormData();

        formData.append('csv_file', file);

        setImporting(true);

        await onImport(formData);

        notify('success', { title: t('common.action_completed') });
        onClose();
      }
    } catch (error) {
      if (isApiError400<{ error: string } | { incoming_fields: string }>(error)) {
        setImporting(false);

        const errorsData = error.response?.data.error;

        if (errorsData) {
          const errors = errorsData.map((item) => {
            const { payload } = item;

            if ('error' in payload) {
              const errorData = payload.error.split('-');

              return `${item.description} - ${errorData[0]}`;
            }

            if ('incoming_fields' in payload) {
              const errorData = JSON.parse(
                payload.incoming_fields
                  .replaceAll('True', '"True"')
                  .replaceAll('False', '"False"')
                  .replaceAll("'", '"')
              );

              return `${item.title} - ${errorData.login}`;
            }

            return `${item.title} - ${item.description}`;
          });

          setFileError(errors);
        }
      }
    }
  };

  const getFileStatusIcon = () => {
    if (fileError) {
      return icon('alertCircle', 16, { className: styles.error });
    }

    return icon('checkCircle', 16, { className: styles.success });
  };

  const getFileError = () => {
    if (fileError) {
      if (Array.isArray(fileError)) {
        return fileError.map((item) => <span key={item}>{item}</span>);
      }

      return t('common.unknown_error');
    }

    return null;
  };

  return (
    <PageAddon onClose={onClose} title={title} disabled={isImporting}>
      {t('sentences.import_from_csv_description')}
      <Button
        variant="light"
        icon={icon('download', 16)}
        className={styles.button}
        onClick={downloadSampleFile}
      >
        {t('common.sample_file')}
      </Button>
      <Dropzone
        multiple={false}
        maxSize={MB}
        disabled={isImporting || Boolean(file)}
        className={styles.dropzone}
        accept={{ [CONTENT_TYPES['text/csv']]: [EXTENSIONS.CSV] }}
        onDrop={(files) => setFile(files[0])}
      >
        {t('sentences.import_from_csv_restrictions')}
      </Dropzone>
      {file && (
        <div className={styles.file}>
          <div className={styles.fileInfo}>
            <span className={styles.fileName}>{file.name}</span>
            <div className={styles.fileError}>{getFileError()}</div>
          </div>
          <div className={styles.fileControls}>
            {getFileStatusIcon()}
            <Button
              disabled={isImporting}
              icon={icon('cross', 16)}
              variant="secondary"
              size="extra-small"
              onClick={() => {
                setFile(null);
                setFileError(null);
              }}
            />
          </div>
        </div>
      )}
      <PageAddon.Controls>
        <Button disabled={!file} loading={isImporting} onClick={handleImport}>
          {isImporting ? t('common.importing') : t('common.import')}
        </Button>
      </PageAddon.Controls>
    </PageAddon>
  );
};

export default ImportFromCSV;
