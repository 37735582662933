import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

const DialogFooter = (props: Props) => {
  const { className, children } = props;

  return <div className={clsx(styles.container, className)}>{children}</div>;
};

export default DialogFooter;
