import { OrderSchema } from '../../../api/schemas/orderSchema';
import PageDetailLink from '../../../components/PageDetailLink';
import { formatAbsoluteDate } from '../../../utils/date';

interface Props {
  data: OrderSchema;
  to: string;
}

const OrderDetailLink = (props: Props) => {
  const { data, to } = props;

  const { created_on } = data;

  return <PageDetailLink label={formatAbsoluteDate(created_on)} to={to} />;
};

export default OrderDetailLink;
