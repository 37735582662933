import { WorkspaceClubTransactionSchema } from '../../api/schemas/workspaceClubTransactionSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Dropdown from '../../components/Dropdown';
import { compact } from '../../utils';
import useDropdown from './useDropdown';

interface Props {
  data: WorkspaceClubTransactionSchema;
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceClubTransactionDropdown = (props: Props) => {
  const { title, options } = useDropdown(props);

  const compactOptions = compact([options.copy, options.divider, options.cancel]);

  return <Dropdown title={title} buttonSize="small" options={compactOptions} />;
};

export default WorkspaceClubTransactionDropdown;
