import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import channelSchema, { ChannelSchema } from '../schemas/channelSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = ChannelSchema;

interface Payload {
  username: Schema['username'];
  password: Schema['password'];
  host: Schema['host'];
  port: Schema['port'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const createWorkspaceChannel = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.post(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/channel`, payload);

  return channelSchema.pick({ id: true }).parse(response.data);
};

export default createWorkspaceChannel;
