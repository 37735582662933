import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import workspaceSchema, { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadGlobalWorkspace = async (args: Args) => {
  const { workspaceId } = args;

  const response = await api.get(`/api/global/${ALIASES.WORKSPACE.L}/${workspaceId}`);

  return workspaceSchema.parse(response.data);
};

export default loadGlobalWorkspace;
