import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceClubMemberSchema } from '../schemas/workspaceClubMemberSchema';
import workspaceClubTransactionSchema, {
  WorkspaceClubTransactionSchema,
} from '../schemas/workspaceClubTransactionSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Payload {
  amount: WorkspaceClubTransactionSchema['amount'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  memberId: WorkspaceClubMemberSchema['id'];
  payload: Payload;
}

const depositWorkspaceClubMember = async (args: Args) => {
  const { workspaceId, memberId, payload } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/club-manager/members/${memberId}/deposit`,
    payload
  );

  return workspaceClubTransactionSchema.pick({ id: true }).parse(response.data);
};

export default depositWorkspaceClubMember;
