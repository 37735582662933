import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import workspaceSchema from './workspaceSchema';

const workspaceTokenSchema = z.object({
  id: z.string(),
  name: z.string(),
  maintenance_id: workspaceSchema.shape.id,
  is_deleted: z.boolean(),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export type WorkspaceTokenSchema = z.infer<typeof workspaceTokenSchema>;

export default workspaceTokenSchema;
