import { z } from 'zod';
import workspaceAccountSchema from './account/workspaceAccountSchema';
import { lobbyTableWithMetricsSchema } from './lobbyTableSchema';
import metricsSchema from './metricsSchema';
import workspaceSchema from './workspaceSchema';

const accountSchema = workspaceAccountSchema
  .pick({
    id: true,
    login: true,
    max_tables: true,
    trainer_version: true,
  })
  .extend({
    maintenance: workspaceSchema.pick({ id: true }),
    metrics: metricsSchema,
  });

const workspaceAccountTableSchema = z.object({
  tables: z.array(lobbyTableWithMetricsSchema),
  account: accountSchema,
});

export type WorkspaceAccountTableSchema = z.infer<typeof workspaceAccountTableSchema>;

export { workspaceAccountTableSchema };
