import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { WorkspaceUserSchema } from '../../api/schemas/workspaceUserSchema';
import Dropdown from '../../components/Dropdown';
import { compact } from '../../utils';
import useDropdown from './useDropdown';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: WorkspaceUserSchema;
}

const WorkspaceUserDropdown = (props: Props) => {
  const { title, options } = useDropdown(props);

  const compactOptions = compact([
    options.resetPassword,
    options.invite,
    options.copy,
    options.divider,
    options.delete,
  ]);

  if (!compactOptions.length) return null;

  return <Dropdown title={title} buttonSize="small" options={compactOptions} />;
};

export default WorkspaceUserDropdown;
