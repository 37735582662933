import { WorkspaceClubTransactionSchema } from '../../api/schemas/workspaceClubTransactionSchema';
import PrettyNumber from '../../components/PrettyNumber';
import { convertCentsToDollars } from '../../utils/data';

interface Props {
  data: WorkspaceClubTransactionSchema;
}

const WorkspaceClubTransactionAmount = (props: Props) => {
  const { data } = props;

  const { amount } = data;

  return <PrettyNumber>{convertCentsToDollars(amount)}</PrettyNumber>;
};

export default WorkspaceClubTransactionAmount;
