import { z } from 'zod';
import botSettingSchema from '../botSettingSchema';
import globalClubSchema from '../globalClubSchema';
import workspaceStageSchema from '../stage/workspaceStageSchema';
import trainerGameTypeSchema from '../trainerGameTypeSchema';
import trainerLimitSchema from '../trainerLimitSchema';
import workspaceUserSchema from '../workspaceUserSchema';

const workspaceDashboardAccountSchema = z.object({
  id: z.string(),
  club_code: globalClubSchema.shape.code,
  club_name: globalClubSchema.shape.name,
  count: z.number(),
  stage: workspaceStageSchema.shape.name.or(z.null()),
  game_type: trainerGameTypeSchema.or(z.null()),
  limit: trainerLimitSchema.or(z.null()),
  bot_settings: botSettingSchema.shape.name.or(z.null()),
  identity: z.string().or(z.null()),
  member: workspaceUserSchema.shape.username.or(z.null()),
});

export type WorkspaceDashboardAccountSchema = z.infer<typeof workspaceDashboardAccountSchema>;

export default workspaceDashboardAccountSchema;
