import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { deleteWorkspaceUser } from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { WorkspaceUserSchema } from '../../api/schemas/workspaceUserSchema';
import { presets } from '../../components/Dropdown';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';
import { useUserStore } from '../../store/user';
import { RouteParams } from '../../utils/generics';
import notify from '../../utils/notify';
import { inviteWorkspaceUser, resetPassword } from './helpers';
import { useWorkspaceQuery } from './queries';

type Params = RouteParams<typeof routes.workspaceUser>;

interface Args {
  workspaceId: WorkspaceSchema['id'];
  data: WorkspaceUserSchema;
}

const useDropdown = (args: Args) => {
  const { workspaceId, data } = args;

  const { id, username, is_owner, verified } = data;

  const { t } = useTranslation();
  const user = useUserStore();
  const { navigate } = useURL();
  const { userId } = useParams<Params>();

  const { data: workspaceData } = useWorkspaceQuery(workspaceId);

  const ownerId = workspaceData?.owner_id;

  const enabled = Boolean(ownerId) && ownerId === user.data?.id && !is_owner;

  return {
    title: username,
    options: {
      resetPassword: {
        icon: icon('unlock', 20),
        label: t('common.reset_password'),
        confirm: true,
        confirmText: t('common.reset'),
        confirmDanger: false,
        onClick: () => resetPassword({ workspaceId, id, username }),
      },
      ...(!verified && {
        invite: {
          icon: icon('mail', 20),
          label: t('common.send_invite_link'),
          confirm: true,
          confirmText: t('common.send'),
          confirmDanger: false,
          onClick: () => inviteWorkspaceUser({ workspaceId, email: username }),
        },
      }),
      copy: presets.copyId(id),
      ...(enabled && { divider: { divider: true } }),
      ...(enabled && {
        delete: {
          ...presets.delete,
          onClick: async () => {
            await deleteWorkspaceUser({ workspaceId, userId: id });

            if (id === userId) {
              navigate(routes.workspaceUsers({ workspaceId }));
            }

            notify('success', { title: t('sentences.record_has_been_deleted') });
          },
        },
      }),
    },
  };
};

export default useDropdown;
