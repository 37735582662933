import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Dropdown from '../../../components/Dropdown';
import icon from '../../../static/icons';
import WorkspaceAccountDuplicateSessionsDialog from '../WorkspaceAccountDuplicateSessionsDialog';
import WorkspaceAccountRemoveSessionsDialog from '../WorkspaceAccountRemoveSessionsDialog';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
}

const WorkspaceAccountScheduleDropdown = (props: Props) => {
  const { workspaceId, accountId } = props;

  const { t } = useTranslation();

  const [duplicate, setDuplicate] = useState(false);
  const [remove, setRemove] = useState(false);

  return (
    <>
      {duplicate && (
        <WorkspaceAccountDuplicateSessionsDialog
          workspaceId={workspaceId}
          accountId={accountId}
          onClose={() => setDuplicate(false)}
        />
      )}
      {remove && (
        <WorkspaceAccountRemoveSessionsDialog
          workspaceId={workspaceId}
          accountId={accountId}
          onClose={() => setRemove(false)}
        />
      )}
      <Dropdown
        options={[
          {
            icon: icon('copy', 20),
            label: t('common.duplicate_schedule'),
            onClick: () => setDuplicate(true),
          },
          {
            icon: icon('broom', 20),
            label: t('sentences.remove_planned_sessions'),
            onClick: () => setRemove(true),
          },
        ]}
      />
    </>
  );
};

export default WorkspaceAccountScheduleDropdown;
