import { z } from 'zod';

const agentSchema = z.object({
  id: z.string(),
  name: z.string(),
  contact: z.string().or(z.null()),
  notes_count: z.number(),
  is_deleted: z.boolean(),
  created_on: z.string(),
  modified_on: z.string(),
});

export type AgentSchema = z.infer<typeof agentSchema>;

export default agentSchema;
