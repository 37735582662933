import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SOCIAL_NETWORK_TYPE } from '../../api/schemas/identity';
import Collapse from '../../components/Collapse';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import PasswordInput from '../../components/PasswordInput';
import Select from '../../components/Select';
import { required } from '../../utils/form';
import { SOCIAL_NETWORKS_LABELS } from './helpers';
import { Fields } from './types';
import WorkspaceIdentityFormCollapse from './WorkspaceIdentityFormCollapse';

const options = [
  {
    value: SOCIAL_NETWORK_TYPE.FACEBOOK,
    label: SOCIAL_NETWORKS_LABELS[SOCIAL_NETWORK_TYPE.FACEBOOK],
  },
  {
    value: SOCIAL_NETWORK_TYPE.TWITTER,
    label: SOCIAL_NETWORKS_LABELS[SOCIAL_NETWORK_TYPE.TWITTER],
  },
  {
    value: SOCIAL_NETWORK_TYPE.TELEGRAM,
    label: SOCIAL_NETWORKS_LABELS[SOCIAL_NETWORK_TYPE.TELEGRAM],
  },
  {
    value: SOCIAL_NETWORK_TYPE.INSTAGRAM,
    label: SOCIAL_NETWORKS_LABELS[SOCIAL_NETWORK_TYPE.INSTAGRAM],
  },
  {
    value: SOCIAL_NETWORK_TYPE.WECHAT,
    label: SOCIAL_NETWORKS_LABELS[SOCIAL_NETWORK_TYPE.WECHAT],
  },
];

const WorkspaceIdentityFormSocialNetworks = () => {
  const { t } = useTranslation();

  const {
    watch,
    control,
    register,
    formState: { errors },
  } = useFormContext<Fields>();

  const { fields, append, remove } = useFieldArray({ control, name: 'social_networks' });

  return (
    <PageAddon.DynamicFields
      label={t('common.social_networks')}
      onAppend={() => append({ type: null, link: '', login: '', password: '' })}
    >
      {fields.map((dynamicField, index) => {
        const { type } = watch(`social_networks.${index}`);

        return (
          <WorkspaceIdentityFormCollapse
            key={dynamicField.id}
            onDelete={() => remove(index)}
            defaultOpen={!dynamicField.link}
            label={
              (type && SOCIAL_NETWORKS_LABELS[type.value]) ||
              `${t('common.social_network')} ${index + 1}`
            }
            error={errors.social_networks ? Boolean(errors.social_networks[index]) : false}
          >
            <Collapse.FieldGroup>
              <Controller
                control={control}
                name={`social_networks.${index}.type`}
                rules={{ validate: required }}
                render={({ field }) => (
                  <Select
                    clearable={false}
                    label={`${t('common.type')}*`}
                    options={options}
                    value={field.value}
                    onChange={field.onChange}
                    {...(errors.social_networks && {
                      // @ts-expect-error
                      error: errors.social_networks[index]?.type?.message,
                    })}
                  />
                )}
              />
              <Input
                label={`${t('common.url')}*`}
                {...register(`social_networks.${index}.link`, {
                  validate: required,
                })}
                {...(errors.social_networks && {
                  error: errors.social_networks[index]?.link?.message,
                })}
              />
              <Input
                label={t('common.login')}
                {...register(`social_networks.${index}.login`)}
                {...(errors.social_networks && {
                  error: errors.social_networks[index]?.login?.message,
                })}
              />
              <PasswordInput
                {...register(`social_networks.${index}.password`)}
                {...(errors.social_networks && {
                  error: errors.social_networks[index]?.password?.message,
                })}
              />
            </Collapse.FieldGroup>
          </WorkspaceIdentityFormCollapse>
        );
      })}
    </PageAddon.DynamicFields>
  );
};

export default WorkspaceIdentityFormSocialNetworks;
