import { useTranslation } from 'react-i18next';
import PageAddon from '..';
import Button from '../../Button';
import PageAddonCircles from '../PageAddonCircles';
import PageAddonControls from '../PageAddonControls';
import styles from './styles.module.scss';

interface Props {
  title: string;
  description: string;
  onSuccess: () => void;
}

const PageAddonSuccess = (props: Props) => {
  const { title, description, onSuccess } = props;

  const { t } = useTranslation();

  return (
    <PageAddon title={title}>
      <div className={styles.container}>
        <PageAddonCircles type="success" />
        <span className={styles.title}>{t('common.success')}</span>
        <span className={styles.description}>{description}</span>
      </div>
      <PageAddonControls>
        <Button onClick={onSuccess}>{t('common.done')}</Button>
      </PageAddonControls>
    </PageAddon>
  );
};

export default PageAddonSuccess;
