import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  createWorkspaceClubManager,
  loadWorkspaceChannels,
  updateWorkspaceClubManager,
} from '../../api';
import { ChannelSchema } from '../../api/schemas/channelSchema';
import { WorkspaceClubManagerSchema, TYPE } from '../../api/schemas/workspaceClubManagerSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Button from '../../components/Button';
import Form from '../../components/Form';
import Input from '../../components/Input';
import PageAddon, { PageAddonContextConsumer } from '../../components/PageAddon';
import PasswordInput from '../../components/PasswordInput';
import Select, { Option, Options } from '../../components/Select';
import SubmitButton from '../../components/SubmitButton';
import TagInput from '../../components/TagInput';
import useWorkspace from '../../hooks/useWorkspace';
import icon from '../../static/icons';
import {
  convertCentsToDollars,
  convertDollarsToCents,
  convertNumberToString,
  convertStringToNumber,
} from '../../utils/data';
import { required, maxLength, minLength, bigNumber } from '../../utils/form';
import notify from '../../utils/notify';
import { getTrainerPokerRoomOptions } from '../../utils/trainer';
import WorkspaceChannelForm from '../workspace-channel/WorkspaceChannelForm';
import { CLUB_MANAGER_TYPES_LABELS } from './helpers';

const typeOptions: Options<WorkspaceClubManagerSchema['type']> = [
  {
    value: TYPE.AGENT,
    label: CLUB_MANAGER_TYPES_LABELS[TYPE.AGENT],
  },
  {
    value: TYPE.MANAGER,
    label: CLUB_MANAGER_TYPES_LABELS[TYPE.MANAGER],
  },
];

interface Fields {
  login: string;
  password: string;
  type: Option<WorkspaceClubManagerSchema['type']>;
  room: Option<WorkspaceClubManagerSchema['room']>;
  clubs: WorkspaceClubManagerSchema['clubs'];
  channel_id: Option<ChannelSchema['id']>;
  balance_threshold: string;
}

interface CommonProps {
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
}

interface CreateProps {
  onCreate: (args: { managerId: WorkspaceClubManagerSchema['id'] }) => void;
  data?: never;
  onEdit?: never;
}

interface EditProps {
  data: WorkspaceClubManagerSchema;
  onEdit: () => void;
  onCreate?: never;
}

type Props = CommonProps & (CreateProps | EditProps);

const WorkspaceClubManagerForm = (props: Props) => {
  const { workspaceId, data, onClose, onCreate, onEdit } = props;

  const { t } = useTranslation();
  const workspace = useWorkspace();

  const workspacePokerRoomOptions = getTrainerPokerRoomOptions(workspace.poker_rooms);

  const form = useForm<Fields>({
    defaultValues: {
      login: data?.login || '',
      password: '',
      type: typeOptions.find((item) => item.value === (data ? data.type : TYPE.MANAGER)),
      room: null,
      clubs: data?.clubs || [],
      channel_id: data?.channel ? { value: data.channel.id, label: data.channel.host } : null,
      balance_threshold: data
        ? convertNumberToString(data.balance_threshold, convertCentsToDollars)
        : '',
    },
  });

  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    if (!values.type?.value) throw new Error();

    const payload = {
      login: values.login,
      type: values.type.value,
      clubs: values.clubs,
      channel_id: values.channel_id?.value || null,
      balance_threshold: convertStringToNumber(values.balance_threshold, convertDollarsToCents),
    };

    if (data) {
      await updateWorkspaceClubManager({ workspaceId, managerId: data.id, payload });

      onEdit();
    } else {
      if (!values.room) throw new Error();

      const response = await createWorkspaceClubManager({
        workspaceId,
        payload: {
          ...payload,
          room: values.room.value,
          password: values.password,
        },
      });

      onCreate({ managerId: response.id });
    }

    notify('success', {
      title: data ? t('sentences.changes_have_been_saved') : t('sentences.record_has_been_created'),
    });
  };

  return (
    <PageAddon
      title={data ? `${t('common.edit')} ${data.login}` : t('common.create_manager')}
      onClose={onClose}
    >
      <Form form={form} onSubmit={onSubmit}>
        <Input
          label={`${t('common.login')}*`}
          error={errors.login?.message}
          {...register('login', {
            validate: {
              required,
              minLength: minLength(4),
              maxLength: maxLength(),
            },
          })}
        />
        {!data && (
          <PasswordInput
            label={`${t('common.password')}*`}
            error={errors.password?.message}
            {...register('password', {
              validate: { required, minLength: minLength(4) },
            })}
          />
        )}
        <Controller
          name="type"
          control={control}
          rules={{ validate: { required } }}
          render={({ field }) => (
            <Select
              clearable={false}
              options={typeOptions}
              label={t('common.type')}
              value={field.value}
              onChange={field.onChange}
              error={errors.type?.message}
            />
          )}
        />
        {!data && (
          <Controller
            name="room"
            control={control}
            rules={{ validate: { required } }}
            render={({ field }) => (
              <Select
                clearable={false}
                label={`${t('common.poker_room')}*`}
                options={workspacePokerRoomOptions}
                value={field.value}
                onChange={field.onChange}
                error={errors.room?.message}
              />
            )}
          />
        )}
        <Controller
          name="clubs"
          control={control}
          rules={{ validate: { required } }}
          render={({ field }) => (
            <TagInput
              label={`${t('common.clubs')}*`}
              values={field.value}
              onChange={field.onChange}
              error={errors.clubs?.message}
              disabled={Boolean(field.value.length)} // TODO: временно ограничили максимальное кол-во клубов до 1-го
            />
          )}
        />
        <PageAddonContextConsumer>
          {({ setExtra }) => (
            <Controller
              name="channel_id"
              control={control}
              rules={data ? {} : { validate: { required } }} // TODO: временно сделали валидацию только при создании
              render={({ field }) => (
                <Select.AsyncV2
                  onLoad={(args) =>
                    loadWorkspaceChannels({ workspaceId }, { ...args, is_free: 'true' })
                  }
                  select={(item) => ({
                    value: item.id,
                    label: item.host,
                    ...(item.country && { caption: item.country }),
                  })}
                >
                  {({ options, ...rest }) => (
                    <Select
                      label={`${t('common.channel')}*`}
                      addon={
                        <Button
                          rtl
                          variant="light"
                          size="extra-small"
                          icon={icon('add', 16)}
                          onClick={() =>
                            setExtra(
                              <WorkspaceChannelForm
                                workspaceId={workspaceId}
                                onClose={() => setExtra(null)}
                                onCreate={({ channelId, host }) => {
                                  setValue('channel_id', { value: channelId, label: host });

                                  setExtra(null);
                                }}
                              />
                            )
                          }
                        >
                          {t('common.create')}
                        </Button>
                      }
                      options={options}
                      value={field.value}
                      onChange={field.onChange}
                      error={errors.channel_id?.message}
                      {...rest}
                    />
                  )}
                </Select.AsyncV2>
              )}
            />
          )}
        </PageAddonContextConsumer>
        <Controller
          control={control}
          rules={{
            validate: {
              max: bigNumber,
            },
          }}
          name="balance_threshold"
          render={({ field }) => (
            <Input
              label={t('common.balance_threshold')}
              error={errors.balance_threshold?.message}
              format={{
                decimalScale: 2,
                onValueChange: field.onChange,
              }}
              {...field}
            />
          )}
        />
        <PageAddon.Controls>
          <SubmitButton>{data ? t('common.save') : t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default WorkspaceClubManagerForm;
