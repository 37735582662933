import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import workspaceAccountTransactionSchema, {
  WorkspaceAccountTransactionSchema,
} from '../schemas/account/workspaceAccountTransactionSchema';
import paginationSchemaNew from '../schemas/paginationSchemaNew';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountTransactionSchema['account_id'];
}

const loadWorkspaceAccountTransactions = async (args: Args, params?: URLParams) => {
  const { workspaceId, accountId } = args;

  const response = await api.get(
    `/api/v2/${ALIASES.WORKSPACE.L}/${workspaceId}/account/${accountId}/transaction`,
    { params }
  );

  return paginationSchemaNew(workspaceAccountTransactionSchema).parse(response.data);
};

export default loadWorkspaceAccountTransactions;
