import { useTranslation } from 'react-i18next';
import Filter from '../../components/Filter';
import useFilter from '../../hooks/useFilter';
import { permissionsGroups } from './helpers';

interface Fields {
  permissions_in: string[];
  created_ge: string;
  created_le: string;
  updated_ge: string;
  updated_le: string;
  show_deleted: boolean;
}

interface Props {
  total?: number;
}

const GlobalRoleFilter = (props: Props) => {
  const { total } = props;

  const { t } = useTranslation();

  const filter = useFilter<Fields>({
    permissions_in: 'multi',
    created_ge: 'single',
    created_le: 'single',
    updated_ge: 'single',
    updated_le: 'single',
    show_deleted: 'toggle',
  });

  const { values, setValue, clearValue } = filter;

  const { permissions_in, created_ge, created_le, updated_ge, updated_le, show_deleted } = values;

  const filterProps = { total, filter };

  return (
    <Filter {...filterProps}>
      <div>
        <Filter.Collapse
          label={t('common.permissions')}
          count={permissions_in.length}
          onClear={clearValue('permissions_in')}
        >
          <Filter.MultiCheckbox
            config={Object.values(permissionsGroups).flatMap((item) =>
              item.permissions.map((el) => ({
                value: el,
                label: `${item.label} — ${t(`permissions.${el}`)}`,
                onChange: setValue('permissions_in'),
                checked: permissions_in.includes(el),
              }))
            )}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.modified')}
          {...((updated_ge || updated_le) && {
            onClear: clearValue(['updated_ge', 'updated_le']),
          })}
        >
          <Filter.Date
            from={{ value: updated_ge, onChange: setValue('updated_ge') }}
            to={{ value: updated_le, onChange: setValue('updated_le') }}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.created')}
          {...((created_ge || created_le) && {
            onClear: clearValue(['created_ge', 'created_le']),
          })}
        >
          <Filter.Date
            from={{ value: created_ge, onChange: setValue('created_ge') }}
            to={{ value: created_le, onChange: setValue('created_le') }}
          />
        </Filter.Collapse>
      </div>
      <Filter.Switcher checked={show_deleted} onChange={setValue('show_deleted')}>
        {t('common.show_deleted')}
      </Filter.Switcher>
    </Filter>
  );
};

export default GlobalRoleFilter;
