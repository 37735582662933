import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import workspaceClubWhitelistSchema, {
  WorkspaceClubWhitelistSchema,
} from '../schemas/workspaceClubWhitelistSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = WorkspaceClubWhitelistSchema;

interface Payload {
  player_id: Schema['player_id'];
  room: Schema['room'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const createWorkspaceClubWhitelistMember = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/club-manager/white-list`,
    payload
  );

  return workspaceClubWhitelistSchema.pick({ id: true }).parse(response.data);
};

export default createWorkspaceClubWhitelistMember;
