import api from '../../services/api';
import globalRoleSchema, { GlobalRoleSchema } from '../schemas/globalRoleSchema';

type Schema = GlobalRoleSchema;

interface Payload {
  name: Schema['name'];
}

interface Args {
  payload: Payload;
}

const createGlobalRole = async (args: Args) => {
  const { payload } = args;

  const response = await api.post('/api/role', payload);

  return globalRoleSchema.pick({ id: true }).parse(response.data);
};

export default createGlobalRole;
