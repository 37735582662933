import { Payload } from '../../components/Notes';
import api from '../../services/api';
import { GlobalDealSchema } from '../schemas/deal/globalDealSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { NoteSchema } from '../schemas/noteSchema';

interface Args {
  dealId: GlobalDealSchema['id'];
  noteId: NoteSchema['id'];
  payload: Payload;
}

const updateGlobalDealNote = async (args: Args) => {
  const { dealId, noteId, payload } = args;

  const response = await api.patch(`/api/deal/${dealId}/notes/${noteId}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateGlobalDealNote;
