import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadWorkspaceDeal } from '../../api';
import { WorkspaceDealSchema } from '../../api/schemas/deal/workspaceDealSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  dealId: WorkspaceDealSchema['id'];
  children: (args: { data: WorkspaceDealSchema }) => ReactElement;
}

const WorkspaceDealLoader = (props: Props) => {
  const { workspaceId, dealId, children } = props;

  const queryKey = [queryKeys.workspaceDeal({ workspaceId, dealId })];
  const queryFn = () => loadWorkspaceDeal({ workspaceId, dealId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data });
};

export default WorkspaceDealLoader;
