import clsx from 'clsx';
import styles from './styles.module.scss';

const Spinner = (props: React.ComponentProps<'svg'>) => {
  const { width = 32, height = 32, className, ...rest } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      className={clsx(styles.spinner, className)}
      {...rest}
    >
      <circle cx="16" cy="16" r="15" />
    </svg>
  );
};

export default Spinner;
