import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkspaceStageSchema } from '../../../api/schemas/stage/workspaceStageSchema';
import { useDropdownContext } from '../../../components/Dropdown';
import Tooltip from '../../../components/Tooltip';
import icon from '../../../static/icons';
import styles from './styles.module.scss';

interface Props {
  data: WorkspaceStageSchema;
  active: boolean;
  index: number;
  children: ReactNode;
}

const WorkspaceAccountStageLabel = (props: Props) => {
  const { data, active, index, children } = props;

  const { is_playable, stop_current_session, clear_channel, clear_schedule } = data;

  const { confirmIndex } = useDropdownContext();

  const { t } = useTranslation();

  const confirming = confirmIndex === null ? true : confirmIndex === index + 2; // TODO: нужно переделать на что-то другое, из-за кнопки Back и разделиителя индексы криво работают

  return (
    <div className={styles.container}>
      {children}
      {!active && (
        <>
          {!is_playable && (
            <Tooltip label={confirming && t('sentences.planned_sessions_will_not_start')}>
              {icon('play', 12)}
            </Tooltip>
          )}
          {stop_current_session && (
            <Tooltip label={confirming && t('sentences.active_session_will_be_stopped')}>
              {icon('pause', 13)}
            </Tooltip>
          )}
          {clear_channel && (
            <Tooltip label={confirming && t('common.channels_will_be_removed')}>
              {icon('device', 12)}
            </Tooltip>
          )}
          {clear_schedule && (
            <Tooltip label={confirming && t('common.planned_sessions_will_be_removed')}>
              {icon('calendar', 12)}
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
};

export default WorkspaceAccountStageLabel;
