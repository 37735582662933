import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = WorkspaceAccountSchema;

interface Payload {
  login?: Schema['login'];
  affiliate_reference_number?: Schema['affiliate_reference_number'] | null;
  bot_settings_ids?: Schema['bot_settings'][number]['id'][];
  deal_ids?: Schema['deals'][number]['id'][];
  channel_ids?: string[];
  table_sizes?: Schema['table_sizes'];
  max_tables?: Schema['max_tables'];
  comparative_limits?: Schema['comparative_limits'];
  geo?: Schema['geo'] | null;
  tag_ids?: Schema['tags'][number]['id'][];
  club_ids?: Schema['clubs'][number]['id'][];
  stage_id?: Exclude<Schema['stage'], null>['id'] | null;
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: Schema['id'];
  payload: Payload;
}

const updateWorkspaceAccount = async (args: Args) => {
  const { workspaceId, accountId, payload } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/${accountId}`,
    payload
  );

  return emptyResponseSchema.parse(response.data);
};

export default updateWorkspaceAccount;
