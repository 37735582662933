import clsx from 'clsx';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { loadGlobalRoles } from '../../../api';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import Select from '../../../components/Select';
import icon from '../../../static/icons';
import { workspaceTypesOptions } from '../../global-workspace/helpers';
import styles from './styles.module.scss';
import { Fields, User } from './types';

interface Props {
  index: number;
  user: User;
  workspaceId?: string;
  onRemove: () => void;
}

const UserInviteFormField = (props: Props) => {
  const { user, index, workspaceId, onRemove } = props;

  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<Fields>();

  const toggle = () =>
    setVisible((prev) => {
      if (prev) {
        setValue(`users.${index}.maintenance_types`, []);

        return false;
      }

      return true;
    });

  return (
    <div className={styles.user}>
      <div className={styles.title}>
        <Button
          danger
          size="small"
          variant="transparent"
          icon={icon('trash', 16)}
          onClick={onRemove}
        />
        <div className={clsx(styles.email, user.isReInvite && styles.reInvite)}>
          <span>{user.email}</span>
          {user.isReInvite && <span>{t('sentences.second_invitation_will_be_sent')}</span>}
        </div>
      </div>
      {!workspaceId && !user.isReInvite && (
        <Controller
          name={`users.${index}.role_ids`}
          control={control}
          render={({ field }) => (
            <Select.Async
              onLoad={async (args) => {
                const response = await loadGlobalRoles(args);

                const options = response.items.map((item) => ({
                  value: item.id,
                  label: item.name,
                }));

                return options;
              }}
            >
              {({ options, ...rest }) => (
                <Select.Multi
                  label={t('common.roles')}
                  options={options}
                  value={field.value}
                  onChange={field.onChange}
                  {...(errors.users?.length && {
                    error: errors.users[index]?.role_ids?.message,
                  })}
                  {...rest}
                />
              )}
            </Select.Async>
          )}
        />
      )}
      {!workspaceId && (
        <Checkbox rtl variant="switch" checked={visible} onChange={toggle}>
          {t('common.create_a_workspace')}
        </Checkbox>
      )}
      {visible && (
        <Controller
          name={`users.${index}.maintenance_types`}
          control={control}
          render={({ field }) => (
            <Select.Multi
              label={t('common.type')}
              options={workspaceTypesOptions}
              value={field.value}
              onChange={field.onChange}
              {...(errors.users?.length && {
                error: errors.users[index]?.maintenance_types?.message,
              })}
            />
          )}
        />
      )}
    </div>
  );
};

export default UserInviteFormField;
