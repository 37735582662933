import { ReactNode } from 'react';
import useBreakpoints from '../../hooks/useBreakpoints';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';
import Button from '../Button';
import Indicator from '../Indicator';
import { usePageContext } from '../Page';
import styles from './styles.module.scss';

interface Props {
  children?: ReactNode;
  filter?: boolean;
  ignoredParams?: string[];
}

const PageControls = (props: Props) => {
  const { children, filter = true, ignoredParams = [] } = props;

  const { urlParams } = useURL();
  const { openFilter } = usePageContext();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();

  const filters = Object.keys(urlParams)
    .filter((item) => !(item === 'sort_by' || item === 'order_by' || item === 'search'))
    .filter((item) => !ignoredParams.includes(item));

  const filterButton = filter ? (
    <Indicator count={filters.length}>
      <Button
        size={isPhone ? 'small' : 'medium'}
        variant="secondary"
        icon={icon('filter', 16)}
        onClick={openFilter}
      />
    </Indicator>
  ) : (
    <div />
  );

  if (isDesktop || isTablet) {
    return (
      <div className={styles.container}>
        {isTablet && filterButton}
        {children}
      </div>
    );
  }

  return (
    <div className={styles.controls}>
      {isPhone ? filterButton : <div />}
      {children}
    </div>
  );
};

export default PageControls;
