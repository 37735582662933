import { BotSettingRuleSchema } from '../../api/schemas/botSettingRuleSchema';
import Dropdown from '../../components/Dropdown';
import useDropdown from './useDropdown';

interface Props {
  data: BotSettingRuleSchema;
}

const GlobalBotSettingRuleDropdown = (props: Props) => {
  const { title, disabled, options } = useDropdown(props);

  return (
    <Dropdown
      title={title}
      buttonSize="small"
      disabled={disabled}
      options={[options.edit, options.copy, options.divider, options.delete]}
    />
  );
};

export default GlobalBotSettingRuleDropdown;
