import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { channelFilterValuesSchema } from '../schemas/channelSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceChannelsFilterValues = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/channel/filter-values`,
    { params }
  );

  return channelFilterValuesSchema.parse(response.data);
};

export default loadWorkspaceChannelsFilterValues;
