import clsx from 'clsx';
import { ReactNode } from 'react';
import { useSidebarStore } from '../../../store/sidebar';
import Placeholder from '../../Placeholder';
import styles from './styles.module.scss';

interface Props {
  children?: ReactNode;
}

const SidebarPlaceholder = (props: Props) => {
  const { children } = props;

  const { open } = useSidebarStore((state) => ({ open: state.open }));

  return (
    <>
      {children}
      <div className={clsx(styles.container, open && styles.open)}>
        {Array.from({ length: 6 }).map((_, index) => (
          <div key={index}>
            <Placeholder />
          </div>
        ))}
      </div>
    </>
  );
};

export default SidebarPlaceholder;
