import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const useActions = (args: Args) => {
  const { workspaceId } = args;

  const { navigate } = useURL();

  const createIdentity = () => navigate(routes.workspaceIdentitiesCreate({ workspaceId }));

  const openMarket = () =>
    navigate(routes.workspaceMarketIdentities({ workspaceId }), { params: false });

  return { createIdentity, openMarket };
};

export default useActions;
