import api from '../../services/api';
import seatControlRuleSchema, { SeatControlRuleSchema } from '../schemas/seatControlRuleSchema';

type Schema = SeatControlRuleSchema;

interface Payload {
  name: Schema['name'];
  query: Schema['query'];
  action_type?: Schema['action_type'];
}

interface Args {
  payload: Payload;
}

const createGlobalSeatControlRule = async (args: Args) => {
  const { payload } = args;

  const response = await api.post('/api/seat-control', payload);

  return seatControlRuleSchema.pick({ id: true }).parse(response.data);
};

export default createGlobalSeatControlRule;
