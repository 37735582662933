import api from '../../services/api';
import { AreaSchema } from '../schemas/areaSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';

type Schema = AreaSchema;

interface Payload {
  status?: Schema['status'];
  area_rates?: Schema['area_rates'];
}

interface Args {
  areaId: Schema['id'];
  payload: Payload;
}

const updateGlobalArea = async (args: Args) => {
  const { areaId, payload } = args;

  const response = await api.patch(`/api/area/${areaId}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateGlobalArea;
