import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceClubManagerSchema } from '../schemas/workspaceClubManagerSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = WorkspaceClubManagerSchema;

interface Payload {
  login?: Schema['login'];
  type?: Schema['type'];
  clubs?: Schema['clubs'];
  channel_id?: Exclude<Schema['channel'], null>['id'] | null;
  balance_threshold?: Schema['balance_threshold'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  managerId: Schema['id'];
  payload: Payload;
}

const updateWorkspaceClubManager = async (args: Args) => {
  const { workspaceId, managerId, payload } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/club-manager/managers/${managerId}`,
    payload
  );

  return emptyResponseSchema.parse(response.data);
};

export default updateWorkspaceClubManager;
