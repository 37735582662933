import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';
import { WorkspaceWalletSchema } from '../schemas/workspaceWalletSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  walletId: WorkspaceWalletSchema['id'];
}

const deleteWorkspaceWallet = async (args: Args) => {
  const { workspaceId, walletId } = args;

  const response = await api.delete(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/wallet/${walletId}`
  );

  return emptyResponseSchema.parse(response.data);
};

export default deleteWorkspaceWallet;
