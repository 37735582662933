import api from '../../services/api';
import URLParams from '../../types/URLParams';
import notificationSchema from '../schemas/notificationSchema';
import paginationSchemaNew from '../schemas/paginationSchemaNew';

const loadNotifications = async (params?: URLParams) => {
  const response = await api.get('/api/notification', { params });

  return paginationSchemaNew(notificationSchema).parse(response.data);
};

export default loadNotifications;
