import { z } from 'zod';

export const TRAINER_POKER_ROOM = {
  UNKNOWN: 0,
  PARTYPOKER: 1,
  PPPOKER: 2,
  POKERRRR2: 3,
  CLUBGG: 4,
  SUPREMA: 5,
  POKERBROS: 6,
  RULER: 7,
} as const;

const trainerPokerRoomSchema = z.nativeEnum(TRAINER_POKER_ROOM);

export type TrainerPokerRoomSchema = z.infer<typeof trainerPokerRoomSchema>;

export default trainerPokerRoomSchema;
