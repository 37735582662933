import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import WorkspaceAccountDetail from '../../features/workspace-account/WorkspaceAccountDetail';
import WorkspaceAccountLoader from '../../features/workspace-account/WorkspaceAccountLoader';
import WorkspaceSessionDetail from '../../features/workspace-session/WorkspaceSessionDetail';
import WorkspaceSessionForm from '../../features/workspace-session/WorkspaceSessionForm';
import WorkspaceSessionLoader from '../../features/workspace-session/WorkspaceSessionLoader';
import WorkspaceSessionsPage from '../../pages/WorkspaceSessionsPage';
import Route from './Route';

const page = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceSessionsPage workspaceId={workspaceId} />}
  </Route>
);

const detail = (
  <Route params={['workspaceId', 'accountId', 'sessionId']}>
    {({ workspaceId, accountId, sessionId, navigate }) => (
      <WorkspaceSessionLoader workspaceId={workspaceId} accountId={accountId} sessionId={sessionId}>
        {({ data }) => (
          <WorkspaceSessionDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceSessions({ workspaceId }))}
            onEdit={() =>
              navigate(routes.workspaceSessionEdit({ workspaceId, accountId, sessionId }))
            }
          />
        )}
      </WorkspaceSessionLoader>
    )}
  </Route>
);

const detailAccount = (
  <Route params={['workspaceId', 'accountId', 'sessionId']}>
    {({ workspaceId, accountId, sessionId, navigate }) => (
      <WorkspaceAccountLoader workspaceId={workspaceId} accountId={accountId}>
        {({ data }) => (
          <WorkspaceAccountDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceSession({ workspaceId, accountId, sessionId }))}
          />
        )}
      </WorkspaceAccountLoader>
    )}
  </Route>
);

const editForm = (
  <Route params={['workspaceId', 'accountId', 'sessionId']}>
    {({ workspaceId, accountId, sessionId, navigate }) => (
      <WorkspaceSessionLoader workspaceId={workspaceId} accountId={accountId} sessionId={sessionId}>
        {({ data }) => (
          <WorkspaceSessionForm
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceSession({ workspaceId, accountId, sessionId }))}
          />
        )}
      </WorkspaceSessionLoader>
    )}
  </Route>
);

const workspaceSessionsPage: RouteObject = {
  path: routes.workspaceSessions({ workspaceId: ':workspaceId' }),
  element: page,
  children: [
    {
      path: routes.workspaceSession({
        workspaceId: ':workspaceId',
        accountId: ':accountId',
        sessionId: ':sessionId',
      }),
      element: detail,
      children: [
        {
          path: routes.workspaceSessionAccount({
            workspaceId: ':workspaceId',
            accountId: ':accountId',
            sessionId: ':sessionId',
          }),
          element: detailAccount,
        },
      ],
    },
    {
      path: routes.workspaceSessionEdit({
        workspaceId: ':workspaceId',
        accountId: ':accountId',
        sessionId: ':sessionId',
      }),
      element: editForm,
    },
  ],
};

export default workspaceSessionsPage;
