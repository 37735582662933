import EventEmmiter from 'eventemitter3';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { WebSocketMessage } from '../types/WebSocketMessage';
import { getUrlWithToken } from '../utils/auth';

const STATE = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

class WebSocketService extends EventEmmiter {
  webSocket?: ReconnectingWebSocket;

  constructor() {
    super();

    this.open = this.open.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.subscribe = this.subscribe.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
  }

  open() {
    const state = this.webSocket?.readyState;

    if (state !== STATE.CONNECTING && state !== STATE.OPEN) {
      const getUrl = () => getUrlWithToken(window.WS_URL);

      this.webSocket = new ReconnectingWebSocket(getUrl);

      this.webSocket.addEventListener('message', this.handleMessage);
    }
  }

  handleMessage(messageEvent: MessageEvent) {
    this.emit('subscribe', JSON.parse(messageEvent.data));
  }

  subscribe<T>(callback: (message: WebSocketMessage<T>) => void) {
    this.addListener('subscribe', callback);
  }

  unsubscribe<T>(callback: (message: WebSocketMessage<T>) => void) {
    this.removeListener('subscribe', callback);
  }

  close() {
    this.webSocket?.close();
  }
}
const ws = new WebSocketService();

export { ws };
