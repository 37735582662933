import api from '../../services/api';
import { GlobalAccountSchema } from '../schemas/account/globalAccountSchema';
import globalSessionSchema, { GlobalSessionSchema } from '../schemas/session/globalSessionSchema';

interface Args {
  accountId: GlobalAccountSchema['id'];
  sessionId: GlobalSessionSchema['id'];
}

const loadGlobalAccountSession = async (args: Args) => {
  const { accountId, sessionId } = args;

  const response = await api.get(`/api/account/${accountId}/session/${sessionId}`);

  return globalSessionSchema.parse(response.data);
};

export default loadGlobalAccountSession;
