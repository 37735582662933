import { Payload } from '../../components/Notes';
import api from '../../services/api';
import { GlobalClubSchema } from '../schemas/globalClubSchema';
import noteSchema from '../schemas/noteSchema';

interface Args {
  clubId: GlobalClubSchema['id'];
  payload: Payload;
}

const createGlobalClubNote = async (args: Args) => {
  const { clubId, payload } = args;

  const response = await api.post(`/api/reference/club/${clubId}/notes`, payload);

  return noteSchema.pick({ id: true }).parse(response.data);
};

export default createGlobalClubNote;
