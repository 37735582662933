import api from '../../services/api';
import { AgentSchema } from '../schemas/agentSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';

type Schema = AgentSchema;

interface Args {
  agentId: Schema['id'];
}

const deleteGlobalAgent = async (args: Args) => {
  const { agentId } = args;

  const response = await api.delete(`/api/agent/${agentId}`);

  return emptyResponseSchema.parse(response.data);
};

export default deleteGlobalAgent;
