import api from '../../services/api';
import seatControlRuleSchema, { SeatControlRuleSchema } from '../schemas/seatControlRuleSchema';

interface Args {
  seatControlRuleId: SeatControlRuleSchema['id'];
}

const loadGlobalSeatControlRule = async (args: Args) => {
  const { seatControlRuleId } = args;

  const response = await api.get(`/api/seat-control/${seatControlRuleId}`);

  return seatControlRuleSchema.parse(response.data);
};

export default loadGlobalSeatControlRule;
