import { z } from 'zod';
import channelSchema from './channelSchema';
import dateTimeSchema from './dateTimeSchema';
import globalClubSchema from './globalClubSchema';
import trainerPokerRoomSchema from './trainerPokerRoomSchema';
import workspaceSchema from './workspaceSchema';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const workspaceClubManagerPasswordSchema = z.string();

export const TYPE = {
  UNKNOWN: 0,
  AGENT: 1,
  MANAGER: 2,
} as const;

export const STATUS = {
  UNKNOWN: 0,
  STOPPED: 1,
  STARTED: 2,
  OFFLINE: 3,
  ONLINE: 4,
} as const;

const workspaceClubManagerSchema = z.object({
  id: z.string(),
  login: z.string(),
  type: z.nativeEnum(TYPE),
  status: z.nativeEnum(STATUS),
  balance: z.number(),
  balance_threshold: z.number().or(z.null()),
  clubs: z.array(globalClubSchema.shape.code),
  room: trainerPokerRoomSchema,
  maintenance: workspaceSchema.pick({
    id: true,
    name: true,
    owner_id: true,
    maintenance_types: true,
    is_deleted: true,
  }),
  channel: channelSchema.or(z.null()),
  is_deleted: z.boolean(),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export const workspaceClubManagerFilterValuesSchema = z.object({
  room: z.array(workspaceClubManagerSchema.shape.room),
  clubs: z.array(
    z.object({
      id: workspaceClubManagerSchema.shape.clubs.element,
      name: workspaceClubManagerSchema.shape.clubs.element,
    })
  ),
});

export type WorkspaceClubManagerPasswordSchema = z.infer<typeof workspaceClubManagerPasswordSchema>;
export type WorkspaceClubManagerSchema = z.infer<typeof workspaceClubManagerSchema>;

export default workspaceClubManagerSchema;
