import { useTranslation } from 'react-i18next';
import { MarketIdentitySchema } from '../../api/schemas/marketIdentitySchema';
import PageAddon from '../../components/PageAddon';
import { renderNumber } from '../../utils/data';
import { formatAbsoluteDate } from '../../utils/date';
import { renderCost } from '../workspace-identity/helpers';

interface Props {
  data: MarketIdentitySchema;
}

const WorkspaceMarketIdentityDetailTabGeneral = (props: Props) => {
  const { data } = props;

  const { t } = useTranslation();

  const { id, minimal_rental_period, monthly_cost, location, modified_on, created_on } = data;

  return (
    <PageAddon.Fields>
      <PageAddon.Id id={id} />
      <PageAddon.Field label={t('common.cost_month_USDT')}>
        {renderCost(monthly_cost)}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.min_rental_month')}>
        {renderNumber(minimal_rental_period)}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.country')}>{location.country_iso}</PageAddon.Field>
      <PageAddon.Field label={t('common.city')}>{location.city}</PageAddon.Field>
      <PageAddon.Field label={t('common.modified')}>
        {formatAbsoluteDate(modified_on)}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.created')}>
        {formatAbsoluteDate(created_on)}
      </PageAddon.Field>
    </PageAddon.Fields>
  );
};

export default WorkspaceMarketIdentityDetailTabGeneral;
