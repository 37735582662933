import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import workspaceAccountBalanceSchema from '../schemas/account/workspaceAccountBalanceSchema';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
}

const loadWorkspaceAccountBalance = async (args: Args) => {
  const { workspaceId, accountId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/${accountId}/balance`
  );

  return workspaceAccountBalanceSchema.parse(response.data);
};

export default loadWorkspaceAccountBalance;
