import { useInfiniteQuery, QueryKey } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import { PaginationSchemaNew } from '../api/schemas/paginationSchemaNew';
import Id from '../types/Id';

type Response<T> = PaginationSchemaNew<T>;

interface Config<T> {
  queryKey: QueryKey;
  queryFn: (urlParams: { next_page?: string }) => Promise<Response<T>>;
  enabled?: boolean;
}

const usePaginatedDataNew = <T extends Id>(config: Config<T>) => {
  const { queryKey, queryFn, enabled } = config;

  const { data, isPending, isFetchingNextPage, hasNextPage, error, fetchNextPage } =
    useInfiniteQuery({
      queryKey,
      queryFn: ({ pageParam }) => queryFn({ ...(pageParam && { next_page: pageParam }) }),
      enabled,
      initialPageParam: '',
      meta: { ignoredStatuses: [403] },
      getNextPageParam: ({ next_page }) => next_page || null,
    });

  const { ref } = useInView({
    onChange: (value) => {
      if (value && !isFetchingNextPage && hasNextPage) {
        fetchNextPage();
      }
    },
  });

  const flatData = data?.pages.map((item) => item.items).flat() || [];
  const ids = flatData.map((item) => item.id);

  const checkIsInView = (index: number) =>
    flatData.length === index + 1 && !isFetchingNextPage && { ref };

  return {
    ids,
    data: flatData,
    loading: isPending,
    loadingMore: isFetchingNextPage && !isPending,
    hasNextPage,
    error,
    queryKey,
    ref,
    checkIsInView,
  };
};

export default usePaginatedDataNew;
