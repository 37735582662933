import { useTranslation } from 'react-i18next';
import { PILOT_STATUS } from '../../api/schemas/account';
import Button from '../../components/Button';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import { openVNC } from './helpers';
import { CommonAccountSchema } from './types';

interface Props {
  data: CommonAccountSchema;
  isDetail?: boolean;
}

const AccountPilotView = (props: Props) => {
  const { data, isDetail } = props;

  const { pilot_status } = data;

  const { t } = useTranslation();

  const on = pilot_status === PILOT_STATUS.ON;

  const handleClick = () =>
    openVNC({ id: data.id, room: data.room, trainer_tech: data.maintenance.trainer_tech });

  const getTooltip = () => {
    if (on) return t('common.vnc');

    return t('common.pilot_stopped');
  };

  return (
    <Tooltip label={getTooltip()} hideOnMobile={on}>
      <div>
        <Button
          size={isDetail ? 'medium' : 'small'}
          variant="transparent"
          icon={icon('monitor', 16)}
          onClick={handleClick}
          disabled={!on}
        />
      </div>
    </Tooltip>
  );
};

export default AccountPilotView;
