import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import icon from '../../../static/icons';
import Button from '../../Button';
import Placeholder from '../../Placeholder';
import Tooltip from '../../Tooltip';
import styles from './styles.module.scss';

interface Props {
  label: ReactNode;
  children: ReactNode;
  tooltip?: string;
  loading?: boolean;
  onRetry?: () => void;
}

const PageAddonField = (props: Props) => {
  const { label, children, tooltip, loading, onRetry } = props;

  const { t } = useTranslation();

  const renderContent = () => {
    if (loading) return <Placeholder className={styles.placeholder} />;

    if (onRetry) {
      return (
        <div className={styles.error}>
          <span>{t('common.error')}</span>
          <Button variant="light" size="small" icon={icon('refresh', 16)} onClick={onRetry}>
            {t('common.retry')}
          </Button>
        </div>
      );
    }

    return <div className={styles.content}>{children}</div>;
  };

  return (
    <div className={styles.container}>
      <Tooltip label={tooltip}>
        <div className={styles.label}>{label}</div>
      </Tooltip>
      {renderContent()}
    </div>
  );
};

export default PageAddonField;
