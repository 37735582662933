import { STATUS } from '../../api/schemas/session';
import Badge, { Color } from '../../components/Badge';
import { STATUS_LABELS } from './helpers';
import { CommonSessionSchema } from './types';

const STATUS_COLORS: Record<CommonSessionSchema['status'], Color> = {
  [STATUS.UNKNOWN]: 'grey',
  [STATUS.PLANNED]: 'blue',
  [STATUS.SENT_START]: 'green',
  [STATUS.RUNNING]: 'green',
  [STATUS.SENT_FINISH]: 'grey',
  [STATUS.FINISHED]: 'grey',
  [STATUS.EXPIRED]: 'red',
  [STATUS.CANCELED]: 'red',
};

interface Props {
  data: CommonSessionSchema;
}

const SessionStatus = (props: Props) => {
  const { data } = props;

  const { status } = data;

  return <Badge color={STATUS_COLORS[status]}>{STATUS_LABELS[status]}</Badge>;
};

export default SessionStatus;
