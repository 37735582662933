import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { WorkspaceSessionSchema } from '../../api/schemas/session/workspaceSessionSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Button from '../../components/Button';
import Link from '../../components/Link';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import routes from '../../config/routes';
import icon from '../../static/icons';
import ClubPageAddonTags from '../club/ClubPageAddonTags';
import { getTitle } from '../session/helpers';
import SessionPageAddonFieldDuration from '../session/SessionPageAddonFieldDuration';
import SessionStatus from '../session/SessionStatus';
import SessionType from '../session/SessionType';
import WorkspaceSessionDetailDropdown from './WorkspaceSessionDetailDropdown';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: WorkspaceSessionSchema;
  onClose: () => void;
  onEdit: () => void;
}

const WorkspaceSessionDetail = (props: Props) => {
  const { workspaceId, data, onClose, onEdit } = props;

  const { t } = useTranslation();

  const { id, start, end, account, clubs, finishing_reason, is_deleted } = data;

  const { id: accountId } = account;

  const { login } = account;

  const title = getTitle({ start, end });

  const titleIcons = is_deleted && icon('trash', 12);

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon title={title} icons={titleIcons} onClose={onClose}>
        <PageAddon.Fields>
          <PageAddon.Id id={id} />
          <PageAddon.Field label={t('common.status')}>
            <SessionStatus data={data} />
          </PageAddon.Field>
          <PageAddon.Field label={t('common.finishing_reason')}>
            {finishing_reason || '—'}
          </PageAddon.Field>
          <PageAddon.Field label={t('common.type')}>
            <SessionType data={data} />
          </PageAddon.Field>
          <SessionPageAddonFieldDuration data={data} />
          <PageAddon.Field label={t('common.clubs')}>
            <ClubPageAddonTags data={clubs} />
          </PageAddon.Field>
          <PageAddon.Field label={t('common.account')}>
            <Link
              to={routes.workspaceSessionAccount({
                workspaceId,
                accountId,
                sessionId: id,
              })}
            >
              {login}
            </Link>
          </PageAddon.Field>
        </PageAddon.Fields>
        {!is_deleted && (
          <PageAddon.Controls>
            <PageAddon.Controls.Group>
              <Tooltip label={t('common.edit')} hideOnMobile>
                <div>
                  <Button
                    variant="transparent"
                    icon={icon('edit', 16)}
                    onClick={onEdit}
                    data-testid="edit"
                  />
                </div>
              </Tooltip>
              <WorkspaceSessionDetailDropdown data={data} workspaceId={workspaceId} />
            </PageAddon.Controls.Group>
          </PageAddon.Controls>
        )}
      </PageAddon>
    </PageAddon.Group>
  );
};

export default WorkspaceSessionDetail;
