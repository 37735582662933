import { useTranslation } from 'react-i18next';
import { GlobalSessionFilterValuesSchema } from '../../api/schemas/session/globalSessionSchema';
import { WorkspaceSessionFilterValuesSchema } from '../../api/schemas/session/workspaceSessionSchema';
import Filter from '../../components/Filter';
import useFilter from '../../hooks/useFilter';
import { STATUS_LABELS, TYPE_LABELS } from './helpers';

interface Fields {
  maintenance_in: string[];
  status_in: string[];
  club_in: string[];
  session_type_in: string[];
  start_ge: string;
  start_le: string;
  show_completed: boolean;
}

interface Props {
  data?: GlobalSessionFilterValuesSchema | WorkspaceSessionFilterValuesSchema;
  loading: boolean;
  total?: number;
  workspace?: boolean;
}

const SessionFilter = (props: Props) => {
  const { data, loading, total, workspace } = props;

  const { t } = useTranslation();

  const filter = useFilter<Fields>({
    maintenance_in: 'multi',
    status_in: 'multi',
    club_in: 'multi',
    session_type_in: 'multi',
    start_ge: 'single',
    start_le: 'single',
    show_completed: 'toggle',
  });

  const { values, setValue, clearValue } = filter;

  const {
    maintenance_in,
    status_in,
    club_in,
    session_type_in,
    start_ge,
    start_le,
    show_completed,
  } = values;

  const filterProps = { total, filter };

  if (loading) {
    return (
      <Filter {...filterProps}>
        <Filter.Placeholder />
      </Filter>
    );
  }

  if (!data) return null;

  const { maintenance, status, clubs, session_type } = data;

  return (
    <Filter {...filterProps}>
      <div>
        {maintenance.length !== 0 && !workspace && (
          <Filter.Collapse
            label={t('common.workspace')}
            count={maintenance_in.length}
            onClear={clearValue('maintenance_in')}
          >
            <Filter.MultiCheckbox
              config={data.maintenance.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('maintenance_in'),
                checked: maintenance_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        {status.length !== 0 && (
          <Filter.Collapse
            label={t('common.status')}
            count={status_in.length}
            onClear={clearValue('status_in')}
          >
            <Filter.MultiCheckbox
              config={status.map((item) => ({
                value: String(item),
                label: STATUS_LABELS[item],
                onChange: setValue('status_in'),
                checked: status_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {clubs.length !== 0 && (
          <Filter.Collapse
            label={t('common.club')}
            count={club_in.length}
            onClear={clearValue('club_in')}
          >
            <Filter.MultiCheckbox
              config={clubs.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('club_in'),
                checked: club_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        {session_type.length !== 0 && (
          <Filter.Collapse
            label={t('common.type')}
            count={session_type_in.length}
            onClear={clearValue('session_type_in')}
          >
            <Filter.MultiCheckbox
              config={session_type.map((item) => ({
                value: String(item),
                label: TYPE_LABELS[item],
                onChange: setValue('session_type_in'),
                checked: session_type_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        <Filter.Collapse
          label={t('common.start_date')}
          {...((start_ge || start_le) && {
            onClear: clearValue(['start_ge', 'start_le']),
          })}
        >
          <Filter.Date
            from={{ value: start_ge, onChange: setValue('start_ge') }}
            to={{ value: start_le, onChange: setValue('start_le') }}
          />
        </Filter.Collapse>
      </div>
      <Filter.Switcher checked={show_completed} onChange={setValue('show_completed')}>
        {t('common.show_completed_sessions')}
      </Filter.Switcher>
    </Filter>
  );
};

export default SessionFilter;
