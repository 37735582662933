import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TYPE } from '../../../api/schemas/session';
import Radio from '../../../components/Radio';
import { TYPE_LABELS } from '../helpers';
import { Fields } from '../types';
import styles from './styles.module.scss';

interface Props {
  disabled?: boolean;
  className?: string;
}

const SessionFormType = (props: Props) => {
  const { disabled = false, className } = props;

  const { t } = useTranslation();

  const form = useFormContext<Fields>();

  const { register } = form;

  return (
    <div className={clsx(styles.radioGroup, className)}>
      <span>{t('common.type')}</span>
      <div>
        <Radio
          {...register('session_type')}
          value={String(TYPE.GAME)}
          disabled={disabled}
          className={styles.radio}
        >
          <span>{TYPE_LABELS[TYPE.GAME]}</span>
        </Radio>
        <Radio
          {...register('session_type')}
          value={String(TYPE.MINING)}
          disabled={disabled}
          className={styles.radio}
        >
          <span>{TYPE_LABELS[TYPE.MINING]}</span>
        </Radio>
      </div>
    </div>
  );
};

export default SessionFormType;
