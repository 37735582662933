import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadNotificationsSettings } from '../../../api';
import { NotificationsSettingSchema } from '../../../api/schemas/notificationsSettingSchema';
import queryKeys from '../../../config/queryKeys';
import usePaginatedDataNew from '../../../hooks/usePaginatedDataNew';
import useWebSocketMessage from '../../../hooks/useWebSocketMessage';
import icon from '../../../static/icons';
import { updateInfinitePaginatedQueryData } from '../../../utils/queryClient';
import Button from '../../Button';
import Dialog from '../../Dialog';
import Tooltip from '../../Tooltip';
import NotificationsSettingsDialogForm from './NotificationsSettingsDialogForm';
import styles from './styles.module.scss';
import { ConfirmClose } from './types';

const NotificationsSettingsDialog = () => {
  const { t } = useTranslation();

  const queryKey = [queryKeys.notificationsSettings];

  const { data, loading } = usePaginatedDataNew({
    queryKey,
    queryFn: (params) => loadNotificationsSettings(params),
  });

  useWebSocketMessage<NotificationsSettingSchema>({
    domain: 'NotificationSettings',
    onMessage: (message) => {
      const { payload } = message;

      if (message.action === 'Edited') {
        updateInfinitePaginatedQueryData(
          queryKey,
          message.payload,
          (item) => item.id === payload.id
        );
      }
    },
  });

  const [confirmClose, setConfirmClose] = useState<ConfirmClose>(false);

  if (loading) return <Button size="small" variant="transparent" icon={icon('settings', 16)} />;

  if (!data || !data.length) {
    return <Button disabled size="small" variant="transparent" icon={icon('settings', 16)} />;
  }

  return (
    <Dialog
      confirmClose={confirmClose}
      label={({ referenceProps }) => (
        <Tooltip label={t('common.settings')} hideOnMobile={Boolean(data.length)}>
          <Button
            disabled={!data.length}
            size="small"
            variant="transparent"
            icon={icon('settings', 16)}
            {...referenceProps}
          />
        </Tooltip>
      )}
      className={styles.dialog}
    >
      {() => (
        <>
          <Dialog.Top>
            <Dialog.Title subTitle={t('sentences.notifications_settings_subtitle')}>
              {t('common.notifications_settings')}
            </Dialog.Title>
          </Dialog.Top>
          <NotificationsSettingsDialogForm
            defaultWorkspace={data[0].workspace}
            setConfirmClose={setConfirmClose}
          />
        </>
      )}
    </Dialog>
  );
};

export default NotificationsSettingsDialog;
