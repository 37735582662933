import { z } from 'zod';
import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import workspaceSchema from '../schemas/workspaceSchema';

const loadWorkspaces = async () => {
  const response = await api.get(`/api/${ALIASES.WORKSPACE.L}`);

  return z.array(workspaceSchema).parse(response.data);
};

export default loadWorkspaces;
