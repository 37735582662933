import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import { getBalanceParams } from '../account/AccountBalance/helpers';
import { CommonAccountTransactionBalanceSchema, OnTransactionsOpen } from '../account/types';

interface Props {
  balance: CommonAccountTransactionBalanceSchema;
  onTransactionsOpen: OnTransactionsOpen;
}

const GlobalAccountDetailBalanceControls = (props: Props) => {
  const { balance, onTransactionsOpen } = props;

  const { t } = useTranslation();

  return (
    <Tooltip label={t('common.transactions')}>
      <Button
        size="small"
        variant="transparent"
        icon={icon('clockBack', 16)}
        onClick={() => onTransactionsOpen(getBalanceParams(balance))}
      />
    </Tooltip>
  );
};

export default GlobalAccountDetailBalanceControls;
