import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import icon from '../../static/icons';
import Button from '../Button';
import styles from './styles.module.scss';

interface Props {
  element: HTMLElement | null;
  className?: string;
}

const ScrollTop = (props: Props) => {
  const { element, className } = props;

  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (element) {
        setScrollTop(element.scrollTop);

        if (
          element.scrollTop > element.clientHeight / 3 &&
          element.scrollTop < scrollTop &&
          // строчка ниже фиксит появление кнопки в сафари в конце скролла из-за эффекта "отскока"
          element.scrollTop + 10 < element.scrollHeight - element.clientHeight
        ) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      }
    };

    element?.addEventListener('scroll', handleScroll);

    return () => element?.removeEventListener('scroll', handleScroll);
  }, [element, scrollTop]);

  return (
    <div className={clsx(styles.container, className)}>
      <Button
        size="small"
        variant="secondary"
        icon={icon('arrowLongTop', 16)}
        className={clsx(styles.button, visible && styles.visible)}
        onClick={() => element?.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        {t('common.back_to_top')}
      </Button>
    </div>
  );
};

export default ScrollTop;
