import { WorkspaceClubMemberSchema } from '../../api/schemas/workspaceClubMemberSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Dropdown from '../../components/Dropdown';
import useDropdown from './useDropdown';

interface Props {
  data: WorkspaceClubMemberSchema;
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceClubMemberDetailDropdown = (props: Props) => {
  const { title, disabled, options } = useDropdown(props);

  return (
    <Dropdown title={title} buttonSize="medium" disabled={disabled} options={[options.kick]} />
  );
};

export default WorkspaceClubMemberDetailDropdown;
