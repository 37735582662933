import { z } from 'zod';
import workspaceAccountSchema from '../account/workspaceAccountSchema';
import workspaceSchema from '../workspaceSchema';
import { sessionCommonFilterValuesSchema, sessionCommonSchema } from '.';

const sessionAccountSchema = z.object({
  id: workspaceAccountSchema.shape.id,
  login: workspaceAccountSchema.shape.login,
});

const workspaceSessionSchema = sessionCommonSchema.extend({
  account: sessionAccountSchema,
});

export const workspaceSessionFilterValuesSchema = sessionCommonFilterValuesSchema.extend({
  maintenance: z.array(workspaceSchema.pick({ id: true, name: true })),
});

export type WorkspaceSessionSchema = z.infer<typeof workspaceSessionSchema>;
export type WorkspaceSessionFilterValuesSchema = z.infer<typeof workspaceSessionFilterValuesSchema>;

export default workspaceSessionSchema;
