import { useTranslation } from 'react-i18next';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { WorkspaceUserSchema } from '../../api/schemas/workspaceUserSchema';
import Button from '../../components/Button';
import Confirm from '../../components/Confirm';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import { formatAbsoluteDate } from '../../utils/date';
import { inviteWorkspaceUser, resetPassword } from './helpers';
import WorkspaceUserDetailDropdown from './WorkspaceUserDetailDropdown';

interface Props {
  data: WorkspaceUserSchema;
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
}

const WorkspaceUserDetail = (props: Props) => {
  const { data, workspaceId, onClose } = props;

  const { t } = useTranslation();

  const { id, username, is_owner, is_deleted, verified, invited_on } = data;

  const titleIcons = (
    <>
      {is_deleted && icon('trash', 12)}
      {is_owner && <Tooltip label={t('common.owner')}>{icon('crown', 12)}</Tooltip>}
      {!verified && <Tooltip label={t('common.invite_not_accepted')}>{icon('clock', 12)}</Tooltip>}
    </>
  );

  return (
    <PageAddon title={username} icons={titleIcons} onClose={onClose}>
      <PageAddon.Fields>
        <PageAddon.Id id={id} />
        <PageAddon.Field label={t('common.invited')}>
          {formatAbsoluteDate(invited_on)}
        </PageAddon.Field>
      </PageAddon.Fields>
      {!is_deleted && (
        <PageAddon.Controls>
          <PageAddon.Controls.Group>
            {!verified && (
              <Tooltip label={t('common.send_invite_link')} hideOnMobile>
                <Confirm
                  danger={false}
                  confirmText={t('common.send')}
                  onConfirm={() => inviteWorkspaceUser({ workspaceId, email: username })}
                >
                  {({ confirming }) => (
                    <Button
                      focused={confirming}
                      icon={icon('mail', 16)}
                      variant="transparent"
                      data-testid="send-invite-link"
                    />
                  )}
                </Confirm>
              </Tooltip>
            )}
            <Tooltip label={t('common.reset_password')} hideOnMobile>
              <Confirm
                danger={false}
                confirmText={t('common.reset')}
                onConfirm={() => resetPassword({ workspaceId, id, username })}
              >
                {() => (
                  <Button
                    icon={icon('unlock', 16)}
                    variant="transparent"
                    data-testid="reset-password"
                  />
                )}
              </Confirm>
            </Tooltip>
            <WorkspaceUserDetailDropdown workspaceId={workspaceId} data={data} />
          </PageAddon.Controls.Group>
        </PageAddon.Controls>
      )}
    </PageAddon>
  );
};

export default WorkspaceUserDetail;
