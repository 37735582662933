import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { GlobalExpenseSchema } from '../schemas/globalExpenseSchema';

type Schema = GlobalExpenseSchema;

interface Payload {
  type?: Schema['type'];
  amount?: Schema['amount'];
  currency?: Schema['currency'];
  reconcile?: Schema['reconcile'];
  description?: Schema['description'];
}

interface Args {
  expenseId: Schema['id'];
  payload: Payload;
}

const updateGlobalExpense = async (args: Args) => {
  const { expenseId, payload } = args;

  const response = await api.patch(`/api/expenses/${expenseId}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateGlobalExpense;
