import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import InvitePage from '../../pages/InvitePage';

const invitePage: RouteObject = {
  path: routes.invite,
  element: <InvitePage />,
};

export default invitePage;
