import clsx from 'clsx';
import { forwardRef } from 'react';
import icon from '../../static/icons';
import CheckboxQuick from './CheckboxQuick';
import styles from './styles.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'input'> {
  rtl?: boolean;
  variant?: 'default' | 'switch';
  indeterminate?: boolean;
  className?: string;
}

const Checkbox = Object.assign(
  forwardRef<HTMLInputElement, Props>((props, ref) => {
    const {
      rtl,
      variant = 'default',
      indeterminate = false,
      className: customClassName,
      children,
      checked,
      onChange,
      ...rest
    } = props;

    const className = clsx(styles.container, {
      [styles.default]: variant === 'default',
      [styles.switch]: variant === 'switch',
      [styles.rtl]: rtl,
      ...(customClassName && { [customClassName]: true }),
    });

    const getIcon = () => {
      if (checked && indeterminate) return <div className={styles.dot} />;

      if (variant === 'default') return icon('check', 16);

      return null;
    };

    return (
      <div>
        <label className={className}>
          <input ref={ref} type="checkbox" checked={checked} onChange={onChange} {...rest} />
          <div>
            <div>{getIcon()}</div>
          </div>
          {children && <span>{children}</span>}
        </label>
      </div>
    );
  }),
  {
    Quick: CheckboxQuick,
  }
);

export default Checkbox;
