import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IdentitySchema } from '../../../api/schemas/identitySchema';
import Button from '../../../components/Button';
import Dialog from '../../../components/Dialog';
import PageAddon from '../../../components/PageAddon';
import Stub from '../../../components/Stub';
import Tooltip from '../../../components/Tooltip';
import icon from '../../../static/icons';
import styles from './styles.module.scss';

interface Props {
  data: IdentitySchema;
}

const WorkspaceIdentityDetailTabDescription = (props: Props) => {
  const { data } = props;

  const { first_name, last_name, description } = data;

  const { t } = useTranslation();

  const [maximized, setMaximized] = useState(false);

  const open = () => setMaximized(true);
  const close = () => setMaximized(false);

  // eslint-disable-next-line react/no-danger
  const content = <div dangerouslySetInnerHTML={{ __html: description }} className={styles.html} />;

  if (!description) return <Stub title={t('common.no_description')} />;

  return (
    <>
      {maximized && (
        <Dialog secondary className={styles.dialog} onClose={close}>
          {() => (
            <>
              <Dialog.Top>
                <Dialog.Title>
                  {`${t('common.description')} ${last_name} ${first_name}`}
                </Dialog.Title>
              </Dialog.Top>
              <div className={styles.dialogContent}>{content}</div>
            </>
          )}
        </Dialog>
      )}
      <PageAddon.Fields>
        <div className={styles.controls}>
          <Tooltip label={t('common.full_screen')} hideOnMobile placement="left">
            <Button
              size="extra-small"
              variant="transparent"
              icon={icon('maximize', 16)}
              onClick={open}
            />
          </Tooltip>
        </div>
        {content}
      </PageAddon.Fields>
    </>
  );
};

export default WorkspaceIdentityDetailTabDescription;
