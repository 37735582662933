import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useBreakpoints from '../../hooks/useBreakpoints';
import staticIcon from '../../static/icons';
import Button from '../Button';
import Tooltip from '../Tooltip';
import { useDropdownContext } from './DropdownContext';
import styles from './styles.module.scss';
import { Option } from './types';

interface Props extends Option {
  index?: number;
}

const DropdownOption = (props: Props) => {
  const {
    icon,
    index,
    label,
    caption,
    divider,
    active,
    danger,
    tooltip,
    extra,
    confirm,
    confirmText,
    close = true,
    confirmDanger = true,
    disabled = false,
    render,
    onClick,
  } = props;

  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  const { confirmIndex, setConfirmIndex, closeDropdown } = useDropdownContext();

  const confirming = confirmIndex === index;

  const optionClassName = clsx(styles.option, {
    [styles.danger]: danger,
    [styles.confirming]: confirming,
    [styles.confirmDanger]: confirmDanger,
  });

  const handleClick = () => {
    if (onClick) {
      onClick();

      if (close) {
        closeDropdown();
      }
    }
  };

  if (divider) return <div className={styles.divider} />;

  if (render) return <>{render()}</>;

  const content = (
    <>
      <div className={styles.content}>
        {icon && <div className={styles.icon}>{icon}</div>}
        <div className={clsx(styles.label, caption && styles.withCaption)}>
          <div>{label}</div>
          {caption && <span className={styles.caption}>{caption}</span>}
        </div>
        {extra && <div className={styles.extra}>{extra}</div>}
      </div>
      {active && <div className={styles.active}>{staticIcon('check', 16)}</div>}
    </>
  );

  const getDisabled = () => {
    if (confirmIndex !== null) {
      if (confirmIndex === index) return false;

      return true;
    }

    return disabled;
  };

  const buttonProps = {
    disabled: getDisabled(),
    onClick: confirm && typeof index === 'number' ? () => setConfirmIndex(index) : handleClick,
  };

  let option = (
    <li className={optionClassName}>
      <button type="button" {...buttonProps}>
        {content}
      </button>
      {confirm && confirming && (
        <div className={styles.confirm}>
          <div className={clsx(styles.confirmArrow, confirmDanger && styles.danger)} />
          <div className={clsx(styles.confirmContent, confirmDanger && styles.danger)}>
            <span>{t('sentences.are_you_sure')}</span>
            <div>
              <Button variant="transparent" onClick={() => setConfirmIndex(null)}>
                {t('common.cancel')}
              </Button>
              <Button
                danger={confirmDanger}
                onClick={() => {
                  setConfirmIndex(null);
                  handleClick();
                }}
              >
                {confirmText || t('common.confirm')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </li>
  );

  if (tooltip) {
    option = (
      <Tooltip
        label={tooltip}
        placement={isMobile ? 'top-start' : 'left'}
        {...(isMobile && { arrowOffset: 20 })}
      >
        {option}
      </Tooltip>
    );
  }

  return option;
};

export default DropdownOption;
