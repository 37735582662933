import { QueryKey } from '@tanstack/react-query';
import { GlobalAccountTransactionSchema } from '../../api/schemas/account/globalAccountTransactionSchema';
import { WorkspaceAccountTransactionSchema } from '../../api/schemas/account/workspaceAccountTransactionSchema';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import URLParams from '../../types/URLParams';
import { WebSocketMessage } from '../../types/WebSocketMessage';
import {
  createInfinitePaginatedQueryData,
  updateInfinitePaginatedQueryData,
} from '../../utils/queryClient';

type Schema = WorkspaceAccountTransactionSchema | GlobalAccountTransactionSchema;

const useAccountTransactionMessage = (args: {
  domain: Extract<
    WebSocketMessage<Schema>['domain'],
    'AccountTransaction' | 'GlobalAccountTransaction'
  >;
  accountId: Schema['id'];
  queryKey: QueryKey;
  urlParams: URLParams;
}) => {
  const { domain, accountId, queryKey, urlParams } = args;

  useWebSocketMessage<Schema>({
    domain,
    onMessage: (message) => {
      const { action, payload } = message;

      const { account_id, trx_object, new_balance } = payload;

      if (account_id === accountId) {
        if (action === 'Created') {
          if (
            String(trx_object) === urlParams.trx_object_eq &&
            (new_balance.table_id === urlParams.table_id ||
              new_balance.club_id === urlParams.club_id ||
              new_balance.league_id === urlParams.league_id ||
              (!new_balance.table_id && !new_balance.club_id && !new_balance.league_id))
          ) {
            createInfinitePaginatedQueryData(queryKey, payload);
          }
        }

        if (action === 'Edited' || action === 'Canceled') {
          updateInfinitePaginatedQueryData(
            queryKey,
            message.payload,
            (item) => item.id === payload.id
          );
        }
      }
    },
  });
};

export default useAccountTransactionMessage;
