import api from '../../services/api';
import globalSuperLeagueSchema, {
  GlobalSuperLeagueSchema,
} from '../schemas/globalSuperLeagueSchema';

interface Args {
  superLeagueId: GlobalSuperLeagueSchema['id'];
}

const loadGlobalSuperLeague = async (args: Args) => {
  const { superLeagueId } = args;

  const response = await api.get(`/api/reference/super-league/${superLeagueId}`);

  return globalSuperLeagueSchema.parse(response.data);
};

export default loadGlobalSuperLeague;
