import { z } from 'zod';
import api from '../../services/api';
import { GlobalAccountSchema } from '../schemas/account/globalAccountSchema';
import accountTablesSearchSchema from '../schemas/accountTablesSearchSchema';

interface Args {
  accountId: GlobalAccountSchema['id'];
}

const loadGlobalAccountTablesSearch = async (args: Args) => {
  const { accountId } = args;

  const response = await api.get(`/api/lobby/account/${accountId}/search-tables`);

  return z.array(accountTablesSearchSchema).parse(response.data);
};

export default loadGlobalAccountTablesSearch;
