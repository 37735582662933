import { Payload } from '../../components/Notes';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { GlobalClubSchema } from '../schemas/globalClubSchema';
import { NoteSchema } from '../schemas/noteSchema';

interface Args {
  clubId: GlobalClubSchema['id'];
  noteId: NoteSchema['id'];
  payload: Payload;
}

const updateGlobalClubNote = async (args: Args) => {
  const { clubId, noteId, payload } = args;

  const response = await api.patch(`/api/reference/club/${clubId}/notes/${noteId}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateGlobalClubNote;
