import { z } from 'zod';
import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { marketIdentityFilterValuesSchema } from '../schemas/marketIdentitySchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceMarketIdentitiesFilterValues = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/identity-market/filter-values`,
    { params }
  );

  /* TODO: с бека неправильно приходят енумы для online_wallets, documents и photos */
  /* return marketIdentityFilterValuesSchema.parse(response.data); */
  return response.data as z.infer<typeof marketIdentityFilterValuesSchema>;
};

export default loadWorkspaceMarketIdentitiesFilterValues;
