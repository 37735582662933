import { ChannelSchema } from '../../api/schemas/channelSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageDetailLink from '../../components/PageDetailLink';
import routes from '../../config/routes';

interface Props {
  data: ChannelSchema;
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceChannelDetailLink = (props: Props) => {
  const { data, workspaceId } = props;

  const { id, host } = data;

  return (
    <PageDetailLink label={host} to={routes.workspaceChannel({ workspaceId, channelId: id })} />
  );
};

export default WorkspaceChannelDetailLink;
