import { useTranslation } from 'react-i18next';
import Filter from '../../components/Filter';
import useFilter from '../../hooks/useFilter';

interface Fields {
  created_ge: string;
  created_le: string;
  show_deleted: boolean;
}

const WorkspaceClubWhitelistFilter = () => {
  const { t } = useTranslation();

  const filter = useFilter<Fields>({
    created_ge: 'single',
    created_le: 'single',
    show_deleted: 'toggle',
  });

  const { values, setValue, clearValue } = filter;

  const { created_ge, created_le, show_deleted } = values;

  const filterProps = { filter };

  return (
    <Filter {...filterProps}>
      <div>
        <Filter.Collapse
          label={t('common.created')}
          {...((created_ge || created_le) && {
            onClear: clearValue(['created_ge', 'created_le']),
          })}
        >
          <Filter.Date
            from={{ value: created_ge, onChange: setValue('created_ge') }}
            to={{ value: created_le, onChange: setValue('created_le') }}
          />
        </Filter.Collapse>
      </div>
      <Filter.Switcher checked={show_deleted} onChange={setValue('show_deleted')}>
        {t('common.show_deleted')}
      </Filter.Switcher>
    </Filter>
  );
};

export default WorkspaceClubWhitelistFilter;
