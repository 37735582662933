import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
}

const closeWorkspaceAccountTablesPokerRoom = async (args: Args) => {
  const { workspaceId, accountId } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/lobby/account/${accountId}/close-room`
  );

  return emptyResponseSchema.parse(response.data);
};

export default closeWorkspaceAccountTablesPokerRoom;
