import { useTranslation } from 'react-i18next';
import icon from '../../../../static/icons';
import Alert from '../../../Alert';
import Button from '../../../Button';

interface Props {
  onClick: () => void;
}

const PageAddonCollapseError = (props: Props) => {
  const { onClick } = props;

  const { t } = useTranslation();

  return (
    <>
      <Alert
        type="error"
        title={t('sentences.something_went_wrong')}
        description={t('sentences.click_retry_to_try_again')}
      />
      <Button size="small" variant="secondary" icon={icon('refresh', 16)} onClick={onClick}>
        {t('common.retry')}
      </Button>
    </>
  );
};

export default PageAddonCollapseError;
