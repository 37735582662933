import { z } from 'zod';
import workspaceSchema from './workspaceSchema';

const workspaceAddressSchema = z.object({
  id: workspaceSchema.shape.id,
  tron_address: z.string(),
});

export type WorkspaceAddressSchema = z.infer<typeof workspaceAddressSchema>;

export default workspaceAddressSchema;
