import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { updateWorkspaceAccount } from '../../../api';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Dialog from '../../../components/Dialog';
import Form from '../../../components/Form';
import Input from '../../../components/Input';
import SubmitButton from '../../../components/SubmitButton';
import { maxLength, minLength, required } from '../../../utils/form';
import notify from '../../../utils/notify';
import styles from './styles.module.scss';

interface Fields {
  login: string;
}

interface Props {
  data: WorkspaceAccountSchema;
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
}

const WorkspaceAccountChangeLoginDialog = (props: Props) => {
  const { data, workspaceId, onClose } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({ defaultValues: { login: data.login } });

  const {
    register,
    formState: { errors },
  } = form;

  return (
    <Dialog onClose={onClose}>
      {({ close }) => {
        const handleSubmit = async (values: Fields) => {
          await updateWorkspaceAccount({
            workspaceId,
            accountId: data.id,
            payload: { login: values.login },
          });

          notify('success', { title: t('sentences.login_has_been_changed') });
          close({ confirmClose: false });
        };

        return (
          <>
            <Dialog.Top>
              <Dialog.Title>{t('common.change_login')}</Dialog.Title>
            </Dialog.Top>
            <Form form={form} onSubmit={handleSubmit} className={styles.form}>
              <Input
                label={`${t('common.login')}*`}
                error={errors.login?.message}
                {...register('login', {
                  validate: { required, minLength: minLength(), maxLength: maxLength() },
                })}
              />
              <Dialog.Footer>
                <SubmitButton>{t('common.change')}</SubmitButton>
              </Dialog.Footer>
            </Form>
          </>
        );
      }}
    </Dialog>
  );
};

export default WorkspaceAccountChangeLoginDialog;
