import { useTranslation } from 'react-i18next';
import { cancelWorkspaceClubTransaction } from '../../api';
import { WorkspaceClubTransactionSchema } from '../../api/schemas/workspaceClubTransactionSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { presets } from '../../components/Dropdown';
import icon from '../../static/icons';
import { formatAbsoluteDate } from '../../utils/date';
import notify from '../../utils/notify';
import { canCancel } from './helpers';

interface Args {
  data: WorkspaceClubTransactionSchema;
  workspaceId: WorkspaceSchema['id'];
}

const useDropdown = (args: Args) => {
  const { data, workspaceId } = args;

  const { id, created_on } = data;

  const { t } = useTranslation();

  const title = formatAbsoluteDate(created_on);

  const cancel = canCancel(data);

  return {
    title,
    options: {
      copy: presets.copyId(id),
      ...(cancel && { divider: { divider: true } }),
      ...(cancel && {
        cancel: {
          icon: icon('crossCircle', 20),
          label: t('common.cancel'),
          danger: true,
          onClick: async () => {
            await cancelWorkspaceClubTransaction({ workspaceId, transactionId: id });

            notify('success', { title: t('sentences.transaction_canceled_successfully') });
          },
        },
      }),
    },
  };
};

export default useDropdown;
