import clsx from 'clsx';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AccountStrategyProfileSchema } from '../../../api/schemas/accountStrategyProfileSchema';
import PageAddon from '../../../components/PageAddon';
import Tooltip from '../../../components/Tooltip';
import useQuickForm from '../../../hooks/useQuickForm';
import { handleError } from '../../../utils/form';
import { getFields, Fields, getPayload, getDefaultValues } from '../strategyHelpers';
import styles from './styles.module.scss';

const Label = (props: { tooltip: string; children: string }) => {
  const { tooltip, children } = props;

  return (
    <Tooltip label={tooltip}>
      <div>{children}</div>
    </Tooltip>
  );
};

interface Props {
  data: AccountStrategyProfileSchema;
  disabled?: boolean;
  onUpdate?: (payload: ReturnType<typeof getPayload>) => void;
}

const AccountStrategyProfile = (props: Props) => {
  const { data, disabled, onUpdate } = props;

  const { t } = useTranslation();

  const form = useQuickForm<Fields>({ data, defaultValues: getDefaultValues({ data }) });

  const onSubmit = async (values: Fields) => {
    try {
      if (onUpdate) await onUpdate(getPayload({ values }));
    } catch (error) {
      handleError({ error, form });
    }
  };

  const fields = getFields({
    form,
    onSubmit,
    selectProps: { disabled },
    inputProps: { disabled },
    checkboxProps: { disabled },
  });

  return (
    <FormProvider {...form}>
      <div className={styles.container}>
        <PageAddon.Field
          label={
            <Label tooltip={t('sentences.strategy_player_type')}>{t('common.player_type')}</Label>
          }
        >
          {fields.player_type}
        </PageAddon.Field>
        <PageAddon.Field
          label={
            <Label tooltip={t('sentences.strategy_seating_preference')}>{t('common.seat')}</Label>
          }
        >
          {fields.seating_preference}
        </PageAddon.Field>
        <PageAddon.Field
          label={
            <Label tooltip={t('sentences.strategy_table_min_hands')}>{t('common.min_hands')}</Label>
          }
        >
          {fields.table_min_hands}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.table_time')}>{fields.table_time}</PageAddon.Field>
        <div className={styles.checkboxes}>
          <PageAddon.Field label={t('common.auto_rebuy')}>{fields.auto_rebuy}</PageAddon.Field>
          <PageAddon.Field
            label={
              <Label tooltip={t('sentences.strategy_wait_for_big_blind')}>
                {t('common.wait_for_bb')}
              </Label>
            }
          >
            {fields.wait_for_big_blind}
          </PageAddon.Field>
        </div>
        <PageAddon.Collapse
          label={t('sentences.buy_in_regular')}
          className={clsx(styles.collapse, styles.first)}
        >
          <PageAddon.Collapse.Field
            label={
              <Label tooltip={t('sentences.strategy_buy_in_range_bb')}>
                {t('common.buy_in_min_bb')}
              </Label>
            }
          >
            {fields.buyin_bb_min}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field
            label={
              <Label tooltip={t('sentences.strategy_buy_in_range_bb')}>
                {t('common.buy_in_max_bb')}
              </Label>
            }
          >
            {fields.buyin_bb_max}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field
            label={
              <Label tooltip={t('sentences.strategy_buy_in_step_bb')}>{t('common.step_bb')}</Label>
            }
          >
            {fields.buyin_step_bb}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field
            label={
              <Label tooltip={t('sentences.strategy_rebuy_threshold_bb')}>
                {t('common.rebuy_threshold_bb')}
              </Label>
            }
          >
            {fields.rebuy_below_bb}
          </PageAddon.Collapse.Field>
        </PageAddon.Collapse>
        <PageAddon.Collapse label={t('sentences.buy_in_straddle')} className={styles.collapse}>
          <PageAddon.Collapse.Field
            label={
              <Label tooltip={t('sentences.strategy_straddle_tables_buy_in_range_bb')}>
                {t('common.buy_in_min_bb')}
              </Label>
            }
          >
            {fields.buyin_bb_straddle_table_min}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field
            label={
              <Label tooltip={t('sentences.strategy_straddle_tables_buy_in_range_bb')}>
                {t('common.buy_in_max_bb')}
              </Label>
            }
          >
            {fields.buyin_bb_straddle_table_max}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field
            label={
              <Label tooltip={t('sentences.strategy_straddle_tables_buy_in_step_bb')}>
                {t('common.step_bb')}
              </Label>
            }
          >
            {fields.buyin_step_bb_straddle_table}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field
            label={
              <Label tooltip={t('sentences.strategy_straddle_tables_rebuy_threshold_bb')}>
                {t('common.rebuy_threshold_bb')}
              </Label>
            }
          >
            {fields.rebuy_below_bb_straddle_table}
          </PageAddon.Collapse.Field>
        </PageAddon.Collapse>
        <PageAddon.Collapse label={t('common.stack_stops')} className={styles.collapse}>
          <PageAddon.Collapse.Field
            label={
              <Label tooltip={t('sentences.strategy_effective_stack_limit_bb')}>
                {t('common.eff_stack_bb')}
              </Label>
            }
          >
            {fields.effective_stack_limit_bb}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field
            label={
              <Label tooltip={t('sentences.strategy_max_stack_size_bb')}>
                {t('common.max_stack_bb')}
              </Label>
            }
          >
            {fields.max_stack_size_bb}
          </PageAddon.Collapse.Field>
        </PageAddon.Collapse>
        <PageAddon.Collapse label={t('common.MTT')} className={styles.collapse}>
          <PageAddon.Collapse.Field label={t('common.max_rebuy')}>
            {fields.mtt_max_rebuy}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.addon')}>
            {fields.mtt_addon}
          </PageAddon.Collapse.Field>
        </PageAddon.Collapse>
        <PageAddon.Collapse label={t('sentences.win_loss_stops')} className={styles.collapse}>
          <PageAddon.Collapse.Field
            label={
              <Label tooltip={t('sentences.strategy_stop_lose_bb')}>
                {t('common.stop_loss_bb')}
              </Label>
            }
          >
            {fields.stop_lose_bb}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field
            label={
              <Label tooltip={t('sentences.strategy_stop_win_bb')}>{t('common.stop_win_bb')}</Label>
            }
          >
            {fields.stop_win_bb}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field
            label={
              <Label tooltip={t('sentences.strategy_stop_lose_table_bb')}>
                {t('common.stop_loss_by_table_bb')}
              </Label>
            }
          >
            {fields.stop_lose_table_bb}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field
            label={
              <Label tooltip={t('sentences.strategy_stop_win_table_bb')}>
                {t('common.stop_win_by_table_bb')}
              </Label>
            }
          >
            {fields.stop_win_table_bb}
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field
            label={
              <Label tooltip={t('sentences.strategy_max_swing_bb')}>
                {t('common.max_swing_bb')}
              </Label>
            }
          >
            {fields.max_swing_bb}
          </PageAddon.Collapse.Field>
        </PageAddon.Collapse>
      </div>
    </FormProvider>
  );
};

export default AccountStrategyProfile;
