import clsx from 'clsx';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import routes from '../../../config/routes';
import useBreakpoints from '../../../hooks/useBreakpoints';
import staticIcon from '../../../static/icons';
import { useSidebarStore } from '../../../store/sidebar';
import Link from '../../Link';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  icon?: ReactNode;
  to?: string;
  count?: number;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}

const SidebarItem = (props: Props) => {
  const { children, icon, to, count, disabled, className: customClassName, onClick } = props;

  const { pathname } = useLocation();
  const { isMobile } = useBreakpoints();
  const { open, hide } = useSidebarStore((state) => ({ open: state.open, hide: state.hide }));

  const getIsActive = () => {
    if (!to) return false;

    if (to === routes.home && pathname === routes.home) return true;

    if (to !== routes.home) return pathname.startsWith(to);

    return false;
  };

  const className = clsx(styles.container, {
    [styles.open]: open,
    [styles.active]: getIsActive(),
    [styles.disabled]: disabled,
    [styles.interactive]: to || onClick,
    ...(customClassName && { [customClassName]: true }),
  });

  const content = (
    <>
      {icon || <div className={styles.dot} />}
      <span>{children}</span>
      {!disabled && (count || count === 0) && <div className={styles.count}>{count}</div>}
      {disabled && <div className={styles.lock}>{staticIcon('lock', 12)}</div>}
    </>
  );

  const renderContent = () => {
    if (to) {
      return (
        <Link to={to} {...(isMobile && { onClick: hide })} params={false} className={className}>
          {content}
        </Link>
      );
    }

    if (onClick) {
      const handleClick = async () => {
        await onClick();

        if (isMobile) hide();
      };

      return (
        <button type="button" onClick={handleClick} className={clsx(className, styles.button)}>
          {content}
        </button>
      );
    }

    return <div className={className}>{content}</div>;
  };

  return <li>{renderContent()}</li>;
};

export default SidebarItem;
