import Placeholder from '../../../Placeholder';
import styles from './styles.module.scss';

interface Count {
  select?: number;
  checkbox?: number;
}

interface Props {
  count: Count;
}

const PageAddonFilterPlaceholder = (props: Props) => {
  const { count } = props;

  return (
    <>
      {count.select &&
        Array.from({ length: count.select }).map((_, index) => (
          <div key={index} className={styles.selectPlaceholder}>
            <Placeholder />
            <Placeholder />
          </div>
        ))}
      {count.checkbox &&
        Array.from({ length: count.checkbox }).map((_, index) => (
          <Placeholder key={index} className={styles.checkboxPlaceholder} />
        ))}
    </>
  );
};

export default PageAddonFilterPlaceholder;
