import { z } from 'zod';
import workspaceSchema from './workspaceSchema';

const workspaceBalanceSchema = z.object({
  id: workspaceSchema.shape.id,
  balance: z.number(),
  balance_hold: z.number(),
});

export type WorkspaceBalanceSchema = z.infer<typeof workspaceBalanceSchema>;

export default workspaceBalanceSchema;
