import {
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  flip,
  shift,
  arrow,
  offset,
  autoUpdate,
  FloatingPortal,
  FloatingArrow,
} from '@floating-ui/react';
import clsx from 'clsx';
import { useState, forwardRef, useRef, cloneElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeRefs } from 'react-merge-refs';
import usePrevious from '../../hooks/usePrevious';
import Button from '../Button';
import styles from './styles.module.scss';
import { Props } from './types';

const Confirm = forwardRef<HTMLElement, Props>((props, ref) => {
  const {
    title,
    children,
    confirmText,
    danger = true,
    dismiss = true,
    placement: customPlacement = 'top',
    onConfirm,
    onCancel,
    onConfirming,
  } = props;

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const arrowRef = useRef(null);

  const { refs, context, floatingStyles } = useFloating({
    open,
    strategy: 'fixed',
    placement: customPlacement,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(8), flip(), shift({ padding: 4 }), arrow({ element: arrowRef })],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context, { enabled: dismiss }),
  ]);

  const prevOpen = usePrevious(open);

  useEffect(() => {
    if (open !== prevOpen && !open && onCancel) {
      onCancel();
    }
  }, [open, prevOpen, onCancel]);

  useEffect(() => {
    if (open !== prevOpen && open && onConfirming) {
      onConfirming();
    }
  }, [open, prevOpen, onConfirming]);

  const referenceProps = { ref: mergeRefs([refs.setReference, ref]), ...getReferenceProps() };

  const handleCancel = () => {
    setOpen(false);

    if (onCancel) {
      onCancel();
    }
  };

  return (
    <>
      {cloneElement(children({ confirming: open }), referenceProps)}
      {open && (
        <FloatingPortal id="root">
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className={styles.container}
            {...getFloatingProps()}
          >
            <FloatingArrow
              ref={arrowRef}
              context={context}
              tipRadius={1}
              className={clsx(styles.arrow, danger && styles.danger)}
            />
            <div className={clsx(styles.content, danger && styles.danger)}>
              <span>{title || t('sentences.are_you_sure')}</span>
              <div>
                <Button variant="transparent" onClick={handleCancel}>
                  {t('common.cancel')}
                </Button>
                <Button
                  danger={danger}
                  onClick={(event) => {
                    onConfirm(event);
                    setOpen(false);
                  }}
                >
                  {confirmText || t('common.confirm')}
                </Button>
              </div>
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  );
});

export default Confirm;
