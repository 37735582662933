import { GlobalLeagueSchema } from '../../api/schemas/globalLeagueSchema';
import PageDetailLink from '../../components/PageDetailLink';
import routes from '../../config/routes';

interface Props {
  data: GlobalLeagueSchema;
}

const GlobalLeagueDetailLink = (props: Props) => {
  const { data } = props;

  const { id, code } = data;

  return <PageDetailLink label={code} to={routes.globalLeague({ leagueId: id })} />;
};

export default GlobalLeagueDetailLink;
