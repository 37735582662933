import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { SeatControlRuleSchema } from '../schemas/seatControlRuleSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = SeatControlRuleSchema;

interface Args {
  workspaceId: WorkspaceSchema['id'];
  seatControlRuleId: Schema['id'];
}

const deactivateWorkspaceSeatControlRule = async (args: Args) => {
  const { workspaceId, seatControlRuleId } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/seat-control/${seatControlRuleId}/deactivate`
  );

  return emptyResponseSchema.parse(response.data);
};

export default deactivateWorkspaceSeatControlRule;
