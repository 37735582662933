import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react';
import clsx from 'clsx';
import { ReactNode, useRef } from 'react';
import useDimensions from 'react-cool-dimensions';
import icon from '../../../static/icons';
import Button from '../../Button';
import Error from '../../Error';
import Label, { getLabelProps } from '../../Label';
import { useSelectContext } from '../SelectContext';
import { OnClear } from '../types';
import styles from './styles.module.scss';

const INPUT_PADDING = 28;
const QUICK_INPUT_PADDING = 6;

interface Props {
  children: ReactNode;
  onClear: OnClear;
  addon?: ReactNode;
}

const SelectField = (props: Props) => {
  const { children, onClear, addon } = props;

  const { height, observe } = useDimensions<HTMLSpanElement>();

  const {
    open,
    size,
    multi,
    label,
    placeholder,
    error,
    quick,
    extra,
    selected,
    disabled,
    clearable,
    renderValueAsTag,
    errorVariant = 'default',
    setSearch,
    getReferenceProps,
    floating: { refs },
  } = useSelectContext();

  const hasValue = selected.length > 0;

  const renderExtra = () => {
    if (quick) {
      if (!open && extra) return <div className={clsx(styles.extra, styles.hidden)}>{extra}</div>;

      if (!open || !clearable) return null;
    }

    if (quick && (!open || !clearable)) return null;

    if (clearable && hasValue && !disabled) {
      return (
        <div className={styles.extra}>
          <Button
            data-select-clear
            size="extra-small"
            variant="transparent"
            icon={icon('cross', 16)}
            className={styles.clear}
            onClick={() => {
              onClear();
              setSearch('');
            }}
          />
        </div>
      );
    }

    return (
      <div className={styles.extra}>
        {icon('chevronDown', 20, { className: clsx(styles.arrow, open && styles.open) })}
      </div>
    );
  };

  const className = clsx(styles.button, {
    [styles.open]: open,
    [styles.error]: error,
    [styles.quick]: quick,
    ...(errorVariant === 'popover' && { [styles.errorInputPopover]: true }),
    ...(!quick && { [styles[size]]: true }),
  });

  const inputShouldGrow = height && multi && renderValueAsTag;

  const padding = quick ? QUICK_INPUT_PADDING : INPUT_PADDING;

  const arrowRef = useRef(null);

  const errorFloating = useFloating({
    open: true,
    placement: 'top',
    whileElementsMounted: autoUpdate,
    middleware: [offset(8), flip(), shift({ padding: 4 }), arrow({ element: arrowRef })],
  });

  return (
    <div ref={errorFloating.refs.setReference} className={styles.container}>
      {(label || addon) && !quick && <Label addon={addon} {...getLabelProps(label)} />}
      <div className={styles.field}>
        <button
          type="button"
          ref={refs.setReference}
          disabled={disabled}
          className={className}
          {...(inputShouldGrow && { style: { height: height + padding } })}
          {...getReferenceProps()}
        />
        <span
          ref={observe}
          className={clsx(styles.value, quick && styles.quick, clearable && styles.clearable)}
        >
          {multi ? children : <span>{children}</span>}
        </span>
        {placeholder && !children && (
          <div className={clsx(styles.placeholder, quick && styles.quick)}>{placeholder}</div>
        )}
        {renderExtra()}
      </div>
      {errorVariant === 'default' && <Error error={error} />}
      {errorVariant === 'popover' && error && (
        <div
          ref={errorFloating.refs.setFloating}
          style={errorFloating.floatingStyles}
          className={styles.errorPopover}
        >
          <FloatingArrow
            ref={arrowRef}
            context={errorFloating.context}
            tipRadius={1}
            className={styles.errorArrow}
          />
          {error}
        </div>
      )}
    </div>
  );
};

export default SelectField;
