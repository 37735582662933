import { useQuery } from '@tanstack/react-query';
import { loadWorkspace } from '../../api';
import queryKeys from '../../config/queryKeys';

export const useWorkspaceQuery = (workspaceId: string) => {
  const queryKey = [queryKeys.workspace({ workspaceId })];
  const queryFn = () => loadWorkspace({ workspaceId });

  return useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });
};
