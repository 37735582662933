import { z } from 'zod';
import globalAccountSchema from './account/globalAccountSchema';
import botSettingRuleSchema from './botSettingRuleSchema';
import botSettingSchema from './botSettingSchema';

const botSettingDeleteErrorSchema = z.object({
  bot_settings_id: botSettingSchema.shape.id,
  accounts: z.array(
    z.object({
      id: globalAccountSchema.shape.id,
      mga_id: globalAccountSchema.shape.mga_id,
    })
  ),
  usage_rules: z.array(
    z.object({
      id: botSettingRuleSchema.shape.id,
      name: botSettingRuleSchema.shape.name,
    })
  ),
});

export type BotSettingDeleteErrorSchema = z.infer<typeof botSettingDeleteErrorSchema>;

export default botSettingDeleteErrorSchema;
