import { useState } from 'react';
import Tabs from '../../../components/Tabs';
import styles from './styles.module.scss';

const tabs = ['Tab 1', 'Tab 2', 'Tab 3', 'Tab 4', 'Tab 5'];

const StorybookPageTabs = () => {
  const [tab, setTab] = useState('Tab 1');

  return (
    <>
      <h2>Tabs</h2>
      <div className={styles.tabs}>
        <Tabs
          tabs={tabs.map((item) => ({
            label: item,
            active: tab === item,
            onClick: () => setTab(item),
          }))}
        />
      </div>
    </>
  );
};

export default StorybookPageTabs;
