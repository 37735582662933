import { useTranslation } from 'react-i18next';
import { ChannelSchema } from '../../api/schemas/channelSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Form from '../../components/Form';
import PageAddon from '../../components/PageAddon';
import SubmitButton from '../../components/SubmitButton';
import useForm from './useForm';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
  onCreate: (args: { channelId: ChannelSchema['id']; host: ChannelSchema['host'] }) => void;
}

const WorkspaceChannelForm = (props: Props) => {
  const { workspaceId, onClose, onCreate } = props;

  const { t } = useTranslation();

  const { form, fields, onSubmit } = useForm({ workspaceId, onCreate });

  return (
    <PageAddon title={t('common.create_channel')} onClose={onClose}>
      <Form form={form} onSubmit={onSubmit}>
        {fields.host}
        {fields.port}
        {fields.username}
        {fields.password}
        <PageAddon.Controls>
          <SubmitButton>{t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default WorkspaceChannelForm;
