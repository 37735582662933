import clsx from 'clsx';
import { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow';
import Logo from '../../static/logo.svg';
import Id from '../../types/Id';
import Empty from '../Empty';
import ScrollTop from '../ScrollTop';
import Spinner from '../Spinner';
import { createTableStore } from './store';
import styles from './styles.module.scss';
import { TableProvider } from './TableContext';
import TableHead from './TableHead';
import TableRow from './TableRow';
import TableRowsPlaceholder from './TableRowsPlaceholder';
import TableSelectedRowsActions from './TableSelectedRowsActions';
import { TableStoreProvider } from './TableStoreContext';
import { Props as TableProps } from './types';

type Props<T> = Pick<
  TableProps<T>,
  | 'data'
  | 'columns'
  | 'search'
  | 'footer'
  | 'loading'
  | 'loadingMore'
  | 'allLoaded'
  | 'className'
  | 'renderRow'
  | 'selectionActions'
  | 'onColumnResize'
  | 'columnSizes'
>;

const Table = <T extends Id>(props: Props<T>) => {
  const {
    data: rows,
    columns,
    search,
    footer,
    loading,
    loadingMore,
    allLoaded,
    columnSizes,
    selectionActions,
    className: customClassName,
    renderRow,
    onColumnResize,
  } = props;

  const { t } = useTranslation();

  const store = useRef(createTableStore({ columnSizes, onColumnResize }));

  const { element, setElement, onScroll } = store.current(
    (state) => ({
      element: state.element,
      setElement: state.setElement,
      onScroll: state.onScroll,
    }),
    shallow
  );

  const getRow = (row: T, index: number) => (
    <Fragment key={row.id}>
      {renderRow ? (
        renderRow({ data: row, columns }, index)
      ) : (
        <TableRow data={row} columns={columns} />
      )}
    </Fragment>
  );

  const empty = !loading && !rows.length;

  const className = clsx(styles.table, {
    [styles.empty]: empty,
    [styles.withFooter]: footer,
    [styles.loading]: loading,
    ...(customClassName && { [customClassName]: true }),
  });

  const getContent = () => {
    if (columns.length === 0) return null;

    return (
      <>
        <TableHead columns={columns} />
        {loading ? <TableRowsPlaceholder columns={columns} /> : rows.map(getRow)}
      </>
    );
  };

  return (
    <TableStoreProvider store={store.current}>
      <div
        className={className}
        onScroll={onScroll}
        ref={(node) => {
          if (node) {
            setElement(node);
            onScroll();
          }
        }}
      >
        {getContent()}
        {allLoaded && rows.length > 10 && (
          <div className={styles.allLoaded}>
            <Logo />
          </div>
        )}
      </div>
      {loadingMore && (
        <div className={styles.loadingMore}>
          <div>
            <Spinner />
            <span>{t('common.loading')}</span>
          </div>
        </div>
      )}
      {empty && <Empty title={search && t('common.no_matching_results')} />}
      {!loadingMore && (
        <ScrollTop element={element} {...(footer && { className: styles.scrollTop })} />
      )}
      {footer && <div className={styles.footer}>{footer}</div>}
      {selectionActions && <TableSelectedRowsActions>{selectionActions}</TableSelectedRowsActions>}
    </TableStoreProvider>
  );
};

Table.Row = TableRow;
Table.Provider = TableProvider;

export default Table;
