import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { LobbyTableSchema } from '../schemas/lobbyTableSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
  tableId: LobbyTableSchema['id'];
}

const closeWorkspaceAccountTable = async (args: Args) => {
  const { workspaceId, accountId, tableId } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/lobby/account/${accountId}/leave-table/${tableId}`
  );

  return emptyResponseSchema.parse(response.data);
};

export default closeWorkspaceAccountTable;
