import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Dropdown from '../../components/Dropdown';
import { compact } from '../../utils';
import useDropdown from './useDropdown';

interface Props {
  data: WorkspaceSchema;
}

const GlobalWorkspaceDetailDropdown = (props: Props) => {
  const { title, disabled, options } = useDropdown(props);

  const compactOptions = compact([options.delete]);

  if (!compactOptions.length) return null;

  return (
    <Dropdown title={title} buttonSize="medium" disabled={disabled} options={compactOptions} />
  );
};

export default GlobalWorkspaceDetailDropdown;
