import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalAgentDetail from '../../features/global-agent/GlobalAgentDetail';
import GlobalAgentForm from '../../features/global-agent/GlobalAgentForm';
import GlobalAgentLoader from '../../features/global-agent/GlobalAgentLoader';
import GlobalAgentNotes from '../../features/global-agent/GlobalAgentNotes';
import GlobalClubDetail from '../../features/global-club/GlobalClubDetail';
import GlobalClubForm from '../../features/global-club/GlobalClubForm';
import GlobalClubLoader from '../../features/global-club/GlobalClubLoader';
import GlobalClubNotes from '../../features/global-club/GlobalClubNotes';
import GlobalDealDetail from '../../features/global-deal/GlobalDealDetail';
import GlobalDealForm from '../../features/global-deal/GlobalDealForm';
import GlobalDealLoader from '../../features/global-deal/GlobalDealLoader';
import GlobalDealNotes from '../../features/global-deal/GlobalDealNotes';
import GlobalDealsPage from '../../pages/GlobalDealsPage';
import Notes from '../Notes';
import Route from './Route';

const page = <GlobalDealsPage />;

const createForm = (
  <Route>
    {({ navigate }) => (
      <GlobalDealForm
        onClose={() => navigate(routes.globalDeals)}
        onCreate={({ dealId }) => navigate(routes.globalDeal({ dealId }))}
      />
    )}
  </Route>
);

const detail = (
  <Route params={['dealId']}>
    {({ dealId, navigate }) => (
      <GlobalDealLoader dealId={dealId}>
        {({ data }) => (
          <GlobalDealDetail
            data={data}
            onClose={() => navigate(routes.globalDeals)}
            onEdit={() => navigate(routes.globalDealEdit({ dealId }))}
            getAgentRoute={({ agentId }) => routes.globalDealAgent({ dealId, agentId })}
            getClubRoute={({ clubId }) => routes.globalDealClub({ dealId, clubId })}
          />
        )}
      </GlobalDealLoader>
    )}
  </Route>
);

const detailNotes = (
  <Route params={['dealId']}>
    {({ dealId, navigate }) => (
      <GlobalDealNotes dealId={dealId}>
        {(loader) => <Notes onClose={() => navigate(routes.globalDeal({ dealId }))} {...loader} />}
      </GlobalDealNotes>
    )}
  </Route>
);

const detailAgent = (
  <Route params={['dealId', 'agentId']}>
    {({ dealId, agentId, navigate }) => (
      <GlobalAgentLoader agentId={agentId}>
        {({ data }) => (
          <GlobalAgentDetail
            data={data}
            onClose={() => navigate(routes.globalDeal({ dealId }))}
            onEdit={() => navigate(routes.globalDealAgentEdit({ dealId, agentId }))}
            getNotesRoute={() => navigate(routes.globalDealAgentNotes({ dealId, agentId }))}
          />
        )}
      </GlobalAgentLoader>
    )}
  </Route>
);

const detailAgentNotes = (
  <Route params={['dealId', 'agentId']}>
    {({ dealId, agentId, navigate }) => (
      <GlobalAgentNotes agentId={agentId}>
        {(loader) => (
          <Notes
            onClose={() => navigate(routes.globalDealAgent({ dealId, agentId }))}
            {...loader}
          />
        )}
      </GlobalAgentNotes>
    )}
  </Route>
);

const detailAgentEditForm = (
  <Route params={['dealId', 'agentId']}>
    {({ dealId, agentId, navigate }) => (
      <GlobalAgentLoader agentId={agentId}>
        {({ data }) => (
          <GlobalAgentForm
            data={data}
            onClose={() => navigate(routes.globalDealAgent({ dealId, agentId }))}
            onEdit={() => navigate(routes.globalDealAgent({ dealId, agentId }))}
          />
        )}
      </GlobalAgentLoader>
    )}
  </Route>
);

const detailClub = (
  <Route params={['dealId', 'clubId']}>
    {({ dealId, clubId, navigate }) => (
      <GlobalClubLoader clubId={clubId}>
        {({ data }) => (
          <GlobalClubDetail
            data={data}
            onClose={() => navigate(routes.globalDeal({ dealId }))}
            onEdit={() => navigate(routes.globalDealClubEdit({ dealId, clubId }))}
            getNotesRoute={() => navigate(routes.globalDealClubNotes({ dealId, clubId }))}
          />
        )}
      </GlobalClubLoader>
    )}
  </Route>
);

const detailClubNotes = (
  <Route params={['dealId', 'clubId']}>
    {({ dealId, clubId, navigate }) => (
      <GlobalClubNotes clubId={clubId}>
        {(loader) => (
          <Notes onClose={() => navigate(routes.globalDealClub({ dealId, clubId }))} {...loader} />
        )}
      </GlobalClubNotes>
    )}
  </Route>
);

const detailClubEditForm = (
  <Route params={['dealId', 'clubId']}>
    {({ dealId, clubId, navigate }) => (
      <GlobalClubLoader clubId={clubId}>
        {({ data, workspacesData }) => (
          <GlobalClubForm
            data={data}
            workspacesData={workspacesData}
            onClose={() => navigate(routes.globalDealClub({ dealId, clubId }))}
            onEdit={() => navigate(routes.globalDealClub({ dealId, clubId }))}
          />
        )}
      </GlobalClubLoader>
    )}
  </Route>
);

const editForm = (
  <Route params={['dealId']}>
    {({ dealId, navigate }) => (
      <GlobalDealLoader dealId={dealId}>
        {({ data }) => (
          <GlobalDealForm
            data={data}
            onClose={() => navigate(routes.globalDeal({ dealId }))}
            onEdit={() => navigate(routes.globalDeal({ dealId }))}
          />
        )}
      </GlobalDealLoader>
    )}
  </Route>
);

const globalDealsPage: RouteObject = {
  path: routes.globalDeals,
  element: page,
  children: [
    {
      path: routes.globalDealsCreate,
      element: createForm,
    },
    {
      path: routes.globalDeal({ dealId: ':dealId' }),
      element: detail,
      children: [
        {
          path: routes.globalDealNotes({ dealId: ':dealId' }),
          element: detailNotes,
        },
        {
          path: routes.globalDealAgent({ dealId: ':dealId', agentId: ':agentId' }),
          element: detailAgent,
          children: [
            {
              path: routes.globalDealAgentNotes({ dealId: ':dealId', agentId: ':agentId' }),
              element: detailAgentNotes,
            },
          ],
        },
        {
          path: routes.globalDealAgentEdit({ dealId: ':dealId', agentId: ':agentId' }),
          element: detailAgentEditForm,
        },
        {
          path: routes.globalDealClub({ dealId: ':dealId', clubId: ':clubId' }),
          element: detailClub,
          children: [
            {
              path: routes.globalDealClubNotes({ dealId: ':dealId', clubId: ':clubId' }),
              element: detailClubNotes,
            },
          ],
        },
        {
          path: routes.globalDealClubEdit({ dealId: ':dealId', clubId: ':clubId' }),
          element: detailClubEditForm,
        },
      ],
    },
    {
      path: routes.globalDealEdit({ dealId: ':dealId' }),
      element: editForm,
    },
  ],
};

export default globalDealsPage;
