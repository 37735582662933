import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { ChannelSchema } from '../schemas/channelSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = ChannelSchema;

interface Payload {
  username?: Schema['username'];
  password?: Schema['password'];
  host?: Schema['host'];
  port?: Schema['port'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  channelId: Schema['id'];
  payload: Payload;
}

const updateWorkspaceChannel = async (args: Args) => {
  const { workspaceId, channelId, payload } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/channel/${channelId}`,
    payload
  );

  return emptyResponseSchema.parse(response.data);
};

export default updateWorkspaceChannel;
