import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalRoleDetail from '../../features/global-role/GlobalRoleDetail';
import GlobalRoleLoader from '../../features/global-role/GlobalRoleLoader';
import GlobalUserDetail from '../../features/global-user/GlobalUserDetail';
import GlobalUserLoader from '../../features/global-user/GlobalUserLoader';
import GlobalWorkspaceDetail from '../../features/global-workspace/GlobalWorkspaceDetail';
import GlobalWorkspaceLoader from '../../features/global-workspace/GlobalWorkspaceLoader';
import UserInviteForm from '../../features/user/UserInviteForm';
import GlobalUsersPage from '../../pages/GlobalUsersPage';
import Route from './Route';

const page = <GlobalUsersPage />;

const inviteForm = (
  <Route>{({ navigate }) => <UserInviteForm onClose={() => navigate(routes.globalUsers)} />}</Route>
);

const detail = (
  <Route params={['userId']}>
    {({ userId, navigate }) => (
      <GlobalUserLoader userId={userId}>
        {({ data }) => (
          <GlobalUserDetail
            data={data}
            onClose={() => navigate(routes.globalUsers)}
            getRoleRoute={({ roleId }) => routes.globalUserRole({ userId, roleId })}
            getWorkspaceRoute={({ workspaceId }) =>
              routes.globalUserWorkspace({ userId, workspaceId })
            }
          />
        )}
      </GlobalUserLoader>
    )}
  </Route>
);

const detailRole = (
  <Route params={['userId', 'roleId']}>
    {({ userId, roleId, navigate }) => (
      <GlobalRoleLoader roleId={roleId}>
        {({ data }) => (
          <GlobalRoleDetail data={data} onClose={() => navigate(routes.globalUser({ userId }))} />
        )}
      </GlobalRoleLoader>
    )}
  </Route>
);

const detailWorkspace = (
  <Route params={['userId', 'workspaceId']}>
    {({ userId, workspaceId, navigate }) => (
      <GlobalWorkspaceLoader workspaceId={workspaceId}>
        {({ data }) => (
          <GlobalWorkspaceDetail
            data={data}
            onClose={() => navigate(routes.globalUser({ userId }))}
          />
        )}
      </GlobalWorkspaceLoader>
    )}
  </Route>
);

const globalUsersPage: RouteObject = {
  path: routes.globalUsers,
  element: page,
  children: [
    {
      path: routes.globalUsersInvite,
      element: inviteForm,
    },
    {
      path: routes.globalUser({ userId: ':userId' }),
      element: detail,
      children: [
        {
          path: routes.globalUserRole({ userId: ':userId', roleId: ':roleId' }),
          element: detailRole,
        },
        {
          path: routes.globalUserWorkspace({ userId: ':userId', workspaceId: ':workspaceId' }),
          element: detailWorkspace,
        },
      ],
    },
  ],
};

export default globalUsersPage;
