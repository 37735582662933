import { useTranslation } from 'react-i18next';
import Dialog from '../../../../components/Dialog';
import Spinner from '../../../../components/Spinner';
import styles from './styles.module.scss';

const AccountScheduleDialogSpinner = () => {
  const { t } = useTranslation();

  return (
    <Dialog secondary className={styles.dialog}>
      {() => (
        <div className={styles.spinner}>
          <Spinner width={16} height={16} />
          <span>{t('common.loading')}</span>
        </div>
      )}
    </Dialog>
  );
};

export default AccountScheduleDialogSpinner;
