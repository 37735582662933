import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import paginationSchemaNew from '../schemas/paginationSchemaNew';
import workspaceClubTransactionSchema from '../schemas/workspaceClubTransactionSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceClubTransactions = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(
    `/api/v2/${ALIASES.WORKSPACE.L}/${workspaceId}/club-manager/transactions`,
    {
      params,
    }
  );

  return paginationSchemaNew(workspaceClubTransactionSchema).parse(response.data);
};

export default loadWorkspaceClubTransactions;
