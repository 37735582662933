import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import permissionSchema from './permissionSchema';

const globalRoleSchema = z.object({
  id: z.string(),
  name: z.string(),
  permissions: z.array(permissionSchema),
  is_deleted: z.boolean(),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export type GlobalRoleSchema = z.infer<typeof globalRoleSchema>;

export default globalRoleSchema;
