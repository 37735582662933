import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import globalClubSchema from './globalClubSchema';
import jsonSchema from './jsonSchema';
import trainerPokerRoomSchema from './trainerPokerRoomSchema';
import workspaceClubManagerSchema from './workspaceClubManagerSchema';
import workspaceSchema from './workspaceSchema';

const workspaceClubMemberSchema = z.object({
  id: z.string(),
  balance: z.number(),
  hands: z.number(),
  nickname: z.string(),
  player_id: z.string(),
  total_fee: z.number(),
  winnings: z.number(),
  club: globalClubSchema.shape.code,
  accept: z.boolean(),
  kick: z.boolean(),
  additional_attributes: jsonSchema,
  room: trainerPokerRoomSchema,
  maintenance: workspaceSchema.pick({
    id: true,
    name: true,
    owner_id: true,
    maintenance_types: true,
    is_deleted: true,
  }),
  manager: workspaceClubManagerSchema,
  is_deleted: z.boolean(),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export const workspaceClubMembersFilterValuesSchema = z.object({
  room: z.array(workspaceClubMemberSchema.shape.room),
  club: z.array(
    z.object({
      id: workspaceClubMemberSchema.shape.club,
      name: workspaceClubMemberSchema.shape.club,
    })
  ),
  manager: z.array(
    z.object({
      id: workspaceClubMemberSchema.shape.manager.shape.id,
      name: workspaceClubMemberSchema.shape.manager.shape.login,
    })
  ),
  balance: z.object({ min: z.number(), max: z.number() }),
  total_fee: z.object({ min: z.number(), max: z.number() }),
  winnings: z.object({ min: z.number(), max: z.number() }),
  hands: z.object({ min: z.number(), max: z.number() }),
});

export type WorkspaceClubMemberSchema = z.infer<typeof workspaceClubMemberSchema>;

export default workspaceClubMemberSchema;
