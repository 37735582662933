import { useQuery } from '@tanstack/react-query';
import { WorkspaceAccountBalanceSchema } from '../../api/schemas/account/workspaceAccountBalanceSchema';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import loadWorkspaceAccountBalance from '../../api/workspace-account/loadWorkspaceAccountBalance';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { updateDetailQueryData } from '../../utils/queryClient';
import AccountBalance from '../account/AccountBalance';
import { OnTransactionsOpen } from '../account/types';
import WorkspaceAccountDetailBalanceControls from './WorkspaceAccountDetailBalanceControls';

interface Props {
  accountData: WorkspaceAccountSchema;
  workspaceId: WorkspaceSchema['id'];
  onTransactionsOpen?: OnTransactionsOpen;
}

const WorkspaceAccountDetailBalanceTab = (props: Props) => {
  const { accountData, workspaceId, onTransactionsOpen } = props;

  const { id: accountId } = accountData;

  const queryKey = [queryKeys.workspaceAccountBalance({ workspaceId, accountId })];
  const queryFn = () => loadWorkspaceAccountBalance({ workspaceId, accountId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<WorkspaceAccountBalanceSchema>({
    domain: 'AccountBalance',
    enabled: (message) => message.meta_info?.maintenance_id === workspaceId,
    onMessage: (message) => {
      if (message.action === 'Changed' && message.payload.id === accountId) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  if (isPending) return <PageAddon.DetailPlaceholder wrapper={false} />;
  if (!data) return null;

  return (
    <AccountBalance
      data={data}
      renderButtons={(balance) => (
        <WorkspaceAccountDetailBalanceControls
          balance={balance}
          accountData={accountData}
          workspaceId={workspaceId}
          onTransactionsOpen={onTransactionsOpen}
        />
      )}
    />
  );
};

export default WorkspaceAccountDetailBalanceTab;
