import QRCode from '../../../components/QRCode';

const StorybookPageQRCode = () => (
  <>
    <h2>Qr code</h2>
    <QRCode value="https://www.youtube.com/watch?v=dQw4w9WgXcQ" size={256} />
  </>
);

export default StorybookPageQRCode;
