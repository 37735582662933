import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  createGlobalDeal,
  loadGlobalAgents,
  loadGlobalClubs,
  loadGlobalUsers,
  loadGlobalWorkspaces,
  updateGlobalDeal,
} from '../../../api';
import { GlobalDealSchema } from '../../../api/schemas/deal/globalDealSchema';
import Form from '../../../components/Form';
import Input from '../../../components/Input';
import PageAddon from '../../../components/PageAddon';
import Select, { Option, Options } from '../../../components/Select';
import SubmitButton from '../../../components/SubmitButton';
import Textarea from '../../../components/Textarea';
import { convertNumberToString, convertStringToNumber } from '../../../utils/data';
import { required } from '../../../utils/form';
import notify from '../../../utils/notify';
import { TRAINER_POKER_ROOM_LABELS, trainerPokerRoomOptions } from '../../../utils/trainer';
import {
  CREDIT_LABELS,
  STATUS_LABELS,
  PROTECTION_LABELS,
  ROLE_LABELS,
  PAYMENT_SYSTEM_LABELS,
} from '../../deal/helpers';
import {
  creditOptions,
  paymentSystemOptions,
  protectionOptions,
  roleOptions,
  statusOptions,
} from '../helpers';
import styles from './styles.module.scss';

interface Fields {
  agent_id: Option<GlobalDealSchema['agent']['id']>;
  poker_room: Option<GlobalDealSchema['poker_room']>;
  club_id: Option<GlobalDealSchema['club']['id']>;
  area_id: string;
  area2_id: string;
  holder_user_id: Option<Exclude<GlobalDealSchema['holder'], null>['id']>;
  owner: string;
  role: Option<GlobalDealSchema['role']>;
  status: Option<GlobalDealSchema['status']>;
  credit: Option<GlobalDealSchema['credit']>;
  protection: Option<GlobalDealSchema['protection']>;
  currency: string;
  chip_rate: string;
  rake_back: string;
  action: string;
  rev_share: string;
  win_share: string;
  rebate: string;
  agent_fee: string;
  withdraw_fee: string;
  payment_system: Options<GlobalDealSchema['payment_system'][number]>;
  workspace_ids: Options<GlobalDealSchema['workspaces'][number]['id']>;
  financial_conditions: string;
  operating_conditions: string;
  policy: string;
  description: string;
}

interface CommonProps {
  onClose: () => void;
}

interface CreateProps {
  onCreate: (args: { dealId: GlobalDealSchema['id'] }) => void;
  data?: never;
  onEdit?: never;
}

interface EditProps {
  data: GlobalDealSchema;
  onEdit: () => void;
  onCreate?: never;
}

type Props = CommonProps & (CreateProps | EditProps);

const GlobalDealForm = (props: Props) => {
  const { data, onClose, onCreate, onEdit } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: {
      agent_id: data ? { label: data.agent.name, value: data.agent.id } : null,
      poker_room: data
        ? { label: TRAINER_POKER_ROOM_LABELS[data.poker_room], value: data.poker_room }
        : null,
      club_id: data ? { label: data.club.name, value: data.club.id } : null,
      area_id: data?.area_id || '',
      area2_id: data?.area2_id || '',
      holder_user_id: data?.holder ? { label: data.holder.username, value: data.holder.id } : null,
      owner: data?.owner || '',
      role: data
        ? { value: data.role, label: ROLE_LABELS[data.role] }
        : { value: roleOptions[0].value, label: roleOptions[0].label },
      status: data
        ? { value: data.status, label: STATUS_LABELS[data.status] }
        : { value: statusOptions[0].value, label: statusOptions[0].label },
      credit: data
        ? { value: data.credit, label: CREDIT_LABELS[data.credit] }
        : { value: creditOptions[0].value, label: creditOptions[0].label },
      protection: data
        ? { value: data.protection, label: PROTECTION_LABELS[data.protection] }
        : { value: protectionOptions[0].value, label: protectionOptions[0].label },
      currency: data?.currency || '',
      chip_rate: data ? convertNumberToString(data.chip_rate) : '',
      rake_back: data ? convertNumberToString(data.rake_back) : '',
      action: data ? convertNumberToString(data.action) : '',
      rev_share: data ? convertNumberToString(data.rev_share) : '',
      win_share: data ? convertNumberToString(data.win_share) : '',
      rebate: data ? convertNumberToString(data.rebate) : '',
      agent_fee: data ? convertNumberToString(data.agent_fee) : '',
      withdraw_fee: data ? convertNumberToString(data.withdraw_fee) : '',
      payment_system: data
        ? data.payment_system.map((item) => ({
            value: item,
            label: PAYMENT_SYSTEM_LABELS[item],
          }))
        : [],
      workspace_ids: data
        ? data.workspaces.map((item) => ({ value: item.id, label: item.name }))
        : [],
      financial_conditions: data?.financial_conditions || '',
      operating_conditions: data?.operating_conditions || '',
      policy: data?.policy || '',
      description: data?.description || '',
    },
  });

  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    if (!values.poker_room) throw new Error();
    if (!values.club_id) throw new Error();
    if (!values.agent_id) throw new Error();

    const payload = {
      agent_id: values.agent_id.value,
      poker_room: values.poker_room.value,
      club_id: values.club_id.value,
      area_id: values.area_id || null,
      area2_id: values.area2_id || null,
      holder_user_id: values.holder_user_id?.value || null,
      owner: values.owner || null,
      role: values.role?.value || null,
      status: values.status?.value || null,
      credit: values.credit?.value || null,
      protection: values.protection?.value || null,
      currency: values.currency || null,
      chip_rate: convertStringToNumber(values.chip_rate),
      rake_back: convertStringToNumber(values.rake_back),
      action: convertStringToNumber(values.action),
      rev_share: convertStringToNumber(values.rev_share),
      win_share: convertStringToNumber(values.win_share),
      rebate: convertStringToNumber(values.rebate),
      agent_fee: convertStringToNumber(values.agent_fee),
      withdraw_fee: convertStringToNumber(values.withdraw_fee),
      payment_system: values.payment_system.map((item) => item.value),
      workspace_ids: values.workspace_ids.map((item) => item.value),
      financial_conditions: values.financial_conditions || null,
      operating_conditions: values.operating_conditions || null,
      policy: values.policy || null,
      description: values.description || null,
    };

    if (data) {
      await updateGlobalDeal({ dealId: data.id, payload });

      onEdit();
    } else {
      const response = await createGlobalDeal({ payload });

      onCreate({ dealId: response.id });
    }

    notify('success', {
      title: data ? t('sentences.changes_have_been_saved') : t('sentences.record_has_been_created'),
    });
  };

  const room = watch('poker_room')?.value;

  return (
    <PageAddon
      onClose={onClose}
      title={data ? `${t('common.edit')} ${data.code}` : t('common.create_deal')}
    >
      <Form form={form} onSubmit={onSubmit}>
        <Controller
          name="agent_id"
          control={control}
          rules={{ validate: required }}
          render={({ field }) => (
            <Select.AsyncV2
              onLoad={(args) => loadGlobalAgents(args)}
              select={(item) => ({ value: item.id, label: item.name })}
            >
              {({ options, ...rest }) => (
                <Select
                  clearable={false}
                  label={`${t('common.agent')}*`}
                  options={options}
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.agent_id?.message}
                  {...rest}
                />
              )}
            </Select.AsyncV2>
          )}
        />
        <Controller
          name="poker_room"
          control={control}
          rules={{ validate: required }}
          render={({ field }) => (
            <Select
              clearable={false}
              label={`${t('common.poker_room')}*`}
              options={trainerPokerRoomOptions}
              value={field.value}
              onChange={(value) => {
                setValue('club_id', null);

                field.onChange(value);
              }}
              error={errors.poker_room?.message}
            />
          )}
        />
        <Controller
          name="club_id"
          control={control}
          rules={{ validate: required }}
          render={({ field }) => (
            <Select.Async
              onLoad={async (args) => {
                const response = await loadGlobalClubs({
                  ...args,
                  poker_room_in: String(room),
                });

                const options = response.items.map((item) => ({
                  value: item.id,
                  label: item.name,
                  caption: item.code,
                }));

                return options;
              }}
            >
              {({ options, ...rest }) => (
                <Select
                  clearable={false}
                  label={`${t('common.club')}*`}
                  options={options}
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.club_id?.message}
                  disabled={!room}
                  {...rest}
                />
              )}
            </Select.Async>
          )}
        />
        <Input label={t('common.area')} {...register('area_id')} />
        <Input label={t('common.area_2')} {...register('area2_id')} />
        <Controller
          name="holder_user_id"
          control={control}
          render={({ field }) => (
            <Select.Async
              onLoad={async (args) => {
                const response = await loadGlobalUsers(args);

                const options = response.items.map((item) => ({
                  value: item.id,
                  label: item.username,
                }));

                return options;
              }}
            >
              {({ options, ...rest }) => (
                <Select
                  label={t('common.holder')}
                  options={options}
                  value={field.value}
                  onChange={field.onChange}
                  {...rest}
                />
              )}
            </Select.Async>
          )}
        />
        <Input label={t('common.owner')} {...register('owner')} />
        <Controller
          name="role"
          control={control}
          render={({ field }) => (
            <Select
              clearable={false}
              label={t('common.role')}
              options={roleOptions}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <Select
              clearable={false}
              label={t('common.status')}
              options={statusOptions}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          name="credit"
          control={control}
          render={({ field }) => (
            <Select
              clearable={false}
              label={t('common.credit')}
              options={creditOptions}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          name="protection"
          control={control}
          render={({ field }) => (
            <Select
              clearable={false}
              label={t('common.protection')}
              options={protectionOptions}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Input label={t('common.currency')} {...register('currency')} />
        <Controller
          name="chip_rate"
          control={control}
          render={({ field }) => (
            <Input
              label={t('common.chip_rate')}
              format={{
                decimalScale: 6,
                onValueChange: field.onChange,
              }}
              {...field}
            />
          )}
        />
        <Controller
          name="rake_back"
          control={control}
          render={({ field }) => (
            <Input
              label={t('common.rakeback')}
              format={{
                decimalScale: 6,
                onValueChange: field.onChange,
              }}
              {...field}
            />
          )}
        />
        <Controller
          name="action"
          control={control}
          render={({ field }) => (
            <Input
              label={t('common.action')}
              format={{
                decimalScale: 6,
                onValueChange: field.onChange,
              }}
              {...field}
            />
          )}
        />
        <Controller
          name="rev_share"
          control={control}
          render={({ field }) => (
            <Input
              label={t('common.revshare')}
              format={{
                decimalScale: 6,
                onValueChange: field.onChange,
              }}
              {...field}
            />
          )}
        />
        <Controller
          name="win_share"
          control={control}
          render={({ field }) => (
            <Input
              label={t('common.winshare')}
              format={{
                decimalScale: 6,
                onValueChange: field.onChange,
              }}
              {...field}
            />
          )}
        />
        <Controller
          name="rebate"
          control={control}
          render={({ field }) => (
            <Input
              label={t('common.rebate')}
              format={{
                decimalScale: 6,
                onValueChange: field.onChange,
              }}
              {...field}
            />
          )}
        />
        <Controller
          name="agent_fee"
          control={control}
          render={({ field }) => (
            <Input
              label={t('common.agent_fee')}
              format={{
                decimalScale: 6,
                onValueChange: field.onChange,
              }}
              {...field}
            />
          )}
        />
        <Controller
          name="withdraw_fee"
          control={control}
          render={({ field }) => (
            <Input
              label={t('common.withdraw_fee')}
              format={{
                decimalScale: 6,
                onValueChange: field.onChange,
              }}
              {...field}
            />
          )}
        />
        <Controller
          name="payment_system"
          control={control}
          render={({ field }) => (
            <Select.Multi
              label={t('common.payment_system')}
              options={paymentSystemOptions}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          name="workspace_ids"
          control={control}
          render={({ field }) => (
            <Select.Async
              onLoad={async (args) => {
                const response = await loadGlobalWorkspaces(args);

                const options = response.items.map((item) => ({
                  value: item.id,
                  label: item.name,
                }));

                return options;
              }}
            >
              {({ options, ...rest }) => (
                <Select.Multi
                  label={t('common.workspaces')}
                  options={options}
                  value={field.value}
                  onChange={field.onChange}
                  {...rest}
                />
              )}
            </Select.Async>
          )}
        />
        <Textarea
          maxLength={0}
          label={t('common.financial_conditions')}
          className={styles.textarea}
          {...register('financial_conditions')}
        />
        <Textarea
          maxLength={0}
          label={t('common.operating_conditions')}
          className={styles.textarea}
          {...register('operating_conditions')}
        />
        <Textarea
          maxLength={0}
          label={t('common.policy')}
          className={styles.textarea}
          {...register('policy')}
        />
        <Textarea
          maxLength={0}
          label={t('common.description')}
          className={styles.textarea}
          {...register('description')}
        />
        <PageAddon.Controls>
          <SubmitButton>{data ? t('common.save') : t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default GlobalDealForm;
