import {
  registerUser as registerUserApi,
  verifyEmail as verifyEmailApi,
  setInvitedUser as setInvitedUserApi,
  loginUser as loginUserApi,
  logoutUser as logoutUserApi,
  sendRecoveryPassword as sendRecoveryPasswordApi,
  receiveRecoveryPassword as receiveRecoveryPasswordApi,
  refreshUserToken as refreshUserTokenApi,
} from '../api';
import { useSessionStore } from '../store/session';
import { useUserStore } from '../store/user';
/* const authBroadcastChannel = new BroadcastChannel('auth'); */

export const registerUser = ({ email, password }: { email: string; password: string }) =>
  registerUserApi({ payload: { username: email, password } });

export const verifyEmail = (hash: string) => verifyEmailApi({ payload: { user_hash: hash } });

export const setInvitedUser = (
  { hash }: { hash: string },
  { password, maintenance_name }: { password: string; maintenance_name?: string }
) => setInvitedUserApi({ hash, payload: { password, maintenance_name } });

export const loginUser = async ({ email, password }: { email: string; password: string }) => {
  const response = await loginUserApi({ payload: { username: email, password } });

  useSessionStore.getState().setSession(response);
};

export const logoutUser = async () => {
  const { session, removeSession } = useSessionStore.getState();
  const { removeUser } = useUserStore.getState();

  if (session) {
    const { refresh_token } = session;

    await logoutUserApi({ payload: { refresh_token } });

    removeSession();
    removeUser();
  }
};

export const sendRecoveryPassword = ({ email }: { email: string }) =>
  sendRecoveryPasswordApi({ payload: { username: email } });

export const receiveRecoveryPassword = (
  { hash }: { hash: string },
  { password }: { password: string }
) => receiveRecoveryPasswordApi({ hash, payload: { password } });

export const refreshUserToken = async () => {
  const { session, setSession, removeSession } = useSessionStore.getState();

  if (session) {
    const { refresh_token } = session;

    try {
      const response = await refreshUserTokenApi({ payload: { refresh_token } });

      setSession(response);
    } catch {
      removeSession();
    }
  }
};

export const getUrlWithToken = (url: string) => {
  const { session } = useSessionStore.getState();

  return `${url}?token=${session?.access_token}`;
};
