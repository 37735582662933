import api from '../../services/api';
import globalDealSchema, { GlobalDealSchema } from '../schemas/deal/globalDealSchema';

interface Args {
  dealId: GlobalDealSchema['id'];
}

const loadGlobalDeal = async (args: Args) => {
  const { dealId } = args;

  const response = await api.get(`/api/deal/${dealId}`);

  return globalDealSchema.parse(response.data);
};

export default loadGlobalDeal;
