import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import globalSuperLeagueSchema from './globalSuperLeagueSchema';
import jsonSchema from './jsonSchema';
import trainerPokerRoomSchema from './trainerPokerRoomSchema';

const globalLeagueSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string(),
  poker_room: trainerPokerRoomSchema,
  extra: jsonSchema,
  super_league: globalSuperLeagueSchema.or(z.null()),
  is_deleted: z.boolean(),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export type GlobalLeagueSchema = z.infer<typeof globalLeagueSchema>;

export default globalLeagueSchema;
