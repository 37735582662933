import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import workspaceAccountTransactionSchema, {
  WorkspaceAccountTransactionSchema,
} from '../schemas/account/workspaceAccountTransactionSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = WorkspaceAccountTransactionSchema;

interface Payload {
  amount: Schema['new_balance']['amount'];
  currency: Schema['new_balance']['currency'];
  club_id: Schema['new_balance']['club_id'];
  league_id: Schema['new_balance']['league_id'];
  user_message: Schema['user_message'];
  file_ids: WorkspaceAccountTransactionSchema['files'][number]['id'][];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
  payload: Payload;
}

const adjustmentWorkspaceAccount = async (args: Args) => {
  const { workspaceId, accountId, payload } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/${accountId}/adjustment`,
    payload
  );

  return workspaceAccountTransactionSchema.pick({ id: true }).parse(response.data);
};

export default adjustmentWorkspaceAccount;
