import { BotSettingRuleSchema } from '../../api/schemas/botSettingRuleSchema';
import PageDetailLink from '../../components/PageDetailLink';
import routes from '../../config/routes';

interface Props {
  data: BotSettingRuleSchema;
}

const GlobalBotSettingRuleDetailLink = (props: Props) => {
  const { data } = props;

  const { id, name } = data;

  return <PageDetailLink label={name} to={routes.globalBotSettingRule({ botSettingRuleId: id })} />;
};

export default GlobalBotSettingRuleDetailLink;
