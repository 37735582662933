import { PROTECTION } from '../../api/schemas/deal';
import Badge, { Color } from '../../components/Badge';
import { PROTECTION_LABELS } from './helpers';
import { CommonDealSchema } from './types';

const COLORS: Record<CommonDealSchema['protection'], Color> = {
  [PROTECTION.UNKNOWN]: 'grey',
  [PROTECTION.PROTECTION]: 'green',
  [PROTECTION.PENALTY]: 'red',
  [PROTECTION.NO_PENALTY]: 'blue',
  [PROTECTION.FIFTY_FIFTY]: 'orange',
};

interface Props {
  data: CommonDealSchema;
}

const DealProtection = (props: Props) => {
  const { data } = props;

  const { protection } = data;

  return <Badge color={COLORS[protection]}>{PROTECTION_LABELS[protection]}</Badge>;
};

export default DealProtection;
