import Checkbox from '../../Checkbox';
import { useTableContext } from '../TableContext';

interface Props {
  id: string;
}

const TableRowSelect = (props: Props) => {
  const { id } = props;

  const { select, checkIsSelected } = useTableContext();

  const handleChange = () => select(id);

  return <Checkbox onChange={handleChange} checked={checkIsSelected(id)} />;
};

export default TableRowSelect;
