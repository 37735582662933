import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';
import Button from '../Button';
import FadingText from '../FadingText';
import styles from './styles.module.scss';

interface Props {
  onCancel: () => void;
  title?: string;
  count?: number;
  children?: ReactNode;
  setTranslateY?: (y: number | null) => void;
}

const SheetTop = (props: Props) => {
  const { title, children, onCancel, setTranslateY } = props;

  const { t } = useTranslation();

  const handlers = useSwipeable({
    preventScrollOnSwipe: true,
    onSwiping: (event) => {
      const { deltaY, dir } = event;

      if (setTranslateY && dir === 'Down') {
        setTranslateY(deltaY);
      }
    },
    onSwiped: (event) => {
      const { deltaY, initial, dir } = event;

      if (setTranslateY && dir === 'Down') {
        setTranslateY(null);

        if (deltaY > (window.innerHeight - initial[1]) / 2) {
          onCancel();
        }
      }
    },
  });

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        {setTranslateY && <div className={styles.handler} {...handlers} />}
        <div>
          <div>
            {title && (
              <FadingText text={title}>
                <span>{title}</span>
              </FadingText>
            )}
          </div>
          <Button variant="light" size="extra-small" onClick={onCancel}>
            {t('common.cancel')}
          </Button>
        </div>
      </div>
      {children}
    </div>
  );
};

export default SheetTop;
