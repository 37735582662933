import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { cloneElement } from 'react';
import { useTranslation } from 'react-i18next';
import { loadWorkspaceClubTransactionsFilterValues } from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Filter from '../../components/Filter';
import queryKeys from '../../config/queryKeys';
import useFilter from '../../hooks/useFilter';
import useURL from '../../hooks/useURL';
import { TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';
import {
  CLUB_TRANSACTIONS_STATUSES_ICONS,
  CLUB_TRANSACTIONS_STATUSES_LABELS,
  CLUB_TRANSACTIONS_TYPES_LABELS,
} from './helpers';
import styles from './styles.module.scss';

interface Fields {
  room_in: string[];
  club_in: string[];
  manager_in: string[];
  status_in: string[];
  type_in: string[];
  created_ge: string;
  created_le: string;
}

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceClubTransactionFilter = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { urlParams } = useURL();

  const queryKey = [queryKeys.workspaceClubTransactionsFilterValues({ workspaceId }), urlParams];
  const queryFn = () => loadWorkspaceClubTransactionsFilterValues({ workspaceId }, urlParams);

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  const filter = useFilter<Fields>({
    room_in: 'multi',
    club_in: 'multi',
    manager_in: 'multi',
    status_in: 'multi',
    type_in: 'multi',
    created_ge: 'single',
    created_le: 'single',
  });

  const { values, setValue, clearValue } = filter;

  const { room_in, club_in, manager_in, status_in, type_in, created_ge, created_le } = values;

  const filterProps = { filter };

  if (isPending) {
    return (
      <Filter {...filterProps}>
        <Filter.Placeholder />
      </Filter>
    );
  }

  if (!data) return null;

  const { room, club, manager, status, type } = data;

  return (
    <Filter {...filterProps}>
      <div>
        {room.length !== 0 && (
          <Filter.Collapse
            label={t('common.poker_room')}
            count={room_in.length}
            onClear={clearValue('room_in')}
          >
            <Filter.MultiCheckbox
              config={data.room.map((item) => ({
                value: String(item),
                label: TRAINER_POKER_ROOM_LABELS[item],
                onChange: setValue('room_in'),
                checked: room_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {club.length !== 0 && (
          <Filter.Collapse
            label={t('common.club')}
            count={club_in.length}
            onClear={clearValue('club_in')}
          >
            <Filter.MultiCheckbox
              config={club.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('club_in'),
                checked: club_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        {manager.length !== 0 && (
          <Filter.Collapse
            label={t('common.manager')}
            count={manager_in.length}
            onClear={clearValue('manager_in')}
          >
            <Filter.MultiCheckbox
              config={manager.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('manager_in'),
                checked: manager_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        {status.length !== 0 && (
          <Filter.Collapse
            label={t('common.status')}
            count={status_in.length}
            onClear={clearValue('status_in')}
          >
            <Filter.MultiCheckbox
              config={status.map((item) => {
                const icon = CLUB_TRANSACTIONS_STATUSES_ICONS[item];

                return {
                  value: String(item),
                  label: CLUB_TRANSACTIONS_STATUSES_LABELS[item],
                  onChange: setValue('status_in'),
                  checked: status_in.includes(String(item)),
                  ...(icon && { extra: cloneElement(icon, { className: styles.icon }) }),
                };
              })}
            />
          </Filter.Collapse>
        )}
        {type.length !== 0 && (
          <Filter.Collapse
            label={t('common.type')}
            count={type_in.length}
            onClear={clearValue('type_in')}
          >
            <Filter.MultiCheckbox
              config={type.map((item) => ({
                value: String(item),
                label: CLUB_TRANSACTIONS_TYPES_LABELS[item],
                onChange: setValue('type_in'),
                checked: type_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        <Filter.Collapse
          label={t('common.created')}
          {...((created_ge || created_le) && {
            onClear: clearValue(['created_ge', 'created_le']),
          })}
        >
          <Filter.Date
            from={{ value: created_ge, onChange: setValue('created_ge') }}
            to={{ value: created_le, onChange: setValue('created_le') }}
          />
        </Filter.Collapse>
      </div>
    </Filter>
  );
};

export default WorkspaceClubTransactionFilter;
