import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import channelSchema, { ChannelSchema } from '../schemas/channelSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  channelId: ChannelSchema['id'];
}

const loadWorkspaceChannel = async (args: Args) => {
  const { workspaceId, channelId } = args;

  const response = await api.get(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/channel/${channelId}`);

  return channelSchema.parse(response.data);
};

export default loadWorkspaceChannel;
