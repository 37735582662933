import { useDrag } from '@use-gesture/react';
import clsx from 'clsx';
import { useRef } from 'react';
import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import useURL from '../../../hooks/useURL';
import icon from '../../../static/icons';
import Id from '../../../types/Id';
import TableRowSelectAll from '../TableRowSelectAll';
import { useTableStoreContext } from '../TableStoreContext';
import { Column } from '../types';
import styles from './styles.module.scss';

interface Props<T> {
  column: Column<T>;
}

const TableHeadCell = <T extends Id>(props: Props<T>) => {
  const { column } = props;

  const { key, pin, title, align, select, sorting, resize, style } = column;

  const { urlParams, setURLParams } = useURL();

  const { sort_by, order_by } = urlParams;

  const store = useTableStoreContext();

  const { element, scrolled, scrolledToEnd, resizedWidth, setColumnSizes, onScroll } =
    useStoreWithEqualityFn(
      store,
      (state) => {
        const resizble = resize && key;
        const width = resizble && state.columnSizes && state.columnSizes[key];

        return {
          ...(width && { resizedWidth: width }),
          ...(resizble && {
            element: state.element,
            setColumnSizes: state.setColumnSizes,
            onScroll: state.onScroll,
          }),
          ...(pin && { scrolled: state.scrolled, scrolledToEnd: state.scrolledToEnd }),
        };
      },
      shallow
    );

  const className = clsx(styles.container, {
    [styles.alignCenter]: align === 'center',
    [styles.alignRight]: align === 'right',
    [styles.pinLeft]: pin === 'left',
    [styles.pinRight]: pin === 'right',
    [styles.resize]: resize,
    [styles.withLeftShadow]: scrolled,
    [styles.withRightShadow]: column.controls && !scrolledToEnd,
  });

  const getSortBy = () => (order_by && order_by === 'desc' && key === sort_by ? null : key);

  const getOrderBy = () => {
    if (!order_by) return 'asc';
    if (key !== sort_by) return 'asc';
    if (order_by && order_by === 'asc') return 'desc';
    if (order_by && order_by === 'desc') return null;

    return 'asc';
  };

  const content = <span>{title}</span>;

  const ref = useRef<HTMLDivElement>(null);

  const bind = useDrag(
    ({ xy: [x] }) => {
      if (ref.current) {
        const value = Math.round(x - ref.current.getBoundingClientRect().x);

        if (style?.width && typeof style.width === 'number' && value >= style.width) {
          if (key && element && setColumnSizes && onScroll) {
            setColumnSizes({ [key]: value });
            onScroll();
          }
        }
      }
    },
    { axis: 'x', preventDefault: true, pointer: { mouse: true } }
  );

  return (
    <div
      ref={ref}
      className={className}
      style={{ ...style, ...(resizedWidth && { width: `${resizedWidth}px` }) }}
    >
      {select && <TableRowSelectAll />}
      {sorting ? (
        <button
          type="button"
          onClick={() => setURLParams({ sort_by: getSortBy(), order_by: getOrderBy() })}
          className={clsx({
            [styles.asc]: sort_by === key && order_by === 'asc',
            [styles.desc]: sort_by === key && order_by === 'desc',
          })}
        >
          {content}
          {icon('arrowLongDown', 16)}
        </button>
      ) : (
        content
      )}
      {resize && <div {...bind()} className={styles.resizer} />}
    </div>
  );
};

export default TableHeadCell;
