import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadGlobalClub } from '../../api';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { can } from '../../utils/permissions';
import { updateDetailQueryData } from '../../utils/queryClient';
import { useGlobalClubWorkspacesQuery } from './queries';

interface Props {
  clubId: GlobalClubSchema['id'];
  children: (args: { data: GlobalClubSchema; workspacesData: WorkspaceSchema[] }) => ReactElement;
}

const GlobalClubLoader = (props: Props) => {
  const { clubId, children } = props;

  const queryKey = [queryKeys.globalClub({ clubId })];
  const queryFn = () => loadGlobalClub({ clubId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<GlobalClubSchema>({
    domain: 'GlobalClub',
    onMessage: (message) => {
      if (
        (message.action === 'Edited' ||
          message.action === 'NoteAdded' ||
          message.action === 'NoteRemoved' ||
          message.action === 'Deleted') &&
        message.payload.id === clubId
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  const { data: workspacesData, loading: workspacesLoading } = useGlobalClubWorkspacesQuery({
    clubId,
  });

  if (isPending || (can.workspace.read && workspacesLoading)) {
    return <PageAddon.DetailPlaceholder />;
  }

  if (!data) return null;

  return children({ data, workspacesData });
};

export default GlobalClubLoader;
