import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import UserInviteForm from '../../features/user/UserInviteForm';
import WorkspaceUserDetail from '../../features/workspace-user/WorkspaceUserDetail';
import WorkspaceUserLoader from '../../features/workspace-user/WorkspaceUserLoader';
import WorkspaceUsersPage from '../../pages/WorkspaceUsersPage';
import Route from './Route';

const page = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceUsersPage workspaceId={workspaceId} />}
  </Route>
);

const detail = (
  <Route params={['workspaceId', 'userId']}>
    {({ workspaceId, userId, navigate }) => (
      <WorkspaceUserLoader workspaceId={workspaceId} userId={userId}>
        {({ data }) => (
          <WorkspaceUserDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceUsers({ workspaceId }))}
          />
        )}
      </WorkspaceUserLoader>
    )}
  </Route>
);

const inviteForm = (
  <Route params={['workspaceId']}>
    {({ workspaceId, navigate }) => (
      <UserInviteForm
        workspaceId={workspaceId}
        onClose={() => navigate(routes.workspaceUsers({ workspaceId }))}
      />
    )}
  </Route>
);

const workspaceUsersPage: RouteObject = {
  path: routes.workspaceUsers({ workspaceId: ':workspaceId' }),
  element: page,
  children: [
    {
      path: routes.workspaceUserInvite({ workspaceId: ':workspaceId' }),
      element: inviteForm,
    },
    {
      path: routes.workspaceUser({ workspaceId: ':workspaceId', userId: ':userId' }),
      element: detail,
    },
  ],
};

export default workspaceUsersPage;
