import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceClubMemberSchema } from '../schemas/workspaceClubMemberSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = WorkspaceClubMemberSchema;

interface Payload {
  room: Schema['room'];
  club: Schema['club'];
  manager_id: Schema['manager']['id'];
  player_ids: Schema['player_id'][];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const acceptWorkspaceClubMembers = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/club-manager/members/accept`,
    payload
  );

  return emptyResponseSchema.parse(response.data);
};

export default acceptWorkspaceClubMembers;
