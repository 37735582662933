import { z } from 'zod';
import globalClubSchema from '../globalClubSchema';
import globalLeagueSchema from '../globalLeagueSchema';
import lobbyTableSchema from '../lobbyTableSchema';

const accountCommonBalanceSchema = z.object({
  amount: z.number(),
  currency: z.string(),
  league_id: globalLeagueSchema.shape.code,
  league_name: globalLeagueSchema.shape.name,
  club_id: globalClubSchema.shape.code,
  club_name: globalClubSchema.shape.name,
  table_id: lobbyTableSchema.shape.id,
});

export default accountCommonBalanceSchema;
