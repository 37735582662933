import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadWorkspaceIdentity } from '../../api';
import { IdentitySchema } from '../../api/schemas/identitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { updateDetailQueryData } from '../../utils/queryClient';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  identityId: IdentitySchema['id'];
  children: (args: { data: IdentitySchema }) => ReactElement;
}

const WorkspaceIdentityLoader = (props: Props) => {
  const { workspaceId, identityId, children } = props;

  const queryKey = [queryKeys.workspaceIdentity({ workspaceId, identityId })];
  const queryFn = () => loadWorkspaceIdentity({ workspaceId, identityId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<IdentitySchema>({
    domain: 'Identity',
    enabled: (message) => message.meta_info?.maintenance_id === workspaceId,
    onMessage: (message) => {
      if (
        (message.action === 'Edited' ||
          message.action === 'Deleted' ||
          message.action === 'AddOnMarket' ||
          message.action === 'RemoveFromMarket' ||
          message.action === 'AccountAdded' ||
          message.action === 'AccountRemoved') &&
        message.payload.id === identityId
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data });
};

export default WorkspaceIdentityLoader;
