import { DndContext, DragEndEvent, UniqueIdentifier, closestCorners } from '@dnd-kit/core';
import {
  restrictToHorizontalAxis,
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  horizontalListSortingStrategy,
  rectSortingStrategy,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { ReactNode } from 'react';
import { addWhen } from '../../utils';
import SortableListItem from './SortableListItem';

interface OnDnD {
  activeId: UniqueIdentifier;
  overId: UniqueIdentifier;
}

interface Props {
  items: UniqueIdentifier[];
  children: ReactNode;
  onDnD: ({ activeId, overId }: OnDnD) => void;
  mode?: 'vertical' | 'horizontal';
}

const SortableList = (props: Props) => {
  const { items, children, onDnD, mode } = props;

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      onDnD({ activeId: active.id, overId: over.id });
    }
  };

  const getStrategy = () => {
    if (mode === 'horizontal') return horizontalListSortingStrategy;
    if (mode === 'vertical') return verticalListSortingStrategy;

    return rectSortingStrategy;
  };

  return (
    <DndContext
      modifiers={[
        restrictToParentElement,
        ...addWhen(restrictToHorizontalAxis, mode === 'horizontal'),
        ...addWhen(restrictToVerticalAxis, mode === 'vertical'),
      ]}
      onDragEnd={handleDragEnd}
      collisionDetection={closestCorners}
    >
      <SortableContext items={items} strategy={getStrategy()}>
        {children}
      </SortableContext>
    </DndContext>
  );
};

export default SortableList;

SortableList.Item = SortableListItem;
