import clsx from 'clsx';
import { Color } from '../../Badge';
import styles from './styles.module.scss';

const COLORS: Record<Color, string> = {
  green: styles.green,
  orange: styles.orange,
  red: styles.red,
  blue: styles.blue,
  purple: styles.purple,
  grey: styles.grey,
};

interface Props {
  color: Color;
}

const CollapseBadge = (props: Props) => {
  const { color } = props;

  return <div className={clsx(styles.container, COLORS[color])} />;
};

export default CollapseBadge;
