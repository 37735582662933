import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import RecoveryPage from '../../pages/RecoveryPage';

const recoveryPage: RouteObject = {
  path: routes.recovery,
  element: <RecoveryPage />,
};

export default recoveryPage;
