import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import WorkspaceClubManagerDetail from '../../features/workspace-club-manager/WorkspaceClubManagerDetail';
import WorkspaceClubManagerForm from '../../features/workspace-club-manager/WorkspaceClubManagerForm';
import WorkspaceClubManagerLoader from '../../features/workspace-club-manager/WorkspaceClubManagerLoader';
import WorkspaceClubMemberAcceptForm from '../../features/workspace-club-member/WorkspaceClubMemberAcceptForm';
import WorkspaceClubMemberAmountForm from '../../features/workspace-club-member/WorkspaceClubMemberAmountForm';
import WorkspaceClubMemberDetail from '../../features/workspace-club-member/WorkspaceClubMemberDetail';
import WorkspaceClubMemberLoader from '../../features/workspace-club-member/WorkspaceClubMemberLoader';
import WorkspaceClubMembersPage from '../../pages/WorkspaceClubMembersPage';
import Route from './Route';

const page = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceClubMembersPage workspaceId={workspaceId} />}
  </Route>
);

const acceptForm = (
  <Route params={['workspaceId']}>
    {({ workspaceId, navigate }) => (
      <WorkspaceClubMemberAcceptForm
        workspaceId={workspaceId}
        onClose={() => navigate(routes.workspaceClubMembers({ workspaceId }))}
      />
    )}
  </Route>
);

const detail = (
  <Route params={['workspaceId', 'memberId']}>
    {({ workspaceId, memberId, navigate }) => (
      <WorkspaceClubMemberLoader workspaceId={workspaceId} memberId={memberId}>
        {({ data }) => (
          <WorkspaceClubMemberDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceClubMembers({ workspaceId }))}
            onDeposit={() => navigate(routes.workspaceClubMemberDeposit({ workspaceId, memberId }))}
            onWithdraw={() =>
              navigate(routes.workspaceClubMemberWithdraw({ workspaceId, memberId }))
            }
            getManagerRoute={({ managerId }) =>
              routes.workspaceClubMemberManager({ workspaceId, memberId, managerId })
            }
          />
        )}
      </WorkspaceClubMemberLoader>
    )}
  </Route>
);

const detailManager = (
  <Route params={['workspaceId', 'memberId', 'managerId']}>
    {({ workspaceId, memberId, managerId, navigate }) => (
      <WorkspaceClubManagerLoader workspaceId={workspaceId} managerId={managerId}>
        {({ data }) => (
          <WorkspaceClubManagerDetail
            data={data}
            workspaceId={workspaceId}
            onClose={() => navigate(routes.workspaceClubMember({ workspaceId, memberId }))}
            onEdit={() =>
              navigate(
                routes.workspaceClubMemberManagerEdit({
                  workspaceId,
                  memberId,
                  managerId,
                })
              )
            }
          />
        )}
      </WorkspaceClubManagerLoader>
    )}
  </Route>
);

const detailManagerEditForm = (
  <Route params={['workspaceId', 'memberId', 'managerId']}>
    {({ workspaceId, memberId, managerId, navigate }) => (
      <WorkspaceClubManagerLoader workspaceId={workspaceId} managerId={managerId}>
        {({ data }) => (
          <WorkspaceClubManagerForm
            data={data}
            workspaceId={workspaceId}
            onClose={() =>
              navigate(routes.workspaceClubMemberManager({ workspaceId, memberId, managerId }))
            }
            onEdit={() =>
              navigate(routes.workspaceClubMemberManager({ workspaceId, memberId, managerId }))
            }
          />
        )}
      </WorkspaceClubManagerLoader>
    )}
  </Route>
);

const depositForm = (
  <Route params={['workspaceId', 'memberId']}>
    {({ workspaceId, memberId, navigate }) => (
      <WorkspaceClubMemberLoader workspaceId={workspaceId} memberId={memberId}>
        {({ data }) => (
          <WorkspaceClubMemberAmountForm
            data={data}
            workspaceId={workspaceId}
            type="deposit"
            onClose={() => navigate(routes.workspaceClubMember({ workspaceId, memberId }))}
          />
        )}
      </WorkspaceClubMemberLoader>
    )}
  </Route>
);

const withdrawForm = (
  <Route params={['workspaceId', 'memberId']}>
    {({ workspaceId, memberId, navigate }) => (
      <WorkspaceClubMemberLoader workspaceId={workspaceId} memberId={memberId}>
        {({ data }) => (
          <WorkspaceClubMemberAmountForm
            data={data}
            workspaceId={workspaceId}
            type="withdraw"
            onClose={() => navigate(routes.workspaceClubMember({ workspaceId, memberId }))}
          />
        )}
      </WorkspaceClubMemberLoader>
    )}
  </Route>
);

const workspaceClubMembersPage: RouteObject = {
  path: routes.workspaceClubMembers({ workspaceId: ':workspaceId' }),
  element: page,
  children: [
    {
      path: routes.workspaceClubMembersAccept({ workspaceId: ':workspaceId' }),
      element: acceptForm,
    },
    {
      path: routes.workspaceClubMember({
        workspaceId: ':workspaceId',
        memberId: ':memberId',
      }),
      element: detail,
      children: [
        {
          path: routes.workspaceClubMemberManager({
            workspaceId: ':workspaceId',
            memberId: ':memberId',
            managerId: ':managerId',
          }),
          element: detailManager,
        },
        {
          path: routes.workspaceClubMemberManagerEdit({
            workspaceId: ':workspaceId',
            memberId: ':memberId',
            managerId: ':managerId',
          }),
          element: detailManagerEditForm,
        },
      ],
    },
    {
      path: routes.workspaceClubMemberDeposit({
        workspaceId: ':workspaceId',
        memberId: ':memberId',
      }),
      element: depositForm,
    },
    {
      path: routes.workspaceClubMemberWithdraw({
        workspaceId: ':workspaceId',
        memberId: ':memberId',
      }),
      element: withdrawForm,
    },
  ],
};

export default workspaceClubMembersPage;
