import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';

export const ACTION_TYPE = {
  UNKNOWN: 0,
  CREATED: 1,
  EDITED: 2,
  DELETED: 3,
  RESTORED: 4,
  STRATEGY_PROFILE_CREATED: 5,
  STRATEGY_PROFILE_EDITED: 6,
  NOTE_CREATED: 7,
  NOTE_EDITED: 8,
  SESSION_CREATED: 9,
  SESSION_EDITED: 10,
  NOTE_DELETED: 11,
  SESSION_DELETED: 12,
} as const;

const authorSchema = z.object({
  username: z.string(),
  id: z.string(),
});

const historySchema = z.object({
  id: z.string(),
  instance: z.object({
    id: z.string(),
    type: z.number(),
    displayed_name: z.string(),
  }),
  action_type: z.nativeEnum(ACTION_TYPE),
  author: authorSchema.or(z.null()),
  workspace_id: z.string().or(z.null()),
  changes: z.array(
    z.object({
      name: z.string(),
      old_value: z.any(),
      new_value: z.any(),
    })
  ),
  created_on: dateTimeSchema,
});

export const historyFilterValuesSchema = z.object({
  action_type: z.array(historySchema.shape.action_type),
  author: z.array(z.object({ id: authorSchema.shape.id, name: authorSchema.shape.username })),
});

export type HistoryFilterValuesSchema = z.infer<typeof historyFilterValuesSchema>;
export type HistorySchema = z.infer<typeof historySchema>;

export default historySchema;
