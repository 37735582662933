import { useTranslation } from 'react-i18next';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import PageAddon from '../../components/PageAddon';
import { copy } from '../../utils/clipboard';
import { getAccountMetrics } from './helpers';
import WorkspaceAccountNetWin from './WorkspaceAccountNetWin';

interface Props {
  data: WorkspaceAccountSchema;
}

const WorkspaceAccountMetrics = (props: Props) => {
  const { data } = props;

  const { metrics, vpip } = data;

  const { t } = useTranslation();

  const { hands_played, win_rate_bb } = getAccountMetrics(metrics);

  return (
    <>
      <PageAddon.Collapse.Field label={t('common.hands')}>
        <PageAddon.Copy>{hands_played}</PageAddon.Copy>
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field label={t('common.net_win')}>
        <WorkspaceAccountNetWin data={data}>
          {({ value, content }) => (
            <PageAddon.Copy onCopy={() => copy(value)}>{content}</PageAddon.Copy>
          )}
        </WorkspaceAccountNetWin>
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field label={t('common.bb/100')}>
        <PageAddon.Copy>{win_rate_bb}</PageAddon.Copy>
      </PageAddon.Collapse.Field>
      <PageAddon.Collapse.Field label={t('common.vpip')}>
        {vpip !== null ? <PageAddon.Copy>{vpip}</PageAddon.Copy> : '—'}
      </PageAddon.Collapse.Field>
    </>
  );
};

export default WorkspaceAccountMetrics;
