import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import workspaceAccountSchema from '../schemas/account/workspaceAccountSchema';
import paginationSchema from '../schemas/paginationSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceAccounts = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account`, {
    params,
  });

  return paginationSchema(workspaceAccountSchema).parse(response.data);
};

export default loadWorkspaceAccounts;
