import api from '../../services/api';
import { GlobalAccountSchema } from '../schemas/account/globalAccountSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { GlobalSessionSchema } from '../schemas/session/globalSessionSchema';

type Schema = GlobalSessionSchema;

interface Payload {
  start?: Schema['start'];
  end?: Schema['end'];
  club_ids?: Schema['clubs'][number]['id'][];
  session_type?: Schema['session_type'];
}

interface Args {
  accountId: GlobalAccountSchema['id'];
  sessionId: Schema['id'];
  payload: Payload;
}

const updateGlobalAccountSession = async (args: Args) => {
  const { accountId, sessionId, payload } = args;

  const response = await api.patch(`/api/account/${accountId}/session/${sessionId}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateGlobalAccountSession;
