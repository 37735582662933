import api from '../../services/api';
import { AgentSchema } from '../schemas/agentSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { NoteSchema } from '../schemas/noteSchema';

interface Args {
  agentId: AgentSchema['id'];
  noteId: NoteSchema['id'];
}

const deleteGlobalAgentNote = async (args: Args) => {
  const { agentId, noteId } = args;

  const response = await api.delete(`/api/agent/${agentId}/notes/${noteId}`);

  return emptyResponseSchema.parse(response.data);
};

export default deleteGlobalAgentNote;
