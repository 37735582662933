import { useTranslation } from 'react-i18next';
import { REGISTRATION_STATUS } from '../../api/schemas/account';
import Badge, { Color } from '../../components/Badge';
import Tooltip from '../../components/Tooltip';
import { CommonAccountSchema } from './types';

interface Badge {
  label: string;
  color: Color;
}

interface Props {
  data: CommonAccountSchema;
}

const AccountStatus = (props: Props) => {
  const { data } = props;

  const { t } = useTranslation();

  const { is_online, is_miner, on_verification, on_registration, register_error_message } = data;

  const getBadge = (): Badge => {
    if (on_registration === REGISTRATION_STATUS.NOT_REGISTERED) {
      return { label: t('common.not_registered'), color: 'grey' };
    }

    if (on_registration === REGISTRATION_STATUS.IN_PROGRESS) {
      return { label: t('common.registering'), color: 'blue' };
    }

    if (on_registration === REGISTRATION_STATUS.ERROR_RECEIVED) {
      return { label: t('common.error'), color: 'red' };
    }

    if (on_registration === REGISTRATION_STATUS.REGISTERED) {
      if (on_verification) return { label: t('common.verifying'), color: 'blue' };

      if (!on_verification) {
        if (is_online && is_miner) return { label: t('common.mining'), color: 'orange' };
        if (is_online) return { label: t('common.online'), color: 'green' };
        if (!is_online) return { label: t('common.offline'), color: 'grey' };
      }
    }

    return { label: t('common.unknown'), color: 'grey' };
  };

  const { label, color } = getBadge();

  return (
    <Tooltip label={register_error_message}>
      <Badge color={color}>{label}</Badge>
    </Tooltip>
  );
};

export default AccountStatus;
