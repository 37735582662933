import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import globalRoleSchema from './globalRoleSchema';
import workspaceSchema from './workspaceSchema';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const currentUserPasswordSchema = z.string();

const currentUserWorkspaceSchema = z.object({
  id: workspaceSchema.shape.id,
  name: workspaceSchema.shape.name,
  maintenance_types: workspaceSchema.shape.maintenance_types,
  is_owner: z.boolean(), // TODO: не используется
  invited_on: dateTimeSchema, // TODO: не используется
});

const currentUserRoleSchema = globalRoleSchema.pick({
  id: true,
  name: true,
  is_deleted: true,
  permissions: true,
});

const currentUserSchema = z.object({
  id: z.string(),
  name: z.string(),
  avatar: z.string(),
  username: z.string(),
  maintenances: z.array(currentUserWorkspaceSchema),
  roles: z.array(currentUserRoleSchema),
  is_deleted: z.boolean(),
  verified: z.boolean(),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export type CurrentUserPasswordSchema = z.infer<typeof currentUserPasswordSchema>;
export type CurrentUserSchema = z.infer<typeof currentUserSchema>;

export default currentUserSchema;
