import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useRerender from '../../../hooks/useRerender';
import useTimeZone from '../../../hooks/useTimeZone';
import icon from '../../../static/icons';
import { useUserStore } from '../../../store/user';
import { logoutUser } from '../../../utils/auth';
import Dropdown from '../../Dropdown';
import SidebarItem from '../SidebarItem';
import styles from './styles.module.scss';

const SidebarUser = () => {
  useRerender({ interval: 1000 });

  const { t } = useTranslation();

  const user = useUserStore();
  const { time, options } = useTimeZone();

  if (!user.data) return null;

  const { username } = user.data;

  return (
    <>
      <SidebarItem
        icon={<div className={styles.avatar}>{icon('user', 16)}</div>}
        className={styles.item}
      >
        {username}
      </SidebarItem>
      <Dropdown
        title={t('common.time_zone')}
        addon={
          <div className={styles.addon}>
            <span>{t('sentences.time_zone_settings_affect_all_dates')}</span>
          </div>
        }
        options={options}
        className={styles.dropdown}
      >
        {() => (
          <div className={styles.time}>
            <div>
              {icon('clock', 24)}
              {time}
            </div>
            {icon('chevronRight', 16)}
          </div>
        )}
      </Dropdown>
      <SidebarItem
        onClick={logoutUser}
        icon={icon('logOut', 24)}
        className={clsx(styles.item, styles.danger)}
      >
        {t('common.log_out')}
      </SidebarItem>
    </>
  );
};

export default SidebarUser;
