import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceSchema } from '../schemas/workspaceSchema';
import { workspaceSettingsStrategyProfileSchema } from '../schemas/workspaceSettingsStrategyProfileSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceStrategyProfile = async (args: Args) => {
  const { workspaceId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/strategy-profile-settings`
  );

  return workspaceSettingsStrategyProfileSchema.parse(response.data);
};

export default loadWorkspaceStrategyProfile;
