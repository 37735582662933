import { GlobalUserSchema } from '../../api/schemas/globalUserSchema';
import Dropdown from '../../components/Dropdown';
import { compact } from '../../utils';
import useDropdown from './useDropdown';

interface Props {
  data: GlobalUserSchema;
}

const GlobalUserDropdown = (props: Props) => {
  const { title, disabled, options } = useDropdown(props);

  const compactOptions = compact([options.resetPassword, options.invite, options.copy]);

  if (!compactOptions.length) return null;

  return <Dropdown title={title} buttonSize="small" disabled={disabled} options={compactOptions} />;
};

export default GlobalUserDropdown;
