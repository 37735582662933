import { z } from 'zod';
import workspaceAccountSchema, { workspaceAccountPasswordSchema } from './workspaceAccountSchema';

const globalAccountPasswordSchema = z.object({
  login: workspaceAccountSchema.shape.login,
  password: workspaceAccountPasswordSchema,
});

export type GlobalAccountPasswordSchema = z.infer<typeof globalAccountPasswordSchema>;

export default globalAccountPasswordSchema;
