import { useTranslation } from 'react-i18next';
import PageDetailLink from '../../components/PageDetailLink';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import { CommonDealSchema } from './types';

interface Props {
  data: CommonDealSchema;
  to: string;
}

const DealDetailLink = (props: Props) => {
  const { data, to } = props;

  const { code, notes_count } = data;

  const { t } = useTranslation();

  return (
    <PageDetailLink
      label={code}
      to={to}
      extra={
        <>
          {Boolean(notes_count) && <Tooltip label={t('common.notes')}>{icon('notes', 12)}</Tooltip>}
        </>
      }
    />
  );
};

export default DealDetailLink;
