import { z } from 'zod';
import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import workspaceAccountVNCSchema from '../schemas/workspaceAccountVNCSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Payload = WorkspaceAccountSchema['id'][];

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
  params?: URLParams;
}

const loadFilteredWorkspaceAccountsVNC = async (args: Args) => {
  const { workspaceId, payload, params } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/accounts-vnc-filtered`,
    payload,
    { params }
  );

  return z.object({ accounts: z.array(workspaceAccountVNCSchema) }).parse(response.data);
};

export default loadFilteredWorkspaceAccountsVNC;
