import { useMutation } from '@tanstack/react-query';
import {
  closeWorkspaceAccountTable,
  closeWorkspaceAccountTablesPokerRoom,
} from '../../api/workspace-account-table';
import { t } from '../../i18n';
import notify from '../../utils/notify';

export const useCloseWorkspaceAccountTablesPokerRoomMutation = () =>
  useMutation({
    mutationFn: closeWorkspaceAccountTablesPokerRoom,
    onSuccess: () => notify('success', { title: String(t('sentences.command_sended')) }),
  });

export const useCloseWorkspaceAccountTableMutation = () =>
  useMutation({
    mutationFn: closeWorkspaceAccountTable,
    onSuccess: () => notify('success', { title: String(t('sentences.command_sended')) }),
  });
