import { useTranslation } from 'react-i18next';
import useBreakpoints from '../../../hooks/useBreakpoints';
import icon from '../../../static/icons';
import Button from '../../Button';
import { useDatePickerContext } from '../DatePickerContext';
import styles from './styles.module.scss';

const DatePickerFooter = () => {
  const { t } = useTranslation();
  const { isDesktop, isTablet } = useBreakpoints();
  const { picker, clearValue, setPicker, closeDatePicker } = useDatePickerContext();

  const handleCancel = () => {
    clearValue();
    closeDatePicker();
  };

  const goBack = () => {
    if (picker === 'month') return setPicker('day');
    if (picker === 'year') return setPicker('month');

    return undefined;
  };

  return (
    <div className={styles.container}>
      {picker === 'day' && (
        <Button size={isDesktop || isTablet ? 'small' : 'medium'} onClick={closeDatePicker}>
          {t('common.set_date')}
        </Button>
      )}
      {(picker === 'month' || picker === 'year') && (
        <Button
          variant="secondary"
          size={isDesktop || isTablet ? 'small' : 'medium'}
          icon={icon('chevronLeft', 16)}
          onClick={goBack}
        >
          {picker === 'month' ? t('common.days') : t('common.months')}
        </Button>
      )}
      {(isDesktop || isTablet) && (
        <Button variant="light" onClick={handleCancel}>
          {t('common.cancel')}
        </Button>
      )}
    </div>
  );
};

export default DatePickerFooter;
