import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import Dropdown, { Options } from '../../../../components/Dropdown';
import icon from '../../../../static/icons';
import styles from './styles.module.scss';

interface Props {
  options: Options;
}

const WorkspaceAccountFormAdd = (props: Props) => {
  const { options } = props;

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Dropdown options={options} className={styles.dropdown}>
        {() => (
          <Button variant="light" icon={icon('add', 16)}>
            {t('common.field')}
          </Button>
        )}
      </Dropdown>
    </div>
  );
};

export default WorkspaceAccountFormAdd;
