import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { WorkspaceSessionSchema } from '../schemas/session/workspaceSessionSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = WorkspaceSessionSchema;

interface Payload {
  account_ids: Schema['account']['id'][];
  start: Schema['start'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
  params?: URLParams;
}

const bulkDeleteWorkspaceAccountPlannedSessions = async (args: Args) => {
  const { workspaceId, payload, params } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/sessions/delete-from`,
    payload,
    { params }
  );

  /* return emptyResponseSchema.parse(response.data); */
  /* TODO: схема должна быть пустой */
  return response.data;
};

export default bulkDeleteWorkspaceAccountPlannedSessions;
