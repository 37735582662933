import clsx from 'clsx';
import { ReactNode, useCallback, useState } from 'react';
import useScrolled from '../../hooks/useScrolled';
import FilterStub from '../FilterStub';
import PageSearch from '../PageSearch';
import ScrollTop from '../ScrollTop';
import { PageProvider, usePageContext } from './PageContext';
import PageHeader from './PageHeader';
import styles from './styles.module.scss';
import { Props } from './types';

interface FilterProps {
  children: ReactNode;
}

const Filter = (props: FilterProps) => {
  const { children } = props;

  const { isFilterVisible } = usePageContext();

  if (isFilterVisible) return <>{children}</>;

  return null;
};

const Page = (props: Props) => {
  const {
    heading,
    extra,
    addon,
    tabs,
    widget,
    search = true,
    goToTop = true,
    sidebar = true,
    children,
  } = props;
  let { filter } = props;

  const { scrolled, onScroll } = useScrolled();
  const [element, setElement] = useState<HTMLDivElement | null>(null);

  const callbackRef = useCallback((node: HTMLDivElement) => setElement(node), [setElement]);

  const getFilter = () => {
    if (filter === false) return null;
    if (!filter) return <FilterStub />;

    return filter;
  };

  filter = getFilter();

  return (
    <PageProvider hasTabs={Boolean(tabs)}>
      <div className={clsx(styles.container, sidebar && styles.withSidebar)}>
        <PageHeader
          heading={heading}
          tabs={tabs}
          widget={widget}
          scrolled={scrolled}
          hasAddon={!filter && !addon}
        />
        <div className={styles.wrapper}>
          <div ref={callbackRef} className={styles.content} onScroll={onScroll}>
            {(search || extra) && (
              <div className={clsx(styles.controls, !search && styles.withoutSearch)}>
                {search && (
                  <div className={styles.search}>
                    <PageSearch />
                  </div>
                )}
                {extra}
              </div>
            )}
            {children}
            {goToTop && <ScrollTop element={element} />}
          </div>
          <Filter>{filter}</Filter>
          {addon}
        </div>
      </div>
    </PageProvider>
  );
};

export default Page;
