import { useQuery, keepPreviousData } from '@tanstack/react-query';
import {
  loadWorkspaceAccount,
  loadWorkspaceAccountsFilterValues,
  loadWorkspaceAccountsStages,
} from '../../api';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceAccountTableSchema } from '../../api/schemas/workspaceAccountTableSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { loadWorkspaceAccountTables } from '../../api/workspace-account-table';
import queryKeys from '../../config/queryKeys';
import useURL from '../../hooks/useURL';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { mutateDetailQueryData, updateDetailQueryData } from '../../utils/queryClient';
import { AccountMetrics, TableMetrics } from '../account/types';
import useCurrentStage from './useCurrentStage';
import { useWorkspaceAccountsContext } from './WorkspaceAccountsContext';

export const useWorkspaceAccountQuery = (args: {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
}) => {
  const { workspaceId, accountId } = args;

  const queryKey = [queryKeys.workspaceAccount({ workspaceId, accountId })];
  const queryFn = () => loadWorkspaceAccount({ workspaceId, accountId });

  useWebSocketMessage<WorkspaceAccountSchema>({
    domain: 'Account',
    onMessage: (message) => {
      if (
        message.payload.id === accountId &&
        (message.action === 'Edited' ||
          message.action === 'Deleted' ||
          message.action === 'AccountIsOnline' ||
          message.action === 'AccountIsOffline' ||
          message.action === 'BalanceChanged')
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  return useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });
};

export const useWorkspaceAccountsStagesQuery = (args: { workspaceId: WorkspaceSchema['id'] }) => {
  const { workspaceId } = args;

  const queryKey = [queryKeys.workspaceAccountsStages({ workspaceId })];
  const queryFn = () => loadWorkspaceAccountsStages({ workspaceId }, { size: '100' });

  return {
    queryKey,
    ...useQuery({
      queryKey,
      queryFn,
      staleTime: Infinity,
      meta: { ignoredStatuses: [403] },
    }),
  };
};

export const useWorkspaceAccountsFilterValuesQuery = (args: {
  workspaceId: WorkspaceSchema['id'];
}) => {
  const { workspaceId } = args;

  const { urlParams } = useURL();
  const { currentStage } = useCurrentStage();
  const { displayAll } = useWorkspaceAccountsContext();

  const queryKey = [
    queryKeys.workspaceAccountsFilterValues({ workspaceId }),
    String(currentStage),
    displayAll,
    urlParams.on_verification,
  ];

  const queryFn = () =>
    loadWorkspaceAccountsFilterValues(
      { workspaceId },
      {
        on_verification: urlParams.on_verification,
        ...(!displayAll && { stage_order_eq: String(currentStage) }),
      }
    );

  return useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });
};

export const useWorkspaceAccountTablesQuery = (args: {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
  enabled: boolean;
}) => {
  const { workspaceId, accountId, enabled } = args;

  const queryKey = [queryKeys.workspaceAccountTables({ workspaceId, accountId })];
  const queryFn = () => loadWorkspaceAccountTables({ workspaceId, accountId });

  useWebSocketMessage<AccountMetrics>({
    domain: 'AccountStats',
    onMessage: (message) => {
      if (message.payload.account_id === accountId) {
        if (message.action === 'Updated') {
          mutateDetailQueryData<WorkspaceAccountTableSchema>(queryKey, (prevData) => ({
            ...prevData,
            account: { ...prevData.account, metrics: message.payload.metrics },
          }));
        }
      }
    },
  });

  useWebSocketMessage<TableMetrics>({
    domain: 'AccountTableStats',
    onMessage: (message) => {
      if (message.payload.account_id === accountId) {
        if (message.action === 'Updated') {
          mutateDetailQueryData<WorkspaceAccountTableSchema>(queryKey, (prevData) => ({
            ...prevData,
            tables: prevData.tables.map((item) => {
              if (item.id === message.payload.table_id) {
                return { ...item, metrics: message.payload.metrics };
              }

              return item;
            }),
          }));
        }
      }
    },
  });

  return useQuery({
    queryKey,
    queryFn,
    enabled,
    meta: { ignoredStatuses: [403] },
  });
};
