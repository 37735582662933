import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { GlobalClubSchema } from '../schemas/globalClubSchema';
import { NoteSchema } from '../schemas/noteSchema';

interface Args {
  clubId: GlobalClubSchema['id'];
  noteId: NoteSchema['id'];
}

const deleteGlobalClubNote = async (args: Args) => {
  const { clubId, noteId } = args;

  const response = await api.delete(`/api/reference/club/${clubId}/notes/${noteId}`);

  return emptyResponseSchema.parse(response.data);
};

export default deleteGlobalClubNote;
