import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadWorkspace } from '../../../api';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Spinner from '../../../components/Spinner';
import queryKeys from '../../../config/queryKeys';
import styles from './styles.module.scss';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  children: (args: { data: WorkspaceSchema }) => ReactElement;
}

const WorkspaceSettingsLoader = (props: Props) => {
  const { workspaceId, children } = props;

  const queryKey = [queryKeys.workspace({ workspaceId })];
  const queryFn = () => loadWorkspace({ workspaceId });

  const { data, isPending } = useQuery({ queryKey, queryFn });

  if (isPending) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  if (!data) return null;

  return children({ data });
};

export default WorkspaceSettingsLoader;
