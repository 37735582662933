import { useTranslation } from 'react-i18next';
import { exportFilteredWorkspaceAccounts, exportWorkspaceAccounts } from '../../../api';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Button from '../../../components/Button';
import Dialog from '../../../components/Dialog';
import Tooltip from '../../../components/Tooltip';
import icon from '../../../static/icons';
import { downloadFile } from '../../../utils/files';
import useBulkActions from '../useBulkActions';
import useStages from '../useStages';
import styles from './styles.module.scss';
import WorkspaceAccountExportToCSVDialogForm from './WorkspaceAccountExportToCSVDialogForm';

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceAccountExportToCSVDialog = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { ids, params, selectAllMode, disabled, handle } = useBulkActions();
  const { query, stage } = useStages({ workspaceId });

  const { isPending } = query;

  return (
    <Dialog
      secondary
      className={styles.dialog}
      label={({ referenceProps }) => (
        <Tooltip label={t('common.export_to_CSV')}>
          <Button
            variant="transparent"
            icon={icon('download', 16)}
            loading={isPending}
            data-testid="export"
            disabled={disabled}
            {...referenceProps}
          />
        </Tooltip>
      )}
    >
      {({ close }) => (
        <>
          <Dialog.Top>
            <Dialog.Title subTitle={t('sentences.export_accounts')}>
              {t('common.export_to_CSV')}
            </Dialog.Title>
          </Dialog.Top>
          {stage && (
            <WorkspaceAccountExportToCSVDialogForm
              data={stage}
              onSubmit={(values) =>
                handle(async () => {
                  const fn = selectAllMode
                    ? exportFilteredWorkspaceAccounts
                    : exportWorkspaceAccounts;

                  const response = await fn({
                    workspaceId,
                    payload: {
                      account_ids: ids,
                      columns: values.columns,
                    },
                    params,
                  });

                  downloadFile(response, 'accounts.csv');
                  close();
                })
              }
            />
          )}
        </>
      )}
    </Dialog>
  );
};

export default WorkspaceAccountExportToCSVDialog;
