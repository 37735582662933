import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { IdentitySchema } from '../schemas/identitySchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = IdentitySchema;

interface Document {
  type: Schema['documents'][number]['type'];
  issued: Schema['documents'][number]['issued'];
  expire: Schema['documents'][number]['expire'] | null;
  file_ids: Schema['documents'][number]['files'][number]['id'][];
}

interface Photo {
  type: Schema['photos'][number]['type'];
  file_ids: Schema['photos'][number]['files'][number]['id'][];
}

type Documents = Document[];
type Photos = Photo[];

interface Payload {
  minimal_rental_period?: Schema['minimal_rental_period'] | null;
  monthly_cost?: Schema['monthly_cost'] | null;
  rooms: Schema['rooms'];
  first_name?: Schema['first_name'];
  last_name?: Schema['last_name'];
  gender?: Schema['gender'];
  date_of_birth?: Schema['date_of_birth'];
  location?: Schema['location'];
  channel?: Schema['channel'];
  online_wallets?: Schema['online_wallets'];
  bank_accounts?: Schema['bank_accounts'];
  phones?: Schema['phones'];
  documents?: Documents;
  photos?: Photos;
  social_networks?: Schema['social_networks'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  identityId: IdentitySchema['id'];
  payload: Payload;
}

const updateWorkspaceIdentity = async (args: Args) => {
  const { workspaceId, identityId, payload } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/identity/${identityId}`,
    payload
  );

  return emptyResponseSchema.parse(response.data);
};

export default updateWorkspaceIdentity;
