import api from '../../services/api';
import globalRoleSchema, { GlobalRoleSchema } from '../schemas/globalRoleSchema';

interface Args {
  roleId: GlobalRoleSchema['id'];
}

const loadGlobalRole = async (args: Args) => {
  const { roleId } = args;

  const response = await api.get(`/api/role/${roleId}`);

  return globalRoleSchema.parse(response.data);
};

export default loadGlobalRole;
