import { z } from 'zod';
import api from '../services/api';
import URLParams from '../types/URLParams';
import geonamesCountrySchema from './schemas/geonamesCountrySchema';

const geonamesSearchCountry = async (params?: URLParams) => {
  const response = await api.get('/api/geonames/country', { params });

  return z
    .object({ geonames: z.array(geonamesCountrySchema), total: z.number() })
    .parse(response.data);
};

export default geonamesSearchCountry;
