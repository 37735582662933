import { ReactElement } from 'react';
import { deleteGlobalClubNote, loadGlobalClubNotes } from '../../../api';
import { GlobalClubSchema } from '../../../api/schemas/globalClubSchema';
import { Props as NotesProps } from '../../../components/Notes';
import queryKeys from '../../../config/queryKeys';
import usePaginatedDataNew from '../../../hooks/usePaginatedDataNew';
import { useCreateGlobalClubNoteMutation, useUpdateGlobalClubNoteMutation } from './mutations';

interface Props {
  clubId: GlobalClubSchema['id'];
  children: (args: NotesProps) => ReactElement;
}

const GlobalClubNotes = (props: Props) => {
  const { clubId, children } = props;

  const queryKey = [queryKeys.globalClubNotes({ clubId })];

  const query = usePaginatedDataNew({
    queryKey,
    queryFn: (params) => loadGlobalClubNotes({ clubId }, { ...params, order_by: 'desc' }),
  });

  const createNote = useCreateGlobalClubNoteMutation();
  const updateNote = useUpdateGlobalClubNoteMutation();

  return children({
    query,
    creating: createNote.isPending,
    updating: updateNote.isPending,
    onCreate: ({ payload }) => createNote.mutateAsync({ clubId, payload }),
    onUpdate: ({ noteId, payload }) => updateNote.mutateAsync({ clubId, noteId, payload }),
    onDelete: ({ noteId }) => deleteGlobalClubNote({ clubId, noteId }),
    onCreateMessage: (message) => message.payload.instance.id === clubId,
  });
};

export default GlobalClubNotes;
