import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { GlobalSuperLeagueSchema } from '../schemas/globalSuperLeagueSchema';

type Schema = GlobalSuperLeagueSchema;

interface Payload {
  name?: Schema['name'];
  extra?: Schema['extra'];
}

interface Args {
  superLeagueId: Schema['id'];
  payload: Payload;
}

const updateGlobalSuperLeague = async (args: Args) => {
  const { superLeagueId, payload } = args;

  const response = await api.patch(`/api/reference/super-league/${superLeagueId}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateGlobalSuperLeague;
