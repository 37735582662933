import { Payload } from '../../components/Notes';
import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { NoteSchema } from '../schemas/noteSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
  noteId: NoteSchema['id'];
  payload: Payload;
}

const updateWorkspaceAccountNote = async (args: Args) => {
  const { workspaceId, accountId, noteId, payload } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/${accountId}/notes/${noteId}`,
    payload
  );

  return emptyResponseSchema.parse(response.data);
};

export default updateWorkspaceAccountNote;
