import api from '../../services/api';
import { GlobalAccountSchema } from '../schemas/account/globalAccountSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { GlobalSessionSchema } from '../schemas/session/globalSessionSchema';

type Schema = GlobalSessionSchema;

interface Args {
  accountId: GlobalAccountSchema['id'];
  sessionId: Schema['id'];
}

const deleteGlobalAccountSession = async (args: Args) => {
  const { accountId, sessionId } = args;

  const response = await api.delete(`/api/account/${accountId}/session/${sessionId}`);

  return emptyResponseSchema.parse(response.data);
};

export default deleteGlobalAccountSession;
