import clsx from 'clsx';
import { ReactNode } from 'react';
import SelectAsync from './SelectAsync';
import SelectAsyncV2 from './SelectAsyncV2';
import { SelectProvider } from './SelectContext';
import SelectField from './SelectField';
import SelectMulti from './SelectMulti';
import SelectOptions from './SelectOptions';
import SelectQuick from './SelectQuick';
import styles from './styles.module.scss';
import { Props as SelectProps, Option, OptionValue } from './types';

interface Props<T extends OptionValue = string> extends SelectProps<T> {
  value: Option<T>;
  onChange: (option: Option<T>) => void;
  renderValue?: (option: Option<T>) => ReactNode;
}

const Select = <T extends OptionValue = string>(props: Props<T>) => {
  const {
    value,
    options = [],
    addon,
    className,
    quick,
    children,
    onChange,
    renderValue,
    ...rest
  } = props;

  const selectedIndex = options.findIndex((option) => option.value === value?.value);
  const selected = value ? [value.value] : [];

  const onClear = () => onChange(null);

  // удаление опций на backspace
  // возможность получить остальные данные помимо value и label (аналог action или meta из react-select)
  // навигация с клавиатуры должна начинаться с выбранной опции
  // при выборе последней опции через Enter нужно менять activeIndex
  // разбить на компоненты
  // isValidNewOption
  // если clearable === true, то тип option в onChange не может быть null?
  // onBlur не прокидывается из пропсов и не обрабатывается
  // name не прокидывается из пропсов и не обрабатывается
  // search as T
  // подумать как сверстать renderValue с ошибкой чтобы не прокидывать children в input
  // getOptions для асинхронной загрузки
  // creatableText Invite new user
  // навигация клавиатурой не должна снимать фокус с инпута, при этом стили фокуса на опциях должны быть только от клавиатуры
  // loadingMore в SelectAsync (если isFetchingMore совмещать с loading то в creatable селекте будет инфинити-луп */)

  return (
    <SelectProvider
      quick={quick}
      options={options}
      selected={selected}
      selectedIndex={selectedIndex}
      {...rest}
    >
      <div
        {...(quick && { 'data-quick': true })}
        className={clsx(styles.container, children && styles.withChildren, className)}
      >
        {children || (
          <SelectField addon={addon} onClear={onClear}>
            {renderValue ? renderValue(value) : value?.label}
          </SelectField>
        )}
        <SelectOptions onChange={onChange} />
      </div>
    </SelectProvider>
  );
};

Select.Async = SelectAsync;
Select.AsyncV2 = SelectAsyncV2;
Select.Multi = SelectMulti;
Select.Quick = SelectQuick;

export default Select;
