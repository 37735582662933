import Card from '../../../components/Card';
import icon from '../../../static/icons';
import styles from './styles.module.scss';

const cards = [
  {
    heading: 'Default state',
    label: 'John Doe',
    gender: 'Male',
    age: '33',
    highlighted: false,
    warning: false,
    danger: false,
  },
  {
    heading: 'Highlighted state',
    label: 'John Doe',
    gender: 'Male',
    age: '33',
    highlighted: true,
    warning: false,
    danger: false,
  },
  {
    heading: 'Warning state',
    label: 'John Doe',
    gender: 'Male',
    age: '33',
    highlighted: false,
    warning: true,
    danger: false,
  },
  {
    heading: 'Danger state',
    label: 'John Doe',
    gender: 'Male',
    age: '33',
    highlighted: false,
    warning: false,
    danger: true,
  },
];

const StorybookPageCard = () => (
  <>
    {cards.map((item, index) => (
      <div key={index} className={styles.card}>
        <h2>{item.heading}</h2>
        <Card highlighted={item.highlighted} warning={item.warning} danger={item.danger}>
          <Card.Top>
            <div className={styles.label}>{item.label}</div>
            <Card.Top.Controls>
              <div className={styles.control}>{icon('moreVertical', 16)}</div>
            </Card.Top.Controls>
          </Card.Top>
          <Card.Field.Group>
            <Card.Field label="Gender">{item.gender}</Card.Field>
            <Card.Field label="Age">{item.age}</Card.Field>
          </Card.Field.Group>
        </Card>
      </div>
    ))}
  </>
);

export default StorybookPageCard;
