import { useTranslation } from 'react-i18next';
import { GlobalUserSchema } from '../../../api/schemas/globalUserSchema';
import PageDetailLink from '../../../components/PageDetailLink';
import Tooltip from '../../../components/Tooltip';
import routes from '../../../config/routes';
import icon from '../../../static/icons';

interface Props {
  data: GlobalUserSchema;
}

const GlobalUserDetailLink = (props: Props) => {
  const { data } = props;

  const { id, username, verified } = data;

  const { t } = useTranslation();

  return (
    <PageDetailLink
      label={username}
      to={routes.globalUser({ userId: id })}
      {...(!verified && {
        extra: <Tooltip label={t('common.invite_not_accepted')}>{icon('clock', 12)}</Tooltip>,
      })}
    />
  );
};

export default GlobalUserDetailLink;
