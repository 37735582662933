import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Collapse from '../../components/Collapse';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import { maxLength, required } from '../../utils/form';
import { Fields } from './types';
import WorkspaceIdentityFormCollapse from './WorkspaceIdentityFormCollapse';

const WorkspaceIdentityFormPhones = () => {
  const { t } = useTranslation();

  const {
    watch,
    control,
    register,
    formState: { errors },
  } = useFormContext<Fields>();

  const { fields, append, remove } = useFieldArray({ control, name: 'phones' });

  return (
    <PageAddon.DynamicFields label={t('common.phones')} onAppend={() => append({ phone: '' })}>
      {fields.map((dynamicField, index) => {
        const { phone } = watch(`phones.${index}`);

        return (
          <WorkspaceIdentityFormCollapse
            key={dynamicField.id}
            onDelete={() => remove(index)}
            defaultOpen={!dynamicField.phone}
            label={phone || `${t('common.phone')} ${index + 1}`}
            error={errors.phones ? Boolean(errors.phones[index]) : false}
          >
            <Collapse.FieldGroup>
              <Input
                label={`${t('common.phone_number')}*`}
                {...register(`phones.${index}.phone`, {
                  validate: { required, maxLength: maxLength() },
                })}
                {...(errors.phones && { error: errors.phones[index]?.phone?.message })}
              />
            </Collapse.FieldGroup>
          </WorkspaceIdentityFormCollapse>
        );
      })}
    </PageAddon.DynamicFields>
  );
};

export default WorkspaceIdentityFormPhones;
