import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Link from '../../components/Link';
import Page from '../../components/Page';
import routes from '../../config/routes';
import { useUserStore } from '../../store/user';
import { addWhen } from '../../utils';
import { checkIsAccountsVisible, checkIsSeatControlRulesVisible } from '../../utils/workspace';
import styles from './styles.module.scss';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: WorkspaceSchema;
}

const WorkspaceSettingsPage = (props: Props) => {
  const { workspaceId, data } = props;

  const { hands_billing } = data;

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const user = useUserStore();

  const owner = user.checkOwner(workspaceId);

  const links = [
    {
      to: routes.workspaceSettings({ workspaceId }),
      label: t('common.general'),
    },
    ...addWhen(
      {
        to: routes.workspaceSettingsTokens({ workspaceId }),
        label: t('common.api_tokens'),
      },
      owner
    ),
    ...addWhen(
      {
        to: routes.workspaceSettingsRules({ workspaceId }),
        label: t('common.seating_rules'),
      },
      checkIsSeatControlRulesVisible(workspaceId)
    ),
    {
      to: routes.workspaceSettingsStrategyProfile({ workspaceId }),
      label: t('common.strategy'),
    },
    ...addWhen(
      { to: routes.workspaceSettingsTags({ workspaceId }), label: t('common.tags') },
      checkIsAccountsVisible(workspaceId)
    ),
    ...addWhen(
      {
        to: routes.workspaceSettingsWallets({ workspaceId }),
        label: t('common.wallets'),
      },
      hands_billing
    ),
  ];

  return (
    <Page filter={false} search={false} heading={t('common.settings')}>
      <div className={styles.container}>
        <div className={styles.nav}>
          {links.map((item) => (
            <Link
              key={item.to}
              to={item.to}
              {...(pathname === item.to && { className: styles.active })}
            >
              {item.label}
            </Link>
          ))}
        </div>
        <div className={styles.wrapper}>
          <Outlet />
        </div>
      </div>
    </Page>
  );
};

export default WorkspaceSettingsPage;
