import { PHOTO_TYPE } from '../../../api/schemas/identity';
import { IdentitySchema } from '../../../api/schemas/identitySchema';
import { MarketIdentitySchema } from '../../../api/schemas/marketIdentitySchema';
import Tooltip from '../../../components/Tooltip';
import icon from '../../../static/icons';
import { PHOTOS_LABELS, PHOTOS_NEGATIVE_LABELS, styleIcon } from '../helpers';
import styles from './styles.module.scss';

type Photos = IdentitySchema['photos'][number]['type'][];
type MarketPhotos = MarketIdentitySchema['photos'];

interface Props {
  photos: Photos | MarketPhotos;
}

const WorkspaceIdentityPhotos = (props: Props) => {
  const { photos } = props;

  const allTypes = Object.values(PHOTO_TYPE);
  const currentTypes = photos.map((item) => item);

  return (
    <div className={styles.container}>
      {allTypes.map((item) => {
        const isPhotoLoad = currentTypes.includes(item);

        return item ? (
          <Tooltip
            key={item}
            label={isPhotoLoad ? PHOTOS_LABELS[item] : PHOTOS_NEGATIVE_LABELS[item]}
          >
            {isPhotoLoad ? styleIcon(icon('imageWithMan', 16)) : icon('imageWithManCrossed', 16)}
          </Tooltip>
        ) : null;
      })}
    </div>
  );
};

export default WorkspaceIdentityPhotos;
