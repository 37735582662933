import { useTranslation } from 'react-i18next';
import { loadGlobalAccountTransactions } from '../../../api';
import { GlobalAccountSchema } from '../../../api/schemas/account/globalAccountSchema';
import PageAddon from '../../../components/PageAddon';
import queryKeys from '../../../config/queryKeys';
import usePaginatedDataNew from '../../../hooks/usePaginatedDataNew';
import { can } from '../../../utils/permissions';
import AccountTransactions from '../../account/AccountTransactions';
import useAccountTransactionsFilter from '../../account/AccountTransactions/useAccountTransactionsFilter';
import useAccountTransactionMessage from '../useAccountTransactionMessage';

interface Props {
  accountId: GlobalAccountSchema['id'];
  onClose: () => void;
}

const GlobalAccountTransactions = (props: Props) => {
  const { accountId, onClose } = props;

  const { t } = useTranslation();

  const filter = useAccountTransactionsFilter();

  const { urlParams } = filter;

  const queryKey = [queryKeys.globalAccountTransactions({ accountId }), urlParams];

  const { data, loading, loadingMore, checkIsInView } = usePaginatedDataNew({
    queryKey,
    queryFn: (params) => loadGlobalAccountTransactions({ accountId }, { ...params, ...urlParams }),
  });

  useAccountTransactionMessage({
    domain: 'GlobalAccountTransaction',
    accountId,
    queryKey,
    urlParams,
  });

  return (
    <PageAddon onClose={onClose} title={t('common.transactions')}>
      <AccountTransactions
        data={data}
        filter={filter}
        loading={loading}
        loadingMore={loadingMore}
        checkIsInView={checkIsInView}
        showMedia={can.account.balanceRead}
      />
    </PageAddon>
  );
};

export default GlobalAccountTransactions;
