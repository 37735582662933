import api from '../../services/api';
import { GlobalAccountSchema } from '../schemas/account/globalAccountSchema';
import accountRoomConfigSchema from '../schemas/accountRoomConfigSchema';

interface Args {
  accountId: GlobalAccountSchema['id'];
}

const loadGlobalAccountRoomConfig = async (args: Args) => {
  const { accountId } = args;

  const response = await api.get(`/api/account/${accountId}/room-customize`);

  return accountRoomConfigSchema.parse(response.data);
};

export default loadGlobalAccountRoomConfig;
