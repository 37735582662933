import { z } from 'zod';
import api from '../../services/api';
import globalUserSchema, { GlobalUserSchema } from '../schemas/globalUserSchema';

type Schema = GlobalUserSchema;

type Payload = {
  email: Schema['username'];
  role_ids?: Schema['roles'][number]['id'][];
  maintenance_types?: Schema['maintenances'][number]['maintenance_types'];
}[];

interface Args {
  payload: Payload;
}

const inviteGlobalUsers = async (args: Args) => {
  const { payload } = args;

  const response = await api.post('/api/invite-users', payload);

  return z.array(globalUserSchema.pick({ id: true })).parse(response.data);
};

export default inviteGlobalUsers;
