import { ReactNode } from 'react';
import Placeholder from '../../../Placeholder';
import PageAddonCollapseFieldCopy from './PageAddonCollapseFieldCopy';
import styles from './styles.module.scss';

interface Props {
  label: ReactNode;
  children: ReactNode;
  loading?: boolean;
}

const PageAddonCollapseField = (props: Props) => {
  const { label, children, loading } = props;

  const renderContent = () => {
    if (loading) return <Placeholder className={styles.placeholder} />;

    return <div className={styles.content}>{children}</div>;
  };

  return (
    <div className={styles.container}>
      {label !== false && <div className={styles.label}>{label}</div>}
      {renderContent()}
    </div>
  );
};

PageAddonCollapseField.Copy = PageAddonCollapseFieldCopy;

export default PageAddonCollapseField;
