import { z } from 'zod';

export const TRAINER_TECH = {
  UNKNOWN: 0,
  PARTYPOKER_DESKTOP: 1,
  PPPPOKER_HEADLESS: 2,
  PPPPOKER_DESKTOP: 3,
  POKERRRR2_HEADLESS: 4,
  POKERRRR2_DROP_MANUAL: 5,
  RULER_HEADLESS: 6,
  CLUBGG_DESKTOP: 7,
} as const;

const trainerTechSchema = z.nativeEnum(TRAINER_TECH);

export type TrainerTechSchema = z.infer<typeof trainerTechSchema>;

export default trainerTechSchema;
