import api from '../../services/api';
import { CurrentUserSchema } from '../schemas/currentUserSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';

interface Payload {
  username: CurrentUserSchema['username'];
}

interface Args {
  payload: Payload;
}

const sendRecoveryPassword = async (args: Args) => {
  const { payload } = args;

  const response = await api.post('/api/recovery-password', payload);

  return emptyResponseSchema.parse(response.data);
};

export default sendRecoveryPassword;
