import { useTranslation } from 'react-i18next';
import { WorkspaceSchema } from '../../../../api/schemas/workspaceSchema';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import Tooltip from '../../../../components/Tooltip';
import icon from '../../../../static/icons';
import { Identity, MAX_PERIOD, useCartStore } from '../../../../store/cart';
import { numbers } from '../../../../utils/validations';
import styles from './styles.module.scss';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  identity: Identity;
}

const WorkspaceMarketIdentityCartMonthInput = (props: Props) => {
  const { workspaceId, identity } = props;

  const { t } = useTranslation();

  const { increment, decrement, change } = useCartStore((state) => ({
    increment: state.increment,
    decrement: state.decrement,
    change: state.change,
  }));

  const { data, period } = identity;

  const { minimal_rental_period } = data;

  return (
    <div className={styles.container}>
      <Button
        icon={icon('minus', 16)}
        size="small"
        variant="transparent"
        disabled={period <= minimal_rental_period}
        onClick={() => decrement(workspaceId, data, minimal_rental_period)}
      />
      <div className={styles.wrapper}>
        <Tooltip label={t('common.month')}>
          <Input
            size="small"
            className={styles.input}
            value={String(period)}
            allow={numbers}
            {...(period < minimal_rental_period && {
              error: t('sentences.value_less_than_min_rent'),
            })}
            onChange={(event) => change(workspaceId, data, Number(event.target.value))}
          />
        </Tooltip>
      </div>
      <Button
        icon={icon('add', 16)}
        size="small"
        variant="transparent"
        disabled={period >= MAX_PERIOD}
        onClick={() => increment(workspaceId, data)}
      />
    </div>
  );
};

export default WorkspaceMarketIdentityCartMonthInput;
