import { useTranslation } from 'react-i18next';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageCreateButton from '../../components/PageCreateButton';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceChannelCreateButton = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { navigate } = useURL();

  const create = () => navigate(routes.workspaceChannelsCreate({ workspaceId }));
  const importFromCSV = () => navigate(routes.workspaceChannelsImport({ workspaceId }));

  return (
    <PageCreateButton
      onClick={create}
      more={[
        {
          icon: icon('upload', 20),
          label: t('common.bulk_import_from_csv'),
          onClick: importFromCSV,
        },
      ]}
    >
      {t('common.channel')}
    </PageCreateButton>
  );
};

export default WorkspaceChannelCreateButton;
