import api from '../../services/api';
import botSettingRuleSchema, { BotSettingRuleSchema } from '../schemas/botSettingRuleSchema';

interface Args {
  botSettingRuleId: BotSettingRuleSchema['id'];
}

const loadGlobalBotSettingRule = async (args: Args) => {
  const { botSettingRuleId } = args;

  const response = await api.get(`/api/bot-settings-usage-rule/${botSettingRuleId}`);

  return botSettingRuleSchema.parse(response.data);
};

export default loadGlobalBotSettingRule;
