import { useQuery } from '@tanstack/react-query';
import { loadWorkspaceBalance } from '../../../api';
import { WorkspaceBalanceSchema } from '../../../api/schemas/workspaceBalanceSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import queryKeys from '../../../config/queryKeys';
import useWebSocketMessage from '../../../hooks/useWebSocketMessage';
import { updateDetailQueryData } from '../../../utils/queryClient';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

export const useWorkspaceBalanceQuery = (args: Args) => {
  const { workspaceId } = args;

  const queryKey = [queryKeys.workspaceBalance({ workspaceId })];
  const queryFn = () => loadWorkspaceBalance({ workspaceId });

  useWebSocketMessage<WorkspaceBalanceSchema>({
    domain: 'Maintenance',
    onMessage: (message) => {
      if (message.action === 'BalanceState' && message.payload.id === workspaceId) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  return useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });
};
