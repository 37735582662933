import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
}

const CardFieldGroup = (props: Props) => {
  const { children } = props;

  return <div className={styles.container}>{children}</div>;
};

export default CardFieldGroup;
