import ReactQRCode from 'react-qr-code';

interface Props {
  value: string;
  size: number;
}

const QRCode = (props: Props) => {
  const { value, size } = props;

  return <ReactQRCode value={value} size={size} />;
};

export default QRCode;
