import { ReactElement } from 'react';
import { loadWorkspaceWallets } from '../../../api';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import { WorkspaceWalletSchema } from '../../../api/schemas/workspaceWalletSchema';
import queryKeys from '../../../config/queryKeys';
import usePaginatedData from '../../../hooks/usePaginatedData';
import useWebSocketMessage from '../../../hooks/useWebSocketMessage';
import {
  createInfinitePaginatedQueryData,
  deleteInfinitePaginatedQueryData,
  updateInfinitePaginatedQueryData,
} from '../../../utils/queryClient';
import WorkspaceSettingsSpinner from '../WorkspaceSettingsSpinner';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  children: (args: { data: WorkspaceWalletSchema[] }) => ReactElement;
}

const WorkspaceSettingsWalletsLoader = (props: Props) => {
  const { workspaceId, children } = props;

  const queryKey = [queryKeys.workspaceWallets({ workspaceId })];

  const query = usePaginatedData({
    queryKey,
    queryFn: (params) => loadWorkspaceWallets({ workspaceId }, { ...params, size: '100' }),
  });

  const { data, loading } = query;

  useWebSocketMessage<WorkspaceWalletSchema>({
    domain: 'Wallet',
    enabled: (message) => message.meta_info?.maintenance_id === workspaceId,
    onMessage: (message) => {
      const { payload } = message;

      if (message.action === 'Created') {
        createInfinitePaginatedQueryData(queryKey, payload, { append: true });
      }

      if (message.action === 'Edited') {
        updateInfinitePaginatedQueryData(
          queryKey,
          message.payload,
          (item) => item.id === payload.id
        );
      }

      if (message.action === 'Deleted') {
        deleteInfinitePaginatedQueryData(queryKey, (item) => item.id === payload.id);
      }
    },
  });

  if (loading) return <WorkspaceSettingsSpinner />;
  if (!data) return null;

  return children({ data });
};

export default WorkspaceSettingsWalletsLoader;
