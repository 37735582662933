import api from '../../services/api';
import URLParams from '../../types/URLParams';
import globalAccountSchema from '../schemas/account/globalAccountSchema';
import paginationSchema from '../schemas/paginationSchema';

const loadGlobalAccounts = async (params?: URLParams) => {
  const response = await api.get('/api/account', { params });

  return paginationSchema(globalAccountSchema).parse(response.data);
};

export default loadGlobalAccounts;
