import { WorkspaceClubMemberSchema } from '../../api/schemas/workspaceClubMemberSchema';
import PrettyNumber from '../../components/PrettyNumber';
import { convertCentsToDollars } from '../../utils/data';

interface Props {
  data: WorkspaceClubMemberSchema;
}

const WorkspaceClubMemberWinnings = (props: Props) => {
  const { data } = props;

  const { winnings } = data;

  return <PrettyNumber>{convertCentsToDollars(winnings)}</PrettyNumber>;
};

export default WorkspaceClubMemberWinnings;
