import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import orderSchema from './orderSchema';

export const STATUS = {
  UNKNOWN: 0,
  PROCESS: 1,
  DONE: 2,
} as const;

const orderTransactionSchema = z.object({
  amount: z.number(),
  created_on: dateTimeSchema,
  execute_on: dateTimeSchema,
  id: z.string(),
  is_fee: z.boolean(),
  order: orderSchema,
  status: z.nativeEnum(STATUS),
});

export type OrderTransactionSchema = z.infer<typeof orderTransactionSchema>;

export default orderTransactionSchema;
