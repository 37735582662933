import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadGlobalWorkspacesFilterValues } from '../../api';
import Filter from '../../components/Filter';
import queryKeys from '../../config/queryKeys';
import useFilter from '../../hooks/useFilter';
import useURL from '../../hooks/useURL';
import { TRAINER_POKER_ROOM_LABELS, TRAINER_TECH_LABELS } from '../../utils/trainer';
import { BUSINESS_MODEL_LABELS, WORKSPACE_TYPES_LABELS } from './helpers';

interface Fields {
  business_model_in: string[];
  maintenance_types_in: string[];
  trainer_tech_in: string[];
  poker_rooms_in: string[];
  owner_in: string[];
  updated_ge: string;
  updated_le: string;
  created_ge: string;
  created_le: string;
  show_deleted: boolean;
}

interface Props {
  total?: number;
}

const GlobalWorkspaceFilter = (props: Props) => {
  const { total } = props;

  const { t } = useTranslation();
  const { urlParams } = useURL();

  const queryKey = [queryKeys.globalWorkspacesFilterValues, urlParams.show_deleted];
  const queryFn = () => loadGlobalWorkspacesFilterValues({ show_deleted: urlParams.show_deleted });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  const filter = useFilter<Fields>({
    business_model_in: 'multi',
    maintenance_types_in: 'multi',
    trainer_tech_in: 'multi',
    poker_rooms_in: 'multi',
    owner_in: 'multi',
    updated_ge: 'single',
    updated_le: 'single',
    created_ge: 'single',
    created_le: 'single',
    show_deleted: 'toggle',
  });

  const { values, setValue, clearValue } = filter;

  const {
    business_model_in,
    maintenance_types_in,
    trainer_tech_in,
    poker_rooms_in,
    owner_in,
    updated_ge,
    updated_le,
    created_ge,
    created_le,
    show_deleted,
  } = values;

  const filterProps = { total, filter };

  if (isPending) {
    return (
      <Filter {...filterProps}>
        <Filter.Placeholder />
      </Filter>
    );
  }

  if (!data) return null;

  const { business_model, maintenance_types, trainer_tech, poker_rooms, owner } = data;

  return (
    <Filter {...filterProps}>
      <div>
        {business_model.length !== 0 && (
          <Filter.Collapse
            label={t('common.business_model')}
            count={business_model_in.length}
            onClear={clearValue('business_model_in')}
          >
            <Filter.MultiCheckbox
              config={business_model.map((item) => ({
                value: String(item),
                label: BUSINESS_MODEL_LABELS[item],
                onChange: setValue('business_model_in'),
                checked: business_model_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {maintenance_types.length !== 0 && (
          <Filter.Collapse
            label={t('common.type')}
            count={maintenance_types_in.length}
            onClear={clearValue('maintenance_types_in')}
          >
            <Filter.MultiCheckbox
              config={maintenance_types.map((item) => ({
                value: String(item),
                label: WORKSPACE_TYPES_LABELS[item],
                onChange: setValue('maintenance_types_in'),
                checked: maintenance_types_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {trainer_tech.length !== 0 && (
          <Filter.Collapse
            label={t('common.trainer_tech')}
            count={trainer_tech_in.length}
            onClear={clearValue('trainer_tech_in')}
          >
            <Filter.MultiCheckbox
              config={trainer_tech.map((item) => ({
                value: String(item),
                label: TRAINER_TECH_LABELS[item],
                onChange: setValue('trainer_tech_in'),
                checked: trainer_tech_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {poker_rooms.length !== 0 && (
          <Filter.Collapse
            label={t('common.poker_room')}
            count={poker_rooms_in.length}
            onClear={clearValue('poker_rooms_in')}
          >
            <Filter.MultiCheckbox
              config={poker_rooms.map((item) => ({
                value: String(item),
                label: TRAINER_POKER_ROOM_LABELS[item],
                onChange: setValue('poker_rooms_in'),
                checked: poker_rooms_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {owner.length !== 0 && (
          <Filter.Collapse
            label={t('common.owner')}
            count={owner_in.length}
            onClear={clearValue('owner_in')}
          >
            <Filter.MultiCheckbox
              config={owner.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('owner_in'),
                checked: owner_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        <Filter.Collapse
          label={t('common.modified')}
          {...((updated_ge || updated_le) && {
            onClear: clearValue(['updated_ge', 'updated_le']),
          })}
        >
          <Filter.Date
            from={{ value: updated_ge, onChange: setValue('updated_ge') }}
            to={{ value: updated_le, onChange: setValue('updated_le') }}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.created')}
          {...((created_ge || created_le) && {
            onClear: clearValue(['created_ge', 'created_le']),
          })}
        >
          <Filter.Date
            from={{ value: created_ge, onChange: setValue('created_ge') }}
            to={{ value: created_le, onChange: setValue('created_le') }}
          />
        </Filter.Collapse>
      </div>
      <Filter.Switcher checked={show_deleted} onChange={setValue('show_deleted')}>
        {t('common.show_deleted')}
      </Filter.Switcher>
    </Filter>
  );
};

export default GlobalWorkspaceFilter;
