import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  label: string;
  children: ReactNode;
}

const TooltipField = (props: Props) => {
  const { label, children } = props;

  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      <div className={styles.line} />
      {children}
    </div>
  );
};

export default TooltipField;
