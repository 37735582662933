import api from '../../services/api';
import globalExpenseSchema, { GlobalExpenseSchema } from '../schemas/globalExpenseSchema';

type Schema = GlobalExpenseSchema;

interface Payload {
  deal_id: Schema['deal']['id'];
  type: Schema['type'];
  amount: Schema['amount'];
  currency: Schema['currency'];
  reconcile: Schema['reconcile'];
  description: Schema['description'];
}

interface Args {
  payload: Payload;
}

const createGlobalExpense = async (args: Args) => {
  const { payload } = args;

  const response = await api.post('/api/expenses', payload);

  return globalExpenseSchema.pick({ id: true }).parse(response.data);
};

export default createGlobalExpense;
