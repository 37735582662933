import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createGlobalBotSetting, loadGlobalWorkspaces, updateGlobalBotSetting } from '../../../api';
import { BotSettingSchema } from '../../../api/schemas/botSettingSchema';
import Form from '../../../components/Form';
import Input from '../../../components/Input';
import PageAddon from '../../../components/PageAddon';
import Select, { Option } from '../../../components/Select';
import SubmitButton from '../../../components/SubmitButton';
import Textarea from '../../../components/Textarea';
import { required } from '../../../utils/form';
import notify from '../../../utils/notify';
import { TRAINER_GAME_TYPE_LABELS, trainerGameTypeOptions } from '../../../utils/trainer';
import styles from './styles.module.scss';

interface Fields {
  name: string;
  game_type: Option<BotSettingSchema['game_type']>;
  bot_code: string;
  bot_build: string;
  bot_build_straddle: string;
  bot_code_tight: string;
  bot_build_tight: string;
  maintenance_id: Option;
  params_size: string;
  params_size_hu: string;
  description: string;
}

interface CommonProps {
  onClose: () => void;
}

interface CreateProps {
  onCreate: (args: { botSettingId: BotSettingSchema['id'] }) => void;
  data?: never;
  onEdit?: never;
}

interface EditProps {
  data: BotSettingSchema;
  onEdit: () => void;
  onCreate?: never;
}

type Props = CommonProps & (CreateProps | EditProps);

const GlobalBotSettingForm = (props: Props) => {
  const { data, onClose, onCreate, onEdit } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: {
      name: data?.name || '',
      game_type: data
        ? { value: data.game_type, label: TRAINER_GAME_TYPE_LABELS[data.game_type] }
        : null,
      bot_code: data?.bot_code || '',
      bot_build: data?.bot_build || '',
      bot_build_straddle: data?.bot_build_straddle || '',
      bot_code_tight: data?.bot_code_tight || '',
      bot_build_tight: data?.bot_build_tight || '',
      maintenance_id: data?.maintenance
        ? { value: data.maintenance.id, label: data.maintenance.name }
        : null,
      params_size: data?.params_size || '',
      params_size_hu: data?.params_size_hu || '',
      description: data?.description || '',
    },
  });

  const {
    control,
    register,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    if (!values.game_type) throw new Error();

    const { game_type, maintenance_id, ...rest } = values;

    if (data) {
      await updateGlobalBotSetting({ botSettingId: data.id, payload: rest });

      onEdit();
    } else {
      const response = await createGlobalBotSetting({
        payload: {
          ...rest,
          game_type: game_type?.value,
          maintenance_id: maintenance_id?.value,
        },
      });

      onCreate({ botSettingId: response.id });
    }

    notify('success', {
      title: data ? t('sentences.changes_have_been_saved') : t('sentences.record_has_been_created'),
    });
  };

  return (
    <PageAddon
      onClose={onClose}
      title={data ? `${t('common.edit')} ${data.name}` : t('common.create_bot_settings')}
    >
      <Form form={form} onSubmit={onSubmit}>
        <Input
          label={`${t('common.name')}*`}
          error={errors.name?.message}
          {...register('name', { validate: required })}
        />
        {!data && (
          <Controller
            name="game_type"
            control={control}
            rules={{ validate: required }}
            render={({ field }) => (
              <Select
                clearable={false}
                label={`${t('common.game_type')}*`}
                options={trainerGameTypeOptions}
                value={field.value}
                onChange={field.onChange}
                error={errors.game_type?.message}
              />
            )}
          />
        )}
        <Input
          label={`${t('common.code')}*`}
          error={errors.bot_code?.message}
          {...register('bot_code', { validate: { required } })}
        />
        <Input
          label={`${t('common.build')}*`}
          error={errors.bot_build?.message}
          {...register('bot_build', { validate: { required } })}
        />
        <Input
          label={t('common.straddle')}
          error={errors.bot_build_straddle?.message}
          {...register('bot_build_straddle')}
        />
        <Input
          label={t('common.code_tight')}
          error={errors.bot_code_tight?.message}
          {...register('bot_code_tight')}
        />
        <Input
          label={t('common.build_tight')}
          error={errors.bot_build_tight?.message}
          {...register('bot_build_tight')}
        />
        {!data && (
          <Controller
            name="maintenance_id"
            control={control}
            render={({ field }) => (
              <Select.Async
                onLoad={async (args) => {
                  const response = await loadGlobalWorkspaces(args);

                  const options = response.items.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }));

                  return options;
                }}
              >
                {({ options, ...rest }) => (
                  <Select
                    label={t('common.workspace')}
                    options={options}
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.maintenance_id?.message}
                    {...rest}
                  />
                )}
              </Select.Async>
            )}
          />
        )}
        <Textarea
          label={t('common.params')}
          maxLength={0}
          className={styles.textarea}
          error={errors.params_size?.message}
          {...register('params_size')}
        />
        <Textarea
          label={t('common.params_hu')}
          maxLength={0}
          className={styles.textarea}
          error={errors.params_size_hu?.message}
          {...register('params_size_hu')}
        />
        <Textarea
          label={t('common.description')}
          maxLength={0}
          className={styles.textarea}
          error={errors.description?.message}
          {...register('description')}
        />
        <PageAddon.Controls>
          <SubmitButton>{data ? t('common.save') : t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default GlobalBotSettingForm;
