import { nanoid } from 'nanoid';
import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import paginationSchema from '../schemas/paginationSchema';
import workspaceDashboardAccountSchema, {
  WorkspaceDashboardAccountSchema,
} from '../schemas/workspace-dashboard/workspaceDashboardAccountSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceDashboardAccounts = async (args: Args, params?: URLParams<false>) => {
  const { workspaceId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/dashboard/accounts-by-club`,
    { params, paramsSerializer: { indexes: null } }
  );

  return paginationSchema(workspaceDashboardAccountSchema).parse({
    ...response.data,
    items: response.data.items.map((item: WorkspaceDashboardAccountSchema) => ({
      ...item,
      id: nanoid(),
    })),
  });
};

export default loadWorkspaceDashboardAccounts;
