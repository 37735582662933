import clsx from 'clsx';
import { ForwardedRef, forwardRef } from 'react';
import Id from '../../../types/Id';
import TableBodyCell from '../TableBodyCell';
import { Columns } from '../types';
import styles from './styles.module.scss';

interface Props<T> {
  data: T;
  columns: Columns<T>;
  active?: boolean;
  danger?: boolean;
  highlighted?: boolean;
  warning?: boolean;
}

const TableRow = forwardRef(<T extends Id>(props: Props<T>, ref: ForwardedRef<HTMLDivElement>) => {
  const { data, columns, active, danger, highlighted, warning, ...rest } = props;

  const className = clsx(styles.container, {
    [styles.highlighted]: highlighted,
    [styles.active]: active,
    [styles.danger]: danger,
    [styles.warning]: warning,
  });

  return (
    <div ref={ref} className={className} {...rest}>
      {columns.map((column, index) => (
        // @ts-ignore
        <TableBodyCell key={`${index}-${column.title}`} data={data} column={column} />
      ))}
    </div>
  );
});

export default TableRow;
