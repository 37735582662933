import useBulkActionsHook from '../../hooks/useBulkActions';
import useCurrentStage from './useCurrentStage';
import { useWorkspaceAccountsContext } from './WorkspaceAccountsContext';

const useBulkActions = () => {
  const { displayAll } = useWorkspaceAccountsContext();
  const { currentStage } = useCurrentStage();

  return useBulkActionsHook({
    params: { ...(!displayAll && { stage_order_eq: String(currentStage) }) },
  });
};

export default useBulkActions;
