import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BANK_ACCOUNT_TYPE } from '../../api/schemas/identity';
import Collapse from '../../components/Collapse';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import Select from '../../components/Select';
import { maxLength, required } from '../../utils/form';
import { BANK_ACCOUNTS_LABELS } from './helpers';
import { Fields } from './types';
import WorkspaceIdentityFormCollapse from './WorkspaceIdentityFormCollapse';

const options = [
  {
    value: BANK_ACCOUNT_TYPE.CHECKING,
    label: BANK_ACCOUNTS_LABELS[BANK_ACCOUNT_TYPE.CHECKING],
  },
  {
    value: BANK_ACCOUNT_TYPE.SAVING,
    label: BANK_ACCOUNTS_LABELS[BANK_ACCOUNT_TYPE.SAVING],
  },
  {
    value: BANK_ACCOUNT_TYPE.MONEY_MARKET,
    label: BANK_ACCOUNTS_LABELS[BANK_ACCOUNT_TYPE.MONEY_MARKET],
  },
  {
    value: BANK_ACCOUNT_TYPE.CERTIFICATE_OF_DEPOSIT,
    label: BANK_ACCOUNTS_LABELS[BANK_ACCOUNT_TYPE.CERTIFICATE_OF_DEPOSIT],
  },
  {
    value: BANK_ACCOUNT_TYPE.CARD,
    label: BANK_ACCOUNTS_LABELS[BANK_ACCOUNT_TYPE.CARD],
  },
];

const WorkspaceIdentityFormBankAccounts = () => {
  const { t } = useTranslation();

  const {
    watch,
    control,
    register,
    formState: { errors },
  } = useFormContext<Fields>();

  const { fields, append, remove } = useFieldArray({ control, name: 'bank_accounts' });

  return (
    <PageAddon.DynamicFields
      label={t('common.bank_accounts')}
      onAppend={() => append({ name: '', type: null, banking_digits: '', fullname: '' })}
    >
      {fields.map((dynamicField, index) => {
        const { name } = watch(`bank_accounts.${index}`);

        return (
          <WorkspaceIdentityFormCollapse
            key={dynamicField.id}
            onDelete={() => remove(index)}
            defaultOpen={!dynamicField.name}
            label={name || `${t('common.bank')} ${index + 1}`}
            error={errors.bank_accounts ? Boolean(errors.bank_accounts[index]) : false}
          >
            <Collapse.FieldGroup>
              <Input
                label={`${t('common.name')}*`}
                {...register(`bank_accounts.${index}.name`, {
                  validate: { required, maxLength: maxLength() },
                })}
                {...(errors.bank_accounts && {
                  error: errors.bank_accounts[index]?.name?.message,
                })}
              />
              <Controller
                control={control}
                name={`bank_accounts.${index}.type`}
                rules={{ validate: required }}
                render={({ field }) => (
                  <Select
                    clearable={false}
                    label={`${t('common.type')}*`}
                    options={options}
                    value={field.value}
                    onChange={field.onChange}
                    {...(errors.bank_accounts && {
                      // @ts-expect-error
                      error: errors.bank_accounts[index]?.type?.message,
                    })}
                  />
                )}
              />
              <Input
                label={`${t('common.banking_digits')}*`}
                {...register(`bank_accounts.${index}.banking_digits`, {
                  validate: { required, maxLength: maxLength() },
                })}
                {...(errors.bank_accounts && {
                  error: errors.bank_accounts[index]?.banking_digits?.message,
                })}
              />
              <Input
                label={`${t('common.full_name')}*`}
                {...register(`bank_accounts.${index}.fullname`, {
                  validate: { required, maxLength: maxLength() },
                })}
                {...(errors.bank_accounts && {
                  error: errors.bank_accounts[index]?.fullname?.message,
                })}
              />
            </Collapse.FieldGroup>
          </WorkspaceIdentityFormCollapse>
        );
      })}
    </PageAddon.DynamicFields>
  );
};

export default WorkspaceIdentityFormBankAccounts;
