import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceClubTransactionSchema } from '../schemas/workspaceClubTransactionSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  transactionId: WorkspaceClubTransactionSchema['id'];
}

const cancelWorkspaceClubTransaction = async (args: Args) => {
  const { workspaceId, transactionId } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/club-manager/transactions/${transactionId}/cancel`
  );

  return emptyResponseSchema.parse(response.data);
};

export default cancelWorkspaceClubTransaction;
