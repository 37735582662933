import api from '../../services/api';
import URLParams from '../../types/URLParams';
import globalUserSchema from '../schemas/globalUserSchema';
import paginationSchema from '../schemas/paginationSchema';

const loadGlobalUsers = async (params?: URLParams) => {
  const response = await api.get('/api/user', { params });

  return paginationSchema(globalUserSchema).parse(response.data);
};

export default loadGlobalUsers;
