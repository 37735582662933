import { useTranslation } from 'react-i18next';
import useFilter from '../../hooks/useFilter';
import Filter from '../Filter';
import Stub from '../Stub';
import styles from './styles.module.scss';

const FilterStub = () => {
  const { t } = useTranslation();

  const filter = useFilter({});

  return (
    <Filter filter={filter}>
      <div className={styles.container}>
        <Stub title={t('sentences.no_filtering_options_available')} />
      </div>
    </Filter>
  );
};

export default FilterStub;
