import Checkbox from '../../../components/Checkbox';
import styles from './styles.module.scss';

const StorybookPageCheckbox = () => {
  const labels = (
    <>
      <span>Default</span>
      <span>Disabled</span>
      <span>LTR</span>
      <span>RTL</span>
    </>
  );

  return (
    <>
      <div className={styles.container}>
        <h2>Checkbox</h2>
        {labels}
        <span>Off</span>
        <Checkbox />
        <Checkbox disabled />
        <Checkbox>Label</Checkbox>
        <Checkbox rtl>Label</Checkbox>
        <span>On</span>
        <Checkbox checked />
        <Checkbox checked disabled />
        <Checkbox checked>Label</Checkbox>
        <Checkbox checked rtl>
          Label
        </Checkbox>
        <span>Indeterminate</span>
        <Checkbox checked indeterminate />
        <Checkbox checked indeterminate disabled />
        <Checkbox checked indeterminate>
          Label
        </Checkbox>
        <Checkbox checked indeterminate rtl>
          Label
        </Checkbox>
      </div>
      <div className={styles.container}>
        <h2>Switch</h2>
        {labels}
        <span>Off</span>
        <Checkbox variant="switch" />
        <Checkbox variant="switch" disabled />
        <Checkbox variant="switch">Label</Checkbox>
        <Checkbox variant="switch" rtl>
          Label
        </Checkbox>
        <span>On</span>
        <Checkbox variant="switch" checked />
        <Checkbox variant="switch" checked disabled />
        <Checkbox variant="switch" checked>
          Label
        </Checkbox>
        <Checkbox variant="switch" checked rtl>
          Label
        </Checkbox>
      </div>
    </>
  );
};

export default StorybookPageCheckbox;
