import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Payload = WorkspaceAccountSchema['id'][];

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
  params?: URLParams;
}

const bulkSwitchOnFilteredWorkspaceAccountsPilot = async (args: Args) => {
  const { workspaceId, payload, params } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/accounts/bulk-switch-pilot-on-filtered`,
    payload,
    { params }
  );

  return emptyResponseSchema.parse(response.data);
};

export default bulkSwitchOnFilteredWorkspaceAccountsPilot;
