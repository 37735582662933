import { useTranslation } from 'react-i18next';
import useURL from '../../hooks/useURL';
import useURLFilter from '../../hooks/useURLFilter';
import icon from '../../static/icons';
import Button from '../Button';
import Dropdown from '../Dropdown';
import { Sorting } from '../Table/types';
import styles from './styles.module.scss';

interface Props {
  sorting: Sorting;
}

const PageSorting = (props: Props) => {
  const { sorting } = props;

  const { t } = useTranslation();

  const { urlParams } = useURL();
  const { setURLParams } = useURLFilter({ debounced: false });

  const { order_by, sort_by } = urlParams;

  const value = sorting.find((item) => item.key === sort_by);
  const defaultValue = sorting.find((item) => item.default);

  const defaultSorting = defaultValue?.key;
  const defaultOrder = defaultValue?.defaultOrder || 'asc';

  const options = sorting.map((item) => ({
    label: item.title,
    active: sort_by ? sort_by === item.key : defaultValue?.key === item.key,
    onClick: () => setURLParams({ sort_by: item.key, order_by: order_by || 'asc' }),
  }));

  const isAscOrder = order_by ? order_by === 'asc' : defaultOrder === 'asc';
  const isDescOrder = order_by ? order_by === 'desc' : defaultOrder === 'desc';

  if (sorting.every((item) => !item.default)) return null;

  return (
    <div className={styles.container}>
      <span className={styles.text}>{t('common.sort_by')}</span>
      <Dropdown
        title={t('common.sort_by')}
        options={[
          ...options,
          { divider: true },
          {
            icon: icon('arrowLongDown', 16),
            label: t('common.ascending'),
            onClick: () => setURLParams({ order_by: 'asc', sort_by: sort_by || defaultSorting }),
            active: isAscOrder,
          },
          {
            icon: icon('arrowLongTop', 16),
            label: t('common.descending'),
            onClick: () => setURLParams({ order_by: 'desc', sort_by: sort_by || defaultSorting }),
            active: isDescOrder,
          },
        ]}
        className={styles.dropdown}
      >
        {() => (
          <Button variant="light" size="small" className={styles.value}>
            {value?.title || defaultValue?.title}
            {isAscOrder && icon('arrowLongDown', 16)}
            {isDescOrder && icon('arrowLongTop', 16)}
          </Button>
        )}
      </Dropdown>
    </div>
  );
};

export default PageSorting;
