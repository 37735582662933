import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { ChannelSchema } from '../schemas/channelSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  channelId: ChannelSchema['id'];
}

const deleteWorkspaceChannel = async (args: Args) => {
  const { workspaceId, channelId } = args;

  const response = await api.delete(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/channel/${channelId}`
  );

  return emptyResponseSchema.parse(response.data);
};

export default deleteWorkspaceChannel;
