import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import paginationSchema from '../schemas/paginationSchema';
import workspaceSchema from '../schemas/workspaceSchema';

const loadGlobalWorkspaces = async (params?: URLParams) => {
  const response = await api.get(`/api/global/${ALIASES.WORKSPACE.L}`, { params });

  return paginationSchema(workspaceSchema).parse(response.data);
};

export default loadGlobalWorkspaces;
