import api from '../../services/api';
import globalAccountPasswordSchema from '../schemas/account/globalAccountPasswordSchema';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';

interface Args {
  accountId: WorkspaceAccountSchema['id'];
}

const loadGlobalAccountPassword = async (args: Args) => {
  const { accountId } = args;

  const response = await api.get(`/api/account/${accountId}/password`);

  return globalAccountPasswordSchema.parse(response.data);
};

export default loadGlobalAccountPassword;
