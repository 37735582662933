import { useTranslation } from 'react-i18next';
import PageCreateButton from '../../components/PageCreateButton';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { can } from '../../utils/permissions';

const GlobalRoleCreateButton = () => {
  const { t } = useTranslation();
  const { navigate } = useURL();

  const handleClick = () => navigate(routes.globalRolesCreate);

  if (!can.role.createUpdate) return null;

  return <PageCreateButton onClick={handleClick}>{t('common.role')}</PageCreateButton>;
};

export default GlobalRoleCreateButton;
