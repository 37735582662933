import api from '../../services/api';
import { GlobalAccountSchema } from '../schemas/account/globalAccountSchema';
import { accountStrategyProfileSchema } from '../schemas/accountStrategyProfileSchema';

interface Args {
  accountId: GlobalAccountSchema['id'];
}

const loadGlobalAccountStrategyProfile = async (args: Args) => {
  const { accountId } = args;

  const response = await api.get(`/api/account/${accountId}/strategy-profile`);

  return accountStrategyProfileSchema.parse(response.data);
};

export default loadGlobalAccountStrategyProfile;
