import { z } from 'zod';

export const timeZones = [
  'local',
  'UTC',
  'Pacific/Pago_Pago',
  'Pacific/Honolulu',
  'Pacific/Marquesas',
  'Pacific/Gambier',
  'America/Anchorage',
  'Pacific/Pitcairn',
  'America/Los_Angeles',
  'America/Phoenix',
  'America/Denver',
  'America/Mexico_City',
  'America/Chicago',
  'America/Jamaica',
  'America/New_York',
  'America/Barbados',
  'America/Halifax',
  'America/St_Johns',
  'America/Argentina/Buenos_Aires',
  'America/Noronha',
  'Atlantic/Cape_Verde',
  'Africa/Accra',
  'Europe/London',
  'Africa/Tunis',
  'Europe/Paris',
  'Europe/Kaliningrad',
  'Africa/Cairo',
  'Europe/Moscow',
  'Asia/Tehran',
  'Asia/Dubai',
  'Asia/Kabul',
  'Asia/Karachi',
  'Asia/Colombo',
  'Asia/Kathmandu',
  'Asia/Dhaka',
  'Asia/Yangon',
  'Asia/Bangkok',
  'Asia/Singapore',
  'Australia/Eucla',
  'Asia/Tokyo',
  'Australia/Darwin',
  'Australia/Brisbane',
  'Australia/Adelaide',
  'Australia/Sydney',
  'Australia/Lord_Howe',
  'Pacific/Noumea',
  'Pacific/Norfolk',
  'Pacific/Nauru',
  'Pacific/Auckland',
  'Pacific/Apia',
  'Pacific/Chatham',
  'Pacific/Kiritimati',
] as const;

const timeZoneSchema = z.enum(timeZones);

export type TimeZoneSchema = z.infer<typeof timeZoneSchema>;

export default timeZoneSchema;
