import api from '../../services/api';
import { GlobalAccountSchema } from '../schemas/account/globalAccountSchema';
import jsonSchema from '../schemas/jsonSchema';

interface Args {
  accountId: GlobalAccountSchema['id'];
}

const loadGlobalAccountFingerprint = async (args: Args) => {
  const { accountId } = args;

  const response = await api.get(`/api/account/${accountId}/fingerprint`);

  return jsonSchema.parse(response.data);
};

export default loadGlobalAccountFingerprint;
