import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import { historyFilterValuesSchema } from '../schemas/historySchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
}

const loadWorkspaceAccountHistoryFilterValues = async (args: Args, params?: URLParams) => {
  const { workspaceId, accountId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/${accountId}/history/filter-values`,
    { params }
  );

  return historyFilterValuesSchema.parse(response.data);
};

export default loadWorkspaceAccountHistoryFilterValues;
