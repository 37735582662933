import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { WorkspaceStageSchema } from '../../../api/schemas/stage/workspaceStageSchema';
import Dialog from '../../../components/Dialog';
import Form from '../../../components/Form';
import SubmitButton from '../../../components/SubmitButton';
import WorkspaceAccountFields from '../WorkspaceAccountFields';
import styles from './styles.module.scss';

interface Fields {
  columns: number[];
}

interface Props {
  data: WorkspaceStageSchema;
  onSubmit: (values: Fields) => void;
}

const WorkspaceAccountExportToCSVDialogForm = (props: Props) => {
  const { /* data, */ onSubmit } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({ defaultValues: { columns: [] } });

  const { watch, setValue } = form;

  const { columns } = watch();

  return (
    <Form form={form} onSubmit={onSubmit} className={styles.form}>
      <div className={styles.container}>
        <WorkspaceAccountFields
          variant="csv"
          value={columns}
          onChange={(value) => setValue('columns', value)}
        />
      </div>
      <Dialog.Footer>
        <SubmitButton>{t('common.export')}</SubmitButton>
      </Dialog.Footer>
    </Form>
  );
};

export default WorkspaceAccountExportToCSVDialogForm;
