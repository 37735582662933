import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import paginationSchema from '../schemas/paginationSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';
import workspaceUserSchema from '../schemas/workspaceUserSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadGlobalWorkspaceUsers = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(`/api/global/${ALIASES.WORKSPACE.L}/${workspaceId}/users`, {
    params,
  });

  return paginationSchema(workspaceUserSchema).parse(response.data);
};

export default loadGlobalWorkspaceUsers;
