import { Outlet } from 'react-router-dom';
import Toasts from '../Toasts';
import styles from './styles.module.scss';

const Global = () => (
  <div className={styles.container}>
    <Toasts />
    <Outlet />
  </div>
);

export default Global;
