import api from '../../services/api';
import URLParams from '../../types/URLParams';
import globalDealSchema from '../schemas/deal/globalDealSchema';
import paginationSchemaNew from '../schemas/paginationSchemaNew';

const loadGlobalDeals = async (params?: URLParams) => {
  const response = await api.get('/api/deal', { params });

  return paginationSchemaNew(globalDealSchema).parse(response.data);
};

export default loadGlobalDeals;
