import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import { addWhen } from '../../utils';
import { getBalanceParams } from '../account/AccountBalance/helpers';
import { CommonAccountTransactionBalanceSchema, OnTransactionsOpen } from '../account/types';
import WorkspaceAccountBalanceDialog, { Type } from './WorkspaceAccountBalanceDialog';

interface Props {
  balance: CommonAccountTransactionBalanceSchema;
  accountData: WorkspaceAccountSchema;
  workspaceId: WorkspaceSchema['id'];
  onTransactionsOpen?: OnTransactionsOpen;
}

const WorkspaceAccountDetailBalanceControls = (props: Props) => {
  const { balance, accountData, workspaceId, onTransactionsOpen } = props;

  const { t } = useTranslation();

  const clubBalance = Boolean(balance.club_id);
  const tableBalance = Boolean(balance.table_id);

  const [type, setType] = useState<Type | null>(null);

  if (!onTransactionsOpen) return null;

  const commonProps = {
    data: balance,
    workspaceId,
    accountData,
    onClose: () => setType(null),
  };

  return (
    <>
      {onTransactionsOpen && (
        <Tooltip label={t('common.transactions')}>
          <Button
            size="small"
            variant="transparent"
            icon={icon('clockBack', 16)}
            onClick={() => onTransactionsOpen(getBalanceParams(balance))}
          />
        </Tooltip>
      )}
      <Dropdown
        title={t('common.transaction')}
        options={[
          ...addWhen(
            {
              label: t('common.deposit'),
              icon: icon('deposit', 20),
              onClick: () => setType('deposit'),
            },
            !tableBalance
          ),
          ...addWhen(
            {
              label: t('common.withdraw'),
              icon: icon('withdraw', 20),
              onClick: () => setType('withdraw'),
            },
            !tableBalance
          ),
          ...addWhen(
            {
              label: t('common.adjustment'),
              icon: icon('crosshair', 20),
              onClick: () => setType('adjustment'),
            },
            !tableBalance && clubBalance
          ),
          ...addWhen(
            {
              label: t('common.penalty'),
              icon: icon('percent', 20),
              onClick: () => setType('fee'),
            },
            !tableBalance && clubBalance
          ),
          ...addWhen(
            {
              label: t('common.transfer'),
              icon: icon('arrowChange', 20),
              onClick: () => setType('transfer'),
            },
            !tableBalance && clubBalance
          ),
          ...addWhen(
            {
              label: t('common.reward'),
              icon: icon('gift', 20),
              onClick: () => setType('reward'),
            },
            !tableBalance && clubBalance
          ),
        ]}
      >
        {({ open }) => (
          <Button
            focused={open}
            size="small"
            variant="transparent"
            icon={icon('moreVertical', 16)}
          />
        )}
      </Dropdown>
      {type === 'deposit' && <WorkspaceAccountBalanceDialog type="deposit" {...commonProps} />}
      {type === 'withdraw' && <WorkspaceAccountBalanceDialog type="withdraw" {...commonProps} />}
      {type === 'adjustment' && (
        <WorkspaceAccountBalanceDialog type="adjustment" {...commonProps} />
      )}
      {type === 'fee' && <WorkspaceAccountBalanceDialog type="fee" {...commonProps} />}
      {type === 'transfer' && <WorkspaceAccountBalanceDialog type="transfer" {...commonProps} />}
      {type === 'reward' && <WorkspaceAccountBalanceDialog type="reward" {...commonProps} />}
    </>
  );
};

export default WorkspaceAccountDetailBalanceControls;
