import clsx from 'clsx';
import { ElementRef, ReactNode, useCallback, useRef, useState } from 'react';
import { mergeRefs } from 'react-merge-refs';
import icon from '../../../static/icons';
import PageTab from './PageTab';
import PageTabsPlaceholder from './PageTabsPlaceholder';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  extra?: ReactNode;
}

const PageTabs = (props: Props) => {
  const { children, extra } = props;

  const ref = useRef<ElementRef<'div'>>(null);

  const [scrollLeft, setScrollLeft] = useState(0);
  const [clientWidth, setClientWidth] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);

  const callbackRef = useCallback((node: HTMLDivElement) => {
    if (node) {
      setScrollLeft(node.scrollLeft);
      setClientWidth(node.clientWidth);
      setScrollWidth(node.scrollWidth);
    }
  }, []);

  const scrollTo = (direction: 'start' | 'end') => {
    const px = direction === 'start' ? -200 : 200;

    if (ref.current) {
      ref.current.scrollTo({ left: ref.current.scrollLeft + px, behavior: 'smooth' });
    }
  };

  return (
    <div className={styles.container}>
      <button
        type="button"
        className={clsx(styles.scrollButton, styles.start, scrollLeft > 0 && styles.visible)}
        onClick={() => scrollTo('start')}
      >
        {icon('chevronLeft', 16)}
      </button>
      <div
        ref={mergeRefs([ref, callbackRef])}
        className={styles.tabs}
        onScroll={(event) => setScrollLeft(event.currentTarget.scrollLeft)}
      >
        {children}
      </div>
      <button
        type="button"
        className={clsx(
          styles.scrollButton,
          styles.end,
          scrollLeft + clientWidth !== scrollWidth && styles.visible
        )}
        onClick={() => scrollTo('end')}
      >
        {icon('chevronRight', 16)}
      </button>
      {extra}
    </div>
  );
};

PageTabs.Tab = PageTab;
PageTabs.Placeholder = PageTabsPlaceholder;

export default PageTabs;
