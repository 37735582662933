import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { workspaceClubManagerFilterValuesSchema } from '../schemas/workspaceClubManagerSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceClubManagersFilterValues = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(
    `/api/maintenance/${workspaceId}/club-manager/managers/filter-values`,
    { params }
  );

  return workspaceClubManagerFilterValuesSchema.parse(response.data);
};

export default loadWorkspaceClubManagersFilterValues;
