import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadGlobalBotSettingsFilterValues } from '../../api';
import Filter from '../../components/Filter';
import queryKeys from '../../config/queryKeys';
import useFilter from '../../hooks/useFilter';
import useURL from '../../hooks/useURL';
import { TRAINER_GAME_TYPE_LABELS } from '../../utils/trainer';

interface Fields {
  game_type_in: string[];
  maintenance_in: string[];
  updated_ge: string;
  updated_le: string;
  created_ge: string;
  created_le: string;
}

interface Props {
  total?: number;
}

const GlobalBotSettingFilter = (props: Props) => {
  const { total } = props;

  const { t } = useTranslation();
  const { urlParams } = useURL();

  const queryKey = [queryKeys.globalBotSettingsFilterValues, urlParams];
  const queryFn = () => loadGlobalBotSettingsFilterValues(urlParams);

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  const filter = useFilter<Fields>({
    game_type_in: 'multi',
    maintenance_in: 'multi',
    updated_ge: 'single',
    updated_le: 'single',
    created_ge: 'single',
    created_le: 'single',
  });

  const { values, setValue, clearValue } = filter;

  const { game_type_in, maintenance_in, updated_ge, updated_le, created_ge, created_le } = values;

  const filterProps = { total, filter };

  if (isPending) {
    return (
      <Filter {...filterProps}>
        <Filter.Placeholder />
      </Filter>
    );
  }

  if (!data) return null;

  const { game_type, maintenance } = data;

  return (
    <Filter {...filterProps}>
      <div>
        {game_type.length !== 0 && (
          <Filter.Collapse
            label={t('common.game_type')}
            count={game_type_in.length}
            onClear={clearValue('game_type_in')}
          >
            <Filter.MultiCheckbox
              config={data.game_type.map((item) => ({
                value: String(item),
                label: TRAINER_GAME_TYPE_LABELS[item],
                onChange: setValue('game_type_in'),
                checked: game_type_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {maintenance.length !== 0 && (
          <Filter.Collapse
            label={t('common.workspace')}
            count={maintenance_in.length}
            onClear={clearValue('maintenance_in')}
          >
            <Filter.MultiCheckbox
              config={maintenance.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('maintenance_in'),
                checked: maintenance_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        <Filter.Collapse
          label={t('common.modified')}
          {...((updated_ge || updated_le) && {
            onClear: clearValue(['updated_ge', 'updated_le']),
          })}
        >
          <Filter.Date
            from={{ value: updated_ge, onChange: setValue('updated_ge') }}
            to={{ value: updated_le, onChange: setValue('updated_le') }}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.created')}
          {...((created_ge || created_le) && {
            onClear: clearValue(['created_ge', 'created_le']),
          })}
        >
          <Filter.Date
            from={{ value: created_ge, onChange: setValue('created_ge') }}
            to={{ value: created_le, onChange: setValue('created_le') }}
          />
        </Filter.Collapse>
      </div>
    </Filter>
  );
};

export default GlobalBotSettingFilter;
