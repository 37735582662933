import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import orderSchema, { OrderSchema } from '../schemas/orderSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  orderId: OrderSchema['id'];
}

const loadWorkspaceOrder = async (args: Args) => {
  const { workspaceId, orderId } = args;

  const response = await api.get(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/order/${orderId}`);

  return orderSchema.parse(response.data);
};

export default loadWorkspaceOrder;
