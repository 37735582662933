import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import PrettyNumber from '../../../components/PrettyNumber';
import { convertCentsToDollars } from '../../../utils/data';
import styles from './styles.module.scss';

interface Props {
  data: WorkspaceAccountSchema;
}

const WorkspaceAccountLobbyBalance = (props: Props) => {
  const { data } = props;

  const { balance } = data;

  if (!balance?.lobby_balance) return '—';

  const { amount, currency } = balance.lobby_balance;

  return (
    <div className={styles.container}>
      <PrettyNumber>{convertCentsToDollars(amount)}</PrettyNumber>
      <span>{currency}</span>
    </div>
  );
};

export default WorkspaceAccountLobbyBalance;
