export const numbers = /^[0-9]+$/;
export const commaSeparatedNumbers = /^([0-9]+,?)+$/;
export const commaSeparatedFloatNumbers = /^([0-9]+(\.|,)?)+$/;

export const timeZone = /(\+|-)(0[0-9]|1[0-4]):(00|15|30|45)/;

export const email =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const uuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;

export const hexColor = /#([a-f0-9]{3}|[a-f0-9]{4}(?:[a-f0-9]{2}){0,2})\b/g;

export const floatNumbers = (value?: number) => new RegExp(`^[0-9]+(\\.[0-9]{0,${value || ''}})?$`);
