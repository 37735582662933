import api from '../../services/api';
import { GlobalDealSchema } from '../schemas/deal/globalDealSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';

type Schema = GlobalDealSchema;

interface Args {
  dealId: Schema['id'];
}

const deleteGlobalDeal = async (args: Args) => {
  const { dealId } = args;

  const response = await api.delete(`/api/deal/${dealId}`);

  return emptyResponseSchema.parse(response.data);
};

export default deleteGlobalDeal;
