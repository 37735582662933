import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import ResetPage from '../../pages/ResetPage';

const resetPage: RouteObject = {
  path: routes.reset,
  element: <ResetPage />,
};

export default resetPage;
