import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalAccountDetail from '../../features/global-account/GlobalAccountDetail';
import GlobalAccountLoader from '../../features/global-account/GlobalAccountLoader';
import GlobalSessionDetail from '../../features/global-session/GlobalSessionDetail';
import GlobalSessionForm from '../../features/global-session/GlobalSessionForm';
import GlobalSessionLoader from '../../features/global-session/GlobalSessionLoader';
import GlobalSessionsPage from '../../pages/GlobalSessionsPage';
import Route from './Route';

const page = <GlobalSessionsPage />;

const detail = (
  <Route params={['accountId', 'sessionId']}>
    {({ accountId, sessionId, navigate }) => (
      <GlobalSessionLoader accountId={accountId} sessionId={sessionId}>
        {({ data }) => (
          <GlobalSessionDetail
            data={data}
            onClose={() => navigate(routes.globalSessions)}
            onEdit={() => navigate(routes.globalSessionEdit({ accountId, sessionId }))}
          />
        )}
      </GlobalSessionLoader>
    )}
  </Route>
);

const detailAccount = (
  <Route params={['accountId', 'sessionId']}>
    {({ accountId, sessionId, navigate }) => (
      <GlobalAccountLoader accountId={accountId}>
        {({ data }) => (
          <GlobalAccountDetail
            data={data}
            onClose={() => navigate(routes.globalSession({ accountId, sessionId }))}
          />
        )}
      </GlobalAccountLoader>
    )}
  </Route>
);

const editForm = (
  <Route params={['accountId', 'sessionId']}>
    {({ accountId, sessionId, navigate }) => (
      <GlobalSessionLoader accountId={accountId} sessionId={sessionId}>
        {({ data }) => (
          <GlobalSessionForm
            data={data}
            onClose={() => navigate(routes.globalSession({ accountId, sessionId }))}
          />
        )}
      </GlobalSessionLoader>
    )}
  </Route>
);

const globalSessionsPage: RouteObject = {
  path: routes.globalSessions,
  element: page,
  children: [
    {
      path: routes.globalSession({ accountId: ':accountId', sessionId: ':sessionId' }),
      element: detail,
      children: [
        {
          path: routes.globalSessionAccount({
            accountId: ':accountId',
            sessionId: ':sessionId',
          }),
          element: detailAccount,
        },
      ],
    },
    {
      path: routes.globalSessionEdit({ accountId: ':accountId', sessionId: ':sessionId' }),
      element: editForm,
    },
  ],
};

export default globalSessionsPage;
