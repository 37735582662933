import { inviteWorkspaceUsers, sendWorkspaceUserRecoveryPassword } from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { WorkspaceUserSchema } from '../../api/schemas/workspaceUserSchema';
import { t } from '../../i18n';
import { handleError } from '../../utils/form';
import notify from '../../utils/notify';

export const inviteWorkspaceUser = async (args: {
  workspaceId: WorkspaceSchema['id'];
  email: WorkspaceUserSchema['username'];
}) => {
  const { workspaceId, email } = args;

  await inviteWorkspaceUsers({ workspaceId, payload: [{ email }] });

  notify('success', { title: String(t('sentences.invitation_sent_one')) });
};

export const resetPassword = async (args: {
  workspaceId: WorkspaceSchema['id'];
  id: WorkspaceUserSchema['id'];
  username: WorkspaceUserSchema['username'];
}) => {
  try {
    const { workspaceId, id, username } = args;

    await sendWorkspaceUserRecoveryPassword({ workspaceId, payload: { user_id: id, username } });

    notify('success', { title: String(t('sentences.password_has_been_reset')) });
  } catch (error) {
    handleError({ error });
  }
};
