import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadGlobalAccountRoomConfig } from '../../api';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import { AccountRoomConfigSchema } from '../../api/schemas/accountRoomConfigSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';

interface Props {
  accountId: GlobalAccountSchema['id'];
  children: (args: { data: AccountRoomConfigSchema }) => ReactElement;
}

const GlobalAccountRoomConfigLoader = (props: Props) => {
  const { accountId, children } = props;

  const queryKey = [queryKeys.globalAccountRoomConfig({ accountId })];
  const queryFn = () => loadGlobalAccountRoomConfig({ accountId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403, 404] },
  });

  if (isPending) return <PageAddon.DetailPlaceholder wrapper={false} />;
  if (!data) return null;

  return children({ data });
};

export default GlobalAccountRoomConfigLoader;
