import axios, { AxiosError } from 'axios';
import routes from '../config/routes';
import { useSessionStore } from '../store/session';
import { useUserStore } from '../store/user';

interface ErrorData {
  error: unknown;
  detail: [];
}

interface Error<T> {
  code: number;
  title: string;
  description: string;
  payload: T;
}

type Error400<T> = {
  error: Error<T>[];
};

const api = axios.create({ baseURL: window.BASE_URL });

export const isApiError400 = <T>(error: unknown): error is AxiosError<Error400<T>> => {
  if (axios.isAxiosError(error)) return true;

  return false;
};

export const isApiError = (error: unknown): error is AxiosError<ErrorData> => {
  if (axios.isAxiosError(error)) return true;

  return false;
};

api.interceptors.request.use((config) => {
  const { session } = useSessionStore.getState();

  if (session) {
    const { access_token, token_type } = session;

    config.headers.set('Authorization', `${token_type} ${access_token}`);
  }

  return config;
}, Promise.reject);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        useSessionStore.getState().removeSession();
        useUserStore.getState().removeUser();

        if (window.location.pathname !== routes.login) {
          window.location.href = `${routes.login}?from=${window.location.pathname}`;
        }
      }
    }

    return Promise.reject(error);
  }
);

export default api;
