import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import workspaceDealSchema, { WorkspaceDealSchema } from '../schemas/deal/workspaceDealSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  dealId: WorkspaceDealSchema['id'];
}

const loadWorkspaceDeal = async (args: Args) => {
  const { workspaceId, dealId } = args;

  const response = await api.get(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/deal/${dealId}`);

  return workspaceDealSchema.parse(response.data);
};

export default loadWorkspaceDeal;
