import { ReactNode } from 'react';
import PageControls from '../../../components/PageControls';
import PageSearch from '../../../components/PageSearch';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
}

const GlobalLobbyPageHeader = (props: Props) => {
  const { children } = props;

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.search}>
          <PageSearch />
        </div>
        {children}
      </div>
      <div className={styles.controls}>
        <PageControls />
      </div>
    </div>
  );
};

export default GlobalLobbyPageHeader;
