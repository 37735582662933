import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { GlobalBonusSchema } from '../schemas/globalBonusSchema';

type Schema = GlobalBonusSchema;

interface Payload {
  type?: Schema['type'];
  amount?: Schema['amount'];
  currency?: Schema['currency'];
  reconcile?: Schema['reconcile'];
  description?: Schema['description'];
}

interface Args {
  bonusId: Schema['id'];
  payload: Payload;
}

const updateGlobalBonus = async (args: Args) => {
  const { bonusId, payload } = args;

  const response = await api.patch(`/api/bonus/${bonusId}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateGlobalBonus;
