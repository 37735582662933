import clsx from 'clsx';
import icon from '../../../static/icons';
import styles from './styles.module.scss';

interface Props {
  type: 'success' | 'error';
}

const PageAddonCircles = (props: Props) => {
  const { type } = props;

  return (
    <div className={clsx(styles.container, styles[type])}>
      <div>
        <div>
          <div>{type === 'success' ? icon('check', 48) : icon('cross', 48)}</div>
        </div>
      </div>
    </div>
  );
};

export default PageAddonCircles;
