import { useTranslation } from 'react-i18next';
import { IdentitySchema } from '../../api/schemas/identitySchema';
import PageAddon from '../../components/PageAddon';
import { renderNumber } from '../../utils/data';
import { getRentalState, renderCost } from './helpers';

interface Props {
  data: IdentitySchema;
}

const WorkspaceIdentityDetailTabRental = (props: Props) => {
  const { data } = props;

  const { t } = useTranslation();

  const { monthly_cost, minimal_rental_period } = data;

  return (
    <PageAddon.Fields>
      <PageAddon.Field label={t('common.cost_month_USDT')}>
        {renderCost(monthly_cost)}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.min_rental_month')}>
        {renderNumber(minimal_rental_period)}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.rental_state')}>{getRentalState(data)}</PageAddon.Field>
    </PageAddon.Fields>
  );
};

export default WorkspaceIdentityDetailTabRental;
