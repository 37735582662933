import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { GlobalUserSchema } from '../schemas/globalUserSchema';
import workspaceSchema, { WorkspaceSchema, WorkspaceTypes } from '../schemas/workspaceSchema';

type Schema = WorkspaceSchema;

interface Payload {
  user_id: GlobalUserSchema['id'];
  name: Schema['name'];
  business_model: Schema['business_model'];
  maintenance_types: WorkspaceTypes;
}

interface Args {
  payload: Payload;
}

const createGlobalWorkspace = async (args: Args) => {
  const { payload } = args;

  const response = await api.post(`/api/global/${ALIASES.WORKSPACE.L}`, payload);

  return workspaceSchema.pick({ id: true }).parse(response.data);
};

export default createGlobalWorkspace;
