import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import workspaceSchema from './workspaceSchema';

export const STATUS = {
  UNKNOWN: 0,
  INACTIVE: 10,
  ACTIVE: 20,
} as const;

const countrySchema = z.string();

const channelSchema = z.object({
  id: z.string(),
  status: z.nativeEnum(STATUS),
  host: z.string(),
  port: z.number(),
  username: z.string(),
  password: z.string(),
  ip: z.string().or(z.null()),
  country: countrySchema.or(z.null()),
  maintenance: workspaceSchema,
  avg_latency: z.number().or(z.null()),
  packet_loss_percent: z.number(),
  is_used: z.boolean(),
  is_deleted: z.boolean(),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
  /*   is_free: z.boolean(), // TODO: не используется  */
});

export const channelFilterValuesSchema = z.object({
  country: z.array(countrySchema),
  status: z.array(channelSchema.shape.status),
  avg_latency: z.object({ min: z.number(), max: z.number() }),
  packet_loss_percent: z.object({ min: z.number(), max: z.number() }),
});

export type ChannelSchema = z.infer<typeof channelSchema>;

export default channelSchema;
