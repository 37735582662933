import { z, ZodType } from 'zod';

const paginationSchemaNew = <T extends ZodType>(schema: T) =>
  z.object({
    items: z.array(schema),
    size: z.number(),
    previous_page: z.string(),
    next_page: z.string(),
  });

export interface PaginationSchemaNew<T> {
  items: T[];
  size: number;
  previous_page: string;
  next_page: string;
}

export default paginationSchemaNew;
