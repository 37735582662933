import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import workspaceSessionSchema, {
  WorkspaceSessionSchema,
} from '../schemas/session/workspaceSessionSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = WorkspaceSessionSchema;

interface Payload {
  start: Schema['start'];
  end: Schema['end'];
  session_type: Schema['session_type'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
  payload: Payload;
}

const createWorkspaceAccountSession = async (args: Args) => {
  const { workspaceId, accountId, payload } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/${accountId}/session`,
    payload
  );

  return workspaceSessionSchema.pick({ id: true }).parse(response.data);
};

export default createWorkspaceAccountSession;
