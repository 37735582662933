import { Payload } from '../../components/Notes';
import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import noteSchema from '../schemas/noteSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
  payload: Payload;
}

const createWorkspaceAccountNote = async (args: Args) => {
  const { workspaceId, accountId, payload } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/${accountId}/notes`,
    payload
  );

  return noteSchema.pick({ id: true }).parse(response.data);
};

export default createWorkspaceAccountNote;
