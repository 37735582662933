import clsx from 'clsx';
import icon from '../../static/icons';
import Tooltip from '../Tooltip';
import styles from './styles.module.scss';
import { Props } from './types';

const Label = (props: Props) => {
  const { hint, addon, children, className } = props;

  return (
    <div className={styles.container}>
      {children && (
        <label className={clsx(styles.label, className)}>
          {children}
          {hint && <Tooltip label={hint}>{icon('helpCircle', 16)}</Tooltip>}
        </label>
      )}
      {addon && <div className={styles.addon}>{addon}</div>}
    </div>
  );
};

export default Label;
