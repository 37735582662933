import { useState } from 'react';
import { WebSocketMessage } from '../types/WebSocketMessage';
import useWebSocketMessage from './useWebSocketMessage';

export type Action<T> = WebSocketMessage<T>['action'][];

interface Config<T> {
  domain: WebSocketMessage<T>['domain'];
  action: Action<T>;
  select: (data: T) => string;
  enabled?: (message: WebSocketMessage<T>) => boolean;
}

const useWebSocketMessageActionIds = <T>(config: Config<T>) => {
  const { domain, action, select, enabled } = config;

  const [ids, setIds] = useState<string[]>([]);

  useWebSocketMessage<T>({
    domain,
    enabled,
    onMessage: (message) => {
      if (action.includes(message.action)) {
        setIds((prev) => [...prev, select(message.payload)]);
      }
    },
  });

  return [ids, setIds] as const;
};

export default useWebSocketMessageActionIds;
