import { BotSettingSchema } from '../../api/schemas/botSettingSchema';
import Dropdown from '../../components/Dropdown';
import useDropdown from './useDropdown';

interface Props {
  data: BotSettingSchema;
}

const GlobalBotSettingDetailDropdown = (props: Props) => {
  const { title, disabled, options } = useDropdown(props);

  return (
    <Dropdown title={title} buttonSize="medium" disabled={disabled} options={[options.delete]} />
  );
};

export default GlobalBotSettingDetailDropdown;
