import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadWorkspaceChannel } from '../../api';
import { ChannelSchema } from '../../api/schemas/channelSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { updateDetailQueryData } from '../../utils/queryClient';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  channelId: ChannelSchema['id'];
  children: (args: { data: ChannelSchema }) => ReactElement;
}

const WorkspaceChannelLoader = (props: Props) => {
  const { workspaceId, channelId, children } = props;

  const queryKey = [queryKeys.workspaceChannel({ workspaceId, channelId })];
  const queryFn = () => loadWorkspaceChannel({ workspaceId, channelId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<ChannelSchema>({
    domain: 'Channel',
    enabled: (message) => message.meta_info?.maintenance_id === workspaceId,
    onMessage: (message) => {
      if (
        (message.action === 'Edited' ||
          message.action === 'Enabled' ||
          message.action === 'Disabled' ||
          message.action === 'Connected' ||
          message.action === 'Disconnected' ||
          message.action === 'Deleted') &&
        message.payload.id === channelId
      ) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data });
};

export default WorkspaceChannelLoader;
