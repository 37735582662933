import clsx from 'clsx';
import { ElementRef, forwardRef, ReactNode } from 'react';
import CardField from './CardField';
import CardGroup from './CardGroup';
import CardTop from './CardTop';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  highlighted?: boolean;
  danger?: boolean;
  warning?: boolean;
}

const Card = Object.assign(
  forwardRef<ElementRef<'div'>, Props>((props, ref) => {
    const { children, highlighted, danger, warning } = props;

    const className = clsx(styles.container, {
      [styles.highlighted]: highlighted,
      [styles.danger]: danger,
      [styles.warning]: warning,
    });

    return (
      <div ref={ref} className={className}>
        {children}
      </div>
    );
  }),
  {
    Group: CardGroup,
    Top: CardTop,
    Field: CardField,
  }
);

export default Card;
