import styles from './styles.module.scss';

interface Props {
  color: string;
}

const WorkspaceAccountColorCircle = (props: Props) => {
  const { color } = props;

  return <div className={styles.circle} style={{ backgroundColor: color }} />;
};

export default WorkspaceAccountColorCircle;
