import api from '../../services/api';
import URLParams from '../../types/URLParams';
import globalAccountTransactionSchema, {
  GlobalAccountTransactionSchema,
} from '../schemas/account/globalAccountTransactionSchema';
import paginationSchemaNew from '../schemas/paginationSchemaNew';

interface Args {
  accountId: GlobalAccountTransactionSchema['account_id'];
}

const loadGlobalAccountTransactions = async (args: Args, params?: URLParams) => {
  const { accountId } = args;

  const response = await api.get(`/api/v2/account/${accountId}/transaction`, { params });

  return paginationSchemaNew(globalAccountTransactionSchema).parse(response.data);
};

export default loadGlobalAccountTransactions;
