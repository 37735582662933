import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { NotificationSchema } from '../schemas/notificationSchema';

type Schema = NotificationSchema;

interface Payload {
  ids: Schema['id'][];
}

interface Args {
  payload: Payload;
}

const readNotifications = async (args: Args) => {
  const { payload } = args;

  const response = await api.post('/api/notification/read', payload);

  return emptyResponseSchema.parse(response.data);
};

export default readNotifications;
