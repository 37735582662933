import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadGlobalAccountPassword } from '../../../api';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import Dialog from '../../../components/Dialog';
import Placeholder from '../../../components/Placeholder';
import queryKeys from '../../../config/queryKeys';
import { copy } from '../../../utils/clipboard';
import styles from './styles.module.scss';

interface Props {
  accountId: WorkspaceAccountSchema['id'];
  onClose?: () => void;
}

const GlobalAccountCredentialsDialog = (props: Props) => {
  const { accountId, onClose } = props;

  const { t } = useTranslation();

  const { data } = useQuery({
    queryKey: [queryKeys.globalAccountPassword({ accountId })],
    queryFn: () => loadGlobalAccountPassword({ accountId }),
  });

  return (
    <Dialog onClose={onClose}>
      {() => (
        <>
          <Dialog.Top>
            <Dialog.Title>{t('common.credentials')}</Dialog.Title>
          </Dialog.Top>
          {!data ? (
            Array.from({ length: 2 }).map((_, index) => (
              <Placeholder key={index} className={styles.placeholder} />
            ))
          ) : (
            <div className={styles.container}>
              <div>
                <span>{t('common.login')}</span>
                <div className={styles.line} />
                <button type="button" onClick={() => copy(data.login)}>
                  {data.login}
                </button>
              </div>
              <div>
                <span>{t('common.password')}</span>
                <div className={styles.line} />
                <button type="button" onClick={() => copy(data.password)}>
                  {data.password}
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </Dialog>
  );
};

export default GlobalAccountCredentialsDialog;
