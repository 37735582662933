import api from '../../services/api';
import URLParams from '../../types/URLParams';
import globalSuperLeagueSchema from '../schemas/globalSuperLeagueSchema';
import paginationSchema from '../schemas/paginationSchema';

const loadGlobalSuperLeagues = async (params?: URLParams) => {
  const response = await api.get('/api/reference/super-league', { params });

  return paginationSchema(globalSuperLeagueSchema).parse(response.data);
};

export default loadGlobalSuperLeagues;
