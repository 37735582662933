import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import workspaceStageSchema, { WorkspaceStageSchema } from '../schemas/stage/workspaceStageSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = WorkspaceStageSchema;

interface Payload {
  name: Schema['name'];
  is_playable: Schema['is_playable'];
  clear_channel: Schema['clear_channel'];
  clear_schedule: Schema['clear_schedule'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const createWorkspaceAccountsStage = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.post(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/stage`, payload);

  return workspaceStageSchema.pick({ id: true }).parse(response.data);
};

export default createWorkspaceAccountsStage;
