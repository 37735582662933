import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceSchema } from '../schemas/workspaceSchema';
import workspaceTokenSchema, { WorkspaceTokenSchema } from '../schemas/workspaceTokenSchema';

interface Payload {
  name: WorkspaceTokenSchema['name'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const createWorkspaceToken = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.post(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/token`, payload);

  return workspaceTokenSchema.pick({ id: true }).parse(response.data);
};

export default createWorkspaceToken;
