import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createGlobalArea, updateGlobalArea } from '../../api';
import { AreaSchema } from '../../api/schemas/areaSchema';
import Button from '../../components/Button';
import DatePicker from '../../components/DatePicker';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import Select, { Option } from '../../components/Select';
import useQuickForm from '../../hooks/useQuickForm';
import icon from '../../static/icons';
import { required } from '../../utils/form';
import notify from '../../utils/notify';
import { numbers } from '../../utils/validations';
import { emptyPeriod, STATUS_LABELS, statusOptions } from './helpers';
import styles from './styles.module.scss';

const formatDate = (date: string) => new Date(date).toISOString();

interface Fields {
  name: string;
  status: Option<AreaSchema['status']>;
  area_rates: {
    start_date: string | null;
    end_date: string | null;
    currency: string;
    units: string;
    nanos: string;
  }[];
}

interface Args {
  data?: AreaSchema;
  onClose?: () => void;
  onCreate?: (args: { areaId: AreaSchema['id'] }) => void;
}

const useForm = (args: Args) => {
  const { data, onCreate, onClose } = args;

  const { t } = useTranslation();

  const form = useQuickForm<Fields>({
    data,
    defaultValues: {
      name: data?.name || '',
      status: data?.status ? { value: data.status, label: STATUS_LABELS[data.status] } : null,
      area_rates: data
        ? data.area_rates.map((item) => ({
            start_date: item.active_date_time_from || '',
            end_date: item.active_date_time_to || '',
            currency: item.rate.currency_code,
            units: String(item.rate.units),
            nanos: String(item.rate.nanos),
          }))
        : [emptyPeriod],
    },
  });

  const { control, handleSubmit } = form;

  const { fields, append, remove } = useFieldArray({ control, name: 'area_rates' });

  const onSubmit = async (values: Fields) => {
    const payload = {
      name: values.name,
      area_rates: values.area_rates.map((item) => ({
        active_date_time_from: item.start_date ? formatDate(item.start_date) : null,
        active_date_time_to: item.end_date ? formatDate(item.end_date) : null,
        rate: {
          currency_code: item.currency,
          units: Number(item.units),
          nanos: Number(item.nanos),
        },
      })),
    };

    if (data) {
      if (!values.status) throw new Error();

      await updateGlobalArea({
        areaId: data.id,
        payload: { status: values.status.value, area_rates: payload.area_rates },
      });

      if (onClose) onClose();

      notify('success', { title: t('sentences.changes_have_been_saved') });
    } else {
      const response = await createGlobalArea({ payload });

      if (onCreate) {
        onCreate({ areaId: response.id });
      }

      notify('success', { title: t('sentences.record_has_been_created') });
    }
  };

  const { watch, setValue } = form;

  const values = watch();

  const detail = data && !onCreate && !onClose;

  return {
    form,
    onSubmit,
    fields: {
      name: (
        <PageAddon.Field label={t('common.name')}>
          <Input.Quick
            name="name"
            control={control}
            disabled={detail}
            rules={{ validate: { required } }}
          />
        </PageAddon.Field>
      ),
      status: (
        <PageAddon.Field label={t('common.status')}>
          <Select.Quick
            control={control}
            name="status"
            options={statusOptions}
            onSubmit={handleSubmit(onSubmit)}
          />
        </PageAddon.Field>
      ),
      periods: (
        <div className={styles.periods}>
          {fields.map((item, index) => (
            <div key={item.id} className={styles.period}>
              <div className={styles.periodTop}>
                <span>{`${t('common.period')} ${index + 1}`}</span>
                {index !== 0 && !detail && (
                  <Button
                    danger
                    size="extra-small"
                    variant="transparent"
                    icon={icon('trash', 16)}
                    onClick={() => remove(index)}
                  />
                )}
              </div>
              <PageAddon.Field label={t('common.start_date')}>
                <DatePicker
                  quick
                  withTime
                  disabled={detail}
                  value={values.area_rates[index].start_date || ''}
                  onChange={(value) => setValue(`area_rates.${index}.start_date`, value)}
                />
              </PageAddon.Field>
              <PageAddon.Field label={t('common.end_date')}>
                <DatePicker
                  quick
                  withTime
                  disabled={detail}
                  value={values.area_rates[index].end_date || ''}
                  onChange={(value) => setValue(`area_rates.${index}.end_date`, value)}
                />
              </PageAddon.Field>
              <PageAddon.Field label={t('common.currency')}>
                <Input.Quick
                  control={control}
                  disabled={detail}
                  name={`area_rates.${index}.currency`}
                  rules={{ validate: { required } }}
                />
              </PageAddon.Field>
              <PageAddon.Field label={t('common.units')}>
                <Input.Quick
                  control={control}
                  disabled={detail}
                  name={`area_rates.${index}.units`}
                  allow={numbers}
                />
              </PageAddon.Field>
              <PageAddon.Field label={t('common.nanos')}>
                <Input.Quick
                  control={control}
                  disabled={detail}
                  name={`area_rates.${index}.nanos`}
                  allow={numbers}
                />
              </PageAddon.Field>
            </div>
          ))}
          {!detail && (
            <Button
              variant="light"
              size="extra-small"
              icon={icon('add', 16)}
              onClick={() => append(emptyPeriod, { shouldFocus: false })}
            >
              {t('common.period')}
            </Button>
          )}
        </div>
      ),
    },
  };
};

export default useForm;
