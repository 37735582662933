import api from '../../services/api';
import URLParams from '../../types/URLParams';
import orderSchema from '../schemas/orderSchema';
import paginationSchema from '../schemas/paginationSchema';

const loadGlobalOrders = async (params?: URLParams) => {
  const response = await api.get('/api/order', { params });

  return paginationSchema(orderSchema).parse(response.data);
};

export default loadGlobalOrders;
