import { useTranslation } from 'react-i18next';
import PageAddon from '../../../components/PageAddon';
import { CommonSessionSchema } from '../types';
import useDuration from '../useDuration';
import styles from './styles.module.scss';

interface Props {
  data: CommonSessionSchema;
}

const SessionPageAddonFieldDuration = (props: Props) => {
  const { data } = props;

  const { t } = useTranslation();

  const { durationInHours, timeRemainsInHours, delayInHours, delayed } = useDuration({ data });

  return (
    <>
      <PageAddon.Field label={t('common.duration')}>{durationInHours}</PageAddon.Field>
      <PageAddon.Field label={t('common.time_remains')}>{timeRemainsInHours}</PageAddon.Field>
      {delayed && (
        <PageAddon.Field label={t('common.delayed')}>
          <span className={styles.danger}>{delayInHours}</span>
        </PageAddon.Field>
      )}
    </>
  );
};

export default SessionPageAddonFieldDuration;
