import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import icon from '../../../static/icons';
import Button from '../../Button';
import Placeholder from '../../Placeholder';
import Tooltip from '../../Tooltip';
import styles from './styles.module.scss';

interface Props {
  src: string;
  name: string;
  onClick: () => void;
  error?: string;
  onRemove?: () => void;
}

const MediaViewerPreview = (props: Props) => {
  const { src, name, onClick, error, onRemove } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  const handleLoad = () => setLoading(false);

  return (
    <div className={styles.container}>
      <div className={clsx(styles.overlay, error && styles.error)} data-overlay>
        {error ? (
          <div className={styles.error}>
            {icon('alertCircle', 16)}
            {t('common.error')}
          </div>
        ) : (
          icon('maximize', 16)
        )}
      </div>
      {onRemove && (
        <Button
          size="extra-small"
          variant="secondary"
          className={styles.removeButton}
          onClick={onRemove}
        >
          {icon('cross', 16)}
        </Button>
      )}
      {loading && <Placeholder className={styles.placeholder} data-placeholder />}
      <button type="button" onClick={onClick}>
        <Tooltip label={error || name} {...(!error && { delay: 1 })} hideOnMobile>
          <img src={src} alt={name} onLoad={handleLoad} />
        </Tooltip>
      </button>
    </div>
  );
};

export default MediaViewerPreview;
