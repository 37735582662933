import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import workspaceAccountSchema, {
  WorkspaceAccountPasswordSchema,
  WorkspaceAccountSchema,
} from '../schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = WorkspaceAccountSchema;

interface Payload {
  login: Schema['login'];
  password: WorkspaceAccountPasswordSchema;
  room: Schema['room'];
  affiliate_reference_number: Schema['affiliate_reference_number'] | null;
  bot_settings_ids: Schema['bot_settings'][number]['id'][];
  deal_ids: Schema['deals'][number]['id'][];
  table_sizes: Schema['table_sizes'];
  max_tables?: Schema['max_tables'];
  comparative_limits: Schema['comparative_limits'];
  channel_ids?: string[];
  geo: Schema['geo'] | null;
  tag_ids: Schema['tags'][number]['id'][];
  club_ids: Schema['clubs'][number]['id'][];
  identity_id: Exclude<Schema['identity'], null>['id'] | null;
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const createWorkspaceAccount = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.post(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account`, payload);

  return workspaceAccountSchema.pick({ id: true }).parse(response.data);
};

export default createWorkspaceAccount;
