import { Dispatch, ReactNode, SetStateAction, createContext, useContext } from 'react';
import { CloseDropdown, ConfirmIndex } from './types';

interface Context {
  confirmIndex: ConfirmIndex;
  setConfirmIndex: Dispatch<SetStateAction<ConfirmIndex>>;
  closeDropdown: CloseDropdown;
}

const DropdownContext = createContext<Context | undefined>(undefined);

interface Props extends Context {
  children: ReactNode;
}

const DropdownProvider = (props: Props) => {
  const { children, ...rest } = props;

  return <DropdownContext.Provider value={rest}>{children}</DropdownContext.Provider>;
};

const useDropdownContext = () => {
  const context = useContext(DropdownContext);

  if (context === undefined) {
    throw new Error('useDropdownContext must be used within a DropdownProvider');
  }

  return context;
};

export { useDropdownContext, DropdownProvider };
