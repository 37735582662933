import { inviteGlobalUsers, sendGlobalUserRecoveryPassword } from '../../api';
import { GlobalUserSchema } from '../../api/schemas/globalUserSchema';
import { t } from '../../i18n';
import { handleError } from '../../utils/form';
import notify from '../../utils/notify';

export const inviteUser = async (args: { email: GlobalUserSchema['username'] }) => {
  const { email } = args;

  await inviteGlobalUsers({ payload: [{ email }] });

  notify('success', { title: String(t('sentences.invitation_sent_one')) });
};

export const resetPassword = async (args: {
  id: GlobalUserSchema['id'];
  username: GlobalUserSchema['username'];
}) => {
  try {
    const { id, username } = args;

    await sendGlobalUserRecoveryPassword({ payload: { user_id: id, username } });

    notify('success', { title: String(t('sentences.password_has_been_reset')) });
  } catch (error) {
    handleError({ error });
  }
};
