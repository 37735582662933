import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { acceptWorkspaceClubMembers, loadWorkspaceClubManagers } from '../../api';
import { WorkspaceClubMemberSchema } from '../../api/schemas/workspaceClubMemberSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Form from '../../components/Form';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import Select, { Option } from '../../components/Select';
import SubmitButton from '../../components/SubmitButton';
import TagInput from '../../components/TagInput';
import { required } from '../../utils/form';
import notify from '../../utils/notify';
import { trainerPokerRoomOptions } from '../../utils/trainer';

interface Fields {
  room: Option<WorkspaceClubMemberSchema['room']>;
  club: string;
  manager_id: Option;
  player_ids: string[];
}

interface Props {
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
}

const WorkspaceClubMemberAcceptForm = (props: Props) => {
  const { workspaceId, onClose } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: {
      room: null,
      club: '',
      manager_id: null,
      player_ids: [],
    },
  });

  const {
    register,
    control,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    if (!values.room) throw new Error();
    if (!values.manager_id) throw new Error();

    const payload = {
      room: values.room.value,
      club: values.club,
      manager_id: values.manager_id.value,
      player_ids: values.player_ids,
    };

    await acceptWorkspaceClubMembers({ workspaceId, payload });

    onClose();
    notify('success', { title: t('sentences.member_has_been_accepted_to_the_list') });
  };

  return (
    <PageAddon title={t('common.acceptance_list')} onClose={onClose}>
      <Form form={form} onSubmit={onSubmit}>
        <Controller
          name="room"
          control={control}
          rules={{ validate: { required } }}
          render={({ field }) => (
            <Select
              clearable={false}
              label={`${t('common.poker_room')}*`}
              options={trainerPokerRoomOptions}
              value={field.value}
              onChange={field.onChange}
              error={errors.room?.message}
            />
          )}
        />
        <Input
          label={`${t('common.club')}*`}
          error={errors.club?.message}
          {...register('club', {
            validate: required,
          })}
        />
        <Controller
          name="manager_id"
          control={control}
          rules={{ validate: { required } }}
          render={({ field }) => (
            <Select.AsyncV2
              onLoad={(args) => loadWorkspaceClubManagers({ workspaceId }, args)}
              select={(item) => ({ value: item.id, label: item.login })}
            >
              {({ options, ...rest }) => (
                <Select
                  clearable={false}
                  label={`${t('common.manager')}*`}
                  options={options}
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.manager_id?.message}
                  {...rest}
                />
              )}
            </Select.AsyncV2>
          )}
        />
        <Controller
          name="player_ids"
          control={control}
          rules={{ validate: { required } }}
          render={({ field }) => (
            <TagInput
              label={`${t('common.pids')}*`}
              values={field.value}
              onChange={field.onChange}
              error={errors.player_ids?.message}
            />
          )}
        />
        <PageAddon.Controls>
          <SubmitButton>{t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default WorkspaceClubMemberAcceptForm;
