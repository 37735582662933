import { updateWorkspaceAccountStrategyProfile } from '../../../api';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import PageAddon from '../../../components/PageAddon';
import AccountStrategyProfile from '../../account/AccountStrategyProfile';
import { useWorkspaceAccountStrategyProfileQuery } from './queries';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  account: WorkspaceAccountSchema;
}

const WorkspaceAccountStrategyProfile = (props: Props) => {
  const { workspaceId, account } = props;

  const { id: accountId } = account;

  const { data, isPending } = useWorkspaceAccountStrategyProfileQuery({
    workspaceId,
    accountId,
  });

  if (isPending) return <PageAddon.DetailPlaceholder wrapper={false} />;
  if (!data) return null;

  return (
    <AccountStrategyProfile
      data={data}
      onUpdate={(payload) =>
        updateWorkspaceAccountStrategyProfile({
          workspaceId,
          accountId,
          payload,
        })
      }
    />
  );
};

export default WorkspaceAccountStrategyProfile;
