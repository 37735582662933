import clsx from 'clsx';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Auth from '../../components/Auth';
import Button from '../../components/Button';
import Form from '../../components/Form';
import Input from '../../components/Input';
import PasswordInput from '../../components/PasswordInput';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';
import { logoutUser, setInvitedUser, loginUser } from '../../utils/auth';
import { maxLength, minLength, password as passwordValidation, required } from '../../utils/form';
import styles from './styles.module.scss';

interface Fields {
  password: string;
  maintenance_name: string;
}

type Step = 'first' | 'second';

const InvitePage = () => {
  const { t } = useTranslation();
  const { urlParams, navigate } = useURL();
  const { hash, username, create_maintenance } = urlParams;

  const [step, setStep] = useState<Step | false>(create_maintenance ? 'first' : false);

  const form = useForm<Fields>({
    defaultValues: { password: '', maintenance_name: '' },
    mode: 'onChange',
  });

  const {
    register,
    trigger,
    formState: { errors, isValid },
  } = form;

  const onSubmit = async (values: Fields) => {
    const { password, maintenance_name } = values;

    const payload = {
      password,
    };

    if (create_maintenance) {
      await setInvitedUser({ hash }, { ...payload, maintenance_name });
    } else {
      await setInvitedUser({ hash }, payload);
    }

    await logoutUser();
    await loginUser({ email: username, password });

    navigate(routes.login, { params: false });
  };

  const className = clsx(styles.dots, {
    [styles.first]: step === 'first',
    [styles.second]: step === 'second',
  });

  return (
    <Auth title={t('common.create_an_account')}>
      <Form form={form} onSubmit={onSubmit}>
        {step !== 'second' && (
          <>
            <Input label={t('common.email')} value={username} disabled />
            <PasswordInput
              error={errors.password?.message}
              {...register('password', { validate: passwordValidation })}
            />
          </>
        )}
        {step === 'second' && (
          <Input
            label={`${t('common.workspace_name')}*`}
            error={errors.maintenance_name?.message}
            {...register('maintenance_name', {
              validate: {
                required,
                minLength: minLength(),
                maxLength: maxLength(),
              },
            })}
          />
        )}
        {step === 'first' ? (
          <Button
            rtl
            size="large"
            icon={icon('chevronRight', 16)}
            onClick={() => {
              trigger();

              if (isValid) {
                setStep('second');
              }
            }}
          >
            {t('common.save_and_next')}
          </Button>
        ) : (
          <Auth.SubmitButton>{t('common.create')}</Auth.SubmitButton>
        )}
      </Form>
      {step === 'second' && (
        <Auth.Link back onClick={() => setStep('first')}>
          {t('common.back_to_password')}
        </Auth.Link>
      )}
      {create_maintenance && <div className={className} />}
    </Auth>
  );
};

export default InvitePage;
