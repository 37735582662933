import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import workspaceSchema from './workspaceSchema';

const workspaceTagSchema = z.object({
  id: z.string(),
  name: z.string(),
  color: z.string(),
  maintenance_id: workspaceSchema.shape.id,
  /*   maintenance: workspaceSchema
    .pick({
      id: true,
      name: true,
      owner_id: true,
      maintenance_types: true,
      is_deleted: true,
    }), */
  is_deleted: z.boolean(),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export type WorkspaceTagSchema = z.infer<typeof workspaceTagSchema>;

export default workspaceTagSchema;
