import { Fragment, ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  items: ReactNode[];
}

const PageAddonSubTitle = (props: Props) => {
  let { items } = props;

  items = items.filter(Boolean);

  return (
    <div className={styles.container}>
      {items.map((item, index) => (
        <Fragment key={index}>
          {item}
          {index + 1 !== items.length && <span>•</span>}
        </Fragment>
      ))}
    </div>
  );
};

export default PageAddonSubTitle;
