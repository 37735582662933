import { z } from 'zod';

const countersSchema = z.object({
  accounts_count: z.number(),
  channels_count: z.number(),
  identities_count: z.number(),
  users_count: z.number(),
  managers_count: z.number(),
});

export type CountersSchema = z.infer<typeof countersSchema>;

export default countersSchema;
