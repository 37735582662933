import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { GlobalLeagueSchema } from '../schemas/globalLeagueSchema';

type Schema = GlobalLeagueSchema;

interface Payload {
  name?: Schema['name'];
  super_league_id?: Exclude<Schema['super_league'], null>['id'] | null;
  extra?: Schema['extra'];
}

interface Args {
  leagueId: Schema['id'];
  payload: Payload;
}

const updateGlobalLeague = async (args: Args) => {
  const { leagueId, payload } = args;

  const response = await api.patch(`/api/reference/league/${leagueId}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateGlobalLeague;
