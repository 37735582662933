import { useEffect, useState } from 'react';
import usePrevious from '../../hooks/usePrevious';
import useURL from '../../hooks/useURL';
import useURLFilter from '../../hooks/useURLFilter';
import SearchInput from '../SearchInput';

const PageSearch = () => {
  const { urlParams } = useURL();
  const { search } = urlParams;
  const { setURLParams } = useURLFilter();

  const [value, setValue] = useState(search || '');

  const prevValue = usePrevious(value);

  useEffect(() => {
    if (value !== prevValue) {
      setURLParams({ search: value });
    }
  }, [value, prevValue, setURLParams]);

  useEffect(() => {
    if (!search) {
      setValue('');
    }
  }, [search]);

  return <SearchInput value={value} onChange={setValue} />;
};

export default PageSearch;
