import api from '../../services/api';
import URLParams from '../../types/URLParams';
import globalExpenseSchema from '../schemas/globalExpenseSchema';
import paginationSchemaNew from '../schemas/paginationSchemaNew';

const loadGlobalExpenses = async (params?: URLParams) => {
  const response = await api.get('/api/expenses', { params });

  return paginationSchemaNew(globalExpenseSchema).parse(response.data);
};

export default loadGlobalExpenses;
