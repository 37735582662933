import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { GlobalExpenseSchema } from '../schemas/globalExpenseSchema';

interface Args {
  expenseId: GlobalExpenseSchema['id'];
}

const deleteGlobalExpense = async (args: Args) => {
  const { expenseId } = args;

  const response = await api.delete(`/api/expenses/${expenseId}`);

  return emptyResponseSchema.parse(response.data);
};

export default deleteGlobalExpense;
