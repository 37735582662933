import { z } from 'zod';

export const TRAINER_LIMITS = {
  UNKNOWN: 0,
  C2: 1,
  C10: 2,
  C25: 3,
  C50: 4,
  C100: 5,
  C200: 6,
  C400: 7,
  C600: 8,
  C1000: 9,
  C2000: 10,
} as const;

const trainerLimitSchema = z.nativeEnum(TRAINER_LIMITS);

export type TrainerLimitSchema = z.infer<typeof trainerLimitSchema>;

export default trainerLimitSchema;
