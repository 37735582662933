import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import { WorkspaceWalletSchema } from '../../../api/schemas/workspaceWalletSchema';
import Button from '../../../components/Button';
import icon from '../../../static/icons';
import WorkspaceSettingsEmpty from '../WorkspaceSettingsEmpty';
import WorkspaceSettingsFieldGroup from '../WorkspaceSettingsFieldGroup';
import WorkspaceSettingsWalletsItem from './WorkspaceSettingsWalletsItem';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: WorkspaceWalletSchema[];
}

const WorkspaceSettingsWallets = (props: Props) => {
  const { workspaceId, data } = props;

  const { t } = useTranslation();

  const [creating, setCreating] = useState(false);

  const create = () => setCreating(true);

  if (!data.length && !creating) {
    return (
      <WorkspaceSettingsEmpty buttonText={t('common.wallet')} onClick={create}>
        {t('sentences.settings_wallets_empty')}
      </WorkspaceSettingsEmpty>
    );
  }

  const commonProps = { workspaceId };

  return (
    <WorkspaceSettingsFieldGroup
      renderAfter={
        <Button
          size="small"
          variant="secondary"
          disabled={creating}
          icon={icon('add', 16)}
          onClick={create}
        >
          {t('common.wallet')}
        </Button>
      }
    >
      {data.map((item) => (
        <WorkspaceSettingsWalletsItem key={item.id} data={item} {...commonProps} />
      ))}
      {creating && (
        <WorkspaceSettingsWalletsItem onRemove={() => setCreating(false)} {...commonProps} />
      )}
    </WorkspaceSettingsFieldGroup>
  );
};

export default WorkspaceSettingsWallets;
