import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AreaSchema } from '../../api/schemas/areaSchema';
import PageAddon from '../../components/PageAddon';
import icon from '../../static/icons';
import { formatAbsoluteDate } from '../../utils/date';
import GlobalAreaDetailDropdown from './GlobalAreaDetailDropdown';
import useForm from './useForm';

interface Props {
  data: AreaSchema;
  onClose: () => void;
}

const GlobalAreaDetail = (props: Props) => {
  const { data, onClose } = props;

  const { t } = useTranslation();

  const { id, name, is_deleted, created_on, modified_on } = data;

  const titleIcons = is_deleted && icon('trash', 12);

  const { form, fields } = useForm({ data });

  return (
    <PageAddon title={name} icons={titleIcons} onClose={onClose}>
      <FormProvider {...form}>
        <PageAddon.Fields>
          <PageAddon.Id id={id} />
          {fields.status}
          <PageAddon.Field label={t('common.modified')}>
            {formatAbsoluteDate(modified_on)}
          </PageAddon.Field>
          <PageAddon.Field label={t('common.created')}>
            {formatAbsoluteDate(created_on)}
          </PageAddon.Field>
          {fields.periods}
        </PageAddon.Fields>
        {!is_deleted && (
          <PageAddon.Controls>
            <PageAddon.Controls.Group>
              <GlobalAreaDetailDropdown data={data} />
            </PageAddon.Controls.Group>
          </PageAddon.Controls>
        )}
      </FormProvider>
    </PageAddon>
  );
};

export default GlobalAreaDetail;
