import { z } from 'zod';

export const CURRENCY = {
  UNKNOWN: 0,
  AED: 1,
  AFN: 2,
  ALL: 3,
  AMD: 4,
  ANG: 5,
  AOA: 6,
  ARS: 7,
  AUD: 8,
  AWG: 9,
  AZN: 10,
  BAM: 11,
  BBD: 12,
  BDT: 13,
  BGN: 14,
  BHD: 15,
  BIF: 16,
  BMD: 17,
  BND: 18,
  BOB: 19,
  BOV: 20,
  BRL: 21,
  BSD: 22,
  BTN: 23,
  BWP: 24,
  BYN: 25,
  BZD: 26,
  CAD: 27,
  CDF: 28,
  CHE: 29,
  CHF: 30,
  CHM: 31,
  CHW: 32,
  CLF: 33,
  CLP: 34,
  CNY: 35,
  COP: 36,
  COU: 37,
  CRC: 38,
  CUC: 39,
  CUP: 40,
  CVE: 41,
  CZK: 42,
  DJF: 43,
  DKK: 44,
  DOP: 45,
  DZD: 46,
  EGP: 47,
  ERN: 48,
  ETB: 49,
  EUR: 50,
  FJD: 51,
  FKP: 52,
  GBP: 53,
  GEL: 54,
  GHS: 55,
  GIP: 56,
  GMD: 57,
  GNF: 58,
  GTQ: 59,
  GYD: 60,
  HKD: 61,
  HNL: 62,
  HTG: 63,
  HUF: 64,
  IDR: 65,
  ILS: 66,
  INR: 67,
  IQD: 68,
  IRR: 69,
  ISK: 70,
  JMD: 71,
  JOD: 72,
  JPY: 73,
  KES: 74,
  KGS: 75,
  KHR: 76,
  KMF: 77,
  KPW: 78,
  KRW: 79,
  KWD: 80,
  KYD: 81,
  KZT: 82,
  LAK: 83,
  LBP: 84,
  LKR: 85,
  LRD: 86,
  LSL: 87,
  LYD: 88,
  MAD: 89,
  MDL: 90,
  MGA: 91,
  MKD: 92,
  MMK: 93,
  MNT: 94,
  MOP: 95,
  MRU: 96,
  MUR: 97,
  MVR: 98,
  MWK: 99,
  MXN: 100,
  MXV: 101,
  MYR: 102,
  MZN: 103,
  NAD: 104,
  NGN: 105,
  NIO: 106,
  NOK: 107,
  NPR: 108,
  NZD: 109,
  OMR: 110,
  PAB: 111,
  PEN: 112,
  PGK: 113,
  PHP: 114,
  PKR: 115,
  PLN: 116,
  PYG: 117,
  QAR: 118,
  RON: 119,
  RSD: 120,
  RUB: 121,
  RWF: 122,
  SAR: 123,
  SBD: 124,
  SCR: 125,
  SDG: 126,
  SEK: 127,
  SGD: 128,
  SHP: 129,
  SLE: 130,
  SOS: 131,
  SRD: 132,
  SSP: 133,
  STN: 134,
  SVC: 135,
  SYP: 136,
  SZL: 137,
  THB: 138,
  TJS: 139,
  TMT: 140,
  TND: 141,
  TOP: 142,
  TRY: 143,
  TTD: 144,
  TWD: 145,
  TZS: 146,
  UAH: 147,
  UGX: 148,
  USD: 149,
  USN: 150,
  UYI: 151,
  UYU: 152,
  UYW: 153,
  UZS: 154,
  VED: 155,
  VES: 156,
  VND: 157,
  VUV: 158,
  WST: 159,
  XAF: 160,
  XAG: 161,
  XAU: 162,
  XBA: 163,
  XBB: 164,
  XBC: 165,
  XBD: 166,
  XCD: 167,
  XDR: 168,
  XOF: 169,
  XPD: 170,
  XPF: 171,
  XPT: 172,
  XSU: 173,
  XTS: 174,
  XUA: 175,
  XXX: 176,
  YER: 177,
  ZAR: 178,
  ZMW: 179,
  ZWG: 180,
} as const;

const currencySchema = z.nativeEnum(CURRENCY);

export type CurrencySchema = z.infer<typeof currencySchema>;

export default currencySchema;
