import { matchSorter } from 'match-sorter';
import { useState } from 'react';
import { GlobalDealSchema } from '../../api/schemas/deal/globalDealSchema';
import Link from '../../components/Link';
import LinkButton from '../../components/LinkButton';
import PageAddon from '../../components/PageAddon';
import routes from '../../config/routes';

interface Props {
  data: GlobalDealSchema;
}

const GlobalDealDetailAccountsTab = (props: Props) => {
  const { data } = props;

  const { accounts } = data;

  const [search, setSearch] = useState('');

  const filteredAccounts = search ? matchSorter(accounts, search, { keys: ['login'] }) : accounts;

  return (
    <PageAddon.List search={search} onSearch={setSearch}>
      {filteredAccounts.map((item) => (
        <PageAddon.List.Item
          key={item.id}
          title={item.login}
          subTitle={`${item.workspace.name} • ${item.mga_id}`}
        >
          <Link
            target="_blank"
            params={false}
            to={`${routes.workspaceAccount({
              workspaceId: item.workspace.id,
              accountId: item.id,
            })}?search=${item.id}`}
          >
            <LinkButton size="small" variant="transparent" />
          </Link>
        </PageAddon.List.Item>
      ))}
    </PageAddon.List>
  );
};

export default GlobalDealDetailAccountsTab;
