import { isAfter, isBefore } from 'date-fns';
import URLParams from '../types/URLParams';

export const checkHasParams = (urlParams: URLParams) => Object.keys(urlParams).length === 0;

export const checkIn = (urlParams: URLParams, key: string, value?: number | string) => {
  if (!urlParams[key]) return true;
  if (!value) return false;

  return urlParams[key].split(',').includes(String(value));
};

export const checkInId = (urlParams: URLParams, key: string, value: { id: string }[]) => {
  if (!urlParams[key]) return true;

  return value.map((item) => item.id).some((item) => urlParams[key].split(',').includes(item));
};

export const checkInEnum = (urlParams: URLParams, key: string, value: number[]) => {
  if (!urlParams[key]) return true;

  return value.some((item) => urlParams[key].split(',').includes(String(item)));
};

export const checkBoolean = (
  urlParams: URLParams,
  key: string,
  value: boolean | Record<string, unknown> | null
) => {
  if (!urlParams[key]) return true;

  return String(Boolean(value)) === urlParams[key];
};

export const checkRange = (urlParams: URLParams, keys: [string, string], value: number) => {
  let [min, max] = keys;

  min = urlParams[min];
  max = urlParams[max];

  if (!min && !max) return true;
  if (min && !max) return value >= Number(min);
  if (max && !min) return value <= Number(max);

  return value >= Number(min) && value <= Number(max);
};

export const checkDate = (urlParams: URLParams, keys: [string, string], value?: string | null) => {
  let [min, max] = keys;

  min = urlParams[min];
  max = urlParams[max];

  if (!min && !max) return true;
  if (!value) return false;
  if (min && !max) return isAfter(value, min);
  if (max && !min) return isBefore(value, max);

  return isAfter(value, min) && isBefore(value, max);
};
