import { JsonSchema } from '../../../api/schemas/jsonSchema';
import { isObject } from '../../../utils';
import PageAddonCodeCollapse from './PageAddonCodeCollapse';
import PageAddonCodeRow from './PageAddonCodeRow';
import styles from './styles.module.scss';

interface Props {
  data: JsonSchema;
  nestingLevel?: number;
}

const PageAddonCode = (props: Props) => {
  const { data, nestingLevel = 1 } = props;

  if (data === null) return null;

  return (
    <div className={styles.container}>
      {Object.entries(data).map((item) => {
        const [label, value] = item;

        const key = JSON.stringify(item);

        if (Array.isArray(value) || isObject(value)) {
          return (
            <PageAddonCodeCollapse
              key={key}
              label={label}
              value={value}
              nestingLevel={nestingLevel}
            >
              <PageAddonCode data={value} nestingLevel={nestingLevel + 1} />
            </PageAddonCodeCollapse>
          );
        }

        return (
          <PageAddonCodeRow key={key} label={label} value={value} nestingLevel={nestingLevel} />
        );
      })}
    </div>
  );
};

export default PageAddonCode;
