import { MarketIdentitySchema } from '../../api/schemas/marketIdentitySchema';
import { presets } from '../../components/Dropdown';
import { getSellerName } from './helpers';

interface Args {
  data: MarketIdentitySchema;
}

const useDropdown = (args: Args) => {
  const { data } = args;

  const { id, is_deleted } = data;

  const title = getSellerName(data);

  return {
    title,
    disabled: is_deleted,
    options: {
      copy: presets.copyId(id),
    },
  };
};

export default useDropdown;
