import { format } from 'date-fns';
import { useState } from 'react';
import DatePicker from '../../../components/DatePicker';
import { DAY, DAY_TIME } from '../../../constants/DATE_FORMATS';
import { isDateBefore } from '../../../utils/date';
import styles from './styles.module.scss';

const StorybookPageDatePicker = () => {
  const [date, setDate] = useState(format(new Date(), DAY));
  const [dateWithTime, setDateWithTime] = useState(format(new Date(), DAY_TIME));
  const [placeholderDate, setPlaceholderData] = useState('');

  return (
    <>
      <h2>Date picker</h2>
      <div className={styles.datePickers}>
        <div className={styles.col}>
          <div className={styles.row}>
            <DatePicker label="Large" value={date} onChange={(value) => setDate(value)} />
            <DatePicker
              label="Medium"
              size="medium"
              value={date}
              onChange={(value) => setDate(value)}
            />
          </div>
          <div className={styles.wrapper}>
            <DatePicker
              label="With time"
              withTime
              value={dateWithTime}
              onChange={(value) => setDateWithTime(value)}
            />
            <DatePicker
              label="Disabled prev dates"
              value={date}
              onChange={(value) => setDate(value)}
              disabledDate={(d) => isDateBefore(d)}
            />
            <DatePicker
              label="Disabled"
              disabled
              value={date}
              onChange={(value) => setDate(value)}
            />
            <DatePicker
              label="Error"
              error="Required"
              value={date}
              onChange={(value) => setDate(value)}
            />
            <DatePicker
              placeholder="Placeholder"
              value={placeholderDate}
              onChange={(value) => setPlaceholderData(value)}
            />
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.wrapper}>
            <DatePicker
              quick
              withTime
              value={dateWithTime}
              onChange={(value) => setDateWithTime(value)}
              disabledDate={(d) => isDateBefore(d)}
            />
            <DatePicker quick disabled value={date} onChange={(value) => setDate(value)} />
            <DatePicker quick error="Required" value={date} onChange={(value) => setDate(value)} />
            <DatePicker
              quick
              placeholder="Placeholder"
              value={placeholderDate}
              onChange={(value) => setPlaceholderData(value)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StorybookPageDatePicker;
