import { useQuery } from '@tanstack/react-query';
import {
  deleteGlobalAccountSession,
  loadGlobalAccountSession,
  updateGlobalAccountSession,
} from '../../api';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import { GlobalSessionSchema } from '../../api/schemas/session/globalSessionSchema';
import queryKeys from '../../config/queryKeys';
import { can } from '../../utils/permissions';
import AccountScheduleDialogForm from '../account/AccountSchedule/AccountScheduleDialogForm';
import AccountScheduleDialogSpinner from '../account/AccountSchedule/AccountScheduleDialogSpinner';

interface Props {
  sessionId: GlobalSessionSchema['id'];
  account: GlobalAccountSchema;
  onClose: () => void;
}

const GlobalAccountScheduleDialog = (props: Props) => {
  const { sessionId, account, onClose } = props;

  const { id: accountId } = account;

  const queryKey = [queryKeys.globalAccountScheduleSession({ accountId, sessionId })];

  const queryFn = () => loadGlobalAccountSession({ accountId, sessionId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  if (isPending) return <AccountScheduleDialogSpinner />;

  if (!data) return null;

  return (
    <AccountScheduleDialogForm
      data={data}
      account={account}
      canDelete={can.session.delete}
      onDelete={() =>
        deleteGlobalAccountSession({
          accountId,
          sessionId,
        })
      }
      onUpdate={(payload) => updateGlobalAccountSession({ accountId, sessionId, payload })}
      onClose={onClose}
    />
  );
};

export default GlobalAccountScheduleDialog;
