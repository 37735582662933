import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import workspaceSchema from './workspaceSchema';
import workspaceWalletSchema from './workspaceWalletSchema';

export const TYPE = {
  UNKNOWN: 0,
  DEPOSIT: 1,
  WITHDRAW: 2,
  BUY: 3,
  SELL: 4,
  POKER_HAND_BILLING: 5,
} as const;

export const STATUS = {
  UNKNOWN: 0,
  PROCESS: 1,
  DONE: 2,
} as const;

const typeSchema = z.nativeEnum(TYPE);
const statusSchema = z.nativeEnum(STATUS);

const orderItemSchema = z.object({
  id: z.string(),
  domain: z.enum(['Identity', 'Account']),
  description: z.string(),
  extra: z.record(z.union([z.number(), z.string(), z.boolean()])).or(z.null()),
});

const orderSchema = z.object({
  id: z.string(),
  maintenance: workspaceSchema,
  maintenance_from: workspaceSchema.or(z.null()),
  maintenance_to: workspaceSchema.or(z.null()),
  type: typeSchema,
  status: statusSchema,
  amount: z.number(),
  wallet_from: workspaceWalletSchema.or(z.null()),
  wallet_to: workspaceWalletSchema.or(z.null()),
  order_item: orderItemSchema.or(z.null()),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export const orderFilterValuesSchema = z.object({
  amount: z.object({ min: z.number(), max: z.number() }),
  type: z.array(typeSchema),
  status: z.array(statusSchema),
});

export type OrderSchema = z.infer<typeof orderSchema>;
export type OrderFilterValuesSchema = z.infer<typeof orderFilterValuesSchema>;

export default orderSchema;
