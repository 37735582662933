import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const deleteWorkspace = async (args: Args) => {
  const { workspaceId } = args;

  const response = await api.delete(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}`);

  return emptyResponseSchema.parse(response.data);
};

export default deleteWorkspace;
