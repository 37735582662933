/* eslint-disable react/button-has-type */

import clsx from 'clsx';
import { forwardRef } from 'react';
import Spinner from '../Spinner';
import ButtonGroup from './ButtonGroup';
import styles from './styles.module.scss';
import { Props } from './types';

const Button = Object.assign(
  forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const {
      variant = 'primary',
      size = 'medium',
      type = 'button',
      danger,
      loading,
      disabled,
      focused,
      rtl,
      icon,
      children,
      className: customClassName,
      ...rest
    } = props;

    const className = clsx(variant === 'light' ? styles.lightButton : styles.button, {
      [styles[variant]]: variant !== 'light',
      [styles[size]]: true,
      [styles.danger]: danger,
      [styles.loading]: loading,
      [styles.focused]: focused,
      [styles.rtl]: rtl,
      [styles.onlyIcon]: icon && !children,
      ...(customClassName && { [customClassName]: true }),
    });

    return (
      <button ref={ref} type={type} className={className} disabled={disabled || loading} {...rest}>
        {loading ? <Spinner className={styles.spinner} /> : icon}
        {children}
      </button>
    );
  }),
  {
    Group: ButtonGroup,
  }
);

export default Button;
