import { create } from 'zustand';

interface State {
  open: boolean;
  show: () => void;
  hide: () => void;
  toggle: () => void;
}

const useSidebarStore = create<State>()((set) => ({
  open: false,
  show: () => set(() => ({ open: true })),
  hide: () => set(() => ({ open: false })),
  toggle: () => set(({ open }) => ({ open: !open })),
}));

export { useSidebarStore };
