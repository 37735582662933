import { useTranslation } from 'react-i18next';
import { GlobalSuperLeagueSchema } from '../../api/schemas/globalSuperLeagueSchema';
import Button from '../../components/Button';
import FadingText from '../../components/FadingText';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import { formatAbsoluteDate } from '../../utils/date';
import { can } from '../../utils/permissions';
import { TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';
import GlobalSuperLeagueDetailDropdown from './GlobalSuperLeagueDetailDropdown';

interface Props {
  data: GlobalSuperLeagueSchema;
  onClose: () => void;
  onEdit: () => void;
}

const GlobalSuperLeagueDetail = (props: Props) => {
  const { data, onClose, onEdit } = props;

  const { t } = useTranslation();

  const { id, code, name, poker_room, extra, is_deleted, created_on, modified_on } = data;

  const titleIcons = is_deleted && icon('trash', 12);

  return (
    <PageAddon title={code} icons={titleIcons} onClose={onClose}>
      <PageAddon.Fields>
        <PageAddon.Id id={id} />
        <PageAddon.Field label={t('common.name')}>
          <FadingText text={name} tooltip={false} />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.poker_room')}>
          {TRAINER_POKER_ROOM_LABELS[poker_room]}
        </PageAddon.Field>
        <PageAddon.ExtraFields data={extra} />
        <PageAddon.Field label={t('common.modified')}>
          {formatAbsoluteDate(modified_on)}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.created')}>
          {formatAbsoluteDate(created_on)}
        </PageAddon.Field>
      </PageAddon.Fields>
      {!is_deleted && (
        <PageAddon.Controls>
          <PageAddon.Controls.Group>
            <Tooltip
              label={can.club.createUpdate ? t('common.edit') : t('common.no_permission')}
              hideOnMobile={can.club.createUpdate}
            >
              <div>
                <Button
                  variant="transparent"
                  icon={icon('edit', 16)}
                  onClick={onEdit}
                  data-testid="edit"
                  disabled={!can.club.createUpdate}
                />
              </div>
            </Tooltip>
            <GlobalSuperLeagueDetailDropdown data={data} />
          </PageAddon.Controls.Group>
        </PageAddon.Controls>
      )}
    </PageAddon>
  );
};

export default GlobalSuperLeagueDetail;
