import { useTranslation } from 'react-i18next';
import { bulkCreateWorkspaceChannels } from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import ImportFromCSV from '../../components/ImportFromCSV';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
}

const WorkspaceChannelImport = (props: Props) => {
  const { workspaceId, onClose } = props;

  const { t } = useTranslation();

  const sample =
    'host,port,username,password\nchannel_host,channel_port,channel_username,channel_password';

  return (
    <ImportFromCSV
      onClose={onClose}
      sample={sample}
      title={t('common.bulk_import_channels')}
      onImport={(formData) => bulkCreateWorkspaceChannels({ workspaceId, payload: formData })}
    />
  );
};

export default WorkspaceChannelImport;
