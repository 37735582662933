import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { STATUS } from '../../../api/schemas/session';
import Tooltip from '../../../components/Tooltip';
import { CommonSessionSchema } from '../types';
import useDuration from '../useDuration';
import styles from './styles.module.scss';

interface Props {
  data: CommonSessionSchema;
}

const SessionDuration = (props: Props) => {
  const { data } = props;

  const { status } = data;

  const { t } = useTranslation();

  const {
    duration,
    timeRemains,
    delay,
    durationInHours,
    timeRemainsInHours,
    delayInHours,
    delayed,
  } = useDuration(props);

  const getWidth = () => {
    if (status === STATUS.SENT_FINISH || status === STATUS.FINISHED || delay) {
      return '100%';
    }

    if (timeRemains !== null) return `calc(100% * ${duration - timeRemains} / ${duration})`;

    return 0;
  };

  return (
    <Tooltip
      label={
        <div className={styles.tooltip}>
          <div>
            <div>{t('common.duration')}</div>
            <div className={styles.dottedLine} />
            <div>{durationInHours}</div>
          </div>
          {Boolean(timeRemains) && !delay && (
            <div>
              <div>{t('common.time_remains')}</div>
              <div className={styles.dottedLine} />
              <div>{timeRemainsInHours}</div>
            </div>
          )}
          {delayed && (
            <div>
              <div>{t('common.delayed')}</div>
              <div className={styles.dottedLine} />
              <div>{delayInHours}</div>
            </div>
          )}
        </div>
      }
      hideOnMobile
    >
      <div className={styles.container}>
        <div className={styles.scale}>
          <div
            style={{ width: getWidth() }}
            className={clsx(styles.filledPartOfScale, delayed && styles.danger)}
          />
        </div>
      </div>
    </Tooltip>
  );
};

export default SessionDuration;
