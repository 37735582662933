import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { WorkspaceStageSchema } from '../../../../api/schemas/stage/workspaceStageSchema';
import Checkbox from '../../../../components/Checkbox';
import Dialog from '../../../../components/Dialog';
import Form from '../../../../components/Form';
import Input from '../../../../components/Input';
import SubmitButton from '../../../../components/SubmitButton';
import { required } from '../../../../utils/form';
import notify from '../../../../utils/notify';
import styles from './styles.module.scss';

interface Fields {
  name: string;
  is_playable: boolean;
  stop_current_session: boolean;
  clear_channel: boolean;
  clear_schedule: boolean;
}

interface Props {
  onSubmit: (values: Fields) => void;
  data?: WorkspaceStageSchema;
}

const WorkspaceAccountStagesForm = (props: Props) => {
  const { onSubmit, data } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: {
      name: data?.name || '',
      is_playable: data?.is_playable || false,
      stop_current_session: data?.stop_current_session || false,
      clear_channel: data?.clear_channel || false,
      clear_schedule: data?.clear_schedule || false,
    },
  });

  const {
    register,
    formState: { errors },
  } = form;

  return (
    <Form
      form={form}
      className={styles.form}
      onSubmit={async (values) => {
        await onSubmit(values);

        notify('success', {
          title: data
            ? t('sentences.changes_have_been_saved')
            : t('sentences.record_has_been_created'),
        });
      }}
    >
      <div className={styles.container}>
        <Input
          label={`${t('common.name')}*`}
          error={errors.name?.message}
          {...register('name', { validate: { required } })}
        />
        <div className={styles.wrapper}>
          <div className={styles.heading}>
            {t('sentences.these_options_apply_only_to_accounts_in_this_stage')}
          </div>
          <Checkbox variant="switch" {...register('is_playable')}>
            {t('sentences.start_planned_sessions')}
          </Checkbox>
          <Checkbox variant="switch" {...register('stop_current_session')}>
            {t('sentences.stop_active_session')}
          </Checkbox>
          <Checkbox variant="switch" {...register('clear_channel')}>
            {t('sentences.remove_channels')}
          </Checkbox>
          <Checkbox variant="switch" {...register('clear_schedule')}>
            {t('sentences.remove_planned_sessions')}
          </Checkbox>
        </div>
      </div>
      <Dialog.Footer>
        <SubmitButton>{data ? t('common.save') : t('common.create')}</SubmitButton>
      </Dialog.Footer>
    </Form>
  );
};

export default WorkspaceAccountStagesForm;
