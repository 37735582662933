import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadWorkspaceAddress } from '../../../api';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Button from '../../../components/Button';
import Dialog, { Props as DialogProps } from '../../../components/Dialog';
import QRCode from '../../../components/QRCode';
import queryKeys from '../../../config/queryKeys';
import TetherIcon from '../../../static/wallets/tether.svg';
import { copy } from '../../../utils/clipboard';
import { convertCentsToUSDT } from '../../../utils/data';
import { useWorkspaceBalanceQuery } from '../WorkspaceSettingsGeneral/queries';
import styles from './styles.module.scss';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  onClose?: () => void;
  children?: DialogProps['label'];
}

const WorkspaceSettingsDepositDialog = (props: Props) => {
  const { workspaceId, onClose, children } = props;

  const { t } = useTranslation();

  const queryFn = () => loadWorkspaceAddress({ workspaceId });
  const queryKey = [queryKeys.workspaceAddress({ workspaceId })];

  const { data: addressData } = useQuery({ queryKey, queryFn });
  const { data: balanceData } = useWorkspaceBalanceQuery({ workspaceId });

  return (
    <Dialog label={children} onClose={onClose} className={styles.dialog}>
      {({ close }) => (
        <>
          <Dialog.Top>
            <Dialog.Title>{t('common.deposit')}</Dialog.Title>
          </Dialog.Top>
          <div className={styles.container}>
            <div className={styles.description}>
              {t('sentences.workspace_balance_deposit_description')}
            </div>
            {addressData && (
              <div className={styles.qr}>
                <QRCode value={addressData.tron_address} size={160} />
              </div>
            )}
            <div className={styles.wallet}>
              <TetherIcon width={24} height={24} />
              USDT TRC-20
            </div>
            {addressData && (
              <div className={styles.hash}>
                <button type="button" onClick={() => copy(addressData.tron_address)}>
                  {addressData.tron_address}
                </button>
              </div>
            )}
          </div>
          <div className={styles.balance}>
            <div className={styles.balanceTitle}>{t('common.balance_USDT')}</div>
            <div className={styles.balanceLine} />
            {balanceData && (
              <div className={styles.balanceValue}>{convertCentsToUSDT(balanceData.balance)}</div>
            )}
          </div>
          <Dialog.Footer>
            <Button onClick={() => close()}>{t('common.close')}</Button>
          </Dialog.Footer>
        </>
      )}
    </Dialog>
  );
};

export default WorkspaceSettingsDepositDialog;
