import { BotSettingRuleSchema } from '../../api/schemas/botSettingRuleSchema';
import { BotSettingSchema } from '../../api/schemas/botSettingSchema';
import { TRAINER_GAME_TYPE_LABELS, TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';

export const getDefaultValues = (data?: BotSettingRuleSchema) => {
  if (data) {
    const { name, game_type, bot_settings, poker_room, maintenance, description } = data;

    return {
      name,
      game_type: { value: game_type, label: TRAINER_GAME_TYPE_LABELS[game_type] },
      bot_settings_id: { value: bot_settings.id, label: bot_settings.name },
      poker_room:
        poker_room !== null
          ? { value: poker_room, label: TRAINER_POKER_ROOM_LABELS[poker_room] }
          : null,
      maintenance_id: maintenance ? { value: maintenance.id, label: maintenance.name } : null,
      description,
    };
  }

  return {
    name: '',
    game_type: null,
    bot_settings_id: null,
    poker_room: null,
    maintenance_id: null,
    description: '',
  };
};

export const getPokerRoom = (data: BotSettingRuleSchema) => {
  const { poker_room } = data;

  if (poker_room) return TRAINER_POKER_ROOM_LABELS[poker_room];

  return '—';
};

export const getWorkspace = (data: BotSettingRuleSchema) => data.maintenance?.name || '—';

export const getBotSettingCaption = (data: BotSettingSchema) =>
  `${TRAINER_GAME_TYPE_LABELS[data.game_type]} • ${data.bot_code} • ${data.bot_build}`;
