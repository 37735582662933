import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceClubWhitelistSchema } from '../schemas/workspaceClubWhitelistSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  whitelistMemberId: WorkspaceClubWhitelistSchema['id'];
}

const deleteWorkspaceClubWhitelistMember = async (args: Args) => {
  const { workspaceId, whitelistMemberId } = args;

  const response = await api.delete(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/club-manager/white-list/${whitelistMemberId}`
  );

  return emptyResponseSchema.parse(response.data);
};

export default deleteWorkspaceClubWhitelistMember;
