import FadingText, { Props as FadingTextProps } from '../../FadingText';

interface Props {
  text: FadingTextProps['text'];
  tooltipLabel?: FadingTextProps['tooltipLabel'];
  onChange?: FadingTextProps['onChange'];
}

const TagFadingText = (props: Props) => {
  const { text, tooltipLabel, onChange } = props;

  return <FadingText text={text} tooltipLabel={tooltipLabel} onChange={onChange} />;
};

export default TagFadingText;
