import useURL from '../../hooks/useURL';

const useCurrentStage = () => {
  const { urlParams } = useURL();

  const { stage_order_eq } = urlParams;

  const currentStage = Number(stage_order_eq) || 1;

  return { currentStage };
};

export default useCurrentStage;
