import { useTranslation } from 'react-i18next';
import { MarketIdentitySchema } from '../../api/schemas/marketIdentitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import icon from '../../static/icons';
import { getSellerName } from './helpers';
import WorkspaceMarketIdentityCartButton from './WorkspaceMarketIdentityCart/WorkspaceMarketIdentityCartButton';
import WorkspaceMarketIdentityDetailDetailsTab from './WorkspaceMarketIdentityDetailDetailsTab';
import WorkspaceMarketIdentityDetailTabGeneral from './WorkspaceMarketIdentityDetailTabGeneral';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: MarketIdentitySchema;
  onClose: () => void;
}

const WorkspaceMarketIdentityDetail = (props: Props) => {
  const { workspaceId, data, onClose } = props;

  const { t } = useTranslation();

  const { is_deleted } = data;

  const titleIcons = is_deleted && icon('trash', 12);

  return (
    <PageAddon
      onClose={onClose}
      title={getSellerName(data)}
      icons={titleIcons}
      tabs={[
        {
          label: t('common.general'),
          content: <WorkspaceMarketIdentityDetailTabGeneral data={data} />,
        },
        {
          label: t('common.details'),
          content: <WorkspaceMarketIdentityDetailDetailsTab data={data} />,
        },
      ]}
    >
      <PageAddon.Controls>
        <PageAddon.Controls.Group>
          <WorkspaceMarketIdentityCartButton workspaceId={workspaceId} data={data} isDetail />
        </PageAddon.Controls.Group>
      </PageAddon.Controls>
    </PageAddon>
  );
};

export default WorkspaceMarketIdentityDetail;
