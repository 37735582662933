import { changeWorkspaceClubManagerPassword } from '../../../api';
import { WorkspaceClubManagerSchema } from '../../../api/schemas/workspaceClubManagerSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import ChangePasswordDialog from '../../../components/ChangePasswordDialog';

interface Props {
  id: WorkspaceSchema['id'];
  data: WorkspaceClubManagerSchema;
  onClose: () => void;
}

const WorkspaceClubManagerChangePasswordDialog = (props: Props) => {
  const { id, data, onClose } = props;

  return (
    <ChangePasswordDialog
      onClose={onClose}
      onSubmit={(values) =>
        changeWorkspaceClubManagerPassword({
          workspaceId: id,
          managerId: data.id,
          payload: { password: values.password },
        })
      }
    />
  );
};

export default WorkspaceClubManagerChangePasswordDialog;
