import { addDays, differenceInCalendarDays, format } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  bulkDuplicateWorkspaceAccountSessions,
  bulkFilteredDuplicateWorkspaceAccountSessions,
  duplicateWorkspaceAccountSessions,
} from '../../../api';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Alert from '../../../components/Alert';
import DatePicker from '../../../components/DatePicker';
import Dialog from '../../../components/Dialog';
import Form from '../../../components/Form';
import Label from '../../../components/Label';
import SubmitButton from '../../../components/SubmitButton';
import { DAY, DAY_TIME } from '../../../constants/DATE_FORMATS';
import useTimeZone from '../../../hooks/useTimeZone';
import { isDateAfter, isDateBefore } from '../../../utils/date';
import { required, validDateFormat } from '../../../utils/form';
import notify from '../../../utils/notify';
import useBulkActions from '../useBulkActions';
import styles from './styles.module.scss';

interface Fields {
  source_from: string;
  source_to: string;
  target_from: string;
  target_to: string;
}

interface Props {
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
  accountId?: WorkspaceAccountSchema['id'];
}

const WorkspaceAccountDuplicateSessionsDialog = (props: Props) => {
  const { workspaceId, onClose, accountId } = props;

  const { t } = useTranslation();
  const { dateToZonedDateTime, dateTimeToZonedDateTime } = useTimeZone();
  const { ids, params, selectAllMode, handle } = useBulkActions();

  const form = useForm<Fields>({
    defaultValues: { source_from: '', source_to: '', target_from: '', target_to: '' },
  });

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const { source_from, source_to } = watch();

  const setTargetTo = () => {
    const values = form.watch();

    if (values.source_from && values.source_to && values.target_from) {
      const daysCount = differenceInCalendarDays(new Date(source_to), new Date(values.source_from));

      const targetTo = addDays(new Date(values.target_from), daysCount);

      setValue('target_to', format(targetTo, DAY));
    }
  };

  const onSubmit = async (values: Fields) => {
    const payload = {
      source_from: dateTimeToZonedDateTime(values.source_from),
      source_to: dateTimeToZonedDateTime(values.source_to),
      target_from: dateToZonedDateTime(values.target_from),
    };

    if (accountId) {
      await duplicateWorkspaceAccountSessions({ workspaceId, accountId, payload });
    } else {
      await handle(
        () => {
          const fn = selectAllMode
            ? bulkFilteredDuplicateWorkspaceAccountSessions
            : bulkDuplicateWorkspaceAccountSessions;

          return fn({
            workspaceId,
            payload: { ...payload, account_ids: ids },
            params,
          });
        },
        { notify: false }
      );
    }

    notify('success', { title: t('sentences.schedule_has_been_duplicate') });
    onClose();
  };

  return (
    <Dialog className={styles.dialog} onClose={onClose}>
      {() => (
        <>
          <Dialog.Top>
            <Dialog.Title>{t('common.duplicate_schedule')}</Dialog.Title>
          </Dialog.Top>
          <Form form={form} onSubmit={onSubmit} className={styles.form}>
            <div>
              <Alert
                type="info"
                title={t('sentences.duplicate_schedule_description')}
                closable={false}
              />
              <div>
                <Label>{t('common.copy')}</Label>
                <div className={styles.controllersGroup}>
                  <Controller
                    control={control}
                    name="source_from"
                    rules={{ validate: { required, validDate: validDateFormat(DAY_TIME) } }}
                    render={({ field }) => (
                      <DatePicker
                        withTime
                        size="medium"
                        value={field.value}
                        onChange={(value) => {
                          field.onChange(value);

                          if (value) {
                            setTargetTo();
                          } else {
                            setValue('target_from', '');
                            setValue('target_to', '');
                          }
                        }}
                        placeholder={t('common.from')}
                        {...(source_to && { disabledDate: (date) => isDateAfter(date, source_to) })}
                        error={errors.source_from?.message}
                      />
                    )}
                  />
                  <span>—</span>
                  <Controller
                    control={control}
                    name="source_to"
                    rules={{ validate: { required, validDate: validDateFormat(DAY_TIME) } }}
                    render={({ field }) => (
                      <DatePicker
                        withTime={{ selectEndOfDay: true }}
                        size="medium"
                        value={field.value}
                        onChange={(value) => {
                          field.onChange(value);

                          if (value) {
                            setTargetTo();
                          } else {
                            setValue('target_from', '');
                            setValue('target_to', '');
                          }
                        }}
                        placeholder={t('common.to')}
                        {...(source_from && {
                          disabledDate: (date) => isDateBefore(date, source_from),
                        })}
                        error={errors.source_to?.message}
                      />
                    )}
                  />
                </div>
              </div>
              <div>
                <Label>{t('common.insert')}</Label>
                <div className={styles.controllersGroup}>
                  <Controller
                    control={control}
                    name="target_from"
                    rules={{ validate: { required, validDate: validDateFormat(DAY) } }}
                    render={({ field }) => (
                      <DatePicker
                        size="medium"
                        value={field.value}
                        onChange={(value) => {
                          field.onChange(value);

                          if (value) {
                            setTargetTo();
                          } else {
                            setValue('target_to', '');
                          }
                        }}
                        disabled={!source_from || !source_to}
                        placeholder={t('common.from')}
                        disabledDate={(date) => !isDateAfter(date, source_to)}
                        error={errors.target_from?.message}
                      />
                    )}
                  />
                  <span>—</span>
                  <Controller
                    control={control}
                    name="target_to"
                    rules={{ validate: { required, validDate: validDateFormat(DAY) } }}
                    render={({ field }) => (
                      <DatePicker
                        disabled
                        size="medium"
                        placeholder={t('common.to')}
                        error={errors.source_to?.message}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <Dialog.Footer>
              <SubmitButton>{t('common.duplicate')}</SubmitButton>
            </Dialog.Footer>
          </Form>
        </>
      )}
    </Dialog>
  );
};

export default WorkspaceAccountDuplicateSessionsDialog;
