import { useState } from 'react';
import { loadGlobalUsers } from '../../../api';
import Select, { Option, Options } from '../../../components/Select';
import icon from '../../../static/icons';
import styles from './styles.module.scss';

const fruitsOptions = [
  {
    value: 'apple',
    label: 'Apple 🍎',
    icon: icon('hash', 16),
    caption: 'Caption',
  },
  {
    value: 'banana',
    label: 'Banana 🍌',
    icon: icon('hash', 16),
    caption: 'Caption',
  },
  {
    value: 'orange',
    label: 'Orange 🍊',
    icon: icon('hash', 16),
    caption: 'Caption',
  },
  {
    value: 'kiwi',
    label: 'Kiwi 🥝',
    icon: icon('hash', 16),
    caption: 'Caption',
  },
  {
    value: 'mango',
    label: 'Mango 🥭',
    icon: icon('hash', 16),
    caption: 'Caption',
  },
  {
    value: 'peach',
    label: 'Peach 🍑',
    icon: icon('hash', 16),
    caption: 'Caption',
  },
];

const StorybookPageSelect = () => {
  const [fruit, setFruit] = useState<Option>(null);
  const [fruits, setFruits] = useState<Options>([]);
  const [user, setUser] = useState<Option>(null);
  const [users, setUsers] = useState<Options>([]);

  return (
    <>
      <h2>Select</h2>
      <div className={styles.selects}>
        <Select
          value={fruit}
          label="Fruits"
          onChange={(option) => setFruit(option)}
          options={fruitsOptions}
        />
      </div>
      <h2>Multi select</h2>
      <div className={styles.selects}>
        <Select.Multi
          value={fruits}
          label="Multi Fruits"
          onChange={(value) => setFruits(value)}
          options={fruitsOptions}
        />
        <Select.Multi
          value={fruits}
          label="Multi Fruits"
          onChange={(value) => setFruits(value)}
          renderValueAsTag
          options={fruitsOptions}
        />
      </div>
      <h2>Async select</h2>
      <div className={styles.selects}>
        <Select.Async
          onLoad={async (args) => {
            const response = await loadGlobalUsers(args);

            return response.items.map((item) => ({
              value: item.id,
              label: item.username,
              icon: icon('user', 16),
              caption: 'Caption',
            }));
          }}
        >
          {(selectProps) => (
            <Select
              value={user}
              label="User"
              onChange={(option) => setUser(option)}
              {...selectProps}
            />
          )}
        </Select.Async>
        <Select.Async
          onLoad={async (args) => {
            const response = await loadGlobalUsers(args);

            return response.items.map((item) => ({
              value: item.id,
              label: item.username,
              icon: icon('user', 16),
              caption: 'Caption',
            }));
          }}
        >
          {(selectProps) => (
            <Select.Multi
              value={users}
              label="Users"
              onChange={(options) => setUsers(options)}
              {...selectProps}
            />
          )}
        </Select.Async>
        <Select.Async
          onLoad={async (args) => {
            const response = await loadGlobalUsers(args);

            return response.items.map((item) => ({
              value: item.id,
              label: item.username,
              icon: icon('user', 16),
              caption: 'Caption',
            }));
          }}
        >
          {(selectProps) => (
            <Select.Multi
              value={users}
              label="Users"
              renderValueAsTag
              onChange={(options) => setUsers(options)}
              {...selectProps}
            />
          )}
        </Select.Async>
      </div>
    </>
  );
};

export default StorybookPageSelect;
