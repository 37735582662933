import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import paginationSchemaNew from '../schemas/paginationSchemaNew';
import { WorkspaceSchema } from '../schemas/workspaceSchema';
import workspaceTagSchema from '../schemas/workspaceTagSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceTags = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(`/api/v2/${ALIASES.WORKSPACE.L}/${workspaceId}/tag`, {
    params,
  });

  return paginationSchemaNew(workspaceTagSchema).parse(response.data);
};

export default loadWorkspaceTags;
