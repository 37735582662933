import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import seatControlRuleSchema, { SeatControlRuleSchema } from '../schemas/seatControlRuleSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = SeatControlRuleSchema;

interface Payload {
  name: Schema['name'];
  query: Schema['query'];
  action_type?: Schema['action_type'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const createWorkspaceSeatControlRule = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.post(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/seat-control`,
    payload
  );

  return seatControlRuleSchema.pick({ id: true }).parse(response.data);
};

export default createWorkspaceSeatControlRule;
