import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { GlobalClubSchema } from '../schemas/globalClubSchema';
import paginationSchema from '../schemas/paginationSchema';
import workspaceSchema from '../schemas/workspaceSchema';

interface Args {
  clubId: GlobalClubSchema['id'];
}

const loadGlobalClubWorkspaces = async (args: Args, params?: URLParams) => {
  const { clubId } = args;

  const response = await api.get(`/api/reference/club/${clubId}/${ALIASES.WORKSPACES.L}`, {
    params,
  });

  return paginationSchema(workspaceSchema).parse(response.data);
};

export default loadGlobalClubWorkspaces;
