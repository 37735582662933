import { Payload } from '../../components/Notes';
import api from '../../services/api';
import { AgentSchema } from '../schemas/agentSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { NoteSchema } from '../schemas/noteSchema';

interface Args {
  agentId: AgentSchema['id'];
  noteId: NoteSchema['id'];
  payload: Payload;
}

const updateGlobalAgentNote = async (args: Args) => {
  const { agentId, noteId, payload } = args;

  const response = await api.patch(`/api/agent/${agentId}/notes/${noteId}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateGlobalAgentNote;
