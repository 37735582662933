import { Navigate, Outlet } from 'react-router-dom';
import routes from '../../config/routes';
import { useSessionStore } from '../../store/session';
import { useUserStore } from '../../store/user';
import Sidebar from '../Sidebar';

const Layout = () => {
  const { session } = useSessionStore((state) => ({ session: state.session }));
  const { workspaceId } = useUserStore((state) => ({ workspaceId: state.workspace?.id }));

  if (!session) return <Navigate to={routes.login} />;

  return (
    <>
      <Sidebar />
      <Outlet key={workspaceId} />
    </>
  );
};

export default Layout;
