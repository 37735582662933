import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '../../utils/queryClient';
import App from '../App';

const Root = () => (
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
);

export default Root;
