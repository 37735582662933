import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { IdentitySchema } from '../schemas/identitySchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  identityId: IdentitySchema['id'];
}

const removeWorkspaceIdentityFromMarket = async (args: Args) => {
  const { workspaceId, identityId } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/identity/${identityId}/remove-from-market`
  );

  return emptyResponseSchema.parse(response.data);
};

export default removeWorkspaceIdentityFromMarket;
