import { useTranslation } from 'react-i18next';
import { JsonSchema } from '../../../api/schemas/jsonSchema';
import PageAddonCode from '../PageAddonCode';
import PageAddonCollapse from '../PageAddonCollapse';

interface Props {
  data: JsonSchema;
}

const PageAddonExtraFields = (props: Props) => {
  const { data } = props;

  const { t } = useTranslation();

  if (!data) return null;

  if (Object.keys(data).length === 0) return null;

  return (
    <PageAddonCollapse label={t('common.extra_fields')}>
      <PageAddonCode data={data} />
    </PageAddonCollapse>
  );
};

export default PageAddonExtraFields;
