import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceSchema } from '../schemas/workspaceSchema';
import workspaceWalletSchema, { WorkspaceWalletSchema } from '../schemas/workspaceWalletSchema';

type Schema = WorkspaceWalletSchema;

interface Payload {
  address: Schema['address'];
  type: Schema['type'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  payload: Payload;
}

const createWorkspaceWallet = async (args: Args) => {
  const { workspaceId, payload } = args;

  const response = await api.post(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/wallet`, payload);

  return workspaceWalletSchema.pick({ id: true }).parse(response.data);
};

export default createWorkspaceWallet;
