import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { updateGlobalRole } from '../../../api';
import { GlobalRoleSchema } from '../../../api/schemas/globalRoleSchema';
import Dialog from '../../../components/Dialog';
import Form from '../../../components/Form';
import Input from '../../../components/Input';
import SubmitButton from '../../../components/SubmitButton';
import { maxLength, minLength, required } from '../../../utils/form';
import notify from '../../../utils/notify';
import styles from './styles.module.scss';

interface Fields {
  name: string;
}

interface Props {
  data: GlobalRoleSchema;
  onClose: () => void;
}

const GlobalRoleChangeNameDialog = (props: Props) => {
  const { data, onClose } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({ defaultValues: { name: data.name } });

  const {
    register,
    formState: { errors },
  } = form;

  return (
    <Dialog onClose={onClose}>
      {({ close }) => {
        const handleSubmit = async (values: Fields) => {
          await updateGlobalRole({
            roleId: data.id,
            payload: { name: values.name },
          });

          notify('success', { title: t('sentences.name_has_been_changed') });
          close({ confirmClose: false });
        };

        return (
          <>
            <Dialog.Top>
              <Dialog.Title>{t('common.change_name')}</Dialog.Title>
            </Dialog.Top>
            <Form form={form} onSubmit={handleSubmit} className={styles.form}>
              <Input
                label={`${t('common.name')}*`}
                error={errors.name?.message}
                {...register('name', {
                  validate: { required, minLength: minLength(), maxLength: maxLength() },
                })}
              />
              <Dialog.Footer>
                <SubmitButton>{t('common.change')}</SubmitButton>
              </Dialog.Footer>
            </Form>
          </>
        );
      }}
    </Dialog>
  );
};

export default GlobalRoleChangeNameDialog;
