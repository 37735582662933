import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import workspaceClubManagerSchema, {
  WorkspaceClubManagerSchema,
} from '../schemas/workspaceClubManagerSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  managerId: WorkspaceClubManagerSchema['id'];
}

const loadWorkspaceClubManager = async (args: Args) => {
  const { workspaceId, managerId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/club-manager/managers/${managerId}`
  );

  return workspaceClubManagerSchema.parse(response.data);
};

export default loadWorkspaceClubManager;
