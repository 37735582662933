import clsx from 'clsx';
import styles from './styles.module.scss';

interface Props {
  error?: string;
  className?: string;
}

const Error = (props: Props) => {
  const { error, className } = props;

  if (!error) return null;

  return (
    <div className={clsx(styles.error, className)} data-error>
      {error}
    </div>
  );
};

export default Error;
