import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { loadGlobalBotSettings, loadGlobalWorkspaces } from '../../../api';
import createGlobalBotSettingRule from '../../../api/global-bot-setting-rule/createGlobalBotSettingRule';
import updateGlobalBotSettingRule from '../../../api/global-bot-setting-rule/updateGlobalBotSettingRule';
import { BotSettingRuleSchema } from '../../../api/schemas/botSettingRuleSchema';
import Form from '../../../components/Form';
import Input from '../../../components/Input';
import PageAddon from '../../../components/PageAddon';
import Select, { Option } from '../../../components/Select';
import SubmitButton from '../../../components/SubmitButton';
import Textarea from '../../../components/Textarea';
import { required } from '../../../utils/form';
import notify from '../../../utils/notify';
import { trainerGameTypeOptions, trainerPokerRoomOptions } from '../../../utils/trainer';
import { getBotSettingCaption, getDefaultValues } from '../helpers';
import styles from './styles.module.scss';

interface Fields {
  name: string;
  game_type: Option<BotSettingRuleSchema['game_type']>;
  bot_settings_id: Option;
  poker_room: Option<Exclude<BotSettingRuleSchema['poker_room'], null>>;
  maintenance_id: Option;
  description: string;
}

interface CommonProps {
  onClose: () => void;
}

interface CreateProps {
  onCreate: (args: { botSettingRuleId: BotSettingRuleSchema['id'] }) => void;
  data?: never;
  onEdit?: never;
}

interface EditProps {
  data: BotSettingRuleSchema;
  onEdit: () => void;
  onCreate?: never;
}

type Props = CommonProps & (CreateProps | EditProps);

const GlobalBotSettingRuleForm = (props: Props) => {
  const { data, onClose, onCreate, onEdit } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({ defaultValues: getDefaultValues(data) });

  const {
    watch,
    control,
    register,
    setValue,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    if (!values.game_type && values.game_type !== 0) throw new Error();
    if (!values.bot_settings_id) throw new Error();

    const payload = {
      name: values.name,
      bot_settings_id: values.bot_settings_id.value,
      game_type: values.game_type.value,
      maintenance_id: values.maintenance_id?.value || null,
      poker_room: values.poker_room?.value || null,
      description: values.description,
    };

    if (data) {
      await updateGlobalBotSettingRule({ botSettingRuleId: data.id, payload });

      onEdit();
    } else {
      const response = await createGlobalBotSettingRule({ payload });

      onCreate({ botSettingRuleId: response.id });
    }

    notify('success', {
      title: data ? t('sentences.changes_have_been_saved') : t('sentences.record_has_been_created'),
    });
  };

  const { game_type, maintenance_id } = watch();

  return (
    <PageAddon
      onClose={onClose}
      title={data ? `${t('common.edit')} ${data.name}` : t('common.create_usage_rule')}
    >
      <Form form={form} onSubmit={onSubmit}>
        <Input
          label={`${t('common.name')}*`}
          error={errors.name?.message}
          {...register('name', { validate: required })}
        />
        <Controller
          name="game_type"
          control={control}
          rules={{ validate: { required } }}
          render={({ field }) => (
            <Select
              clearable={false}
              label={`${t('common.game_type')}*`}
              options={trainerGameTypeOptions}
              value={field.value}
              onChange={(value) => {
                setValue('bot_settings_id', null);

                field.onChange(value);
              }}
              error={errors.game_type?.message}
            />
          )}
        />
        <Controller
          name="maintenance_id"
          control={control}
          render={({ field }) => (
            <Select.Async
              onLoad={async (args) => {
                const response = await loadGlobalWorkspaces(args);

                const options = response.items.map((item) => ({
                  value: item.id,
                  label: item.name,
                }));

                return options;
              }}
            >
              {({ options, ...rest }) => (
                <Select
                  disabled={!game_type}
                  label={t('common.workspace')}
                  options={options}
                  value={field.value}
                  onChange={(value) => {
                    setValue('bot_settings_id', null);

                    field.onChange(value);
                  }}
                  error={errors.maintenance_id?.message}
                  {...rest}
                />
              )}
            </Select.Async>
          )}
        />
        <Controller
          name="bot_settings_id"
          control={control}
          rules={{ validate: { required } }}
          render={({ field }) => (
            <Select.Async
              onLoad={async (args) => {
                const response = await loadGlobalBotSettings({
                  ...args,
                  ...(game_type && { game_type_in: String(game_type.value) }),
                  ...(!maintenance_id && { show_null_maintenance: 'true' }),
                  ...(maintenance_id && { maintenance_in_or_null: maintenance_id.value }),
                });

                const options = response.items.map((item) => ({
                  value: item.id,
                  label: item.name,
                  caption: getBotSettingCaption(item),
                }));

                return options;
              }}
            >
              {({ options, ...rest }) => (
                <Select
                  clearable={false}
                  disabled={!game_type}
                  label={`${t('common.bot_settings')}*`}
                  options={options}
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.bot_settings_id?.message}
                  {...rest}
                />
              )}
            </Select.Async>
          )}
        />
        <Controller
          name="poker_room"
          control={control}
          render={({ field }) => (
            <Select
              disabled={!game_type}
              label={t('common.poker_room')}
              options={trainerPokerRoomOptions}
              value={field.value}
              onChange={field.onChange}
              error={errors.poker_room?.message}
            />
          )}
        />
        <Textarea
          maxLength={0}
          label={t('common.description')}
          className={styles.textarea}
          error={errors.description?.message}
          {...register('description')}
        />
        <PageAddon.Controls>
          <SubmitButton>{data ? t('common.save') : t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default GlobalBotSettingRuleForm;
