import api from '../../services/api';
import { GlobalAccountSchema } from '../schemas/account/globalAccountSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';

type Schema = GlobalAccountSchema;

interface Payload {
  table_sizes?: Schema['table_sizes'];
  max_tables?: Schema['max_tables'];
  comparative_limits?: Schema['comparative_limits'];
  geo?: Schema['geo'];
  bot_settings_ids?: Schema['bot_settings'][number]['id'][];
  club_ids?: Schema['clubs'][number]['id'][];
  stopped_club_ids?: Schema['stopped_clubs'][number]['id'][];
}

interface Args {
  accountId: Schema['id'];
  payload: Payload;
}

const updateGlobalAccount = async (args: Args) => {
  const { accountId, payload } = args;

  const response = await api.patch(`/api/account/${accountId}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateGlobalAccount;
