import { createContext, useContext } from 'react';
import { FilterFields, UseFilterReturn } from '../../hooks/useFilter';

type Context<T extends FilterFields<T> | unknown = unknown> = UseFilterReturn<T>;

const FilterContext = createContext<Context | undefined>(undefined);

interface Props<T extends FilterFields<T>> extends Context<T> {
  children: React.ReactNode;
}

const FilterProvider = <T extends FilterFields<T>>(props: Props<T>) => {
  const { children, ...rest } = props;

  return <FilterContext.Provider value={rest}>{children}</FilterContext.Provider>;
};

const useFilterContext = <T extends FilterFields<T> | unknown = unknown>() => {
  const context = useContext(FilterContext);

  if (context === undefined) {
    throw new Error('useFilterContext must be used within a FilterProvier');
  }

  return context as Context<T>;
};

export { FilterProvider, useFilterContext };
