import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { WorkspaceDealSchema } from '../../api/schemas/deal/workspaceDealSchema';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import DealDetailGeneralTab from '../deal/DealDetailGeneralTab';

interface Props {
  data: WorkspaceDealSchema;
  onClose: () => void;
  getClubRoute?: (args: { clubId: GlobalClubSchema['id'] }) => string;
}

const WorkspaceDealDetail = (props: Props) => {
  const { data, onClose, getClubRoute } = props;

  const { t } = useTranslation();

  const { code, notes_count, is_deleted } = data;

  const titleIcons = (
    <>
      {is_deleted && icon('trash', 12)}
      {Boolean(notes_count) && <Tooltip label={t('common.notes')}>{icon('notes', 12)}</Tooltip>}
    </>
  );

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon title={code} icons={titleIcons} onClose={onClose}>
        <DealDetailGeneralTab data={data} getClubRoute={getClubRoute} />
      </PageAddon>
    </PageAddon.Group>
  );
};

export default WorkspaceDealDetail;
