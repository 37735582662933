import { AreaSchema, STATUS } from '../../api/schemas/areaSchema';
import { t } from '../../i18n';

export const STATUS_LABELS: Record<AreaSchema['status'], string> = {
  [STATUS.UNKNOWN]: t('common.unknown'),
  [STATUS.NEW]: t('common.new'),
  [STATUS.VERIFIED]: t('common.verified'),
};

export const statusOptions = Object.values(STATUS)
  .filter((item) => item !== STATUS.UNKNOWN)
  .map((item) => ({
    value: item,
    label: STATUS_LABELS[item],
  }));

export const getCurrency = (data: AreaSchema) =>
  data.area_rates[data.area_rates.length - 1]?.rate.currency_code || '—';

export const getUnits = (data: AreaSchema) =>
  data.area_rates[data.area_rates.length - 1]?.rate.units;

export const getNanos = (data: AreaSchema) =>
  data.area_rates[data.area_rates.length - 1]?.rate.nanos;

export const emptyPeriod = { start_date: '', end_date: '', currency: '', units: '', nanos: '' };
