import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import marketIdentitySchema, { MarketIdentitySchema } from '../schemas/marketIdentitySchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
  identityId: MarketIdentitySchema['id'];
}

const loadWorkspaceMarketIdentity = async (args: Args) => {
  const { workspaceId, identityId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/identity-market/${identityId}`
  );

  return marketIdentitySchema.parse(response.data);
};

export default loadWorkspaceMarketIdentity;
