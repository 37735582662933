import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalClubDetail from '../../features/global-club/GlobalClubDetail';
import GlobalClubForm from '../../features/global-club/GlobalClubForm';
import GlobalClubLoader from '../../features/global-club/GlobalClubLoader';
import GlobalClubNotes from '../../features/global-club/GlobalClubNotes';
import WorkspaceDealDetail from '../../features/workspace-deal/WorkspaceDealDetail';
import WorkspaceDealLoader from '../../features/workspace-deal/WorkspaceDealLoader';
import WorkspaceDealsPage from '../../pages/WorkspaceDealsPage';
import Notes from '../Notes';
import Route from './Route';

const page = (
  <Route params={['workspaceId']}>
    {({ workspaceId }) => <WorkspaceDealsPage workspaceId={workspaceId} />}
  </Route>
);

const detail = (
  <Route params={['workspaceId', 'dealId']}>
    {({ workspaceId, dealId, navigate }) => (
      <WorkspaceDealLoader workspaceId={workspaceId} dealId={dealId}>
        {({ data }) => (
          <WorkspaceDealDetail
            data={data}
            onClose={() => navigate(routes.workspaceDeals({ workspaceId }))}
            getClubRoute={({ clubId }) => routes.workspaceDealClub({ workspaceId, dealId, clubId })}
          />
        )}
      </WorkspaceDealLoader>
    )}
  </Route>
);

const detailClub = (
  <Route params={['workspaceId', 'dealId', 'clubId']}>
    {({ workspaceId, dealId, clubId, navigate }) => (
      <GlobalClubLoader clubId={clubId}>
        {({ data }) => (
          <GlobalClubDetail
            data={data}
            onClose={() => navigate(routes.workspaceDeal({ workspaceId, dealId }))}
            onEdit={() => navigate(routes.workspaceDealClubEdit({ workspaceId, dealId, clubId }))}
            getNotesRoute={() =>
              navigate(routes.workspaceDealClubNotes({ workspaceId, dealId, clubId }))
            }
          />
        )}
      </GlobalClubLoader>
    )}
  </Route>
);

const detailClubNotes = (
  <Route params={['workspaceId', 'dealId', 'clubId']}>
    {({ workspaceId, dealId, clubId, navigate }) => (
      <GlobalClubNotes clubId={clubId}>
        {(loader) => (
          <Notes
            onClose={() => navigate(routes.workspaceDealClub({ workspaceId, dealId, clubId }))}
            {...loader}
          />
        )}
      </GlobalClubNotes>
    )}
  </Route>
);

const detailClubEditForm = (
  <Route params={['workspaceId', 'dealId', 'clubId']}>
    {({ workspaceId, dealId, clubId, navigate }) => (
      <GlobalClubLoader clubId={clubId}>
        {({ data, workspacesData }) => (
          <GlobalClubForm
            data={data}
            workspacesData={workspacesData}
            onClose={() => navigate(routes.workspaceDealClub({ workspaceId, dealId, clubId }))}
            onEdit={() => navigate(routes.workspaceDealClub({ workspaceId, dealId, clubId }))}
          />
        )}
      </GlobalClubLoader>
    )}
  </Route>
);

const workspaceDealsPage: RouteObject = {
  path: routes.workspaceDeals({ workspaceId: ':workspaceId' }),
  element: page,
  children: [
    {
      path: routes.workspaceDeal({ workspaceId: ':workspaceId', dealId: ':dealId' }),
      element: detail,
      children: [
        {
          path: routes.workspaceDealClub({
            workspaceId: ':workspaceId',
            dealId: ':dealId',
            clubId: ':clubId',
          }),
          element: detailClub,
          children: [
            {
              path: routes.workspaceDealClubNotes({
                workspaceId: ':workspaceId',
                dealId: ':dealId',
                clubId: ':clubId',
              }),
              element: detailClubNotes,
            },
          ],
        },
        {
          path: routes.workspaceDealClubEdit({
            workspaceId: ':workspaceId',
            dealId: ':dealId',
            clubId: ':clubId',
          }),
          element: detailClubEditForm,
        },
      ],
    },
  ],
};

export default workspaceDealsPage;
