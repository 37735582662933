import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import useForm from './useForm';

interface Props {
  data: WorkspaceSchema;
}

const GlobalWorkspaceDetailMiningTab = (props: Props) => {
  const { data } = props;

  const { fields } = useForm({ data });

  return (
    <PageAddon.Fields>
      {fields.auto_create_mining_session}
      {fields.mining_stage_ids}
      {fields.mining_session_duration}
      {fields.mining_session_interval}
    </PageAddon.Fields>
  );
};

export default GlobalWorkspaceDetailMiningTab;
