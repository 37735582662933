import { FormProvider } from 'react-hook-form';
import { loadGlobalRoles, updateGlobalUser } from '../../api';
import { GlobalRoleSchema } from '../../api/schemas/globalRoleSchema';
import { GlobalUserSchema } from '../../api/schemas/globalUserSchema';
import LinkButton from '../../components/LinkButton';
import PageAddon from '../../components/PageAddon';
import Select, { Options } from '../../components/Select';
import useQuickForm from '../../hooks/useQuickForm';
import useURL from '../../hooks/useURL';
import { handleError } from '../../utils/form';
import { can } from '../../utils/permissions';

interface Fields {
  role_ids: Options;
}

interface Props {
  data: GlobalUserSchema;
  getRoleRoute?: (args: { roleId: GlobalRoleSchema['id'] }) => string;
}

const GlobalUserDetailRoles = (props: Props) => {
  const { data, getRoleRoute } = props;

  const { roles } = data;

  const disabled = !can.user.createUpdate || !can.role.read;

  const { navigate } = useURL();

  const form = useQuickForm<Fields>({
    data,
    defaultValues: { role_ids: data.roles.map((item) => ({ value: item.id, label: item.name })) },
  });

  const { handleSubmit, watch, setValue } = form;

  const onSubmit = async (values: Fields) => {
    const payload = { role_ids: values.role_ids.map((item) => item.value) };

    try {
      await updateGlobalUser({ userId: data.id, payload });
    } catch (error) {
      handleError({ error, form });
    }
  };

  const roleIds = watch('role_ids');

  if (roles.length === 0 && disabled) return '—';

  return (
    <FormProvider {...form}>
      <PageAddon.QuickField.Group>
        {roles.map((item) => (
          <PageAddon.QuickField
            key={item.id}
            disabled={disabled}
            title={item.name}
            onDelete={() => {
              setValue(
                'role_ids',
                roleIds.filter((el) => el.value !== item.id)
              );

              handleSubmit(onSubmit)();
            }}
          >
            {getRoleRoute && (
              <LinkButton
                size="extra-small"
                variant="light"
                onClick={() => navigate(getRoleRoute({ roleId: item.id }))}
              />
            )}
          </PageAddon.QuickField>
        ))}
        {!disabled && (
          <Select.Async
            onLoad={async (args) => {
              const response = await loadGlobalRoles(args);

              const options = response.items.map((item) => ({
                value: item.id,
                label: item.name,
              }));

              return options;
            }}
          >
            {({ options, ...selectAsyncProps }) => (
              <Select.Quick
                name="role_ids"
                disabled={disabled}
                options={options.filter(
                  (item) => !roleIds.map((el) => el.value).includes(item.value)
                )}
                onChange={(value) => {
                  setValue('role_ids', value ? [...roleIds, value] : []);

                  handleSubmit(onSubmit)();
                }}
                {...selectAsyncProps}
              />
            )}
          </Select.Async>
        )}
      </PageAddon.QuickField.Group>
    </FormProvider>
  );
};

export default GlobalUserDetailRoles;
