import api from '../../services/api';
import globalLeagueSchema, { GlobalLeagueSchema } from '../schemas/globalLeagueSchema';

interface Args {
  leagueId: GlobalLeagueSchema['id'];
}

const loadGlobalLeague = async (args: Args) => {
  const { leagueId } = args;

  const response = await api.get(`/api/reference/league/${leagueId}`);

  return globalLeagueSchema.parse(response.data);
};

export default loadGlobalLeague;
