import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import notificationSchema, { notificationsWorkspaceSchema } from './notificationSchema';

const notificationsSettingSchema = z.object({
  id: z.string(),
  action_types: z.array(notificationSchema.shape.action_type),
  workspace: notificationsWorkspaceSchema,
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export type NotificationsSettingSchema = z.infer<typeof notificationsSettingSchema>;

export default notificationsSettingSchema;
