import { matchSorter } from 'match-sorter';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalUserSchema } from '../../api/schemas/globalUserSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import LinkButton from '../../components/LinkButton';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';

interface Props {
  data: GlobalUserSchema;
  getWorkspaceRoute?: (args: { workspaceId: WorkspaceSchema['id'] }) => string;
}

const GlobalUserDetailWorkspacesTab = (props: Props) => {
  const { data, getWorkspaceRoute } = props;

  const { maintenances } = data;

  const { t } = useTranslation();
  const { navigate } = useURL();

  const [search, setSearch] = useState('');

  const filteredMaintenances = search
    ? matchSorter(maintenances, search, { keys: ['name'] })
    : maintenances;

  return (
    <PageAddon.List search={search} onSearch={setSearch}>
      {filteredMaintenances.map((item) => (
        <PageAddon.List.Item key={item.id} title={item.name}>
          {item.is_owner && <Tooltip label={t('common.owner')}>{icon('crown', 16)}</Tooltip>}
          {getWorkspaceRoute && (
            <LinkButton
              size="small"
              variant="transparent"
              onClick={() => navigate(getWorkspaceRoute({ workspaceId: item.id }))}
            />
          )}
        </PageAddon.List.Item>
      ))}
    </PageAddon.List>
  );
};

export default GlobalUserDetailWorkspacesTab;
