import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import icon from '../../../static/icons';
import Button from '../../Button';
import Tooltip from '../../Tooltip';
import { useTableContext } from '../TableContext';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
}

const TableSelectedRowsActions = (props: Props) => {
  const { children } = props;

  const { t } = useTranslation();

  const { selected, unselected, allSelected, disabled, legacyRowSelection, unselectAll } =
    useTableContext();

  if (legacyRowSelection ? selected.length : selected.length || unselected.length || allSelected) {
    return (
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.info}>
            <Tooltip label={t('common.cancel')} hideOnMobile>
              <Button
                size="small"
                variant="transparent"
                icon={icon('cross', 16)}
                onClick={unselectAll}
                disabled={disabled}
              />
            </Tooltip>
          </div>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    );
  }

  return null;
};

export default TableSelectedRowsActions;
