import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadWorkspaceAccountVerificationCode } from '../../../api';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Button from '../../../components/Button';
import Dialog from '../../../components/Dialog';
import Placeholder from '../../../components/Placeholder';
import queryKeys from '../../../config/queryKeys';
import styles from './styles.module.scss';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
  onClose?: () => void;
}

const WorkspaceAccountVerificationCodeDialog = (props: Props) => {
  const { workspaceId, accountId, onClose } = props;

  const { t } = useTranslation();

  const { data, isPending } = useQuery({
    queryKey: [queryKeys.workspaceAccountVerificationCode({ workspaceId, accountId })],
    queryFn: () => loadWorkspaceAccountVerificationCode({ workspaceId, accountId }),
  });

  return (
    <Dialog onClose={onClose}>
      {({ close }) => (
        <>
          <Dialog.Top>
            <Dialog.Title>{t('common.verification_code')}</Dialog.Title>
          </Dialog.Top>
          <div className={styles.container}>
            {isPending &&
              Array.from({ length: 6 }).map((_, index) => (
                <Placeholder key={index} className={styles.placeholder} />
              ))}
            {data &&
              Array.from(String(data.verification_code)).map((item, index) => (
                <div key={index} className={styles.cell}>
                  {item}
                </div>
              ))}
          </div>
          <Dialog.Footer>
            <Button onClick={() => close()}>{t('common.ok')}</Button>
          </Dialog.Footer>
        </>
      )}
    </Dialog>
  );
};

export default WorkspaceAccountVerificationCodeDialog;
