import { useTranslation } from 'react-i18next';
import { GlobalRoleSchema } from '../../api/schemas/globalRoleSchema';
import { GlobalUserSchema } from '../../api/schemas/globalUserSchema';
import PageAddon from '../../components/PageAddon';
import { formatAbsoluteDate } from '../../utils/date';
import GlobalUserDetailRoles from './GlobalUserDetailRoles';

interface Props {
  data: GlobalUserSchema;
  getRoleRoute?: (args: { roleId: GlobalRoleSchema['id'] }) => string;
}

const GlobalUserDetailGeneralTab = (props: Props) => {
  const { data, getRoleRoute } = props;

  const { id, created_on } = data;

  const { t } = useTranslation();

  return (
    <PageAddon.Fields>
      <PageAddon.Id id={id} />
      <PageAddon.Field label={t('common.roles')}>
        <GlobalUserDetailRoles data={data} getRoleRoute={getRoleRoute} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.created')}>
        <PageAddon.Copy>{formatAbsoluteDate(created_on)}</PageAddon.Copy>
      </PageAddon.Field>
    </PageAddon.Fields>
  );
};

export default GlobalUserDetailGeneralTab;
