import { QueryKey } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { updateWorkspaceAccountsStage } from '../../../api';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Button from '../../../components/Button';
import Dialog from '../../../components/Dialog';
import useURL from '../../../hooks/useURL';
import icon from '../../../static/icons';
import notify from '../../../utils/notify';
import { queryClient } from '../../../utils/queryClient';
import useStages from '../useStages';
import styles from './styles.module.scss';
import WorkspaceAccountTableViewDialogForm from './WorkspaceAccountTableViewDialogForm';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  queryKey: QueryKey;
}

const WorkspaceAccountTableViewDialog = (props: Props) => {
  const { workspaceId, queryKey } = props;

  const { t } = useTranslation();
  const { setURLParams } = useURL();
  const { query, stage } = useStages({ workspaceId });

  const { isPending } = query;

  return (
    <Dialog
      secondary
      className={styles.dialog}
      label={({ referenceProps }) => (
        <Button
          size="extra-small"
          variant="transparent"
          icon={icon('settings', 16)}
          loading={isPending}
          {...referenceProps}
        />
      )}
    >
      {({ close }) => (
        <>
          <Dialog.Top>
            <Dialog.Title subTitle={t('sentences.table_view_subtitle')}>
              {t('common.table_view')}
            </Dialog.Title>
          </Dialog.Top>
          {stage && (
            <WorkspaceAccountTableViewDialogForm
              data={stage}
              onSubmit={async (values) => {
                if (!values.default_sorting) throw new Error();

                const payload = {
                  columns: values.columns,
                  default_sorting: {
                    name: values.default_sorting,
                    direction: values.default_direction,
                  },
                };

                await updateWorkspaceAccountsStage({
                  workspaceId,
                  stageId: stage.id,
                  payload,
                });

                close();
                notify('success', { title: t('sentences.changes_have_been_saved') });
                queryClient.resetQueries({ queryKey });
                setURLParams({ sort_by: null, order_by: null });
              }}
            />
          )}
        </>
      )}
    </Dialog>
  );
};

export default WorkspaceAccountTableViewDialog;
