import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { deleteWorkspaceClubManager, restoreWorkspaceClubManager } from '../../api';
import { WorkspaceClubManagerSchema } from '../../api/schemas/workspaceClubManagerSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { presets } from '../../components/Dropdown';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import icon from '../../static/icons';
import { RouteParams } from '../../utils/generics';
import notify from '../../utils/notify';

type Params = RouteParams<typeof routes.workspaceClubManager>;

interface Args {
  data: WorkspaceClubManagerSchema;
  workspaceId: WorkspaceSchema['id'];
  changePassword: () => void;
}

const useDropdown = (args: Args) => {
  const { data, workspaceId, changePassword } = args;

  const { id, login, is_deleted } = data;

  const { t } = useTranslation();
  const { navigate } = useURL();
  const { managerId } = useParams<Params>();

  return {
    title: login,
    options: {
      edit: {
        ...presets.edit,
        onClick: () => navigate(routes.workspaceClubManagerEdit({ workspaceId, managerId: id })),
      },
      changePassword: {
        icon: icon('unlock', 20),
        label: t('common.change_password'),
        onClick: changePassword,
      },
      copy: presets.copyId(id),
      divider: { divider: true },
      ...(!is_deleted && {
        delete: {
          ...presets.delete,
          onClick: async () => {
            await deleteWorkspaceClubManager({ workspaceId, managerId: id });

            if (id === managerId) {
              navigate(routes.workspaceClubManagers({ workspaceId }));
            }

            notify('success', { title: t('sentences.record_has_been_deleted') });
          },
        },
      }),
      ...(is_deleted && {
        restore: {
          ...presets.restore,
          onClick: async () => {
            await restoreWorkspaceClubManager({ workspaceId, managerId: id });

            notify('success', { title: t('sentences.record_has_been_restored') });
          },
        },
      }),
    },
  };
};

export default useDropdown;
