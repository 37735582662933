import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { globalUserFilterValuesSchema } from '../schemas/globalUserSchema';

const loadGlobalUsersFilterValues = async (params?: URLParams) => {
  const response = await api.get('/api/user/filter-values', { params });

  return globalUserFilterValuesSchema.parse(response.data);
};

export default loadGlobalUsersFilterValues;
