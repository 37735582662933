import { z } from 'zod';
import { identityCommonSchema } from '../identity';

const accountCommonIdentitySchema = z
  .object({
    id: identityCommonSchema.shape.id,
    first_name: identityCommonSchema.shape.first_name,
    last_name: identityCommonSchema.shape.last_name,
    bank_accounts: identityCommonSchema.shape.bank_accounts,
    date_of_birth: identityCommonSchema.shape.date_of_birth,
    documents: identityCommonSchema.shape.documents,
    gender: identityCommonSchema.shape.gender,
    location: identityCommonSchema.shape.location,
    online_wallets: identityCommonSchema.shape.online_wallets,
    phones: identityCommonSchema.shape.phones,
    photos: identityCommonSchema.shape.photos,
    social_networks: identityCommonSchema.shape.social_networks,
  })
  .or(z.null());

export default accountCommonIdentitySchema;
