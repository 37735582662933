import { z } from 'zod';
import accountStatusSchema from './accountStatusSchema';
import globalClubSchema from './globalClubSchema';
import globalLeagueSchema from './globalLeagueSchema';
import metricsSchema from './metricsSchema';
import trainerGameTypeSchema from './trainerGameTypeSchema';
import trainerLimitSchema from './trainerLimitSchema';
import trainerPokerRoomSchema from './trainerPokerRoomSchema';

export const COLOR = {
  UNKNOWN: 0,
  GREEN: 1,
  YELLOW: 2,
  RED: 3,
  PURPLE: 4,
} as const;

const lobbyTableSchema = z.object({
  id: z.string(),
  table_id: z.string(),
  club_id: globalClubSchema.shape.code,
  league_id: globalLeagueSchema.shape.code,
  big_blind: z.number(),
  small_blind: z.number(),
  max_no_of_seats: z.number(),
  player_count: z.number(),
  room: trainerPokerRoomSchema,
  comparative_limit: trainerLimitSchema,
  game_type: trainerGameTypeSchema,
  short_name: z.string(),
  table_name: z.string(),
  currency: z.string(),
  ante: z.number().or(z.null()),
  color: z.nativeEnum(COLOR),
  extra_fields: z.record(z.union([z.number(), z.string(), z.boolean()])),
});

export const lobbyTableWithMetricsSchema = lobbyTableSchema.extend({
  metrics: metricsSchema,
  account_status: accountStatusSchema,
});

export type LobbyTableSchema = z.infer<typeof lobbyTableSchema>;
export type LobbyTableWithMetricsSchema = z.infer<typeof lobbyTableWithMetricsSchema>;

export default lobbyTableSchema;
