import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { loadWorkspaceOrdersFilterValues } from '../../api';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import queryKeys from '../../config/queryKeys';
import URLParams from '../../types/URLParams';
import OrderFilter from '../order/OrderFilter';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  urlParams: URLParams;
}

const WorkspaceOrderFilter = (props: Props) => {
  const { workspaceId, urlParams } = props;

  const queryKey = [queryKeys.workspaceOrdersFilterValues({ workspaceId }), urlParams];
  const queryFn = () => loadWorkspaceOrdersFilterValues({ workspaceId }, urlParams);

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  return <OrderFilter data={data} loading={isPending} />;
};

export default WorkspaceOrderFilter;
