import { useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { loadWorkspaceMarketIdentity } from '../../api';
import { MarketIdentitySchema } from '../../api/schemas/marketIdentitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageAddon from '../../components/PageAddon';
import queryKeys from '../../config/queryKeys';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { updateDetailQueryData } from '../../utils/queryClient';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  identityId: MarketIdentitySchema['id'];
  children: (args: { data: MarketIdentitySchema }) => ReactElement;
}

const WorkspaceMarketIdentityLoader = (props: Props) => {
  const { workspaceId, identityId, children } = props;

  const queryKey = [queryKeys.workspaceMarketIdentity({ workspaceId, identityId })];
  const queryFn = () => loadWorkspaceMarketIdentity({ workspaceId, identityId });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    meta: { ignoredStatuses: [403] },
  });

  useWebSocketMessage<MarketIdentitySchema>({
    domain: 'Market',
    enabled: (message) => message.meta_info?.maintenance_id === workspaceId,
    onMessage: (message) => {
      if (message.action === 'Deleted' && message.payload.id === identityId) {
        updateDetailQueryData(queryKey, message.payload);
      }
    },
  });

  if (isPending) return <PageAddon.DetailPlaceholder />;
  if (!data) return null;

  return children({ data });
};

export default WorkspaceMarketIdentityLoader;
