import { GlobalAccountSchema } from '../../../api/schemas/account/globalAccountSchema';
import PageDetailLink from '../../../components/PageDetailLink';
import routes from '../../../config/routes';
import AccountScale from '../../account/AccountScale';
import styles from './styles.module.scss';

interface Props {
  data: GlobalAccountSchema;
}

const GlobalAccountDetailLink = (props: Props) => {
  const { data } = props;

  const { id, mga_id } = data;

  return (
    <div className={styles.container}>
      <PageDetailLink label={mga_id} to={routes.globalAccount({ accountId: id })} />
      <AccountScale data={data} />
    </div>
  );
};

export default GlobalAccountDetailLink;
