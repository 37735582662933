import { GlobalDealSchema } from '../../api/schemas/deal/globalDealSchema';
import Dropdown from '../../components/Dropdown';
import useDropdown from './useDropdown';

interface Props {
  data: GlobalDealSchema;
}

const GlobalDealDetailDropdown = (props: Props) => {
  const { title, disabled, options } = useDropdown(props);

  return (
    <Dropdown title={title} buttonSize="medium" disabled={disabled} options={[options.delete]} />
  );
};

export default GlobalDealDetailDropdown;
