import { useEffect, useReducer } from 'react';

interface Args {
  interval?: number;
}

const useRerender = (args: Args = {}) => {
  const { interval } = args;

  const rerender = useReducer(() => ({}), {})[1];

  useEffect(() => {
    if (interval) {
      const id = setInterval(rerender, interval);

      return () => clearInterval(id);
    }

    return undefined;
  }, [interval, rerender]);

  return { rerender };
};

export default useRerender;
