import { icons } from '../../../static/icons';
import styles from './styles.module.scss';

const StorybookPageIcons = () => (
  <div className={styles.icons}>
    {Object.entries(icons)
      .filter((item) => !item[0].toLowerCase().includes('crossed'))
      .map((item) => {
        const [name, element] = item;

        return (
          <div key={name} className={styles.icon}>
            <span title={name}>{name}</span>
            {element}
          </div>
        );
      })}
  </div>
);

export default StorybookPageIcons;
