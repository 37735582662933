import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { GlobalClubSchema } from '../../api/schemas/globalClubSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { WorkspaceUserSchema } from '../../api/schemas/workspaceUserSchema';
import PageAddon from '../../components/PageAddon';
import { addWhen } from '../../utils';
import { can } from '../../utils/permissions';
import GlobalWorkspaceDetailBillingTab from './GlobalWorkspaceDetailBillingTab';
import GlobalWorkspaceDetailClubsTab from './GlobalWorkspaceDetailClubsTab';
import GlobalWorkspaceDetailDropdown from './GlobalWorkspaceDetailDropdown';
import GlobalWorkspaceDetailGeneralTab from './GlobalWorkspaceDetailGeneralTab';
import GlobalWorkspaceDetailMiningTab from './GlobalWorkspaceDetailMiningTab';
import GlobalWorkspaceDetailUsersTab from './GlobalWorkspaceDetailUsersTab';

interface Props {
  data: WorkspaceSchema;
  onClose: () => void;
  getUserRoute?: (args: { userId: WorkspaceUserSchema['id'] }) => string;
  getClubRoute?: (args: { clubId: GlobalClubSchema['id'] }) => string;
}

const GlobalWorkspaceDetail = (props: Props) => {
  const { data, onClose, getUserRoute, getClubRoute } = props;

  const { t } = useTranslation();

  const { id, name, is_deleted } = data;

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon
        copy
        title={name}
        onClose={onClose}
        tabs={[
          {
            label: t('common.general'),
            content: <GlobalWorkspaceDetailGeneralTab data={data} />,
          },
          {
            label: t('common.mining'),
            content: <GlobalWorkspaceDetailMiningTab data={data} />,
          },
          {
            label: t('common.billing'),
            content: <GlobalWorkspaceDetailBillingTab data={data} />,
          },
          ...addWhen(
            {
              label: t('common.clubs'),
              content: (
                <GlobalWorkspaceDetailClubsTab workspaceId={id} getClubRoute={getClubRoute} />
              ),
            },
            can.club.read
          ),
          ...addWhen(
            {
              label: t('common.members'),
              content: (
                <GlobalWorkspaceDetailUsersTab workspaceId={id} getUserRoute={getUserRoute} />
              ),
            },
            can.user.read
          ),
        ]}
      >
        {!is_deleted && (
          <PageAddon.Controls>
            <PageAddon.Controls.Group>
              <GlobalWorkspaceDetailDropdown data={data} />
            </PageAddon.Controls.Group>
          </PageAddon.Controls>
        )}
      </PageAddon>
    </PageAddon.Group>
  );
};

export default GlobalWorkspaceDetail;
