import JsCookie, { CookieAttributes } from 'js-cookie';

type Name = '_s';

export const getCookie = (name: Name): string | undefined => JsCookie.get(name);

export const setCookie = (
  name: Name,
  value: string,
  options?: CookieAttributes
): string | undefined => JsCookie.set(name, value, options);

export const removeCookie = (name: Name) => JsCookie.remove(name);
