import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { deleteGlobalExpense } from '../../api';
import { GlobalExpenseSchema } from '../../api/schemas/globalExpenseSchema';
import { presets } from '../../components/Dropdown';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { formatAbsoluteDate } from '../../utils/date';
import { RouteParams } from '../../utils/generics';
import notify from '../../utils/notify';
import { can } from '../../utils/permissions';

type Params = RouteParams<typeof routes.globalExpense>;

interface Args {
  data: GlobalExpenseSchema;
}

const useDropdown = (args: Args) => {
  const { data } = args;

  const { id, is_deleted, created_on } = data;

  const { t } = useTranslation();
  const { navigate } = useURL();
  const { expenseId } = useParams<Params>();

  return {
    title: formatAbsoluteDate(created_on),
    disabled: is_deleted,
    options: {
      copy: presets.copyId(id),
      divider: { divider: true },
      delete: {
        ...presets.delete,
        onClick: async () => {
          await deleteGlobalExpense({ expenseId: id });

          if (id === expenseId) {
            navigate(routes.globalExpenses);
          }

          notify('success', { title: t('sentences.record_has_been_deleted') });
        },
        disabled: !can.deal.delete,
        ...(!can.deal.delete && { tooltip: t('common.no_permission') }),
      },
    },
  };
};

export default useDropdown;
