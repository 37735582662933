import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { INSTANCE_TYPE, NotificationSchema } from '../../../api/schemas/notificationSchema';
import { TIME } from '../../../constants/DATE_FORMATS';
import { getDate } from '../../../utils/date';
import { getActionTypeContent } from '../helpers';
import styles from './styles.module.scss';

interface Props {
  data: NotificationSchema;
}

const NotificationsItem = (props: Props) => {
  const { data } = props;

  const { message, author, instance, workspace, is_read, created_on } = data;

  const { t } = useTranslation();

  const getTitle = () => {
    if (instance.type === INSTANCE_TYPE.AREA) return t('common.currency_service');

    return workspace?.name || '';
  };

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.title}>
          {getTitle()}
          {author && (
            <>
              {' '}
              <span>•</span> {author.username}
            </>
          )}
        </div>
        <div className={styles.side}>
          {!is_read && <div className={styles.indicator} />}
          <div className={styles.time}>{format(getDate(created_on), TIME)}</div>
        </div>
      </div>
      <div className={styles.action}>{getActionTypeContent(data)}</div>
      {Boolean(message) && <div className={styles.message}>{message}</div>}
    </div>
  );
};

export default NotificationsItem;
