import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useState } from 'react';
import useDimensions from 'react-cool-dimensions';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { loadGlobalLobby } from '../../api';
import { LobbyAccountSchema, LobbySchema } from '../../api/schemas/lobbySchema';
import { COLOR, LobbyTableSchema } from '../../api/schemas/lobbyTableSchema';
import Page from '../../components/Page';
import Placeholder from '../../components/Placeholder';
import Tooltip from '../../components/Tooltip';
import queryKeys from '../../config/queryKeys';
import getCounters from '../../features/global-lobby/getCounters';
import getFilteredData from '../../features/global-lobby/getFilteredData';
import getSortedData from '../../features/global-lobby/getSortedData';
import GlobalLobbyFilter from '../../features/global-lobby/GlobalLobbyFilter';
import GlobalLobbyPageHeader from '../../features/global-lobby/GlobalLobbyPageHeader';
import GlobalLobbyTable from '../../features/global-lobby/GlobalLobbyTable';
import useURL from '../../hooks/useURL';
import useWebSocketMessage from '../../hooks/useWebSocketMessage';
import { createQueryData, deleteQueryData, updateLobbyData } from '../../utils/queryClient';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

const GlobalLobbyPage = () => {
  const { t } = useTranslation();
  const { urlParams } = useURL();

  const queryKey = [queryKeys.globalLobby];
  const queryFn = () => loadGlobalLobby();

  const {
    data: unfilteredData = [],
    isPending,
    error,
  } = useQuery({ queryKey, queryFn, meta: { ignoredStatuses: [403] } });

  const filteredData = getFilteredData(unfilteredData, urlParams);
  const data = getSortedData(filteredData);
  const counters = getCounters(filteredData, unfilteredData);

  useWebSocketMessage<LobbySchema>({
    domain: 'RoomLobby',
    enabled: () => !isPending,
    onMessage: (message) => {
      if (
        message.action === 'TableUpdated' ||
        message.action === 'AccountAdded' ||
        message.action === 'AccountRemoved'
      ) {
        updateLobbyData(queryKey, message.payload);
      }

      if (message.action === 'TableCreated') {
        createQueryData(queryKey, message.payload);
      }
    },
  });

  useWebSocketMessage<{
    accounts: LobbyAccountSchema[];
    table: Pick<LobbyTableSchema, 'id' | 'table_id'>;
  }>({
    domain: 'RoomLobby',
    enabled: () => !isPending,
    onMessage: (message) => {
      if (message.action === 'TableDeleted') {
        deleteQueryData<LobbySchema>(
          queryKey,
          (item) => item.table.id === message.payload.table.table_id
        );
      }
    },
  });

  const [placeholdersAmount, setPlaceholdersAmount] = useState(0);

  const { observe } = useDimensions<HTMLSpanElement>({
    onResize: (event) => {
      const { width, height } = event;

      const TABLE_WIDTH = 220;
      const TABLE_HEIGHT = 90;
      const GAP = 12;

      let columns;
      let rows;

      columns = Math.floor(width / TABLE_WIDTH);
      rows = Math.floor(height / TABLE_HEIGHT);

      const isColumnsFit = TABLE_WIDTH * columns + GAP * (columns - 1) < width;
      const isRowFit = TABLE_HEIGHT * rows + GAP * (rows - 1) < height;

      if (!isColumnsFit) columns -= 1;
      if (!isRowFit) rows -= 1;

      rows += 1;

      setPlaceholdersAmount(columns * rows);
    },
  });

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <Page
      heading={t('common.lobby')}
      filter={
        <GlobalLobbyFilter
          data={unfilteredData}
          loading={isPending}
          counters={counters}
          total={data.length}
        />
      }
      search={false}
      addon={<Outlet />}
    >
      <div className={styles.container}>
        <GlobalLobbyPageHeader>
          {isPending ? (
            <>
              <Placeholder className={styles.placeholderWidgetValues} />
              <Placeholder className={styles.placeholderWidgetValues} />
            </>
          ) : (
            <>
              <div className={styles.widget}>
                <div className={styles.widgetTitle}>{t('common.status')}</div>
                <div className={styles.widgetValues}>
                  <Tooltip label={t('common.critical')}>
                    <div className={clsx(styles.widgetValue, styles.critical)}>
                      {counters.status.critical}
                    </div>
                  </Tooltip>
                  <Tooltip label={t('common.warning')}>
                    <div className={clsx(styles.widgetValue, styles.warning)}>
                      {counters.status.warning}
                    </div>
                  </Tooltip>
                  <Tooltip label={t('common.ok')}>
                    <div className={clsx(styles.widgetValue, styles.ok)}>{counters.status.ok}</div>
                  </Tooltip>
                  <Tooltip label={t('common.banned')}>
                    <div className={clsx(styles.widgetValue, styles.banned)}>
                      {counters.status.banned}
                    </div>
                  </Tooltip>
                  <Tooltip label={t('common.empty')}>
                    <div className={clsx(styles.widgetValue, styles.empty)}>
                      {counters.status.empty}
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className={styles.widget}>
                <div className={styles.widgetTitle}>{t('common.color')}</div>
                <div className={styles.widgetValues}>
                  <Tooltip label={t('common.red')}>
                    <div className={clsx(styles.widgetValue, styles.red)}>
                      {counters.color[COLOR.RED]}
                    </div>
                  </Tooltip>
                  <Tooltip label={t('common.yellow')}>
                    <div className={clsx(styles.widgetValue, styles.yellow)}>
                      {counters.color[COLOR.YELLOW]}
                    </div>
                  </Tooltip>
                  <Tooltip label={t('common.green')}>
                    <div className={clsx(styles.widgetValue, styles.green)}>
                      {counters.color[COLOR.GREEN]}
                    </div>
                  </Tooltip>
                  <Tooltip label={t('common.purple')}>
                    <div className={clsx(styles.widgetValue, styles.purple)}>
                      {counters.color[COLOR.PURPLE]}
                    </div>
                  </Tooltip>
                  <Tooltip label={t('common.unknown')}>
                    <div className={clsx(styles.widgetValue, styles.unknown)}>
                      {counters.color[COLOR.UNKNOWN]}
                    </div>
                  </Tooltip>
                </div>
              </div>
            </>
          )}
        </GlobalLobbyPageHeader>
        {isPending && (
          <div ref={observe} className={styles.wrapper}>
            <div className={styles.grid}>
              {Array.from({ length: placeholdersAmount }).map((_, index) => (
                <Placeholder key={index} className={styles.placeholderTable} />
              ))}
            </div>
          </div>
        )}
        <div className={styles.grid}>
          {data.map((item, index) => (
            <GlobalLobbyTable index={data.length - index} key={item.table.id} data={item} />
          ))}
        </div>
      </div>
    </Page>
  );
};

export default GlobalLobbyPage;
