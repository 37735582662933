import {
  accountPermissions,
  botSettingPermissions,
  clubPermissions,
  currencyPermissions,
  dealPermissions,
  lobbyPermissions,
  orderPermissions,
  rolePermissions,
  seatControlRulePermissions,
  sessionPermissions,
  userPermissions,
  workspacePermissions,
} from '../../api/schemas/permissionSchema';
import { t } from '../../i18n';

export const permissionsGroups = {
  accounts: { label: t('common.accounts'), permissions: accountPermissions },
  botSettings: { label: t('common.bot_settings'), permissions: botSettingPermissions },
  clubs: { label: t('common.clubs'), permissions: clubPermissions },
  currencies: { label: t('common.currencies'), permissions: currencyPermissions },
  deals: { label: t('common.deals'), permissions: dealPermissions },
  lobby: { label: t('common.lobby'), permissions: lobbyPermissions },
  orders: { label: t('common.orders'), permissions: orderPermissions },
  roles: { label: t('common.roles'), permissions: rolePermissions },
  seatControlRules: {
    label: t('common.seating_rules'),
    permissions: seatControlRulePermissions,
  },
  sessions: { label: t('common.sessions'), permissions: sessionPermissions },
  users: { label: t('common.users'), permissions: userPermissions },
  workspaces: { label: t('common.workspaces'), permissions: workspacePermissions },
};
