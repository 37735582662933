import {
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  flip,
  offset,
  autoUpdate,
  UseFloatingReturn,
} from '@floating-ui/react';
import { matchSorter } from 'match-sorter';
import {
  useRef,
  useMemo,
  Dispatch,
  useState,
  useEffect,
  ReactNode,
  useContext,
  createContext,
  SetStateAction,
  MutableRefObject,
} from 'react';
import {
  Open,
  Search,
  Multi,
  Options,
  OptionValue,
  ActiveIndex,
  SelectedIndex,
  ListRef,
  Props as SelectProps,
  Selected,
} from './types';

const OFFSET = 4;

interface Context<T extends OptionValue = string> {
  open: Open;
  search: Search;
  options: Options<T>;
  selected: Selected<T>;
  activeIndex: ActiveIndex;
  floating: UseFloatingReturn;
  listRef: MutableRefObject<ListRef>;
  setOpen: Dispatch<SetStateAction<Open>>;
  setSearch: Dispatch<SetStateAction<Search>>;
  setActiveIndex: Dispatch<SetStateAction<ActiveIndex>>;
  getReferenceProps: ReturnType<typeof useInteractions>['getReferenceProps'];
  getFloatingProps: ReturnType<typeof useInteractions>['getFloatingProps'];
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
  multi?: Multi;
  label?: SelectProps['label'];
  placeholder?: SelectProps['placeholder'];
  size: Exclude<SelectProps['size'], undefined>;
  error?: SelectProps['error'];
  errorVariant?: SelectProps['errorVariant'];
  disabled?: SelectProps['disabled'];
  loading?: SelectProps['loading'];
  quick?: SelectProps['quick'];
  extra?: SelectProps['extra'];
  tagExtra?: SelectProps['tagExtra'];
  clearable?: SelectProps['clearable'];
  creatable?: SelectProps['creatable'];
  popupSize?: SelectProps['popupSize'];
  filterOptions?: SelectProps['filterOptions'];
  renderValueAsTag?: SelectProps['renderValueAsTag'];
  onLastOptionInView?: SelectProps['onLastOptionInView'];
}

const SelectContext = createContext<Context<OptionValue> | undefined>(undefined);

interface Props<T extends OptionValue = string> {
  children: ReactNode;
  options: Options<T>;
  selected: Selected<T>;
  multi?: Multi;
  size?: SelectProps['size'];
  label?: SelectProps['label'];
  placeholder?: SelectProps['placeholder'];
  error?: SelectProps['error'];
  errorVariant?: SelectProps['errorVariant'];
  selectedIndex?: SelectedIndex;
  config?: SelectProps['config'];
  disabled?: SelectProps['disabled'];
  loading?: SelectProps['loading'];
  quick?: SelectProps['quick'];
  extra?: SelectProps['extra'];
  tagExtra?: SelectProps['tagExtra'];
  clearable?: SelectProps['clearable'];
  creatable?: SelectProps['creatable'];
  popupSize?: SelectProps['popupSize'];
  placement?: SelectProps['placement'];
  filterOptions?: SelectProps['filterOptions'];
  renderValueAsTag?: SelectProps['renderValueAsTag'];
  onOpen?: SelectProps['onOpen'];
  onSearch?: SelectProps['onSearch'];
  onLastOptionInView?: SelectProps['onLastOptionInView'];
}

const SelectProvider = <T extends OptionValue = string>(props: Props<T>) => {
  const {
    multi,
    label,
    placeholder,
    error,
    selected,
    children,
    disabled,
    creatable,
    loading,
    quick,
    extra,
    tagExtra,
    selectedIndex,
    size = 'large',
    clearable = true,
    popupSize = 'input',
    placement = 'bottom-start',
    filterOptions = true,
    errorVariant = 'default',
    renderValueAsTag,
    config = { flip: {} },
    onOpen,
    onSearch,
    onLastOptionInView,
  } = props;
  let { options } = props;

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [activeIndex, setActiveIndex] = useState<ActiveIndex>(null);

  const listRef = useRef<ListRef>([]);

  const floating = useFloating({
    open,
    strategy: 'absolute',
    placement,
    middleware: [offset({ mainAxis: OFFSET }), flip(config.flip)],
    onOpenChange: (value) => {
      if (onOpen) onOpen(value);
      if (!value) setSearch('');

      setOpen(value);
    },
    whileElementsMounted: autoUpdate,
  });

  const { context } = floating;

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useClick(context, { keyboardHandlers: false }),
    useDismiss(context, {
      outsidePressEvent: 'click',
      outsidePress: (event) => {
        if (event.target instanceof Element) return !event.target.closest('[data-select-clear]');

        return true;
      },
    }),
    useListNavigation(context, {
      listRef,
      activeIndex,
      selectedIndex,
      focusItemOnOpen: false,
      focusItemOnHover: false,
      scrollItemIntoView: true,
      onNavigate: setActiveIndex,
      disabledIndices: options.filter((item) => item.disabled).map((_, index) => index),
    }),
  ]);

  useEffect(() => {
    if (onSearch) {
      onSearch(search);
    }
  }, [search, onSearch]);

  useEffect(() => {
    if (!multi) {
      listRef.current.forEach((item, index) => {
        if (selectedIndex === index) {
          item?.scrollIntoView({ block: 'center' });
        }
      });
    }
  }, [multi, selectedIndex]);

  if (filterOptions) {
    options = search ? matchSorter(options, search, { keys: ['label'] }) : options;
  }

  if (creatable && !options.length && !loading && search) {
    options = [{ value: search as T, label: search, caption: 'Invite new user' }];
  }

  const value = useMemo(
    () => ({
      open,
      size,
      multi,
      label,
      placeholder,
      error,
      errorVariant,
      search,
      options,
      listRef,
      selected,
      floating,
      disabled,
      popupSize,
      placement,
      creatable,
      loading,
      quick,
      extra,
      tagExtra,
      clearable,
      activeIndex,
      renderValueAsTag,
      setOpen,
      setSearch,
      onLastOptionInView,
      setActiveIndex,
      getReferenceProps,
      getFloatingProps,
      getItemProps,
    }),
    [
      open,
      size,
      multi,
      label,
      placeholder,
      error,
      errorVariant,
      search,
      options,
      selected,
      floating,
      disabled,
      popupSize,
      placement,
      creatable,
      loading,
      quick,
      extra,
      tagExtra,
      clearable,
      activeIndex,
      renderValueAsTag,
      onLastOptionInView,
      getReferenceProps,
      getFloatingProps,
      getItemProps,
    ]
  );

  return <SelectContext.Provider value={value}>{children}</SelectContext.Provider>;
};

const useSelectContext = <T extends OptionValue = string>() => {
  const context = useContext(SelectContext);

  if (context === undefined) {
    throw new Error('useSelectContext must be used within a SelectProvier');
  }

  return context as Context<T>;
};

export { SelectProvider, useSelectContext };
