import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { GlobalBonusSchema } from '../schemas/globalBonusSchema';

interface Args {
  bonusId: GlobalBonusSchema['id'];
}

const deleteGlobalBonus = async (args: Args) => {
  const { bonusId } = args;

  const response = await api.delete(`/api/bonus/${bonusId}`);

  return emptyResponseSchema.parse(response.data);
};

export default deleteGlobalBonus;
