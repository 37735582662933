import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import { WorkspaceAccountSchema } from '../schemas/account/workspaceAccountSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

type Schema = WorkspaceAccountSchema;

interface Payload {
  on_registration?: Schema['on_registration'];
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  accountId: Schema['id'];
  payload: Payload;
}

const changeWorkspaceAccountRegistrationStatus = async (args: Args) => {
  const { workspaceId, accountId, payload } = args;

  const response = await api.patch(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/account/${accountId}/change-registration-status`,
    payload
  );

  return emptyResponseSchema.parse(response.data);
};

export default changeWorkspaceAccountRegistrationStatus;
