import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { TimeZoneSchema, timeZones } from '../api/schemas/timeZoneSchema';
import { MONTH_DAY_TIME, TIME } from '../constants/DATE_FORMATS';
import { t } from '../i18n';
import { useTimeZoneStore } from '../store/timeZone';
import { getDate, getTimeZoneFormat } from '../utils/date';

const TIMEZONE_CAPTION: Record<TimeZoneSchema, string> = {
  local: t('common.my_local_time_zone'),
  UTC: t('common.coordinated_universal_time'),
  'Pacific/Pago_Pago': 'Pago Pago, Midway Islands, Niue',
  'Pacific/Honolulu': 'Honolulu, Rarotonga, Tahiti',
  'Pacific/Marquesas': 'Marquesas Islands',
  'Pacific/Gambier': 'Gambier Islands',
  'America/Anchorage': 'Anchorage, Juneau',
  'Pacific/Pitcairn': 'Pitcairn Islands',
  'America/Los_Angeles': 'Los Angeles, Vancouver, Tijuana',
  'America/Phoenix': 'Phoenix',
  'America/Denver': 'Denver',
  'America/Mexico_City': 'Mexico City',
  'America/Chicago': 'Chicago, Winnipeg',
  'America/Jamaica': 'Jamaica, Panama',
  'America/New_York': 'New York, Toronto, Havana',
  'America/Barbados': 'Barbados, Caracas',
  'America/Halifax': 'Halifax',
  'America/St_Johns': "St. John's",
  'America/Argentina/Buenos_Aires': 'Buenos Aires, São Paulo, Montevideo',
  'America/Noronha': 'Fernando de Noronha, South Georgia',
  'Atlantic/Cape_Verde': 'Cape Verde',
  'Africa/Accra': 'Accra, Reykjavik, Danmarkshavn',
  'Europe/London': 'London, Dublin, Ponta Delgada',
  'Africa/Tunis': 'Tunis',
  'Europe/Paris': 'Paris, Berlin, Rome',
  'Europe/Kaliningrad': 'Kaliningrad',
  'Africa/Cairo': 'Cairo, Athens',
  'Europe/Moscow': 'Moscow, Istanbul, Baghdad, Riyadh',
  'Asia/Tehran': 'Tehran',
  'Asia/Dubai': 'Dubai, Baku, Yerevan, Samara',
  'Asia/Kabul': 'Kabul',
  'Asia/Karachi': 'Karachi, Tashkent, Maldives',
  'Asia/Colombo': 'Colombo, Delhi, Mumbai',
  'Asia/Kathmandu': 'Kathmandu',
  'Asia/Dhaka': 'Dhaka, Almaty, Omsk',
  'Asia/Yangon': 'Yangon, Cocos Islands',
  'Asia/Bangkok': 'Bangkok, Jakarta, Ho Chi Minh City',
  'Asia/Singapore': 'Singapore, Beijing, Ulaanbaatar',
  'Australia/Eucla': 'Eucla',
  'Asia/Tokyo': 'Tokyo, Seoul, Pyongyang',
  'Australia/Darwin': 'Darwin',
  'Australia/Brisbane': 'Brisbane, Vladivostok',
  'Australia/Adelaide': 'Adelaide',
  'Australia/Sydney': 'Sydney, Melbourne',
  'Australia/Lord_Howe': 'Lord Howe Island',
  'Pacific/Noumea': 'Nouméa, Honiara',
  'Pacific/Norfolk': 'Norfolk Island',
  'Pacific/Nauru': 'Nauru',
  'Pacific/Auckland': 'Auckland',
  'Pacific/Apia': 'Apia, Tongatapu',
  'Pacific/Chatham': 'Chatham Islands',
  'Pacific/Kiritimati': 'Kiritimati',
};

const useTimeZone = () => {
  const { timeZone, setTimeZone } = useTimeZoneStore((state) => ({
    timeZone: state.timeZone,
    setTimeZone: state.setTimeZone,
  }));

  const date = new Date();

  const formatTimeZone = (value: TimeZoneSchema, timeZoneFormat: 'O' | 'OOOO' = 'OOOO') => {
    if (value === 'local') return format(date, timeZoneFormat).replace('GMT', 'UTC');

    return formatInTimeZone(date, value, timeZoneFormat).replace('GMT', 'UTC');
  };

  const dateToZonedDateTime = (value: string, type?: 'startOfDay' | 'endOfDay') => {
    if (!value) return '';

    const time = type === 'endOfDay' ? '23:59' : '00:00';

    return `${value} ${time}${formatTimeZone(timeZone).replace('UTC', '')}`;
  };

  const dateTimeToZonedDateTime = (value: string) => {
    if (!value) return '';

    return `${value}${formatTimeZone(timeZone).replace('UTC', '')}`;
  };

  const options = timeZones
    .filter((item) => item !== 'UTC' && item !== 'local')
    .map((item) => ({
      label: `${formatTimeZone(item)} (${formatInTimeZone(
        date,
        getTimeZoneFormat(item),
        MONTH_DAY_TIME
      )})`,
      caption: TIMEZONE_CAPTION[item],
      active: timeZone === item,
      onClick: () => setTimeZone(item),
    }));

  return {
    time: `${format(getDate(), TIME)}  (${formatTimeZone(timeZone, 'O')})`,
    options: [
      {
        label: `${formatTimeZone('local')} (${formatInTimeZone(
          date,
          getTimeZoneFormat('local'),
          MONTH_DAY_TIME
        )})`,
        caption: TIMEZONE_CAPTION.local,
        active: timeZone === 'local',
        onClick: () => setTimeZone('local'),
      },
      {
        label: `${formatTimeZone('UTC')} (${formatInTimeZone(
          date,
          getTimeZoneFormat('UTC'),
          MONTH_DAY_TIME
        )})`,
        caption: TIMEZONE_CAPTION.UTC,
        active: timeZone === 'UTC',
        onClick: () => setTimeZone('UTC'),
      },
      { divider: true },
      ...options,
    ],
    formatTimeZone,
    dateToZonedDateTime,
    dateTimeToZonedDateTime,
  };
};

export default useTimeZone;
