import { z } from 'zod';
import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import workspaceDashboardAccountCountSchema from '../schemas/workspace-dashboard/workspaceDashboardAccountCountSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceDashboardAccountsCount = async (args: Args) => {
  const { workspaceId } = args;

  const response = await api.get(
    `/api/${ALIASES.WORKSPACE.L}/${workspaceId}/dashboard/accounts-by-stage`
  );

  return z.array(workspaceDashboardAccountCountSchema).parse(response.data);
};

export default loadWorkspaceDashboardAccountsCount;
