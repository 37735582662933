import api from '../../services/api';
import emptyResponseSchema from '../schemas/emptyResponseSchema';
import { GlobalRoleSchema } from '../schemas/globalRoleSchema';

type Schema = GlobalRoleSchema;

interface Payload {
  name?: Schema['name'];
  permissions?: Schema['permissions'];
}

interface Args {
  roleId: Schema['id'];
  payload: Payload;
}

const updateGlobalRole = async (args: Args) => {
  const { roleId, payload } = args;

  const response = await api.patch(`/api/role/${roleId}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateGlobalRole;
