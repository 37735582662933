import api from '../../services/api';
import { GlobalAccountSchema } from '../schemas/account/globalAccountSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';

interface Args {
  accountId: GlobalAccountSchema['id'];
}

const resetGlobalAccountTablesCache = async (args: Args) => {
  const { accountId } = args;

  const response = await api.post(`/api/lobby/account/${accountId}/reset-cache`);

  return emptyResponseSchema.parse(response.data);
};

export default resetGlobalAccountTablesCache;
