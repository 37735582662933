import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  loadWorkspaceDashboardAccountsCount,
  loadWorkspaceDashboardChannelsCount,
  loadWorkspaceDashboardIdentitiesCount,
} from '../../../api';
import { STATUS } from '../../../api/schemas/channelSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import queryKeys from '../../../config/queryKeys';
import COLORS from '../../../constants/COLORS';
import { CHANNEL_STATUS_LABELS } from '../../workspace-channel/helpers';
import styles from './styles.module.scss';
import WorkspaceDashboardWidget, { Chart } from './WorkspaceDashboardWidget';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  className?: string;
}

const WorkspaceDashboardWidgets = (props: Props) => {
  const { workspaceId, className } = props;

  const { t } = useTranslation();

  const { data: accountsData = [], isPending: accountsLoading } = useQuery({
    queryKey: [queryKeys.workspaceDashboardAccountsCount({ workspaceId })],
    queryFn: () => loadWorkspaceDashboardAccountsCount({ workspaceId }),
    meta: { ignoredStatuses: [403] },
  });

  const { data: channelsData = [], isPending: channelsLoading } = useQuery({
    queryKey: [queryKeys.workspaceDashboardChannelsCount({ workspaceId })],
    queryFn: () => loadWorkspaceDashboardChannelsCount({ workspaceId }),
    meta: { ignoredStatuses: [403] },
  });

  const { data: identitiesData = [], isPending: identitiesLoading } = useQuery({
    queryKey: [queryKeys.workspaceDashboardIdentitiesCount({ workspaceId })],
    queryFn: () => loadWorkspaceDashboardIdentitiesCount({ workspaceId }),
    meta: { ignoredStatuses: [403] },
  });

  const modifiedAccountsData: Chart = accountsData.map((item) => {
    const getColor = () => {
      if (item.stage === 'Active') return COLORS.GREEN_300;
      if (item.stage === 'Banned') return COLORS.ORANGE_300;
      if (item.stage === 'Deleted') return COLORS.RED_300;

      return null;
    };

    return {
      label: item.stage,
      count: item.count,
      color: getColor(),
    };
  });

  const modifiedChannelsData: Chart = channelsData.map((item) => {
    const getColor = () => {
      if (item.status === STATUS.ACTIVE) return COLORS.GREEN_300;
      if (item.status === STATUS.INACTIVE) return COLORS.RED_300;

      return null;
    };

    return {
      label: CHANNEL_STATUS_LABELS[item.status],
      count: item.count,
      color: getColor(),
    };
  });

  const modifiedIdentitiesData: Chart = identitiesData.map((item) => ({
    label: item.country,
    count: item.count,
    color: null,
  }));

  return (
    <div className={clsx(styles.container, className)}>
      <WorkspaceDashboardWidget
        title={t('common.accounts')}
        data={modifiedAccountsData}
        loading={accountsLoading}
      />
      <WorkspaceDashboardWidget
        title={t('common.channels')}
        data={modifiedChannelsData}
        loading={channelsLoading}
      />
      <WorkspaceDashboardWidget
        title={t('common.identities')}
        data={modifiedIdentitiesData}
        loading={identitiesLoading}
      />
    </div>
  );
};

export default WorkspaceDashboardWidgets;
