import { WorkspaceClubMemberSchema } from '../../api/schemas/workspaceClubMemberSchema';
import PrettyNumber from '../../components/PrettyNumber';
import { convertCentsToDollars } from '../../utils/data';

interface Props {
  data: WorkspaceClubMemberSchema;
}

const WorkspaceClubMemberTotalFee = (props: Props) => {
  const { data } = props;

  const { total_fee } = data;

  return <PrettyNumber>{convertCentsToDollars(total_fee)}</PrettyNumber>;
};

export default WorkspaceClubMemberTotalFee;
