import { useTranslation } from 'react-i18next';
import icon from '../../../static/icons';
import Button from '../../Button';
import Indicator from '../../Indicator';
import Tooltip from '../../Tooltip';
import styles from './styles.module.scss';

interface Props {
  count: number;
  onClick: () => void;
}

const NotesButton = (props: Props) => {
  const { count, onClick } = props;

  const { t } = useTranslation();

  return (
    <Indicator variant="dot" count={count} className={styles.container}>
      <Tooltip label={t('common.notes')} hideOnMobile>
        <Button
          variant="transparent"
          icon={icon('notes', 16)}
          onClick={onClick}
          data-testid="notes"
        />
      </Tooltip>
    </Indicator>
  );
};

export default NotesButton;
