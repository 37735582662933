import Alert from '../../../components/Alert';
import { Type } from '../../../types/Alert';
import { capitalize } from '../../../utils/data';
import styles from './styles.module.scss';

const types: Type[] = ['success', 'warning', 'info', 'error'];

const StorybookPageAlert = () => (
  <>
    {types.map((item) => (
      <div key={item} className={styles.alerts}>
        <h2>{capitalize(item)}</h2>
        <div>
          <Alert type={item} title="Title" closable={false} />
          <Alert type={item} title="Title" description="Description" closable={false} />
        </div>
      </div>
    ))}
  </>
);

export default StorybookPageAlert;
