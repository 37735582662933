import { Controller, UseFormReturn } from 'react-hook-form';
import Input from '../../../../components/Input';
import Label from '../../../../components/Label';
import Select from '../../../../components/Select';
import { t } from '../../../../i18n';
import { max, required } from '../../../../utils/form';
import {
  booleanOptions,
  getBooleanOption,
  playerTypesOptions,
  seatingPreferencesOptions,
} from '../../../account/helpers';
import { Fields } from '../../../account/strategyHelpers';
import styles from './styles.module.scss';

// eslint-disable-next-line no-use-before-define
export const labels: Record<keyof ReturnType<typeof getFields>, string> = {
  player_type: t('common.player_type'),
  buyin_bb_min: t('common.buy_in_range_bb'),
  buyin_step_bb: t('common.buy_in_step_bb'),
  buyin_bb_straddle_table_min: t('common.straddle_tables_buy_in_range_bb'),
  buyin_step_bb_straddle_table: t('common.straddle_tables_buy_in_step_bb'),
  rebuy_below_bb: t('common.rebuy_threshold_bb'),
  rebuy_below_bb_straddle_table: t('common.straddle_tables_rebuy_threshold_bb'),
  stop_lose_bb: t('common.stop_loss_bb'),
  stop_win_bb: t('common.stop_win_bb'),
  stop_lose_table_bb: t('common.stop_loss_by_table_bb'),
  stop_win_table_bb: t('common.stop_win_by_table_bb'),
  max_swing_bb: t('common.max_swing_bb'),
  effective_stack_limit_bb: t('common.eff_stack_bb'),
  max_stack_size_bb: t('common.max_stack_bb'),
  table_min_hands: t('common.min_hands'),
  table_time: t('common.table_time'),
  wait_for_big_blind: t('common.wait_for_bb'),
  seating_preference: t('common.seat'),
  mtt_max_rebuy: t('common.mtt_max_rebuy'),
  mtt_addon: t('common.mtt_addon'),
  auto_rebuy: t('common.auto_rebuy'),
};

export const getFields = (form: UseFormReturn<Fields>) => {
  const {
    control,
    formState: { errors },
  } = form;

  const MAX = 100_000;

  return {
    player_type: (
      <Controller
        control={control}
        rules={{ validate: { required } }}
        name="player_type"
        render={({ field }) => (
          <Select
            clearable={false}
            label={{
              text: `${labels.player_type}*`,
              hint: String(t('sentences.strategy_player_type')),
            }}
            options={playerTypesOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.player_type?.message}
          />
        )}
      />
    ),
    buyin_bb_min: (
      <div>
        <Label hint={String(t('sentences.strategy_buy_in_range_bb'))}>
          {`${labels.buyin_bb_min}*`}
        </Label>
        <div className={styles.controllersGroup}>
          <Controller
            control={control}
            rules={{
              validate: {
                required,
                max: max(labels.buyin_bb_min, MAX),
              },
            }}
            name="buyin_bb_min"
            render={({ field }) => (
              <Input
                placeholder={t('common.min')}
                error={errors.buyin_bb_min?.message}
                format={{ onValueChange: field.onChange }}
                {...field}
              />
            )}
          />
          <span>—</span>
          <Controller
            control={control}
            rules={{
              validate: {
                required,
                max: max(labels.buyin_bb_min, MAX),
              },
            }}
            name="buyin_bb_max"
            render={({ field }) => (
              <Input
                placeholder={t('common.max')}
                error={errors.buyin_bb_max?.message}
                format={{ onValueChange: field.onChange }}
                {...field}
              />
            )}
          />
        </div>
      </div>
    ),
    buyin_step_bb: (
      <Controller
        control={control}
        rules={{ validate: { required, max: max(labels.buyin_step_bb, 100) } }}
        name="buyin_step_bb"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.buyin_step_bb}*`,
              hint: String(t('sentences.strategy_buy_in_step_bb')),
            }}
            error={errors.buyin_step_bb?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    buyin_bb_straddle_table_min: (
      <div>
        <Label hint={String(t('sentences.strategy_straddle_tables_buy_in_range_bb'))}>
          {`${labels.buyin_bb_straddle_table_min}*`}
        </Label>
        <div className={styles.controllersGroup}>
          <Controller
            control={control}
            rules={{
              validate: {
                required,
                max: max(labels.buyin_bb_straddle_table_min, MAX),
              },
            }}
            name="buyin_bb_straddle_table_min"
            render={({ field }) => (
              <Input
                placeholder={t('common.min')}
                error={errors.buyin_bb_straddle_table_min?.message}
                format={{ onValueChange: field.onChange }}
                {...field}
              />
            )}
          />
          <span>—</span>
          <Controller
            control={control}
            rules={{
              validate: {
                required,
                max: max(labels.buyin_bb_straddle_table_min, MAX),
              },
            }}
            name="buyin_bb_straddle_table_max"
            render={({ field }) => (
              <Input
                placeholder={t('common.max')}
                error={errors.buyin_bb_straddle_table_max?.message}
                format={{ onValueChange: field.onChange }}
                {...field}
              />
            )}
          />
        </div>
      </div>
    ),
    buyin_step_bb_straddle_table: (
      <Controller
        control={control}
        rules={{ validate: { required, max: max(labels.buyin_step_bb_straddle_table, 100) } }}
        name="buyin_step_bb_straddle_table"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.buyin_step_bb_straddle_table}*`,
              hint: String(t('sentences.strategy_straddle_tables_buy_in_step_bb')),
            }}
            error={errors.buyin_step_bb_straddle_table?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    rebuy_below_bb: (
      <Controller
        control={control}
        rules={{
          validate: {
            required,
            max: max(labels.rebuy_below_bb, MAX),
          },
        }}
        name="rebuy_below_bb"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.rebuy_below_bb}*`,
              hint: String(t('sentences.strategy_rebuy_threshold_bb')),
            }}
            error={errors.rebuy_below_bb?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    rebuy_below_bb_straddle_table: (
      <Controller
        control={control}
        rules={{
          validate: {
            required,
            max: max(labels.rebuy_below_bb_straddle_table, MAX),
          },
        }}
        name="rebuy_below_bb_straddle_table"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.rebuy_below_bb_straddle_table}*`,
              hint: String(t('sentences.strategy_straddle_tables_rebuy_threshold_bb')),
            }}
            error={errors.rebuy_below_bb_straddle_table?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    stop_lose_bb: (
      <Controller
        control={control}
        rules={{
          validate: {
            required,
            max: max(labels.stop_lose_bb, MAX),
          },
        }}
        name="stop_lose_bb"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.stop_lose_bb}*`,
              hint: String(t('sentences.strategy_stop_lose_bb')),
            }}
            error={errors.stop_lose_bb?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    stop_win_bb: (
      <Controller
        control={control}
        rules={{
          validate: {
            required,
            max: max(labels.stop_lose_table_bb, MAX),
          },
        }}
        name="stop_win_bb"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.stop_win_bb}*`,
              hint: String(t('sentences.strategy_stop_win_bb')),
            }}
            error={errors.stop_win_bb?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    stop_lose_table_bb: (
      <Controller
        control={control}
        rules={{
          validate: {
            required,
            max: max(labels.stop_lose_table_bb, MAX),
          },
        }}
        name="stop_lose_table_bb"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.stop_lose_table_bb}*`,
              hint: String(t('sentences.strategy_stop_lose_table_bb')),
            }}
            error={errors.stop_lose_table_bb?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    stop_win_table_bb: (
      <Controller
        control={control}
        rules={{
          validate: {
            required,
            max: max(labels.stop_win_table_bb, MAX),
          },
        }}
        name="stop_win_table_bb"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.stop_win_table_bb}*`,
              hint: String(t('sentences.strategy_stop_win_table_bb')),
            }}
            error={errors.stop_win_table_bb?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    max_swing_bb: (
      <Controller
        control={control}
        rules={{
          validate: {
            required,
            max: max(labels.max_swing_bb, MAX),
          },
        }}
        name="max_swing_bb"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.max_swing_bb}*`,
              hint: String(t('sentences.strategy_max_swing_bb')),
            }}
            error={errors.max_swing_bb?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    effective_stack_limit_bb: (
      <Controller
        control={control}
        rules={{
          validate: {
            required,
            max: max(labels.effective_stack_limit_bb, MAX),
          },
        }}
        name="effective_stack_limit_bb"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.effective_stack_limit_bb}*`,
              hint: String(t('sentences.strategy_effective_stack_limit_bb')),
            }}
            error={errors.effective_stack_limit_bb?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    max_stack_size_bb: (
      <Controller
        control={control}
        rules={{
          validate: {
            required,
            max: max(labels.max_stack_size_bb, MAX),
          },
        }}
        name="max_stack_size_bb"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.max_stack_size_bb}*`,
              hint: String(t('sentences.strategy_max_stack_size_bb')),
            }}
            error={errors.max_stack_size_bb?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    table_min_hands: (
      <Controller
        control={control}
        rules={{
          validate: {
            required,
            max: max(labels.table_min_hands, MAX),
          },
        }}
        name="table_min_hands"
        render={({ field }) => (
          <Input
            label={{
              text: `${labels.table_min_hands}*`,
              hint: String(t('sentences.strategy_table_min_hands')),
            }}
            error={errors.table_min_hands?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    table_time: (
      <Controller
        control={control}
        rules={{
          validate: {
            max: max(labels.table_time, MAX),
          },
        }}
        name="table_time"
        render={({ field }) => (
          <Input
            label={labels.table_time}
            error={errors.table_time?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    mtt_max_rebuy: (
      <Controller
        control={control}
        rules={{
          validate: {
            required,
            max: max(labels.mtt_max_rebuy, 5),
          },
        }}
        name="mtt_max_rebuy"
        render={({ field }) => (
          <Input
            label={labels.mtt_max_rebuy}
            error={errors.mtt_max_rebuy?.message}
            format={{ onValueChange: field.onChange }}
            {...field}
          />
        )}
      />
    ),
    mtt_addon: (
      <Controller
        name="mtt_addon"
        control={control}
        render={({ field }) => (
          <Select
            clearable={false}
            label={String(t('common.mtt_addon'))}
            options={booleanOptions}
            value={typeof field.value === 'boolean' ? getBooleanOption(field.value) : field.value}
            onChange={field.onChange}
            error={errors.mtt_addon?.message}
          />
        )}
      />
    ),
    auto_rebuy: (
      <Controller
        name="auto_rebuy"
        control={control}
        render={({ field }) => (
          <Select
            clearable={false}
            label={String(t('common.auto_rebuy'))}
            options={booleanOptions}
            value={typeof field.value === 'boolean' ? getBooleanOption(field.value) : field.value}
            onChange={field.onChange}
            error={errors.auto_rebuy?.message}
          />
        )}
      />
    ),
    wait_for_big_blind: (
      <Controller
        name="wait_for_big_blind"
        control={control}
        render={({ field }) => (
          <Select
            clearable={false}
            label={{
              text: `${labels.wait_for_big_blind}*`,
              hint: String(t('sentences.strategy_wait_for_big_blind')),
            }}
            options={booleanOptions}
            value={typeof field.value === 'boolean' ? getBooleanOption(field.value) : field.value}
            onChange={field.onChange}
            error={errors.wait_for_big_blind?.message}
          />
        )}
      />
    ),
    seating_preference: (
      <Controller
        control={control}
        rules={{ validate: { required } }}
        name="seating_preference"
        render={({ field }) => (
          <Select
            clearable={false}
            label={{
              text: `${labels.seating_preference}*`,
              hint: String(t('sentences.strategy_seating_preference')),
            }}
            options={seatingPreferencesOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.seating_preference?.message}
          />
        )}
      />
    ),
  };
};
