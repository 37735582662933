import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { updateWorkspaceAccountSession } from '../../api';
import { WorkspaceSessionSchema } from '../../api/schemas/session/workspaceSessionSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Form from '../../components/Form';
import PageAddon from '../../components/PageAddon';
import SubmitButton from '../../components/SubmitButton';
import notify from '../../utils/notify';
import { TYPE_ENUM, getTitle } from '../session/helpers';
import SessionFormType from '../session/SessionFormType';
import { Fields } from '../session/types';

interface Props {
  data: WorkspaceSessionSchema;
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
}

const WorkspaceSessionForm = (props: Props) => {
  const { data, workspaceId, onClose } = props;

  const { id: sessionId, start, end, account } = data;

  const { id: accountId } = account;

  const { t } = useTranslation();

  const form = useForm<Fields>({ defaultValues: { session_type: String(data.session_type) } });

  const onSubmit = async (values: Fields) => {
    const payload = {
      session_type: TYPE_ENUM[values.session_type],
    };

    await updateWorkspaceAccountSession({ workspaceId, accountId, sessionId, payload });

    onClose();
    notify('success', { title: t('sentences.changes_have_been_saved') });
  };

  return (
    <PageAddon title={`${t('common.edit')} ${getTitle({ start, end })}`} onClose={onClose}>
      <Form form={form} onSubmit={onSubmit}>
        <SessionFormType />
        <PageAddon.Controls>
          <SubmitButton>{t('common.save')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default WorkspaceSessionForm;
