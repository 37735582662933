import { activateGlobalSeatControlRule, deactivateGlobalSeatControlRule } from '../../api';
import { SeatControlRuleSchema } from '../../api/schemas/seatControlRuleSchema';
import Checkbox from '../../components/Checkbox';
import { handleError } from '../../utils/form';
import { can } from '../../utils/permissions';

interface Props {
  data: SeatControlRuleSchema;
}

const GlobalSeatControlRuleIsActive = (props: Props) => {
  const { data } = props;

  const { id, is_active, is_deleted } = data;

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (event.target.checked) {
        await activateGlobalSeatControlRule({ seatControlRuleId: id });
      } else {
        await deactivateGlobalSeatControlRule({ seatControlRuleId: id });
      }
    } catch (error) {
      handleError({ error });
    }
  };

  const disabled = is_deleted || !can.seatControlRule.createUpdate;

  return (
    <Checkbox variant="switch" checked={is_active} onChange={handleChange} disabled={disabled} />
  );
};

export default GlobalSeatControlRuleIsActive;
