import clsx from 'clsx';
import { useState } from 'react';
import icon from '../../../../static/icons';
import { ActionType, Enums } from '../../helpers';
import { Changes, getChange } from './helpers';
import styles from './styles.module.scss';

interface Props {
  changes: Changes;
  actionType: ActionType;
  enums?: Enums;
}

const HistoryDialogCollapseFieldGroup = (props: Props) => {
  const { changes, actionType, enums } = props;

  const [open, setOpen] = useState(false);

  const toggle = () => setOpen((prev) => !prev);

  return (
    <div key={changes.name} className={clsx(styles.fieldGroup, open && styles.open)}>
      {getChange({
        name: changes.name,
        oldValue: changes.old_value,
        newValue: changes.new_value,
        actionType,
        enums,
        renderLabel: ({ name }) => (
          <button
            type="button"
            onClick={toggle}
            className={clsx(styles.collapseButton, open && styles.open)}
          >
            {name}
            {icon('chevronDown', 12)}
          </button>
        ),
        renderGroup: (args) => (
          <HistoryDialogCollapseFieldGroup changes={args} actionType={actionType} enums={enums} />
        ),
      })}
    </div>
  );
};

export default HistoryDialogCollapseFieldGroup;
