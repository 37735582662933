import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSidebarStore } from '../../../store/sidebar';
import styles from './styles.module.scss';

interface Props {
  isWorkspaces?: boolean;
}

const SidebarSeparator = (props: Props) => {
  const { isWorkspaces } = props;

  const { t } = useTranslation();
  const { open } = useSidebarStore((state) => ({ open: state.open }));

  const className = clsx(styles.container, {
    [styles.open]: open,
    [styles.second]: !isWorkspaces,
  });

  return (
    <div className={className}>{isWorkspaces ? t('common.workspace') : t('common.platform')}</div>
  );
};

export default SidebarSeparator;
