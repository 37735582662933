import { z } from 'zod';
import workspaceStageSchema from '../stage/workspaceStageSchema';

const workspaceDashboardAccountCountSchema = z.object({
  count: z.number(),
  stage: workspaceStageSchema.shape.name,
});

export type WorkspaceDashboardAccountCountSchema = z.infer<
  typeof workspaceDashboardAccountCountSchema
>;

export default workspaceDashboardAccountCountSchema;
