import api from '../../services/api';
import URLParams from '../../types/URLParams';
import { AgentSchema } from '../schemas/agentSchema';
import workspaceAccountNoteSchema from '../schemas/noteSchema';
import paginationSchemaNew from '../schemas/paginationSchemaNew';

interface Args {
  agentId: AgentSchema['id'];
}

const loadGlobalAgentNotes = async (args: Args, params?: URLParams) => {
  const { agentId } = args;

  const response = await api.get(`/api/agent/${agentId}/notes`, {
    params,
  });

  return paginationSchemaNew(workspaceAccountNoteSchema).parse(response.data);
};

export default loadGlobalAgentNotes;
