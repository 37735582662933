import clsx from 'clsx';
import { ReactNode } from 'react';
import PageAddonControlsGroup from './PageAddonControlsGroup';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

const PageAddonControls = (props: Props) => {
  const { children, className: customClassName } = props;

  const className = clsx(styles.container, {
    ...(customClassName && { [customClassName]: true }),
  });

  return (
    <div className={className}>
      <div className={styles.wrapper}>{children}</div>
    </div>
  );
};

PageAddonControls.Group = PageAddonControlsGroup;

export default PageAddonControls;
