import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import Auth from '../../components/Auth';
import Form from '../../components/Form';
import Input from '../../components/Input';
import PasswordInput from '../../components/PasswordInput';
import routes from '../../config/routes';
import useURL from '../../hooks/useURL';
import { useSessionStore } from '../../store/session';
import { loginUser, verifyEmail } from '../../utils/auth';
import { email, password, required } from '../../utils/form';
import notify from '../../utils/notify';

interface Inputs {
  email: string;
  password: string;
}

const LoginPage = () => {
  const { t } = useTranslation();
  const { urlParams } = useURL();
  const { session } = useSessionStore((state) => ({ session: state.session }));

  const { from, hash } = urlParams;

  const [verified, setVerified] = useState(!hash);
  const [verifying, setVerifying] = useState(false);

  const form = useForm<Inputs>({ defaultValues: { email: '', password: '' } });

  const {
    register,
    formState: { errors },
  } = form;

  const onSubmit = loginUser;

  useEffect(() => {
    const verify = async () => {
      try {
        setVerifying(true);

        await verifyEmail(hash);

        setVerified(true);
        setVerifying(false);

        notify('success', { title: t('sentences.email_has_been_verified') });
      } catch {
        notify('error', { title: t('common.something_went_wrong') });
      }
    };

    if (!verified && !verifying) {
      verify();
    }
  }, [hash, verified, verifying, t]);

  if (session) return <Navigate to={from || routes.home} />;

  return (
    <Auth title={t('common.log_in')}>
      <Form form={form} onSubmit={onSubmit}>
        <Input
          label={t('common.email')}
          error={errors.email?.message}
          {...register('email', { validate: { required, email } })}
        />
        <PasswordInput
          autoComplete="on"
          error={errors.password?.message}
          {...register('password', { validate: password })}
        />
        <Auth.SubmitButton>{t('common.login')}</Auth.SubmitButton>
      </Form>
      <Auth.Link to={routes.recovery}>{t('common.forgot_my_password')}</Auth.Link>
    </Auth>
  );
};

export default LoginPage;
