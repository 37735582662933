import { useTranslation } from 'react-i18next';
import { createGlobalBonus, loadGlobalDeals, updateGlobalBonus } from '../../api';
import { GlobalBonusSchema } from '../../api/schemas/globalBonusSchema';
import Checkbox from '../../components/Checkbox';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import Select, { Option } from '../../components/Select';
import Textarea from '../../components/Textarea';
import useQuickForm from '../../hooks/useQuickForm';
import { currenciesOptions, CURRENCY_LABELS } from '../../utils/currency';
import { convertCentsToDollars, convertDollarsToCents } from '../../utils/data';
import { required } from '../../utils/form';
import notify from '../../utils/notify';
import { can } from '../../utils/permissions';
import { renderCaption } from '../deal/helpers';
import { TYPE_LABELS, typesOptions } from './helpers';

interface Fields {
  deal_id: Option<GlobalBonusSchema['deal']['id']>;
  type: Option<GlobalBonusSchema['type']>;
  amount: string;
  currency: Option<GlobalBonusSchema['currency']>;
  reconcile: boolean;
  description: string;
}

interface Args {
  data?: GlobalBonusSchema;
  onCreate?: (args: { bonusId: GlobalBonusSchema['id'] }) => void;
}

const useForm = (args: Args) => {
  const { data, onCreate } = args;

  const { t } = useTranslation();

  const form = useQuickForm<Fields>({
    data,
    defaultValues: {
      deal_id: data ? { label: data.deal.code, value: data.deal.id } : null,
      type: data ? { value: data.type, label: TYPE_LABELS[data.type] } : null,
      amount: data ? String(convertCentsToDollars(data.amount)) : '',
      currency: data ? { value: data.currency, label: CURRENCY_LABELS[data.currency] } : null,
      reconcile: data?.reconcile || false,
      description: data?.description || '',
    },
  });

  const { control, handleSubmit } = form;

  const onSubmit = async (values: Fields) => {
    if (!values.currency || !values.type) throw new Error();

    const payload = {
      type: values.type.value,
      amount: convertDollarsToCents(Number(values.amount)),
      currency: values.currency.value,
      reconcile: values.reconcile,
      description: values.description,
    };

    if (data) {
      await updateGlobalBonus({ bonusId: data.id, payload });
    } else {
      if (!values.deal_id) throw new Error();

      const response = await createGlobalBonus({
        payload: { ...payload, deal_id: values.deal_id.value },
      });

      if (onCreate) {
        onCreate({ bonusId: response.id });
      }

      notify('success', { title: t('sentences.record_has_been_created') });
    }
  };

  const detail = data && !onCreate;

  const disabled = !can.deal.createUpdate;

  const commonProps = { control, disabled, ...(detail && { onSubmit: handleSubmit(onSubmit) }) };

  return {
    form,
    onSubmit,
    fields: {
      deal_id: (
        <PageAddon.Field label={t('common.deal')}>
          <Select.AsyncV2
            onLoad={(params) => loadGlobalDeals(params)}
            select={(item) => ({
              value: item.id,
              label: item.code,
              caption: renderCaption(item),
            })}
          >
            {(selectAsyncProps) => (
              <Select.Quick
                name="deal_id"
                rules={{ validate: { required } }}
                {...{ ...commonProps, disabled: commonProps.disabled || Boolean(data) }}
                {...selectAsyncProps}
              />
            )}
          </Select.AsyncV2>
        </PageAddon.Field>
      ),
      type: (
        <PageAddon.Field label={t('common.type')}>
          <Select.Quick
            name="type"
            options={typesOptions}
            rules={{ validate: { required } }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      amount: (
        <PageAddon.Field label={t('common.amount')}>
          <Input.Quick
            name="amount"
            format={{ decimalScale: 2, allowNegative: false, thousandSeparator: false }}
            rules={{ validate: { required } }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      currency: (
        <PageAddon.Field label={t('common.currency')}>
          <Select.Quick
            name="currency"
            options={currenciesOptions}
            rules={{ validate: { required } }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      reconcile: (
        <PageAddon.Field label={t('common.reconcile')}>
          <Checkbox.Quick name="reconcile" {...commonProps} />
        </PageAddon.Field>
      ),
      description: (
        <PageAddon.Field label={t('common.description')}>
          <Textarea.Quick name="description" {...commonProps} />
        </PageAddon.Field>
      ),
    },
  };
};

export default useForm;
