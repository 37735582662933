import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadWorkspaceMarketIdentities } from '../../api';
import { MarketIdentitySchema } from '../../api/schemas/marketIdentitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Card from '../../components/Card';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import { renderCost } from '../../features/workspace-identity/helpers';
import WorkspaceIdentityCost from '../../features/workspace-identity/WorkspaceIdentityCost';
import WorkspaceIdentityCountry from '../../features/workspace-identity/WorkspaceIdentityCountry';
import WorkspaceIdentityDocuments from '../../features/workspace-identity/WorkspaceIdentityDocuments';
import WorkspaceIdentityPhotos from '../../features/workspace-identity/WorkspaceIdentityPhotos';
import WorkspaceIdentityWallets from '../../features/workspace-identity/WorkspaceIdentityWallets';
import WorkspaceMarketIdentityCartButton from '../../features/workspace-market-identity/WorkspaceMarketIdentityCart/WorkspaceMarketIdentityCartButton';
import WorkspaceMarketIdentityDetailLink from '../../features/workspace-market-identity/WorkspaceMarketIdentityDetailLink';
import WorkspaceMarketIdentityDropdown from '../../features/workspace-market-identity/WorkspaceMarketIdentityDropdown';
import WorkspaceMarketIdentityFilter from '../../features/workspace-market-identity/WorkspaceMarketIdentityFilter';
import WorkspaceMarketIdentityWidget from '../../features/workspace-market-identity/WorkspaceMarketIdentityWidget';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedData from '../../hooks/usePaginatedData';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { RouteParams } from '../../utils/generics';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.workspaceMarketIdentity>;

interface Props {
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceMarketIdentityPage = (props: Props) => {
  const { workspaceId } = props;

  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { identityId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search } = urlParams;

  const queryKey = [queryKeys.workspaceMarketIdentities({ workspaceId }), urlParams];

  const query = usePaginatedData({
    queryKey,
    queryFn: (params) =>
      loadWorkspaceMarketIdentities({ workspaceId }, { ...urlParams, ...params }),
  });

  const { ids, data, total, loading, error, checkIsInView } = query;

  const { checkIsCreated, ...meta } = useWebSocketPaginatedActions<MarketIdentitySchema>({
    ids,
    updateAction: ['Edited', 'Created'],
    queryKey,
    domain: 'Market',
    select: (item) => item,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <Page
      heading={t('common.market')}
      tabs={[
        {
          to: routes.workspaceMarketIdentities({ workspaceId }),
          label: t('common.identity'),
          active: true,
        },
      ]}
      extra={<PageControls />}
      filter={<WorkspaceMarketIdentityFilter workspaceId={workspaceId} total={total} />}
      widget={<WorkspaceMarketIdentityWidget workspaceId={workspaceId} />}
      addon={<Outlet />}
    >
      <PageTop
        total={total}
        loading={loading}
        {...(isPhone && {
          sorting: [{ key: 'location.country_name', title: t('common.country'), default: true }],
        })}
        meta={meta}
      />
      {(isDesktop || isTablet) && (
        <Table
          data={data}
          search={search}
          {...getTableProps(query)}
          className={styles.table}
          columns={[
            {
              title: t('common.seller'),
              render: ({ values }) => (
                <WorkspaceMarketIdentityDetailLink data={values} workspaceId={workspaceId} />
              ),
            },
            {
              title: <WorkspaceIdentityCost />,
              render: ({ values }) => renderCost(values.monthly_cost),
            },
            {
              title: t('common.min_rent'),
              render: ({ values }) => values.minimal_rental_period,
            },
            {
              key: 'location.country_name',
              title: t('common.ctry'),
              sorting: true,
              render: ({ values }) => <WorkspaceIdentityCountry data={values} />,
            },
            {
              title: t('common.documents'),
              render: ({ values }) => <WorkspaceIdentityDocuments documents={values.documents} />,
            },
            {
              title: t('common.selfies'),
              render: ({ values }) => <WorkspaceIdentityPhotos photos={values.photos} />,
            },
            {
              title: t('common.wallets'),
              render: ({ values }) => <WorkspaceIdentityWallets wallets={values.online_wallets} />,
            },
            {
              render: ({ values }) => (
                <div className={styles.buttons}>
                  <WorkspaceMarketIdentityCartButton workspaceId={workspaceId} data={values} />
                  <WorkspaceMarketIdentityDropdown data={values} />
                </div>
              ),
              align: 'right',
              controls: true,
            },
          ]}
          renderRow={(row, index) => (
            <Table.Row
              highlighted={checkIsCreated(row.data.id)}
              active={row.data.id === identityId}
              danger={row.data.is_deleted}
              {...checkIsInView(index)}
              {...row}
            />
          )}
        />
      )}
      {isPhone && (
        <Card.Group>
          {data.map((item, index) => (
            <Card
              key={item.id}
              highlighted={checkIsCreated(item.id)}
              danger={item.is_deleted}
              {...checkIsInView(index)}
            >
              <Card.Top>
                <WorkspaceMarketIdentityDetailLink data={item} workspaceId={workspaceId} />
                <Card.Top.Controls>
                  <WorkspaceMarketIdentityCartButton workspaceId={workspaceId} data={item} />
                  <WorkspaceMarketIdentityDropdown data={item} />
                </Card.Top.Controls>
              </Card.Top>
              <Card.Field.Group>
                <Card.Field label={<WorkspaceIdentityCost />}>
                  {renderCost(item.monthly_cost)}
                </Card.Field>
                <Card.Field label={t('common.min_rent')}>{item.minimal_rental_period}</Card.Field>
                <Card.Field label={t('common.ctry')}>
                  <WorkspaceIdentityCountry data={item} />
                </Card.Field>
                <Card.Field label={t('common.documents')}>
                  <WorkspaceIdentityDocuments documents={item.documents} />
                </Card.Field>
                <Card.Field label={t('common.selfies')}>
                  <WorkspaceIdentityPhotos photos={item.photos} />
                </Card.Field>
                <Card.Field label={t('common.wallets')}>
                  <WorkspaceIdentityWallets wallets={item.online_wallets} />
                </Card.Field>
              </Card.Field.Group>
            </Card>
          ))}
        </Card.Group>
      )}
    </Page>
  );
};

export default WorkspaceMarketIdentityPage;
