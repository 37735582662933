import {
  useState,
  createContext,
  useMemo,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
  ReactElement,
} from 'react';

type Extra = ReactNode;

interface Context {
  extra: Extra;
  setExtra: Dispatch<SetStateAction<Extra>>;
}

const PageAddonContext = createContext<Context | undefined>(undefined);

interface Props {
  children: ReactNode;
}

const PageAddonProvider = (props: Props) => {
  const { children } = props;

  const [extra, setExtra] = useState<Extra>(null);

  const value = useMemo(() => ({ extra, setExtra }), [extra, setExtra]);

  return <PageAddonContext.Provider value={value}>{children}</PageAddonContext.Provider>;
};

const usePageAddonContext = () => {
  const context = useContext(PageAddonContext);

  if (context === undefined) {
    throw new Error('usePageAddonContext must be used within a PageAddonProvider');
  }

  return context;
};

const PageAddonContextConsumer = (props: {
  children: (context: ReturnType<typeof usePageAddonContext>) => ReactElement;
}) => {
  const { children } = props;

  const context = usePageAddonContext();

  return children(context);
};

export { PageAddonProvider, usePageAddonContext, PageAddonContextConsumer };
