import { useTranslation } from 'react-i18next';
import Id from '../../Id';
import PageAddonField from '../PageAddonField';

interface Props {
  id: string;
  label?: string;
}

const PageAddonId = (props: Props) => {
  const { id, label } = props;

  const { t } = useTranslation();

  return (
    <PageAddonField label={label || t('common.id')}>
      <Id variant="primary" id={id} />
    </PageAddonField>
  );
};

export default PageAddonId;
