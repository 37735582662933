import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import globalLeagueSchema from '../schemas/globalLeagueSchema';
import paginationSchema from '../schemas/paginationSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadWorkspaceLeagues = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(`/api/${ALIASES.WORKSPACE.L}/${workspaceId}/league`, {
    params,
  });

  return paginationSchema(globalLeagueSchema).parse(response.data);
};

export default loadWorkspaceLeagues;
