import { entries, invert, omit, pick } from 'remeda';
import { TRAINER_GAME_TYPE } from '../api/schemas/trainerGameTypeSchema';
import { TRAINER_LIMITS, TrainerLimitSchema } from '../api/schemas/trainerLimitSchema';
import { TRAINER_POKER_ROOM, TrainerPokerRoomSchema } from '../api/schemas/trainerPokerRoomSchema';
import { TRAINER_TECH, TrainerTechSchema } from '../api/schemas/trainerTechSchema';
import { Options } from '../components/Select';

export const TRAINER_POKER_ROOM_LABELS: Record<TrainerPokerRoomSchema, string> = {
  [TRAINER_POKER_ROOM.UNKNOWN]: '—',
  [TRAINER_POKER_ROOM.PARTYPOKER]: 'PartyPoker',
  [TRAINER_POKER_ROOM.PPPOKER]: 'PPPoker',
  [TRAINER_POKER_ROOM.POKERRRR2]: 'Pokerrrr2',
  [TRAINER_POKER_ROOM.CLUBGG]: 'ClubGG',
  [TRAINER_POKER_ROOM.SUPREMA]: 'Suprema',
  [TRAINER_POKER_ROOM.POKERBROS]: 'PokerBros',
  [TRAINER_POKER_ROOM.RULER]: 'Ruler',
};

export const TRAINER_GAME_TYPE_LABELS = invert(TRAINER_GAME_TYPE);

export const TRAINER_LIMITS_LABELS: Record<TrainerLimitSchema, string> = {
  [TRAINER_LIMITS.UNKNOWN]: '—',
  [TRAINER_LIMITS.C2]: 'C2',
  [TRAINER_LIMITS.C10]: 'C10',
  [TRAINER_LIMITS.C25]: 'C25',
  [TRAINER_LIMITS.C50]: 'C50',
  [TRAINER_LIMITS.C100]: 'C100',
  [TRAINER_LIMITS.C200]: 'C200',
  [TRAINER_LIMITS.C400]: 'C400',
  [TRAINER_LIMITS.C600]: 'C600',
  [TRAINER_LIMITS.C1000]: 'C1000',
  [TRAINER_LIMITS.C2000]: 'C2000',
};

export const TRAINER_TECH_LABELS: Record<TrainerTechSchema, string> = {
  [TRAINER_TECH.UNKNOWN]: '—',
  [TRAINER_TECH.PARTYPOKER_DESKTOP]: 'PartyPoker desktop',
  [TRAINER_TECH.PPPPOKER_HEADLESS]: 'PPPoker headless',
  [TRAINER_TECH.PPPPOKER_DESKTOP]: 'PPPoker desktop',
  [TRAINER_TECH.POKERRRR2_HEADLESS]: 'Pokerrrr2 headless',
  [TRAINER_TECH.POKERRRR2_DROP_MANUAL]: 'Pokerrrr2 drop manual',
  [TRAINER_TECH.RULER_HEADLESS]: 'Ruler headless',
  [TRAINER_TECH.CLUBGG_DESKTOP]: 'ClubGG desktop',
};

export const getTrainerPokerRoomOptions = (values: TrainerPokerRoomSchema[]) =>
  values.map((item) => ({
    value: item,
    label: TRAINER_POKER_ROOM_LABELS[item],
  }));

export const trainerPokerRoomOptions = getTrainerPokerRoomOptions(
  Object.values(TRAINER_POKER_ROOM).filter((item) => item !== TRAINER_POKER_ROOM.UNKNOWN)
);

const POPULAR_GAME_TYPES: (keyof typeof TRAINER_GAME_TYPE)[] = [
  'NL',
  'NLB',
  'PLO',
  'PLOB',
  'PLO5C',
  'PLO5CB',
  'PLO5CDB',
  'PLO5CDBB',
  'PLO6C',
  'PLO6CB',
  'PLO6CDB',
  'PLO6CDBB',
  'POFC',
  'ROE',
];

export const trainerGameTypeOptions: Options<number> = entries({
  ...pick(TRAINER_GAME_TYPE, POPULAR_GAME_TYPES),
  ...omit(TRAINER_GAME_TYPE, POPULAR_GAME_TYPES),
})
  .filter(([, value]) => value !== TRAINER_GAME_TYPE.UNSUPPORTED)
  .map(([label, value], index) => ({
    value,
    label,
    ...(index === POPULAR_GAME_TYPES.length && { separator: true }),
  }));

export const trainerTechOptions = Object.values(TRAINER_TECH)
  .filter((item) => item !== TRAINER_TECH.UNKNOWN)
  .map((item) => ({
    value: item,
    label: TRAINER_TECH_LABELS[item],
  }));
