import styles from './styles.module.scss';

interface Props {
  children: React.ReactNode;
}

const PageAddonTags = (props: Props) => {
  const { children } = props;

  return <div className={styles.tags}>{children}</div>;
};

export default PageAddonTags;
