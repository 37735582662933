import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RuleGroupType } from 'react-querybuilder';
import { createGlobalSeatControlRule, updateGlobalSeatControlRule } from '../../../api';
import { SeatControlRuleSchema, ACTION_TYPE } from '../../../api/schemas/seatControlRuleSchema';
import Form from '../../../components/Form';
import Input from '../../../components/Input';
import PageAddon from '../../../components/PageAddon';
import QueryBuilder from '../../../components/QueryBuilder';
import { prepareQuery, mapQuery } from '../../../components/QueryBuilder/helpers';
import Select, { Option, Options } from '../../../components/Select';
import SubmitButton from '../../../components/SubmitButton';
import { maxLength, minLength, required } from '../../../utils/form';
import notify from '../../../utils/notify';
import { ACTION_TYPE_LABEL } from '../../seat-control-rule/helpers';
import styles from './styles.module.scss';

const options: Options<SeatControlRuleSchema['action_type']> = [
  { value: ACTION_TYPE.PLAYER, label: ACTION_TYPE_LABEL[ACTION_TYPE.PLAYER] },
  { value: ACTION_TYPE.OBSERVER, label: ACTION_TYPE_LABEL[ACTION_TYPE.OBSERVER] },
];

interface Fields {
  name: string;
  query: RuleGroupType;
  action_type: Option<SeatControlRuleSchema['action_type']>;
}

interface Props {
  data?: SeatControlRuleSchema;
  onClose: () => void;
}

const GlobalSeatControlRuleForm = (props: Props) => {
  const { data, onClose } = props;

  const { t } = useTranslation();

  const form = useForm<Fields>({
    defaultValues: {
      name: data?.name || '',
      action_type: options.find(
        (item) => item.value === (data ? data.action_type : ACTION_TYPE.PLAYER)
      ),
      query: data ? mapQuery(data.query) : { combinator: 'and', rules: [] },
    },
  });

  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    const payload = {
      ...values,
      query: prepareQuery(values.query),
      action_type: values.action_type?.value,
    };

    if (data) {
      await updateGlobalSeatControlRule({ seatControlRuleId: data.id, payload });
      onClose();
    } else {
      await createGlobalSeatControlRule({ payload });
    }

    notify('success', {
      title: data ? t('sentences.changes_have_been_saved') : t('sentences.record_has_been_created'),
    });
  };

  return (
    <PageAddon
      onClose={onClose}
      className={styles.form}
      title={data ? `${t('common.edit')} ${data.name}` : t('common.create_seat_control_rule')}
    >
      <Form form={form} onSubmit={onSubmit}>
        <Input
          label={`${t('common.name')}*`}
          error={errors.name?.message}
          {...register('name', {
            validate: {
              required,
              minLength: minLength(),
              maxLength: maxLength(),
            },
          })}
        />
        <Controller
          name="action_type"
          control={control}
          rules={{ validate: { required } }}
          render={({ field }) => (
            <Select
              clearable={false}
              options={options}
              label={t('common.action')}
              value={field.value}
              onChange={field.onChange}
              error={errors.action_type?.message}
            />
          )}
        />
        <div>
          <PageAddon.Heading level="first" className={styles.heading}>
            {t('common.rule_builder')}
          </PageAddon.Heading>
          <Controller
            name="query"
            control={control}
            rules={{
              validate: (value) => {
                const validateRules = (rules: RuleGroupType['rules']): boolean | string => {
                  if (rules) {
                    if (rules.length === 0) return true;

                    if (rules.length > 0) {
                      return rules.some((item) => 'rules' in item && validateRules(item.rules));
                    }
                  }

                  return false;
                };

                const hasError = validateRules(value.rules);

                if (hasError) return t('validations.group_or_condition');

                return true;
              },
            }}
            render={({ field }) => (
              <QueryBuilder
                value={field.value}
                error={errors.query?.message}
                onChange={(value) => setValue('query', value, { shouldValidate: true })}
              />
            )}
          />
        </div>
        <PageAddon.Controls>
          <SubmitButton>{data ? t('common.save') : t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default GlobalSeatControlRuleForm;
