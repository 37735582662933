import { ROLE } from '../../api/schemas/deal';
import Badge, { Color } from '../../components/Badge';
import { ROLE_LABELS } from './helpers';
import { CommonDealSchema } from './types';

const COLORS: Record<CommonDealSchema['role'], Color> = {
  [ROLE.UNKNOWN]: 'grey',
  [ROLE.PLAYER]: 'red',
  [ROLE.AGENT]: 'orange',
  [ROLE.CLUBOWNER]: 'green',
  [ROLE.OPEN_DEAL]: 'blue',
};

interface Props {
  data: CommonDealSchema;
}

const DealRole = (props: Props) => {
  const { data } = props;

  const { role } = data;

  return <Badge color={COLORS[role]}>{ROLE_LABELS[role]}</Badge>;
};

export default DealRole;
