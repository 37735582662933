import {
  useContext,
  createContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import { BotSettingDeleteErrorSchema } from '../../api/schemas/botSettingDeleteErrorSchema';

type DeleteError = BotSettingDeleteErrorSchema | null;

interface Context {
  deleteError: DeleteError;
  setDeleteError: Dispatch<SetStateAction<DeleteError>>;
}

const GlobalBotSettingContext = createContext<Context | undefined>(undefined);

interface Props {
  children: ReactNode;
}

const GlobalBotSettingProvider = (props: Props) => {
  const { children } = props;

  const [deleteError, setDeleteError] = useState<DeleteError>(null);

  const value = useMemo(() => ({ deleteError, setDeleteError }), [deleteError]);

  return (
    <GlobalBotSettingContext.Provider value={value}>{children}</GlobalBotSettingContext.Provider>
  );
};

const useGlobalBotSettingContext = () => {
  const context = useContext(GlobalBotSettingContext);

  if (context === undefined) {
    throw new Error('useGlobalBotSettingContext must be used within a GlobalBotSettingProvider');
  }

  return context;
};

export { useGlobalBotSettingContext, GlobalBotSettingProvider };
