import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import loadNotificationsFilterValues from '../../../api/notification/loadNotificationsFilterValues';
import queryKeys from '../../../config/queryKeys';
import URLParams from '../../../types/URLParams';
import Checkbox from '../../Checkbox';
import PageAddon from '../../PageAddon';
import Select from '../../Select';
import { ACTION_TYPE_FILTER_LABELS } from '../helpers';
import { Fields } from '../types';
import styles from './styles.module.scss';

interface Props {
  urlParams: URLParams;
}

const NotificationsFilter = (props: Props) => {
  const { urlParams } = props;

  const { show_read } = urlParams;

  const { t } = useTranslation();

  const { data, isPending } = useQuery({
    queryKey: [queryKeys.notificationsFilterValues, show_read],
    queryFn: () => loadNotificationsFilterValues({ show_read }),
    placeholderData: keepPreviousData,
  });

  const form = useFormContext<Fields>();

  const { control, watch, register } = form;

  return (
    <PageAddon.Filter className={styles.filter}>
      {isPending && <PageAddon.Filter.Placeholder count={{ select: 3, checkbox: 1 }} />}
      {data && (
        <>
          <Controller
            name="action_type_in"
            control={control}
            render={({ field }) => (
              <Select.Multi
                label={t('common.type')}
                options={data.action_type.map((item) => ({
                  value: item,
                  label: ACTION_TYPE_FILTER_LABELS[item],
                }))}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="workspace_in"
            control={control}
            render={({ field }) => (
              <Select.Multi
                label={t('common.workspace')}
                options={data.workspace.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name="author_in"
            control={control}
            render={({ field }) => (
              <Select.Multi
                label={t('common.author')}
                options={data.author.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Checkbox rtl variant="switch" checked={watch('show_read')} {...register('show_read')}>
            {t('common.show_readed')}
          </Checkbox>
        </>
      )}
    </PageAddon.Filter>
  );
};

export default NotificationsFilter;
