import { useTranslation } from 'react-i18next';
import { STATUS as CHANNEL_STATUS } from '../../../api/schemas/channelSchema';
import Tooltip from '../../../components/Tooltip';
import COLORS from '../../../constants/COLORS';
import icon from '../../../static/icons';
import { CommonAccountSchema } from '../types';
import styles from './styles.module.scss';

type Fields = {
  label: string;
  filled: boolean;
}[];

interface Props {
  data: CommonAccountSchema;
  fields?: Fields;
}

const AccountScale = (props: Props) => {
  const { data, fields: customFields = [] } = props;

  const { t } = useTranslation();

  const { clubs, comparative_limits, table_sizes, max_tables, geo, channels, next_session } = data;

  const fields: Fields = [
    {
      label: t('common.club'),
      filled: Boolean(clubs.length),
    },
    {
      label: t('common.limit'),
      filled: Boolean(comparative_limits.length),
    },
    {
      label: t('common.table_size'),
      filled: Boolean(table_sizes.length),
    },
    {
      label: t('common.max_tables'),
      filled: Boolean(max_tables),
    },
    {
      label: t('common.geolocation'),
      filled: Boolean(geo),
    },
    {
      label: t('common.channel'),
      filled: channels.some((item) => item.status === CHANNEL_STATUS.ACTIVE),
    },
    {
      label: t('common.next_session'),
      filled: Boolean(next_session),
    },
    ...customFields,
  ];

  const numberOfFields = fields.length + 1;

  const numberOfFilledFields = fields.map((item) => item.filled).filter((item) => item).length + 1;

  const getColor = () => {
    const fillingPercent = (numberOfFilledFields / numberOfFields) * 100;

    if (fillingPercent < 50) return COLORS.RED_300;
    if (fillingPercent === 100) return COLORS.GREEN_300;

    return COLORS.ORANGE_300;
  };

  return (
    <Tooltip
      label={
        <Tooltip.FieldGroup>
          {fields.map((item) => (
            <Tooltip.Field key={item.label} label={item.label}>
              {item.filled
                ? icon('check', 16, { className: styles.filled })
                : icon('cross', 16, { className: styles.unfilled })}
            </Tooltip.Field>
          ))}
        </Tooltip.FieldGroup>
      }
      placement="right"
      hideOnMobile
    >
      <div className={styles.container}>
        <div className={styles.scale}>
          <div
            style={{
              backgroundColor: getColor(),
              width: `calc(100% * ${numberOfFilledFields} / ${numberOfFields})`,
            }}
            className={styles.filledPartOfScale}
          />
        </div>
      </div>
    </Tooltip>
  );
};

export default AccountScale;
