import { z } from 'zod';
import dateTimeSchema from './dateTimeSchema';
import trainerPokerRoomSchema from './trainerPokerRoomSchema';
import workspaceSchema from './workspaceSchema';

const workspaceClubWhitelistSchema = z.object({
  id: z.string(),
  player_id: z.string(),
  room: trainerPokerRoomSchema,
  maintenance: workspaceSchema,
  is_deleted: z.boolean(),
  created_on: dateTimeSchema,
});

export type WorkspaceClubWhitelistSchema = z.infer<typeof workspaceClubWhitelistSchema>;

export default workspaceClubWhitelistSchema;
