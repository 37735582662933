import { z } from 'zod';
import workspaceAccountSchema from './account/workspaceAccountSchema';

const workspaceAccountVNCSchema = z.object({
  id: workspaceAccountSchema.shape.id,
  room: workspaceAccountSchema.shape.room,
  pilot_status: workspaceAccountSchema.shape.pilot_status,
  trainer_tech: workspaceAccountSchema.shape.maintenance.shape.trainer_tech,
});

export type WorkspaceAccountVNCSchema = z.infer<typeof workspaceAccountVNCSchema>;

export default workspaceAccountVNCSchema;
