import { CREDIT, PAYMENT_SYSTEM, PROTECTION, ROLE, STATUS } from '../../api/schemas/deal';
import Tag from '../../components/Tag';
import { t } from '../../i18n';
import { CommonDealSchema } from './types';

export const ROLE_LABELS: Record<CommonDealSchema['role'], string> = {
  [ROLE.UNKNOWN]: t('common.unknown'),
  [ROLE.PLAYER]: t('common.player'),
  [ROLE.AGENT]: t('common.agent'),
  [ROLE.CLUBOWNER]: t('common.club_owner'),
  [ROLE.OPEN_DEAL]: t('common.open_deal'),
};

export const STATUS_LABELS: Record<CommonDealSchema['status'], string> = {
  [STATUS.UNKNOWN]: t('common.unknown'),
  [STATUS.REVIEW]: t('common.review'),
  [STATUS.AVAILABLE]: t('common.available'),
  [STATUS.ACTIVE]: t('common.active'),
  [STATUS.PAUSED]: t('common.paused'),
  [STATUS.DISCUSSION]: t('common.discussion'),
  [STATUS.STOP]: t('common.stop'),
  [STATUS.NOT_RELEVANT]: t('common.not_relevant'),
};

export const CREDIT_LABELS: Record<CommonDealSchema['credit'], string> = {
  [CREDIT.UNKNOWN]: t('common.unknown'),
  [CREDIT.FULL]: t('common.full'),
  [CREDIT.LIMITED]: t('common.limited'),
  [CREDIT.FIFTY_FIFTY]: t('common.fifty_fifty'),
  [CREDIT.NO_CREDIT]: t('common.no_credit'),
};

export const PROTECTION_LABELS: Record<CommonDealSchema['protection'], string> = {
  [PROTECTION.UNKNOWN]: t('common.unknown'),
  [PROTECTION.PROTECTION]: t('common.protection'),
  [PROTECTION.PENALTY]: t('common.penalty'),
  [PROTECTION.NO_PENALTY]: t('common.no_penalty'),
  [PROTECTION.FIFTY_FIFTY]: t('common.fifty_fifty'),
};

export const PAYMENT_SYSTEM_LABELS: Record<CommonDealSchema['payment_system'][number], string> = {
  [PAYMENT_SYSTEM.UNKNOWN]: t('common.unknown'),
  [PAYMENT_SYSTEM.USDT]: t('common.usdt'),
  [PAYMENT_SYSTEM.BTC]: t('common.btc'),
  [PAYMENT_SYSTEM.BANK_CARD_RU]: t('common.bank_card_ru'),
  [PAYMENT_SYSTEM.VENMO]: t('common.venmo'),
  [PAYMENT_SYSTEM.ZELLE]: t('common.zelle'),
  [PAYMENT_SYSTEM.CASH_APP]: t('common.cash_app'),
  [PAYMENT_SYSTEM.PAYPAL]: t('common.paypal'),
  [PAYMENT_SYSTEM.APPLE_PAY]: t('common.apple_pay'),
};

const renderTag = (value?: string) => {
  if (!value) return '—';

  return (
    <Tag>
      <Tag.FadingText text={value} />
    </Tag>
  );
};

export const renderClubName = (data: CommonDealSchema) => renderTag(data.club.name);
export const renderClubCode = (data: CommonDealSchema) => renderTag(data.club.code);

export const getCurrency = (data: CommonDealSchema) => {
  const { chip_rate, currency } = data;

  if (!chip_rate && !currency) return '—';

  return `${chip_rate || ''} ${currency || ''}`;
};

export const renderPaymentSystems = (data: CommonDealSchema) => {
  const { payment_system } = data;

  if (payment_system.length) {
    return (
      <Tag.Group
        tooltip={(index) =>
          payment_system
            .slice(index)
            .map((item) => PAYMENT_SYSTEM_LABELS[item])
            .join(', ')
        }
      >
        {payment_system.map((item) => (
          <Tag key={item}>{PAYMENT_SYSTEM_LABELS[item]}</Tag>
        ))}
      </Tag.Group>
    );
  }

  return '—';
};

export const renderCaption = (data: CommonDealSchema) =>
  `${data.agent.name} • ${data.club.code}${data.holder ? ` • ${data.holder?.username}` : ''}`;

export const sortings = [
  { key: 'code', title: t('common.code'), default: true },
  { key: 'agent_name', title: t('common.agent') },
  { key: 'poker_room', title: t('common.poker_room') },
  { key: 'club_name', title: t('common.club') },
  { key: 'area_id', title: t('common.area') },
  { key: 'area2_id', title: t('common.area_2') },
  { key: 'holder_username', title: t('common.holder') },
  { key: 'owner', title: t('common.owner') },
  { key: 'role', title: t('common.role') },
  { key: 'status', title: t('common.status') },
  { key: 'credit', title: t('common.credit') },
  { key: 'protection', title: t('common.protection') },
  { key: 'currency', title: t('common.currency') },
  { key: 'chip_rate', title: t('common.chip_rate') },
  { key: 'rake_back', title: t('common.rakeback') },
  { key: 'action', title: t('common.action') },
  { key: 'rev_share', title: t('common.revshare') },
  { key: 'win_share', title: t('common.winshare') },
  { key: 'rebate', title: t('common.rebate') },
  { key: 'agent_fee', title: t('common.agent_fee') },
  { key: 'withdraw_fee', title: t('common.withdraw_fee') },
  { key: 'created_on', title: t('common.created') },
];
