import ALIASES from '../../constants/ALIASES';
import api from '../../services/api';
import URLParams from '../../types/URLParams';
import paginationSchemaNew from '../schemas/paginationSchemaNew';
import workspaceStageSchema from '../schemas/stage/workspaceStageSchema';
import { WorkspaceSchema } from '../schemas/workspaceSchema';

interface Args {
  workspaceId: WorkspaceSchema['id'];
}

const loadGlobalWorkspaceStages = async (args: Args, params?: URLParams) => {
  const { workspaceId } = args;

  const response = await api.get(`/api/global/${ALIASES.WORKSPACE.L}/${workspaceId}/stage`, {
    params,
  });

  return paginationSchemaNew(workspaceStageSchema).parse(response.data);
};

export default loadGlobalWorkspaceStages;
