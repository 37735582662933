import { GlobalBonusSchema, TYPE } from '../../api/schemas/globalBonusSchema';
import { t } from '../../i18n';

export const TYPE_LABELS: Record<GlobalBonusSchema['type'], string> = {
  [TYPE.UNKNOWN]: '—',
  [TYPE.JACKPOT]: t('common.jackpot'),
  [TYPE.HAND_COMBO]: t('common.hand_combo'),
  [TYPE.CLUB_CONDITIONS]: t('common.club_conditions'),
  [TYPE.OTHER]: t('common.other'),
};

export const typesOptions = Object.values(TYPE)
  .filter((item) => item !== TYPE.UNKNOWN)
  .map((item) => ({
    value: item,
    label: TYPE_LABELS[item],
  }));
