import api from '../../services/api';
import accountBalanceSchema from '../schemas/account/accountBalanceSchema';
import { GlobalAccountSchema } from '../schemas/account/globalAccountSchema';

interface Args {
  accountId: GlobalAccountSchema['id'];
}

const loadGlobalAccountBalance = async (args: Args) => {
  const { accountId } = args;

  const response = await api.get(`/api/account/${accountId}/balance`);

  return accountBalanceSchema.parse(response.data);
};

export default loadGlobalAccountBalance;
