import { MarketIdentitySchema } from '../../api/schemas/marketIdentitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import PageDetailLink from '../../components/PageDetailLink';
import routes from '../../config/routes';
import { getSellerName } from './helpers';

interface Props {
  data: MarketIdentitySchema;
  workspaceId: WorkspaceSchema['id'];
}

const WorkspaceMarketIdentityDetailLink = (props: Props) => {
  const { data, workspaceId } = props;

  const { id } = data;

  return (
    <PageDetailLink
      label={getSellerName(data)}
      to={routes.workspaceMarketIdentity({ workspaceId, identityId: id })}
    />
  );
};

export default WorkspaceMarketIdentityDetailLink;
