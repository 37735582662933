const getColor = (value: string) => getComputedStyle(document.body).getPropertyValue(value);

export default {
  get BLACK() {
    return getColor('--black');
  },
  get WHITE() {
    return getColor('--white');
  },

  get GREY_BG_50() {
    return getColor('--grey-bg-50');
  },
  get GREY_BG_100() {
    return getColor('--grey-bg-100');
  },
  get GREY_BG_200() {
    return getColor('--grey-bg-200');
  },

  get GREY_100() {
    return getColor('--grey-100');
  },
  get GREY_200() {
    return getColor('--grey-200');
  },
  get GREY_300() {
    return getColor('--grey-300');
  },
  get GREY_400() {
    return getColor('--grey-400');
  },
  get GREY_500() {
    return getColor('--grey-500');
  },
  get GREY_600() {
    return getColor('--grey-600');
  },

  get BLUE_100() {
    return getColor('--blue-100');
  },
  get BLUE_200() {
    return getColor('--blue-200');
  },
  get BLUE_300() {
    return getColor('--blue-300');
  },
  get BLUE_400() {
    return getColor('--blue-400');
  },
  get BLUE_500() {
    return getColor('--blue-500');
  },
  get BLUE_600() {
    return getColor('--blue-600');
  },

  get RED_100() {
    return getColor('--red-100');
  },
  get RED_200() {
    return getColor('--red-200');
  },
  get RED_300() {
    return getColor('--red-300');
  },
  get RED_400() {
    return getColor('--red-400');
  },
  get RED_500() {
    return getColor('--red-500');
  },
  get RED_600() {
    return getColor('--red-600');
  },

  get GREEN_100() {
    return getColor('--green-100');
  },
  get GREEN_200() {
    return getColor('--green-200');
  },
  get GREEN_300() {
    return getColor('--green-300');
  },
  get GREEN_400() {
    return getColor('--green-400');
  },
  get GREEN_500() {
    return getColor('--green-500');
  },
  get GREEN_600() {
    return getColor('--green-600');
  },

  get ORANGE_100() {
    return getColor('--orange-100');
  },
  get ORANGE_200() {
    return getColor('--orange-200');
  },
  get ORANGE_300() {
    return getColor('--orange-300');
  },
  get ORANGE_400() {
    return getColor('--orange-400');
  },
  get ORANGE_500() {
    return getColor('--orange-500');
  },
  get ORANGE_600() {
    return getColor('--orange-600');
  },

  get PURPLE_100() {
    return getColor('--purple-100');
  },
  get PURPLE_200() {
    return getColor('--purple-200');
  },
  get PURPLE_300() {
    return getColor('--purple-300');
  },
  get PURPLE_400() {
    return getColor('--purple-400');
  },
  get PURPLE_500() {
    return getColor('--purple-500');
  },
  get PURPLE_600() {
    return getColor('--purple-600');
  },

  get CYAN_100() {
    return getColor('--cyan-100');
  },
  get CYAN_200() {
    return getColor('--cyan-200');
  },
  get CYAN_300() {
    return getColor('--cyan-300');
  },
  get CYAN_400() {
    return getColor('--cyan-400');
  },
  get CYAN_500() {
    return getColor('--cyan-500');
  },
  get CYAN_600() {
    return getColor('--cyan-600');
  },

  get FUCHSIA_100() {
    return getColor('--fuchsia-100');
  },
  get FUCHSIA_200() {
    return getColor('--fuchsia-200');
  },
  get FUCHSIA_300() {
    return getColor('--fuchsia-300');
  },
  get FUCHSIA_400() {
    return getColor('--fuchsia-400');
  },
  get FUCHSIA_500() {
    return getColor('--fuchsia-500');
  },
  get FUCHSIA_600() {
    return getColor('--fuchsia-600');
  },

  get PINK_100() {
    return getColor('--pink-100');
  },
  get PINK_200() {
    return getColor('--pink-200');
  },
  get PINK_300() {
    return getColor('--pink-300');
  },
  get PINK_400() {
    return getColor('--pink-400');
  },
  get PINK_500() {
    return getColor('--pink-500');
  },
  get PINK_600() {
    return getColor('--pink-600');
  },
};
