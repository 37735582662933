import { ReactElement } from 'react';
import { deleteWorkspaceAccountNote, loadWorkspaceAccountNotes } from '../../../api';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import { Props as NotesProps } from '../../../components/Notes';
import queryKeys from '../../../config/queryKeys';
import usePaginatedDataNew from '../../../hooks/usePaginatedDataNew';
import {
  useCreateWorkspaceAccountNoteMutation,
  useUpdateWorkspaceAccountNoteMutation,
} from './mutations';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  accountId: WorkspaceAccountSchema['id'];
  children: (args: NotesProps) => ReactElement;
}

const WorkspaceAccountNotes = (props: Props) => {
  const { workspaceId, accountId, children } = props;

  const queryKey = [queryKeys.workspaceAccountNotes({ workspaceId, accountId })];

  const query = usePaginatedDataNew({
    queryKey,
    queryFn: (params) =>
      loadWorkspaceAccountNotes({ workspaceId, accountId }, { ...params, order_by: 'desc' }),
  });

  const createNote = useCreateWorkspaceAccountNoteMutation();
  const updateNote = useUpdateWorkspaceAccountNoteMutation();

  return children({
    query,
    creating: createNote.isPending,
    updating: updateNote.isPending,
    onCreate: ({ payload }) => createNote.mutateAsync({ workspaceId, accountId, payload }),
    onUpdate: ({ noteId, payload }) =>
      updateNote.mutateAsync({ workspaceId, accountId, noteId, payload }),
    onDelete: ({ noteId }) => deleteWorkspaceAccountNote({ workspaceId, accountId, noteId }),
    onCreateMessage: (message) => message.payload.instance.id === accountId,
  });
};

export default WorkspaceAccountNotes;
