import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { WorkspaceClubManagerSchema } from '../../api/schemas/workspaceClubManagerSchema';
import { WorkspaceClubMemberSchema } from '../../api/schemas/workspaceClubMemberSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Button from '../../components/Button';
import Link from '../../components/Link';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import { formatAbsoluteDate } from '../../utils/date';
import { TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';
import WorkspaceClubMemberBalance from './WorkspaceClubMemberBalance';
import WorkspaceClubMemberDetailDropdown from './WorkspaceClubMemberDetailDropdown';
import WorkspaceClubMemberTotalFee from './WorkspaceClubMemberTotalFee';
import WorkspaceClubMemberWinnings from './WorkspaceClubMemberWinnings';

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data: WorkspaceClubMemberSchema;
  onClose: () => void;
  onDeposit: () => void;
  onWithdraw: () => void;
  getManagerRoute?: (args: { managerId: WorkspaceClubManagerSchema['id'] }) => string;
}

const WorkspaceClubMemberDetail = (props: Props) => {
  const { workspaceId, data, onClose, onDeposit, onWithdraw, getManagerRoute } = props;

  const { t } = useTranslation();

  const {
    id,
    room,
    club,
    nickname,
    hands,
    player_id,
    accept,
    kick,
    manager,
    additional_attributes,
    is_deleted,
    created_on,
    modified_on,
  } = data;

  const titleIcons = (
    <>
      {is_deleted && icon('trash', 12)}
      {accept && <Tooltip label={t('common.awaiting_entry')}>{icon('clock', 12)}</Tooltip>}
      {kick && <Tooltip label={t('common.awaiting_kick_off')}>{icon('minusCircle', 12)}</Tooltip>}
    </>
  );

  return (
    <PageAddon.Group>
      <Outlet />
      <PageAddon title={player_id} icons={titleIcons} onClose={onClose}>
        <PageAddon.Fields>
          <PageAddon.Id id={id} />
          <PageAddon.Field label={t('common.poker_room')}>
            {TRAINER_POKER_ROOM_LABELS[room]}
          </PageAddon.Field>
          <PageAddon.Field label={t('common.club')}>{club}</PageAddon.Field>
          <PageAddon.Field label={t('common.manager')}>
            {getManagerRoute ? (
              <Link to={getManagerRoute({ managerId: manager.id })}>{manager.login}</Link>
            ) : (
              manager.login
            )}
          </PageAddon.Field>
          <PageAddon.Field label={t('common.nickname')}>{nickname}</PageAddon.Field>
          <PageAddon.Field label={t('common.balance')}>
            <WorkspaceClubMemberBalance data={data} />
          </PageAddon.Field>
          <PageAddon.Field label={t('common.total_fee')}>
            <WorkspaceClubMemberTotalFee data={data} />
          </PageAddon.Field>
          <PageAddon.Field label={t('common.winnings')}>
            <WorkspaceClubMemberWinnings data={data} />
          </PageAddon.Field>
          <PageAddon.Field label={t('common.hands')}>{hands}</PageAddon.Field>
          <PageAddon.ExtraFields data={additional_attributes} />
          <PageAddon.Field label={t('common.modified')}>
            {formatAbsoluteDate(modified_on)}
          </PageAddon.Field>
          <PageAddon.Field label={t('common.created')}>
            {formatAbsoluteDate(created_on)}
          </PageAddon.Field>
        </PageAddon.Fields>
        {!is_deleted && (
          <PageAddon.Controls>
            <PageAddon.Controls.Group>
              <Tooltip label={t('common.deposit')} hideOnMobile>
                <Button
                  variant="transparent"
                  icon={icon('deposit', 16)}
                  onClick={onDeposit}
                  data-testid="deposit"
                />
              </Tooltip>
              <Tooltip label={t('common.withdraw')} hideOnMobile>
                <Button
                  variant="transparent"
                  icon={icon('withdraw', 16)}
                  onClick={onWithdraw}
                  data-testid="widthdraw"
                />
              </Tooltip>
              <WorkspaceClubMemberDetailDropdown data={data} workspaceId={workspaceId} />
            </PageAddon.Controls.Group>
          </PageAddon.Controls>
        )}
      </PageAddon>
    </PageAddon.Group>
  );
};

export default WorkspaceClubMemberDetail;
