import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  bulkDeleteWorkspaceAccountPlannedSessions,
  bulkFilteredDeleteWorkspaceAccountPlannedSessions,
  deleteWorkspaceAccountPlannedSessions,
} from '../../../api';
import { WorkspaceAccountSchema } from '../../../api/schemas/account/workspaceAccountSchema';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import Alert from '../../../components/Alert';
import DatePicker from '../../../components/DatePicker';
import Dialog from '../../../components/Dialog';
import Form from '../../../components/Form';
import SubmitButton from '../../../components/SubmitButton';
import { DAY } from '../../../constants/DATE_FORMATS';
import useTimeZone from '../../../hooks/useTimeZone';
import { isDateBefore } from '../../../utils/date';
import { required, validDateFormat } from '../../../utils/form';
import notify from '../../../utils/notify';
import useBulkActions from '../useBulkActions';
import styles from './styles.module.scss';

interface Fields {
  start: string;
}

interface Props {
  workspaceId: WorkspaceSchema['id'];
  onClose: () => void;
  accountId?: WorkspaceAccountSchema['id'];
}

const WorkspaceAccountRemoveSessionsDialog = (props: Props) => {
  const { workspaceId, onClose, accountId } = props;

  const { t } = useTranslation();
  const { dateToZonedDateTime } = useTimeZone();
  const { ids, params, selectAllMode, handle } = useBulkActions();

  const form = useForm<Fields>({ defaultValues: { start: '' } });

  const {
    control,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    const payload = { start: dateToZonedDateTime(values.start) };

    if (accountId) {
      await deleteWorkspaceAccountPlannedSessions({ workspaceId, accountId, payload });
    } else {
      await handle(
        () => {
          const fn = selectAllMode
            ? bulkFilteredDeleteWorkspaceAccountPlannedSessions
            : bulkDeleteWorkspaceAccountPlannedSessions;

          return fn({
            workspaceId,
            payload: { ...payload, account_ids: ids },
            params,
          });
        },
        { notify: false }
      );
    }

    notify('success', { title: t('sentences.planned_sessions_have_been_removed') });
    onClose();
  };

  return (
    <Dialog onClose={onClose}>
      {() => (
        <>
          <Dialog.Top>
            <Dialog.Title>{t('sentences.remove_planned_sessions')}</Dialog.Title>
          </Dialog.Top>
          <Form form={form} onSubmit={onSubmit} className={styles.form}>
            <div>
              <Alert
                type="info"
                title={t('sentences.remove_planned_sessions_description')}
                closable={false}
              />
              <Controller
                control={control}
                name="start"
                rules={{ validate: { required, validDate: validDateFormat(DAY) } }}
                render={({ field }) => (
                  <DatePicker
                    size="medium"
                    label={t('common.remove_from')}
                    disabledDate={(date) => isDateBefore(date)}
                    error={errors.start?.message}
                    {...field}
                  />
                )}
              />
            </div>
            <Dialog.Footer>
              <SubmitButton>{t('common.remove')}</SubmitButton>
            </Dialog.Footer>
          </Form>
        </>
      )}
    </Dialog>
  );
};

export default WorkspaceAccountRemoveSessionsDialog;
