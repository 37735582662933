import { OperatorSelectorProps, useValueSelector } from 'react-querybuilder';
import Select from '../Select';
import { optionListMapNameToValue, selectConfig } from './helpers';
import styles from './styles.module.scss';

const QueryBuilderOperatorSelector = (props: OperatorSelectorProps) => {
  const { options } = props;

  const { onChange, val: value } = useValueSelector(props);

  const data = optionListMapNameToValue(options);

  const option = data.find((item) => item.name === value);

  return (
    <Select
      size="small"
      popupSize="auto"
      clearable={false}
      className={styles.operator}
      value={{
        value: option ? option.name : data[0].name,
        label: option ? option.label : data[0].label,
        icon: option ? option.icon : data[0].icon,
      }}
      config={selectConfig}
      disabled={data.length === 1}
      options={data.map((item) => ({ value: item.name, label: item.label, icon: item.icon }))}
      renderValue={(selectedOption) => selectedOption?.icon}
      onChange={(event) => {
        if (event) {
          onChange(event.value);
        }
      }}
    />
  );
};

export default QueryBuilderOperatorSelector;
