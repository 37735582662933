import Placeholder from '../../Placeholder';
import styles from './styles.module.scss';

const FilterPlaceholder = () => (
  <div className={styles.container}>
    {Array.from({ length: 3 }).map((_, index) => (
      <div key={index}>
        <Placeholder className={styles.label} />
        <Placeholder className={styles.content} />
      </div>
    ))}
  </div>
);

export default FilterPlaceholder;
