import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Collapse from '../../components/Collapse';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import PasswordInput from '../../components/PasswordInput';
import Select from '../../components/Select';
import { maxLength, required, minLength } from '../../utils/form';
import { WALLETS_LABELS, walletsOptions } from './helpers';
import { Fields } from './types';
import WorkspaceIdentityFormCollapse from './WorkspaceIdentityFormCollapse';

const WorkspaceIdentityFormOnlineWallets = () => {
  const { t } = useTranslation();

  const {
    watch,
    control,
    register,
    formState: { errors },
  } = useFormContext<Fields>();

  const { fields, append, remove } = useFieldArray({ control, name: 'online_wallets' });

  return (
    <PageAddon.DynamicFields
      label={t('common.online_wallets')}
      onAppend={() => append({ login: '', password: '', type: null })}
    >
      {fields.map((dynamicField, index) => {
        const { type } = watch(`online_wallets.${index}`);

        return (
          <WorkspaceIdentityFormCollapse
            key={dynamicField.id}
            onDelete={() => remove(index)}
            defaultOpen={!dynamicField.login}
            label={(type && WALLETS_LABELS[type.value]) || `${t('common.wallet')} ${index + 1}`}
            error={errors.online_wallets ? Boolean(errors.online_wallets[index]) : false}
          >
            <Collapse.FieldGroup>
              <Controller
                control={control}
                name={`online_wallets.${index}.type`}
                rules={{ validate: required }}
                render={({ field }) => (
                  <Select
                    clearable={false}
                    label={`${t('common.type')}*`}
                    options={walletsOptions}
                    value={field.value}
                    onChange={field.onChange}
                    {...(errors.online_wallets && {
                      // @ts-expect-error
                      error: errors.online_wallets[index]?.type?.message,
                    })}
                  />
                )}
              />
              <Input
                label={`${t('common.login')}*`}
                {...register(`online_wallets.${index}.login`, {
                  validate: { required, maxLength: maxLength() },
                })}
                {...(errors.online_wallets && {
                  error: errors.online_wallets[index]?.login?.message,
                })}
              />
              <PasswordInput
                {...register(`online_wallets.${index}.password`, {
                  validate: { minLength: minLength(6), maxLength: maxLength() },
                })}
                {...(errors.online_wallets && {
                  error: errors.online_wallets[index]?.password?.message,
                })}
              />
            </Collapse.FieldGroup>
          </WorkspaceIdentityFormCollapse>
        );
      })}
    </PageAddon.DynamicFields>
  );
};

export default WorkspaceIdentityFormOnlineWallets;
